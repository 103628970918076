import { connect } from "react-redux"

import GridComponent from "../components/Grid"
import {
  closeOptionBoxes,
  loadAllGoals,
  loadAllWidgets,
  loadSurveys,
  loadWidgetLayout,
  localLayoutChange,
  postLayout,
  removeWidget,
  updateGridCustomisationFlag
} from "../store/developCareer.actions"
import { getAllWidgets, getGridCustomisationFlag, getWidgetLayout } from "../store/developCareer.selectors"

const loader = dispatch => {
  dispatch(loadWidgetLayout())
  dispatch(loadAllWidgets())
  dispatch(loadSurveys())
  dispatch(loadAllGoals())
}

const mapState = state => ({
  widgetLayout: getWidgetLayout(state),
  allWidgets: getAllWidgets(state),
  gridCustomisationFlag: getGridCustomisationFlag(state)
})

const mapDispatch = dispatch => ({
  removeWidget: i => dispatch(removeWidget(i)),
  loadAll: () => loader(dispatch),
  localLayoutChange: localLayout => dispatch(localLayoutChange(localLayout)),
  postLayout: () => dispatch(postLayout()),
  closeOptionBoxes: () => dispatch(closeOptionBoxes()),
  updateGridCustomisationFlag: boolean => dispatch(updateGridCustomisationFlag(boolean))
})

const Grid = connect(mapState, mapDispatch)(GridComponent)

export default Grid
