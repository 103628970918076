import React from "react"
import { Flex } from "@engaging-tech/components"
import { useTranslation } from "react-i18next"
import ResultCard from "../../components/ResultCard"
import StatisticBox from "../../HappinessDataHighlight/components/StatisticBox"
import ReportItem from "../../components/ReportItem"
import PercentageGraphic from "../../../ui/components/PercentageGraphic"
import WellbeingComparisonChart from "./WellbeingComparisonChart"

const WellbeingDataHighlight = ({
  completedAt,
  happinessChange,
  wellbeingScore: { industry, global, score }
}) => {
  const { t } = useTranslation(["wellbeing_results"])
  const formatDate = date => `${date.getDate()} ${t(
    `months.${[date.getMonth().toString()]}`,
    ""
  )} 
    ${date.getFullYear()}`

  return (
    <ResultCard flexDirection={["column", "row"]}>
      <Flex flexDirection="column" justifyContent="flex-start">
        <ReportItem
          heading={t("happiness_data_highlight.heading", "")}
          subHeading={`${t(
            "happiness_data_highlight.sub_heading",
            ""
          )} ${formatDate(new Date(completedAt))}`}
        >
          <PercentageGraphic score={score} />
          <StatisticBox
            comparedToIndustry={score - industry}
            happinessChange={happinessChange}
          />
        </ReportItem>
      </Flex>
      <WellbeingComparisonChart
        score={score}
        industryScore={industry}
        globalScore={global}
      />
    </ResultCard>
  )
}

export default WellbeingDataHighlight
