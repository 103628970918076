import { connect } from "react-redux"
import SkillsComponent from "../components/Skills"
import { updateYourProfile } from "../store/yourProfile.actions"
import { getSkills} from "../store/yourProfile.selectors"
import { getAllSkills } from "../../misc/store/misc.selectors"

const mapState = state => ({
  skills: getSkills(state),
  allSkills: getAllSkills(state)
})

const mapDispatch = dispatch => ({
  onUpdate: params => dispatch(updateYourProfile(params))
})

const Skills = connect(mapState, mapDispatch)(SkillsComponent)

export default Skills
