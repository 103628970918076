import { connect } from "react-redux"
import {
  getQuestionIndexById,
  getQuestionTotal
} from "../store/survey.selectors"

const mapState = (state, { id }) => ({
  current: getQuestionIndexById(state, id),
  total: getQuestionTotal(state)
})

const progressBarContainer = connect(mapState)

export default progressBarContainer
