import { connect } from "react-redux"
import { getHasLoaded, getStatus } from "../store/survey.selectors"

const mapState = state => ({
  status: getStatus(state),
  hasLoaded: getHasLoaded(state)
})

const testingBarContainer = connect(mapState)

export default testingBarContainer
