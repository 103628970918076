import React from "react"
import BaseLayout from "../layouts/BaseLayout"
import Banner from "../features/landingPage/components/Banner"
import SiteFeatures from "../features/landingPage/components/SiteFeatures/SiteFeatures"
import WorkplaceHappinessSurvey from "../features/landingPage/components/WorkplaceHappinessSurvey"
import MessengerApp from "../features/landingPage/components/MessengerApp"
import EngagingBusiness from "../features/landingPage/components/EngagingBusiness/EngagingBusiness"
import EngagingWorksFoundation from "../features/landingPage/components/EngagingWorksFoundation"
import GetStarted from "../features/landingPage/components/GetStarted"
import PremiumBanner from "../features/landingPage/components/PremiumBanner"
import AllCountriesLoader from "../features/misc/containers/CountriesLoader"

const Home = () => (
  <BaseLayout before={PremiumBanner}>
    <AllCountriesLoader />
    <Banner />
    <SiteFeatures />
    <WorkplaceHappinessSurvey />
    <MessengerApp />
    <EngagingBusiness />
    <EngagingWorksFoundation />
    <GetStarted />
  </BaseLayout>
)

export default Home
