/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react"
import {
  MotionSelect,
  LocationAutocomplete,
  Text
} from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"

const SelectDropdowns = ({
  categories,
  countries,
  setEventAlert,
  eventAlert
}) => {
  const setLocation = location => {
    if (location) {
      setEventAlert({
        ...eventAlert,
        location: {
          name: location.formatted_address,
          countryId: countries.filter(
            country =>
              country.code ===
              location.address_components.filter(component =>
                component.types.includes("country")
              )[0].short_name
          )[0].id,
          country: {
            name: location.address_components.filter(country =>
              country.types.includes("country", "political")
            )[0].long_name,
            shortName: location.address_components.filter(country =>
              country.types.includes("country", "political")
            )[0].short_name
          },
          point: {
            coordinates: [
              location.geometry.location.lat(),
              location.geometry.location.lng()
            ]
          }
        }
      })
    }
  }

  const handleChange = value => {
    if (eventAlert?.location && !(eventAlert?.location?.name === value))
      setEventAlert({
        ...eventAlert,
        location: null
      })
  }

  return (
    <>
      <MotionSelect
        label="Choose Category"
        id="categoryId"
        disableClearButton
        width={150}
        options={[
          ...categories.map(category => ({
            id: category.id,
            value: category.name
          }))
        ]}
        defaultOptionId={categories[0].id}
        maxExtendedHeight={278}
        zIndex={3}
        mt={[15, 0, 0]}
        borderRadius={24}
        bg="light.0"
        buttonTextStyling={{
          fontSize: 14,
          ml: 3,
          mr: 0,
          fontWeight: 400,
          color: "primary.0"
        }}
        border={{
          style: "solid",
          width: "1px",
          color: "rgb(1,62,76)"
        }}
        dropdownBg="grey.0"
        onChange={option =>
          setEventAlert({
            ...eventAlert,
            category: option
          })
        }
      />

      <Text
        lineHeight={3}
        display="inline-flex"
        fontWeight="500"
        color="dark.2"
        fontSize={3}
        width="auto"
        mx={3}
        textAlign="center"
      >
        in
      </Text>

      <LocationAutocomplete
        height={40}
        width={240}
        noLabel
        googleApiKey={getConfig().keys.googleApiKey}
        noHighlight
        borderRadius={20}
        border="1px solid rgba(1, 62, 76, 1)"
        bg="light.0"
        fontSize={16}
        iconName="room"
        iconStyle={{
          ml: "6px",
          color: "primary.0"
        }}
        selectedLocationCallback={setLocation}
        onChange={handleChange}
      />
    </>
  )
}

export default SelectDropdowns
