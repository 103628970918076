import { Button, Flex, List, Text } from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"
import styled from "styled-components"

const Image = styled("img")`
  width: 50px;
  maring-right: 10px;
`

const TelegraphText = () => {
  const paths = usePaths()

  return (
    <Flex flexDirection="column">
      <DesktopTitles />
      <List.Wrapper spacing="8px">
        <List.Item>
          <Text fontSize={[3, 4]} color="dark.2">
            Rankings are based on the data collected through our free
            <Link to="/happiness-survey">
              <Text fontWeight="bold" ml={1} fontSize={[3, 4]} color="primary.0">
                <u>Happy at Work Test</u>
              </Text>
            </Link>
          </Text>
        </List.Item>
        <List.Item fontSize={[3, 4]} color="dark.2">
          Organisations shown in green have a workplace happiness score of 70% or above.
        </List.Item>
        <List.Item fontSize={[3, 4]} color="dark.2">
          <Text fontSize={[3, 4]} color="dark.2">
            Organisations listed have been nominated by their employees, to enter your organisation{" "}
            <Link to={paths.happinessSurvey.index}>
              <Text fontWeight="bold" color="primary.0" style={{ textDecoration: "underline" }}>
                click here.
              </Text>
            </Link>
          </Text>
        </List.Item>
        <List.Item fontSize={[3, 4]} color="dark.2">
          Search by organisation name or filter the list by industry, country and award.
        </List.Item>
        <List.Item fontSize={[3, 4]} color="dark.2">
          <Text fontFamily="inherit" fontSize="inherit" color="inherit">
            Our Happiest Workplaces List is supported by our brilliant partners{" "}
            <Link to={`${getConfig().workL.public.index}/happiest-companies`} newTab external>
              <Text fontWeight="bold" color="primary.0" style={{ textDecoration: "underline" }}>
                click here
              </Text>
            </Link>{" "}
            for more.
          </Text>
        </List.Item>
      </List.Wrapper>
    </Flex>
  )
}
const titleText = [
  "Happiest Workplaces List",
  "Our Happiest Workplaces List is a live ranking of how happy and engaged employees are at organisations around the world. Click on the name of the organisation to find out more and find jobs at that organisation."
]

const MobileTitles = () => (
  <Flex flexDirection="column" display={["block", "block", "none"]} mb={2} alignItems="center">
    <Text
      fontSize={[5, 7]}
      fontWeight={600}
      textAlign="center"
      color="primary.0"
      width="auto"
      fontFamily="Tiempos"
      mb={[0, 4, 4]}
    >
      {titleText[0]}
    </Text>
    <br />
    <Text fontSize={4} fontWeight={500} textAlign="center" color="dark.1" width="auto">
      {titleText[1]}
    </Text>
  </Flex>
)

const DesktopTitles = () => (
  <>
    <Text
      display={["none", "none", "block"]}
      fontSize={[5, 7]}
      fontWeight={600}
      textAlign="center"
      color="primary.0"
      width="auto"
      fontFamily="Tiempos"
      mb={[0, 4, 4]}
    >
      {titleText[0]}
    </Text>
    <Text
      display={["none", "none", "block"]}
      fontSize={4}
      fontWeight={500}
      textAlign="left"
      color="dark.1"
      width="auto"
    >
      {titleText[1]}
    </Text>
  </>
)

const TelegraphPlaceHolder = ({ ...props }) => {
  const paths = usePaths()

  return (
    <Flex flexDirection="column" width={1 / 1} mb={2} px={[3, 0]}>
      <MobileTitles />
      <Flex flexDirection={["column", "row", "row"]} alignItems={["center", "center"]} {...props}>
        <TelegraphText />
        <Flex
          flexDirection="column"
          ml={[1, 6]}
          mr={[1, 0]}
          width={[1 / 1, "400px"]}
          alignItems="center"
          bg="#F0F8FF"
          p={4}
        >
          <Text fontSize={5} color="primary.0" px={4} fontWeight={600}>
            Entries for the 2024 WorkL Happiest Workplaces Awards are now open.
          </Text>
          <Flex mt={3} px={4}>
            <Image
              src="https://media.engaging.works/awards/logos/2024+Awards+Gold.png"
              alt="enter workl awards"
            />
            <Text pl={3}>
              Take our Happy At Work Test to enter your workplace. It’s free and takes just 5 minutes to
              complete.
            </Text>
          </Flex>
          <Link to={paths.happinessSurvey.index} newTab>
            <Button mt={3} bg="primary.0" color="light.0" px={5}>
              ENTER NOW
            </Button>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  )
}
export default TelegraphPlaceHolder
