import { Text } from "@engaging-tech/components"
import React from "react"
import SurveyCard from "./surveyCard"

const CustomIntro = ({
  title,
  description,
  isFirstSection,
  onNext,
  onPrevious
}) => (
  <SurveyCard
    title={title}
    onNext={onNext}
    onPrevious={onPrevious}
    isFirstSection={isFirstSection}
    isIntro
  >
    {typeof description === "string" ? (
      <Text>{description}</Text>
    ) : (
      description?.map((item, index) => (
        <Text
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          mb={index === description.length - 1 ? 0 : 3}
          display="block"
        >
          {item.content}
        </Text>
      ))
    )}
  </SurveyCard>
)

export default CustomIntro
