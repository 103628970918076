export const types = {
  FETCH_SURVEY_FOR_SAML_USER: "[SAML] Fetch Survey For Saml User",
  FETCH_SURVEY_FOR_SAML_USER_SUCCESS:
    "[SAML] Fetch Survey For Saml User Success",
  SAML_SIGN_IN: "[SAML] Saml Sign In",
  SAML_SIGN_IN_SUCCESS: "[SAML] Saml Sign In Success"
}

export const fetchSurveyForSamlUser = () => ({
  type: types.FETCH_SURVEY_FOR_SAML_USER
})

export const fetchSurveyForSamlUserSuccess = surveys => ({
  type: types.FETCH_SURVEY_FOR_SAML_USER_SUCCESS,
  payload: surveys
})

export const samlSignIn = ({ idToken, accessToken, refreshToken }) => ({
  type: types.SAML_SIGN_IN,
  payload: { idToken, accessToken, refreshToken }
})

export const samlSignInSuccess = () => ({
  type: types.SAML_SIGN_IN_SUCCESS
})
