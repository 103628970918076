import { CircularProgress } from "@engaging-tech/charts"
import { DesktopOnly, Flex, Icon, Spinner, TabletDown, TabletUp, Text } from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"
import { format } from "date-fns"
import React, { useRef } from "react"
import styled from "styled-components"

import usePercentageGraphicAnimation from "../../../../hooks/usePercentageGraphicAnimation"
import { getLabelData } from "../../../../lib/utils"
import ScoreLabel from "../../../surveyResults/components/ScoreLabel"
import PercentageGraphic from "../../../ui/components/PercentageGraphic"
import PrimaryButton from "../../../ui/components/PrimaryButton"
import WidgetCard from "../../components/WidgetCard"

const Wrapper = styled(Flex)`
  border-bottom: ${({ theme, noBorder }) => (noBorder ? "none" : `1px solid ${theme.colors.dark[4]}`)};
`

const Title = styled(Text)`
  text-transform: capitalize;
`

const getTitle = string => {
  let sentence = string
  if (string.includes("well_being")) {
    sentence = "Wellbeing"
  } else {
    sentence = string.replace("_", " ")
  }

  return sentence
}

const icons1 = ["stars", "record_voice_over", "stickman"]
const icons2 = ["tag_faces", "thumb_up", "work"]

const SurveyDisplay = ({ score, date, takeSurveyText }) => {
  const paths = usePaths()

  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      width={[1 / 1, 250, 250]}
      alignItems="center"
      height="100%"
    >
      <Text fontSize={4} fontWeight={600} width="100%">
        Your Latest Survey Insights
      </Text>
      {score ? (
        <Flex flexDirection="column" justifyContent="center" alignItems="center">
          <PercentageGraphic score={score} graphicWidth={130} thickness={0.44} height={180} animate />

          {date && (
            <Text textAlign="center" mb={3} fontSize={2}>
              Survey taken on: <strong>{date}</strong>
            </Text>
          )}
        </Flex>
      ) : (
        <Flex flexDirection="column" justifyContent="center" alignItems="center">
          <CircularProgress noData pt={2} labelText="?" thickness={0.44} labelSize={60} width={140} />
          <Text textAlign="center" mb={2} fontSize={4} color="dark.3" fontWeight={600}>
            No Score
          </Text>

          <Text fontSize={3} width="auto" textAlign="center" mx={[0, 2]}>
            {takeSurveyText}
          </Text>
        </Flex>
      )}
      <Flex width="100%" mt={2}>
        <Link
          to={score ? paths.careerDeveloper.yourSurveys : `${paths.happinessSurvey.index}`}
          queryParameters={`returnTo=${paths.careerDeveloper.index}`}
        >
          <PrimaryButton
            color="light.0"
            px={3}
            text={score ? "Your Surveys" : "Take Survey"}
            width={150}
            height={30}
          />
        </Link>
      </Flex>
    </Flex>
  )
}

const StepBar = ({ score, icon, section, noBorder, index }) => {
  const containerRef = useRef()
  const currentPercentage = usePercentageGraphicAnimation({
    scoreToAnimateTo: score,
    delay: index * 300,
    containerRef
  })

  return (
    <Wrapper py={[1, 1, 2]} width={1 / 1} alignItems="center" noBorder={noBorder}>
      <Flex justifyContent="flex-start" alignItems="center" width={23 / 50}>
        <Icon name={icon} mx={[2, 5, 2]} color={score ? "dark.2" : "dark.3"} />
        <Title width="auto" fontSize={[3, 4, 3]} color={score ? "dark.2" : "dark.3"} fontWeight={600}>
          {section}
        </Title>
      </Flex>
      <Flex justifyContent="flex-start" alignItems="center" width={10 / 50} ref={containerRef}>
        <CircularProgress
          percentage={currentPercentage || 0}
          width={50}
          height={50}
          strokeColor={getLabelData(currentPercentage || 0).color}
          thickness={0.45}
          fontSizeNoLabel={120}
        />
      </Flex>
      <Flex width={1 / 3} justifyContent="center" alignItems="center">
        <ScoreLabel noIcon score={score || null} width="auto" fontSize={[3, 4, 3]} />
      </Flex>
    </Wrapper>
  )
}

const SixSteps = ({ sixSteps, icons }) => {
  return (
    <Flex flexDirection="column" mr={5}>
      {sixSteps &&
        sixSteps.map((step, index) => (
          <StepBar
            key={step.name}
            section={getTitle(step.name)}
            score={step.score ? step.score : null}
            noBorder={index === sixSteps.length - 1}
            icon={icons[index]}
            index={index}
          />
        ))}
    </Flex>
  )
}

const LatestScore = ({ isLoading, latestSurveys, sixSteps }) => {
  const emptyStateSteps = [
    { name: "reward_recognition" },
    { name: "information_sharing" },
    { name: "empowerment" },
    { name: "wellbeing" },
    { name: "instilling_pride" },
    { name: "job_satisfaction" }
  ]

  const takeSurveyText = "Take the Happiness at Work Survey to gain insights about yourself"

  return (
    <WidgetCard width="100%" bg="light.0" p={3} height="100%">
      {isLoading ? (
        <Spinner py={3} color="primary.0" width={1 / 1} />
      ) : (
        <Flex
          width={1 / 1}
          height="100%"
          flexDirection={["column", "row"]}
          justifyContent="space-evenly"
          alignItems="center"
        >
          <SurveyDisplay
            takeSurveyText={takeSurveyText}
            score={latestSurveys?.data && latestSurveys.data.length > 0 ? latestSurveys.data[0].score : null}
            date={
              latestSurveys?.data && latestSurveys.data.length > 0
                ? format(new Date(latestSurveys.data[0].completedAt), "dd/MM/yyyy")
                : null
            }
          />
          <TabletUp width={1 / 1}>
            <DesktopOnly width={1 / 1}>
              <Flex mt={[0, 0, 4]} width={1 / 1} flexDirection="column">
                <Flex width={1 / 1} flexDirection="row" justifyContent="space-around" alignItems="center">
                  <SixSteps
                    mr={2}
                    sixSteps={sixSteps ? sixSteps.slice(0, 3) : emptyStateSteps.slice(0, 3)}
                    icons={icons1}
                  />
                  <SixSteps
                    sixSteps={sixSteps ? sixSteps.slice(3, 6) : emptyStateSteps.slice(3, 6)}
                    icons={icons2}
                  />
                </Flex>
              </Flex>
            </DesktopOnly>
            <TabletDown width={1 / 1}>
              <SixSteps sixSteps={sixSteps || emptyStateSteps} icons={[...icons1, ...icons2]} />
            </TabletDown>
          </TabletUp>
        </Flex>
      )}
    </WidgetCard>
  )
}

export default LatestScore
