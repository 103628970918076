import React from "react"
import { formatDistanceToNowStrict, format } from "date-fns"
import styled from "styled-components"
import {
  Paragraph,
  Text,
  Box,
  Flex,
  IconButton,
  Button,
  Icon
} from "@engaging-tech/components"

import { getTypeNameById } from "./lib/filters"

const StyledNotifyButton = styled(Button)`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`

const StyledExpandButton = styled(Button)`
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  background-color: transparent;
  border-color: ${({ theme }) => theme.colors.primary[0]};
  :focus {
    outline: 0;
  }
`

const formatDate = (dateString, options) =>
  `${format(new Date(dateString), "dd/MM/yyyy")} - ${formatDistanceToNowStrict(
    new Date(dateString),
    options
  )}`

const DeleteIcon = styled(Icon)`
  cursor: pointer;
`

const ExpirationDate = ({ expireAt }) => {
  if (!expireAt) return null
  if (new Date(expireAt) < new Date())
    return (
      <Paragraph color="error.0" fontSize={2}>
        This vacancy expired on:{" "}
        <Text color="error.0" fontSize={2} fontWeight={500}>
          {formatDate(expireAt, { addSuffix: true })}
        </Text>
      </Paragraph>
    )
  return (
    <Paragraph color="dark.1" fontSize={2}>
      This vacancy expires on:{" "}
      <Text color="dark.1" fontSize={2} fontWeight={500}>
        {formatDate(expireAt)} left to apply
      </Text>
    </Paragraph>
  )
}

const Detail = ({ type, value }) => (
  <Flex width={1} mb={2} mr={2}>
    <Text width="auto" mr={1}>
      {type}:
    </Text>
    <Text width="auto" fontWeight={700}>
      {value}
    </Text>
  </Flex>
)

const formatCompanyNameStr = companyName => {
  const nameLowerCaseKey = companyName
    .toLowerCase()
    .split(" ")
    .join("-")

  if (nameLowerCaseKey === "company-name") return ""
  return companyName
}

const JobSummary = ({
  role,
  companyName,
  city,
  location,
  jobType,
  salary,
  postedDate,
  expireAt,
  match,
  children,
  action,
  deleteCallback,
  showDetails,
  setShowDetails,
  isExternal
}) => {
  const companyNameStr = companyName?.length
    ? formatCompanyNameStr(companyName)
    : companyName
  return (
    <>
      <Flex
        justifyContent="space-between"
        p={3}
        bg={isExternal ? "dark.7" : "secondary.5"}
        borderRadius={4}
        alignItems="center"
      >
        <Flex width="auto" alignItems="center">
          <Text fontSize={4} color="dark.1" fontWeight={700} width="auto">
            {companyNameStr}
          </Text>
          {postedDate && (
            <Text color="dark.1" fontSize={4} width="auto" ml={[2, 6]} m={2}>
              {format(new Date(postedDate), "dd/MM/yyyy")}
            </Text>
          )}
        </Flex>
        {match && (
          <Text
            fontSize={[4, 5]}
            color="dark.0"
            width="auto"
            fontWeight={600}
            ml={4}
          >
            {match}% Match
          </Text>
        )}
      </Flex>

      <Box width={1} p={3}>
        <Flex flexDirection={["column", "column", "row"]} width={1 / 1}>
          <Box width={[1 / 1, 1 / 1, 3 / 4]}>
            <Flex width={1} flexDirection={["column", "row"]}>
              <Detail type="Location" value={city || location?.name} />
              <Detail
                type="Job Type"
                value={getTypeNameById(jobType) || jobType}
              />
            </Flex>

            <Flex width={1} flexDirection={["column", "row"]}>
              <Detail type="Job Role" value={role} />
              <Detail
                type="Salary"
                value={
                  salary?.private
                    ? "Competitive"
                    : `£${salary?.min} - £${salary?.max}`
                }
              />
            </Flex>
          </Box>
          <Flex width={1 / 4} flexDirection="column" ml={[0, 0, 3]}>
            {/* <StyledExpandButton
              elevation={0}
              minWidth="max-content"
              width="auto"
              onClick={() => setShowDetails(current => !current)}
              mb={2}
            >
              <Text
                color="primary.0"
                fontWeight={700}
                fontSize={3}
                m={1}
              >
                {showDetails ? "Hide" : "View"} Job Details
              </Text>
            </StyledExpandButton> */}
          </Flex>
          {action && (
            <Flex alignItems="center" width="auto">
              <IconButton
                icon={action.icon}
                size={20}
                onClick={action.handler}
              />
            </Flex>
          )}
          {deleteCallback && (
            <DeleteIcon
              name="delete"
              color="primary.0"
              mt={[2, 2, 1]}
              onClick={deleteCallback}
            />
          )}
        </Flex>

        {children}

        <ExpirationDate expireAt={expireAt} />
      </Box>
    </>
  )
}

export default JobSummary
