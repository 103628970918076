import { connect } from "react-redux"
import ManageSubscriptionModalComponent from "../../components/ProfileSettingsModal/ManageSubscriptionModal"
import * as yourProfileActions from "../../../../yourProfile/store/yourProfile.actions"
import * as yourProfileSelectors from "../../../../yourProfile/store/yourProfile.selectors"

const mapState = state => ({
  isPremium: yourProfileSelectors.getIsPremium(state),
  isPremiumCancelled: yourProfileSelectors.getIsPremiumCancelled(state),
  hasErrorPremium: yourProfileSelectors.getHasErrorPremium(state),
  premiumExpiration: yourProfileSelectors.getPremiumExpiration(state),
  isLoading: yourProfileSelectors.getIsLoading(state),
  hasError: yourProfileSelectors.getHasError(state),
  showModal: yourProfileSelectors.getShowModal(state)
})

const mapDispatch = dispatch => ({
  setVisibility: type => dispatch(yourProfileActions.setShowModal(type)),
  onSubmit: params =>
    dispatch(yourProfileActions.cancelPremiumSubscription(params)),
  resetErrors: () => dispatch(yourProfileActions.resetErrors())
})

const ManageSubscriptionModal = connect(
  mapState,
  mapDispatch
)(ManageSubscriptionModalComponent)

export default ManageSubscriptionModal
