import React from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
import { Text } from "@engaging-tech/components"
import { AccountSelectors } from "../../../account"

const ClickableText = styled(Text)`
  cursor: pointer;
`

const ReplyLink = ({ onClick }) => {
  const isAuthenticated = useSelector(AccountSelectors.getIsAuthenticated)

  return isAuthenticated ? (
    <ClickableText
      width="auto"
      color="primary.0"
      fontWeight="500"
      fontSize={[4, 3]}
      ml={[0, 3]}
      onClick={onClick}
    >
      Reply
    </ClickableText>
  ) : null
}

export default ReplyLink
