import { Flex } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import RankYourCompany from "../../features/happinessSurvey/containers/RankYourCompany"
import gradientCss from "../../helpers/gradientCss"

const GradientBox = styled(Flex)`
  ${({ theme }) => gradientCss(theme)}
`

export default function RankCompany() {
  return (
    <GradientBox as="main" flexDirection="column" minHeight="100vh">
      <Flex width={1 / 1} flex="1" alignItems="center" justifyContent="center">
        <RankYourCompany />
      </Flex>
    </GradientBox>
  )
}
