import { Box } from "@engaging-tech/components"
import styled from "styled-components"

export const SelectField = styled.select`
  width: 100%;
  position: relative;
  appearance: none;
  padding: 20px 42px 6px 16px;
  border-radius: 4px 4px 0 0;

  cursor: pointer;

  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.009375em;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);
  background-color: rgba(0, 0, 0, 0.04);
  outline: 0;
  text-overflow: ellipsis;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
    border-bottom: 1px solid rgba(0, 0, 0, 0.87);
  }

  &:focus {
    background-color: rgba(0, 0, 0, 0.08);
    border-bottom: 2px solid rgba(1, 62, 76, 1);
    transition: 0.15s;

    & + label {
      color: rgba(1, 62, 76, 0.6);
    }
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      border-bottom: 1px solid rgba(0, 0, 0, 0.38);
    }

    & + label {
      opacity: 0.6;
    }
  }
`

export const Wrapper = styled(Box)`
  position: relative;
  width: 100%;

  &:before {
    font-size: 16px;
    line-height: 1;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 20px;
    right: 16px;
    transition: 180ms;

    z-index: 1;
    pointer-events: none;
  }
`
