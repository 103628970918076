import { Card } from "@engaging-tech/components"
import styled from "styled-components"

const WidgetCard = styled(Card)`
  border-radius: 7px;
  position: relative;
  height: 100%;
`

export default WidgetCard
