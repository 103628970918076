import React from "react"

import { CountriesLoader, HobbiesLoader, IndustriesLoader, LanguagesLoader, SkillsLoader } from "../../misc"
import CVLibrary from "../containers/CVLibrary"

const CVLibraryView = () => (
  <>
    <SkillsLoader />
    <HobbiesLoader />
    <IndustriesLoader />
    <LanguagesLoader />
    <CountriesLoader />
    <CVLibrary />
  </>
)

export default CVLibraryView
