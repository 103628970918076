import { call, delay, put, takeLatest } from "redux-saga/effects"

import { load } from "../services/surveyResults.service"
import * as Actions from "./surveyResults.actions"

const resultsLifecycle = {
  pending: "pending",
  processed: "processed"
}

const RETRY_TIMEOUT = 2000

export function* loadSaga(action, retryTimeout = RETRY_TIMEOUT) {
  try {
    const res = yield call(load, action.payload)
    const results = res.data.getSurveyResults

    if (results.status === resultsLifecycle.processed) {
      yield put(Actions.loadSuccess(results))
    } else {
      yield delay(retryTimeout)
      yield put(Actions.retryLoad(action.payload))
    }
  } catch (e) {
    yield put(Actions.loadFail())
  }
}

export function* saga() {
  yield takeLatest([Actions.types.LOAD, Actions.types.LOAD_SUBMISSION, Actions.types.RETRY_LOAD], loadSaga)
}
