import { usePaths } from "@engaging-tech/routing"
import React from "react"

import SelectedPostContainer from "../../features/globalHub/containers/SelectedPost"
import TokenAuth from "../../features/misc/components/TokenAuth"
import BackBar from "../../features/ui/components/Backbar"
import AppViewBaseLayout from "../../layouts/AppViewBaseLayout"

const Job = () => {
  const paths = usePaths()
  return (
    <AppViewBaseLayout
      mx={3}
      mt={4}
      banner={() => <BackBar to={paths.engagingWorksApp.globalhub} actionName="back" />}
    >
      <TokenAuth />
      <SelectedPostContainer />
    </AppViewBaseLayout>
  )
}

export default Job
