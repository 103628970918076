import { userInterfaceNotificationsStore } from "@engaging-tech/components"
import { all, call, delay, put, select, takeLatest } from "redux-saga/effects"

import {
  getJobById,
  getJobCompanyRanking,
  getJobsFeed,
  getJobsFeedFilters
} from "../services/jobFinder.service"
import * as Actions from "./jobFinder.actions"
import { getFilters, getJobsNextToken } from "./jobFinder.selectors"

export function* loadSaga(action) {
  // console.log("loadSaga", { action })
  try {
    const filters = yield select(getFilters)
    const nextToken = yield select(getJobsNextToken)
    const filtersValuesUpdated = {}

    action.payload.forEach(item => {
      const filterInfo = filters?.find(filter => filter.id === item.name)

      if (item.value) {
        if (filterInfo && filterInfo.input === "integer" && item.value > 0) {
          filtersValuesUpdated[item.name] = item.value
        } else if (filterInfo && filterInfo.input === "array_string" && item.value?.length) {
          filtersValuesUpdated[item.name] = item.value
        } else if (item.name === "location" && item.value.length > 1) {
          filtersValuesUpdated[item.name] = [item.value]
        } else if (item.name === "search_term" || item.name === "company_name") {
          filtersValuesUpdated[item.name] = item.value
        } else if (item.name === "country_code") {
          filtersValuesUpdated.countryCode = item.value
        } else if (
          item.name === "demographicFilters" &&
          item.value?.length &&
          item.value.filter(i => i !== "0").length
        ) {
          filtersValuesUpdated.demographicFilters = item.value
        }
      }
    })

    if (filtersValuesUpdated?.company_name && filtersValuesUpdated?.search_term) {
      delete filtersValuesUpdated.search_term
    }

    let params = filtersValuesUpdated

    if (action.type === Actions.types.LOAD_MORE && nextToken) {
      params = { ...params, nextToken }
    }

    const res = yield call(getJobsFeed, params)

    const jobObj = res?.data?.getAllFeedByFilters

    const companyNameKeyList = []
    jobObj.items.forEach(item => {
      if (item?.company_name_key) {
        companyNameKeyList.push({ companyNameKey: item.company_name_key })
      }
    })

    if (action.type === Actions.types.LOAD_MORE) {
      yield put(Actions.loadMoreSuccess(jobObj))
      yield put(Actions.loadJobRankingsMore(companyNameKeyList))
    } else {
      yield put(Actions.loadSuccess(jobObj))
      yield put(Actions.selectJob(jobObj.items[0]))
      yield put(Actions.loadJobRankings(companyNameKeyList))
    }
  } catch (error) {
    if (action.type === Actions.types.LOAD_MORE) {
      yield put(Actions.loadMoreFail())
    } else {
      yield put(Actions.loadFail())
    }
    // yield put(
    //   userInterfaceNotificationsStore.actions.addNotification({
    //     message: "Error in loading Jobs"
    //   })
    // )
  }
}

export function* loadJobsFeedFiltersSaga() {
  try {
    const res = yield call(getJobsFeedFilters)

    yield put(Actions.loadFiltersSuccess(res.data.getJobStaticFilters))
  } catch {
    yield put(Actions.loadFiltersFail())
    // yield put(
    //   userInterfaceNotificationsStore.actions.addNotification({
    //     message: "Error in loading Jobs filters"
    //   })
    // )
  }
}

export function* loadJobSaga(action) {
  try {
    const res = yield call(getJobById, { id: action.payload })

    const jobObj = res?.data?.getJobById

    yield put(Actions.loadJobSuccess(jobObj))
  } catch (e) {
    yield put(Actions.loadJobFail())
    // yield put(
    //   userInterfaceNotificationsStore.actions.addNotification({
    //     message: "Error in loading Job"
    //   })
    // )
  }
}

function* loadCompanyRankingSaga(item) {
  try {
    const response = yield call(getJobCompanyRanking, { companyNameKey: item.companyNameKey })

    return response?.data?.getCompanyRanking
  } catch {
    return { companyNameKey: item.companyNameKey }
  }
}

export function* loadJobCompanyRankingSaga(action) {
  try {
    const allResult = yield all(action.payload.map(item => call(loadCompanyRankingSaga, item)))
    const data = yield allResult

    if (action.type === Actions.types.LOAD_JOBS_RANKINGS_MORE) {
      yield put(Actions.loadJobRankingsMoreSuccess(data))
    } else {
      yield put(Actions.loadJobRankingsSuccess(data))
    }
  } catch (error) {
    if (action.type === Actions.types.LOAD_JOBS_RANKINGS_MORE) {
      yield put(Actions.loadJobRankingsMoreFail())
    } else {
      yield put(Actions.loadJobRankingsFail())
    }
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Error in loading Job Company Rankings"
      })
    )
  }
}

export function* updateStatusBarSaga() {
  yield delay(300)
  yield put(Actions.updateProgressState("unActive"))
}

export function* saga() {
  yield takeLatest([Actions.types.LOAD, Actions.types.LOAD_MORE, Actions.types.FILTER], loadSaga)
  yield takeLatest(Actions.types.LOAD_FILTERS, loadJobsFeedFiltersSaga)
  yield takeLatest(Actions.types.LOAD_JOB, loadJobSaga)
  yield takeLatest(
    [Actions.types.LOAD_JOBS_RANKINGS, Actions.types.LOAD_JOBS_RANKINGS_MORE],
    loadJobCompanyRankingSaga
  )
  yield takeLatest(
    [Actions.types.LOAD_MORE_SUCCESS, Actions.types.LOAD_SUCCESS, Actions.types.LOAD_FAIL],
    updateStatusBarSaga
  )
}
