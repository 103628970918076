import React from "react"

import { Basket } from "../../../features/gardners"
import GlobalHubSideColumnLayout from "../../../layouts/GlobalHubSideColumnLayout"

const Index = () => (
  <GlobalHubSideColumnLayout sideColumn={() => <Basket.Total />} isSticky>
    <Basket.Books />
  </GlobalHubSideColumnLayout>
)

export default Index
