import { Spinner } from "@engaging-tech/components"
import { useParams } from "@engaging-tech/routing"
import React, { useEffect, useState } from "react"

import BookmarkToggle from "../../bookmarks/containers/BookmarkToggle"
import BillingInformation from "../../businessLibrary/components/Payment/BillingInformation"
import PurchasedDialog from "../../businessLibrary/components/Payment/PurchasedDialog"
import LibraryContent from "../../userGeneratedContent/components/BusinessLibrary/Content/Content"

const BookmarkedDetail = ({
  isLoading,
  post,
  onLoad,
  isAuthenticated,
  onSubmit,
  intentSecret,
  onPaymentActionSuccess,
  onPaymentActionFail
}) => {
  const [showBillingInfo, setShowBillingInfo] = useState(false)
  const [showPurchasedPopup, setShowPurchasedPopup] = useState(false)
  const { id: postId } = useParams()

  const handlePurchasedPopup = () => setShowPurchasedPopup(!showPurchasedPopup)

  useEffect(() => {
    onLoad(postId)
  }, [onLoad, postId])

  const onPurchase = () => {
    setShowBillingInfo(true)
  }

  const onPurchasedSuccess = () => {
    setShowBillingInfo(false)
    onPaymentActionSuccess()
    handlePurchasedPopup()
  }

  const onBack = () => {
    setShowBillingInfo(false)
  }

  if (isLoading) return <Spinner color="primary.0" justifyContent="center" />

  const paymentAction = intentSecret ? { action: "handle_card_payment", intentSecret } : null

  if (showBillingInfo) {
    return (
      <BillingInformation
        isLoading={isLoading}
        onBack={onBack}
        onSubmit={onSubmit}
        post={post}
        postId={post.id}
        paymentAction={paymentAction}
        onPaymentActionSuccess={onPurchasedSuccess}
        onPaymentActionFail={onPaymentActionFail}
      />
    )
  }

  return (
    <>
      <LibraryContent
        post={post}
        onPurchase={onPurchase}
        isAuthenticated={isAuthenticated}
        interactions={() => <BookmarkToggle id={post.id} postType={post.type} />}
      />

      {showPurchasedPopup && <PurchasedDialog handlePurchasedPopup={handlePurchasedPopup} />}
    </>
  )
}

export default BookmarkedDetail
