import {
  Button,
  Card,
  Flex,
  Paragraph,
  Select,
  Text
} from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"
import React, { useState } from "react"
import styled from "styled-components"
import PrimaryButton from "../../../ui/components/PrimaryButton"

const QuantitySelect = styled(Select)`
  width: auto;
  margin-bottom: 16px;

  select {
    width: auto;
    border-bottom: 0px solid red;
    font-size: 14px;
    padding: 15px 50px 15px 25px;
    :hover,
    :focus {
      border-bottom: 0px solid red;
    }
  }
`

const AddToBasket = ({ addToBasket, format, product }) => {
  const [quantity, setQuantity] = useState(1)
  const paths = usePaths()

  if (!product) return <Card mb={3} p={3} width={1 / 1} />
  return (
    <Card mb={3} p={3} width={1 / 1} bg="light.0">
      <Paragraph fontSize={4} fontWeight={700}>
        {product && product.price && `£${product.price.toFixed(2)}`}
        {format === "hardCover" ? " + postage" : ""}
      </Paragraph>
      <Paragraph fontSize={3} color="dark.2">
        <Link
          to={paths.getPremium.premiumFeatures}
          color="primary.0"
          fontWeight="500"
        >
          Premium
        </Link>{" "}
        members receive a 20% discount
      </Paragraph>

      <Flex justifyContent="space-between" alignItems="center">
        <Paragraph width="80%" fontSize={3} fontWeight="500" color="dark.2">
          Quantity:
        </Paragraph>
        <QuantitySelect
          name="quantity"
          id="quantity"
          onChange={e => setQuantity(e.target.value)}
          value={quantity}
          disabled={format !== "hardCover"}
        >
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
        </QuantitySelect>
      </Flex>

      <PrimaryButton
        mb={2}
        width={1 / 1}
        height={37}
        color="grey.0"
        onClick={() => addToBasket({ format, quantity })}
        disabled={!product.freeStock && format === "hardCover"}
        text={
          product.freeStock || format === "ebook"
            ? "ADD TO BASKET"
            : "OUT OF STOCK"
        }
      />
      <Link to={paths.resourceCentre.basket.index}>
        <Button elevation={0} bg="transparent" mx="auto" mt={2}>
          <Text
            fontWeight={700}
            textAlign="center"
            color="primary.0"
            fontSize={3}
          >
            Go To Basket
          </Text>
        </Button>
      </Link>
    </Card>
  )
}

export default AddToBasket
