import { useEffect } from "react"
import { connect } from "react-redux"

import { AccountActions } from "../../account"

const BootstrapComponent = ({ onLoad }) => {
  useEffect(() => {
    onLoad()
  }, [onLoad])

  return null
}

const mapDispatch = dispatch => ({
  onLoad: () => {
    dispatch(AccountActions.tokenSignIn())
  }
})

const Bootstrap = connect(null, mapDispatch)(BootstrapComponent)

export default Bootstrap
