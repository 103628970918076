import { Box, Button, Flex, H1, Icon, Modal, Paragraph, Text, Utils } from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"
import React from "react"
import { createPortal } from "react-dom"

import CircleIcon from "../../ui/components/CircleIcon"
import Separator from "../../ui/components/Separator"

const premiumFeatures = [
  {
    title: "Job Matcher Services",
    text: "Get automatically matched with a job of interest to you."
  },
  {
    title: "Mentor Matcher Service",
    text: "Our My Mentor service given you personalised advice to enjoy a happier working life"
  },
  {
    title: "Survey Results Action Plans",
    text: "Get comprehensive guidance in how to improve your low-scoring areas of your workplace happiness"
  },
  {
    title: "20% off all ebooks and audiobooks, monthly ezine and ebook (Coming soon)"
  }
]

const FeatureList = () =>
  premiumFeatures.map((feature, index) => (
    <Flex
      // eslint-disable-next-line react/no-array-index-key
      key={`${index}-${feature.text}`}
      alignItems="center"
      mb={3}
      flexDirection="column"
    >
      <Flex flexDirection="row" alignItems="center" mb={2}>
        <Icon name="done_outline" mr={2} color="primary.0" size={20} />
        {feature.title && (
          <Text color="dark.1" fontWeight="500" fontSize={3}>
            {feature.title}
          </Text>
        )}
      </Flex>
      {feature.text && (
        <Text color="dark.2" fontSize={2} fontWeight={300}>
          {feature.text}
        </Text>
      )}
    </Flex>
  ))

const determineTitle = type => {
  if (type === "event") return "Get Alerted with New Events"
  if (type === "job") return "Get Alerted with New Jobs"
  if (type === "Business Library") return "Get Alerted with New Business Library Content"
  return "Get Alerted with New Content"
}
const determineParagraph = type => {
  if (type === "event")
    return "Set up automatic customisable alerts for Events. Receive emails when new events are posted based on your criteria."
  if (type === "job")
    return "Set up automatic customisable alerts for Jobs. Receive emails when new jobs are posted based on your search criteria."
  if (type === "Business Library")
    return "Set up automatic customisable alerts for Business Library content. Receive emails when new content is uploaded to Business Library."
  return "Set up automatic customisable alerts for Events, Jobs and Business Library content. Receive emails when new content is uploaded."
}

const appRoot = () => typeof document !== "undefined" && document.getElementById("root")

const PremiumModal = ({ toggle, type }) => {
  const paths = usePaths()

  return createPortal(
    <Modal
      animated
      disableScrollbar
      bg="light.0"
      onToggle={toggle}
      maxWidth={580}
      width="90vw"
      flexDirection="column"
      alignItems="center"
      pb={1}
    >
      <Utils.DisableScroll />
      <Box py={3} width={1} bg="dark.7">
        <H1 fontSize={5} color="dark.1" fontWeight="700" mb={0} textAlign="center" fontFamily="Tiempos">
          {determineTitle(type)}
        </H1>
      </Box>
      <Box py={3} px={4} width={1} bg="light.0">
        <CircleIcon name="work" bg="secondary.0" mx="auto" mb={3} />
        <Paragraph fontSize={3} color="dark.1">
          {determineParagraph(type)}
        </Paragraph>
        <Separator width={1} lineColor="dark.5" />
        <Paragraph fontSize={3} color="dark.1" my={4}>
          With an WorkL Premium account, you will also get access to the following great services:
        </Paragraph>
        <FeatureList />
        <Text color="dark.2" fontSize={3} display="block" mb={3}>
          Enjoy a free 14 day trial then use our full range of Premium services for £9.99 a month
        </Text>
        <Text fontWeight="500" fontSize={3} display="block" mb={4} color="dark.2">
          This price includes VAT
        </Text>
        <Flex flexDirection="row-reverse">
          <Link to={paths.getPremium.index}>
            <Button variant="primary.0" ml={4} px={4}>
              Get premium
            </Button>
          </Link>

          <Button variant="light.0" onClick={toggle} elevation={0}>
            Cancel
          </Button>
        </Flex>
      </Box>
    </Modal>,
    appRoot()
  )
}

export default PremiumModal
