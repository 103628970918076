import { useEffect } from "react"
import { connect } from "react-redux"

import { load } from "../store/surveyResults.actions"

const LoaderComponent = ({ onLoad }) => {
  useEffect(() => {
    onLoad()
  }, [onLoad])

  return null
}

const mapDispatch = (dispatch, props) => ({
  onLoad: () => {
    dispatch(load(props.submissionId))
  }
})

const Loader = connect(null, mapDispatch)(LoaderComponent)

export default Loader
