import * as y from "yup"

const schemaForm = y.object().shape({
  firstName: y.string("Please enter your first name").required("A first name is required"),
  lastName: y.string("Please enter your surname").required("A surname is required"),
  location: y.object().shape({
    name: y
      .string("Please enter your Town, City or Country your located in")
      .required("A Location is required")
  }),
  email: y.string().email("Please enter a valid email address").required("A valid email address is required"),
  password: y
    .string()
    .required("A password is required")
    .min(8, "At least 8 characters")
    .matches(/[0-9]|[^a-zA-Z\d\s:\u00C0-\u00FF]/, "A number or special character is required"),
  confirmPassword: y
    .string()
    .oneOf([y.ref("password")], "Passwords must match")
    .required("Please confirm your password"),
  allowMarketing: y.boolean(),
  acceptConditions: y.boolean().oneOf([true], "Terms and conditions must be accepted").required()
})

export default schemaForm
