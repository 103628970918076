import styled from "styled-components"
import SingleKnobSliderTrack from "./SingleKnobSliderTrack"

const DoubleKnobSliderTrack = styled(SingleKnobSliderTrack)`
  /* This gradient contains a calculation for the slider track. */
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.secondary[2]} 0%,
    ${({ theme }) => theme.colors.secondary[2]}
      ${({ knob1Position, trackWidth }) =>
        Number(100 * (knob1Position / trackWidth)).toFixed()}%,
    ${({ theme }) => theme.colors.secondary[0]}
      ${({ knob1Position, trackWidth }) =>
        Number(100 * (knob1Position / trackWidth)).toFixed()}%,
    ${({ theme }) => theme.colors.secondary[0]}
      ${({ knob2Position, trackWidth }) =>
        Number(100 * (knob2Position / trackWidth)).toFixed()}%,
    ${({ theme }) => theme.colors.secondary[2]}
      ${({ knob2Position, trackWidth }) =>
        Number(100 * (knob2Position / trackWidth)).toFixed()}%
  );
`
export default DoubleKnobSliderTrack
