import { connect } from "react-redux"

import { getMatricesChildrenLayout } from "../../store/surveyResults.selectors"
import MatricesComponent from "../components/Matrices"

const mapState = (state, {isolated = true, showAction = true}) => ({
  matricesLayout: getMatricesChildrenLayout(state),
  showShadow: !isolated,
  showAction: !isolated
})

const Matrices = connect(mapState)(MatricesComponent)

export default Matrices
