import React from "react"
import { Text } from "@engaging-tech/components"

import SurveyCard from "./SurveyCard"

const CustomIntro = ({ title, content, onNext, onPrevious }) => (
  <SurveyCard title={title} onNext={onNext} onPrevious={onPrevious}>
    {typeof content === "string" ? (
      <Text>{content}</Text>
    ) : (
      content.map((item, index) => (
        <Text
          key={item.content}
          mb={index === content.length - 1 ? 0 : 3}
          display="block"
        >
          {item.content}
        </Text>
      ))
    )}
  </SurveyCard>
)

export default CustomIntro
