import React from "react"
import styled from "styled-components"
import { Dialog, Text, Flex, Box } from "@engaging-tech/components"

import ExternalBadges from "./ExternalBadges"

const DialogTextBox = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.colors.dark[4]};
`

const DigitalBookDialog = ({ book, onDownload, handleDownloadPopup }) => {
  const headerText =
    book.type === "ebook"
      ? "Before you download your eBook, have you downloaded Adobe Digital Editions?"
      : "Before you download your audiobook, have you downloaded iTunes or other MP3 players?"

  const bodyText =
    book.type === "ebook"
      ? "You must download Adobe Digital Editions to read your ebook"
      : "You must download iTunes (or other MP3 players) to play your audiobook"

  return (
    <Dialog
      onToggle={handleDownloadPopup}
      header={headerText}
      footer={[
        {
          label: "YES",
          action: () => [
            handleDownloadPopup(),
            onDownload(),
            typeof window !== "undefined" && window.open(book.downloadLink)
          ]
        },
        {
          label: "CANCEL",
          action: handleDownloadPopup
        }
      ]}
    >
      <DialogTextBox mt={3}>
        <Flex p={3} flexDirection="column" alignItems="center">
          <Text fontSize={4} color="dark.2">
            {bodyText}
          </Text>
          {book.type === "ebook" ? (
            <ExternalBadges
              appleUrl="https://apps.apple.com/us/app/adobe-digital-editions/id952977781"
              googleUrl="https://play.google.com/store/apps/details?id=com.adobe.digitaleditions"
              desktopUrl="https://www.adobe.com/solutions/ebook/digital-editions/download.html"
              mt={3}
              alignItems="flex-start"
              justifyContent="flex-start"
              alignment="flex-start"
            />
          ) : (
            <ExternalBadges
              appleUrl="https://www.apple.com/uk/itunes/"
              googleUrl="https://play.google.com/store/apps/details?id=com.apple.android.music&referrer=utm_source=http%3A%2F%2Fitunes.apple.com%2Fsubscribe%3Fapp%3Dmusic%26at%3D1000lpya%26ct%3D"
              desktopUrl="https://www.apple.com/uk/itunes/download/"
              mt={3}
              alignItems="flex-start"
              justifyContent="flex-start"
              alignment="flex-start"
            />
          )}
        </Flex>
      </DialogTextBox>
    </Dialog>
  )
}

export default DigitalBookDialog
