import React from "react"

import SideBar from "../../features/globalHub/components/SideBar/SideBar"
import CreatePost from "../../features/globalHub/containers/CreatePost"
import HubNav from "../../features/globalHub/containers/HubNav"
import PostList from "../../features/globalHub/containers/PostList"
import TopBar from "../../features/globalHub/containers/TopBar"
import GlobalHubSideColumnLayout from "../../layouts/GlobalHubSideColumnLayout"

const Feed = () => (
  <GlobalHubSideColumnLayout isSticky sideColumn={SideBar} topRow={TopBar} banner={HubNav}>
    <CreatePost />
    <PostList />
  </GlobalHubSideColumnLayout>
)

export default Feed
