import React from "react"
import styled from "styled-components"
import { Link } from "@engaging-tech/routing"
import { Flex, Text, Card, Box } from "@engaging-tech/components"
import IconCircle from "../../ui/components/CircleIcon"

const previewImage = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/happiness-survey-preview.png`)

const StyledImage = styled.img`
  margin-top: 10%;
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}px) {
    width: 60%;
  }
  @media (max-width: 560px) {
    width: 80%;
  }
`

const benefits = [
  {
    icon: "poll",
    heading: "Get In-depth Analysis",
    description: (
      <Text color="dark.2" fontWeight={500} fontSize={3}>
        View graphs that analyse your position compared to your global and
        industry peers.
      </Text>
    )
  },
  {
    icon: "trending_up",
    heading: "Track and Measure your progress",
    description: (
      <Text color="dark.2" fontWeight={500} fontSize={3}>
        Take the Workplace Happiness Survey regularly to track your progress and
        check that your score is improving.
      </Text>
    )
  },
  {
    icon: "assignment",
    heading: "Improve your scores with our action plans",
    description: (
      <Text color="dark.2" fontWeight={500} fontSize={3}>
        Sign up to our{" "}
        <Link to="/premium" color="primary.0">
          Premium Service
        </Link>{" "}
        to see action plans for each of the{" "}
        <Link to="/" color="primary.0">
          Six Steps
        </Link>
        . These help you improve you low-scoring areas by giving you actionable
        points to work on.
      </Text>
    )
  }
]

const Benefit = ({ icon, heading, description: Description }) => (
  <Box mt={4}>
    <Flex alignItems="center" mb={3}>
      <IconCircle name={icon} bg="rgba(0, 225, 255, 0.5)" />
      <Text fontSize={5} fontWeight={500} ml={2}>
        {heading}
      </Text>
    </Flex>
    {Description}
  </Box>
)

const EmptyState = () => (
  <Flex justifyContent="center" width={1 / 1}>
    <Card
      bg="dark.7"
      elevation={0}
      py={5}
      px={[3, 6]}
      alignItems="center"
      maxWidth={1000}
    >
      <Text
        color="dark.2"
        fontWeight={500}
        textAlign="center"
        width="auto"
        maxWidth={500}
      >
        Track, measure and improve your workplace happiness with our
        comprehensive range of surveys
      </Text>
      <Flex flexDirection={["column", "row"]}>
        <Flex flexDirection="column" flex={1}>
          {benefits.map(benefit => (
            <Benefit key={benefit.heading} {...benefit} />
          ))}
        </Flex>
        <Flex
          flex={1}
          width={[1 / 1, "auto"]}
          mt={4}
          justifyContent={["center", "flex-end"]}
        >
          <StyledImage
            alt="Happiness Survey Preview"
            src={previewImage}
            loading="lazy"
          />
        </Flex>
      </Flex>
    </Card>
  </Flex>
)

export default EmptyState
