import { Button, Modal } from "@engaging-tech/components"
import React from "react"
import { createPortal } from "react-dom"

import ChartsHandler from "./charts-handler"

const appRoot = () => typeof document !== "undefined" && document.getElementById("root")

export default function ModalChartViewer({ chartData, onToggle, graphDescription = "", ...props }) {
  return createPortal(
    <Modal
      bg="light.0"
      onToggle={onToggle}
      flexDirection="column"
      disableScrollbar
      animated
      maxWidth={700}
      width="80%"
      alignItems="center"
      {...props}
    >
      <ChartsHandler
        disableAnimationTrigger
        chartData={chartData}
        mb={0}
        height={[400, 450, 500]}
        elevation={0}
        width="100%"
        graphDescription={graphDescription}
      />
      <Button variant="primary.0" onClick={onToggle} my={4} p={3} width={80}>
        Close
      </Button>
    </Modal>,
    appRoot()
  )
}
