import { connect } from "react-redux"

import * as Actions from "../store/premium.actions"
import * as Selectors from "../store/premium.selectors"
import SignUpComparison from "../views/SignUpComparison"

const PremiumProductsList = () => {
  const mapState = state => ({
    products: Selectors.getProducts(state),
    isLoading: Selectors.getIsLoading(state),
    stripeCheckoutUrl: Selectors.getStripeCheckoutUrl(state),
    stripeCheckoutIsLoading: Selectors.getStripeCheckoutIsLoading(state),
    stripeCheckoutHasLoaded: Selectors.getStripeCheckoutHasLoaded(state)
  })

  const mapDispatch = dispatch => ({
    onLoad: () => dispatch(Actions.load()),
    onSuccess: () => dispatch(Actions.loadSuccess()),
    onFail: () => dispatch(Actions.loadFail()),
    handleStripeCheckout: stripePriceId => dispatch(Actions.stripeCheckout(stripePriceId))
  })

  return connect(mapState, mapDispatch)(SignUpComparison)
}

export default PremiumProductsList
