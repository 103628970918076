import React from "react"

import { ResetPassword as ResetPasswordView } from "../../features/account"
import SiteLogo from "../../features/ui/components/SiteLogo"
import GradientLayout from "../../layouts/GradientLayout"

export default function ResetPassword() {
  return (
    <GradientLayout>
      <ResetPasswordView siteLogo={SiteLogo} cancelRoute="/" redirectTo="/account/sign-in" />
    </GradientLayout>
  )
}
