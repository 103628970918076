import { useEffect } from "react"
import { connect } from "react-redux"
import { GlobalHubActions } from ".."
import { yourProfileSelectors } from "../../yourProfile"

const LoaderComponent = ({ onLoad, userId }) => {
  useEffect(() => {
    if (userId) onLoad({ type: "advice", userId })
  }, [onLoad, userId])
  return null
}

const mapState = state => ({
  userId: yourProfileSelectors.getUserId(state)
})

const mapDispatch = dispatch => ({
  onLoad: (type, userId) =>
    dispatch(GlobalHubActions.loadUsersAdvicePosts(type, userId))
})

const UsersAdviceLoader = connect(mapState, mapDispatch)(LoaderComponent)

export default UsersAdviceLoader
