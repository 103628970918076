import React from "react"
import { useSelector } from "react-redux"

import { getIsLoading, getJobDetailsIsLoading } from "../../../store/jobFinder.selectors"
import { JobDetailContainer } from "./jobDetail.styled"
import JobDetailBody from "./jobDetailBody"
import JobDetailHeader from "./jobDetailHeader"

const JobDetail = ({ fullScreen = false, job }) => {
  const isLoading = useSelector(getIsLoading)
  const isJobLoading = useSelector(getJobDetailsIsLoading)

  if (job && !isLoading && !isJobLoading) {
    return (
      <JobDetailContainer fullScreen={fullScreen}>
        <JobDetailHeader job={job} />

        <JobDetailBody job={job} />
      </JobDetailContainer>
    )
  }

  return null
}

export default JobDetail
