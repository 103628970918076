import { connect } from "react-redux"
import ServicesToggleComponent from "../components/ServicesToggle"
import { toggleServices } from "../../../yourProfile/store/yourProfile.actions"
import {
  getServices,
  getJobMatcherProfileComplete,
  getIsPremium
} from "../../../yourProfile/store/yourProfile.selectors"

const mapState = state => ({
  isPremium: getIsPremium(state),
  services: getServices(state),
  jobMatcherProfileComplete: getJobMatcherProfileComplete(state)
})

const mapDispatch = dispatch => ({
  updateServices: services => dispatch(toggleServices(services))
})

const ServicesToggle = connect(mapState, mapDispatch)(ServicesToggleComponent)

export default ServicesToggle
