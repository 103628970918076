import { useEffect } from "react"
import { connect } from "react-redux"
import {
  getAllSkills,
  getHasLoaded,
  getIsLoading
} from "../store/misc.selectors"
import { loadSkills } from "../store/misc.actions"

const SkillsLoaderComponent = ({ allSkills, load, loading, hasLoaded }) => {
  useEffect(() => {
    if (loading) return
    if (hasLoaded && !allSkills.length) {
      setTimeout(loadSkills, 1000)
    } else if (!allSkills.length) {
      load()
    }
  }, [allSkills, hasLoaded, load, loading])

  return null
}

const mapState = state => ({
  allSkills: getAllSkills(state),
  loading: getIsLoading(state, "Skills"),
  hasLoaded: getHasLoaded(state, "Skills")
})

const mapDispatch = dispatch => ({
  load: () => dispatch(loadSkills())
})

const SkillsLoader = connect(mapState, mapDispatch)(SkillsLoaderComponent)

export default SkillsLoader
