import React from "react"
import styled from "styled-components"
import { Flex, Box, List, Paragraph, Text } from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"

import ContentBlock from "./ContentBlock"

/* eslint-disable-next-line import/no-dynamic-require */
const image = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/foundation.jpg`)

const Image = styled.img`
  width: 100%;
  height: 100%;
`

const EmailLink = styled.a`
  color: ${({ theme }) => theme.colors.primary[0]};
`

const EngagingWorksFoundation = () => {
  const paths = usePaths()
  return (
    <ContentBlock title="WorkLFoundation">
      <Flex
        flexDirection={["column", "row"]}
        alignItems={["center", "flex-start"]}
      >
        <Box width={[1 / 1, 1 / 2]} mr={[0, 5]} mb={[5, 0]}>
          <Image src={image} />
        </Box>
        <Box width={[1 / 1, 1 / 2]}>
          <Paragraph color="dark.2" width="auto">
            The WorkL Foundation has a focus on helping those who might struggle
            get into work. To learn more about the foundation and the research
            we do, please email{" "}
            <EmailLink href="mailto:foundation@engaging.works">
              foundation@engaging.works
            </EmailLink>
          </Paragraph>
          <Paragraph width="auto" color="dark.2">
            The foundation’s work is split into three areas:
          </Paragraph>
          <List.Wrapper>
            <List.Item color="dark.2">
              Research into the business, societal and economic benefits of
              happy and engaged employees.
            </List.Item>
            <List.Item>
              <Text color="dark.2">
                To match mentors with mentees who need support.{" "}
                <Link
                  to={paths.careerDeveloper.mentorMatcher}
                  color="primary.0"
                >
                  Sign up to become a mentor.
                </Link>
              </Text>
            </List.Item>
            <List.Item color="dark.2">
              To match volunteers with those who need help completing CVs, and
              finding and applying for the right job.
            </List.Item>
          </List.Wrapper>
        </Box>
      </Flex>
    </ContentBlock>
  )
}

export default EngagingWorksFoundation
