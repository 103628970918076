import styled from "styled-components"
import { Box } from "@engaging-tech/components"

const BodyWrapper = styled(Box)`
  & > *:last-child {
    margin-bottom: 0;
  }
`

export default BodyWrapper
