import { getConfig } from "@engaging-tech/ssr-config"
import { apollo } from "../../../lib/Apollo"

import {
  LOAD_SERIES,
  CREATE_MATERIAL,
  CREATE_SERIES,
  CREATE_PURCHASE_MATERIAL_INTENT
} from "./userContent.queries"

const GRAPHQL_API_URL = () =>
  `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

/**
 * Fetches all business library series belonging to a user.
 * @param {string} userId The ID of the currently logged in user.
 */
export const loadSeries = userId =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD_SERIES,
    variables: { userId }
  })

/**
 * Creates a new business library material. Used by the content publishing forms
 * to save user-generated content.
 * @param {object} newMaterial The new material to be published to the business library.
 */
export const createMaterial = newMaterial =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: CREATE_MATERIAL,
    variables: newMaterial
  })

/**
 * Creates a new business library series.
 * @param {object} newSeries The new series to be created.
 */
export const createSeries = newSeries =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: CREATE_SERIES,
    variables: newSeries
  })
/**
 * Trigers the creation of a payment intent for the specified material
 * @param {string} materialId
 */

export const createPurchaseMaterialIntent = materialId =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: CREATE_PURCHASE_MATERIAL_INTENT,
    variables: { id: materialId }
  })
