import { gql } from "../../../lib/Apollo"

import ITEMS_TO_LOAD from "../lib/itemsToLoad"

export const LOAD = gql`
  query($from: Int, $type: String, $searchTerm: String, $categoryId: ID) {
    AllMaterials(type: $type, limit: ${ITEMS_TO_LOAD}, skip: $from, searchTerm: $searchTerm, categoryId: $categoryId) {
      id
      title
      categories
      publishedAt
      coverImage {
        source
        caption
      }
      summary
      type
      user {
        id
          firstName
        lastName
          jobTitle
          picture {
            small
          }
      }
      series {
        title
      }
      access {
        type
        price
      }
    }
  }
`

export const LOAD_BY_ID = gql`
  query($id: ID!) {
    AllMaterials(id: $id) {
      title
      id
      summary
      type
      publishedAt
      coverImage {
        source
        caption
      }
      content {
        type
        source
        body
      }
      user {
        id
        firstName
        lastName
        jobTitle
        picture {
          small
        }
      }
      attachments {
        fileType
        name
      }
      series {
        title
      }
      access {
        type
        price
      }
    }
  }
`

export const LOAD_FEATURED = gql`
  query {
    AllMaterials(promote: true) {
      id
      type
      title
      publishedAt
      coverImage {
        source
        caption
      }
      user {
        id
        firstName
        lastName
        jobTitle
        picture {
          small
        }
      }
      series {
        title
      }
    }
  }
`
