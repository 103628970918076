import { connect } from "react-redux"

import { AccountSelectors } from "../../account"
import { yourProfileSelectors } from "../../yourProfile"
import Form from "../forms/createCommentForm"
import { comment } from "../store/globalHub.actions"
import { getIsCommenting } from "../store/globalHub.selectors"

const mapState = state => ({
  user: yourProfileSelectors.getProfile(state),
  isAuthenticated: AccountSelectors.getIsAuthenticated(state),
  isCommenting: getIsCommenting(state)
})

const mapDispatch = dispatch => ({
  onSubmit: newComment => dispatch(comment(newComment))
})

const CreateComment = connect(mapState, mapDispatch)(Form)

export default CreateComment
