import React from "react"
import { Flex, Text } from "@engaging-tech/components"
import styled from "styled-components"

const StyledFlex = styled(Flex)`
  border-radius: 4px 4px 0 0;
`

const ComparisonBanner = () => (
  <StyledFlex
    flexDirection="column"
    bg="tertiary.0"
    pt={4}
    pb={5}
    borderRadius={10}
  >
    <Text
      textAlign="center"
      fontSize={[4, "22px"]}
      fontWeight={700}
      color="primary.0"
    >
      Join hundreds of thousands of WorkLers to have a better working life
    </Text>
    <Text textAlign="center" fontSize={[3, "18px"]} mt={2} color="primary.0">
      Choose the best option for you to begin your journey
    </Text>
  </StyledFlex>
)

export default ComparisonBanner
