import React from "react"
import { Flex, Text } from "@engaging-tech/components"
import WidgetCard from "../../components/WidgetCard"
import trainingCentreVideos from "../lib/trainingCentreVideos"
import trainingCentreLinks from "../lib/trainingCentreLinks"
import TrainingVideo from "./TrainingVideo"
import TrainingLink from "./TrainingLink"
import DynamicState from "../../containers/DynamicState"

const TrainingCentreWidget = ({ id }) => {
  return (
    <WidgetCard bg="light.0" width="100%" height="100%">
      <DynamicState id={id} removable title="Training Centre">
        <Flex flexDirection="column">
          <Text fontSize="16px" ml={3}>
            Links
          </Text>
          <Flex flexWrap="wrap" justifyContent="space-evenly">
            {trainingCentreLinks.map(linkInfo => (
              <TrainingLink
                key={linkInfo.url}
                url={linkInfo.url}
                thumbnailSrc={linkInfo.thumbnailSrc}
                title={linkInfo.title}
              />
            ))}
          </Flex>
        </Flex>
        <Flex flexDirection="column">
          <Text fontSize="16px" ml={3}>
            Videos
          </Text>
          <Flex flexWrap="wrap" justifyContent="space-evenly">
            {trainingCentreVideos.map(videoInfo => (
              <TrainingVideo videoInfo={videoInfo} key={videoInfo.url} />
            ))}
          </Flex>
        </Flex>
      </DynamicState>
    </WidgetCard>
  )
}

export default TrainingCentreWidget
