import { Box, Flex } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"
import styled from "styled-components"

import { ConfirmBanner } from "../features/account"
import HappinessWidget from "../features/rankings/containers/HappinessWidget"
import Footer from "../features/ui/components/Footer"
import SiteBanner from "../features/ui/components/SiteBanner"
import TestingSiteBanner from "../features/ui/components/TestingSiteBanner"
import Nav from "../features/ui/components/nav"
import AuthGuard from "../features/ui/containers/AuthGuard"

const Wrapper = styled(Flex)`
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
`

export default function HappiestLayout({
  before: Before,
  banner: Banner,
  children,
  bg = "transparent",
  maxWidth = 1280,
  banner,
  isPublic,
  ...props
}) {
  const { websiteInfoBanner } = getConfig()

  if (isPublic) {
    return (
      <>
        <TestingSiteBanner />
        {websiteInfoBanner && <SiteBanner text={websiteInfoBanner} disableClose icon="warning" />}
        {Before && <Before />}
        <Nav />
        <ConfirmBanner />
        <HappinessWidget />
        {Banner && <Banner />}
        <Box as="main" bg={bg} banner={banner || null} px={[0, 4]} py={5} isPublic={isPublic}>
          <Wrapper justifyContent="center" mx="auto" maxWidth={maxWidth} {...props}>
            {children}
          </Wrapper>
        </Box>
        <Footer />
      </>
    )
  }

  return (
    <AuthGuard>
      <TestingSiteBanner />
      {Before && <Before />}
      <Nav />
      <ConfirmBanner />
      <HappinessWidget />
      {Banner && <Banner />}
      <Box as="main" bg={bg} banner={banner || null} px={[3, 3, 4]} py={5} isPublic={isPublic}>
        <Wrapper justifyContent="center" mx="auto" maxWidth={maxWidth} {...props}>
          {children}
        </Wrapper>
      </Box>
      <Footer />
    </AuthGuard>
  )
}
