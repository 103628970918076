import { connect } from "react-redux"
import { getStep, getQuestionsLength } from "../store/selectors"

import StepResultComponent from "../../HappinessSixSteps/components/StepResult"

const mapState = (state, { section }) => ({
  step: getStep(state, section),
  numQuestions: getQuestionsLength(state, section)
})

const StepResult = connect(mapState)(StepResultComponent)

export default StepResult
