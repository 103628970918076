import { Modal, CustomHooks } from "@engaging-tech/components"
import React from "react"
import { createPortal } from "react-dom"
import ManageAlerts from "./ManageAlerts"
import EditAlert from "../containers/EditAlert"
import DeleteAlert from "../containers/DeleteAlert"

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const ManageAlertsModal = ({
  toggle,
  modalType,
  setModalType,
  initialNavItem = "Jobs"
}) => {
  CustomHooks.useFreshChatDeactivate()

  return createPortal(
    <Modal
      animated
      disableScrollbar
      bg="light.0"
      onToggle={toggle}
      maxWidth={modalType === "manage" ? 640 : 386}
      maxHeight="80vh"
      mb={100}
      width={modalType === "manage" ? "90vw" : 386}
      height="auto"
      flexDirection="column"
      alignItems="center"
      px={4}
      py={3}
    >
      {modalType === "manage" && (
        <ManageAlerts
          toggle={toggle}
          setModalType={setModalType}
          initialNavItem={initialNavItem}
        />
      )}

      {modalType === "edit" && (
        <EditAlert toggle={toggle} setModalType={setModalType} />
      )}

      {modalType === "delete" && (
        <DeleteAlert toggle={toggle} setModalType={setModalType} />
      )}
    </Modal>,
    appRoot()
  )
}

export default ManageAlertsModal
