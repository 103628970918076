import { Button, Flex } from "@engaging-tech/components"
import styled from "styled-components"

export const FilterWrapper = styled(Flex)`
  height: auto;
  min-height: 145px;

  flex-direction: column;
  align-items: center;
`

export const FilterContent = styled.div`
  width: 100%;
  max-width: 1080px;

  display: flex;
  flex-direction: column;
`

export const FiltersContainer = styled(Flex)`
  width: auto;
  height: auto;

  justify-content: space-between;

  padding-top: 24px;

  margin: 0 16px;

  div:not(:last-child) {
    margin-right: 12px;
  }

  @media (max-width: 1080px) {
    justify-content: center;
    flex-wrap: wrap;
    flex: 1;

    > div {
      margin-bottom: 7px;
    }
  }

  @media (max-width: 580px) {
    div:not(:last-child) {
      margin-right: ${({ dynamic }) => (dynamic ? "8px" : "0")};
    }

    flex-direction: ${({ dynamic }) => (dynamic ? "row" : "column")};
    align-items: center;
  }
`

export const FiltersContent = styled(Flex)`
  @media (max-width: 1080px) {
    justify-content: center;
    flex-wrap: wrap;
    flex: 1;
  }
`

export const ClearAllButton = styled(Button)`
  border-style: solid;
  border-width: 2px;
  border-radius: 4px;

  &:hover {
    transform: translateY(0.5px);
  }

  font-size: 14px;
  font-weight: 700;
`
