import { types } from "./premium.actions"

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  hasError: false,
  products: [],
  stripeCheckoutIsLoading: false,
  stripeCheckoutHasLoaded: false,
  stripeCheckoutHasError: false,
  stripeCheckoutUrl: ""
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD:
      return { ...state, isLoading: true, hasError: false }
    case types.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasLoaded: true,
        products: action.payload
      }
    case types.LOAD_FAIL:
      return { ...state, isLoading: false, hasError: true }
    case types.STRIPE_CHECKOUT:
      return {
        ...state,
        stripeCheckoutIsLoading: true,
        stripeCheckoutHasError: false
      }
    case types.STRIPE_CHECKOUT_SUCCESS:
      return {
        ...state,
        stripeCheckoutIsLoading: false,
        stripeCheckoutHasLoaded: true,
        stripeCheckoutUrl: action.payload
      }
    case types.STRIPE_CHECKOUT_FAIL:
      return {
        ...state,
        stripeCheckoutIsLoading: false,
        stripeCheckoutHasError: true
      }
    default:
      return state
  }
}
