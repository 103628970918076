import { Button, Flex, Modal, MotionFlex, Text } from "@engaging-tech/components"
import { AnimatePresence } from "framer-motion"
import React from "react"
import { createPortal } from "react-dom"
import styled from "styled-components"

const appRoot = () => typeof document !== "undefined" && document.getElementById("root")

const PopupBanner = styled(Flex)`
  border-radius: 6px 6px 0px 0px;
`

const NotifiedOverlay = ({ showNotifiedOverlay, setShowNotifiedOverlay }) => {
  return createPortal(
    <AnimatePresence>
      {showNotifiedOverlay && (
        <MotionFlex
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.1
          }}
        >
          <Modal
            onToggle={() => setShowNotifiedOverlay(false)}
            bg="light.0"
            width={1 / 1}
            height="auto"
            maxWidth={580}
            flexDirection="column"
            alignItems="center"
            alignContent="center"
          >
            <PopupBanner
              bg="tertiary.0"
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              width={1 / 1}
              height={60}
            >
              <Text color="primary.0" fontFamily="Tiempos" fontSize={5} width="auto">
                The Employer Has Been Notified
              </Text>
            </PopupBanner>
            <Flex
              justifyContent="space-between"
              mt={5}
              mb={5}
              height="auto"
              width={19 / 20}
              flexDirection="column"
              alignItems="center"
              alignContent="center"
            >
              <Text color="dark.0" fontSize={4} mb={2} mt={2} textAlign="center">
                Awesome! You just took your first step to being matched with a job.
              </Text>
              <Text color="dark.0" fontSize={4} mb={2} mt={2} textAlign="center">
                What happens now?
              </Text>
              <Text color="dark.0" fontSize={4} mb={2} mt={2} textAlign="center">
                The employer will receive an email with your details, and will get in touch if interested in
                your profile.
              </Text>
            </Flex>
            <Button
              bg="secondary.0"
              elevation={0}
              width={168}
              onClick={() => setShowNotifiedOverlay(false)}
              mb={3}
            >
              <Text color="primary.0" fontWeight={700} fontSize={3} m={1}>
                DONE
              </Text>
            </Button>
          </Modal>
        </MotionFlex>
      )}
    </AnimatePresence>,
    appRoot()
  )
}

export default NotifiedOverlay
