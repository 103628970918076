import { Text } from "@engaging-tech/components"
import React from "react"

import ComparisonBarChart from "./common/comparison-bar-chart"
import WidgetContainer from "./widget-container"

export default function IndustryGlobalComp({ happinessScores, company, ...props }) {
  return (
    <WidgetContainer
      mb={4}
      width={["98%", "58%"]}
      title="Industry & Global Comparison"
      height={375}
      {...props}
    >
      {happinessScores && (
        <ComparisonBarChart
          noContainer
          dataSet={{
            scores: [
              {
                label: company,
                values: {
                  data: Math.round(happinessScores.score)
                }
              },
              {
                label: "Global",
                values: {
                  data: Math.round(happinessScores.global)
                }
              },
              {
                label: "Industry",
                values: {
                  data: Math.round(happinessScores.industry)
                }
              }
            ]
          }}
          unit="%"
        />
      )}
      <Text fontSize={[2, 3]} color="dark.2" width="100%" textAlign="center">
        Comparison of the companies happiness score against and industry & globally.
      </Text>
    </WidgetContainer>
  )
}
