import styled from "styled-components"

// eslint-disable-next-line import/prefer-default-export
export const Content = styled.div`
  max-width: 1080px;
  width: 100%;

  display: flex;
  flex-direction: column;
`
