import React from "react"
import styled, { keyframes } from "styled-components"
import { Text } from "@engaging-tech/components"

const ANIMATION_DURATION = 1

const FadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const AnimatedText = styled(Text)`
  animation: ${FadeIn};
  animation-duration: ${ANIMATION_DURATION}s;
`

const LoadingText = ({ children }) => {
  return (
    <AnimatedText
      textAlign="center"
      fontSize={[3, 5]}
      color="dark.1"
      mt={16}
      fontWeight="500"
    >
      {children}
    </AnimatedText>
  )
}

export default LoadingText
