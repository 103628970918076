const reportPostMetadata = [
  {
    key: "bullying-or-harassment",
    label: "Bullying or harassment"
  },
  {
    key: "spam-or-platform-exploitation",
    label: "Spam or Platform exploitation"
  },
  {
    key: "hate-speech",
    label: "Hate Speech"
  },
  {
    key: "false-information",
    label: "False information"
  },
  {
    key: "sexual-content",
    label: "Sexual content"
  },
  {
    key: "impersonating-someone",
    label: "Impersonating someone"
  },
  {
    key: "suicide-or-self-injury",
    label: "Suicide or self-injury"
  },
  {
    key: "intellectual-property-violation",
    label: "Intellectual property violation"
  },
  {
    key: "i-just-dont-like-this-post",
    label: "I just dont like this post"
  },
  {
    key: "other",
    label: "Other"
  }
]

const reportUserMetadata = [
  {
    key: "abusive-language",
    label: "Abusive language"
  },
  {
    key: "spam",
    label: "Spam"
  },
  {
    key: "fake-profile",
    label: "Fake profile"
  },
  {
    key: "inappropriate-profile",
    label: "Inappropriate Profile Pic"
  },
  {
    key: "pretending-to-be-me",
    label: "Pretending to be me"
  },
  {
    key: "impersonating",
    label: "Impersonating someone"
  },
  {
    key: "sharing-personal-info",
    label: "Sharing personal information"
  },
  {
    key: "violance",
    label: "Violence or Terrorism"
  },
  {
    key: "something-else",
    label: "Something else"
  }
]

module.exports = {
  reportPostMetadata,
  reportUserMetadata
}
