import { connect } from "react-redux"
import SearchBar from "../../ui/components/Search/SearchBar"

import { loadAll } from "../store/businessLibrary.actions"
import {
  getSearchTerm,
  getPostType,
  getFilter
} from "../store/businessLibrary.selectors"

const mapState = state => ({
  hasSearched: !!getSearchTerm(state),
  postType: getPostType(state),
  category: getFilter(state)
})

const mapDispatch = dispatch => ({
  onSearch: (searchTerm, postType, categoryId) =>
    dispatch(
      loadAll({
        postType,
        from: 0,
        searchTerm,
        categoryId
      })
    ),
  onClear: (postType, categoryId) =>
    dispatch(
      loadAll({
        postType,
        from: 0,
        searchTerm: "",
        categoryId
      })
    )
})

const LibrarySearch = connect(mapState, mapDispatch)(SearchBar)

export default LibrarySearch
