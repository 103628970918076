import React from "react"
import {
  Card,
  Text,
  H4,
  Flex,
  Box,
  UserDetails
} from "@engaging-tech/components"

import Thumbnail from "../../Attachments/Thumbnail"
import CategoryChips from "./CategoryChips"

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const LecturePreview = ({ lecture, interactions }) => (
  <Card mb={3} p={3} bg="light.0">
    <Flex width={1 / 1} flexDirection={["column", "row"]}>
      <Thumbnail
        hasPlayButton
        width={[1 / 1, 1 / 3]}
        src={lecture.coverImage.source}
      />
      <Box width={1 / 1} pl={[0, 3]} pt={[3, 0]}>
        <UserDetails
          userHoldingImage={profilePlaceholder}
          user={lecture.user}
          time={lecture.publishedAt}
          interactions={interactions}
        />
        <H4 mb={1} fontSize={4} fontWeight="500">
          {lecture.title}
        </H4>
        <Text color="dark.2" lineHeight="1.4">
          {lecture.summary}
        </Text>
        {lecture.categories.length > 0 && (
          <CategoryChips ids={lecture.categories} />
        )}
      </Box>
    </Flex>
  </Card>
)

export default LecturePreview
