import { gql } from "../../../lib/Apollo"

export const LOAD_SERIES = gql`
  query($userId: ID) {
    AllSeries(userId: $userId) {
      id
      title
    }
  }
`

export const CREATE_MATERIAL = gql`
  mutation(
    $type: String!
    $title: String!
    $summary: String!
    $seriesId: String
    $categories: [String]
    $coverImage: CoverImageInput!
    $content: [ContentInput]!
  ) {
    createMaterial(
      type: $type
      title: $title
      summary: $summary
      seriesId: $seriesId
      categories: $categories
      coverImage: $coverImage
      content: $content
    ) {
      id
      type
    }
  }
`

export const CREATE_SERIES = gql`
  mutation(
    $title: String!
    $summary: String!
    $categories: [String]
    $coverImage: CoverImageInput!
  ) {
    createSeries(
      title: $title
      summary: $summary
      categories: $categories
      coverImage: $coverImage
    ) {
      id
      title
    }
  }
`

export const CREATE_PURCHASE_MATERIAL_INTENT = gql`
  mutation($id: ID!) {
    purchaseMaterial(id: $id)
  }
`
