import { getConfig } from "@engaging-tech/ssr-config"
import { apollo } from "../../../lib/Apollo"
import { LOAD_ALL, LOAD_BY_ID } from "./myLibrary.queries"

const GRAPHQL_API_URL = () =>
  `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

/**
 * Fetches all bookmarked content of a specific post type from the backend.
 * @param {string} postType The type of posted content to load.
 */
export const loadAll = postType =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD_ALL,
    variables: { type: postType }
  })

/**
 * Fetches a specific bookmarked post from the backend, based on its ID.
 * @param {string} id The id of the post to load.
 */
export const loadById = id =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD_BY_ID,
    variables: { id }
  })
