import { Button, Flex, Text } from "@engaging-tech/components"
import { AnimatePresence } from "framer-motion"
import React, { useState } from "react"
import { useSelector } from "react-redux"

import { businessProfilePageSelectors } from "../../.."
import ScoreLabel from "../../../../surveyResults/components/ScoreLabel"
import BarIndicator from "./common/bar-indicator"
import ModalChartViewer from "./common/modal-chart-viewer"
import IndustryAndGlobalScoreBox from "./industry-and-global-score-box"
import WidgetContainer from "./widget-container"

const DiversityAndInclusionIndicator = () => {
  const [showModal, setShowModal] = useState(false)

  const dataSet = useSelector(businessProfilePageSelectors.getIndicatorDAndIDataSet)
  const chartData = useSelector(businessProfilePageSelectors.getDandIChartData)

  return (
    <>
      <WidgetContainer
        mb={4}
        width={["100%", "48%"]}
        height={["auto", "500px"]}
        title="Diversity and Inclusion"
      >
        <BarIndicator hideArrowLabels dataSet={{ score: dataSet?.score }} />
        <Text color="dark.2" width="100%" textAlign="center">
          Percentage point difference in average score between majority and minority groups
        </Text>
        <Flex style={{ opacity: dataSet ? 1 : 0 }} width="auto" mt={[2, 0]}>
          <ScoreLabel
            fontSize={5}
            score={!!dataSet && typeof dataSet.score === "number" ? 100 - Math.abs(dataSet.score) : 100}
          />
        </Flex>
        <IndustryAndGlobalScoreBox
          addPlusSymbol
          globalScore={dataSet ? dataSet.global : null}
          industryScore={dataSet ? dataSet.industry : null}
          mb={3}
          mt={[3, 0]}
        />
        <Button variant="primary.0" onClick={() => setShowModal(!showModal)} height={32}>
          Find out more
        </Button>
      </WidgetContainer>
      <AnimatePresence>
        {showModal && (
          <ModalChartViewer
            onToggle={() => setShowModal(!showModal)}
            chartData={chartData}
            graphDescription="This graph displays the percentage points of difference between the minority and majority employees at this organisation. A positive score means the minority groups have scored more highly, a negative score means the majority groups have scored more highly."
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default DiversityAndInclusionIndicator
