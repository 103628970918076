import { Box, Button, Flex, Spinner, Text } from "@engaging-tech/components"
import { Link, useLocation, useRouter } from "@engaging-tech/routing"
import { Auth } from "aws-amplify"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import AccountDialog from "../../ui/components/AccountDialog"
import { signInSuccess } from "../store/account.actions"
import { getIsAuthenticated } from "../store/account.selectors"

export default function MagicLinkRedirect({ siteLogo, redirectTo }) {
  const router = useRouter()

  const isAuthenticated = useSelector(getIsAuthenticated)

  const locationParam = new URLSearchParams(useLocation().search)
  const emailEncoded = locationParam.get("email")
  const token = locationParam.get("token")

  const dispatch = useDispatch()

  const onSignInWithMagicLink = async (email, code) => {
    const currentUser = await Auth.signIn(email)

    await Auth.sendCustomChallengeAnswer(currentUser, code)
    const currentSession = await Auth.currentSession()

    dispatch(signInSuccess(currentSession.idToken.payload.sub))
  }

  useEffect(() => {
    if (isAuthenticated) {
      router.redirect(redirectTo)
    }

    const decodedEmailBuffer = Buffer.from(emailEncoded, "base64")
    const plainEmail = decodedEmailBuffer.toString("utf-8")

    onSignInWithMagicLink(plainEmail, token)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailEncoded, token, isAuthenticated])

  const renderVerifyCTAs = () => {
    return (
      <>
        <Box mb={5}>
          <Flex width={1 / 1} flexDirection="row" alignItems="center">
            <Spinner color="primary.0" />
            <Text mx={3} fontSize={[4, 3]} color="primary.0">
              Please wait while we are signing you in, you will be redirected to your account in a moment.
            </Text>
          </Flex>
        </Box>
        <Box mb={5}>
          <Text fontSize={[4, 3]}>If there are any issues signing in, click on </Text>
          <Link to="/account/sign-in">
            <Text fontSize={[4, 3]} color="primary.0">
              Go back to sign in
            </Text>
          </Link>
        </Box>
        <Flex width={1 / 1} flexDirection="row-reverse" alignItems="center">
          <Link to="/account/sign-in">
            <Button variant="primary.0" type="submit" ml={3} width="auto">
              Go back to sign in
            </Button>
          </Link>
          <Link to="/account/sign-up" mr="auto">
            <Button variant="text.noBg.primary.0">Create new account</Button>
          </Link>
        </Flex>
      </>
    )
  }

  return (
    <AccountDialog title="" subtitle="" renderLogo={siteLogo}>
      {renderVerifyCTAs()}
    </AccountDialog>
  )
}
