import { connect } from "react-redux"
import { BusinessLibrarySelectors } from ".."
import { LibraryBooksSelectors } from "../../gardners/index"
import { notificationsActions } from "../../Notifications"

import TopBarComponent from "../components/TopBar"

const TopBar = materialType => {
  const mapState = state => ({
    searchTerm: BusinessLibrarySelectors.getSearchTerm(state),
    bookSearchTerm: BusinessLibrarySelectors.getBookSearchTerm(state),
    bookFilter: LibraryBooksSelectors.getFilter(state),
    filter: BusinessLibrarySelectors.getFilter(state),
    materialType
  })

  const mapDispatch = dispatch => ({
    handleSubmit: (frequency, searchTerm, filter) =>
      dispatch(
        notificationsActions.createMaterialNotification({
          frequency,
          searchTerm,
          filter,
          materialType
        })
      )
  })

  return connect(mapState, mapDispatch)(TopBarComponent)
}
export default TopBar
