import { connect } from "react-redux"
import GoalWidgetComponent from "../components/GoalWidget"
import {
  updateMyGoals,
  updateYourProfile
} from "../../../yourProfile/store/yourProfile.actions"
import { getAllGoals } from "../../store/developCareer.selectors"

import {
  getMyGoals,
  getGoalsViewed,
  getProfile,
  getIsLoading
} from "../../../yourProfile/store/yourProfile.selectors"

const mapState = state => ({
  seenBefore: getGoalsViewed(state),
  myGoals: getMyGoals(state),
  allGoals: getAllGoals(state),
  userProfile: getProfile(state),
  isProfileLoading: getIsLoading(state)
})

const mapDispatch = dispatch => ({
  updateSeenBefore: bool =>
    dispatch(updateYourProfile({ goalsViewed: !!bool })),
  updateMyGoals: goals => dispatch(updateMyGoals(goals))
})

const GoalWidget = connect(mapState, mapDispatch)(GoalWidgetComponent)

export default GoalWidget
