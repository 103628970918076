import { connect } from "react-redux"

import {
  subscribe,
  subscribeSuccess,
  subscribeFail,
  applyDiscount
} from "../../yourProfile/store/yourProfile.actions"

import {
  getPaymentIntent,
  getIsPremium,
  getDiscountCode,
  getPromoId,
  getUserId
} from "../../yourProfile/store/yourProfile.selectors"

import SubscriptionFormComponent from "../forms/SubscriptionForm"

const mapState = state => ({
  paymentIntent: getPaymentIntent(state),
  isPremium: getIsPremium(state),
  discountState: {
    discountCode: getDiscountCode(state),
    promoId: getPromoId(state)
  },
  userId: getUserId(state)
})

const mapDispatch = dispatch => ({
  onSubmit: (cardDetails, userId) =>
    dispatch(subscribe({ cardDetails, userId })),
  onSuccess: () => dispatch(subscribeSuccess()),
  onFail: () => dispatch(subscribeFail()),
  onApplyDiscount: discountCode => dispatch(applyDiscount(discountCode))
})

const SubscriptionForm = connect(
  mapState,
  mapDispatch
)(SubscriptionFormComponent)

export default SubscriptionForm
