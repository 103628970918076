import { connect } from "react-redux"

import AdviceWidgetComponent from "../components/AdviceWidget"
import { GlobalHubSelectors } from "../../../globalHub/index"

import { getIsPremium } from "../../../yourProfile/store/yourProfile.selectors"

const mapState = state => ({
  myLatestAdvicePost: GlobalHubSelectors.getMyLatestAdvicePost(state),
  isPremium: getIsPremium(state)
})

const AdviceWidget = connect(mapState)(AdviceWidgetComponent)

export default AdviceWidget
