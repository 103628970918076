import { connect } from "react-redux"
import { AccountSelectors } from "../../account"
import { getHeaderLogo } from "../../survey/store/survey.selectors"
import { yourProfileSelectors } from "../../yourProfile"
import NavComponent from "../components/MinifiedNav"

const mapState = state => ({
  isAuthenticated: AccountSelectors.getIsAuthenticated(state),
  userProfile: yourProfileSelectors.getProfile(state),
  headerLogo: getHeaderLogo(state)
})

const MinifiedNav = connect(mapState)(NavComponent)

export default MinifiedNav
