import { useLocation, usePaths } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"
import { useSelector } from "react-redux"

import SignUpView from "../../features/account/views/signUp"
import { UiSelectors } from "../../features/ui"
import useFreschatWidget from "../../hooks/useFreschatWidget"
import SolidLayout from "../../layouts/SolidLayout"

const cookieDomain = () => {
  if (typeof window !== "undefined") {
    return window.location?.hostname
  }
  return null
}

export default function SignUp() {
  const paths = usePaths()
  useFreschatWidget()
  const location = useLocation()
  const isFromPremium = location.search.includes("premium")
  const prevRoute = useSelector(UiSelectors.getPreviousRoute)

  return (
    <SolidLayout>
      <SignUpView
        redirectTo={{
          pathname: paths.account.thankYou,
          state: { postThankYouRedirect: prevRoute, isFromPremium }
        }}
        postThankYouRedirect={prevRoute}
        cancelRoute={`${getConfig().workL.public.index}/home`}
        isFromPremium={isFromPremium}
        domain={cookieDomain()}
      />
    </SolidLayout>
  )
}
