export const ukDeliveryType = [
  { code: "1", name: "UK Standard", price: 1.5 }
  // { code: "2", name: "UK tracked 48 hr ", price: 2.75 }
]

export const internationalDeliveryType = [
  { code: "3", name: "Europe", price: 3.99 },
  { code: "4", name: "Africa / Asia", price: 9.99 },
  { code: "5", name: "Americas", price: 11.4 },
  { code: "6", name: "Australasia", price: 8.99 }
]

export const Types = [
  { code: "0", name: "Free", price: 0 },
  { code: "1", name: "UK Standard", price: 1.5 },
  // { code: "2", name: "UK tracked 48 hr ", price: 2.75 },
  { code: "3", name: "Europe", price: 3.99 },
  { code: "4", name: "Africa / Asia", price: 9.99 },
  { code: "5", name: "Americas", price: 11.4 },
  { code: "6", name: "Australasia", price: 8.99 }
]
