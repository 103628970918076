import React from "react"
import { Text, Flex } from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"
import PrimaryButton from "../../ui/components/PrimaryButton"

const NoMatches = ({ tabState, mentor, mentee, services }) => {
  const paths = usePaths()

  const previousText = () => {
    const text = mentor ? "mentor" : "mentee"
    return `You have no previous connections. Start connecting by checking out
    your potential ${text}s!`
  }

  const pendingText = () => {
    const text = mentor ? "mentor" : "mentee"
    return `You currently have no pending connections. Start connecting by
    checking out your potential ${text}s!`
  }

  const turnedOffText = () => {
    const text = mentor ? "Mentor" : "Mentee"
    const oposite = mentor ? "Mentors" : "people looking for a Mentor"
    return [
      `No ${text}s found!`,
      `Turn on the ‘Find a ${text}’ button to start being matched with ${oposite}.`,
      "You can update your profile to help find better matches"
    ]
  }
  const noMatchesText = [
    "You haven't been matched yet!",
    "But don’t worry, we will keep looking and will notify you as soon as we find new matches.",
    "Keep your profile updated - help us find people to connect you with by keeping your profile up to date."
  ]
  switch (tabState) {
    case "matches":
      return (
        <Flex flexDirection="column" my={3}>
          {mentee &&
            services &&
            services.menteeMatcher === "ON" &&
            noMatchesText.map((text, index) => (
              <Text
                fontSize={4}
                color={index === 2 ? "dark.2" : "dark.0"}
                key={text}
                my={index === 2 ? 3 : 0}
              >
                {text}
              </Text>
            ))}
          {mentor &&
            services &&
            services.mentorMatcher === "ON" &&
            noMatchesText.map((text, index) => (
              <Text
                fontSize={4}
                color={index === 2 ? "dark.2" : "dark.0"}
                key={text}
                my={index === 2 ? 3 : 0}
              >
                {text}
              </Text>
            ))}
          {mentor &&
            services &&
            services.mentorMatcher === "OFF" &&
            turnedOffText().map((text, index) => (
              <Text
                fontSize={4}
                color="dark.0"
                key={text}
                mb={index === 0 || index === 2 ? 3 : 0}
              >
                {text}
              </Text>
            ))}
          {mentee &&
            services &&
            services.menteeMatcher === "OFF" &&
            turnedOffText(mentor).map((text, index) => (
              <Text
                fontSize={4}
                color="dark.0"
                key={text}
                mb={index === 0 || index === 2 ? 3 : 0}
              >
                {text}
              </Text>
            ))}
          <Link to={paths.careerDeveloper.yourProfile}>
            <PrimaryButton text="Update profile" px={4} />
          </Link>
        </Flex>
      )
    case "pending":
      return (
        <Flex flexDirection="column" my={3}>
          <Text>{pendingText()}</Text>
        </Flex>
      )
    case "previous":
      return (
        <Flex flexDirection="column" my={3}>
          <PrimaryButton mb={2} text="Delete previous connections" disabled />
          <Text>{previousText()}</Text>
        </Flex>
      )
    default:
      return null
  }
}

export default NoMatches
