import { connect } from "react-redux"
import { mentorMatcherActions } from ".."
import DisplayMatchComponent from "../components/DisplayMatch"

const mapDispatch = dispatch => ({
  deleteMatch: id => dispatch(mentorMatcherActions.deleteMatch(id)),
  connectToMatch: ({ userId, matchId }) =>
    dispatch(mentorMatcherActions.connect({ userId, matchId }))
})

const DisplayMatch = connect(null, mapDispatch)(DisplayMatchComponent)

export default DisplayMatch
