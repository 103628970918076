import { connect } from "react-redux"

import {
  getRegions,
  getJobRoles,
  getHasCreated,
  getTextSearch
} from "../store/advice.selectors"
import {
  loadRegions,
  loadJobRoles,
  create,
  setJobRoleTextSearch
} from "../store/advice.actions"

import CreateAdviceComponent from "../components/CreateAdvice"

const mapState = state => ({
  hasCreated: getHasCreated(state),
  regions: getRegions(state),
  jobRoles: getJobRoles(state),
  textSearch: getTextSearch(state)
})

const mapDispatch = dispatch => ({
  onLoadJobRoles: textSearch => dispatch(loadJobRoles(textSearch)),
  onChangeCountry: countryId => dispatch(loadRegions(countryId)),
  onSubmit: newAdvice => dispatch(create(newAdvice)),
  onSetTextSearch: textSearch => dispatch(setJobRoleTextSearch(textSearch))
})

const CreateAdvice = connect(mapState, mapDispatch)(CreateAdviceComponent)

export default CreateAdvice
