import { useParams } from "@engaging-tech/routing"
import React from "react"

import SelectedCVContainer from "../containers/SelectedCV"

const SelectedCV = () => {
  const { id } = useParams()

  return <SelectedCVContainer id={id} />
}

export default SelectedCV
