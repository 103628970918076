/* eslint-disable no-nested-ternary */
import { Box, Flex, Text, userInterfaceNotificationsStore } from "@engaging-tech/components"
import { useLocation, useParams, usePaths } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

import { businessProfilePageSelectors } from ".."
import { LinkIcon } from "../../../assets/icons"
import useWorkLAppQuery from "../../../hooks/use-workl-app-query"
import { createUrl } from "../../../lib/utils"
import PrimaryButton from "../../ui/components/PrimaryButton"
import AboutUsTab from "../components/about-us-tab"
import AwardsTab from "../components/awards-tab"
import WorkLDataTab from "../components/workl-data-tab"
import { GET_AWARDS_WINS } from "../services/businessProfilePage.queries"
import { load, loadDunnsData } from "../store/businessProfilePage.actions"

const bannerPlaceholderImage = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/bip-banner-placeholder.svg`)
const logoPlaceholderImage = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/bip-logo-placeholder.png`)

const tabs = [
  { id: "workl-data", title: "WorkL Data" },
  { id: "about-us", title: "About Us" },
  { id: "awards", title: "Awards" }
]

const HeaderContainer = styled.div`
  width: 100%;

  background: white;

  display: flex;
  flex-direction: column;
`

const HeaderContent = styled.div`
  width: 100%;
  max-width: 690px;

  padding: 18px 12px 0;

  display: flex;

  @media (min-width: 768px) {
    max-width: none;

    padding: 30px;

    justify-content: space-between;
  }
`

const HeaderViewJobsVacanciesContainer = styled.div`
  display: none;

  @media (min-width: 768px) {
    width: 100%;
    height: fit-content;

    margin: 16px 20px 30px 0;

    display: flex;
    justify-content: end;

    margin: 0;
  }
`

const HeaderViewJobsVacanciesContainerMobile = styled.div`
  margin-bottom: 27px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    display: none;
  }
`

const Title = styled(Text)`
  width: fit-content;

  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  text-align: center;
`

const ShortStatement = styled(Text)`
  width: fit-content;

  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
`

const Website = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: underline;
  color: #003e4c;

  cursor: pointer;
`

const MainContent = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.dark[3]};

  display: flex;
  flex-direction: column;
  max-width: 1280px;
  align-items: center;

  overflow-x: hidden;
`

const TabContainer = styled(Flex)`
  justify-content: space-between;

  & > div:not(:last-child) {
    margin-right: 1px;
  }

  @media (min-width: 768px) {
    margin-left: 2px;

    justify-content: unset;
  }
`

const TabItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  padding: 8px 0;

  cursor: pointer;

  box-shadow: 2px 0px 4px 0px #00000040;
  border-radius: 0 0 10px 0;

  background-color: ${({ active, disabled }) => (disabled ? "#D9D9D9" : active ? "#E6F5F5" : "transparent")};

  @media (min-width: 768px) {
    width: fit-content;

    padding: 8px 18px;
  }
`

const TabItemText = styled(Text)`
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: #003e4c;

  font-weight: ${({ active }) => (active ? 700 : 400)};
`

const BannerImage = styled.img`
  max-width: 1280px;
  width: 100%;
  max-height: 250px;
  height: 100%;
`

const LinkIconWrapper = styled.div`
  display: block;
  cursor: pointer;
`

const LogoImage = styled.img`
  width: 100%;
  height: 100%;

  max-width: 62px;
  max-height: 62px;

  object-fit: contain;

  @media (min-width: 768px) {
    max-width: 200px;
    max-height: 200px;
  }
`

const getAwardYear = searchParams => {
  const awardStr = searchParams.match(/award=\d+/gi)
  return awardStr?.length > 0 ? awardStr[0].replace("award=", "") : null
}

export default function BusinessProfileView() {
  const history = useHistory()
  const location = useLocation()
  const { profileId } = useParams()
  const paths = usePaths()

  const { data: awardsWins, getData } = useWorkLAppQuery(GET_AWARDS_WINS, { enabledOnMount: false })

  useEffect(() => {
    if (profileId) {
      getData({ companyNameKey: profileId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId])

  const awardYear = getAwardYear(location.search)

  const optionSearchParams = new URLSearchParams(location.search)
  const tabActive = optionSearchParams.get("tab")

  useEffect(() => {
    if (!tabActive) {
      optionSearchParams.set("tab", "workl-data")
      const optionUrl = createUrl(location.pathname, optionSearchParams)
      history.replace(optionUrl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabActive])

  const onChangeTab = tab => {
    optionSearchParams.set("tab", tab)
    const optionUrl = createUrl(location.pathname, optionSearchParams)
    history.replace(optionUrl)
  }

  const bipInfo = useSelector(businessProfilePageSelectors.getBipInfo)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(load({ id: profileId, awardYear: null }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [awardYear, profileId])

  useEffect(() => {
    if (profileId) {
      dispatch(loadDunnsData(profileId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId])

  const awardsWinsUI = awardsWins?.getAwardWins?.map(award => {
    if (award?.logoUrls?.length) {
      return award.logoUrls.map((logo, index) => (
        <img
          key={logo}
          alt={award.awardWins[index]}
          src={logo}
          style={{ marginLeft: "5px" }}
          loading="lazy"
          width="44px"
          height="54px"
        />
      ))
    }
    if (award?.sundayTimesAward) {
      return (
        <img
          key={award.sundayTimesAward}
          alt="Sunday Times Award"
          src={award.sundayTimesAward}
          style={{ marginLeft: "14px" }}
          loading="lazy"
          width="152px"
          height="54px"
        />
      )
    }
    return null
  })

  return (
    <Flex flexDirection="column" maxWidth="1000px">
      <BannerImage
        alt="BIP Banner"
        src={
          bipInfo?.profile?.summary?.banner
            ? bipInfo.profile.summary.banner.includes("https")
              ? bipInfo.profile.summary.banner
              : `${getConfig().awsImageEndpoint}${bipInfo.profile.summary.banner}`
            : bannerPlaceholderImage
        }
        loading="lazy"
      />

      <HeaderContainer>
        <HeaderContent style={{ position: "relative" }}>
          <Box width="fit-content">
            <LogoImage
              alt="BIP Logo"
              src={
                bipInfo?.profile?.summary?.logoUrl
                  ? bipInfo.profile.summary.logoUrl.includes("https")
                    ? bipInfo.profile.summary.logoUrl
                    : `${getConfig().awsImageEndpoint}${bipInfo.profile.summary.logoUrl}`
                  : logoPlaceholderImage
              }
              loading="lazy"
            />
          </Box>

          <Flex justifyContent="space-between" width="100%">
            <Flex flexDirection="column" ml="16px" width="auto">
              <Title color="primary.0" width="fit-content">
                {bipInfo?.company ? bipInfo.company : "Company Name"}
              </Title>
              {bipInfo?.profile?.status === "PUBLISHED" ? (
                <ShortStatement mt="9px" color="primary.0">
                  {bipInfo?.profile?.status === "PUBLISHED" ? bipInfo.profile.culture?.shortStatement : ""}
                </ShortStatement>
              ) : null}
              <Website
                mt="18px"
                onClick={() => {
                  window.open(bipInfo.profile.summary.websiteURL, "_blank", "noreferrer")
                }}
              >
                {bipInfo?.profile?.status === "PUBLISHED" && bipInfo?.profile?.summary?.websiteURL
                  ? bipInfo.profile.summary.websiteURL
                  : ""}
              </Website>
              <Website
                mt="9px"
                onClick={() => {
                  window.open(`mailto:${bipInfo.profile.summary.contactEmail}`, "_blank", "noreferrer")
                }}
              >
                {bipInfo?.profile?.status === "PUBLISHED" && bipInfo?.profile?.summary?.contactEmail
                  ? bipInfo.profile.summary.contactEmail
                  : ""}
              </Website>
            </Flex>
          </Flex>
          <Flex flexDirection="column" width="auto">
            <Flex
              justifyContent="end"
              width="100%"
              style={{ position: "absolute", top: "11px", right: "20px" }}
            >
              <LinkIconWrapper
                onClick={e => {
                  e.preventDefault()
                  navigator.clipboard.writeText(
                    `${window.location.origin}${paths.companyBusinessProfile.index}/${profileId}`
                  )

                  dispatch(
                    userInterfaceNotificationsStore.actions.addNotification({
                      message: "URL copied to clipboard"
                    })
                  )
                }}
              >
                <LinkIcon />
              </LinkIconWrapper>
            </Flex>

            <HeaderViewJobsVacanciesContainer>
              <Flex flexDirection="column" width="100%" mt="33px" ml="14px">
                <PrimaryButton
                  disabled={!bipInfo}
                  text={bipInfo?.companyNameKey ? "View Job Vacancies" : "No Jobs Vacancies"}
                  width="180px"
                  fontSize="14px"
                  fontWeight="700"
                  lineHeight="18.2px"
                  onClick={() => {
                    if (window?.gtag) {
                      window.gtag("event", "BP_JOB_VACANCIES_CLICK", {
                        event_category: "page_view",
                        event_label: "BP - Find a Job Page"
                      })
                    }

                    window.open(
                      `${paths.findAJob.index}?company=${bipInfo.company}&companyKey=${bipInfo.companyNameKey}`,
                      "_blank",
                      "noreferrer"
                    )
                  }}
                />
                <Flex mt="15px" justifyContent="end" width="100%">
                  {awardsWinsUI}
                </Flex>
              </Flex>
            </HeaderViewJobsVacanciesContainer>
          </Flex>
        </HeaderContent>
        <HeaderViewJobsVacanciesContainerMobile>
          <Flex mt="22px" ml="20px" mb="32px" justifyContent="start" width="100%">
            {awardsWinsUI}
          </Flex>
          <PrimaryButton
            disabled={!bipInfo}
            text={bipInfo?.companyNameKey ? "View Job Vacancies" : "No Jobs Vacancies"}
            width="172px"
            fontSize="14px"
            fontWeight="700"
            lineHeight="18.2px"
            onClick={() => {
              if (window?.gtag) {
                window.gtag("event", "BP_JOB_VACANCIES_CLICK", {
                  event_category: "page_view",
                  event_label: "BP - Find a Job Page"
                })
              }

              window.open(
                `${paths.findAJob.index}?company=${bipInfo.company}&companyKey=${bipInfo.companyNameKey}`,
                "_blank",
                "noreferrer"
              )
            }}
          />
        </HeaderViewJobsVacanciesContainerMobile>
      </HeaderContainer>
      <MainContent flexDirection="column" bg="white">
        {/* Tabs */}
        <TabContainer>
          {tabs.map(tab => (
            <TabItem key={tab.id} active={tabActive === tab.id} onClick={() => onChangeTab(tab.id)}>
              <TabItemText active={tabActive === tab.id}>{tab.title}</TabItemText>
            </TabItem>
          ))}
        </TabContainer>
        {tabActive === "workl-data" && <WorkLDataTab />}
        {tabActive === "about-us" && <AboutUsTab />}
        {tabActive === "awards" && <AwardsTab />}
      </MainContent>
    </Flex>
  )
}
