import { CustomHooks, Flex, Text } from "@engaging-tech/components"
import React, { useRef } from "react"
import { Bar } from "react-chartjs-2"

import { formatData, getIsSingular, mergeDeep } from "../utils"
import WidgetContainer from "../widget-container"

const Graph = ({ containerRef, dataSet, unit, disableAnimationTrigger, yAxisDecimalPlaces = 0 }) => {
  const device = CustomHooks.useClientDevice()
  const indicatorVisible = CustomHooks.useElementVisibleTrigger({
    componentRef: containerRef,
    delay: 0
  })
  const isSingular = getIsSingular(dataSet)

  const data = formatData(dataSet)

  return (
    <Flex position="relative" height="95%" width="100%" className="chart-container">
      <Bar
        data={data}
        options={mergeDeep(
          {
            responsive: true,
            maintainAspectRatio: false,
            animation: {
              delay: disableAnimationTrigger || indicatorVisible ? 100 : 100000
            },
            plugins: {
              legend: {
                position: "top",
                display: !isSingular,
                labels: {
                  pointStyle: "circle",
                  usePointStyle: true,
                  padding: 25,
                  font: {
                    size: device === "MOBILE" ? 10 : 14
                  }
                }
              },
              tooltip: {
                callbacks: {
                  title: () => false
                }
              }
            },
            scales: {
              y: {
                ticks: {
                  beginAtZero: true,
                  callback: (value, index, values) => {
                    const roundedValue = value.toFixed(yAxisDecimalPlaces)
                    if (unit && (values.length - 1 === index || index === 0)) {
                      return `${roundedValue}${unit}`
                    }
                    return roundedValue
                  }
                },
                title: {
                  font: {
                    size: device === "MOBILE" ? 10 : 12
                  },
                  padding: {
                    top: device === "MOBILE" ? 0 : 20,
                    left: 0,
                    right: 0,
                    bottom: 0
                  }
                }
              },
              x: {
                ticks: {
                  font: {
                    size: device === "MOBILE" ? 10 : 14
                  }
                }
              }
            }
          },
          dataSet.graphOptions
        )}
        device={device}
      />
    </Flex>
  )
}

export default function ComparisonBarChart({
  dataSet,
  unit,
  yAxisDecimalPlaces,
  disableAnimationTrigger,
  noContainer,
  graphDescription,
  ...props
}) {
  const containerRef = useRef()

  const TheGraph = () => {
    return (
      <Flex ref={containerRef} height={["100%", "500px"]} width="100%" flexDirection="column">
        {graphDescription && (
          <Text color="dark.2" fontSize={[2, 3]} mt={3} textAlign="center">
            {graphDescription}
          </Text>
        )}
        <Graph
          dataSet={dataSet}
          unit={unit}
          disableAnimationTrigger={disableAnimationTrigger}
          yAxisDecimalPlaces={yAxisDecimalPlaces}
          containerRef={containerRef}
        />
      </Flex>
    )
  }

  if (noContainer) {
    return <TheGraph />
  }

  return (
    <WidgetContainer
      mb={4}
      width="100%"
      title={dataSet.name}
      height={[300, 400, 500]}
      key={dataSet.name}
      {...props}
    >
      <TheGraph />
    </WidgetContainer>
  )
}
