import { Button, Card, Flex, Text, TextField } from "@engaging-tech/components"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { emailEB } from "../../../../lib/globalConstants"
import codeCheckContainer from "../../containers/codeCheck.container"
import SurveyLogo from "../surveyLogo"
import CodeAlreadyUsed from "./codeAlreadyUsed"
import EmailLink from "./emailLink"

const CodeCheckContent = ({
  individualCode,
  isValidCode,
  codeAlreadyUsed,
  surveyId,
  status,
  checkCode,
  onNext,
  onPrevious
}) => {
  const [textCode, onTextCodeChange] = useState(individualCode)
  const { t } = useTranslation(["organisation_survey"])

  useEffect(() => {
    if (isValidCode || status === "draft" || status === "scheduled") {
      onNext()
    }
  }, [isValidCode, onNext, status])

  if (codeAlreadyUsed) {
    return <CodeAlreadyUsed onNext={onNext} onPrevious={onPrevious} />
  }

  return (
    <>
      <Card bg="light.0" mt={[5, 5, 6]} mb={[6, 5]} elevation={8} px={4}>
        <Flex flexDirection="column" py={3} px={[3, 4]} alignContent="center">
          <SurveyLogo />
          <Text
            color="dark.2"
            fontSize={4}
            textAlign="center"
            my={3}
            fontWeight={500}
          >
            {t("code_check.text1", "")}
          </Text>

          <TextField
            value={textCode || ""}
            onChange={e => onTextCodeChange(e.target.value)}
            width={1 / 1}
            placeholder={t("code_check.enter_code", "")}
          />
          <Text color="dark.2" fontSize={4} textAlign="center" fontWeight={500}>
            {t("code_check.text2", "")} <EmailLink url={emailEB} />
          </Text>
        </Flex>
        <Flex
          flexDirection="row"
          py={2}
          px={[3, 4]}
          justifyContent="space-between"
        >
          <Button
            variant="text.dark.2"
            elevation={0}
            onClick={onPrevious}
            my={2}
            mx={[3, 4]}
          >
            {t("code_check.cancel", "")}
          </Button>
          <Button
            my={2}
            variant="primary.0"
            onClick={() => checkCode({ code: textCode, surveyId })}
          >
            {t("code_check.submit", "")}
          </Button>
        </Flex>
      </Card>
    </>
  )
}

export default codeCheckContainer(CodeCheckContent)
