import React from "react"
import { H4, Box, Flex, Text, Icon } from "@engaging-tech/components"
import Paragraph from "@engaging-tech/components/dist/lib/Paragraph/Paragraph"

const Check = ({ text }) => (
  <Flex alignItems="center" bg="dark.7" borderRadius={24} p={1} mb={2}>
    <Icon
      name="check"
      mr={2}
      bg="primary.0"
      borderRadius={99}
      color="light.0"
    />
    <Text fontSize={4} color="dark.2">
      {text}
    </Text>
  </Flex>
)

const CheckList = ({ title, subTitle, items }) => (
  <Box px={3} pt={3} mb={3} mt={3}>
    <H4 fontSize={3} color="dark.2" fontWeight="800" mb={2}>
      {title}
    </H4>
    {subTitle && (
      <Paragraph fontSize={3} color="dark.1" mb={2}>
        {subTitle}
      </Paragraph>
    )}
    <Text textAlign="left" fontSize={3} color="dark.1" fontWeight="400" >
      {items.map(item => (
        <Check key={item} text={item} />
      ))}
    </Text>
  </Box>
)

export default CheckList
