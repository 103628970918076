import React from "react"
import { Card, Flex, Text, Icon, ToggleLogic } from "@engaging-tech/components"
import { useRouter } from "@engaging-tech/routing"
import PrimaryButton from "../../ui/components/PrimaryButton"
import TestModal from "./TestModal"

import testDirectory from "../lib/testDirectory"

const YourTests = () => {
  const router = useRouter()
  return (
    <Flex flexDirection="column" width="99%">
      {testDirectory.map(test => (
        <ToggleLogic>
          {({ on, toggle }) => (
            <>
              <Card mb={4} px={3} m={1}>
                <Flex
                  py={4}
                  flexDirection={["column", "row"]}
                  alignItems="center"
                >
                  <Flex alignItems="center">
                    <Icon
                      name={test.icon}
                      size={48}
                      bg="primary.0"
                      color="light.0"
                      borderRadius={30}
                      padding={30}
                      mr={3}
                    />
                    <Text fontWeight="600">{test.title}</Text>
                  </Flex>
                  <PrimaryButton
                    mt={[3, 0]}
                    text="Take the test"
                    width="200px"
                    onClick={() => {
                      if (test.redirectLink) {
                        router.redirect(test.link)
                      }
                      if (test.title === "Career Path Finder") {
                        window.open(test.link, "_blank")
                      } else {
                        toggle()
                      }
                    }}
                  />
                </Flex>
              </Card>
              {on && <TestModal onToggle={toggle} link={test.link} />}
            </>
          )}
        </ToggleLogic>
      ))}
    </Flex>
  )
}

export default YourTests
