import { types } from "./libraryBooks.actions"

const BOOKS_TO_LOAD = 20

const MAX_QUANTITY_ORDER = 3

export const initialState = {
  isLoading: false,
  books: [],
  loadFrom: BOOKS_TO_LOAD,
  hasLoadedAll: false,
  book: "",
  selectedFormat: "",
  filter: "0",
  searchTerm: "",
  order: "",
  isCheckoutLoading: false,
  orders: [],
  digitalBooks: [],
  basket: [],
  discoveryBooks: {}
}
const getBookInfo = (format, book) => {
  let product = {}
  const ebooks = book.eBooks || []
  switch (format) {
    case "ebook":
      product = ebooks.find(
        ebookData =>
          ebookData.formats &&
          ebookData.formats.find(eBookFormat => eBookFormat.id === 6)
      )
      break
    case "audiobook":
      product = ebooks.find(
        ebookData =>
          ebookData.formats &&
          ebookData.formats.find(eBookFormat => eBookFormat.id === 5)
      )
      break
    case "hardCover":
      product = book
      break
    default:
      product = {}
  }
  return product
}

const saveToBasket = (payload, book) => {
  const product = {}
  const isPhysicalBook = payload.format === "hardCover"
  const bookInfo = getBookInfo(payload.format, book)
  product.title = book.title
  product.authors = book.authors
  product.ean = isPhysicalBook ? book.isbn_13 : bookInfo.ean
  product.image = `https://gardnersmedia.engaging.works${book.image}`
  product.price = isPhysicalBook ? bookInfo.ukPriceInclVat : bookInfo.price
  product.id = bookInfo.id
  product.quantity = isPhysicalBook ? payload.quantity : 1
  product.format = payload.format
  if (!isPhysicalBook) product.type = bookInfo.formats[0].id
  product.weight = isPhysicalBook ? book.weight : 0
  product.url = book.id
  return product
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_ALL_BOOKS:
      return { ...state, isLoading: true }
    case types.LOAD_BOOK:
      return { ...state, isLoading: true, book: initialState.book }

    case types.RESET_ORDER:
      return { ...state, orderId: null, hasPaid: false }

    case types.RESET_BASKET:
      return { ...state, basket: [] }

    case types.CREATE_ORDER:
      return { ...state, isCheckoutLoading: true }
    case types.UPDATE_ORDER:
      return { ...state, isCheckoutLoading: true }
    case types.CREATE_ORDER_INTENT:
      return { ...state, isCheckoutLoading: true }
    case types.LOAD_DIGITAL_PURCHASES:
      return { ...state, hasPaid: false, isLoading: true }

    case types.CREATE_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orderId: action.payload,
        isCheckoutLoading: false
      }

    case types.UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orderInfo: action.payload.orderInfo,
        isCheckoutLoading: false
      }

    case types.CREATE_ORDER_INTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        intentSecret: action.payload,
        isCheckoutLoading: false
      }

    case types.CREATE_ORDER_INTENT_FAIL:
      return {
        ...state,
        isLoading: false,
        intentSecret: null,
        isCheckoutLoading: false
      }

    case types.PAY_ORDER_SUCCESS:
      return { ...state, isLoading: false, hasPaid: true, intentSecret: null }

    case types.PAY_ORDER_FAIL:
      return { ...state, isLoading: false, hasPaid: false, intentSecret: null }

    case types.LOAD_ALL_BOOKS_SUCCESS:
      return {
        ...state,
        books: action.payload,
        isLoading: false
      }

    case types.LOAD_MORE:
      return {
        ...state,
        isLoadingMore: true
      }

    case types.LOAD_MORE_SUCCESS:
      return {
        ...state,
        books: [...state.books, ...action.payload],
        loadFrom: state.loadFrom + action.payload.length,
        isLoadingMore: false,
        hasLoadedAll: action.payload.length < BOOKS_TO_LOAD
      }

    case types.LOAD_MORE_FAIL:
      return { ...state, isLoadingMore: false }

    case types.LOAD_BOOK_SUCCESS:
      return {
        ...state,
        book: action.payload,
        selectedFormat: "hardCover",
        isLoading: false
      }

    case types.LOAD_ALL_BOOKS_FAIL:
      return { ...state, isLoading: false }
    case types.LOAD_BOOK_FAIL:
    case types.CREATE_ORDER_FAIL:
      return { ...state, isCheckoutLoading: false }
    case types.LOAD_ORDER_FAIL:
    case types.LOAD_ORDERS_FAIL:
    case types.LOAD_DIGITAL_PURCHASES_FAIL:
    case types.LOAD_DISCOVERY_BOOKS_FAIL:
      return { ...state, isLoading: false }

    case types.UPDATE_ORDER_FAIL:
      return {
        ...state,
        isLoading: false,
        orderId: null,
        isCheckoutLoading: false
      }

    case types.SELECT_FORMAT:
      return { ...state, selectedFormat: action.payload }

    case types.FILTER:
      return { ...state, filter: action.payload, isLoading: true }

    case types.RESET_FILTER:
      return { ...state, filter: initialState.filter, isLoading: true }

    case types.SEARCH:
      return { ...state, searchTerm: action.payload, isLoading: true }

    case types.RESET_SEARCH:
      return { ...state, searchTerm: initialState.searchTerm, isLoading: true }

    case types.ADD_TO_BASKET: {
      const basket = [...state.basket]
      const newProduct = saveToBasket(action.payload, state.book)
      const basketProduct = basket.find(
        product => product.ean === newProduct.ean
      )
      if (!basketProduct)
        return { ...state, basket: [...state.basket, newProduct] }
      if (newProduct.format === "hardCover") {
        const availableQuantity = MAX_QUANTITY_ORDER - basketProduct.quantity
        basketProduct.quantity =
          availableQuantity >= newProduct.quantity
            ? basketProduct.quantity + newProduct.quantity
            : MAX_QUANTITY_ORDER
      }

      return { ...state, basket }
    }

    case types.UPDATE_QUANTITY: {
      const basket = [...state.basket]
      const basketProduct = basket.find(
        product => product.id === action.payload.id
      )
      basketProduct.quantity = action.payload.quantity
      return { ...state, basket }
    }

    case types.DELETE_PRODUCT: {
      const basket = [...state.basket]
      const basketProducts = basket.filter(
        product => product.id !== action.payload
      )
      return { ...state, basket: basketProducts }
    }

    case types.SET_DELIVERY_TYPE:
      return { ...state, deliveryType: action.payload }

    case types.LOAD_ORDER:
      return { ...state, isLoading: true, order: initialState.order }

    case types.LOAD_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
        isLoading: false
      }

    case types.LOAD_ORDERS:
      return { ...state, isLoading: true, orders: initialState.orders }

    case types.LOAD_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
        isLoading: false
      }

    case types.LOAD_DIGITAL_PURCHASES_SUCCESS:
      return { ...state, isLoading: false, digitalBooks: action.payload }

    case types.LOAD_DISCOVERY_BOOKS:
      return { ...state, isLoading: true }

    case types.LOAD_DISCOVERY_BOOKS_SUCCESS:
      return { ...state, isLoading: false, discoveryBooks: action.payload }

    default:
      return state
  }
}
