export const types = {
  LOAD: "[Notifications] Load",
  LOAD_SUCCESS: "[Notifications] Load Success",
  LOAD_FAIL: "[Notifications] Load Fail",
  CREATE_JOB_NOTIFICATION: "[Notifications] Create Job Notifications",
  CREATE_JOB_NOTIFICATION_SUCCESS: "[Notifications] Create Job Notifications Success",
  CREATE_EVENT_NOTIFICATION: "[Notifications] Create Event Notifications",
  CREATE_EVENT_NOTIFICATION_SUCCESS: "[Notifications] Create Event Notifications Success",
  CREATE_MATERIAL_NOTIFICATION: "[Notifications] Create Material Notifications",
  CREATE_MATERIAL_NOTIFICATION_SUCCESS: "[Notifications] Create Material Notifications Success",
  NOTIFICATION_FAIL: "[Notifications] Create Notifications Fail",
  TOGGLE: "[Notifications] Toggle",
  SET_ACTIVE_ALERT: "[Notifications] Set Active Alert",
  UPDATE_JOB_ALERT: "[Notifications] Update Job Alert",
  UPDATE_JOB_ALERT_SUCCESS: "[Notifications] Update Job Alert Success",
  UPDATE_JOB_ALERT_FAIL: "[Notifications] Update Job Alert Fail",
  UPDATE_EVENT_ALERT: "[Notifications] Update Event Alert",
  UPDATE_EVENT_ALERT_SUCCESS: "[Notifications] Update Event Alert Success",
  UPDATE_EVENT_ALERT_FAIL: "[Notifications] Update Event Alert Fail",
  UPDATE_MATERIAL_ALERT: "[Notifications] Update Material Alert",
  UPDATE_MATERIAL_ALERT_SUCCESS: "[Notifications] Update Material Alert Success",
  UPDATE_MATERIAL_ALERT_FAIL: "[Notifications] Update Material Alert Fail",
  DELETE_ALERT: "[Notifications] Delete Alert",
  DELETE_ALERT_SUCCESS: "[Notifications] Delete Alert Success",
  DELETE_ALERT_FAIL: "[Notifications] Delete Alert Fail",
  SET_MODAL_TYPE: "[Notifications] Set Modal Type"
}

export const load = payload => ({
  type: types.LOAD,
  payload
})

export const loadSuccess = payload => ({
  type: types.LOAD_SUCCESS,
  payload
})

export const loadFail = () => ({
  type: types.LOAD_FAIL
})

export const createJobNotification = payload => ({
  type: types.CREATE_JOB_NOTIFICATION,
  payload
})

export const createJobNotificationSuccess = payload => ({
  type: types.CREATE_JOB_NOTIFICATION_SUCCESS,
  payload
})

export const setModalType = payload => ({
  type: types.SET_MODAL_TYPE,
  payload
})

export const createEventNotification = ({ frequency, categoryId, location }) => {
  return {
    type: types.CREATE_EVENT_NOTIFICATION,
    payload: {
      frequency,
      categoryId,
      location
    }
  }
}

export const createEventNotificationSuccess = payload => ({
  type: types.CREATE_EVENT_NOTIFICATION_SUCCESS,
  payload
})

export const createMaterialNotification = ({
  frequency,
  // searchTerm,
  filter,
  materialType
}) => {
  return {
    type: types.CREATE_MATERIAL_NOTIFICATION,
    payload: {
      frequency,
      //  searchTerm: searchTerm || "",
      categoryId: filter || "",
      materialType
    }
  }
}

export const createMaterialNotificationSuccess = payload => ({
  type: types.CREATE_MATERIAL_NOTIFICATION_SUCCESS,
  payload
})

export const createNotificationFail = errorCode => ({
  type: types.NOTIFICATION_FAIL,
  payload: errorCode
})

export const toggle = () => ({
  type: types.TOGGLE
})

export const setActiveAlert = payload => ({
  type: types.SET_ACTIVE_ALERT,
  payload
})

export const updateJobAlert = ({ id, frequency }) => ({
  type: types.UPDATE_JOB_ALERT,
  payload: { id, frequency }
})

export const updateJobAlertSuccess = payload => ({
  type: types.UPDATE_JOB_ALERT_SUCCESS,
  payload
})

export const updateJobAlertFail = () => ({
  type: types.UPDATE_JOB_ALERT_FAIL
})

export const updateEventAlert = ({ id, frequency }) => ({
  type: types.UPDATE_EVENT_ALERT,
  payload: { id, frequency }
})

export const updateEventAlertSuccess = payload => ({
  type: types.UPDATE_EVENT_ALERT_SUCCESS,
  payload
})

export const updateEventAlertFail = () => ({
  type: types.UPDATE_EVENT_ALERT_FAIL
})

export const updateMaterialAlert = ({ id, frequency }) => ({
  type: types.UPDATE_MATERIAL_ALERT,
  payload: { id, frequency }
})

export const updateMaterialAlertSuccess = payload => ({
  type: types.UPDATE_MATERIAL_ALERT_SUCCESS,
  payload
})

export const updateMaterialAlertFail = () => ({
  type: types.UPDATE_MATERIAL_ALERT_FAIL
})

export const deleteAlert = ({ id, type }) => ({
  type: types.DELETE_ALERT,
  payload: { id, type }
})

export const deleteAlertSuccess = payload => ({
  type: types.DELETE_ALERT_SUCCESS,
  payload
})

export const deleteAlertFail = () => ({
  type: types.DELETE_ALERT_FAIL
})
