import { Box, Text } from "@engaging-tech/components"
import styled from "styled-components"

export const Body = styled(Box)`
  overflow-y: auto;

  width: 100%;
  height: 100%;
  max-height: 680px;

  padding: 20px;

  @media screen and (max-width: 1080px) {
    overflow-y: hidden;

    max-height: unset;
  }
`

export const JobDescriptionWrapper = styled(Box)`
  width: auto;

  height: 100%;
`

export const JobDescriptionContainer = styled(Box)`
  height: 100%;

  display: flex;
  padding: 14px 16px 24px;

  @media screen and (max-width: 1080px) {
    padding: 14px 0 24px;
  }
`

export const JobDescriptionContent = styled(Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #000000;
`
