import React from "react"
import { Text, Flex, Box, Icon } from "@engaging-tech/components"
import styled from "styled-components"
import PrimaryButton from "../../ui/components/PrimaryButton"
import TextButton from "../../ui/components/TextButton"
import ProgressBar from "../../ui/components/ProgressBar"
import WidgetCard from "./WidgetCard"
import CircleIcon from "../../ui/components/CircleIcon"

const EmptyBox = styled(Box)`
  border: 1px solid ${({ theme }) => `${theme.colors.primary[0]}`};
  border-radius: 7px;
  height: 115px;
  width: 170px;
  background-color: ${({ theme }) => `${theme.colors.dark[6]}`};
  margin: 10px;
  @media (max-width: 414px) {
    width: 155px;
    height: 70px;
  }
  @media (max-width: 330px) {
    width: 145px;
    height: 70px;
  }
`

const GoalBox = styled(Box)`
  border-radius: 7px;
  height: 115px;
  width: 170px;
  background-color: rgba(204, 251, 226, 1);
  padding-top: 3px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  @media (max-width: 414px) {
    padding-left: 5px;
    padding-right: 5px;
    width: 155px;
    height: 70px;
  }
  @media (max-width: 330px) {
    padding-left: 5px;
    padding-right: 5px;
    width: 145px;
    height: 70px;
  }
`

const ShadowGoalBox = styled(GoalBox)`
  box-shadow: 1px 3px 8px ${({ theme }) => `${theme.colors.dark[4]}`};
`

const OutlineGoalBox = styled(GoalBox)`
  margin: 10px;
  border: 1px solid ${({ theme }) => `${theme.colors.primary[0]}`};
`

const GoalText = styled(Text)`
  display: block;
  font-size: 15px;
  color: ${({ theme }) => `${theme.colors.primary[0]}`};
  width: 100%;
  @media (max-width: 414px) {
    width: 99%;
    font-size: 11.5px;
    line-height: -5px;
  }
`

const MobileCard = styled(WidgetCard)`
  background-color: ${({ theme }) => `${theme.colors.light[0]}`};
  padding: 12px;
  margin: 12px;
`

const MobileGoals = ({
  setChosenGoals,
  chosenGoals,
  goalsLeftToChoose,
  allGoals,
  myGoals,
  setGoalsLeftToChoose,
  seenBefore,
  toggle,
  updateSeenBefore,
  updateMyGoals,
  chosenGoalsIdsArray,
  setShowSecondOnboarding
}) => {
  return (
    <Flex
      flexDirection="column"
      width="100vw"
      bg="dark.5"
      height="100vh"
      alignItems="center"
      style={{ overflowY: "auto" }}
    >
      <Flex bg="tertiary.0" width={1 / 1} p={3} alignItems="center">
        <Icon
          name="close"
          mr={3}
          size={30}
          color="primary.0"
          onClick={
            seenBefore
              ? () => toggle()
              : () => {
                  updateSeenBefore(true)
                }
          }
        />
        <ProgressBar
          height="10px"
          percentage={70}
          backgroundColor="light.0"
          primaryColor="primary.0"
          borderRadius={3}
        />
      </Flex>

      <MobileCard
        flexDirection="row"
        flexWrap="wrap"
        width="auto"
        height="400px !important"
        justifyContent="space-between"
      >
        <Flex flexDirection="column" height="15%">
          <Text fontSize={2} mb={2} fontWeight={600}>
            Get started by selecting the goals below to start your Career
            Developer journey.
          </Text>
          <Text fontSize={1} mb={2}>
            *Select a minimum of 3 goals to get started
          </Text>
        </Flex>
        <Flex
          width="100%"
          flexWrap="wrap"
          justifyContent="center"
          height="80%"
          mt="3%"
          style={{ overflowY: "auto" }}
        >
          {myGoals &&
            allGoals.data.length > 0 &&
            goalsLeftToChoose.map(goal => (
              <ShadowGoalBox
                m={2}
                key={goal.id}
                onClick={
                  chosenGoals.length === 5
                    ? null
                    : () => {
                        setGoalsLeftToChoose(
                          goalsLeftToChoose.filter(el => el.id !== goal.id)
                        )
                        setChosenGoals([...chosenGoals, goal])
                      }
                }
              >
                <GoalText>{goal.text}</GoalText>
              </ShadowGoalBox>
            ))}
        </Flex>
      </MobileCard>
      <MobileCard
        flexDirection="row"
        flexWrap="wrap"
        width="auto"
        justifyContent="space-between"
        style={{ marginBottom: "80px" }}
      >
        <Flex width={1 / 1} alignItems="center">
          <CircleIcon
            name="work"
            bg="rgba(204, 251, 226, 1)"
            color="primary.0"
            mr={3}
          />
          <Text fontSize={4} fotWeight={600}>
            Your Goals
          </Text>
        </Flex>
        <Flex width="100%" flexWrap="wrap" justifyContent="center">
          {chosenGoals[0] ? (
            <OutlineGoalBox
              onClick={() => {
                setGoalsLeftToChoose([...goalsLeftToChoose, chosenGoals[0]])
                setChosenGoals(
                  chosenGoals.filter(el => el.id !== chosenGoals[0].id)
                )
              }}
            >
              <GoalText>{chosenGoals[0].text}</GoalText>
            </OutlineGoalBox>
          ) : (
            <EmptyBox />
          )}
          {chosenGoals[1] ? (
            <OutlineGoalBox
              onClick={() => {
                setGoalsLeftToChoose([...goalsLeftToChoose, chosenGoals[1]])
                setChosenGoals(
                  chosenGoals.filter(el => el.id !== chosenGoals[1].id)
                )
              }}
            >
              <GoalText>{chosenGoals[1].text}</GoalText>
            </OutlineGoalBox>
          ) : (
            <EmptyBox />
          )}

          {chosenGoals[2] ? (
            <OutlineGoalBox
              onClick={() => {
                setGoalsLeftToChoose([...goalsLeftToChoose, chosenGoals[2]])
                setChosenGoals(
                  chosenGoals.filter(el => el.id !== chosenGoals[2].id)
                )
              }}
            >
              <GoalText>{chosenGoals[2].text}</GoalText>
            </OutlineGoalBox>
          ) : (
            <EmptyBox />
          )}
          {chosenGoals[3] ? (
            <OutlineGoalBox
              onClick={() => {
                setGoalsLeftToChoose([...goalsLeftToChoose, chosenGoals[3]])
                setChosenGoals(
                  chosenGoals.filter(el => el.id !== chosenGoals[3].id)
                )
              }}
            >
              <GoalText>{chosenGoals[3].text}</GoalText>
            </OutlineGoalBox>
          ) : (
            <EmptyBox display={chosenGoals[2] ? "block" : "none"} />
          )}
          {chosenGoals[4] ? (
            <OutlineGoalBox
              onClick={() => {
                setGoalsLeftToChoose([...goalsLeftToChoose, chosenGoals[4]])
                setChosenGoals(
                  chosenGoals.filter(el => el.id !== chosenGoals[4].id)
                )
              }}
            >
              <GoalText>{chosenGoals[4].text}</GoalText>
            </OutlineGoalBox>
          ) : (
            <EmptyBox display={chosenGoals[3] ? "block" : "none"} />
          )}
        </Flex>

        <Flex justifyContent="space-between" mt={4}>
          {chosenGoals.length > 0 && (
            <TextButton
              mr={4}
              onClick={() => {
                setChosenGoals([])
                setGoalsLeftToChoose([...allGoals.data])
              }}
            >
              RESET SELECTION
            </TextButton>
          )}
          <PrimaryButton
            width="120px"
            disabled={chosenGoals.length < 3}
            onClick={
              seenBefore
                ? () => {
                    updateMyGoals(chosenGoalsIdsArray)
                    toggle()
                  }
                : () => setShowSecondOnboarding(true)
            }
          >
            CONFIRM
          </PrimaryButton>
        </Flex>
      </MobileCard>
    </Flex>
  )
}

export default MobileGoals
