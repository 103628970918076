import { connect } from "react-redux"

import MatrixComponent from "../components/ResultsMatrix"
import { getMatrix, getMatrixInfo } from "../store/selectors"

const mapState = (state, { name, showAction = true }) => ({
  matrixData: getMatrix(state, name),
  matrixInfo: getMatrixInfo(name),
  showAction
})

const Matrix = connect(mapState)(MatrixComponent)

export default Matrix
