import { Card, Flex, Modal, Paragraph, Text } from "@engaging-tech/components"
import React from "react"
import { createPortal } from "react-dom"
import styled from "styled-components"

import { getLabelData } from "../../../../../../lib/utils"
import ExplanationTopBar from "../../../../../surveyResults/HappinessSixSteps/components/ExplanationTopBar"
import PercentageGraphic from "../../../../../ui/components/PercentageGraphic"

const StyledFlex = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[5]};
  margin-bottom: ${({ theme }) => theme.space[3]}px;

  :last-child {
    border-bottom: none;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
`

const StyledModal = styled(Modal)`
  box-shadow: 0px 12px 24px ${({ theme }) => theme.colors.dark[3]};
`

const appRoot = () => typeof document !== "undefined" && document.getElementById("root")

export default function Explanation({ setVisibility, heading, score, breakdown }) {
  return createPortal(
    <StyledModal
      bg="light.0"
      onToggle={() => setVisibility(false)}
      width="auto"
      maxWidth="617px"
      animated
      disableScrollbar
    >
      <Flex flexDirection="column" width={1 / 1} alignItems="center">
        <ExplanationTopBar heading={heading} setVisibility={setVisibility} />
        <Card width={1 / 1} alignItems="center" px={[3, 5]}>
          <PercentageGraphic score={score} mb={5} />
          <Paragraph mb={5} textAlign="center" fontSize={3}>
            These three questions combine to make up your overall score for {heading}:
          </Paragraph>
          <Flex flexDirection="column" bg="dark.7" p={5} mb={5}>
            {breakdown.map((explanation, i) => (
              <StyledFlex pb={3} flexDirection="row" key={explanation.question}>
                <Text width="52px" fontSize={3}>
                  {i + 1}
                </Text>
                <Text fontSize={3}>{explanation.question}</Text>
                <Text fontSize={3} width="50px" color={getLabelData(score).color}>
                  {Math.round(explanation.score)}%
                </Text>
              </StyledFlex>
            ))}
          </Flex>
          <Paragraph fontSize={3} textAlign="center" mb={4}>
            If your score for {heading} indicates scope for improvement then you should look at the scores for
            each questions to pinpoint where any issues lie.
          </Paragraph>
        </Card>
      </Flex>
    </StyledModal>,
    appRoot()
  )
}
