import React from "react"
import { Text, Box, UserDetails } from "@engaging-tech/components"

import Attachment from "../../Attachments"
import Post from "../../Post"

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const LectureCard = ({ lecture, interactions }) => (
  <Post.Card bg="light.0">
    <Post.Header bg="secondary.1" mb={3} title="lecture" />
    <Post.Content>
      <UserDetails
        userHoldingImage={profilePlaceholder}
        user={lecture.user}
        time={lecture.publishedAt}
        interactions={interactions}
      />
      <Box>
        <Box mb={1}>
          <Text fontWeight="500">{lecture.title}</Text>
        </Box>
        <Box mb={2}>
          {lecture.series && (
            <Text color="dark.2" fontSize={3}>
              {lecture.series.title}
            </Text>
          )}
        </Box>
        <Box>
          <Text color="dark.2" lineHeight="1.4">
            {lecture.summary}
          </Text>
        </Box>
      </Box>
      {lecture.content.map(video => (
        <Attachment.Video
          mb={2}
          url={video.source}
          thumbnail={lecture.coverImage.source}
          key={video.source}
        />
      ))}
    </Post.Content>
  </Post.Card>
)

export default LectureCard
