/* eslint-disable radix */
export const operators = {
  answered: ["radio", "dropdown", "multipleChoice", "scale", "short-text"],
  skipped: ["radio", "dropdown", "multipleChoice", "scale", "short-text"],
  selected: ["radio", "dropdown", "multipleChoice"],
  "not-selected": ["radio", "dropdown", "multipleChoice"],
  "less-than": ["scale"],
  equals: ["scale"],
  "not-equals": ["scale"],
  "greater-than": ["scale"]
}

const evaluateLogicRow = (answeredValue, operator, value) => {
  switch (operator) {
    case "answered":
      return !!answeredValue
    case "skipped":
      return !answeredValue
    case "selected":
      return answeredValue instanceof Array
        ? answeredValue.includes(value)
        : answeredValue === value
    case "not-selected":
      return answeredValue instanceof Array
        ? !answeredValue.includes(value)
        : answeredValue === value
    case "less-than":
      return answeredValue instanceof Array
        ? answeredValue[0] < parseInt(value)
        : answeredValue < parseInt(value)
    case "equals":
      return answeredValue instanceof Array
        ? answeredValue[0] === parseInt(value)
        : answeredValue === parseInt(value)
    case "not-equals":
      return answeredValue instanceof Array
        ? answeredValue[0] !== parseInt(value)
        : answeredValue !== parseInt(value)
    case "greater-than":
      return answeredValue instanceof Array
        ? answeredValue[0] > parseInt(value)
        : answeredValue > parseInt(value)
    default:
      return true
  }
}
const evaluateLogic = (logicArray, allAnswers) => {
  return logicArray.reduce((result, logic) => {
    const { questionId, operator, value, condition } = logic
    const answer = allAnswers.find(a => a.questionId === questionId)
    const answeredValue = answer?.value
    const evaluation = evaluateLogicRow(answeredValue, operator, value)
    if (condition === "and") {
      return result && evaluation
    }
    return result || evaluation
  }, false)
}
export const isChoiceVisible = (val, allAnswers, choiceLogics) => {
  const choiceId = val.id
  const choiceLogic = choiceLogics?.find(
    logic => logic.appliedChoiceId === choiceId
  )
  if (!choiceLogic) return true
  const result = evaluateLogic(choiceLogic.logic, allAnswers)
  return result
}
export const isQuestionVisible = (
  idx,
  allAnswers,
  questionLogics,
  setAnswer
) => {
  const id = idx.toString()
  const questionLogic = questionLogics?.find(
    logic => logic.appliedQuestionId === id
  )
  if (!questionLogic) return true
  setAnswer(null)
  const result = evaluateLogic(questionLogic.logic, allAnswers)
  return result
}
