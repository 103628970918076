import { connect } from "react-redux"
import { AccountSelectors } from "../../account"
import GetNotificationsComponent from "../components/GetNotifications"
import { notificationsSelectors, notificationsActions } from ".."
import { getIsPremium } from "../../yourProfile/store/yourProfile.selectors"
import { MiscSelectors } from "../../misc"

const mapState = state => ({
  isAuthenticated: AccountSelectors.getIsAuthenticated(state),
  success: notificationsSelectors.getAlertSuccess(state),
  fail: notificationsSelectors.getHasError(state),
  latestAlert: notificationsSelectors.getLatestAlert(state),
  isPremium: getIsPremium(state),
  industries: MiscSelectors.getAllIndustries(state)
})

const mapDispatch = dispatch => ({
  toggle: () => dispatch(notificationsActions.toggle())
})

const GetNotifications = connect(
  mapState,
  mapDispatch
)(GetNotificationsComponent)

export default GetNotifications
