const homepageFeatures = [
  {
    title: "Communicate",
    icon: "forum",
    points: [
      {
        heading: "Global Hub",
        description:
          "The Global Hub brings the world of business to you and takes you to the world : post content, ask for expert advice, watch and listen to lectures and tutorials, read articles, forthcoming events, view jobs."
      },
      {
        heading: "Messenger",
        description:
          "Securely communicate with your business contacts. Build your community with project groups. (Also available on iOS and Android)"
      },
      {
        heading: "Video and conference calling",
        description:
          "Available as a subscription for £1.99 per quarter you can make unlimited calls with unlimited participants."
      },
      {
        heading: "Email",
        description:
          "Use an WorkL email address or your own to have all your business communications in one place. - Coming Soon",
        isComingSoon: true
      },
      {
        heading: "Mentoring",
        description: "Register to be a mentor or mentee and connect via our messenger service."
      }
    ],
    action: {
      description: "Get Started",
      url: "/messenger"
    },
    image: "communicate-and-connect"
  },
  {
    title: "Find work",
    icon: "shopping-bags",
    points: [
      {
        heading: "Career tests",
        description: "Take our career tests to discover what’s your ideal career choice."
      },
      {
        heading: "CV maker",
        description: "Create the perfect CVs and adjust them to the company you want to apply to."
      },
      {
        heading: "Job matcher",
        description:
          "Complete your profile to be matched to job vacancies registered by employers. Our job matcher will suggest which sectors and companies might be a good fit for you."
      },
      {
        heading: "Job posts",
        description:
          "Employers post jobs on the Global Hub. As part of our premium service you will be notified of job posts you believe relevant to you."
      }
    ],
    action: {
      description: "Get Started",
      url: "/find-work"
    },
    image: "find-work"
  },
  {
    title: "Career developer",
    icon: "boost",
    points: [
      {
        heading: "Workplace happiness survey",
        description:
          "Our survey helps you understand where you can improve your workplace satisfaction and skills."
      },
      {
        heading: "Test yourself",
        description: "Take Career, EQ, IQ and Brain Teaser tests to discover your talents."
      },
      {
        heading: "Mentoring",
        description: "Match with your perfect mentor or mentee by completing your user profile."
      },
      {
        heading: "Global Hub",
        description:
          "Offers extensive opportunities to build your knowledge and skills: watch and listen to lectures and tutorials, read articles, ask for expert advice and view forthcoming events."
      },
      {
        heading: "Business Library",
        description:
          "Build your business Library with our collection of ebooks, books, videos or audio articles and receive free ebooks and offer as part of our premium service."
      },
      {
        heading: "Career diary",
        description: "Set out your plans and objectives, store your CV and test results."
      }
    ],
    action: {
      description: "Get Started",
      url: "/develop-career"
    },
    image: "develop-career"
  },
  {
    title: "Business Library",
    icon: "shopping-bags",
    points: [
      {
        heading: "Upload podcasts, articles, research and lectures",
        description:
          "Upload podcasts, articles, research and lectures to the Business Library for others to read and have your content promoted on the Global Hub."
      },
      {
        heading: "Save articles, podcasts, lectures and research",
        description:
          "Browse the Business Library and Global Hub to bookmark articles, lectures, podcasts and research to your own library."
      },
      {
        heading: "Buy business and educational books",
        description: "Browse the online business library and buy books and ebooks."
      },
      {
        heading: "Marketplace - coming soon",
        description:
          "Buy and sell what you need for your working life such as property, stationary, hardware and furniture."
      }
    ],
    action: {
      description: "Get Started",
      url: "/business-library"
    },
    image: "buy-and-sell"
  }
]

export default homepageFeatures
