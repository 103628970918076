import { Button, Flex, UserDetails } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import Attachment from "../../../userGeneratedContent/components/Attachments"
import AttachmentInput from "../attachmentInput"
import { TextAreaCustom } from "../shared/styled"

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const FullWidthForm = styled.form`
  width: 100%;
`

const attachmentInputs = [
  {
    id: "file",
    mimeType: "application/pdf",
    icon: "insert_drive_file"
  },
  {
    id: "video",
    mimeType: "video/mp4",
    icon: "video_call"
  },
  {
    id: "image",
    mimeType: "image/jpeg,image/png",
    icon: "photo"
  }
]

const Form = ({
  user,
  handleSubmit,
  errors,
  touched,
  values,
  isValid,
  setValues,
  handleChange,
  handleBlur,
  handleClose,
  isLoading
}) => {
  const handleAttachment = attachment => {
    if (attachment) {
      setValues({ ...values, attachments: [...values.attachments, attachment] })
    }
  }

  const removeAttachment = attachment => {
    setValues({
      ...values,
      attachments: values.attachments.filter(file => file.name !== attachment.name)
    })
  }

  return (
    <FullWidthForm onSubmit={handleSubmit}>
      <Flex width="100%">
        <Flex width="calc(100% - 150px)">
          <UserDetails user={values.anonymous ? null : user} userHoldingImage={profilePlaceholder} />
        </Flex>
        <Flex width={150} alignItems="flex-end" justifyContent="center">
          {attachmentInputs.map(input => (
            <AttachmentInput
              key={input.id}
              id={input.id}
              icon={input.icon}
              accept={input.mimeType}
              onSelect={handleAttachment}
            />
          ))}
        </Flex>
      </Flex>

      <TextAreaCustom
        id="create-post"
        name="content"
        value={values.content}
        error={touched.content && errors.content}
        onChange={handleChange}
        onBlur={handleBlur}
        width={1 / 1}
        label="Create a post"
        height={[96, 96, 192]}
        autoFocus
      />

      {values.attachments.length > 0 && (
        <Flex mb={3} flexDirection="column">
          {values.attachments.map(attachment => (
            <Attachment.File
              name={attachment.name}
              key={attachment.name}
              onDismiss={isLoading ? null : () => removeAttachment(attachment)}
            />
          ))}
        </Flex>
      )}

      <Flex flexDirection="row-reverse" flexWrap="wrap" mt={3}>
        <Button
          width="auto"
          variant="primary.0"
          type="submit"
          ml={[3, 3, 2]}
          disabled={isLoading || !isValid}
        >
          Publish
        </Button>
        <Button width="auto" type="button" variant="text.dark.2" elevation={0} onClick={handleClose}>
          Cancel
        </Button>
      </Flex>
    </FullWidthForm>
  )
}

export default Form
