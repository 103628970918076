import { call, put, takeLatest } from "redux-saga/effects"

import { createCV, deleteCV, loadCVs, loadSelectedCV, setDefaultCV } from "../services/cvLibrary.service"
import * as Actions from "./cvLibrary.actions"

export function* loadCVsSaga(action) {
  try {
    if (action?.payload?.place === "in-app-view") {
      const res = yield call(loadCVs, { place: action.payload.place })
      yield put(Actions.loadCVsSuccess(res?.data?.getCVs))
    } else {
      const res = yield call(loadCVs, { place: "" })
      yield put(Actions.loadCVsSuccess(res?.data?.getCVs))
    }
  } catch {
    yield put(Actions.loadCVsFail())
  }
}

export function* loadSelectedCVSaga(action) {
  try {
    if (action?.payload?.place === "in-app-view") {
      const res = yield call(loadSelectedCV, { id: action.payload.id, place: action.payload.place })
      yield put(Actions.loadSelectedCVSuccess(res.data.getSelectedCV))
    } else {
      const res = yield call(loadSelectedCV, { id: action.payload.id, place: "" })
      yield put(Actions.loadSelectedCVSuccess(res.data.getSelectedCV))
    }
  } catch {
    yield put(Actions.loadSelectedCVFail())
  }
}

export function* deleteCVSaga(action) {
  try {
    if (action?.payload?.place === "in-app-view") {
      const res = yield call(deleteCV, action.payload)
      if (res?.data?.deleteCV) {
        yield put(Actions.loadCVs({ place: action.payload.place }))
      }
    } else {
      const res = yield call(deleteCV, { place: "", ...action.payload })
      if (res?.data?.deleteCV) {
        yield put(Actions.loadCVs({ place: "" }))
      }
    }
  } catch {
    yield put(Actions.setHasError("delete CV"))
  }
}

export function* setDefaultCVSaga(action) {
  try {
    if (action?.payload?.place === "in-app-view") {
      const res = yield call(setDefaultCV, action.payload)
      if (res?.data?.setDefaultCV) {
        yield put(Actions.loadCVs({ place: action.payload.place }))
      }
    } else {
      const res = yield call(setDefaultCV, { place: "", ...action.payload })
      if (res?.data?.setDefaultCV) {
        yield put(Actions.loadCVs({ place: "" }))
      }
    }
  } catch {
    yield put(Actions.setHasError("set default CV"))
  }
}

const removeFields = array =>
  array?.map(el => {
    const newEl = el
    if (newEl.id) delete newEl.id
    if (newEl.endDate === "") delete newEl.endDate
    if (newEl.end === "") delete newEl.end
    return newEl
  })

const deleteTypeNames = source => {
  if (typeof source !== "object" || source === null) return source

  if (Array.isArray(source)) {
    return source.map(element => deleteTypeNames(element))
  }

  const newObj = {}
  Object.keys(source).forEach(key => {
    if (key !== "__typename") {
      if (Array.isArray(source[key])) {
        newObj[key] = source[key].map(element => deleteTypeNames(element))
      } else if (typeof source[key] === "object") {
        newObj[key] = deleteTypeNames(source[key])
      } else {
        newObj[key] = source[key]
      }
    }
  })
  return newObj
}

const prepareCV = ({ ...cvData }) => {
  const dataToChange = cvData
  deleteTypeNames(cvData)
  delete dataToChange.qualToAdd
  delete dataToChange.positionToAdd
  dataToChange.skills = dataToChange.skills?.filter(skill => skill.id || skill)
  dataToChange.qualifications = removeFields(dataToChange.qualifications)
  dataToChange.positionsHeld = removeFields(dataToChange.positionsHeld)

  dataToChange.positionsHeld = dataToChange.positionsHeld?.map(pos => {
    return {
      ...pos,
      location: pos.location
    }
  })
  dataToChange.references = dataToChange.references.id === 1
  return dataToChange
}

export function* createCVSaga(action) {
  try {
    const submissionData = prepareCV({
      ...action.payload.data,
      address: {
        name: action.payload.data.address.name,
        point: { coordinates: [] },
        country: {
          name: action.payload.data.address.country?.name,
          shortName: action.payload.data.address.name.split(",")[1]
        }
      },
      targetPosition: {
        company: action.payload.data.targetPosition.company,
        jobTitle: action.payload.data.targetPosition.jobTitle
      },
      positionsHeld: action.payload.data.positionsHeld?.map(pos => ({
        jobTitle: pos.jobTitle,
        company: pos.company,
        endDate: pos.endDate,
        startDate: pos.startDate,
        description: pos.description,
        jobRoleId: pos.jobRole,
        industryId: pos.industry,
        location: {
          name: pos.location.name,
          country: {
            name: pos.location.country.name,
            shortName: pos.location.name.split(",")[1]
          }
        }
      })),
      qualifications: action.payload.data.qualifications?.map(qual => ({
        institution: qual.institution,
        courseTitle: qual.courseTitle,
        location: qual.location,
        description: qual.description,
        start: qual.start,
        end: qual.end
      }))
    })

    if (action?.payload?.place === "in-app-view") {
      const res = yield call(createCV, { dataToSend: submissionData, place: "in-app-view" })
      yield put(Actions.createCVSuccess(res.data.setCV))
    } else {
      const res = yield call(createCV, { dataToSend: submissionData })
      yield put(Actions.createCVSuccess(res.data.setCV))
    }
  } catch (error) {
    console.log({ error })
    yield put(Actions.createCVFail())
  }
}

export function* saga() {
  yield takeLatest(Actions.types.LOAD_CVS, loadCVsSaga)
  yield takeLatest(Actions.types.LOAD_SELECTED_CV, loadSelectedCVSaga)
  yield takeLatest(Actions.types.DELETE_CV, deleteCVSaga)
  yield takeLatest(Actions.types.SET_DEFAULT_CV, setDefaultCVSaga)
  yield takeLatest(Actions.types.CREATE_CV, createCVSaga)
}
