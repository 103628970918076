import { gql } from "../../../lib/Apollo"

export const LOAD_ALL = gql`
  query($type: String) {
    myBookmarks(refSubType: $type) {
      createdAt
      refTarget {
        id
        type
        title
        summary
        categories
        coverImage {
          source
          caption
        }
        user {
          firstName
          lastName
          picture {
            small
          }
          jobTitle
        }
        series {
          title
        }
        publishedAt
        access {
          type
          price
        }
      }
    }
  }
`

export const LOAD_BY_ID = gql`
  query($id: ID!) {
    AllMaterials(id: $id) {
      title
      id
      summary
      type
      publishedAt
      coverImage {
        source
        caption
      }
      content {
        type
        source
        body
      }
      user {
        id
        firstName
        lastName
        jobTitle
        picture {
          small
        }
      }
      attachments {
        fileType
        name
      }
      series {
        title
      }
      access {
        type
        price
      }
    }
  }
`
