import React from "react"
import {
  Flex,
  Paragraph,
  Text,
  Modal,
  CustomHooks
} from "@engaging-tech/components"
import { format } from "date-fns"
import { createPortal } from "react-dom"
import styled from "styled-components"
import PrimaryButton from "../../../../ui/components/PrimaryButton"

const StyledModal = styled(Modal)`
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
`

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const CancelledMembershipModal = ({ setVisibility, premiumExpiration }) => {
  CustomHooks.useFreshChatDeactivate()
  return createPortal(
    <StyledModal
      onToggle={() => setVisibility("settings")}
      animated
      maxHeight="80vh"
      mb={100}
    >
      <Flex
        bg="light.0"
        width={1}
        px={[3, 4]}
        py={5}
        flexDirection="Column"
        alignItems="center"
      >
        <Text
          color="primary.0"
          fontSize={[5, 6]}
          fontWeight={700}
          textAlign="center"
        >
          Your Premium Membership has been cancelled
        </Text>
        <Paragraph color="dark.1" my={4} fontSize={[4, 5]}>
          {`We’re sorry to see you go. You will still be able to use our Premium features until ${
            premiumExpiration
              ? format(new Date(premiumExpiration), "MMMM do, yyyy")
              : "the end of the paid subscription period"
          }.`}
        </Paragraph>
        <PrimaryButton width={133} onClick={() => setVisibility("settings")}>
          Close
        </PrimaryButton>
      </Flex>
    </StyledModal>,
    appRoot()
  )
}

export default CancelledMembershipModal
