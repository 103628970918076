import React, { useState } from "react"
import { Avatar, Box, Flex, Text } from "@engaging-tech/components"
import PrimaryButton from "../../ui/components/PrimaryButton"
import OutlinedPrimaryButton from "../../ui/components/OutlinedPrimaryButton"
import ClickableIcon from "../../ui/components/ClickableIcon"

const holdingImage = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const names = {
  industries: "Industries",
  jobRoles: "Job Roles",
  skills: "Skills",
  experience: "Experience",
  languages: "Languages",
  hobbies: "Hobbies"
}

const MatchedCriteriaList = ({ criteria = {} }) => {
  return (
    Object.keys(criteria)
      // .sort()
      .map(
        key =>
          key !== "__typename" && (
            <>
              <Text fontWeight={700} color="dark.2">
                {names[key] || key}
              </Text>

              <Flex mt={3} mb={4}>
                {criteria[key]?.map(item => (
                  <Box
                    mr={2}
                    px={3}
                    py={2}
                    bg="tertiary.0"
                    borderRadius={99}
                    width="auto"
                  >
                    <Text fontSize={3} fontWeight={300}>
                      {typeof item === "string" ? item : item.name}
                    </Text>
                  </Box>
                ))}
              </Flex>
            </>
          )
      )
  )
}

const ButtonsToShow = ({ tabState, showDetails, setShowDetails, connect }) => {
  return (
    <Flex width="auto" flexDirection={["column", "row"]}>
      <OutlinedPrimaryButton
        text={!showDetails ? "View Details" : "Hide Details"}
        onClick={() => setShowDetails(v => !v)}
        width="auto"
        minWidth="max-content"
        px={3}
      />
      {tabState === "matches" && (
        <PrimaryButton
          mt={[2, 0]}
          ml={3}
          width="auto"
          minWidth="max-content"
          text="Connect"
          onClick={connect}
          px={3}
        />
      )}
    </Flex>
  )
}

const DisplayMatch = ({ tabState, match, deleteMatch, connectToMatch }) => {
  const [showDetails, setShowDetails] = useState(false)

  const { score, user, matchedCriteria, id } = match
  const { profile, firstName, lastName } = user
  return (
    <>
      <Flex justifyContent="space-between" width={1 / 1} mb={3}>
        <Avatar picture={profile.picture.small || holdingImage} mr={3} />
        <Flex flexDirection="column">
          <Text fontSize={4}>{`${firstName} ${lastName}`}</Text>
          {profile?.jobTitle && (
            <Text fontSize={3} fontWeight={300}>
              {profile.jobTitle}
            </Text>
          )}
        </Flex>
        <Text fontSize={4} width={1 / 1} mx={1}>
          <strong>{Math.round(score * 100)}%</strong> Match
        </Text>
        {tabState !== "matches" && (
          <Flex flexDirection="row" mb={3} alignItems="center">
            <ClickableIcon
              name="delete"
              color="primary.0"
              onClick={() => deleteMatch(id)}
            />
            <Text fontSize={3} color="primary.0">
              Delete Match
            </Text>
          </Flex>
        )}
        <ButtonsToShow
          tabState={tabState}
          showDetails={showDetails}
          setShowDetails={setShowDetails}
          connect={() => connectToMatch({ matchId: id, userId: user.id })}
        />
      </Flex>
      {showDetails && (
        <>
          <Text mb={3} fontSize={4}>
            Match Rating: <strong>{Math.round(score * 100)}%</strong>
          </Text>
          <Text mb={3} fontSize={3}>
            Your match rating with {firstName} {lastName} is based on the
            following criteria:
          </Text>
          <MatchedCriteriaList criteria={matchedCriteria} />
        </>
      )}
    </>
  )
}

export default DisplayMatch
