export const types = {
  LOAD_JOB_MATCHES: "[Job Matcher] Load Job Matches",
  LOAD_JOB_MATCHES_SUCCESS: "[Job Matcher] Load Job Matches Success",

  SUBMIT: "[Job Matcher] Submit Form Data",
  SUBMIT_SUCCESS: "[Job Matcher] Submitted Data Succesfully",

  SET_HAS_ERROR: "[Job Matcher] Set Has Error",
  DELETE_JOB_MATCH: "[Job Matcher] Delete Job Match"
}

export const loadJobMatches = () => ({
  type: types.LOAD_JOB_MATCHES
})
export const loadJobMatchesSuccess = jobMatches => ({
  type: types.LOAD_JOB_MATCHES_SUCCESS,
  payload: jobMatches
})

export const submit = formDetails => ({
  type: types.SUBMIT,
  payload: formDetails
})
export const submitSuccess = response => ({
  type: types.SUBMIT_SUCCESS,
  payload: response
})

export const deleteJobMatch = jobMatchId => ({
  type: types.DELETE_JOB_MATCH,
  payload: jobMatchId
})

export const setHasError = payload => ({
  type: types.SET_HAS_ERROR,
  payload
})
