import { Checkbox, Flex, LocationAutocomplete, Text, TextField } from "@engaging-tech/components"
import { getCountries } from "@engaging-tech/frontend-utils"
import { Link } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React, { useEffect, useState } from "react"

import PasswordHint from "../components/PasswordHint"

const TermsAndConditionsLabel = () => (
  <Text color="dark.2" fontSize={[4, 3]}>
    I accept the{" "}
    <Link to="/legal/terms-and-conditions">
      <Text color="primary.0" fontWeight="700" fontSize={[4, 3]}>
        terms and conditions
      </Text>
    </Link>{" "}
    and{" "}
    <Link to="/legal/privacy-policy">
      <Text color="primary.0" fontWeight="700" fontSize={[4, 3]}>
        privacy policy
      </Text>
    </Link>
    , including receiving necessary emails such as job post notifications, event notifications and connection
    requests
  </Text>
)

const MarketingLabel = () => (
  <Text color="dark.2" fontSize={[4, 3]}>
    I want to be kept up to date with the best jobs for me, personalised content and networking opportunities
    to help me enhance my career and be happier at work.
  </Text>
)

const MyInfomationSubForm = ({
  device,
  handleChange,
  handleBlur,
  values,
  touched,
  errors,
  setFieldTouched,
  setFieldValue
}) => {
  const [countries, setCountries] = useState([])

  useEffect(() => {
    setCountries(getCountries())
  }, [])

  const handleLocationChange = e => {
    if (e && e.geometry) {
      setFieldValue("location", {
        countryId: countries.filter(
          country =>
            country.code ===
            e.address_components.filter(component => component.types.includes("country"))[0].short_name
        )[0].id,
        name: e.formatted_address,
        point: {
          coordinates: [e.geometry.location.lat(), e.geometry.location.lng()]
        },
        country: {
          shortName: e.address_components.filter(country => country.types.includes("country", "political"))[0]
            .short_name,

          name: e.address_components.filter(country => country.types.includes("country", "political"))[0]
            .long_name
        },
        vicinity: e.vicinity
      })
    }
  }

  return (
    <Flex flexDirection="column" width="100%">
      <Flex mb={2} justifyContent="space-between" flexDirection={["column", "row"]}>
        <TextField
          wrapperProps={{ width: device === "MOBILE" ? "100%" : "48%" }}
          id="sign-up-firstname"
          name="firstName"
          type="text"
          bg="dark.6"
          label="First Name"
          onChange={handleChange}
          onBlur={handleBlur}
          borderRadius="4px 4px 0px 0px"
          value={values.firstName}
          error={touched.firstName && errors.firstName}
        />

        <TextField
          wrapperProps={{ width: device === "MOBILE" ? "100%" : "48%" }}
          id="sign-up-surname"
          name="lastName"
          type="text"
          bg="dark.6"
          label="Surname"
          borderRadius="4px 4px 0px 0px"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.lastName}
          error={touched.lastName && errors.lastName}
        />
      </Flex>
      <Flex justifyContent="center" alignItems="stretch" flexDirection="column" width={1 / 1}>
        <LocationAutocomplete
          width={1 / 1}
          height={60}
          borderRadius="4px 4px 0px 0px"
          bg="dark.6"
          id="location.name"
          name="location.name"
          type="text"
          label=""
          initialValue={values?.location?.name || null}
          placeholder="Location"
          googleApiKey={getConfig().keys.googleApiKey}
          selectedLocationCallback={handleLocationChange}
          onKeyDown={e => {
            if (e.key === "Backspace") {
              setFieldValue("location", {
                countryId: null,
                name: "",
                coordinates: { lat: 0, lng: 0 },
                country: {
                  name: "",
                  shortName: ""
                }
              })
            }
          }}
          onFocus={() => setFieldTouched("location.name")}
          error={touched.location && errors.location && touched.location.name && errors.location.name}
        />
      </Flex>
      <TextField
        width={1 / 1}
        id="sign-up-email"
        name="email"
        type="email"
        label="Email"
        bg="dark.6"
        borderRadius="4px 4px 0px 0px"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        error={touched.email && errors.email}
        wrapperProps={{ mb: 2 }}
      />

      <Flex mb={2} justifyContent="space-between" flexDirection={["column", "row"]}>
        <TextField
          wrapperProps={{ width: device === "MOBILE" ? "100%" : "48%" }}
          id="sign-up-password"
          name="password"
          type="password"
          bg="dark.6"
          label="Password"
          borderRadius="4px 4px 0px 0px"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          error={touched.password && errors.password}
        />

        <TextField
          wrapperProps={{ width: device === "MOBILE" ? "100%" : "48%" }}
          id="sign-up-confirm-password"
          name="confirmPassword"
          type="password"
          bg="dark.6"
          borderRadius="4px 4px 0px 0px"
          label="Confirm Password"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.confirmPassword}
          error={touched.confirmPassword && errors.confirmPassword}
        />
      </Flex>

      <PasswordHint />
      <Checkbox
        mb={3}
        colour="dark.2"
        id="allowMarketing"
        checked={values.allowMarketing}
        onChange={handleChange}
        onBlur={handleBlur}
        label={MarketingLabel}
      />
      <Checkbox
        mb={3}
        colour="dark.2"
        id="acceptConditions"
        checked={values.acceptConditions}
        onChange={handleChange}
        onBlur={handleBlur}
        label={TermsAndConditionsLabel}
      />
    </Flex>
  )
}

export default MyInfomationSubForm
