import { connect } from "react-redux"

import CVLibraryWidgetComponent from "../components/CVLibraryWidget"

import { getIsPremium } from "../../../yourProfile/store/yourProfile.selectors"

import { cvLibrarySelectors } from "../../../cvLibrary"

const mapState = state => ({
  isLoading: cvLibrarySelectors.getIsLoading(state),
  isPremium: getIsPremium(state),
  cvList: cvLibrarySelectors.getCVs(state),
  isLoadingCVs: cvLibrarySelectors.getIsLoadingCVs(state)
})

const CVLibraryWidget = connect(mapState)(CVLibraryWidgetComponent)

export default CVLibraryWidget
