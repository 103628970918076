import React from "react"
import styled from "styled-components"
import { Flex, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"

const googlePlay = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/google-play.png`)
const itunes = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/app-store.svg`)

const Badge = styled.img`
  max-height: 40px;
  margin-right: ${({ theme, hasMargin }) => (hasMargin ? theme.space[2] : 0)}px;
`

const DesktopBadge = styled(Flex)`
  flex-shrink: 0;
  box-shadow: 0 2px 2px ${({ theme }) => theme.colors.dark[4]};
  height: 40px;
  margin-right: ${({ theme, hasMargin }) => (hasMargin ? theme.space[2] : 0)}px;
`

const AdobeBadges = ({ alignment, ...props }) => (
  <Flex {...props}>
    <Flex width="auto" flexDirection="column" alignItems={alignment}>
      <Flex
        flexDirection={["column", "row"]}
        justifyContent="flex-start"
        width="auto"
        flexWrap="wrap"
      >
        <Link
          to="https://apps.apple.com/us/app/adobe-digital-editions/id952977781"
          external
          newTab
        >
          <Badge hasMargin src={itunes} alt="iTunes App Store" />
        </Link>
        <Link
          to="https://play.google.com/store/apps/details?id=com.adobe.digitaleditions"
          external
          newTab
        >
          <Badge hasMargin src={googlePlay} alt="Google Play store" />
        </Link>
        <Link
          to="https://www.adobe.com/solutions/ebook/digital-editions/download.html"
          external
          newTab
        >
          <DesktopBadge
            bg="dark.1"
            borderRadius={8}
            py={2}
            px={3}
            alignItems="center"
            width="auto"
          >
            <Text fontSize={3} fontWeight={500} color="light.0">
              DOWNLOAD FOR PC OR MAC
            </Text>
          </DesktopBadge>
        </Link>
      </Flex>
    </Flex>
  </Flex>
)

AdobeBadges.defaultProps = {
  flexDirection: ["column", "row"],
  justifyContent: ["center", "flex-start", "flex-end"],
  alignItems: "center",
  alignment: ["center", "flex-start"]
}

export default AdobeBadges
