import React, { useState } from "react"
import { createPortal } from "react-dom"
import { Dialog } from "@engaging-tech/components"
import { reportUserMetadata } from "../../../constants/report"
import ReportComponent from "../../../features/reportComponent/Report"

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const ReportUserComponent = ({ id, onCloseParent, onReportUser }) => {
  const [reportReason, setReportReason] = useState()
  const [isDialogueVisible, setIsDialogueVisible] = useState(true)

  const toggleDialog = () => {
    setIsDialogueVisible(!isDialogueVisible)
    onCloseParent()
  }

  const handleReasonChange = reason => setReportReason(reason)
  const submitReportReason = () => {
    reportReason && onReportUser({ id, reason: reportReason })
    setIsDialogueVisible(!isDialogueVisible)
    onCloseParent()
  }

  const dialogActions = [
    {
      label: "confirm",
      action: submitReportReason
    },
    {
      label: "close",
      action: toggleDialog
    }
  ]

  return (
    isDialogueVisible &&
    createPortal(
      <Dialog onToggle={toggleDialog} footer={dialogActions}>
        <ReportComponent
          onReportChange={handleReasonChange}
          reportReason={reportReason}
          reportMetadata={reportUserMetadata}
        />
      </Dialog>,
      appRoot()
    )
  )
}

export default ReportUserComponent
