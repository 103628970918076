import { Spinner } from "@engaging-tech/components"
import { useParams } from "@engaging-tech/routing"
import React, { useEffect, useState } from "react"

import BookmarkToggle from "../../bookmarks/containers/BookmarkToggle"
import LibraryContent from "../../userGeneratedContent/components/BusinessLibrary/Content/Content"
import BillingInformation from "./Payment/BillingInformation"
import PurchasedDialog from "./Payment/PurchasedDialog"

const SelectedPost = ({
  post,
  isLoading,
  onLoad,
  isAuthenticated,
  onSubmit,
  intentSecret,
  onPaymentActionSuccess,
  onPaymentActionFail
}) => {
  const [showBillingInfo, setShowBillingInfo] = useState(false)
  const [showPurchasedPopup, setShowPurchasedPopup] = useState(false)
  const { id } = useParams()

  const handlePurchasedPopup = () => setShowPurchasedPopup(!showPurchasedPopup)

  useEffect(() => {
    onLoad(id)
  }, [id, onLoad])

  const onPurchase = () => {
    setShowBillingInfo(true)
  }

  const onPurchasedSuccess = () => {
    setShowBillingInfo(false)
    onPaymentActionSuccess()
    handlePurchasedPopup()
  }

  const onBack = () => {
    setShowBillingInfo(false)
  }

  if (isLoading) {
    return <Spinner color="primary.0" />
  }

  const paymentAction = intentSecret ? { action: "handle_card_payment", intentSecret } : null

  if (showBillingInfo) {
    return (
      <BillingInformation
        isLoading={isLoading}
        onBack={onBack}
        onSubmit={onSubmit}
        post={post}
        postId={post.id}
        paymentAction={paymentAction}
        onPaymentActionSuccess={onPurchasedSuccess}
        onPaymentActionFail={onPaymentActionFail}
      />
    )
  }

  return (
    post && (
      <>
        <LibraryContent
          post={post}
          isAuthenticated={isAuthenticated}
          onPurchase={onPurchase}
          interactions={() => <BookmarkToggle id={post.id} postType={post.type} />}
        />
        {showPurchasedPopup && <PurchasedDialog handlePurchasedPopup={handlePurchasedPopup} />}
      </>
    )
  )
}

export default SelectedPost
