import { connect } from "react-redux"

import { getSurveyId, getSurveyTitle } from "../../store/surveyResults.selectors"
import PlanBoxComponent from "../components/PlanBox"
import * as Selectors from "../store/actionplans.selectors"

const mapState = (state, { section, furtherReading = true }) => ({
  stepResult: Selectors.getStepResult(state, section),
  surveyTitle: getSurveyTitle(state),
  surveyId: getSurveyId(state),
  furtherReading
})

const PlanBox = connect(mapState)(PlanBoxComponent)

export default PlanBox
