import React, { useState, useEffect } from "react"
import {
  Flex,
  H2,
  Card,
  Spinner,
  Text,
  TabletUp,
  Box
} from "@engaging-tech/components"

import LoadingText from "./LoadingText"

const statuses = [
  "Calculating your Six Steps scores...",
  "Calculating your workplace happiness score...",
  "Comparing your score globally..."
].map(status => <LoadingText key={status}>{status}</LoadingText>)

const HoldingPage = () => {
  const [currentStatus, setCurrentStatus] = useState(0)

  const handleStatus = statusIndex => {
    if (statusIndex === statuses.length - 1) setCurrentStatus(0)
    else setCurrentStatus(statusIndex + 1)
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      handleStatus(currentStatus)
    }, Math.floor(Math.random() * 6) * 1000 + 2000)
    return () => clearTimeout(timer)
  })

  return (
    <Flex
      width={1 / 1}
      maxWidth={1080}
      flexDirection="column"
      alignItems="center"
    >
      <H2 fontSize={[5, 7]} fontWeight={500} fontFamily="serif">
        Thank you for completing the survey!
      </H2>
      <Card
        width={1 / 1}
        minHeight={[210, 420]}
        justifyContent="center"
        alignItems="center"
        elevation={2}
        my={[3, 5]}
        bg="dark.7"
      >
        <Spinner color="primary.0" width={100} height={100} />
        <Text textAlign="center" fontSize={[3, 5]} color="dark.1" mt={16}>
          Crunching the numbers
        </Text>

        {statuses[currentStatus]}
      </Card>
      <TabletUp width={1 / 1}>
        <Card
          width={1 / 1}
          bg="dark.7"
          minHeight={[600]}
          justifyContent="center"
        >
          <Flex justifyContent="space-evenly">
            <Box width={180} height={146} bg="dark.7" />
            <Box width={180} height={146} bg="dark.7" />
            <Box width={180} height={146} bg="dark.7" />
          </Flex>
          <Flex justifyContent="space-evenly" pt={7}>
            <Box width={180} height={146} bg="dark.7" />
            <Box width={180} height={146} bg="dark.7" />
            <Box width={180} height={146} bg="dark.7" />
          </Flex>
        </Card>
      </TabletUp>
    </Flex>
  )
}

export default HoldingPage
