import { TextField } from "@engaging-tech/components"
import React from "react"

import { Container, Wrapper } from "./textSearch.styled"

const TextSearch = ({ placeholder = "", value, onChangeValue }) => {
  const handleInputChange = event => {
    const { value: inputValue } = event.target

    onChangeValue(inputValue)
  }

  return (
    <Wrapper>
      <Container>
        <TextField
          id="searchTextField"
          noLabel
          fontSize={16}
          height={48}
          width={[1 / 1, 265]}
          borderRadius={100}
          noHighlight
          onChange={handleInputChange}
          value={value}
          placeholder={placeholder}
        />
      </Container>
    </Wrapper>
  )
}

export default TextSearch
