import { connect } from "react-redux"

import {
  getLoadFrom,
  getIsLoadingMore,
  getHasLoadedAll,
  getSearchTerm,
  getFilter
} from "../store/businessLibrary.selectors"
import { loadMore } from "../store/businessLibrary.actions"

import LoadMoreButton from "../components/LoadMore"

const mapState = state => ({
  from: getLoadFrom(state),
  isLoadingMore: getIsLoadingMore(state),
  hasLoadedAll: getHasLoadedAll(state),
  searchTerm: getSearchTerm(state),
  categoryId: getFilter(state)
})

const mapDispatch = dispatch => ({
  onLoad: (postType, from, searchTerm, categoryId) =>
    dispatch(
      loadMore({
        postType,
        from,
        searchTerm,
        categoryId
      })
    )
})

const LoadMore = connect(mapState, mapDispatch)(LoadMoreButton)

export default LoadMore
