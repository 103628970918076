import { types } from "./userContent.actions"

export const initialState = {
  isLoading: false,
  isSeriesLoading: false,
  hasCreated: false,
  hasPaid: false,
  hasCreatedSeries: false,
  series: [],
  intentSecret: ""
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_SERIES:
    case types.CREATE_SERIES:
      return { ...state, isSeriesLoading: true }

    case types.CREATE_PURCHASE_MATERIAL_INTENT:
      return { ...state, isLoading: true }

    case types.LOAD_SERIES_SUCCESS:
      return { ...state, series: action.payload, isSeriesLoading: false }

    case types.LOAD_SERIES_FAIL:
    case types.CREATE_SERIES_FAIL:
      return { ...state, isSeriesLoading: false }

    case types.ADD_MATERIAL:
      return { ...state, isLoading: true }

    case types.ADD_MATERIAL_SUCCESS:
      return { ...state, isLoading: false, hasCreated: true }

    case types.ADD_MATERIAL_FAIL:
      return { ...state, isLoading: false }

    case types.RESET_CREATE:
      return { ...state, hasCreated: false, hasCreatedSeries: false }

    case types.CREATE_SERIES_SUCCESS:
      return {
        ...state,
        isSeriesLoading: false,
        series: [...state.series, action.payload],
        hasCreatedSeries: true
      }

    case types.CREATE_PURCHASE_MATERIAL_INTENT_SUCCESS:
      return { ...state, intentSecret: action.payload }

    case types.CREATE_PURCHASE_MATERIAL_INTENT_FAIL:
    case types.PURCHASE_MATERIAL_SUCCESS:
    case types.PURCHASE_MATERIAL_FAIL:
      return { ...state, isLoading: false, intentSecret: "" }

    default:
      return state
  }
}
