import React from "react"
import { Text, Flex, TabletUp } from "@engaging-tech/components"
import { StripeWrapper, BillingForm } from "@engaging-tech/payments"
import { getConfig } from "@engaging-tech/ssr-config"

import BillingSummary from "./BillingSummary"

const STRIPE_KEY = () => getConfig().keys.stripe

const Dialog = ({ title, children }) => (
  <Flex p={3} justifyContent="center" alignItems="center" mb={[6, 0]}>
    <Flex
      maxWidth={560}
      elevation={12}
      p={3}
      bg="light.0"
      borderRadius={3}
      flexDirection="column"
    >
      <Flex mb={3}>
        <Text fontWeight="500" fontSize={5}>
          {title}
        </Text>
      </Flex>
      {children}
    </Flex>
  </Flex>
)

const BillingInformation = ({
  post,
  isLoading,
  postId,
  paymentAction,
  onBack,
  onSubmit,
  onPaymentActionSuccess,
  onPaymentActionFail
}) => (
  <Flex>
    <Dialog width={1 / 1} title="Billing Information">
      <Text mb={2}>
        Please enter your billing information in the form below:
      </Text>
      <Text fontWeight="500" mb={3}>
        This price includes VAT
      </Text>
      <StripeWrapper apiKey={STRIPE_KEY()}>
        <BillingForm
          isLoading={isLoading}
          paymentAction={paymentAction}
          onSubmit={{
            action: source => onSubmit(postId, source),
            text: "Purchase",
            variant: "primary.0"
          }}
          onCancel={{
            action: onBack,
            text: "Cancel"
          }}
          onPaymentActionSuccess={{
            action: onPaymentActionSuccess,
            text: "Success Payment Action"
          }}
          onPaymentActionFail={{
            action: onPaymentActionFail,
            text: "Failed Payment Action"
          }}
        />
      </StripeWrapper>
    </Dialog>
    <TabletUp p={3} width={1 / 1}>
      <BillingSummary post={post} />
    </TabletUp>
  </Flex>
)

export default BillingInformation
