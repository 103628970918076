import { types } from "./mentorMatcher.actions"

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  matches: {
    mentors: [],
    mentees: []
  }
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD:
      return { ...state, isLoading: true, hasLoaded: false }

    case types.LOAD_SUCCESS:
      return {
        ...state,
        matches: action.payload,
        isLoading: false,
        hasLoaded: true
      }

    case types.DELETE_MATCH_SUCCESS:
      return {
        ...state,
        matches: {
          mentors: state.matches.mentors.filter(m => m.id !== action.payload),
          mentees: state.matches.mentees.filter(m => m.id !== action.payload)
        }
      }

    case types.CONNECT_SUCCESS:
      return {
        ...state,
        matches: {
          mentors: state.matches.mentors.map(m =>
            m.id === action.payload ? { ...m, status: "pendingConnection" } : m
          ),
          mentees: state.matches.mentees.map(m =>
            m.id === action.payload ? { ...m, status: "pendingConnection" } : m
          )
        }
      }

    case types.LOAD_FAIL:
      return { ...state, isLoading: false, hasLoaded: true }

    default:
      return state
  }
}
