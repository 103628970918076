import { Box } from "@engaging-tech/components"
import React from "react"

const IsolatedWrapper = ({ children }) => {
  return (
    <Box maxWidth={560} margin="auto" padding={16}>
      {children}
    </Box>
  )
}

export default IsolatedWrapper
