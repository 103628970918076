import { Box, Button, Flex, Icon, Text, Themes } from "@engaging-tech/components"
import { useParams } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled, { ThemeProvider, createGlobalStyle } from "styled-components"
import * as system from "styled-system"

import PoweredBy from "../../public/img/powered-by.png"
import { ConfirmBanner } from "../features/account"
import * as DevelopCareerActions from "../features/developCareer/store/developCareer.actions"
import * as SurveyActions from "../features/survey/store/survey.actions"
import { getBranding, getIsIsolated } from "../features/survey/store/survey.selectors"
import HoldingPage from "../features/surveyResults/components/HoldingPage"
import * as SurveyResultActions from "../features/surveyResults/store/surveyResults.actions"
import {
  getIsIsolatedResultsLoading,
  getResultsResponse
} from "../features/surveyResults/store/surveyResults.selectors"
import Footer from "../features/ui/components/Footer"
import SiteBanner from "../features/ui/components/SiteBanner"
import TestingSiteBanner from "../features/ui/components/TestingSiteBanner"
import Nav from "../features/ui/components/nav"
import { attachUserToSubmissionByTokens } from "../lib/SubmissionTokenCookies"

const DefaultResultsLayout = ({ children, bg, banner: Banner, ...props }) => {
  const { websiteInfoBanner } = getConfig()

  return (
    <>
      <TestingSiteBanner />
      {websiteInfoBanner && <SiteBanner text={websiteInfoBanner} disableClose icon="warning" />}
      <Nav />
      <ConfirmBanner />
      {Banner && <Banner />}
      <Box as="main" id="results-layout" bg={bg} {...props}>
        <Flex as="main" flexDirection="column" minHeight="100vh" alignItems="center">
          {Banner && <Banner />}

          <Flex
            width={1 / 1}
            px={[3, 3, 4]}
            py={5}
            flex="1"
            justifyContent="flex-start"
            flexDirection="column"
            maxWidth={1080}
            {...props}
          >
            {children}
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </>
  )
}

const BrandImage = styled.img.attrs(props => ({
  height: [56, 136],
  padding: props.padded ? ["8px", "20px"] : 0
}))`
  object-fit: contain;
  ${system.padding}
  ${system.height}
`

const Wrapper = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.primary[4]};
  padding: ${props => props.theme.space[3]}px 0;
`

const Container = styled(Flex)`
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 ${props => props.theme.space[3]}px;
  align-items: center;
  justify-content: space-between;
  position: relative;
`

const CloseButton = styled(Box)`
  width: auto;
  position: absolute;
  right: ${props => props.theme.space[3]}px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`

const StyledFlex = styled(Flex)`
  align-items: center;
  justify-content: center;
  gap: 40px;

  @media (max-width: ${props => props.theme.breakpoints[0]}px) {
    flex-direction: column;
    align-items: flex-start;
    padding-right: ${props => props.theme.space[3]}px;
    gap: ${props => props.theme.space[3]}px;
  }
`

function CopyLink({ isCopied, onClickCopyButton, onClickCloseButton }) {
  return (
    <Wrapper>
      <Container>
        <StyledFlex>
          <Text width="fit-content" fontSize={4}>
            To view your results later, copy the link and keep it somewhere safe.
          </Text>
          <ThemeProvider theme={Themes.workL}>
            {!isCopied && (
              <Button variant="primary.0" onClick={onClickCopyButton}>
                <span style={{ display: "flex", alignItems: "center", gap: 4 }}>
                  Copy Link
                  <Icon name="link" color="light.0" />
                </span>
              </Button>
            )}
            {isCopied && (
              <Button variant="outline.textSecondary.0" onClick={onClickCopyButton}>
                <span style={{ display: "flex", alignItems: "center", gap: 4 }}>
                  Link copied
                  <Icon name="check" />
                </span>
              </Button>
            )}
          </ThemeProvider>
        </StyledFlex>
        <CloseButton onClick={onClickCloseButton}>
          <Icon name="close" size={24} />
        </CloseButton>
      </Container>
    </Wrapper>
  )
}

const EXP_TIME = 1000 * 5

function ClearableCopyLink({ link }) {
  const [isCopied, setIsCopied] = useState(false)
  const [expired, setExpired] = useState(false)
  const timeoutRef = useRef()

  useEffect(() => {
    const timeout = timeoutRef.current
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const handleOnClickCopyButton = () => {
    navigator.clipboard.writeText(link)
    setIsCopied(true)
    timeoutRef.current = setTimeout(() => {
      setExpired(true)
    }, EXP_TIME)
  }

  const handleOnClickCloseButton = () => {
    setExpired(true)
  }

  return (
    <>
      {!expired && (
        <CopyLink
          isCopied={isCopied}
          onClickCopyButton={handleOnClickCopyButton}
          onClickCloseButton={handleOnClickCloseButton}
        />
      )}
    </>
  )
}
const GlobalStyle = createGlobalStyle`
    html {
        box-sizing: border-box;
    }

    *, *::before, *::after {
        box-sizing: inherit;
    }

    body {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
`

const AppWrapper = styled.div`
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`

function ResultsIsolatedLayout({ children, loading }) {
  const url = new URL(window.location.href)

  const brand = useSelector(getBranding)

  return (
    <ThemeProvider theme={brand.theme}>
      <GlobalStyle />
      <AppWrapper>
        <Box backgroundColor="light.1">
          <Flex flexDirection="column">
            <Flex
              justifyContent="center"
              alignItems="center"
              height={[56, 136]}
              backgroundColor={brand?.colour || "primary.5"}
            >
              {Boolean(brand.logo.url) && (
                <BrandImage src={brand.logo.url} padded={brand.logo.isFallback} alt="Logo Placeholder" />
              )}
            </Flex>
            {!loading && <ClearableCopyLink link={url.href} />}
            <Flex as="main" flex="1" px={[3, 3, 4]} py={5} maxWidth={1080} mx="auto">
              <ThemeProvider theme={Themes.workL}>{children}</ThemeProvider>
            </Flex>
            <Flex
              marginTop={brand.theme ? ["56px", "112px", "190px"] : "0"}
              backgroundColor="light.1"
              height={60}
              justifyContent="flex-end"
              alignItems="center"
              paddingRight={28}
            >
              <img width={148} height={24} src={PoweredBy} alt="Logo Placeholder" />
            </Flex>
          </Flex>
        </Box>
      </AppWrapper>
    </ThemeProvider>
  )
}

const useSurveyResults = () => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const isIsolatedLoading = useSelector(getIsIsolatedResultsLoading)
  const isIsolated = useSelector(getIsIsolated)

  const surveyResults = useSelector(getResultsResponse)

  const surveyId = surveyResults?.surveyId

  useEffect(() => {
    if (!id) return

    dispatch(SurveyResultActions.load(id))
    dispatch(DevelopCareerActions.loadSurveys())
  }, [dispatch, id])

  useEffect(() => {
    if (!surveyId) return

    dispatch(SurveyActions.load({ id: surveyId }))
  }, [dispatch, surveyId])

  return {
    loading: isIsolatedLoading,
    shouldShowIsolatedContent: isIsolated || isIsolatedLoading
  }
}

function Content({ isolated, ...props }) {
  if (isolated) {
    return <ResultsIsolatedLayout {...props} />
  }

  return <DefaultResultsLayout {...props} />
}

function ResultsLayout({ children }) {
  const { shouldShowIsolatedContent, loading } = useSurveyResults()

  useEffect(() => {
    attachUserToSubmissionByTokens()
  }, [])

  return (
    <Content isolated={shouldShowIsolatedContent} loading={loading}>
      {loading ? <HoldingPage /> : children}
    </Content>
  )
}

export default ResultsLayout
