import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Flex, Button, Text } from "@engaging-tech/components"
import { useTranslation } from "react-i18next"

const CircleButton = styled(Button)`
  border-radius: 99px;
  min-width: 40px;
  min-height: 40px;
  background-color: ${({ theme, isSelected }) =>
    !isSelected && theme.colors.dark[6]};

  &:focus,
  &:active,
  &:hover {
    background-color: ${({ theme, isSelected }) =>
      isSelected && theme.colors.secondary[0]};
  }

  @media only screen and (min-width: 768px) {
    min-width: 32px;
    min-height: 32px;
  }
`
const KEY_CODES = {
  one: 49,
  two: 50,
  three: 51,
  four: 52,
  five: 53,
  six: 54,
  seven: 55,
  eight: 56,
  nine: 57,
  zero: 48
}

const Scale = ({ min, max, onAnswer, initialValue }) => {
  const { t } = useTranslation(["happiness_survey"])
  const scaleValues = Array.from({ length: max - min + 1 }, (_, i) => i)
  const [selected, setSelected] = useState(
    (initialValue && initialValue.value[0]) || null
  )

  const isSelected = value => value === selected

  const handleSelect = value => {
    setSelected(value)
    onAnswer(value)
  }

  const handleKeyPress = event => {
    event.preventDefault()
    const keyCode = event.which
    switch (keyCode) {
      case KEY_CODES.zero:
        handleSelect(0)
        break
      case KEY_CODES.one:
        handleSelect(1)
        break
      case KEY_CODES.two:
        handleSelect(2)
        break
      case KEY_CODES.three:
        handleSelect(3)
        break
      case KEY_CODES.four:
        handleSelect(4)
        break
      case KEY_CODES.five:
        handleSelect(5)
        break
      case KEY_CODES.six:
        handleSelect(6)
        break
      case KEY_CODES.seven:
        handleSelect(7)
        break
      case KEY_CODES.eight:
        handleSelect(8)
        break
      case KEY_CODES.nine:
        handleSelect(9)
        break

      default:
        break
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress)
    return () => window.removeEventListener("keydown", handleKeyPress)
  })

  return (
    <>
      <Flex
        flexWrap="wrap"
        justifyContent={["flex-start", "space-between"]}
        mt={[3, 4]}
        mb={[2, 3]}
      >
        {scaleValues.map(val => (
          <CircleButton
            isSelected={isSelected(val)}
            variant={isSelected(val) ? "secondary.0" : "light.0"}
            elevation={0}
            mr={[2, 0]}
            mb={[2, 0]}
            key={val}
            onClick={() => handleSelect(val)}
          >
            {val}
          </CircleButton>
        ))}
      </Flex>
      <Text color="dark.2" fontSize={1}>
        {min} = {t("questions.disagree", "").toUpperCase()}{" "}
        {t("ui.and", "")} {max} ={" "}
        {t("questions.agree", "").toUpperCase()}
      </Text>
    </>
  )
}

export default Scale
