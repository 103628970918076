import React from "react"
import styled from "styled-components"
import { Link, useLocation } from "@engaging-tech/routing"
import { Card, H1, Flex, Button } from "@engaging-tech/components"
import { goBack } from "@engaging-tech/frontend-utils"

import Disclaimer from "./Disclaimer"

const FormWrapper = styled.form`
  width: inherit;
`

const AdviceFormCard = ({
  title,
  onSubmit,
  disableSubmitWhen,
  postType,
  children
}) => {
  const { pathname } = useLocation()

  const { url } = goBack(pathname)

  return (
    <Card bg="light.0" p={3} mb={4}>
      <H1 fontSize={4} fontWeight="500" mb={4}>
        {title}
      </H1>
      <FormWrapper onSubmit={onSubmit}>
        {children}
        <Disclaimer postType={postType} />
        <Flex width={1 / 1} flexDirection="row-reverse" alignItems="center">
          <Button
            maxWidth={168}
            type="submit"
            variant="primary.0"
            ml={3}
            disabled={disableSubmitWhen}
          >
            Submit
          </Button>
          <Link to={url}>
            <Button width="auto" variant="text.dark.2" elevation={0}>
              Cancel
            </Button>
          </Link>
        </Flex>
      </FormWrapper>
    </Card>
  )
}

export default AdviceFormCard
