export const types = {
  LOAD: "[Your Profile] Load",
  LOAD_SUCCESS: "[Your Profile] Load Success",
  LOAD_FAIL: "[Your Profile] Load Fail",
  CREATE_PROFILE: "[Your Profile] Create Your Profile",
  CREATE_PROFILE_SUCCESS: "[Your Profile] Create Your Profile Success",
  CREATE_PROFILE_FAIL: "[Your Profile] Create Your Profile Fail",
  UPDATE_YOUR_PROFILE: "[Your Profile] Update Your Profile",
  UPDATE_YOUR_PROFILE_SUCCESS: "[Your Profile] Update Your Profile Success",
  UPDATE_YOUR_PROFILE_FAIL: "[Your Profile] Update Your Profile Fail",
  SET_HAS_ERROR: "[Your Profile] Set Has Error",
  UPLOAD_IMAGE: "[Your Profile] Upload Image",
  UPLOAD_IMAGE_SUCCESS: "[Your Profile] Upload Image Success",
  UPLOAD_IMAGE_FAIL: "[Your Profile] Upload Image Fail",
  SET_SHOW_MODAL: "[Your Profile] Set Show Modal",
  TOGGLE_SERVICES: "[Your Profile] Toggle Services",
  TOGGLE_SERVICES_SUCCESS: "[Your Profile] Toggle Services Success",
  CONFIRM_EMAIL: "[Your Profile] Confirm Email",
  CONFIRM_EMAIL_SUCCESS: "[Your Profile] Confirm Email Success",
  CONFIRM_EMAIL_FAIL: "[Your Profile] Confirm Email Fail",
  REQUEST_CONFIRMATION: "[Your Profile] Request Confirmation Email",
  REQUEST_CONFIRMATION_SUCCESS: "[Your Profile] Request Confirmation Success",
  REQUEST_CONFIRMATION_FAIL: "[Your Profile] Request Confirmation Fail",
  UPDATE_MY_GOALS: "[Your Profile] Update My Goals",
  UPDATE_GOALS_VIEWED: "[Your Profile] Update Goals Viewed",
  SUBSCRIBE: "[Your Profile Premium] Subscribe",
  SUBSCRIBE_SUCCESS: "[Your Profile Premium] Subscribe Success",
  SUBSCRIBE_PENDING: "[Your Profile Premium] Subscribe Pending",
  SUBSCRIBE_FAIL: "[Your Profile Premium] Subscribe Fail",
  APPLY_DISCOUNT: "[Premium] Apply Discount",
  APPLY_DISCOUNT_SUCCESS: "[Premium] Apply Discount Success",
  APPLY_DISCOUNT_FAIL: "[Premium] Apply Discount Fail",
  SET_STATUS: "[Your Profile Premium] Set Status",
  SET_ATTRIBUTES: "[Your Profile Premium] Set Attributes",
  CANCEL_PREMIUM_SUBSCRIPTION: "[Your Profile Premium] Cancel Subscription",
  CANCEL_PREMIUM_SUBSCRIPTION_SUCCESS: "[Your Profile Premium] Cancel Subscription Success",
  CANCEL_PREMIUM_SUBSCRIPTION_FAILURE: "[Your Profile Premium] Cancel Subscription Failure",
  SET_SHOW_SUCCESS_PAGE: "[Your Profile Premium] Set Show Success Page",
  RESET_ERRORS: "[Your Profile Premium] Clear Errors",
  RESET: "[Your Profile Premium] Reset",
  CLEAR_PROFILE: "[Your Profile] Clear Profile"
}

export const clearProfile = () => ({ type: types.CLEAR_PROFILE })

export const load = payload => ({
  type: types.LOAD,
  payload
})

export const loadSuccess = payload => ({
  type: types.LOAD_SUCCESS,
  payload
})

export const loadFail = payload => ({
  type: types.LOAD_FAIL,
  payload
})

export const uploadImage = payload => ({
  type: types.UPLOAD_IMAGE,
  payload
})

export const uploadImageSuccess = payload => ({
  type: types.UPLOAD_IMAGE_SUCCESS,
  payload
})

export const createProfile = payload => ({
  type: types.CREATE_PROFILE,
  payload
})

export const createProfileSuccess = payload => ({
  type: types.CREATE_PROFILE_SUCCESS,
  payload
})

export const createProfileFail = payload => ({
  type: types.CREATE_PROFILE_FAIL,
  payload
})

export const uploadImageFail = () => ({
  type: types.UPLOAD_IMAGE_FAIL
})

export const updateYourProfile = payload => ({
  type: types.UPDATE_YOUR_PROFILE,
  payload
})
export const setHasError = payload => ({
  type: types.SET_HAS_ERROR,
  payload
})

export const updateYourProfileSuccess = payload => ({
  type: types.UPDATE_YOUR_PROFILE_SUCCESS,
  payload
})

export const updateYourProfileFail = () => ({
  type: types.UPDATE_YOUR_PROFILE_FAIL
})

export const setShowModal = payload => ({
  type: types.SET_SHOW_MODAL,
  payload
})

export const toggleServices = payload => ({
  type: types.TOGGLE_SERVICES,
  payload
})

export const toggleServicesSuccess = payload => ({
  type: types.TOGGLE_SERVICES_SUCCESS,
  payload
})

/** CONFIRM EMAIL */
export const confirmEmail = confirmToken => ({
  type: types.CONFIRM_EMAIL,
  payload: confirmToken
})
export const confirmEmailSuccess = () => ({ type: types.CONFIRM_EMAIL_SUCCESS })
export const confirmEmailFail = () => ({ type: types.CONFIRM_EMAIL_FAIL })
export const requestConfirmation = () => ({
  type: types.REQUEST_CONFIRMATION
})
export const requestConfirmationSuccess = notificationCode => ({
  type: types.REQUEST_CONFIRMATION_SUCCESS,
  payload: notificationCode
})
export const requestConfirmationFail = () => ({
  type: types.REQUEST_CONFIRMATION_FAIL
})

export const updateMyGoals = payload => ({
  type: types.UPDATE_MY_GOALS,
  payload
})

// PREMIUM //
export const subscribe = payload => ({
  type: types.SUBSCRIBE,
  payload
})

export const subscribeSuccess = () => ({
  type: types.SUBSCRIBE_SUCCESS
})

export const subscribePending = intent => ({
  type: types.SUBSCRIBE_PENDING,
  payload: intent
})

export const subscribeFail = () => ({
  type: types.SUBSCRIBE_FAIL
})

export const applyDiscount = payload => ({
  type: types.APPLY_DISCOUNT,
  payload
})

export const applyDiscountSuccess = payload => ({
  type: types.APPLY_DISCOUNT_SUCCESS,
  payload
})

export const applyDiscountFail = () => ({
  type: types.APPLY_DISCOUNT_FAIL
})

export const setStatus = isPremium => ({
  type: types.SET_STATUS,
  payload: isPremium
})

export const setPremiumAttributes = (attributes = {}) => ({
  type: types.SET_ATTRIBUTES,
  payload: attributes
})

export const reset = () => ({
  type: types.RESET
})

export const setShowSuccessPage = payload => ({
  type: types.SET_SHOW_SUCCESS_PAGE,
  payload
})

export const cancelPremiumSubscription = ({ reason, message }) => ({
  type: types.CANCEL_PREMIUM_SUBSCRIPTION,
  payload: { reason, message }
})

export const cancelPremiumSubscriptionSuccess = premiumExpiration => ({
  type: types.CANCEL_PREMIUM_SUBSCRIPTION_SUCCESS,
  payload: premiumExpiration
})

export const cancelPremiumSubscriptionFail = payload => ({
  type: types.CANCEL_PREMIUM_SUBSCRIPTION_FAILURE,
  payload
})

export const resetErrors = () => ({
  type: types.RESET_ERRORS
})
