import { types } from "./myLibrary.actions"

export const initialState = {
  isLoading: false,
  content: [],
  selected: ""
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD:
    case types.SELECT:
      return { ...state, isLoading: true }

    case types.LOAD_SUCCESS:
      return { ...state, content: action.payload, isLoading: false }

    case types.SELECT_SUCCESS:
      return { ...state, selected: action.payload, isLoading: false }

    case types.LOAD_FAIL:
    case types.SELECT_FAIL:
      return { ...state, isLoading: false }

    case types.RESET:
      return initialState

    default:
      return state
  }
}
