import React, { useState } from "react"
import { Text, Card } from "@engaging-tech/components"
import { LineGraph } from "@engaging-tech/charts"
import { format } from "date-fns"
import Paragraph from "@engaging-tech/components/dist/lib/Paragraph/Paragraph"
import EmptyLineGraphState from "./EmptyLineGraphState"
import TrendGraphFilterBar from "./TrendGraphFilterBar"

const surveyTypes = {
  all: { text: "All Surveys", value: "all" },
  indivdualWorkFromHome: {
    text: "Working From Home Happiness Survey",
    value: "indivdualWorkFromHome"
  },
  happiness: { text: "Workplace Happiness Survey", value: "happiness" }
}

/**
 * Filters surveys against given year and type
 *
 * @param {Object} surveys - Survey objects to be filtered
 * @param {Number} yearFilter - Survey year filter
 * @param {String} [typeFilter='all'] - Survey type filter
 *
 * @returns {Object} returned filtered survey objects
 */
const filterSurveys = (surveys, yearFilter, typeFilter = "all") => {
  // filter by type
  const typeCheck = survey => {
    if (
      typeFilter === "all" &&
      survey.surveyType !== "organisation" &&
      surveys.surveyType !== "workFromHome"
    ) {
      return true
    }
    return survey.surveyType === typeFilter
  }
  // filter by year
  const yearCheck = survey => {
    if (yearFilter === 0) {
      return true
    }
    const surveyYear = new Date(survey.completedAt).getFullYear()
    return surveyYear === yearFilter
  }

  return surveys.filter(survey => yearCheck(survey) && typeCheck(survey))
}

/**
 * Sorts surveys by completed date
 *
 * @param {Object} surveys - Survey objects to be sorted
 * @param {String} [order='increase'] - Order of sort
 *
 * @returns {Object} returned sorted survey objects
 */
const sortSurveysByDate = (surveys, order = "increase") => {
  if (order === "increase") {
    return surveys.sort(
      (surveyA, surveyB) =>
        // @ts-ignore
        new Date(surveyA.completedAt) - new Date(surveyB.completedAt)
    )
  }
  return surveys.sort(
    (surveyA, surveyB) =>
      // @ts-ignore
      new Date(surveyB.completedAt) - new Date(surveyA.completedAt)
  )
}

/**
 * Graph to show happiness trend of a users surveys
 *
 * @param {Object} params - params passed into component
 * @param {Object} params.surveys - Survey objects to be displayed
 * @param {Boolean} params.hasLoaded - Survey objects has been loaded
 *
 * @returns react component
 */
const TrendGraph = ({ surveys, hasLoaded }) => {
  const [selectedYear, setSelectedYear] = useState(0)
  const [selectedType, setSelectedType] = useState(surveyTypes.all)

  const filteredSurveys = filterSurveys(
    surveys,
    selectedYear,
    selectedType.value
  )
  const sortedFilteredSurveys = sortSurveysByDate(filteredSurveys)
  const mapSurveys = sortedFilteredSurveys.map(survey => ({
    y: survey.score * 100,
    x: format(new Date(survey.completedAt), "dd.MM.yyyy")
  }))

  if (hasLoaded && surveys.length === 0) return null

  return (
    <Card width={1} bg="light.0" p={4} mb={2}>
      <Text display="block" fontWeight={500} fontSize={5} color="dark.1" mb={2}>
        Your Survey Trend
      </Text>
      <Paragraph color="dark.2">
        Track your progress over time by taking a survey regularly. This enables
        you to see your workplace happiness overall trend.
      </Paragraph>
      <TrendGraphFilterBar
        originalSurveyList={surveys}
        filteredSurveys={sortedFilteredSurveys}
        setSelectedYear={setSelectedYear}
        setSelectedType={setSelectedType}
        selectedYear={selectedYear}
        selectedType={selectedType}
        surveyTypes={surveyTypes}
      />
      {sortedFilteredSurveys.length < 3 ? (
        <EmptyLineGraphState
          stateShow={surveys.length === 0 ? "NO_SURVEYS" : "FEW_SURVEYS"}
        />
      ) : (
        <LineGraph
          data={mapSurveys}
          height={340}
          width={750}
          axisColour="#0000001E"
          axisLabelFontSize={12}
          AxisTickFontSize={10}
          yAxisLabel="Happiness Scores"
          xAxisLabel="Surveys Taken"
          yAxisLabelDistance={-10}
          xAxisLabelDistance={54}
          yAxisTickDistance={5}
          xAxisTickDistance={-28}
          domainPadding={20}
          padding={{ top: 10, bottom: 100, left: 50, right: 14 }}
        />
      )}
    </Card>
  )
}

export default TrendGraph
