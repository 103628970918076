import React, { useEffect, useState } from "react"

import CreateReply from "../../containers/CreateReply"
import Reply from "./Reply"
import ReplySummary from "./ReplySummary"

const Replies = ({ replies, isFormOpen, onClose, postId, commentId }) => {
  const [lastReply, setLastReply] = useState(null)
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpand = () => setIsExpanded(!isExpanded)

  useEffect(() => {
    // Replies are in reverse chronological order, hence index 0 is the last/most recent reply
    setLastReply(replies[0])
  }, [replies])

  return (
    <>
      {replies.length > 0 && lastReply && (
        <ReplySummary lastReply={lastReply} numberOfReplies={replies.length} onClick={toggleExpand} />
      )}

      {isExpanded && replies.map(reply => <Reply key={reply.id} reply={reply} />)}

      {isFormOpen && <CreateReply onClose={onClose} postId={postId} parentId={commentId} />}
    </>
  )
}

export default Replies
