import { call, delay, put, takeLatest } from "redux-saga/effects"

import { libraryPostTypes } from "../lib/postTypes"
import { parseArticle, parseLecture, parsePodcast, parseResearch } from "../services/parse.service"
import { uploadArticle, uploadLecture, uploadPodcast, uploadResearch } from "../services/upload.service"
import {
  createMaterial,
  createPurchaseMaterialIntent,
  createSeries,
  loadSeries
} from "../services/userContent.service"
import * as Actions from "./userContent.actions"

function* publishArticle(payload) {
  const uploaded = yield call(uploadArticle, payload.attachments)

  const sanitisedArticle = yield call(parseArticle, {
    ...payload,
    ...uploaded
  })

  const res = yield call(createMaterial, sanitisedArticle)

  yield put(Actions.addMaterialSuccess(res.data.createMaterial))
}

function* publishPodcast(payload) {
  const uploaded = yield call(uploadPodcast, payload.attachments)

  const sanitisedPodcast = yield call(parsePodcast, {
    ...payload,
    ...uploaded
  })
  const res = yield call(createMaterial, sanitisedPodcast)
  yield put(Actions.addMaterialSuccess(res.data.createMaterial))
}

function* publishResearch(payload) {
  const uploaded = yield call(uploadResearch, payload.attachments)

  const sanitisedResearch = yield call(parseResearch, {
    ...payload,
    ...uploaded
  })

  const res = yield call(createMaterial, sanitisedResearch)

  yield put(Actions.addMaterialSuccess(res.data.createMaterial))
}

function* publishLecture(payload) {
  const uploaded = yield call(uploadLecture, payload.attachments)

  const sanitisedLecture = yield call(parseLecture, {
    ...payload,
    ...uploaded
  })

  const res = yield call(createMaterial, sanitisedLecture)

  yield put(Actions.addMaterialSuccess(res.data.createMaterial))
}

export function* loadSeriesSaga(action) {
  try {
    const res = yield call(loadSeries, action.payload)
    yield put(Actions.loadSeriesSuccess(res.data.AllSeries))
  } catch {
    yield put(Actions.loadSeriesFail())
  }
}

export function* createSeriesSaga(action) {
  try {
    const res = yield call(createSeries, action.payload)
    yield put(Actions.createSeriesSuccess(res.data.createSeries))
  } catch {
    yield put(Actions.createSeriesFail())
  }
}

export function* addMaterialSaga(action) {
  try {
    switch (action.payload.type) {
      case libraryPostTypes.articles:
        yield call(publishArticle, action.payload)
        break

      case libraryPostTypes.podcasts:
        yield call(publishPodcast, action.payload)
        break

      case libraryPostTypes.research:
        yield call(publishResearch, action.payload)
        break

      case libraryPostTypes.lectures:
        yield call(publishLecture, action.payload)
        break

      default:
        yield put(Actions.addMaterialFail())
    }
  } catch {
    yield put(Actions.addMaterialFail())
  }
}

export function* createSuccessSaga() {
  // Allow enough time to redirect a user after they have successfully
  // published something
  yield delay(100)
  yield put(Actions.resetCreate())
}

export function* createPurchaseMaterialIntentSaga(action) {
  try {
    const res = yield call(createPurchaseMaterialIntent, action.payload)
    yield put(Actions.createPurchaseMaterialIntentSuccess(res.data.purchaseMaterial))
  } catch {
    yield put(Actions.createPurchaseMaterialIntentFail())
  }
}

export function* saga() {
  yield takeLatest(Actions.types.LOAD_SERIES, loadSeriesSaga)
  yield takeLatest(Actions.types.CREATE_SERIES, createSeriesSaga)
  yield takeLatest(Actions.types.ADD_MATERIAL, addMaterialSaga)
  yield takeLatest(
    [Actions.types.ADD_MATERIAL_SUCCESS, Actions.types.CREATE_SERIES_SUCCESS],
    createSuccessSaga
  )
  yield takeLatest(Actions.types.CREATE_PURCHASE_MATERIAL_INTENT, createPurchaseMaterialIntentSaga)
}
