import { call, put, select, takeLatest } from "redux-saga/effects"

import flattenFilters from "../../utils/flattenFilters"
import { loadCompanies, loadCompanyRankings, loadSurveyCompanies } from "../services/rankings.service"
import * as Actions from "./rankings.actions"
import { getCompaniesNextToken } from "./rankings.selectors"

export function* loadSaga(action) {
  try {
    const res = yield call(loadCompanyRankings, action.payload)
    const companyRankings = res && res.data && res.data.allCompanyRanking
    if (companyRankings) {
      if (action.type === Actions.types.LOAD_COMPANY_RANKINGS) {
        yield put(Actions.loadCompanySuccess(companyRankings))
      }
      if (action.type === Actions.types.LOAD_MORE) {
        yield put(Actions.loadMoreSuccess(companyRankings))
      }
    } else {
      yield put(Actions.loadFail())
    }
  } catch (e) {
    yield put(Actions.loadFail())
  }
}

export function* getSurveyCompaniesSaga(action) {
  try {
    const res = yield call(loadSurveyCompanies, action.payload)
    if (res?.data?.getSurveyCompanies)
      yield put(Actions.getSurveyCompaniesSuccess(res.data.getSurveyCompanies))
  } catch {
    yield put(Actions.getSurveyCompaniesFail())
  }
}

export function* loadCompaniesSaga(action) {
  try {
    const filters = action?.payload
    const nextToken = yield select(getCompaniesNextToken)

    const filtersValuesUpdated = {}

    if (filters) {
      Object.keys(filters).forEach(item => {
        if (item === "searchTerm") {
          filtersValuesUpdated.companyName = filters[item]
        } else if (item === "countryFilter" && filters[item]?.name) {
          filtersValuesUpdated.countryId = filters[item].name
        } else if (item === "industryFilter" && filters[item]?.name) {
          filtersValuesUpdated.industryId = filters[item].name
        } else if (item === "categoryFilter" && filters[item]?.id) {
          filtersValuesUpdated.category = filters[item].id
        } else if (item === "filters") {
          const filtersArr = Object.values(filters[item])?.length
            ? flattenFilters(Object.values(filters[item]))
            : []

          filtersValuesUpdated.filters = filtersArr
        }
      })
    }

    let params = { ...filtersValuesUpdated }

    if (action.type === Actions.types.LOAD_COMPANIES_LOAD_MORE && nextToken) {
      params = { ...params, nextToken }
    }

    const res = yield call(loadCompanies, params)

    const companiesList = res?.data?.getRankingData

    if (action.type === Actions.types.LOAD_COMPANIES_LOAD_MORE) {
      yield put(Actions.loadMoreCompaniesSuccess(companiesList))
    } else {
      yield put(Actions.loadCompaniesSuccess(companiesList))
    }
  } catch (error) {
    if (action.type === Actions.types.LOAD_COMPANIES_LOAD_MORE) {
      yield put(Actions.loadMoreCompaniesFail())
    } else {
      yield put(Actions.loadCompaniesFail())
    }
  }
}

export function* saga() {
  yield takeLatest([Actions.types.LOAD_COMPANY_RANKINGS, Actions.types.LOAD_MORE], loadSaga)
  yield takeLatest(Actions.types.GET_SURVEY_COMPANIES, getSurveyCompaniesSaga)
  yield takeLatest([Actions.types.LOAD_COMPANIES, Actions.types.LOAD_COMPANIES_LOAD_MORE], loadCompaniesSaga)
}
