import React from "react"
import { Button, Flex, Text } from "@engaging-tech/components"

const PremiumButton = ({
  width = 200,
  isLoading,
  handleStripeCheckout,
  stripeCheckoutUrl,
  premiumProduct,
  ...props
}) => {
  const premiumPriceText = `Only ${
    premiumProduct?.pricing?.currency === "GBP" ? "£" : ""
  }${premiumProduct?.pricing?.amount} ${
    premiumProduct?.pricing?.billingPeriod === "monthly" ? "a month" : ""
  } `
  const premiumTrailText = `${premiumProduct?.trial?.amount} ${premiumProduct?.trial?.period} free trial`

  return (
    <Flex
      justifyContent={["center", "flex-end"]}
      width={width}
      alignItems="center"
      flexDirection="column"
      px={5}
      {...props}
    >
      <Button
        leadingIcon="stars"
        bg="primary.0"
        color="yellow.0"
        elevation={0}
        p={2}
        width="168px"
        height="100%"
        mb={3}
        display="block"
        onClick={() => handleStripeCheckout(premiumProduct.provider.priceId)}
      >
        <Text fontWeight={700} color="yellow.0" width={1 / 1} display="block">
          GET PREMIUM
        </Text>
      </Button>

      {[premiumPriceText, premiumTrailText].map(text => (
        <Text
          textAlign="center"
          fontWeight={700}
          color="primary.0"
          fontSize={3}
          key={text}
        >
          {text}
        </Text>
      ))}

      <Text fontSize={2} color="primary.0" mt={2} textAlign="center">
        This price includes VAT
      </Text>
    </Flex>
  )
}

export default PremiumButton
