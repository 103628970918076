/* eslint-disable no-nested-ternary, react/no-array-index-key, no-param-reassign, no-underscore-dangle */
import {
  Box,
  Button,
  Checkbox,
  DatePicker,
  Flex,
  Icon,
  LocationAutocomplete,
  Text,
  TextArea,
  TextField
} from "@engaging-tech/components"
import { getCountries } from "@engaging-tech/frontend-utils"
import { getConfig } from "@engaging-tech/ssr-config"
import { format } from "date-fns"
import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import * as Yup from "yup"

import { deleteTypeNames } from "../../../utils"
import ProfileSection from "./ProfileSection"

const BorderedBox = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[3]};
  margin-top: 10px;
  margin-bottom: 10px;
`

const FormWrapper = styled.form`
  width: 100%;
`
const EditIcon = styled(Icon)`
  cursor: pointer;
`

const Form = ({
  toggle,
  handleSubmit,
  values,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  isValid,
  setFieldTouched
}) => {
  const [currentWork, setCurrentWork] = useState(false)
  const [countries, setCountries] = useState([])

  useEffect(() => {
    setCountries(getCountries())
  }, [])

  const handleLocationChange = e => {
    if (e && e.geometry) {
      setFieldValue("location", {
        countryId: countries.filter(
          country =>
            country.code ===
            e.address_components.filter(component => component.types.includes("country"))[0].short_name
        )[0].id,
        name: e.formatted_address,
        point: {
          coordinates: [e.geometry.location.lat(), e.geometry.location.lng()]
        },
        country: {
          shortName: e.address_components.filter(country => country.types.includes("country", "political"))[0]
            .short_name,

          name: e.address_components.filter(country => country.types.includes("country", "political"))[0]
            .long_name
        },
        vicinity: e.vicinity
      })
    }
  }

  return (
    <FormWrapper
      onSubmit={() => {
        handleSubmit()
        toggle()
      }}
    >
      <Flex mt={3} flexDirection="column" alignItems="stretch">
        <TextField
          width={1 / 1}
          color="primary.0"
          placeholder="Job Title"
          error={errors.jobTitle}
          value={values?.jobTitle || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          id="jobTitle"
        />
        <TextField
          width={1 / 1}
          color="primary.0"
          placeholder="Company"
          error={errors.company}
          value={values?.company || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          id="company"
        />
        <LocationAutocomplete
          id="location.name"
          name="location.name"
          type="text"
          label=""
          placeholder="Location"
          value={values.location.name || ""}
          googleApiKey={getConfig().keys.googleApiKey}
          width={1 / 1}
          selectedLocationCallback={handleLocationChange}
          onFocus={() => setFieldTouched("location.name")}
          // onChange={handleLocationChange}
          error={errors.location && errors.location.name}
          style={{
            zIndex: 1000
          }}
          onKeyDown={e => {
            if (e.key === "Backspace") {
              setFieldValue("location", {
                countryId: null,
                name: "",
                coordinates: { lat: 0, lng: 0 },
                country: {
                  name: "",
                  shortName: ""
                }
              })
            }
          }}
        />{" "}
        <Flex
          width={1 / 1}
          flexDirection={["column", "column", "row"]}
          alignItems="center"
          justifyContent="space-between"
        >
          <DatePicker
            mr={2}
            width={1 / 1}
            name="startDate"
            color="primary.0"
            type="date"
            format="dd/MM/yyyy"
            label="Start Date"
            error={errors.startDate}
            handleChange={val => setFieldValue("startDate", val)}
            onBlur={handleBlur}
            id="startDate"
          />
          {!currentWork && (
            <DatePicker
              width={1 / 1}
              name="endDate"
              color="primary.0"
              type="date"
              format="dd/MM/yyyy"
              label="End Date"
              error={errors.endDate}
              handleChange={val => setFieldValue("endDate", val)}
              onBlur={handleBlur}
              dayPickerInputProps={{
                modifiers: {
                  disabled: [
                    {
                      before: values.startDate || null
                    }
                  ]
                }
              }}
              id="endDate"
            />
          )}
        </Flex>
        <Checkbox
          id="checkbox-current-work"
          width={1 / 1}
          mb={2}
          checked={currentWork}
          onChange={() => setCurrentWork(!currentWork)}
          label={() => <Text>I currently work here</Text>}
        />
        <TextArea
          width={1 / 1}
          color="primary.0"
          mt={2}
          placeholder="Please describe the nature of your job role"
          maxLength="200"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          id="description"
          height={150}
        />
        <Button
          mt={2}
          elevation={0}
          variant="outline.primary.0"
          width="120px"
          type="submit"
          disabled={!isValid}
        >
          SAVE
        </Button>
      </Flex>
    </FormWrapper>
  )
}

const FormShowing = ({ toggle, positionsHeld, onUpdate }) => {
  const handleUpdate = values => {
    if (positionsHeld) {
      const noTypeName = positionsHeld.map(pos => {
        deleteTypeNames(pos)

        const obj = {
          ...pos,
          location: {
            countryId: pos.location.countryId,
            name: pos.location.name,
            point: pos.location.point,
            country: {
              shortName: values.location.country.shortName,
              name: values.location.country.name
            }
          }
        }

        return deleteTypeNames(obj)
      })

      onUpdate({ positionsHeld: [...noTypeName, values] })
    }
    if (!positionsHeld) onUpdate({ positionsHeld: [values] })
  }

  const validationSchema = Yup.object().shape({
    description: Yup.string().max(200, "Must be less than or equal to 200 characters"),
    location: Yup.object().shape({
      name: Yup.string().required("Required")
    }),
    company: Yup.string().max(50, "Must be less than or equal to 50 characters").required("Required"),
    jobTitle: Yup.string().max(50, "Must be less than or equal to 50 characters").required("Required")
  })

  const initialValues = {
    jobTitle: "",
    company: "",
    location: {
      countryId: "",
      name: "",
      point: {
        coordinates: []
      },
      country: { name: "", shortName: "" }
    },
    startDate: null,
    endDate: null,
    description: ""
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleUpdate}
      validateOnMount
    >
      {props => <Form {...props} toggle={toggle} />}
    </Formik>
  )
}

const DataShowing = ({ positionsHeld, onUpdate }) => {
  const removePosition = exp => {
    const filteredPositions = positionsHeld
      .filter(position => position.id.toString() !== exp.id.toString())
      .map(pos => {
        return {
          id: pos.id,
          company: pos.company,
          description: pos.description,
          jobTitle: pos.jobTitle,
          startDate: pos.startDate,
          endDate: pos.endDate,
          location: {
            countryId: pos.location.country.id,
            name: pos.location.name,
            coordinates: pos.location.coordinates
          }
        }
      })

    onUpdate({ positionsHeld: filteredPositions })
  }

  return positionsHeld?.length > 0 ? (
    <Flex flexDirection="column" mt={2}>
      {positionsHeld.map((exp, index) => (
        <React.Fragment key={index}>
          <Flex flexDirection={["column", "row"]} justifyContent="space-between" mt={2}>
            <Text fontSize={3} fontWeight={600} color="dark.2" mt={[3, 0]} mb={1} mr={2}>
              {exp.jobTitle}
            </Text>
            <Text fontSize={3} color="dark.2" mb={1} mr={2}>
              {exp.company}
            </Text>
            <Text fontSize={3} color="dark.2" mb={1} mr={2}>
              {exp?.startDate ? format(new Date(exp.startDate), "dd MMM yyyy") : " -- "}
            </Text>
            <Text fontSize={3} color="dark.2" mb={1} mr={2}>
              {exp?.startDate
                ? exp.endDate
                  ? format(new Date(exp.endDate), "dd MMM yyyy")
                  : "present"
                : " -- "}
            </Text>
            {exp.location?.name && (
              <Text fontSize={3} color="dark.2" mb={[2, 1]} mr={2}>
                {exp.location?.name}
              </Text>
            )}
            {exp.description && (
              <Text fontSize={3} color="dark.2" mb={[3, 1]}>
                {exp.description}
              </Text>
            )}
            <EditIcon name="delete" onClick={() => removePosition(exp)} />
          </Flex>
          {index !== positionsHeld.length - 1 && <BorderedBox />}
        </React.Fragment>
      ))}
    </Flex>
  ) : (
    <Text mt={2}>You have not added any positions yet</Text>
  )
}

const Experience = ({ positionsHeld, onUpdate, toggle, hideCancelButton = false }) => {
  const [dataShowing, setDataShowing] = useState(true)

  return (
    <ProfileSection
      title="Experience"
      icon="briefcase"
      icon2="add_circle"
      onClick={() => setDataShowing(!dataShowing)}
      toggle={toggle}
      dataShowing={dataShowing}
      hideCancelButton={hideCancelButton}
    >
      {dataShowing ? (
        <DataShowing positionsHeld={positionsHeld} onUpdate={onUpdate} />
      ) : (
        <FormShowing
          toggle={toggle || (() => setDataShowing(true))}
          onUpdate={onUpdate}
          positionsHeld={positionsHeld}
        />
      )}
    </ProfileSection>
  )
}

export default Experience
