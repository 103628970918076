import React, { useState } from "react"
import {
  Card,
  Text,
  Flex,
  Button,
  Icon,
  Spinner
} from "@engaging-tech/components"
import { AnimatePresence } from "framer-motion"
import styled from "styled-components"
import WidgetCard from "../../components/WidgetCard"
import GoalModal from "../../components/GoalModal"

const ScrollCard = styled(Card)`
  overflow-x: auto;
  overflow-y: hidden;
`

const ButtonCard = styled(Card)`
  border-radius: 7px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.dark[6]};
  :hover {
    background-color: ${({ theme }) => theme.colors.dark[5]};
  }
`

const GoalCard = ({ goal, index, type }) => (
  <WidgetCard
    key={goal.text}
    flexDirection="row"
    bg="#CCFBE2"
    elevation={0}
    width={170}
    height="107px !Important"
    flexShrink={0}
    p={2}
    mr={3}
  >
    <Flex height="100%">
      <Text
        fontWeight={700}
        fontFamily="Tiempos Text"
        color="primary.0"
        width={1 / 7}
        fontSize={5}
        mr={1}
      >
        {goal.completed ? (
          <Icon ml={-1} color="primary.0" name="check_circle_outline" />
        ) : (
          `${index + 1}.`
        )}
      </Text>
      <Flex width={1 / 1} alignItems="center" height="100%">
        <Text
          color="primary.0"
          width={6 / 7}
          fontSize={[2, 3, 3]}
          textAlign={type === "noGoals" ? "center" : "left"}
        >
          {goal.text}
        </Text>
      </Flex>
    </Flex>
  </WidgetCard>
)

const GoalWidget = ({
  seenBefore = true,
  updateSeenBefore,
  myGoals,
  allGoals,
  updateMyGoals,
  userProfile,
  isProfileLoading
}) => {
  const [showGoalModal, setShowGoalModal] = useState(false)
  const noGoalsYetTexts = [
    { text: "Add some goals to get started", id: 1 },
    { text: " ", id: 2 },
    { text: " ", id: 3 }
  ]

  return (
    <>
      <AnimatePresence>
        {(showGoalModal || !seenBefore) &&
          allGoals.data.length > 0 &&
          myGoals && (
            <GoalModal
              toggle={() => setShowGoalModal(!showGoalModal)}
              seenBefore={seenBefore}
              myGoals={myGoals}
              allGoals={allGoals}
              updateSeenBefore={updateSeenBefore}
              updateMyGoals={updateMyGoals}
              userProfile={userProfile}
            />
          )}
      </AnimatePresence>
      <WidgetCard
        flexDirection="column"
        bg="white"
        elevation={2}
        width="100%"
        height="100%"
        p={3}
        pb={2}
        justifyContent="space-evenly"
      >

        <Flex
          alignItems="center"
          width={1 / 1}
          justifyContent="space-between"
          flexDirection={["column", "row", "row"]}
        >
          <Text fontSize={4} fontWeight={600} mr={15}>
            Develop Your Career By Achieving Your Goals
          </Text>
          <Button
            width="200px"
            variant="outline.primary.0"
            elevation={0}
            onClick={() => setShowGoalModal(!showGoalModal)}
            my={[2, 0, 0]}
          >
            <strong>Edit Goals</strong>
          </Button>
        </Flex>
        {!allGoals.loading && !isProfileLoading && allGoals.data.length > 0 ? (
          <ScrollCard
            flexDirection="row"
            elevation={0}
            width={1 / 1}
            mt={2}
            pb={1}
          >
            {myGoals &&
              myGoals.map((goal, index) => (
                <GoalCard
                  goal={goal}
                  index={index}
                  type="goalsExist"
                  key={goal.id}
                />
              ))}
            {myGoals &&
              myGoals.length === 0 &&
              noGoalsYetTexts.map((goal, index) => (
                <GoalCard
                  goal={goal}
                  index={index}
                  type="noGoals"
                  key={goal.id}
                />
              ))}
            {myGoals && myGoals.length < 5 && (
              <ButtonCard
                flexDirection="row"
                elevation={0}
                width={170}
                height={["100%", 105, 105]}
                p={2}
                justifyContent="center"
                alignItems="center"
                onClick={() => setShowGoalModal(!showGoalModal)}
              >
                <Text
                  textAlign="center"
                  fontSize={4}
                  fontWeight={600}
                  color="primary.0"
                >
                  + Add A Goal
                </Text>
              </ButtonCard>
            )}
          </ScrollCard>
        ) : (
          <Spinner py={3} color="primary.0" width={1 / 1} />
        )}
      </WidgetCard>
    </>
  )
}

export default GoalWidget
