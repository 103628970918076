import { Flex, Text } from "@engaging-tech/components"
import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"

import { businessProfilePageSelectors } from "../.."

const MissionStatementContainer = styled(Flex)`
  padding: 24px 8px 30px;

  width: 100%;

  flex-direction: column;
  justify-items: left;

  @media (min-width: 768px) {
    padding: 36px 32px 40px;
  }
`

const MissionStatementTitle = styled(Text)`
  width: auto;

  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;

  color: #003e4c;
`

const MissionStatementValue = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;

  color: #003e4c;

  position: relative;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &.show-all {
    -webkit-line-clamp: unset;
  }
`

const SeeMoreButton = styled.p`
  margin-top: 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18.2px;

  color: #003e4c;

  text-decoration: underline;
  cursor: pointer;
`

const SeeMoreButtonContainer = styled.div`
  display: none;

  ${MissionStatementValue}.clipped + & {
    width: 100%;
    display: inline-flex;
    justify-content: end;
  }
`

export default function MissionStatement() {
  const [showAll, setShowAll] = useState(false)
  const [isClipped, setIsClipped] = useState(false)
  const textRef = useRef(null)

  const bipInfo = useSelector(businessProfilePageSelectors.getBipInfo)

  useEffect(() => {
    const truncateTextElement = textRef.current
    setIsClipped(truncateTextElement.scrollHeight > truncateTextElement.clientHeight)
  }, [])

  const handleShowMore = () => {
    setShowAll(!showAll)
  }

  return (
    <MissionStatementContainer>
      <MissionStatementTitle>What we’re about</MissionStatementTitle>
      <MissionStatementValue
        mt="18px"
        width="fit-content"
        className={`${showAll ? "show-all" : ""} ${isClipped ? "clipped" : ""}`}
        ref={textRef}
      >
        {bipInfo?.profile?.culture?.statement}
      </MissionStatementValue>
      <SeeMoreButtonContainer>
        <SeeMoreButton onClick={handleShowMore}>See {showAll ? "less" : "more"}</SeeMoreButton>
      </SeeMoreButtonContainer>
    </MissionStatementContainer>
  )
}
