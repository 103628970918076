import * as LibraryBooksActions from "./store/libraryBooks.actions"
import { reducer as libraryBooksReducer } from "./store/libraryBooks.reducer"
import { saga as libraryBooksSaga } from "./store/libraryBooks.sagas"
import * as LibraryBooksSelectors from "./store/libraryBooks.selectors"
import * as DeliveryTypes from "./lib/deliveryTypes"

import FeaturedBooks from "./containers/FeaturedBooks"
import AllBooks from "./containers/AllBooks"
import SelectedBook from "./containers/SelectedBook"
import AddToBasket from "./containers/AddToBasket"
import BasketBooks from "./containers/BasketBooks"
import BasketTotal from "./containers/BasketTotal"
import CheckoutSummary from "./containers/CheckoutSummary"
import CheckoutPaymentForm from "./containers/CheckoutPaymentForm"
import OrderList from "./containers/OrderList"
import OrderDetails from "./containers/OrderDetails"

import Search from "./components/Search/Search"
import BookCategoryChips from "./components/Browse/CategoryChips"

const Basket = {
  Books: BasketBooks,
  Total: BasketTotal
}

const Checkout = {
  Summary: CheckoutSummary,
  Payment: CheckoutPaymentForm
}

export {
  LibraryBooksActions,
  libraryBooksReducer,
  libraryBooksSaga,
  LibraryBooksSelectors,
  DeliveryTypes,
  FeaturedBooks,
  AllBooks,
  SelectedBook,
  AddToBasket,
  Basket,
  Checkout,
  OrderList,
  OrderDetails,
  Search,
  BookCategoryChips
}
