import React, { useEffect } from "react"
import styled, { css } from "styled-components"
import { useParams, Link } from "@engaging-tech/routing"
import { Card, Text, Flex, Spinner, Box } from "@engaging-tech/components"

import CategoryChips from "../Browse/CategoryChips"
import PrimaryButton from "../../../ui/components/PrimaryButton"

const appStoreLogo = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/app-store.svg`)
const googlePlayLogo = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/google-play.png`)

const Badge = styled.img`
  max-height: 40px;
  margin-right: ${({ theme, hasMargin }) => (hasMargin ? theme.space[2] : 0)}px;
`

const defaultBox = css`
  border: 1px solid transparent;
`
const selectedBox = css`
  cursor: default;
  border: 1px solid ${({ theme }) => theme.colors.primary[0]};
`

const FormatBox = styled(Flex)`
  cursor: pointer;
  ${({ selected }) => (selected ? selectedBox : defaultBox)};
`
const Thumbnail = styled.img`
  max-height: 250px;
  max-width: 180px;
  width: auto;
`

const BookOption = ({ label, price, selected, onClick }) => (
  <FormatBox
    flexDirection="column"
    justifyContent="space-between"
    width="110px"
    height="87px"
    p={3}
    mr={3}
    bg="dark.6"
    selected={selected}
    onClick={onClick}
  >
    <Text fontSize={3} fontWeight="500" color="dark.2">
      {label}
    </Text>
    <Text fontSize={3} fontWeight="500" color="primary.0">
      £{price.toFixed(2)}
    </Text>
  </FormatBox>
)

const SelectedBook = ({
  selectedFormat,
  book,
  ebook,
  audioBook,
  isLoading,
  onLoad,
  onSelectFormat
}) => {
  const { id } = useParams()

  useEffect(() => {
    onLoad(id)
  }, [])

  if (isLoading) {
    return <Spinner color="primary.0" py={3} />
  }

  return book ? (
    <>
      <Card
        mb={3}
        p={3}
        width={1 / 1}
        ml="auto"
        bg="light.0"
        flexDirection={["column", "row"]}
      >
        {book.image && (
          <Flex
            width={[1 / 1, 180]}
            mr={[0, 3]}
            mb={[3, 0]}
            justifyContent={["center", "flex-start"]}
          >
            <Thumbnail
              src={`https://gardnersmedia.engaging.works${book.image}`}
            />
          </Flex>
        )}
        <Flex flexDirection="column" width={[1 / 1, "auto"]}>
          <Text mb={1} fontSize={4} fontWeight="500">
            {book.title && book.title}
          </Text>
          {book.authors &&
            book.authors.map((author, aIndex) => (
              <Text
                key={author.name + aIndex.toString()}
                mb={1}
                mr={2}
                fontSize={2}
                fontWeight="500"
              >
                {author.name}
              </Text>
            ))}
          <Text color="dark.2" lineHeight="1.4">
            {book.shortDescription}
          </Text>
          {book.bicCliassifications && !!book.bicCliassifications.length && (
            <CategoryChips classifications={book.bicCliassifications} />
          )}
        </Flex>
      </Card>
      <Card
        width={1 / 1}
        ml="auto"
        mb={3}
        p={3}
        bg="light.0"
        flexDirection="column"
      >
        <Text fontSize={3} color="dark.2">
          All formats
        </Text>
        <Flex mt={3}>
          <BookOption
            onClick={() => onSelectFormat("hardCover")}
            label="Hardcover"
            selected={selectedFormat === "hardCover"}
            price={book.price}
          />
          {ebook && (
            <BookOption
              key="ebook"
              label="Ebook"
              price={ebook.price}
              selected={selectedFormat === "ebook"}
              onClick={() => onSelectFormat("ebook")}
            />
          )}
          {audioBook && (
            <BookOption
              key="audiobook"
              label="audiobook"
              price={audioBook.price}
              selected={selectedFormat === "audiobook"}
              onClick={() => onSelectFormat("audiobook")}
            />
          )}
        </Flex>
        {selectedFormat !== "hardCover" && (
          <Box mt={3}>
            <Text fontSize={4} fontWeight={500}>
              Download Adobe Digital Editions to read your eBook
            </Text>
            <Flex
              mt={2}
              width="auto"
              alignItems={["flex-start", "flex-start", "center"]}
              flexDirection={["column", "column", "row"]}
            >
              <Flex flexDirection="row">
                <Link
                  target="_blank"
                  to="https://itunes.apple.com/gb/app/adobe-digital-editions/id952977781?mt=8"
                  newTab
                  external
                >
                  <Badge hasMargin src={appStoreLogo} alt="iTunes App Store" />
                </Link>
                <Link
                  newTab
                  external
                  to="https://play.google.com/store/apps/details?id=com.adobe.digitaleditions&hl=en_GB"
                >
                  <Badge src={googlePlayLogo} alt="Google Play store" />
                </Link>
                <Link
                  newTab
                  external
                  to="https://www.adobe.com/uk/solutions/ebook/digital-editions/download.html"
                >
                  <PrimaryButton
                    ml={2}
                    width={250}
                    color="grey.0"
                    text="Download for PC and Mac"
                  />
                </Link>
              </Flex>
            </Flex>
          </Box>
        )}
      </Card>
    </>
  ) : (
    <Card
      width="100%"
      mb={3}
      p={3}
      bg="light.0"
      flexDirection={["column", "row"]}
    >
      The book requested is no longer available
    </Card>
  )
}

export default SelectedBook
