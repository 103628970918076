import React from "react"

import SurveyContentView from "../../features/survey/views/surveyContent"
import OrganisationSurveyLayout from "../../layouts/organisationSurveyLayout"

const SurveyContent = () => (
  <OrganisationSurveyLayout>
    <SurveyContentView />
  </OrganisationSurveyLayout>
)

export default SurveyContent
