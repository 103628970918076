import { getConfig } from "@engaging-tech/ssr-config"
import { API, Amplify } from "aws-amplify"

import { apollo } from "../../../lib/Apollo"
import { GET_DUNNS_DATA_BY_KEY, LOAD } from "./businessProfilePage.queries"

const GRAPHQL_API_URL = () => `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

/**
 * Fetches a post of a specific post type from the backend, based on its ID.
 * @param {string} id The id of the post to load.
 */

export const loadProfile = ({ id, awardYear }) =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD,
    variables: { key: id, awardYear },
    fetchPolicy: "no-cache"
  })

export const getDunnsDataByKey = companyNameKey => {
  const config = getConfig()
  Amplify.configure({
    aws_appsync_graphqlEndpoint: config.appSync.publicGatewayURL,
    aws_appsync_authenticationType: config.appSync.publicGatewayAuthType,
    aws_appsync_apiKey: config.appSync.publicGatewayApiKey,
    aws_appsync_region: config.appSync.publicGatewayRegion
  })

  return API.graphql({
    query: GET_DUNNS_DATA_BY_KEY,
    variables: { companyNameKey }
  })
}

export default loadProfile
