import React, { useState, useEffect } from "react"
import { StripeProvider, Elements } from "react-stripe-elements"
import { Spinner } from "@engaging-tech/components"

/**
 * A wrapper element for Stripe Elements that lazy loads the stripe JS library. This
 * file has to be loaded in this way to ensure PCI compliancy for online payments.
 * @param {string} key The API key for stripe. Defaults to whatever is specified
 * in config.keys.stripe
 */
const StripeWrapper = ({ apiKey, children }) => {
  const [stripe, setStripe] = useState(null)

  const isInitialised = !!(window && window.Stripe)

  const remove = () => {
    document.getElementById("stripe-js").remove()
    window.Stripe = null
  }

  const createScript = () => {
    const stripeJs = document.createElement("script")

    stripeJs.id = "stripe-js"
    stripeJs.src = "https://js.stripe.com/v3/"
    stripeJs.async = true

    return stripeJs
  }

  const initialise = () => {
    if (isInitialised) {
      remove() // Ensure there's only *one* copy of stripe.js on the page at any time.
    }

    const stripeJs = createScript()

    stripeJs.onload = () => {
      setStripe(window.Stripe(apiKey))
    }

    if (document.body) {
      document.body.appendChild(stripeJs)
    }

    return () => {
      remove()
    }
  }

  useEffect(() => initialise(), [])

  return stripe ? (
    <StripeProvider stripe={stripe}>
      <Elements>{children}</Elements>
    </StripeProvider>
  ) : (
    <Spinner color="primary.0" justifyContent="center" />
  )
}

export default StripeWrapper
