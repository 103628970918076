import { getConfig } from "@engaging-tech/ssr-config"
import axios from "axios"

import { apollo } from "../../../lib/Apollo"
import { getIdToken } from "../../account/services/account.service"
import { LOAD_ALL_GOALS, LOAD_ALL_WIDGETS, LOAD_SURVEYS, UPDATE_MY_WIDGETS } from "./developCareer.queries"

const GRAPHQL_API_URL = () => `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

export const loadSurveyResults = () =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD_SURVEYS
  })

export const loadAllWidgets = () =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD_ALL_WIDGETS
  })

export const updateWidgetLayout = widgetLayout =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: UPDATE_MY_WIDGETS,
    variables: { widgetLayout }
  })

export const loadAllGoals = () =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD_ALL_GOALS
  })

// THIS IS A HACKY SOLUTION AROUND A BUG WHERE MAKING THIS REQUEST WITH REDUX SAGA 'CALL' FUNCTION WAS NOT RETURNING CORRECT VALUES
export const axiosLoadWidgetLayout = async userId => {
  const tokenString = await getIdToken()
  return axios({
    url: GRAPHQL_API_URL(),
    method: "post",
    headers: { Authorization: `JWT ${tokenString}` },
    data: {
      query: `
        { getUserProfile(userId: "${userId}") {
          widgetLayout {
            mobile {
              id
              x
              y
              h
              w
            }
            tablet {
              id
              x
              y
              h
              w
            }
            desktop {
              id
              x
              y
              h
              w
            }
          }
        }}
          `
    }
  })
}
