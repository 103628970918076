export const SponsorLogos = [
  {
    image: "https://d19vbgnwz7jfjm.cloudfront.net/CMI-Compact-RGB-Slate%2BPink-dot.png",
    imageAlt: "CMI"
  },
  {
    image:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/business/material-images/RT_WordMark_Blue+Skies+2.svg",
    imageAlt: "RT WordMark Blue Skies"
  },
  {
    image:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/business/material-images/BITCLogo2.svg",
    imageAlt: "BITC"
  },
  {
    image:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/business/material-images/Retailweek_ascential_logo_black.png",
    imageAlt: "Retail Week"
  },
  {
    image:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/business/material-images/wo-logo.svg",
    imageAlt: "WO"
  },
  {
    image:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/business/material-images/MPowder.png",
    imageAlt: "MPowder"
  },
  {
    image:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/business/material-images/RT_mailchimp.svg",
    imageAlt: "Retail Times"
  },
  {
    image:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/business/material-images/NVCO.png",
    imageAlt: "NVCO"
  },
  {
    image:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/business/material-images/The+Marketing+Society+Logo.png",
    imageAlt: "Marketing Society"
  },
  {
    image:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/business/material-images/Disability+Rights+UK.png",
    imageAlt: "Disability Rights Uk"
  },
  {
    image:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/business/material-images/WeShop.png",
    imageAlt: "WeShop"
  },
  {
    image:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/business/material-images/BDF.png",
    imageAlt: "Business Disability Forum"
  },
  {
    image: "https://d19vbgnwz7jfjm.cloudfront.net/BRC.jpg",
    imageAlt: "BRC"
  },
  {
    image: "https://d19vbgnwz7jfjm.cloudfront.net/LEAD-Network-logo-1.jpg",
    imageAlt: "LEAD"
  },
  {
    image: "https://d19vbgnwz7jfjm.cloudfront.net/Women-in-work-summit.jpg",
    imageAlt: "Women-in-work-summit"
  }
]
