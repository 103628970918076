import styled from "styled-components"

import MessengerComingSoonSvg from "./messenger-coming-soon.svg"

const MessengerComingSoonImage = styled(MessengerComingSoonSvg)`
  min-width: 600px;
  width: 100%;
  min-height: 524px;
  height: 100%;
`

export default MessengerComingSoonImage
