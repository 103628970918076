import React from "react"
import styled from "styled-components"
import { Text, Flex, Select, IconButton } from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"
import { titleCaps } from "@engaging-tech/frontend-utils"

const Thumbnail = styled.img`
  max-height: 125px;
  max-width: 86px;
  width: auto;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
`

const ItemBox = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[4]};
`

const QuantitySelect = styled(Select)`
  width: auto;
  margin-bottom: 16px;

  select {
    width: auto;
    border-bottom: 0px solid red;
    font-size: 14px;
    padding: 15px 50px 15px 25px;
    :hover,
    :focus {
      border-bottom: 0px solid red;
    }
  }
`

const Book = ({ book, updateQuantity, deleteProduct }) => {
  const paths = usePaths()

  return (
    book && (
      <ItemBox
        width="100%"
        p={3}
        bg="light.0"
        flexDirection={["column", "row"]}
      >
        <Flex>
          {book.image && (
            <Flex
              width={[1 / 1, "100px"]}
              mr={[0, 3]}
              mb={[3, 0]}
              justifyContent={["center", "flex-start"]}
            >
              <Thumbnail src={book.image} />
            </Flex>
          )}
          <Flex flexDirection="column" width={[1 / 1, 2 / 3]}>
            <Link
              width={1 / 1}
              to={`${paths.resourceCentre.browse.books.index}/${book.url}`}
            >
              <Text mb={1} color="primary.0" fontSize={4} fontWeight={700}>
                {book.title}
              </Text>
            </Link>

            {book.authors &&
              book.authors.map((author, aIndex) => (
                <Text
                  mb={1}
                  mr={2}
                  fontSize={2}
                  fontWeight="500"
                  key={author.name + aIndex.toString()}
                >
                  {author.name}
                </Text>
              ))}
            <Text mb={3} fontSize={3} color="dark.2">
              {titleCaps(book.format)}
            </Text>
            <Text mb={1} fontSize={4} fontWeight={700}>
              {`£${book.price.toFixed(2)}`}
            </Text>
          </Flex>
        </Flex>

        <Flex
          width={[1 / 1, 1 / 3]}
          flexDirection={["column", "row", "column"]}
        >
          <Flex
            width={1 / 1}
            flexDirection={["row", "column", "row"]}
            alignItems="center"
            alignContent="center"
            justifyContent={["space-between", "center", "space-between"]}
          >
            <Text
              mr={[0, 0, 5]}
              mb={12}
              fontSize={3}
              fontWeight="500"
              color="dark.2"
            >
              Quantity
            </Text>
            {book.format === "hardCover" ? (
              <QuantitySelect
                name="quantity"
                id="quantity"
                onChange={e => {
                  updateQuantity({ id: book.id, quantity: e.target.value })
                }}
                onSelect={() => null}
                value={book.quantity}
              >
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
              </QuantitySelect>
            ) : (
              <QuantitySelect
                name="forced-quantity"
                id="forced-quantity"
                onSelect={() => null}
                value={1}
                disabled
              >
                <option value={1}>1</option>
              </QuantitySelect>
            )}
          </Flex>
          <Flex
            width={1 / 1}
            mt={2}
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <IconButton
              icon="delete"
              width="auto"
              onClick={() => deleteProduct(book.id)}
            />
          </Flex>
        </Flex>
      </ItemBox>
    )
  )
}

export default Book
