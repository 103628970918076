import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link, Redirect, usePaths } from "@engaging-tech/routing"
import {
  Card,
  Flex,
  Box,
  H3,
  Text,
  Button,
  Spinner
} from "@engaging-tech/components"
import PrimaryButton from "../../../ui/components/PrimaryButton"

const CardTitle = styled(H3)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[4]};
`
const Summary = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[4]};
`

const Total = ({
  hasProducts,
  isAuthenticated,
  createOrder,
  orderId,
  basket,
  subTotalPrice,
  isPremium,
  onLoad,
  isCheckoutLoading
}) => {
  const [price, setPrice] = useState(0)
  const paths = usePaths()

  useEffect(() => {
    onLoad()
  }, [])

  useEffect(() => {
    if (isPremium) {
      setPrice(subTotalPrice * 0.8)
    } else {
      setPrice(subTotalPrice)
    }
  }, [subTotalPrice, isPremium])

  if (orderId) {
    return <Redirect to={paths.resourceCentre.basket.checkout} />
  }

  const generateCheckoutButtonStatus = () => (
    <Flex px={3} pt={2} pb={3} width={1 / 1}>
      {isCheckoutLoading ? (
        <Spinner width={1 / 1} color="primary.0" height={30} />
      ) : (
        <PrimaryButton
          width={1 / 1}
          text="Checkout"
          color="grey.0"
          onClick={() => createOrder(basket)}
          disabled={!hasProducts}
        />
      )}
    </Flex>
  )

  return (
    <Card bg="light.0" flexDirection="column" mb={[3, 0]} width={[1 / 1, 230]}>
      <CardTitle width={1 / 1} fontSize={18} fontWeight={700} p={3}>
        Total
      </CardTitle>
      <Summary>
        {basket.map(book => (
          <Flex
            p={3}
            width={1 / 1}
            justifyContent="space-between"
            alignItems="center"
            key={book.id}
          >
            <Text fontSize={2} pr={2}>
              {book.title}
            </Text>
            <Text fontSize={4} color="dark.2" width="auto">
              £{(book.quantity * parseFloat(book.price)).toFixed(2)}
            </Text>
          </Flex>
        ))}
      </Summary>
      <Flex
        px={3}
        py={2}
        width={1 / 1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Text>Subtotal</Text>
        <Text width="auto">£{subTotalPrice.toFixed(2)}</Text>
      </Flex>

      {isPremium && (
        <Flex
          px={3}
          py={2}
          width={1 / 1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Text color="dark.2">Discount</Text>
          <Text color="dark.2" ml={2}>
            20%
          </Text>
          <Flex justifyContent="flex-end" alignItems="center">
            <Text color="dark.2" width="auto">
              - £
            </Text>
            <Text color="dark.2" width="auto">
              {(subTotalPrice - price).toFixed(2)}
            </Text>
          </Flex>
        </Flex>
      )}
      <Flex
        px={3}
        py={2}
        width={1 / 1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Text fontWeight="500">TOTAL TO PAY</Text>
        <Text width="auto">£{price.toFixed(2)}</Text>
      </Flex>
      {isAuthenticated ? (
        generateCheckoutButtonStatus()
      ) : (
        <Flex
          px={3}
          py={2}
          width={1 / 1}
          flexDirection={["column", "column", "row"]}
        >
          <Box width={[1 / 1, 1 / 1, "auto"]} mr={[0, 0, 2]} mb={[2, 2, 0]}>
            <Link to={paths.account.signUp}>
              <Button width={1 / 1} variant="secondary.0">
                Sign Up
              </Button>
            </Link>
          </Box>
          <Box width={[1 / 1, 1 / 1, "auto"]}>
            <Link to={paths.account.signIn}>
              <Button width={1 / 1} variant="secondary.4">
                Sign In
              </Button>
            </Link>
          </Box>
        </Flex>
      )}
    </Card>
  )
}

export default Total
