import { Flex, ToggleLogic, UserDetails } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React, { useCallback } from "react"
import { useSelector } from "react-redux"

import BookmarkToggle from "../../../bookmarks/containers/BookmarkToggle"
import ConnectModal from "../../../connect/containers/ConnectModal"
import LibrarySummary from "../../../userGeneratedContent/components/BusinessLibrary/Summary/Summary"
import AdviceSummary from "../../../userGeneratedContent/components/GlobalHub/Advice/Summary"
import EventSummary from "../../../userGeneratedContent/components/GlobalHub/Event/Summary"
import HubSummary from "../../../userGeneratedContent/components/GlobalHub/Summary/Summary"
import JobDetails from "../../../userGeneratedContent/components/Jobs/Details/Details"
import { yourProfileSelectors } from "../../../yourProfile"
import CreateComment from "../../containers/CreateComment"
import Endorsements from "../../containers/Endorsements"
import CommentCounter from "../Comments/CommentCounter"
import Comments from "../Comments/Comments"
import Share from "../Share/Share"

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const PostUserDetails = ({ user, publishedAt, interactions }) => {
  const currentUserId = useSelector(yourProfileSelectors.getUserId)
  const router = useRouter()
  const paths = usePaths()

  const handleOnUserClick = useCallback(
    toggle => {
      if (!user) return null

      if (user.id !== currentUserId) return toggle()

      return router.navigate(paths.careerDeveloper.yourProfile)
    },
    [currentUserId, paths.careerDeveloper.yourProfile, router, user]
  )
  return (
    <ToggleLogic>
      {({ on, toggle }) => (
        <>
          <Flex width="100%">
            <Flex width="calc(100% - 40px)">
              <UserDetails
                user={user}
                userHoldingImage={profilePlaceholder}
                avatarDecoration={{
                  bg: "light.0",
                  color: "primary.0",
                  icon: "supervised_user_circle",
                  isVisible: user?.isConnected
                }}
                avatarMargin={2}
                handleOnUserClick={() => handleOnUserClick(toggle)}
                time={publishedAt}
              />
            </Flex>
            <Flex width={40} justifyContent="center" flexDirection="column" alignItems="flex-end">
              {interactions()}
            </Flex>
          </Flex>

          {on && <ConnectModal {...user} onToggle={toggle} />}
        </>
      )}
    </ToggleLogic>
  )
}

const Post = ({ post }) => {
  if (post.materialRef) {
    return (
      <LibrarySummary
        post={post.materialRef}
        hubPostId={post.id}
        user={post.user}
        publishedAt={post.publishedAt}
        userDetails={PostUserDetails}
        interactions={() => (
          <Flex width="100%" justifyContent="center" alignItems="flex-end">
            <BookmarkToggle id={post.materialRef.id} postType={post.materialRef.type} />
            <Share id={post.id} />
          </Flex>
        )}
        postInteractions={() => (
          <>
            <Endorsements endorsements={post.likes} postId={post.id} />
            <CommentCounter count={post?.comments?.length} />
          </>
        )}
        postComments={
          post?.comments?.length ? () => <Comments comments={post.comments} postId={post.id} /> : null
        }
        commentForm={() => <CreateComment postId={post.id} />}
      />
    )
  }

  if (post.adviceRef) {
    return (
      <AdviceSummary
        user={post.user}
        post={post.adviceRef}
        hubPostId={post.id}
        publishedAt={post.publishedAt}
        userDetails={PostUserDetails}
        interactions={() => (
          <>
            <Share id={post.id} />
          </>
        )}
        postInteractions={() => (
          <>
            <Endorsements endorsements={post.likes} postId={post.id} />
            <CommentCounter count={post?.comments?.length || 0} />
          </>
        )}
        postComments={
          post?.comments?.length ? () => <Comments comments={post.comments} postId={post.id} /> : null
        }
        commentForm={() => <CreateComment postId={post.id} />}
      />
    )
  }

  if (post.eventRef) {
    return (
      <EventSummary
        user={post.user}
        post={post.eventRef}
        hubPostId={post.id}
        publishedAt={post.publishedAt}
        userDetails={PostUserDetails}
        interactions={() => (
          <>
            <Share id={post.id} />
          </>
        )}
        postInteractions={() => (
          <>
            <Endorsements endorsements={post.likes} postId={post.id} />
            <CommentCounter count={post?.comments?.length} />
          </>
        )}
        postComments={
          post?.comments?.length ? () => <Comments comments={post.comments} postId={post.id} /> : null
        }
        commentForm={() => <CreateComment postId={post.id} />}
      />
    )
  }

  if (post.jobRef) {
    return (
      <JobDetails
        job={post.jobRef}
        user={post.user}
        publishedAt={post.publishedAt}
        interactions={() => <Share id={post.id} />}
        userDetails={PostUserDetails}
        postInteractions={() => (
          <>
            <Endorsements endorsements={post.likes} postId={post.id} />
            <CommentCounter count={post?.comments?.length} />
          </>
        )}
        postComments={
          post?.comments?.length ? () => <Comments comments={post.comments} postId={post.id} /> : null
        }
        commentForm={() => <CreateComment postId={post.id} />}
      />
    )
  }

  return (
    <HubSummary
      post={post}
      user={post.user}
      interactions={() => <Share id={post.id} />}
      userDetails={PostUserDetails}
      postInteractions={() => (
        <>
          <Endorsements endorsements={post.likes} postId={post.id} />
          <CommentCounter count={post?.comments?.length} />
        </>
      )}
      postComments={
        post?.comments?.length ? () => <Comments comments={post.comments} postId={post.id} /> : null
      }
      commentForm={() => <CreateComment postId={post.id} />}
    />
  )
}

export default Post
