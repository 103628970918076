import { Flex } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"

import SiteBanner from "../features/ui/components/SiteBanner"
import TestingSiteBanner from "../features/ui/components/TestingSiteBanner"

const SolidLayout = ({ alignItems = "center", children, banner: Banner, ...props }) => {
  const router = useRouter()
  const paths = usePaths()
  const { downForMaintenance, websiteInfoBanner } = getConfig()
  if (downForMaintenance.all) {
    router.navigate(paths.downForMaintenance)
  }
  return (
    <>
      <TestingSiteBanner />
      {websiteInfoBanner && <SiteBanner text={websiteInfoBanner} disableClose icon="warning" />}
      {Banner && <Banner />}
      <Flex as="main" flexDirection="column" minHeight="100vh" bg="primary.0">
        <Flex width={1 / 1} flex="1" alignItems={alignItems} justifyContent="center" {...props}>
          {children}
        </Flex>
      </Flex>
    </>
  )
}

export default SolidLayout
