import styled from "styled-components"
import { Flex } from "@engaging-tech/components"

const Wrapper = styled(Flex)`
  & > div {
    @media (min-width: ${({ theme }) => theme.breakpoints[0]}px) {
      width: ${({ theme }) =>
        `calc(50% - ${theme.space[2]}px - ${theme.space[2]}px)`};
      margin-left: ${({ theme }) => `${theme.space[2]}px`};
      margin-right: ${({ theme }) => `${theme.space[2]}px`};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints[1]}px) {
      width: ${({ theme }) =>
        `calc(33% - ${theme.space[2]}px - ${theme.space[2]}px)`};
    }
  }
`

export default Wrapper
