import { Select } from "@engaging-tech/components"
import styled from "styled-components"

const SelectShaped = styled(Select)`
  & > select {
    background-color: ${({ theme }) => theme.colors.light[0]};
    border: 1px solid rgba(0, 0, 0, 0.24);
    border-radius: 5px;
    padding: 12px 42px 12px 32px;
    transition: 0.18s;
  }

  & > select:hover {
    border: 1px solid ${({ theme }) => theme.colors.dark[1]};
    background-color: ${({ theme }) => theme.colors.light[0]};
  }

  & > select:focus {
    border: 1px solid ${({ theme }) => theme.colors.primary[0]};
    background-color: ${({ theme }) => theme.colors.light[0]};
  }

  & > label {
    display: block;
    top: 0px;
    left: 30px;
    border: 2px solid white;

    /* Hacky solution for providing the background around the label text */
    padding: 0 4px;
    line-height: 5%;
    overflow: visible;
  }

  & > div {
    padding-left: 16px;
  }
`

export default SelectShaped
