import { connect } from "react-redux"
import EventAlertsComponent from "../components/EventAlerts"
import * as NotificationsActions from "../store/notifications.actions"

const mapDispatch = dispatch => ({
  handleSubmit: (frequency, location, category) =>
    dispatch(
      NotificationsActions.createEventNotification({
        frequency,
        categoryId: category.id,
        location
      })
    )
})

const EventAlerts = connect(null, mapDispatch)(EventAlertsComponent)

export default EventAlerts
