import React from "react"
import { Flex, Icon, Text, Box } from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"
import styled from "styled-components"

const Border = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary[0]};
  margin-bottom: 20px;
`

const premiumFeaturesTextArray = [
  {
    title: "Job Matcher Services",
    text:
      "Get automatically matched with a job vacancy based on your skill set and career goals"
  },
  {
    title: "Survey Results Action Plans",
    text:
      "Get comprehensive guidance in how to improve your low-scoring areas of your workplace happiness"
  },
  {
    title: "Mentor Matcher Service - For Mentees",
    text:
      "Get connected with a mentor who can help guide and advise you at any point in your career"
  },
  {
    title: "Monthly Business e-magazine",
    text: "With articles, podcasts, lectures, book reviws and much more"
  },
  {
    title: "Tailored Job Notifications",
    text:
      "Receive job notifications tailored to your search preferences to save time un your job search"
  },
  {
    title: "Event Notifications",
    text:
      "Receive tailored event notifications based on your interests, preferences and location"
  },
  {
    title:
      "Receive 20% off all books, ebooks and audiobooks in the Business Library"
  },
  {
    title: "Receive a free ebook: 'Fairness For All' by Lord Mark Price"
  }
]

const PremiumFeatures = ({ icon, title, signUpText, subTexts = [] }) => {
  const paths = usePaths()
  return (
    <Flex
      width={1 / 1}
      bg="dark.7"
      flexDirection="column"
      alignItems="center"
      px={[1, 3]}
      py={[1, 3]}
      borderRadius="7px"
    >
      {icon && <Icon name={icon} color="secondary.0" size={50} mb={3}/>}
      <Text
        fontSize={5}
        fontWeight={600}
        fontFamily="Tiempos"
        textAlign="center"
        color="primary.0"
        mb={4}
      >
        {title}
      </Text>
      <Text fontSize={3} color="dark.1" textAlign="center" px={[2, 5]}>
        Sign up to our{" "}
        <Link to={paths.getPremium.index}>
          <Text fontSize={3} color="primary.0" fontWeight={600}>
            Premium Service
          </Text>
        </Link>{" "}
        {signUpText ? `to ${signUpText}` : null}
      </Text>
      {subTexts.map(text => (
        <Text fontSize={3} color="dark.1" textAlign="center" px={[2, 5]} mb={4}>
          {text}
        </Text>
      ))}
      <Border />
      <Text
        fontSize={5}
        fontWeight={600}
        fontFamily="Tiempos"
        textAlign="center"
        color="primary.0"
        mb={3}
      >
        Get access to these exclusive features with Premium
      </Text>
      <Text
        fontSize={3}
        color="primary.0"
        fontWeight={600}
        textAlign="center"
        px={[3, 5]}
        mb={2}
      >
        Enjoy a free 14 day trial then use our full range of Premium services
        for £4.99 a month
      </Text>
      <Text
        fontSize={2}
        color="primary.0"
        textAlign="center"
        px={[2, 5]}
        mb={3}
      >
        This price includes VAT
      </Text>
      <Flex flexDirection="row" flexWrap="wrap" width="100%" mb={1}>
        {premiumFeaturesTextArray.map(data => (
          <Flex
            flexDirection="column"
            width={["100%", "50%"]}
            p={[2, 1]}
            px={[3, 1]}
          >
            <Flex width="100%">
              <Icon name="done_outline" color="primary.0" size={20} />
              <Text fontSize={3} color="primary.0" ml={2} fontWeight={600}>
                {data.title}
              </Text>
            </Flex>
            <Text fontSize={3} color="primary.0" width="100%">
              {data.text}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}

export default PremiumFeatures
