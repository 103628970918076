import { Spinner } from "@engaging-tech/components"
import React from "react"
import SurveyCard from "../surveyCard"

const ValidatingCode = () => (
  <SurveyCard>
    <Spinner py={3} color="primary.0" justifyContent="center" width={1 / 1} />
  </SurveyCard>
)

export default ValidatingCode
