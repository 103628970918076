import { types } from "./saml.actions"

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  isLoadingSurveys: false,
  hasLoadedSurveys: false,
  surveys: null
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SAML_SIGN_IN:
      return { ...state, isLoading: true, hasLoaded: false }

    case types.SAML_SIGN_IN_SUCCESS:
      return { ...state, isLoading: false, hasLoaded: true }

    case types.FETCH_SURVEY_FOR_SAML_USER:
      return { ...state, isLoadingSurveys: true, hasLoadedSurveys: false }

    case types.FETCH_SURVEY_FOR_SAML_USER_SUCCESS:
      return {
        ...state,
        isLoadingSurveys: false,
        hasLoadedSurveys: true,
        surveys: action.payload
      }

    default:
      return state
  }
}
