import { Box, Button, Flex, Icon, Text } from "@engaging-tech/components"
import styled from "styled-components"

export const TestWrapper = styled(Flex)`
  align-items: center;

  width: auto;
`

export const TestButton = styled(Button)`
  margin: 16px 0 12px;
  width: 175px;
  height: 28px;

  background: #1be96e;

  border-radius: 5px;
  box-shadow: none;
  position: relative;
  z-index: 2;
`

export const TestButtonText = styled(Text)`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  text-align: center;

  color: #003e4c;
`

export const TestButtonIcon = styled(Icon)`
  margin-left: 2px;

  color: #013e4c;
`

export const TestMessageWrapper = styled(Box)`
  width: auto;

  padding: 0 5px;
  text-align: center;
  overflow-wrap: break-word;

  display: flex;
  flex-wrap: wrap;
`

export const TestMessage = styled(Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  max-width: 447px;
  width: auto;
`
