import Article from "./Article"
import Podcast from "./Podcast"
import Lecture from "./Lecture"
import Research from "./Research"

export default {
  Article,
  Podcast,
  Lecture,
  Research
}
