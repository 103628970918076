import { connect } from "react-redux"
import {
  getStep,
  getHappinessScoreChange,
  getCompletedAt
} from "../store/selectors"

import WellbeingDataHighlight from "../components/WellbeingDataHighlight"

const mapState = (state, props) => ({
  // happinessDatahighlightData: Selectors.getHappinessDatahighlightData(state)
  completedAt: getCompletedAt(state),
  happinessChange: getHappinessScoreChange(state, props.submissionId),
  wellbeingScore: getStep(state, "well_being")
})

const WellbeingDataHighlightContainer = connect(mapState)(
  WellbeingDataHighlight
)

export default WellbeingDataHighlightContainer
