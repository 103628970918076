import React, { useEffect } from "react"
import styled from "styled-components"
import { Flex, Spinner, Card, Box, H3, Text } from "@engaging-tech/components"
import { useParams } from "@engaging-tech/routing"

import OrderItem from "./OrderItem"

const CardTitle = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[4]};
`

const ThankYouTitle = styled(H3)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[4]};
`

const BookOrder = ({ order, hasPaid, isLoading, onLoad, onLeave }) => {
  const { id } = useParams()

  useEffect(() => {
    onLoad({ id })

    return () => onLeave()
  }, [])

  if (isLoading) {
    return (
      <Spinner color="primary.0" width={1 / 1} py={3} justifyContent="center" />
    )
  }

  if (!order) {
    return (
      <Flex flexDirection="column" width={1 / 1}>
        <Card bg="light.0" flexDirection="column" mr={[0, 3]} mb={3} p={0}>
          <H3 fontSize={20} fontWeight="500" p={3} color="dark.2">
            Order Not found
          </H3>
        </Card>
      </Flex>
    )
  }

  return (
    <Flex flexDirection="column" width={1 / 1}>
      {hasPaid && (
        <ThankYouTitle
          fontSize={20}
          fontWeight="500"
          width={1 / 1}
          pt={3}
          pb={3}
          mb={3}
          color="dark.2"
        >
          Thank you, your order has been placed
        </ThankYouTitle>
      )}
      <Card bg="light.0" flexDirection="column" mr={[0, 3]} mb={3} p={0}>
        <CardTitle p={3} width={1 / 1}>
          <H3 pb={2} fontSize={5} fontWeight="500">
            Order Details
          </H3>
          <H3 py={2} fontSize={3} fontWeight="500" color="dark.2">
            Order Number: {order.id}
          </H3>
        </CardTitle>
        <Flex flexDirection="column" px={3} py={3}>
          {order.booksData.map(book => (
            <OrderItem key={book.id.toString()} book={book} />
          ))}
        </Flex>
        <Flex
          width={1 / 1}
          flexDirection={["column", "row"]}
          justifyContent="space-between"
          px={3}
          py={3}
        >
          <Flex width={[1 / 1, 1 / 3]} flexDirection="column" px={3}>
            All prices include VAT
          </Flex>

          <Flex width={[1 / 1, 1 / 4]} flexDirection="column" px={3} py={3}>
            <Flex
              width={1 / 1}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Text fontSize={4} py={2} fontWeight={500} color="dark.1">
                SubTotal
              </Text>
              <Text fontSize={4} py={2} color="dark.2" width="auto">
                {`£${(order.price - order.delivery).toFixed(2)}`}
              </Text>
            </Flex>
            <Flex
              width={1 / 1}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Text fontSize={4} py={2} fontWeight={500} color="dark.1">
                Delivery
              </Text>
              <Text fontSize={4} py={2} color="dark.2" width="auto">
                {`£${order.delivery.toFixed(2)}`}
              </Text>
            </Flex>
            <Flex
              width={1 / 1}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Text fontSize={4} py={2} fontWeight={500} color="dark.1">
                TOTAL PAID
              </Text>
              <Text fontSize={4} py={2} color="dark.2" width="auto">
                {`£${order.price.toFixed(2)}`}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Card>
      {order.address && (
        <Card bg="light.0" flexDirection="column" mr={[0, 3]} p={0}>
          <CardTitle p={3} width={1 / 1} fontSize={5} fontWeight="500">
            Delivery Details
          </CardTitle>
          <Flex flexDirection="column" px={3} py={3}>
            <Text fontSize={3} py={2} color="dark.2">
              {order.name}
            </Text>
            <Text fontSize={3} py={2} color="dark.2">
              {order.address}
            </Text>
            <Text fontSize={3} py={2} color="dark.2">
              {order.postCode}
            </Text>
            <Text fontSize={3} py={2} color="dark.2">
              {order.country}
            </Text>
          </Flex>
        </Card>
      )}
    </Flex>
  )
}

export default BookOrder
