import React from "react"

import { Text, Flex } from "@engaging-tech/components"
import styled from "styled-components"
import ClickableIcon from "../../ui/components/ClickableIcon"

const AlertText = styled(Text)`
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes[3]}px;
  color: ${({ theme }) => theme.colors.dark[1]};
`

const FailBox = ({ errorCode, failToggle, type }) => {
  const failReason = {
    ERR009: `The alert for this ${type} already exists`
  }

  return (
    <Flex bg="primary.5" flexDirection="row" py={2} px={3} borderRadius={4}>
      <Text color="dark.2" fontSize={3}>
        Unfortunately, your alert has failed due to the following reason:{" "}
        <AlertText>{failReason[errorCode]}</AlertText>
      </Text>
      <ClickableIcon name="close" onClick={failToggle} />
    </Flex>
  )
}

export default FailBox
