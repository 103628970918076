import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { string, object, array } from "yup"
import { titleCaps, getCountries } from "@engaging-tech/frontend-utils"
import { Box, Text, LocationAutocomplete } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import { Area } from "../../userGeneratedContent/components/PublishForm/PublishInput"
import CategorySelect from "./CategorySelect"
import TextSearch from "./TextSearch"
import AdviceForm from "../forms/AdviceForm"
import { MiscSelectors } from "../../misc"
import IndustriesLoader from "../../misc/containers/IndustriesLoader"

const CreateAdviceForm = ({
  onSubmit,
  isLoading,
  hasCreated,
  jobRoles,
  textSearch,
  onLoadJobRoles,
  onSetTextSearch
}) => {
  const initialValues = {
    expertTypeId: "",
    industriesId: [],
    question: "",
    location: {
      countryId: "",
      name: "",
      coordinates: {
        lat: "",
        lng: ""
      }
    }
  }

  const validation = object().shape({
    expertTypeId: string().required("Type of the expert is required"),
    industriesId: array().of(string()),
    question: string().required("A question is required"),
    location: object().shape({
      name: string().required("Type and select a location")
    })
  })

  const allIndustries = useSelector(MiscSelectors.getAllIndustries).map(
    industry => {
      return {
        ...industry,
        name: titleCaps(industry.name)
      }
    }
  )
  return (
    <>
      <IndustriesLoader />
      <AdviceForm
        initialValues={initialValues}
        validation={validation}
        onSubmit={onSubmit}
        isLoading={isLoading}
        hasCreated={hasCreated}
        render={({
          handleBlur,
          handleSelect,
          handleCloseDropdown,
          setFieldTouched,
          setFieldValue,
          touched,
          values,
          handleChange,
          errors
        }) => {
          const [countries, setCountries] = useState([])
          const [selectedOption, setSelectedOption] = useState("")
          const [internalTextSearch, setInternalTextSearch] = useState(
            textSearch
          )
          const [showJobRolesList, setShowJobRolesList] = useState(false)

          const onFinishWriting = () => {
            onSetTextSearch(internalTextSearch)
            if (internalTextSearch.length > 2) {
              onLoadJobRoles(internalTextSearch)
            }
          }

          const handleDropDowns = e => {
            setShowJobRolesList(false)
            handleSelect(e)
          }

          useEffect(() => {
            // Formik expects an object with target, name and value. It won't
            // work otherwise.
            const customEventForFormik = {
              target: {
                name: "expertTypeId",
                value: selectedOption.id
              }
            }
            if (selectedOption) {
              handleChange(customEventForFormik)
              onSetTextSearch(selectedOption.name)
            }
          }, [selectedOption])

          useEffect(() => {
            setCountries(getCountries())
          }, [])

          useEffect(() => {
            if (jobRoles.length > 0) setShowJobRolesList(true)
            else setShowJobRolesList(false)
          }, [jobRoles])

          const handleLocationChange = e => {
            if (e.geometry) {
              setFieldValue("location", {
                countryId: countries.filter(
                  country =>
                    country.code ===
                    e.address_components.filter(component =>
                      component.types.includes("country")
                    )[0].short_name
                )[0].id,
                name: e.formatted_address,
                coordinates: {
                  lat: e.geometry.location.lat(),
                  lng: e.geometry.location.lng()
                }
              })
            } else if (values.location.name) {
              if (e !== values.location.name.name)
                // this happens when they have not selected a dropdown object, and 'e' === formatted_address
                setFieldValue("location", "")
            }
          }

          return (
            <>
              <Box mb={3}>
                <TextSearch
                  id="expertTypeId"
                  name="expertTypeId"
                  label="Type of expert"
                  helperText="Start typing to choose an expert"
                  defaultValue=""
                  sourceList={jobRoles}
                  onChange={handleChange}
                  value={values.expertTypeId}
                  onBlur={handleBlur}
                  handleSelect={handleSelect}
                  searchText={internalTextSearch}
                  setSearchText={setInternalTextSearch}
                  setSelectedOption={setSelectedOption}
                  onFinishWriting={onFinishWriting}
                  showDropDownList={showJobRolesList}
                  error={touched.expertType && errors.expertType}
                />
              </Box>
              <CategorySelect
                label="Industries"
                name="industriesId"
                helperText="Select the industries (max 3)"
                categories={allIndustries}
                onChange={handleChange}
                closeDropdownTrigger={handleCloseDropdown}
                selectedIndustries={values.industriesId}
              />
              <Area
                onSelect={handleDropDowns}
                height={[248, 124]}
                id="advice-question"
                name="question"
                type="text"
                label="What would you like to ask?"
                helperText="Enter a question you would like answered by an industry expert"
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={250}
                value={values.question}
                error={touched.question && errors.question}
              />
              <Text fontSize={3}>Where are you located?</Text>

              <LocationAutocomplete
                mt={2}
                types={["(cities)"]}
                width={1 / 1}
                id="location.name"
                name="location.name"
                type="text"
                initialValue={values.location.name || null}
                placeholder="City / Region.."
                value={values.location.name || null}
                googleApiKey={getConfig().keys.googleApiKey}
                selectedLocationCallback={location =>
                  handleLocationChange(location)
                }
                onFocus={() => setFieldTouched("location.name")}
                onChange={handleLocationChange}
                error={
                  touched.location &&
                  errors.location &&
                  touched.location.name &&
                  errors.location.name
                }
              />
            </>
          )
        }}
      />
    </>
  )
}

export default CreateAdviceForm
