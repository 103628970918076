import React from "react"

import WidgetCard from "../../../components/WidgetCard"
import JobMatcherWidgetContent from "./jobMatcherWidgetContent"

const JobMatcherWidget = ({ id }) => (
  <WidgetCard width="100%" height="100%" bg="light.0">
    <JobMatcherWidgetContent id={id} />
  </WidgetCard>
)

export default JobMatcherWidget
