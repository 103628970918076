import React, { useState } from "react"

import ContentBlock from "../ContentBlock"
import FeatureSelector from "./FeatureSelector"
import homepageFeatures from "../../lib/homepageFeatures"
import Description from "./Description"

const SiteFeatures = () => {
  const [selected, setSelected] = useState(0)

  const selectFeature = index => setSelected(index)

  return (
    <ContentBlock title="How we can help" isFullWidth noGutter>
      <FeatureSelector
        features={homepageFeatures}
        onSelect={selectFeature}
        selected={selected}
      />
      <ContentBlock py={0}>
        <Description feature={homepageFeatures[selected]} />
      </ContentBlock>
    </ContentBlock>
  )
}

export default SiteFeatures
