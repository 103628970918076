import React from "react"

import DetailsLayout from "../../../layouts/DetailsLayout"
import Post from "../../../features/businessLibrary/containers/Post"

const Research = () => (
  <DetailsLayout
    flexDirection="column"
    alignItems="center"
    justifyContent="flex-start"
  >
    <Post />
  </DetailsLayout>
)

export default Research
