import { connect } from "react-redux"

import LoadMoreButton from "../components/Posts/LoadMore"
import { loadMore } from "../store/globalHub.actions"
import {
  getHasLoadedAll,
  getIsLoadingMore,
  getLoadCounter,
  getSelectedCategory
} from "../store/globalHub.selectors"

const mapState = state => ({
  from: getLoadCounter(state),
  postType: getSelectedCategory(state),
  isLoadingMore: getIsLoadingMore(state),
  hasLoadedAll: getHasLoadedAll(state)
})

const mapDispatch = dispatch => ({
  onLoad: (postType, from, place) =>
    dispatch(
      loadMore({
        postType,
        from,
        place
      })
    )
})

const LoadMore = connect(mapState, mapDispatch)(LoadMoreButton)

export default LoadMore
