import { Flex } from "@engaging-tech/components"
import React from "react"

import SurveyGuard from "../../../features/survey/guards/surveyGuard"
import SurveySubmitView from "../../../features/survey/views/surveySubmit"

const SurveySubmit = () => (
  <Flex minHeight="100vh" flexDirection="column">
    <Flex as="main" flex="1" alignItems="stretch">
      <SurveyGuard>
        <SurveySubmitView />
      </SurveyGuard>
    </Flex>
  </Flex>
)

export default SurveySubmit
