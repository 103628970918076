import { Box, Flex, Paragraph, Toggle } from "@engaging-tech/components"
import _debounce from "lodash.debounce"
import React from "react"
import styled from "styled-components"

const BorderedBox = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.dark[4]};
  padding: ${({ theme }) => theme.space[4]}px;
`

const MarketingSettings = ({ submitProfile, allowMarketing }) => {
  const allowMarketingChange = value => {
    const data = {
      allowMarketing: value
    }

    submitProfile(data)
  }
  const handleToggle = _debounce(allowMarketingChange, 4000)

  return (
    <Flex flexDirection="column">
      <Paragraph
        fontWeight={700}
        fontSize={5}
        color="primary.0"
        textAlign="center"
      >
        Manage Your Marketing Preferences
      </Paragraph>
      <BorderedBox>
        <Flex flexDirection="row">
          <Paragraph>Allow Marketing</Paragraph>
          <Toggle
            id="toggle-marketing"
            onColour="secondary.0"
            offColour="dark.3"
            size={40}
            submitProfile={submitProfile}
            onChange={handleToggle}
            initialState={allowMarketing}
          />
        </Flex>
      </BorderedBox>
    </Flex>
  )
}

export default MarketingSettings
