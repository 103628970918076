import { getConfig } from "@engaging-tech/ssr-config"
import { apollo } from "../../../lib/Apollo"
import {
  CONNECT_TO_USER,
  GET_CONNECT_INVITES,
  REPLY_INVITATION,
  REPORT_USER
} from "./connect.queries"

const GRAPHQL_API_URL = () =>
  `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

/**
 * Accept or decline an invitation to connect users.
 * @param {Object} variables - Variables containing response to invitation.
 * @param {string} invitationId - The ID of the invitation being responded to.
 * @param {boolean} accept - The response to the invitation.
 */
export const replyToInvitation = ({ invitationId, accept }) =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: REPLY_INVITATION,
    variables: { invitationId, accept }
  })

/**
 * Send a connection invite to specified user.
 * @param {string} userId - The ID of the user being sent the invitation.
 */
export const connectToUser = userId =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: CONNECT_TO_USER,
    variables: { userId }
  })

/**
 * Get all connection invites for current user.
 */
export const loadInvites = () =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: GET_CONNECT_INVITES
  })

export const reportUser = ({ reason, reportedUserId }) => {
  return apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: REPORT_USER,
    variables: { reason, reportedUserId }
  })
}
