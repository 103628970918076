import { connect } from "react-redux"
import { getSelected, getIsLoading } from "../store/businessLibrary.selectors"

import {
  UserContentActions,
  UserContentSelectors
} from "../../userGeneratedContent"
import { loadSelected } from "../store/businessLibrary.actions"

import SelectedPost from "../components/SelectedPost"

const mapState = state => ({
  isLoading: getIsLoading(state),
  post: getSelected(state),
  intentSecret: UserContentSelectors.getIntentSecret(state)
})

const mapDispatch = dispatch => ({
  onLoad: postId => dispatch(loadSelected(postId)),
  onSubmit: postId =>
    dispatch(UserContentActions.createPurchaseMaterialIntent(postId)),
  onPaymentActionSuccess: () =>
    dispatch(UserContentActions.purchaseMaterialSuccess()),
  onPaymentActionFail: () => dispatch(UserContentActions.purchaseMaterialFail())
})

const Post = connect(mapState, mapDispatch)(SelectedPost)

export default Post
