/* eslint-disable import/prefer-default-export */
import { gql } from "../../../lib/Apollo"

export const LOAD = gql`
  query getUserProfile($userId: ID!) {
    getUserProfile(userId: $userId) {
      id
      firstName
      lastName
      email
      confirmed
      premium {
        active
        expireDate
        cancelled
        discounts
      }
      jobTitle
      companyName
      desiredJobLocation {
        name
        vicinity
        country {
          name
          shortName
        }
        countryId
        point {
          coordinates
        }
      }
      location {
        name
        vicinity
        country {
          name
          shortName
        }
        countryId
        point {
          coordinates
        }
      }
      websiteUrl
      currentGoal
      employmentStatus
      picture {
        small
        large
      }
      goalsViewed
      goals {
        id
        key
        text
      }
      jobMatcher {
        currentJobRole
        currentJobSalary
        desiredJobRoles
        desiredJobType
        desiredJobSalary {
          min
          max
          private
        }
      }
      allowMarketing
      skills {
        id
        name
      }
      positionsHeld {
        id
        jobTitle
        location {
          name
          vicinity
          countryId
          country {
            name

            shortName
          }
          point {
            coordinates
          }
        }
        company
        startDate
        endDate
        description
      }
      qualifications {
        institution
        courseTitle
        location {
          name
          vicinity
          countryId
          country {
            shortName
            name
          }
          point {
            coordinates
          }
        }
        description
        start
        end
      }
      personalStatement
      age
      gender
      industries {
        id
        name
      }
      hobbies {
        id
        name
      }
      languages {
        code
        name
        nativeName
      }
      services {
        jobMatcher
        mentorMatcher
        menteeMatcher
      }
    }
  }
`
export const CREATE_PROFILE = gql`
  mutation createProfile(
    $firstName: String!
    $lastName: String!
    $allowMarketing: Boolean!
    $location: ProfileCreationLocationInput!
  ) {
    createProfile(
      firstName: $firstName
      lastName: $lastName
      allowMarketing: $allowMarketing
      location: $location
    ) {
      id
      firstName
      lastName
      goalsViewed
      jobTitle
      confirmed
      premium {
        active
        expireDate
        cancelled
      }
      companyName
      goals {
        id
        key
        text
      }
      websiteUrl
      currentGoal
      employmentStatus
      picture {
        small
        large
      }

      location {
        name
        vicinity
        country {
          name
          shortName
        }
        countryId
        point {
          coordinates
        }
      }

      desiredJobLocation {
        name
        vicinity
        country {
          name
          shortName
        }
        countryId
        point {
          coordinates
        }
      }
    }
  }
`

export const UPDATE_YOUR_PROFILE = gql`
  mutation updateMyProfile(
    $firstName: String
    $goalsViewed: Boolean
    $lastName: String
    $jobTitle: String
    $companyName: String
    $location: ProfileCreationLocationInput
    $desiredJobLocation: ProfileCreationLocationInput
    $websiteUrl: String
    $currentGoal: String
    $employmentStatus: String
    $picture: UserProfilePictureInput
    $skills: [String]
    $personalStatement: String
    $allowMarketing: Boolean
    $age: String
    $gender: String
    $industries: [String]
    $hobbies: [String]
    $languages: [String]
    $positionsHeld: [PositionHeldInput]
    $qualifications: [QualificationsInput]
  ) {
    updateMyProfile(
      firstName: $firstName
      goalsViewed: $goalsViewed
      lastName: $lastName
      jobTitle: $jobTitle
      companyName: $companyName
      location: $location
      desiredJobLocation: $desiredJobLocation
      websiteUrl: $websiteUrl
      currentGoal: $currentGoal
      employmentStatus: $employmentStatus
      picture: $picture
      skills: $skills
      personalStatement: $personalStatement
      allowMarketing: $allowMarketing
      age: $age
      gender: $gender
      industries: $industries
      hobbies: $hobbies
      languages: $languages
      positionsHeld: $positionsHeld
      qualifications: $qualifications
    ) {
      personalStatement
      age
      gender
      languages {
        code
        name
        nativeName
      }
      hobbies {
        id
        name
      }
      industries {
        id
        name
      }
      skills {
        id
        name
      }
      qualifications {
        institution
        courseTitle
        description
        start
        end
        location {
          name
          countryId
          vicinity
          country {
            name
            shortName
          }
          point {
            coordinates
          }
        }
      }
      allowMarketing
      positionsHeld {
        id
        jobTitle
        company
        startDate
        endDate
        description
        location {
          name
          vicinity
          countryId
          country {
            name
            shortName
          }
          point {
            coordinates
          }
        }
      }
      id
      firstName
      lastName
      goalsViewed
      jobTitle
      confirmed
      premium {
        active
        expireDate
        cancelled
      }
      companyName
      goals {
        id
        key
        text
      }
      websiteUrl
      currentGoal
      employmentStatus
      picture {
        small
        large
      }

      jobMatcher {
        currentJobRole
        currentJobSalary
        desiredJobRoles
        desiredJobType
        desiredJobSalary {
          min
          max
          private
        }
      }

      services {
        jobMatcher
        mentorMatcher
        menteeMatcher
      }
      location {
        name
        vicinity
        country {
          name
          shortName
        }
        countryId
        point {
          coordinates
        }
      }

      desiredJobLocation {
        name
        vicinity
        country {
          name
          shortName
        }
        countryId
        point {
          coordinates
        }
      }
    }
  }
`

export const TOGGLE_SERVICES = gql`
  mutation toggleServices($services: ServiceInput!) {
    toggleServices(services: $services) {
      jobMatcher
      mentorMatcher
      menteeMatcher
    }
  }
`
export const SEND_CONFIRMATION_EMAIL = gql`
  mutation {
    sendEmailConfirmation
  }
`

export const CONFIRM_EMAIL = gql`
  mutation ($confirmToken: String!) {
    verifyEmailConfirmation(confirmToken: $confirmToken)
  }
`

export const UPDATE_MY_GOALS = gql`
  mutation updateGoals($goalIds: [ID]!) {
    updateGoals(goalIds: $goalIds) {
      id
      text
      key
    }
  }
`

// PREMIUM //
export const CANCEL_SUBSCRIPTION = gql`
  mutation cancelPremiumSubscription($reason: String!, $message: String) {
    cancelPremiumSubscription(reason: $reason, message: $message)
  }
`
