import { parseSurveyResponse } from "./helpers"
import initialState from "./ini"
import { types } from "./survey.actions"

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REMOVE_ANSWER:
      return {
        ...state,
        answers: state.answers.filter(a => a.questionId !== action.payload)
      }

    case types.LOAD:
      return { ...state, isLoading: true, hasError: false }

    case types.SET_SAVANTA_ID: {
      return { ...state, savantaId: action.payload }
    }

    case types.LOAD_SUCCESS:
      return {
        ...state,
        data: parseSurveyResponse(action.payload),
        isLoading: false,
        hasLoaded: true,
        hasAttachedUser: false,
        answers: []
      }

    case types.LOAD_FAIL:
      return { ...state, isLoading: false, hasError: true }

    case types.ANSWER:
      return {
        ...state,
        answers: [
          ...state.answers.filter(
            answer => answer.questionId !== action.payload.questionId
          ),
          action.payload
        ]
      }

    case types.CHECK_CODE:
      return {
        ...state,
        individualCode: action.payload.code,
        isValidatingCode: true,
        isValidCode: false,
        hasSubmitted: false
      }
    case types.CHECK_CODE_SUCCESS:
      return { ...state, isValidCode: true, isValidatingCode: false }

    case types.CHECK_CODE_FAIL:
      if (action.payload === 2)
        return {
          ...state,
          isValidCode: true,
          hasSubmitted: true,
          isValidatingCode: false
        }
      return {
        ...state,
        isValidCode: false,
        hasSubmitted: false,
        isValidatingCode: false
      }

    case types.SET_CODE:
      return {
        ...state,
        individualCode: action.payload,
        isValidCode: false,
        hasSubmitted: false
      }

    case types.SUBMIT:
      return { ...state, isSubmitting: true, hasError: false }

    case types.SUBMIT_SUCCESS:
      return {
        ...state,
        submissionId: action.payload.id,
        isSubmitting: false,
        hasSubmitted: true,
        hasAttachedUser: action.payload.isAuthenticated,
        hasError: false
      }

    case types.SUBMIT_FAIL:
      return { ...state, isSubmitting: false, hasError: true }

    case types.ATTACH_USER_SUCCESS:
      return { ...state, hasAttachedUser: true }

    case types.CHANGE_LANGUAGE:
      return { ...state, language: action.payload }

    case types.SECTIONS_LIST_UPDATE:
      return { ...state, sectionsList: action.payload }

    case types.SECTION_CURRENT_UPDATE:
      return { ...state, currentSection: action.payload, currentQuestion: 0 }

    case types.QUESTION_HISTORY_UPDATE:
      return { ...state, questionsHistory: action.payload }

    case types.QUESTION_CURRENT_UPDATE:
      return { ...state, currentQuestion: action.payload }

    case types.QUESTION_LIST_UPDATE:
      return { ...state, questionsList: action.payload }

    case types.BRANCHING_LOGICS_UPDATE:
      return { ...state, branchingLogics: action.payload }

    default:
      return state
  }
}

export default reducer
