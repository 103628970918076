import { useLocation } from "@engaging-tech/routing"
import React from "react"
import { useSelector } from "react-redux"
import { getHasLoaded } from "../store/survey.selectors"
import { toSurveyRoot } from "../utils/surveyRedirects"

const SurveyGuard = ({ children }) => {
  const hasLoaded = useSelector(getHasLoaded)

  const { pathname } = useLocation()

  if (!hasLoaded) {
    const welcomePageUrl = toSurveyRoot(pathname)

    window.location.href = welcomePageUrl
  }

  return <>{children}</>
}

export default SurveyGuard
