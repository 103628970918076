import { connect } from "react-redux"

import { applyDiscount } from "../../yourProfile/store/yourProfile.actions"
import {
  getIsDiscountCodeLoading,
  getDiscountCodeError
} from "../../yourProfile/store/yourProfile.selectors"

import PaymentMethodFormComponent from "../forms/PaymentMethodForm"

const mapState = state => ({
  isDiscountCodeLoading: getIsDiscountCodeLoading(state),
  discountCodeError: getDiscountCodeError(state)
})

const mapDispatch = dispatch => ({
  onDiscountSubmit: discountCode => dispatch(applyDiscount({ discountCode }))
})

const SubscriptionForm = connect(
  mapState,
  mapDispatch
)(PaymentMethodFormComponent)

export default SubscriptionForm
