import React from "react"

import Loader from "../../features/Notifications/containers/Loader"
import VacancyFilters from "../../features/jobFinder/components/filters"
import Vacancies from "../../features/jobFinder/views/jobsFeed/jobsList"
import TokenAuth from "../../features/misc/components/TokenAuth"
import AppViewBaseLayout from "../../layouts/AppViewBaseLayout"

const Jobs = () => (
  <>
    <TokenAuth />
    <AppViewBaseLayout mx={3} mt={4} banner={VacancyFilters}>
      <Loader />
      <Vacancies />
    </AppViewBaseLayout>
  </>
)

export default Jobs
