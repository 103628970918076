import { connect } from "react-redux"

import StepResultComponent from "../components/StepResult"
import { getQuestionsLength, getStep } from "../store/selectors"

const mapState = (state, { section }) => ({
  step: getStep(state, section),
  numQuestions: getQuestionsLength(state, section)
})

const StepResult = connect(mapState)(StepResultComponent)

export default StepResult
