import { Card, Paragraph } from "@engaging-tech/components"
import React, { useState } from "react"

import Attachment from "../../Attachments"
import BodyWrapper from "../../BodyWrapper"
import CommentWrapper from "../../CommentWrapper/CommentWrapper"
import Interactions from "../../Interactions/Interactions"
import ReadMore from "./ReadMore"

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const Summary = ({
  post,
  interactions: RenderShare,
  userDetails: UserDetails,
  postInteractions: RenderInteractions,
  postComments: RenderComments,
  commentForm: RenderCommentForm
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const isExpandable = post.content.length > 1 && !isExpanded

  const generateAttachments = attachments =>
    attachments.map(attachment => {
      switch (attachment.type) {
        case "image":
          return (
            <Attachment.Thumbnail key={attachment.source} src={attachment.source} alt={attachment.caption} />
          )

        case "video":
          return (
            <Attachment.Video
              key={attachment.source}
              url={attachment.source}
              thumbnail={post.coverImage && post.coverImage.source}
            />
          )

        case "document":
          return <Attachment.File key={attachment.source} url={attachment.source} name={attachment.caption} />

        default:
          return null
      }
    })

  return (
    <Card width={1 / 1} bg="light.0" position="relative" mb={[3, 5, 5]}>
      <BodyWrapper p={3}>
        {UserDetails && (
          <UserDetails
            userHoldingImage={profilePlaceholder}
            interactions={RenderShare}
            publishedAt={post.publishedAt}
            user={post.user}
          />
        )}
        {isExpandable ? (
          <>
            <Paragraph letterSpacing="0.02rem" color="dark.2" fontSize={3}>
              {post.content[0].body}
            </Paragraph>
            <ReadMore onClick={() => setIsExpanded(true)} />
          </>
        ) : (
          post.content.map((paragraph, index) => (
            <Paragraph
              letterSpacing="0.02rem"
              color="dark.2"
              fontSize={3}
              // eslint-disable-next-line react/no-array-index-key
              key={`${paragraph.body}+${index}`}
            >
              {paragraph.body}
            </Paragraph>
          ))
        )}

        {post.attachments && generateAttachments(post.attachments)}
      </BodyWrapper>
      <Interactions>{RenderInteractions && <RenderInteractions />}</Interactions>
      <CommentWrapper>{RenderComments && <RenderComments />}</CommentWrapper>
      <RenderCommentForm />
    </Card>
  )
}

export default Summary
