import React from "react"
import { useSelector } from "react-redux"
import { happinessSurveySelectors } from "../index"
import FailureDialog from "../../survey/components/failureDialog"
import SuccessDialog from "../components/SuccessDialog"
import Submitter from "../containers/Submitter"
import AttachUser from "../containers/AttachUser"

const Submit = ({ onSignUp, onSignIn, onFinish, onSeeResults }) => {
  const hasSubmitted = useSelector(happinessSurveySelectors.getHasSubmitted)
  const hasError = useSelector(happinessSurveySelectors.getHasError)
  const hasAttachedUser = useSelector(
    happinessSurveySelectors.getHasAttachedUser
  )
  const savantaId = useSelector(happinessSurveySelectors.getSavantaId)

  return (
    <>
      <Submitter />
      <AttachUser />
      {hasSubmitted && (
        <SuccessDialog
          onSignUp={onSignUp}
          onSignIn={onSignIn}
          onFinish={onFinish}
          onSeeResults={() => {
            if (hasAttachedUser) {
              onSeeResults()
            }
          }}
          savantaId={savantaId}
        />
      )}
      {hasError && <FailureDialog />}
    </>
  )
}

export default Submit
