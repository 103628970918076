import { Flex, Spinner } from "@engaging-tech/components"
import React, { useEffect } from "react"

import BookmarkToggle from "../../bookmarks/containers/BookmarkToggle"
import LibraryPreview from "../../userGeneratedContent/components/BusinessLibrary/Preview/Preview"
import { libraryPostTypes } from "../../userGeneratedContent/lib/postTypes"
import LoadMore from "../containers/LoadMore"
import NoResults from "./Search/NoResults"

const PostList = ({ posts, type, isLoading, onLoad, noResults }) => {
  useEffect(() => {
    onLoad()
  }, [onLoad])

  if (isLoading) {
    return <Spinner color="primary.0" width={1 / 1} />
  }

  return (
    <Flex flexDirection="column" width={1 / 1}>
      {noResults && (
        <NoResults type={type} isPlural={type !== libraryPostTypes.research} /> // "researchs" is grammatically incorrect
      )}
      {posts.map(post => (
        <LibraryPreview
          post={post}
          key={post.id}
          interactions={() => <BookmarkToggle id={post.id} postType={post.type} />}
        />
      ))}
      <LoadMore postType={type} />
    </Flex>
  )
}

export default PostList
