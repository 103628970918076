import { gql } from "../../../lib/Apollo"

export const LOAD = gql`
  query {
    myBookmarks {
      refId
      refType
      refSubType
      createdAt
      refTarget {
        id
        title
        summary
        type
        categories
        coverImage {
          source
          caption
        }
        content {
          type
          source
          caption
          body
        }
        userId
        seriesId
        user {
          id
          firstName
          lastName
          jobTitle
          companyName
          location {
            name
            country {
              name
              shortName
            }
            countryId
            point {
              coordinates
            }
          }
          picture {
            small
            large
          }
        }
        publishedAt
        createdAt
      }
    }
  }
`
export const LOAD_BY_TYPE = gql`
  query ($refType: String!, $refSubType: String!) {
    myBookmarks(refType: $refType, refSubType: $refSubType) {
      refId
      refType
      refSubType
      createdAt
      refTarget {
        id
        title
        summary
        type
        categories
        coverImage {
          source
          caption
        }
        content {
          type
          source
          caption
          body
        }
        userId
        seriesId
        user {
          id
          firstName
          lastName
          jobTitle
          companyName
          location {
            name
            country {
              name
              shortName
            }
            countryId
            point {
              coordinates
            }
          }
          picture {
            small
            large
          }
        }
        publishedAt
        createdAt
      }
    }
  }
`

export const TOGGLE_BOOKMARK = gql`
  mutation ($isBookmarking: Boolean!, $refId: ID!, $refType: String!, $refSubType: String) {
    bookmark(refId: $refId, refType: $refType, isBookmarking: $isBookmarking, refSubType: $refSubType)
  }
`
