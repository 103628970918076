import React, { useMemo, useState } from "react"
import { Flex } from "@engaging-tech/components"
import TabBar from "../../ui/components/TabBar"
import DisplayConnections from "./DisplayMatches"

const routes = [
  {
    route: "matches",
    text: "Your Matches"
  },
  {
    route: "pending",
    text: "Pending Connections"
  },
  {
    route: "previous",
    text: "Previous Connections"
  }
]

const ConnectionTypeManager = ({
  matches,
  serviceStatus,
  services,
  mentor,
  mentee
}) => {
  const [tabState, setTabState] = useState()

  const { matched, pendingConnection, connected } = useMemo(
    () =>
      matches.reduce(
        (acc, match) => ({
          ...acc,
          [match.status]: [...acc[match.status], match]
        }),
        {
          matched: [],
          pendingConnection: [],
          connected: []
        }
      ),
    [matches]
  )

  return (
    <>
      <TabBar
        routes={routes}
        onChange={setTabState}
        justifyContent="space-between"
      />
      <Flex width={1 / 1} flexDirection="column" p={3}>
        {
          {
            matches: (
              <DisplayConnections
                tabState={tabState}
                matches={matched}
                services={services}
                mentor={mentor}
                mentee={mentee}
              />
            ),
            pending: (
              <DisplayConnections
                tabState={tabState}
                matches={pendingConnection}
                mentor={mentor}
                mentee={mentee}
              />
            ),
            previous: (
              <DisplayConnections tabState={tabState} matches={connected} />
            )
          }[tabState]
        }
      </Flex>
    </>
  )
}

export default ConnectionTypeManager
