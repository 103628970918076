import { useLocation, useRouter } from "@engaging-tech/routing"
import React, { useEffect, useRef, useState } from "react"

import { createUrl } from "../../../../../lib/utils"
import {
  DropdownButton,
  DropdownContainer,
  DropdownItem,
  DropdownList,
  StyledIcon
} from "./selectInputs.styled"

const RadioButtonGroup = ({ options }) => {
  const location = useLocation()
  const dropdownRef = useRef()
  const router = useRouter()

  const optionsArray = options.items.map(item => ({
    key: item.replace(/,/g, "_"),
    value: item
  }))

  const optionSearchParams = new URLSearchParams(location.search)
  const filterValue = optionSearchParams.get(options.id) ?? ""
  const filterValueArray = filterValue?.length ? filterValue?.split(",") : []

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const handleInputChange = option => {
    let newValuesArray = []

    if (filterValueArray.includes(option)) {
      newValuesArray = filterValueArray.filter(item => item !== option)
    } else {
      newValuesArray = filterValueArray?.length ? [filterValueArray, option] : [option]
    }

    if (newValuesArray?.length) {
      optionSearchParams.set(options.id, newValuesArray)
    } else {
      optionSearchParams.delete(options.id)
    }
    const optionUrl = createUrl(location.pathname, optionSearchParams)
    router.navigate(optionUrl)
  }

  const toggleDropdown = () => {
    setIsOpen(prevIsOpen => !prevIsOpen)
  }

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownButton isOpen={isOpen} onClick={toggleDropdown} selected={filterValueArray?.length}>
        {options.label}
        <StyledIcon />
      </DropdownButton>
      {isOpen && (
        <DropdownList>
          {optionsArray.map((item, index) => (
            <DropdownItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              selected={filterValueArray?.includes(item.key)}
              onClick={() => handleInputChange(item.key)}
            >
              {item.value}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  )
}

export default RadioButtonGroup
