import React, { useState } from "react"
import { Link } from "@engaging-tech/routing"
import { Paragraph, MotionBox } from "@engaging-tech/components"
import { AnimatePresence } from "framer-motion"
import styled from "styled-components"
import { getExperienceNameById } from "./lib/filters"

import JobSummary from "./JobSummary"
import Application from "./Application"
import SectionBox from "./SectionBox"
import CheckList from "./CheckList"

const StyledMotionBox = styled(MotionBox)`
  overflow: hidden;
`

const showInnerDetailsVariants = {
  hidden: {
    opacity: 0,
    height: 0,
    transition: {
      duration: 0.3
    }
  },
  visable: {
    opacity: 1,
    height: "auto",
    transition: {
      opacity: {
        delay: 0.3,
        duration: 0.3
      }
    }
  }
}

const ShowDetails = ({
  jobDescription,
  responsibilities,
  requirements,
  skills,
  remote,
  experienceLevel,
  hobbies,
  industryRanking,
  companyScore
}) => (
  <MotionBox width={1}>
    <StyledMotionBox
      width={1}
      variants={showInnerDetailsVariants}
      initial="hidden"
      animate="visable"
      exit="hidden"
    >
      {experienceLevel && (
        <SectionBox
          title="Experience Level"
          text={getExperienceNameById(experienceLevel)}
        />
      )}
      {remote && <SectionBox title="Remote" text="This job is remote." />}
      {jobDescription && (
        <SectionBox title="Job Description" text={jobDescription} />
      )}
      {responsibilities && (
        <SectionBox title="Responsibilities" text={responsibilities} />
      )}
      {requirements && <SectionBox title="Requirements" text={requirements} />}
      {skills && !!skills.length && (
        <CheckList
          title="Skills"
          subTitle="You will need the following skills"
          items={skills}
        />
      )}
      {hobbies && !!hobbies.length && (
        <CheckList
          title="Hobbies"
          subTitle="It is a bonus if you have the following hobbies"
          items={hobbies}
        />
      )}
      {industryRanking && (
        <Paragraph fontWeight={800} color="primary.0" mb={3} px={3} pt={3}>
          <Link to="/find-work/happiest-industries">
            Happiest Industry Ranking: {industryRanking}
          </Link>
        </Paragraph>
      )}
      {companyScore && (
        <Paragraph fontWeight={800} color="primary.0" mb={3} px={3} pt={3}>
          <Link to="/find-work/happiest-companies">
            Happiest Companies Score: {Math.round(companyScore * 100)} %
          </Link>
        </Paragraph>
      )}
    </StyledMotionBox>
  </MotionBox>
)

const JobInfo = ({
  jobDetails,
  withApplication = false,
  children,
  deleteCallback = null,
  isExternal
}) => {
  const [showDetails, setShowDetails] = useState(true)

  if (!jobDetails) return null
  return (
    <>
      {withApplication && (
        <Application email={jobDetails.email} link={jobDetails.link} />
      )}

      <JobSummary
        {...jobDetails}
        deleteCallback={deleteCallback}
        showDetails={showDetails}
        setShowDetails={setShowDetails}
        isExternal={isExternal}
      >
        {children}
      </JobSummary>
      <AnimatePresence>
        {showDetails && <ShowDetails {...jobDetails} />}
      </AnimatePresence>
    </>
  )
}

export default JobInfo
