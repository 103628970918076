import { connect } from "react-redux"
import LanguagesComponent from "../components/Languages"
import { updateYourProfile } from "../store/yourProfile.actions"
import { getLanguages} from "../store/yourProfile.selectors"
import { getAllLanguages } from "../../misc/store/misc.selectors"

const mapState = state => ({
  languages: getLanguages(state),
  allLanguages: getAllLanguages(state)
})

const mapDispatch = dispatch => ({
  onUpdate: params => dispatch(updateYourProfile(params))
})

const Languages = connect(mapState, mapDispatch)(LanguagesComponent)

export default Languages
