import { call, put, takeLatest } from "redux-saga/effects"
import { handleStripeCheckout, load } from "../services/premium.service"
import * as Actions from "./premium.actions"

export function* loadProductsSaga(action) {
  try {
    const res = yield call(load, action.payload)
    yield put(Actions.loadSuccess(res.data.getWorkLProducts))
  } catch {
    yield put(Actions.loadFail())
  }
}

export function* handleStripeCheckoutSaga(action) {
  try {
    const res = yield call(handleStripeCheckout, action.payload)
    yield put(Actions.stripeCheckoutSuccess(res.data.createCheckoutSession))
  } catch {
    yield put(Actions.stripeCheckoutFail())
  }
}

export function* sagas() {
  yield takeLatest(Actions.types.LOAD, loadProductsSaga)
  yield takeLatest(Actions.types.STRIPE_CHECKOUT, handleStripeCheckoutSaga)
}
