import { Flex } from "@engaging-tech/components"
import { usePaths } from "@engaging-tech/routing"
import React from "react"

import JobDetail from "../../features/jobFinder/views/jobDetail"
import TokenAuth from "../../features/misc/components/TokenAuth"
import BackBar from "../../features/ui/components/Backbar"
import AppViewBaseLayout from "../../layouts/AppViewBaseLayout"

const Job = () => {
  const paths = usePaths()
  return (
    <>
      <TokenAuth />
      <AppViewBaseLayout
        mx={3}
        mt={4}
        banner={() => <BackBar to={paths.engagingWorksApp.appJobs} actionName="back" />}
      >
        <Flex justifyContent="center">
          <JobDetail />
        </Flex>
      </AppViewBaseLayout>
    </>
  )
}

export default Job
