import { userInterfaceNotificationsStore } from "@engaging-tech/components"
import { call, put, select, takeLatest } from "redux-saga/effects"

import getGraphQLErrCode from "../../../helpers/getGraphqlErrorCode"
import { jobFinderSelectors } from "../../jobFinder"
import {
  createEventAlert,
  createJobAlert,
  createMaterialAlert,
  deleteAlert,
  loadAlerts,
  updateEventAlert,
  updateJobAlert,
  updateMaterialAlert
} from "../services/notifications.service"
import * as Actions from "./notifications.actions"

export function* loadSaga(action) {
  try {
    const res = yield call(loadAlerts, action.payload)
    yield put(Actions.loadSuccess(res.data.getMyAlerts))
  } catch (err) {
    yield put(Actions.loadFail())
  }
}

export function* createJobAlertSaga(action) {
  const filtersValues = yield select(jobFinderSelectors.getFiltersValues)

  try {
    const res = yield call(createJobAlert, {
      filters: {
        jobRole: filtersValues?.search_term,
        salary: filtersValues?.salary,
        industry: filtersValues?.industry?.length ? filtersValues.industry[0] : undefined,
        location: action.payload?.location
          ? {
              ...action.payload.location
            }
          : undefined,
        jobType: filtersValues?.jobType?.length ? filtersValues.jobType[0] : undefined
      },
      frequency: action.payload.frequency
    })

    yield put(Actions.createJobNotificationSuccess(res.data.createJobAlert))
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Success in creating a Job Alert"
      })
    )
  } catch (e) {
    const code = getGraphQLErrCode(e)
    yield put(Actions.createNotificationFail(code))
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Error in creating a Job Alert"
      })
    )
  }
}

export function* createEventAlertSaga(action) {
  try {
    const res = yield call(createEventAlert, action.payload)
    yield put(Actions.createEventNotificationSuccess(res.data.createEventAlert))
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Success in creating an Event Alert"
      })
    )
  } catch (e) {
    yield put(Actions.createNotificationFail())
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Error in creating an Event Alert"
      })
    )
  }
}

export function* createMaterialAlertSaga(action) {
  try {
    const res = yield call(createMaterialAlert, action.payload)
    yield put(Actions.createMaterialNotificationSuccess(res.data.createMaterialAlert))
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Success in creating a Material Alert"
      })
    )
  } catch (err) {
    if (err?.graphQLErrors?.length && err.graphQLErrors[0]?.extensions?.code === "ERR009") {
      yield put(
        userInterfaceNotificationsStore.actions.addNotification({
          message: "You have already created an alert for this!"
        })
      )
    } else {
      yield put(Actions.createNotificationFail())
      yield put(
        userInterfaceNotificationsStore.actions.addNotification({
          message: "Error in creating a Material Alert"
        })
      )
    }
  }
}

export function* updateJobAlertSaga(action) {
  try {
    const res = yield call(updateJobAlert, action.payload)
    yield put(Actions.updateJobAlertSuccess(res.data.updateJobAlert))
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Success in updating a Job Alert"
      })
    )
  } catch (err) {
    yield put(Actions.updateJobAlertFail())
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Error in updating a Job Alert"
      })
    )
  }
}

export function* updateEventAlertSaga(action) {
  try {
    const res = yield call(updateEventAlert, action.payload)
    yield put(Actions.updateEventAlertSuccess(res.data.updateEventAlert))
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Success in updating an Event Alert"
      })
    )
  } catch (err) {
    yield put(Actions.updateEventAlertFail())
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Error in updating an Event Alert"
      })
    )
  }
}

export function* updateMaterialAlertSaga(action) {
  try {
    const res = yield call(updateMaterialAlert, action.payload)
    yield put(Actions.updateMaterialAlertSuccess(res.data.updateMaterialAlert))
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Success in updating a Material Alert"
      })
    )
  } catch (err) {
    yield put(Actions.updateMaterialAlertFail())
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Error in updating a Material Alert"
      })
    )
  }
}

export function* deleteAlertSaga(action) {
  try {
    const res = yield call(deleteAlert, action.payload.id)
    if (res.data.deleteUserAlert) {
      yield put(Actions.deleteAlertSuccess(action.payload))
    } else {
      yield put(Actions.deleteAlertFail())
    }
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Success in deleting an Alert"
      })
    )
  } catch (err) {
    yield put(Actions.deleteAlertFail())
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Error in deleting an Alert"
      })
    )
  }
}

export function* saga() {
  yield takeLatest(Actions.types.LOAD, loadSaga)
  yield takeLatest(Actions.types.CREATE_JOB_NOTIFICATION, createJobAlertSaga)
  yield takeLatest(Actions.types.CREATE_EVENT_NOTIFICATION, createEventAlertSaga)
  yield takeLatest(Actions.types.CREATE_MATERIAL_NOTIFICATION, createMaterialAlertSaga)
  yield takeLatest(Actions.types.UPDATE_JOB_ALERT, updateJobAlertSaga)
  yield takeLatest(Actions.types.UPDATE_EVENT_ALERT, updateEventAlertSaga)
  yield takeLatest(Actions.types.UPDATE_MATERIAL_ALERT, updateMaterialAlertSaga)
  yield takeLatest(Actions.types.DELETE_ALERT, deleteAlertSaga)
}
