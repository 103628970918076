import { useLocation } from "@engaging-tech/routing"
import React from "react"

import ThankyouView from "../../features/account/views/Thankyou"
import GradientLayout from "../../layouts/GradientLayout"

const Thankyou = () => {
  const { state = {} } = useLocation()
  const { isFromPremium } = state

  return (
    <GradientLayout>
      <ThankyouView redirectTo={state.postThankYouRedirect} isFromPremium={isFromPremium} />
    </GradientLayout>
  )
}

export default Thankyou
