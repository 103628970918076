import { Card, Text } from "@engaging-tech/components"
import React from "react"
import { emailEB } from "../../../lib/globalConstants"

const ErrorCard = () => (
  <Card p={3} bg="light.0">
    <Text>
      We’ve encountered a problem launching your survey, please try the same
      link again in a short while, if you see this message again please let us
      know at{" "}
      <a href={`mailto:${emailEB}`}>
        <Text color="primary.0">{emailEB}</Text>
      </a>
    </Text>
  </Card>
)

export default ErrorCard
