import React from "react"
import { H4, Paragraph, Box } from "@engaging-tech/components"

const SectionBox = ({ title, text }) => (
  <Box px={3} pt={3} mt={2} mb={2}>
    <H4 fontSize={4} color="dark.1" fontWeight="800" mb={2}>
      {title}
    </H4>
    <Paragraph textAlign="left" fontSize={4} color="dark.1" mb={0}>
      {text}
    </Paragraph>
  </Box>
)

export default SectionBox
