import { getConfig } from "@engaging-tech/ssr-config"
import { apollo } from "../../../lib/Apollo"
import { HANDLE_STRIPE_CHECKOUT, LOAD } from "./premium.queries"

const GRAPHQL_API_URL = () =>
  `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

export const load = () =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD
  })

export const handleStripeCheckout = async stripePriceId =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: HANDLE_STRIPE_CHECKOUT,
    variables: { price: stripePriceId, quantity: 1 }
  })
