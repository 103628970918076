import { Box, Select } from "@engaging-tech/components"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { MiscSelectors } from "../../../misc"

const JobRole = ({ onAnswer, initialValue }) => {
  const { t } = useTranslation(["organisation_survey"])

  const [selected, setSelected] = useState(
    (initialValue && initialValue.value[0]) || ""
  )

  const handleSelect = e => {
    setSelected(e.target.value)
    onAnswer(e.target.value)
  }

  const jobRoles = useSelector(MiscSelectors.getSurveyJobRoles)

  return (
    <Box mt={[3, 5]}>
      <Select
        label={t("questions.job", "")}
        defaultValue=""
        value={selected}
        onChange={handleSelect}
      >
        <option disabled value="">
          {t("questions.job_select", "")}
        </option>
        {jobRoles.map(role => (
          <option key={role.id} value={role.id}>
            {role.name}
          </option>
        ))}
      </Select>
    </Box>
  )
}

export default JobRole
