/**
 * The categories of user-generated content that can be present on the business library.
 */
const categories = [
  { code: "KJS", description: "Sales & marketing" },
  { code: "KNSG", description: "Tourism industry" },
  { code: "KJWS", description: "Secretarial, clerical & office skills" },
  { code: "KJ", description: "Business & management" },
  { code: "KJH", description: "Entrepreneurship" },
  { code: "KJVS", description: "Small businesses & self-employed" },
  { code: "VSC", description: "Advice on careers & achieving success" },
  { code: "KJC", description: "Business strategy" },
  { code: "KJMV5", description: "Production & quality control management" },
  { code: "KCA", description: "Economic theory & philosophy" },
  { code: "KCBM", description: "Monetary economics" },
  { code: "KJVB", description: "Takeovers, mergers & buy-outs" },
  { code: "KJF", description: "Business competition" },
  { code: "KJMP", description: "Project management" },
  { code: "KJMV1", description: "Budgeting & financial management" },
  { code: "KCF", description: "Labour economics" },
  { code: "KJM", description: "Management & management techniques" },
  { code: "KJMB", description: "Management: leadership & motivation" },
  { code: "KJMD", description: "Management decision making" },
  { code: "KJP", description: "Business communication & presentation" },
  { code: "JPP", description: "Public administration" },
  { code: "KNV", description: "Civil service & public sector" },
  { code: "KNSX", description: "Fashion & beauty industries" },
  { code: "KJT", description: "Operational research" },
  { code: "KNDF", description: "Food manufacturing & related industries" },
  { code: "KJSU", description: "Customer services" },
  { code: "KJMV8", description: "Purchasing & supply management" },
  { code: "KJMV7", description: "Sales & marketing management" },
  { code: "KJSA", description: "Advertising" },
  { code: "KJU", description: "Organizational theory & behaviour" },
  {
    code: "KJMQ",
    description: "Quality Assurance (QA) & Total Quality Management (TQM)"
  },
  { code: "KJMV2", description: "Personnel & human resources management" },
  { code: "KFFH", description: "Corporate finance" },
  { code: "KFFM", description: "Investment & securities" },
  { code: "KJB", description: "Business studies: general" },
  { code: "BGB", description: "Biography: business & industry" },
  { code: "KJD", description: "Business innovation" },
  { code: "KJG", description: "Business ethics & social responsibility" },
  { code: "KFFN", description: "Insurance & actuarial studies" },
  { code: "KC", description: "Economics" },
  { code: "KJV", description: "Ownership & organization of enterprises" },
  { code: "KJSM", description: "Market research" },
  {
    code: "KJMV4",
    description: "Management of real estate, property & plant"
  },
  { code: "KJK", description: "International business" },
  { code: "KJMV9", description: "Distribution & warehousing management" },
  { code: "KJN", description: "Business negotiation" },
  { code: "AK", description: "Industrial / commercial art & design" },
  { code: "JNRV", description: "Industrial or vocational training" },
  { code: "KCL", description: "International economics" },
  { code: "KJMV6", description: "Research & development management" },
  { code: "KJWF", description: "Office systems & equipment" },
  { code: "KFFR", description: "Property & real estate" },
  { code: "KFCM", description: "Management accounting & bookkeeping" },
  { code: "KJMV", description: "Management of specific areas" },
  { code: "KJVG", description: "Multinationals" },
  { code: "KJWX", description: "Working patterns & practices" },
  { code: "VSP", description: "Popular psychology" },
  { code: "KFF", description: "Finance" },
  { code: "KJVX", description: "Non-profitmaking organizations" },
  { code: "BGBA", description: "Autobiography: business & industry" },
  { code: "KCC", description: "Microeconomics" },
  { code: "KCS", description: "Economic systems & structures" },
  { code: "KCZ", description: "Economic history" },
  {
    code: "KNT",
    description: "Media, information & communication industries"
  },
  { code: "KCJ", description: "Economic forecasting" },
  { code: "KNDH", description: "Hi-tech manufacturing industries" },
  { code: "KJQ", description: "Business mathematics & systems" },
  { code: "KFFK", description: "Banking" },
  { code: "KJSP", description: "Public relations" },
  { code: "KND", description: "Manufacturing industries" },
  { code: "KJMT", description: "Time management" },
  { code: "KCP", description: "Political economy" },
  { code: "AKC", description: "Graphic design" },
  { code: "KJL", description: "Consultancy & grants for businesses" },
  { code: "KJE", description: "E-commerce: business aspects" },
  {
    code: "KJZ",
    description: "History of specific companies / corporate history"
  },
  { code: "KJW", description: "Office & workplace" },
  { code: "KJWB", description: "Office management" },
  { code: "KNDC", description: "Chemical industries" },
  { code: "KJR", description: "Corporate governance" },
  { code: "KNXB", description: "Industrial relations" },
  { code: "KN", description: "Industry & industrial studies" },
  { code: "JPB", description: "Comparative politics" },
  { code: "JPQB", description: "Central government policies" },
  { code: "KNAT", description: "Mining industry" },
  { code: "KCLT", description: "International trade" },
  { code: "KNDR", description: "Road vehicle manufacturing industry" },
  { code: "KJMV3", description: "Knowledge management" },
  { code: "KNDP", description: "Pharmaceutical industries" },
  { code: "KNJC", description: "Construction industry" },
  { code: "KNTX", description: "Information technology industries" },
  { code: "VSB", description: "Personal finance" },
  { code: "KFCF", description: "Financial accounting" },
  {
    code: "KFCR",
    description: "Financial reporting, financial statements"
  },
  { code: "VSPM", description: "Assertiveness, motivation & self-esteem" },
  { code: "KJVD", description: "Privatization" },
  {
    code: "KJBX",
    description: "Business & management: study & revision guides"
  },
  { code: "KNTD", description: "Radio & television industry" },
  { code: "KFC", description: "Accounting" },
  { code: "JPWH", description: "Non-governmental organizations (NGOs)" },
  { code: "KCG", description: "Economic growth" },
  {
    code: "KCM",
    description: "Development economics & emerging economies"
  },
  { code: "LNDC2", description: "Privacy law" },
  { code: "LNJX", description: "Advertising, marketing & sponsorship law" },
  { code: "LNR", description: "Intellectual property law" },
  { code: "KNXC", description: "Health & safety issues" },
  { code: "KCX", description: "Economic & financial crises & disasters" },
  { code: "KNTY", description: "Advertising industry" },
  { code: "KCHS", description: "Economic statistics" },
  { code: "KJVW", description: "Employee-ownership & co-operatives" },
  { code: "JP", description: "Politics & government" },
  { code: "KCB", description: "Macroeconomics" },
  { code: "AKTA", description: "Fashion design & theory" },
  { code: "LNHD", description: "Discrimination in employment law" },
  { code: "KFFM2", description: "Stocks & shares" },
  { code: "KNGS", description: "Shipping industries" },
  { code: "KF", description: "Finance & accounting" },
  { code: "LNC", description: "Company, commercial & competition law" },
  {
    code: "KJRD",
    description: "Boards & directors: role & responsibilities"
  },
  { code: "AKP", description: "Product design" },
  { code: "KNGV", description: "Aerospace & air transport industries" },
  { code: "KNSJ", description: "Events management industries" },
  {
    code: "KJJ",
    description: "Business & the environment, ‘Green’ approaches to business"
  },
  { code: "KNSP", description: "Sport & leisure industries" },
  { code: "AKL", description: "Illustration & commercial art" },
  { code: "KCU", description: "Urban economics" },
  { code: "JPA", description: "Political science & theory" },
  { code: "VSK", description: "Advice on education" },
  { code: "KNAC", description: "Agriculture & related industries" },
  { code: "JPZ", description: "Political corruption" },
  { code: "KNGV1", description: "Airports" },
  { code: "LNFX5", description: "Police law & police procedures" },
  { code: "KJVN", description: "Public ownership / nationalization" },
  { code: "LAT", description: "Legal profession: general" },
  { code: "JPSL", description: "Geopolitics" },
  { code: "KCN", description: "Environmental economics" },
  { code: "KNTX1", description: "Internet & WWW industries" },
  { code: "KNS", description: "Service industries" },
  { code: "KNPR", description: "Retail sector" },
  { code: "LAY", description: "Law as it applies to other professions" },
  { code: "LNH", description: "Employment & labour law" },
  { code: "KNSH", description: "Hospitality industry" },
  { code: "KNST", description: "Financial services industry" },
  { code: "KNSS", description: "Security services" },
  { code: "KFFM1", description: "Commodities" },
  { code: "KFFD1", description: "Taxation" },
  { code: "LNUC", description: "Corporate tax" },
  { code: "KNTP", description: "Publishing industry & book trade" },
  { code: "VSPT", description: "Memory improvement & thinking techniques" },
  { code: "KNDM", description: "Armaments industries" },
  { code: "LAM", description: "Comparative law" },
  { code: "LBBM", description: "International economic & trade law" },
  { code: "LNCH", description: "Competition law / Antitrust law" },
  { code: "LNRD", description: "Patents law" },
  { code: "KCD", description: "Economics of industrial organisation" },
  { code: "VS", description: "Self-help & personal development" },
  { code: "KJVT", description: "Outsourcing" },
  { code: "KCH", description: "Econometrics" },
  { code: "KNDD", description: "Textile industries" },
  { code: "KFFL", description: "Credit & credit institutions" },
  { code: "KFFD", description: "Public finance" },
  { code: "KNTJ", description: "Press & journalism" },
  { code: "LNF", description: "Criminal law & procedure" },
  { code: "LNAA", description: "Courts & procedure" },
  {
    code: "VSD",
    description: "Law, citizenship & rights for the lay person"
  },
  { code: "JNR", description: "Careers guidance" },
  { code: "LNU", description: "Taxation & duties law" },
  { code: "LNCB", description: "Commercial law" },
  { code: "KCLF", description: "International finance" },
  { code: "LBB", description: "Public international law" },
  { code: "KNP", description: "Distributive industries" },
  { code: "K", description: "Economics, finance, business & management" },
  { code: "KCY", description: "Popular economics" },
  { code: "JPHC", description: "Constitution: government & the state" },
  { code: "LNJ", description: "Entertainment & media law" },
  { code: "LNRC", description: "Copyright law" },
  { code: "JPS", description: "International relations" },
  { code: "LNTH", description: "Social security & welfare law" },
  { code: "KCK", description: "Behavioural economics" },
  { code: "KFCX", description: "Accounting: study & revision guides" },
  { code: "KNBN", description: "Nuclear power industries" },
  { code: "KNTT", description: "Postal & telecommunications industries" },
  { code: "LNCD", description: "Company law" },
  { code: "KJVV", description: "Joint ventures" },
  { code: "VSW", description: "Living & working abroad" },
  { code: "JPHL", description: "Political leaders & leadership" },
  {
    code: "VSH",
    description:
      "Housing & property for the individual - buying/selling & legal aspects"
  },
  {
    code: "LNAC5",
    description: "Arbitration, mediation & alternative dispute resolution"
  },
  { code: "LAFS", description: "Islamic law" },
  { code: "LNFN", description: "Fraud" },
  { code: "JPQ", description: "Central government" },
  { code: "KNTF", description: "Music industry" },
  { code: "JPWL", description: "Terrorism, armed struggle" },
  { code: "LNCB2", description: "E-commerce law" },
  { code: "LNTU", description: "Consumer protection law" },
  { code: "LNQD", description: "Data protection law" },
  { code: "KJVF", description: "Franchises" },
  { code: "KNTC", description: "Cinema industry" },
  { code: "JPWQ", description: "Revolutionary groups & movements" },
  {
    code: "KJRS",
    description: "Company secretary: role & responsibilities"
  },
  { code: "LNTM", description: "Medical & healthcare law" },
  { code: "KFCP", description: "Public finance accounting" },
  { code: "KNDH1", description: "Biotechnology industries" },
  { code: "AKTH", description: "History of fashion" },
  { code: "VSZ", description: "Green lifestyle & self-sufficiency" },
  { code: "KNG", description: "Transport industries" },
  { code: "KNGR", description: "Road transport industries" },
  { code: "KNGT", description: "Railway transport industries" },
  { code: "JPH", description: "Political structure & processes" },
  { code: "LBDK", description: "Transnational commercial law" },
  {
    code: "KFFX",
    description: "Banking & finance: study & revision guides"
  },
  { code: "KCQ", description: "Health economics" },
  { code: "LAF", description: "Systems of law" },
  { code: "LBBR", description: "International human rights law" },
  { code: "LNKJ", description: "Environment law" },
  { code: "KFCC", description: "Cost accounting" },
  { code: "JPHV", description: "Political structures: democracy" },
  { code: "L", description: "Law" },
  { code: "LA", description: "Jurisprudence & general issues" },
  { code: "KCFM", description: "Employment & unemployment" },
  { code: "JPVH", description: "Human rights" },
  { code: "AKT", description: "Fashion & textiles: design" },
  { code: "KJVP", description: "Monopolies" },
  { code: "KNBC", description: "Coal & solid fuel industries" },
  { code: "KNJH", description: "Iron, steel & metals industries" },
  { code: "KNXB2", description: "Trade unions" },
  {
    code: "KNBT",
    description: "Alternative & renewable energy industries"
  },
  { code: "LNT", description: "Social law" },
  { code: "LAQ", description: "Law & society" },
  { code: "LNJS", description: "Sport & the law" },
  { code: "LBBZ", description: "International criminal law" },
  { code: "LBBM3", description: "Investment treaties & disputes" },
  {
    code: "LBBU",
    description: "International organisations & institutions"
  },
  { code: "KCT", description: "Agricultural economics" },
  {
    code: "KNTR",
    description: "Printing, packaging & reprographic industry"
  },
  { code: "LB", description: "International law" },
  { code: "LNS", description: "Property law" },
  { code: "LNPC", description: "Bankruptcy & insolvency" },
  { code: "LNCD1", description: "Mergers & acquisitions law" },
  { code: "LNCB6", description: "Aviation law" },
  { code: "JPV", description: "Political control & freedoms" },
  { code: "KCLT1", description: "Trade agreements" },
  { code: "KNBP", description: "Petroleum & oil industries" },
  { code: "LND", description: "Constitutional & administrative law" },
  { code: "LNDC", description: "Human rights & civil liberties law" },
  { code: "JPSD", description: "Diplomacy" },
  {
    code: "LBG",
    description: "Private international law & conflict of laws"
  },
  { code: "KNB", description: "Energy industries & utilities" },
  { code: "LNP", description: "Financial law" },
  { code: "LAS", description: "Legal skills & practice" },
  { code: "LNPB", description: "Banking law" },
  { code: "JPVL", description: "Political campaigning & advertising" },
  { code: "LNCJ", description: "Contract law" },
  { code: "LNHH", description: "Occupational health & safety law" },
  { code: "KNBG", description: "Gas industries" },
  { code: "KNA", description: "Primary industries" },
  {
    code: "JPFF",
    description: "Socialism & left-of-centre democratic ideologies"
  },
  { code: "LAR", description: "Criminology: legal aspects" },
  { code: "LNRL", description: "Designs law" },
  { code: "JPVH1", description: "Civil rights & citizenship" },
  { code: "JPSN2", description: "EU & European institutions" },
  { code: "JPWD", description: "Pressure groups & lobbying" },
  { code: "LNCB3", description: "Sale of goods law" },
  { code: "KFFP", description: "Pensions" },
  { code: "KCCD", description: "Domestic trade" },
  { code: "KNX", description: "Industrial relations, health & safety" },
  { code: "KNDV", description: "Aviation manufacturing industry" },
  { code: "VSPX", description: "Neuro Linguistic Programming (NLP)" },
  { code: "AKB", description: "Individual designers" },
  { code: "KCR", description: "Welfare economics" },
  { code: "LNCQ", description: "Construction & engineering law" },
  { code: "LBDM", description: "International maritime law" },
  { code: "LNPF", description: "Financial services law & regulation" },
  { code: "LNFX1", description: "Sentencing & punishment" },
  {
    code: "LNTM2",
    description: "Regulation of medicines & medical devices"
  },
  { code: "LAQG", description: "Gender & the law" },
  { code: "KNJ", description: "Construction & heavy industry" },
  { code: "LNTJ", description: "Public health & safety law" },
  { code: "LAZ", description: "Legal history" },
  { code: "VSG", description: "Consumer advice" },
  { code: "LBBP", description: "International environmental law" },
  { code: "LNCR", description: "Energy & natural resources law" },
  { code: "LATC", description: "Legal ethics & professional conduct" },
  { code: "LNPP", description: "Pensions law" },
  { code: "JPSN1", description: "United Nations & UN agencies" },
  { code: "JPR", description: "Regional government" },
  { code: "LNQ", description: "IT & Communications law" },
  { code: "LBBK", description: "Law of the sea" },
  {
    code: "JPFM",
    description: "Conservatism & right-of-centre democratic ideologies"
  },
  { code: "LNHU", description: "Employment contracts" },
  { code: "LNTX", description: "Licensing, gaming & club law" },
  { code: "JPW", description: "Political activism" },
  { code: "LNCB5", description: "Shipping law" },
  { code: "JPFC", description: "Marxism & Communism" },
  { code: "LAFR", description: "Roman law" },
  { code: "VSR", description: "Retirement" },
  {
    code: "JPFK",
    description: "Liberalism & centre democratic ideologies"
  },
  { code: "AKR", description: "Furniture design" },
  { code: "LAB", description: "Jurisprudence & philosophy of law" },
  { code: "AKLC", description: "Comic book & cartoon art" },
  { code: "AKD", description: "Typography & lettering" },
  { code: "LN", description: "Laws of Specific jurisdictions" },
  { code: "AKLB", description: "Illustration" },
  { code: "VSF", description: "Roadcraft, driving & the Highway Code" },
  {
    code: "LBBC",
    description: "Treaties & other sources of international law"
  },
  { code: "LBBS", description: "International humanitarian law" },
  { code: "LNKT", description: "Transport law" },
  { code: "LNZL", description: "Legislation" },
  { code: "AKTX", description: "Textile design & theory" },
  { code: "JPVN", description: "Propaganda" },
  { code: "LNL", description: "Equity & trusts" },
  { code: "LNFJ", description: "Offences against the person" },
  { code: "KNAF", description: "Fisheries & related industries" },
  { code: "AKH", description: "Book design" },
  { code: "AKLC1", description: "Graphic novel & Manga artwork" },
  {
    code: "LBD",
    description: "International law of transport, communications & commerce"
  },
  { code: "LNA", description: "Legal system: general" },
  { code: "LNM", description: "Family law" },
  { code: "LNDH", description: "Government powers" },
  { code: "LNAC12", description: "Restitution" },
  { code: "JPF", description: "Political ideologies" },
  { code: "KNBW", description: "Water industries" },
  { code: "JPWF", description: "Demonstrations & protest movements" },
  { code: "LBH", description: "Settlement of international disputes" },
  { code: "LBL", description: "International law reports" },
  { code: "LR", description: "Law: study & revision guides" },
  { code: "LNV", description: "Torts / Delicts" },
  { code: "LNDA", description: "Citizenship & nationality law" },
  { code: "JPSN", description: "International institutions" },
  { code: "LNTM1", description: "Mental health law" },
  { code: "LNFB", description: "Criminal justice law" },
  {
    code: "LNFR",
    description: "Offences against public health, safety, order"
  },
  { code: "LNKG", description: "Animal law" },
  { code: "LNTQ", description: "Disability & the law" },
  { code: "KNSS1", description: "Surveillance services" },
  { code: "AKLP", description: "Poster art" },
  { code: "LNSH", description: "Land & real estate law" },
  { code: "LNZ", description: "Primary sources of law" },
  { code: "JPSF", description: "Arms negotiation & control" },
  { code: "LNTD", description: "Education & the law" },
  { code: "KNXB1", description: "Strikes" },
  { code: "VSL", description: "Adult literacy guides & handbooks" },
  { code: "VSN", description: "Adult numeracy guides & handbooks" },
  { code: "LASD", description: "Advocacy" },
  { code: "LNPN", description: "Insurance law" },
  { code: "LNB", description: "Private / Civil law: general works" },
  { code: "LNDC4", description: "Freedom of expression law" },
  { code: "LBHG", description: "International courts & procedures" },
  { code: "LNTC", description: "Charity law" },
  { code: "LBBD", description: "Diplomatic law" },
  {
    code: "LNAC",
    description: "Civil procedure, litigation & dispute resolution"
  },
  { code: "LNVJ", description: "Personal injury" },
  { code: "LNFT", description: "Road traffic law, motoring offences" },
  { code: "LNUT", description: "Trusts & estates taxation" },
  { code: "LNW", description: "Wills & probate / Succession" },
  { code: "LNSH9", description: "Housing law" },
  { code: "JPWS", description: "Armed conflict" },
  { code: "JPVH4", description: "Religious freedom / freedom of worship" },
  { code: "LNZC", description: "Case law" },
  { code: "LAFD", description: "Civil codes / Civil law" },
  { code: "LBHT", description: "International arbitration" },
  { code: "LAFX", description: "Ecclesiastical (canon) law" },
  { code: "JPVH3", description: "Land rights" },
  { code: "LNFX", description: "Criminal procedure" },
  {
    code: "LNPD",
    description: "Capital markets & securities law & regulation"
  },
  { code: "LAFC", description: "Common law" },
  { code: "LNDA1", description: "Immigration law" },
  { code: "LNJD", description: "Defamation law (slander & libel)" },
  { code: "LNMB", description: "Family law: marriage & divorce" },
  { code: "LBBF", description: "Jurisdiction & immunities" },
  {
    code: "LBBJ",
    description: "International law of territory & statehood"
  },
  { code: "KNSZ", description: "Funeral services" },
  { code: "LNAL", description: "Regulation of legal profession" },
  { code: "JPRB", description: "Regional government policies" },
  { code: "LNUP", description: "Personal tax" },
  { code: "LNMK", description: "Family law: children" },
  { code: "JPVR", description: "Political oppression & persecution" },
  { code: "KNAL", description: "Forestry & related industries" },
  { code: "LASP", description: "Paralegals & paralegalism" },
  { code: "LNSH1", description: "Ownership & mortgage law" },
  { code: "LNK", description: "Environment, transport & planning law" },
  { code: "LNKN", description: "Nature Conservation law" },
  { code: "LNFV", description: "Terrorism law" },
  { code: "LNCL", description: "Agency law" },
  { code: "KNDS", description: "Shipbuilding industry" },
  { code: "LNCB1", description: "Franchising law" },
  { code: "LNMF", description: "Family law: same-sex partnership" },
  { code: "LNCN", description: "Procurement law" },
  { code: "LNCF", description: "Partnership law" },
  { code: "LNFQ", description: "Juvenile criminal law" },
  { code: "LNKF", description: "Agricultural law" },
  { code: "LNDK", description: "Military & defence law" },
  { code: "LNRV", description: "Confidential information law" },
  { code: "LNDP", description: "Parliamentary & legislative practice" },
  { code: "LNDU", description: "Local government law" },
  { code: "LNFX3", description: "Criminal procedure: law of evidence" },
  { code: "LNAA1", description: "Judicial powers" },
  { code: "LNCB4", description: "Outsourcing law" },
  { code: "LNSH3", description: "Landlord & tenant law" },
  { code: "JPHF", description: "Elections & referenda" },
  { code: "LNDM", description: "Judicial review" },
  {
    code: "LBBV",
    description: "Responsibility of states & other entities"
  },
  { code: "JPL", description: "Political parties" },
  { code: "LNRF", description: "Trademarks law" },
  { code: "LNSH5", description: "Conveyancing law" },
  { code: "LNVC", description: "Negligence" },
  { code: "LNAC3", description: "Civil procedure: law of evidence" },
  { code: "LNDA3", description: "Asylum law" },
  { code: "LNDS", description: "Election law" },
  { code: "LNTS", description: "Law & the elderly" },
  { code: "JPVK", description: "Public opinion & polls" },
  { code: "KNDF1", description: "Tobacco industry" },
  { code: "LNDF", description: "Freedom of information law" },
  { code: "JPSH", description: "Espionage & secret services" },
  { code: "KNXB3", description: "Industrial arbitration & negotiation" },
  {
    code: "JPVH2",
    description: "Freedom of information & freedom of speech"
  },
  { code: "JPFN", description: "Nationalism" },
  { code: "LNPA", description: "Accounting law" },
  { code: "LNAC14", description: "Damages & compensation" },
  { code: "LNFL", description: "Offences against property" },
  { code: "LNKW", description: "Planning law" },
  {
    code: "JPHX",
    description: "Political structures: totalitarianism & dictatorship"
  },
  { code: "JPWJ", description: "Political subversion" },
  { code: "KNBL", description: "Electrical power industries" },
  { code: "LBDA", description: "International space & aerospace law" },
  { code: "LNSH7", description: "Rating & valuation law" },
  { code: "LNAF", description: "Legal system: costs & funding" },
  { code: "LNUS", description: "Sales tax  & Customs duties" },
  { code: "JPFQ", description: "Fascism & Nazism" },
  { code: "LNHR", description: "Industrial relations & trade unions law" },
  { code: "LNAC16", description: "Injunctions & other orders" },
  { code: "LNSP", description: "Personal property law" },
  { code: "LNFG", description: "Offences against the government" },
  { code: "JPFB", description: "Anarchism" },
  { code: "LBBC1", description: "Customary law" },
  { code: "LNAC1", description: "Civil remedies" },
  { code: "LNMC", description: "Family law: cohabitation" },
  { code: "JPFR", description: "Religious & theocratic ideologies" },
  {
    code: "LBDT",
    description: "International communications & telecommunications law"
  },
  { code: "LNKV", description: "Highways" },
  { code: "JPLM", description: "Political manifestos" },
  { code: "JPWL1", description: "Political assassinations" },
  { code: "JPWL2", description: "Terrorist attack" }
]

export default categories.sort(
  (a, b) => a.description.toLowerCase() > b.description.toLowerCase()
)
