import { connect } from "react-redux"

import { happinessSurveyActions } from ".."
import { rankingsActions, rankingsSelectors } from "../../rankings"
import CompanyRankingDialog from "../components/CompanyRankingDialog"
import { getCompanyName, getSavantaId } from "../store/happinessSurvey.selectors"

const mapState = state => ({
  savantaId: getSavantaId(state),
  companyName: getCompanyName(state),
  companies: rankingsSelectors.getSurveyCompanies(state),
  isLoading: rankingsSelectors.getIsLoadingSurveyCompanies(state)
})

const mapDispatch = dispatch => ({
  onSubmitCompany: (companyId, companyName) => {
    dispatch(happinessSurveyActions.setCompanyName(companyName))
    dispatch(happinessSurveyActions.setCompanyId(companyId))
  },
  getSurveyCompanies: searchTerm => dispatch(rankingsActions.getSurveyCompanies(searchTerm))
})

const RankYourCompany = connect(mapState, mapDispatch)(CompanyRankingDialog)

export default RankYourCompany
