import React from "react"
import styled from "styled-components"
import { Flex, Text } from "@engaging-tech/components"
import { useTranslation } from "react-i18next"

const BorderedFlex = styled(Flex)`
  div {
    border-left: 2px solid ${({ theme }) => theme.colors.dark[6]};
    span {
      margin: 0 8px;
    }
  }
  div:first-child {
    border: none;
  }
`

const StatisticBox = ({ comparedToIndustry, happinessChange }) => {
  const { t } = useTranslation(["results"])
  const getIndustryComparisonText = score =>
    `${score.toFixed(0)}% ${
      score >= 0
        ? `${t("happiness_data_highlight.statistic_box.above", "")}`
        : `${t("happiness_data_highlight.statistic_box.below", "")}`
    }`
  return (
    <BorderedFlex
      width={[1 / 1, 9 / 10]}
      height={80}
      bg="dark.7"
      mb={4}
      alignItems="center"
      justifyContent="space-evenly"
    >
      <Flex
        width={1 / 2}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Flex width={[1/1, 180]} flexDirection="column">
          <Text color="dark.2" width="auto" fontSize={2} pb={1}>
            {t("happiness_data_highlight.statistic_box.change", "")}
          </Text>
          <Text width="auto">
            {happinessChange === "-"
              ? t("happiness_data_highlight.statistic_box.no_data", "")
              : happinessChange}
          </Text>
        </Flex>
      </Flex>
      <Flex
        width={1 / 2}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Flex width={[1/1, 150]} flexDirection="column">
          <Text color="dark.2" width="auto" fontSize={2} pb={1}>
            {t("happiness_data_highlight.statistic_box.compare", "")}
          </Text>

          <Text width="auto">
            {getIndustryComparisonText(comparedToIndustry)}
          </Text>
        </Flex>
      </Flex>
    </BorderedFlex>
  )
}

export default StatisticBox
