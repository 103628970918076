import { Box } from "@engaging-tech/components"
import React from "react"
import { useSelector } from "react-redux"

import CompanyInformationButton from "../../../../components/companyInformationButton"
import HappyAtWorkTest from "../../../../components/jobRankings/happyAtWorkTest"
import Kpis from "../../../../components/jobRankings/kpis"
import { getJobRanking } from "../../../../store/jobFinder.selectors"
import { JobSubtitle } from "../jobDetail.styled"
import {
  Body,
  JobDescriptionContainer,
  JobDescriptionContent,
  JobDescriptionWrapper
} from "./jobDetailBody.styled"

const JobDetailBody = ({ job }) => {
  const jobRanking = useSelector(getJobRanking(job.company_name_key))

  return (
    <Body>
      <JobDescriptionWrapper>
        <JobSubtitle>About this job</JobSubtitle>

        <JobDescriptionContainer>
          <JobDescriptionContent width={1 / 1} dangerouslySetInnerHTML={{ __html: job.snippet }} />
        </JobDescriptionContainer>
      </JobDescriptionWrapper>

      <JobSubtitle mt={40}>{job.company}</JobSubtitle>

      <Box mt={16}>
        <Kpis companyScore={jobRanking?.score} />
        {jobRanking ? (
          <CompanyInformationButton companyNameKey={job.company_name_key} />
        ) : (
          <HappyAtWorkTest />
        )}
      </Box>
    </Body>
  )
}

export default JobDetailBody
