import React from "react"
import styled from "styled-components"
import { Box } from "@engaging-tech/components"

import PublishContent from "./PublishContent"
import BusinessServices from "./BusinessServices"

const Wrapper = styled(Box)`
  & > div {
    margin-bottom: ${({ theme }) => theme.space[3]}px;
  }
`

const SideBar = () => (
  <Wrapper width={1 / 1}>
    <PublishContent />
    <BusinessServices />
  </Wrapper>
)

export default SideBar
