import React from "react"
import styled from "styled-components"
import { Box, Flex, Paragraph } from "@engaging-tech/components"
import { CircularProgress } from "@engaging-tech/charts"

const icon = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/dropdown-arrow.svg`)

const Select = styled.select`
  border: none;
  appearance: none;
  width: 100%;
  padding: ${({ theme }) => theme.space[3]}px 48px
    ${({ theme }) => theme.space[3]}px ${({ theme }) => theme.space[3]}px;
  background-image: url(${icon});
  background-position: center right ${({ theme }) => theme.space[3]}px;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.colors.dark[7]};
  font-size: ${({ theme }) => theme.fontSizes[4]}px;
  color: ${({ theme }) => theme.colors.dark[2]};
  border-radius: 4px 4px 0 0;
  text-overflow: ellipsis;
  margin: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}px) {
    font-size: ${({ theme }) => theme.fontSizes[3]}px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.dark[5]};
  }
`

const DropdownCircularProgress = ({
  type,
  onChange,
  items,
  selectedItem,
  defaultValue
}) => (
  <Box bg="light.0" borderRadius={4} width={1 / 1}>
    <Select
      key={items[0]}
      onChange={e => onChange(e.target.value)}
      id={`dropdown-${type}`}
      defaultValue={items[0] && items[0].id}
    >
      {items.map(item => (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      ))}
    </Select>
    <Flex
      alignItems="center"
      justifyContent="center"
      p={2}
      borderRadius={4}
      elevation={2}
      bg="light.0"
    >
      <Flex alignItems="center" mx={3}>
        <Paragraph textAlign="left" fontSize={3} color="dark.1">
          {selectedItem === null ? defaultValue : selectedItem.name}
        </Paragraph>
        {items[0] && (
          <CircularProgress
            width="65px"
            height="65px"
            color="primary.1"
            percentage={
              selectedItem
                ? selectedItem.overall || 65
                : items[0] && items[0].overall
            }
            strokeColor="#00BB88"
            thickness={0.5}
          />
        )}
      </Flex>
    </Flex>
  </Box>
)

export default DropdownCircularProgress
