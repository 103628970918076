import { createSelector } from "reselect"
import { surveyResultsSelectors } from "../.."
import { developCareerSelectors } from "../../../developCareer"
import {
  mappedHappinessSixSteps,
  pipePassedInProp
} from "../../HappinessSixSteps/store/selectors"

export const getHappinessScoreChange = createSelector(
  [
    developCareerSelectors.getIndividualSurveySubmissions,
    (state, submissionId) => submissionId
  ],
  (surveys, submissionId) => {
    const foundSurveyIndex = surveys.findIndex(
      survey => survey.id === submissionId
    )

    if (surveys.length <= 1 || foundSurveyIndex === -1) {
      return "-"
    }

    const percentageDifference =
      surveys[foundSurveyIndex].score * 100 -
      surveys[foundSurveyIndex + 1].score * 100

    const trendDirection = percentageDifference > 0 ? "+" : ""
    return `${trendDirection}${Math.round(percentageDifference)}%`
  }
)

export const getCompletedAt = createSelector(
  surveyResultsSelectors.getResponseResults,
  response => response?.completedAt
)

export const getStep = createSelector(
  mappedHappinessSixSteps,
  pipePassedInProp,
  (sixSteps, currentStep) =>
    sixSteps && sixSteps.find(step => step.name === currentStep)
)
