import { getConfig } from "@engaging-tech/ssr-config"
import { apollo } from "../../../lib/Apollo"
import {
  CREATE_EXPERT_ADVICE,
  LOAD_REGIONS,
  LOAD_JOB_ROLES
} from "./advice.queries"

const GRAPHQL_API_URL = () =>
  `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

/**
 * Creates a new expert advice question.
 * @param {object} newAdvice The new expert advice question to create.
 */
export const createAdvice = newAdvice =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: CREATE_EXPERT_ADVICE,
    variables: newAdvice
  })

export const loadRegions = countryId =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: LOAD_REGIONS,
    variables: { countryId }
  })

export const loadJobRoles = text =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: LOAD_JOB_ROLES,
    variables: { text }
  })
