/**
 * A list of potential job types that a vacancy can be assigned to.
 * This list is used for client-side filtering of job vacancies.
 */
const jobTypes = [
  {
    id: "fullTime",
    name: "Full-time"
  },
  {
    id: "partTime",
    name: "Part-time"
  },
  {
    id: "internships",
    name: "Internship"
  },
  {
    id: "volunteer",
    name: "Volunteer"
  },
  {
    id: "graduateSchemes",
    name: "Graduate Scheme"
  },
  {
    id: "placements",
    name: "Placement"
  },
  {
    id: "apprenticeship",
    name: "Apprenticeship"
  }
]

/**
 * A list of potential industries vacancies can be assigned to. This
 * list is used for client-side filtering of job vacancies.
 */
const industries = [
  {
    id: "58e642e5d26bfe62923671e8",
    name: "Agriculture, forestry and fishing"
  },
  { id: "58e642e5d26bfe62923671e9", name: "Architecture and Engineering" },
  {
    id: "58e642e5d26bfe62923671ea",
    name: "Construction and Building Materials"
  },
  { id: "58e642e5d26bfe62923671eb", name: "Education" },
  { id: "58e642e5d26bfe62923671ec", name: "Energy" },
  { id: "58e642e5d26bfe62923671ed", name: "Entertainment and Media" },
  { id: "58e642e5d26bfe62923671ee", name: "Financial services" },
  { id: "58e642e5d26bfe62923671ef", name: "Healthcare" },
  { id: "58e642e5d26bfe62923671f0", name: "Travel and Leisure" },
  { id: "58e642e5d26bfe62923671f1", name: "Automotive and engineering" },
  { id: "58e642e5d26bfe62923671f3", name: "Legal Services" },
  { id: "58e642e5d26bfe62923671f4", name: "Marketing and Advertising" },
  { id: "58e642e5d26bfe62923671f5", name: "Non-profit Organisation" },
  { id: "58e642e5d26bfe62923671f6", name: "Public Sector" },
  { id: "58e642e5d26bfe62923671f7", name: "Retail" },
  { id: "58e642e5d26bfe62923671f8", name: "Technology" },
  { id: "58e642e5d26bfe62923671f9", name: "Transportation and Logistics" },
  { id: "58e642e5d26bfe62923671fa", name: "Other" },
  { id: "5a15b496b1a1f38d182ed4a3", name: "Fast Moving Consumer Goods" },
  { id: "5b043ab9e6cc99c18616a573", name: "Aerospace and Defence" },
  {
    id: "5b043ab9e6cc99c18616a574",
    name: "Business and Management Services"
  },
  { id: "5b043ab9e6cc99c18616a575", name: "Chemicals" },
  {
    id: "5b043ab9e6cc99c18616a576",
    name: "Real Estate, rental and leasing"
  },
  {
    id: "5b043ab9e6cc99c18616a577",
    name: "Telecommunications and Publishing"
  },
  { id: "5b043ab9e6cc99c18616a578", name: "Wholesale" }
]

export const jobs = {
  jobType: jobTypes,
  industryId: industries
}

export const getJobTypeById = id =>
  jobTypes.find(jobType => jobType.id === id).name
