import { Button, Chip, Flex, Select, Text } from "@engaging-tech/components"
import React, { useState } from "react"
import styled from "styled-components"

import ProfileSection from "./ProfileSection"

const StyledChip = styled(Chip)`
  &:hover {
    cursor: auto;
  }
`
const FormShowing = ({ toggle, onUpdate, age, gender }) => {
  const [myAge, setMyAge] = useState(age)
  const [myGender, setMyGender] = useState(gender)
  return (
    <Flex flexDirection="column">
      <Select label="Age Range" color="primary.0" value={myAge} onChange={e => setMyAge(e.target.value)}>
        <option value="All"> </option>
        <option value="Under 18">Under 18</option>
        <option value="19-24">19-24</option>
        <option value="25-34">25-34</option>
        <option value="35-44">35-44</option>
        <option value="45-54">45-54</option>
        <option value="55-64">55-64</option>
        <option value="65+">65+</option>
      </Select>
      <Select
        label="Gender"
        mt={2}
        color="primary.0"
        value={myGender}
        onChange={e => setMyGender(e.target.value)}
      >
        <option value="All"> </option>
        <option value="Male">Male</option>
        <option value="Female">Female</option>
        <option value="Other">Other</option>
      </Select>
      <Button
        mt={3}
        elevation={0}
        variant="outline.primary.0"
        width="120px"
        onClick={() => {
          onUpdate({ age: myAge, gender: myGender })
          toggle()
        }}
      >
        SAVE
      </Button>
    </Flex>
  )
}

const DataShowing = ({ age, gender }) => (
  <Flex flexWrap="wrap">
    {age && (
      <StyledChip mr={2} mt={2}>
        {age}
      </StyledChip>
    )}
    {gender && (
      <StyledChip mr={2} mt={2}>
        {gender}
      </StyledChip>
    )}
    <Flex flexDirection="column">
      {!age && <Text>You have not entered your age yet.</Text>}
      {!gender && <Text>You have not entered your gender yet.</Text>}
    </Flex>
  </Flex>
)

const AboutYou = ({ age, gender, onUpdate, toggle, hideCancelButton = false }) => {
  const [dataShowing, setDataShowing] = useState(true)

  return (
    <ProfileSection
      title="About You"
      text="This data is completely confidential and private. Please refer to our Privacy Policy to find out more."
      icon="stickman"
      linkText="Privacy Policy"
      linkTo="legal/privacy-policy"
      onClick={() => setDataShowing(!dataShowing)}
      toggle={toggle}
      hideCancelButton={hideCancelButton}
      dataShowing={dataShowing}
    >
      {dataShowing ? (
        <DataShowing age={age} gender={gender} />
      ) : (
        <FormShowing
          toggle={toggle || (() => setDataShowing(true))}
          onUpdate={onUpdate}
          age={age}
          gender={gender}
        />
      )}
    </ProfileSection>
  )
}

export default AboutYou
