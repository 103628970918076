import React from "react"

import AuthGuard from "../../features/ui/containers/AuthGuard"
import AudiobooksContainer from "../../features/myLibrary/containers/AudioBooks"
import BackBar from "../../features/ui/components/Backbar"
import PageLayout from "../../layouts/PageLayout"

const Audiobooks = () => (
  <AuthGuard>
    <PageLayout
      bg="dark.7"
      banner={() => (
        <>
          <BackBar />
        </>
      )}
    >
      <AudiobooksContainer />
    </PageLayout>
  </AuthGuard>
)

export default Audiobooks
