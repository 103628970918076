import { connect } from "react-redux"
import { yourProfileSelectors } from "../../yourProfile"

import { getIsLoading, getOrders } from "../store/libraryBooks.selectors"
import { loadOrders } from "../store/libraryBooks.actions"
import { getIsPremium } from "../../yourProfile/store/yourProfile.selectors"

import OrderListComponent from "../components/Orders/OrderList"

const mapState = state => ({
  isLoading: getIsLoading(state),
  orders: getOrders(state),
  userDetails: yourProfileSelectors.getProfile(state),
  isPremium: getIsPremium(state)
})

const mapDispatch = dispatch => ({
  onLoad: userId => dispatch(loadOrders(userId))
})

const OrderList = connect(mapState, mapDispatch)(OrderListComponent)

export default OrderList
