import { CustomHooks, Modal } from "@engaging-tech/components"
import React from "react"
import { createPortal } from "react-dom"

import FullProfileEdit from "../../yourProfile/components/FullProfileEdit"
import AboutYou from "../../yourProfile/containers/AboutYou"
import Education from "../../yourProfile/containers/Education"
import Experience from "../../yourProfile/containers/Experience"
import Hobbies from "../../yourProfile/containers/Hobbies"
import Industries from "../../yourProfile/containers/Industries"
import Languages from "../../yourProfile/containers/Languages"
import PersonalStatement from "../../yourProfile/containers/PersonalStatement"
import Skills from "../../yourProfile/containers/Skills"

const appRoot = () => typeof document !== "undefined" && document.getElementById("root")

const SectionType = ({ sectionName, toggle }) => {
  if (sectionName === "About You") {
    return <AboutYou toggle={toggle} />
  }
  if (sectionName === "Personal Statement") {
    return <PersonalStatement toggle={toggle} />
  }
  if (sectionName === "Experience") {
    return <Experience toggle={toggle} />
  }
  if (sectionName === "Education") {
    return <Education toggle={toggle} />
  }
  if (sectionName === "Industries") {
    return <Industries toggle={toggle} />
  }
  if (sectionName === "Skills") {
    return <Skills toggle={toggle} />
  }
  if (sectionName === "Hobbies and Interests") {
    return <Hobbies toggle={toggle} />
  }
  if (sectionName === "Languages") {
    return <Languages toggle={toggle} />
  }
  return null
}

const AboutSectionModal = ({ sectionName, toggle }) => {
  CustomHooks.useFreshChatDeactivate()
  return createPortal(
    <Modal
      bg="light.0"
      flexDirection="column"
      animated
      disableScrollbar
      maxHeight="80vh"
      mb={100}
      onToggle={() => toggle(false)}
      p={[2, 3, 3]}
      zIndex={950} // google maps api autocomplete has z-index of 1000
    >
      {sectionName ? (
        <SectionType sectionName={sectionName} toggle={toggle} />
      ) : (
        <FullProfileEdit toggle={toggle} />
      )}
    </Modal>,
    appRoot()
  )
}

export default AboutSectionModal
