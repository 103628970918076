import { Box } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import CompanyInformationButton from "../../../components/companyInformationButton"
import HappyAtWorkTest from "../../../components/jobRankings/happyAtWorkTest"
import Kpis from "../../../components/jobRankings/kpis"
import { selectJob } from "../../../store/jobFinder.actions"
import { getJobRanking, getJobSelected } from "../../../store/jobFinder.selectors"
import { parseJobTypeAndPostedDate } from "../utils"
import {
  JobCompanyName,
  JobContainer,
  JobLocation,
  JobTitle,
  JobTitleContainer,
  JobType
} from "./jobsList.styled"

function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  }
}

const JobListItem = ({ job, index }) => {
  const router = useRouter()

  const [screenSize, setScreenSize] = useState(getCurrentDimension())

  const paths = usePaths()

  const jobSelected = useSelector(getJobSelected)
  const jobRanking = useSelector(getJobRanking(job.company_name_key))

  const dispatch = useDispatch()

  const onSelectJob = value => dispatch(selectJob(value))

  const onGoToDetails = jobId => {
    window.open(`${paths.findAJob.jobFinder.index}/${jobId}`, "_blank", "noreferrer")
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener("resize", updateDimension)

    return () => {
      window.removeEventListener("resize", updateDimension)
    }
  }, [screenSize])

  return (
    <JobContainer
      first={index === 0}
      selected={jobSelected?.job_id === job.job_id}
      onClick={() => {
        if (screenSize.width > 1080) {
          onSelectJob(job)
        } else {
          router.navigate(`${paths.findAJob.jobFinder.index}/${job.job_id}`)
        }
      }}
    >
      <JobTitleContainer>
        <JobTitle
          onClick={() => {
            if (screenSize.width > 1080) {
              onGoToDetails(job.job_id)
            }
          }}
        >
          {job.title}
        </JobTitle>

        <JobCompanyName>{job.company}</JobCompanyName>
        <JobLocation>{job.location}</JobLocation>
        <JobType>
          {parseJobTypeAndPostedDate({
            jobType: job.job_type,
            jobAge: job.age
          })}
        </JobType>
      </JobTitleContainer>

      <Box mt={28} mb={18}>
        <Box p="0 20px">
          <Kpis companyScore={jobRanking?.score} />
          {jobRanking ? (
            <CompanyInformationButton companyNameKey={job.company_name_key} />
          ) : (
            <HappyAtWorkTest />
          )}
        </Box>
      </Box>
    </JobContainer>
  )
}

export default JobListItem
