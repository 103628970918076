import React from "react"
import { Flex, Text, Icon } from "@engaging-tech/components"

const CheckList = ({ text }) => (
  <Flex alignItems="center" bg="dark.7" borderRadius="24px" p={1} mb={2}>
    <Icon
      m={0}
      name="check"
      color="light.0"
      bg="primary.0"
      borderRadius={99}
      width="auto"
    />
    <Text fontSize={3} ml={2} color="dark.2">
      {text}
    </Text>
  </Flex>
)

export default CheckList
