import { Flex } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import BaseLayout from "./BaseLayout"

const Wrapper = styled(Flex)`
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
`

const PageLayout = ({ children, bg = "transparent", maxWidth = 1280, banner, isPublic, ...props }) => (
  <BaseLayout bg={bg} banner={banner || null} px={[3, 3, 4]} py={5} isPublic={isPublic}>
    <Wrapper justifyContent="center" mx="auto" maxWidth={maxWidth} {...props}>
      {children}
    </Wrapper>
  </BaseLayout>
)

export default PageLayout
