import { Flex, IconButton } from "@engaging-tech/components"
import { hideVisually } from "polished"
import React from "react"
import styled from "styled-components"

const HiddenInput = styled.input.attrs({ type: "file" })`
  ${hideVisually()}
`

const AttachmentInput = ({ id, icon, onSelect, accept }) => {
  const handleChange = e => {
    onSelect(e.target.files[0])
    e.target.value = null
  }

  return (
    <Flex as="label" width="auto" htmlFor={id} onChange={handleChange}>
      <IconButton
        icon={icon}
        as="div"
        alignItems="center"
        onClick={() => console.log("IconButton - onClick")}
      />
      <HiddenInput id={id} accept={accept} />
    </Flex>
  )
}

export default AttachmentInput
