const premiumFeaturesList = [
  {
    title: "Job Matcher Services",
    text:
      "Get automatically matched with a job vacancy based on your skill set and career goals"
  },
  {
    title: "Survey Results Action Plans",
    text:
      "Get comprehensive guidance in how to improve your low-scoring areas of your workplace happiness"
  },
  {
    title: "Mentor Matcher Service - For Mentees",
    text:
      "Get connected with a mentor who can help guide and advise you at any point in your career"
  },
  {
    title: "Monthly Business e-magazine",
    text: "With articles, podcasts, lectures, book reviws and much more"
  },
  {
    title: "Tailored Job Notifications",
    text:
      "Receive job notifications tailored to your search preferences to save time un your job search"
  },
  {
    title: "Event Notifications",
    text:
      "Receive tailored event notifications based on your interests, preferences and location"
  },
  {
    title:
      "Receive 20% off all books, ebooks and audiobooks in the Business Library"
  },
  {
    title: "Receive a free ebook: 'Fairness For All' by Lord Mark Price"
  }
]

export default premiumFeaturesList
