import React from "react"
import { useTranslation } from "react-i18next"

import ReportItem from "../../components/ReportItem"
import ResultCard from "../../components/ResultCard"
import Matrix from "../containers/Matrix"

const Matrices = ({ matricesLayout, showShadow = true, showAction = true }) => {
  const { t } = useTranslation(["results"])
  return (
    <ResultCard style={showShadow ? undefined : {boxShadow: 'none'}}>
      <ReportItem heading={t("matrices.heading", "")}>
        {matricesLayout.map(layout => (
          <Matrix key={layout} name={layout} showAction={showAction} />
        ))}
      </ReportItem>
    </ResultCard>
  )
}

export default Matrices
