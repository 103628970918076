import React from "react"
import { Flex } from "@engaging-tech/components"
import AboutYou from "../containers/AboutYou"
import Education from "../containers/Education"
import Experience from "../containers/Experience"
import Industries from "../containers/Industries"
import Hobbies from "../containers/Hobbies"
import PersonalStatement from "../containers/PersonalStatement"
import Skills from "../containers/Skills"
import Languages from "../containers/Languages"

const FullProfileEdit = ({ toggle }) => {
  return (
    <Flex width="100%" height="100%" flexDirection="column">
      <AboutYou hideCancelButton />
      <Experience hideCancelButton />
      <Industries hideCancelButton />
      <Hobbies hideCancelButton />
      <PersonalStatement hideCancelButton />
      <Education hideCancelButton />
      <Skills hideCancelButton />
      <Languages toggle={toggle} />
    </Flex>
  )
}

export default FullProfileEdit
