import { connect } from "react-redux"

import WelcomeComponent from "../components/Welcome"
import { load } from "../store/happinessSurvey.actions"
import { getSurveyLanguages } from "../store/happinessSurvey.selectors"

const mapState = state => ({
  languages: getSurveyLanguages(state)
})

const mapDispatch = dispatch => ({
  onLoad: id => dispatch(load(id))
})

const Welcome = connect(mapState, mapDispatch)(WelcomeComponent)

export default Welcome
