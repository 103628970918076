import { Flex } from "@engaging-tech/components"
import React, { useState } from "react"

import Comment from "./Comment"
import Expander from "./Expander"

const Comments = ({ comments, postId }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [selectedComment, setSelectedComment] = useState(null)

  const toggleComments = () => setIsExpanded(!isExpanded)

  const toggleSelectedComment = id =>
    selectedComment === id ? setSelectedComment(null) : setSelectedComment(id)

  return (
    <Flex flexDirection="column">
      {comments.length > 1 && (
        <Expander onExpand={toggleComments} text={isExpanded ? "Hide comments" : "View all comments"} />
      )}

      {isExpanded ? (
        comments
          .map(comment => (
            <Comment
              key={comment.id}
              comment={comment}
              onReply={() => toggleSelectedComment(comment.id)}
              isSelected={selectedComment === comment.id}
              postId={postId}
            />
          ))
          .reverse()
      ) : (
        <Comment
          comment={comments[0]}
          onReply={() => toggleSelectedComment(comments[0].id)}
          isSelected={selectedComment === comments[0].id}
          postId={postId}
        />
      )}
    </Flex>
  )
}

export default Comments
