import { takeLatest, call, put, delay } from "redux-saga/effects"

import { GlobalHubActions } from "../../globalHub"

import {
  createAdvice,
  loadRegions,
  loadJobRoles
} from "../services/advice.service"

import * as AdviceActions from "./advice.actions"

export function* createSaga(action) {
  try {
    const res = yield call(createAdvice, action.payload)
    yield put(AdviceActions.createSuccess(res.data.createExpertAdvice))
    yield put(GlobalHubActions.load())
  } catch {
    yield put(AdviceActions.createFail())
  }
}

export function* createSuccessSaga() {
  // Allow enough time to redirect a user after they have successfully
  // published the advice
  yield delay(100)
  yield put(AdviceActions.resetCreate())
}

export function* loadRegionsSaga(action) {
  try {
    const res = yield call(loadRegions, action.payload)
    yield put(AdviceActions.loadRegionsSuccess(res.data.allRegions))
  } catch {
    yield put(AdviceActions.loadRegionsFail())
  }
}

export function* loadJobRolesSaga(action) {
  try {
    const res = yield call(loadJobRoles, action.payload)
    yield put(AdviceActions.loadJobRolesSuccess(res.data.allJobRoles))
  } catch (err) {
    yield put(AdviceActions.loadJobRolesFail())
  }
}

export function* saga() {
  yield takeLatest(AdviceActions.types.LOAD_REGIONS, loadRegionsSaga)
  yield takeLatest(AdviceActions.types.LOAD_JOB_ROLES, loadJobRolesSaga)
  yield takeLatest(AdviceActions.types.CREATE, createSaga)
  yield takeLatest(AdviceActions.types.CREATE_SUCCESS, createSuccessSaga)
}
