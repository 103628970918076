import React from "react"
import { Flex, Text } from "@engaging-tech/components"

import Attachment from "../../../userGeneratedContent/components/Attachments"

const FeaturedMedia = ({ content }) => (
  <Flex width={1 / 1} flexDirection="column">
    <Attachment.Thumbnail
      src={content.coverImage.source}
      hasPlayButton
      mb={3}
    />
    <Text fontWeight="500">{content.title}</Text>
    <Text color="dark.2" fontSize={3}>
      {content.user.firstName} {content.user.lastName}
    </Text>
  </Flex>
)

export default FeaturedMedia
