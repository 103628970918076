import React, { useEffect, useRef } from "react"
import { createPortal } from "react-dom"
import BackBar from "./Backbar"

const getTarget = target =>
  typeof document !== "undefined" && document.getElementById(target)

const UseBackBar = ({ target, to = "", actionName = "Back" }) => {
  const el = useRef(document.createElement("div"))

  useEffect(() => {
    const parentElem = getTarget(target)
    const elem = el.current
    if (parentElem) {
      parentElem.insertBefore(elem, parentElem.firstChild)
    }
    return () => elem.remove()
  }, [target, el])

  if (!el.current) return null
  return createPortal(
    <BackBar to={`${to}/`} actionName={actionName} />,
    el.current
  )
}

export default UseBackBar
