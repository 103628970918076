import React from "react"

import GradientWrapper from "./GradientWrapper"
import Phone from "./Phone"
import CardGrid from "./CardGrid"

import messengerFeatures from "../../lib/messengerFeatures"

/* eslint-disable-next-line import/no-dynamic-require */
const image = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/mobile-placeholder.png`)

const AppPromotion = () => (
  <GradientWrapper>
    <Phone src={image} />
    <CardGrid items={messengerFeatures} />
  </GradientWrapper>
)

export default AppPromotion
