import { connect } from "react-redux"
import { developCareerSelectors } from ".."
import SurveysListComponent from "../components/SurveysList"

const mapState = state => ({
  surveys: developCareerSelectors.getSurveys(state)
})

const SurveysList = connect(mapState)(SurveysListComponent)

export default SurveysList
