const awsmobile = {
  aws_project_region: 'eu-west-2',
  aws_cognito_region: 'eu-west-2',
  aws_user_pools_id: 'eu-west-2_3dNv6TwSs',
  aws_user_pools_web_client_id: '3nsl7kgoaq9375v9ks269v0en6',
  oauth: {},
  aws_appsync_graphqlEndpoint: 'https://za6qugf37nderjdtjn7swxni54.appsync-api.eu-west-2.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS'
};

export default awsmobile;
