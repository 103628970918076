import { types } from "./bookmark.actions"

export const initialState = {
  isLoading: false,
  bookmarkedMaterials: []
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD:
    case types.TOGGLE:
      return { ...state, isLoading: true }
    case types.LOAD_BY_MATERIAL_TYPE_SUCCESS:
    case types.LOAD_SUCCESS:
      return { ...state, bookmarkedMaterials: action.payload, isLoading: false }

    case types.BOOKMARK_SUCCESS:
      return {
        ...state,
        bookmarkedMaterials: [...state.bookmarkedMaterials, action.payload],
        isLoading: false
      }

    case types.UNBOOKMARK_SUCCESS:
      return {
        ...state,
        bookmarkedMaterials: state.bookmarkedMaterials.filter(
          bookmark => bookmark.refId !== action.payload.refId
        ),
        isLoading: false
      }
    case types.LOAD_BY_MATERIAL_TYPE_FAIL:
    case types.LOAD_FAIL:
    case types.TOGGLE_FAIL:
      return { ...state, isLoading: false }

    case types.RESET:
      return initialState

    default:
      return state
  }
}
