import { Box, Button, Card, Flex, H3 } from "@engaging-tech/components"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Transition } from "react-transition-group"
import styled, { css } from "styled-components"

import { useSliderContext } from "../lib/SliderContext"

const ANIMATION_DURATION = 180

const animateFowards = css`
  @media only screen and (orientation: portrait) {
    margin-top: ${({ state }) => (state === "entering" ? "15%" : 0)};
    margin-bottom: ${({ state }) => (state === "exiting" ? "15%" : 0)};
  }

  @media only screen and (orientation: landscape) {
    margin-left: ${({ state }) => (state === "entering" ? "10%" : 0)};
    margin-right: ${({ state }) => (state === "exiting" ? "10%" : 0)};
    margin-top: 32px;
    margin-bottom: 32px;
  }
`

const animateBackwards = css`
  @media only screen and (orientation: portrait) {
    margin-bottom: ${({ state }) => (state === "entering" ? "15%" : 0)};
    margin-top: ${({ state }) => (state === "exiting" ? "15%" : 0)};
  }

  @media only screen and (orientation: landscape) {
    margin-right: ${({ state }) => (state === "entering" ? "10%" : 0)};
    margin-left: ${({ state }) => (state === "exiting" ? "10%" : 0)};
    margin-top: 32px;
    margin-bottom: 32px;
  }
`

const AnimatedCard = styled(Card)`
  transition: ${ANIMATION_DURATION}ms;
  user-select: ${({ state }) => (state === "entered" ? "auto" : "none")};
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
  position: relative;

  ${({ direction }) => (direction === "forward" ? animateFowards : animateBackwards)}
`

const formatTitle = title => title.split("-").join(" ")

const TitleText = styled(H3)`
  text-transform: capitalize;
`

const Title = ({ children }) => (
  <Box bg="dark.7" py={[2, 3]} px={3}>
    <TitleText fontSize={[3, 6]} textAlign="center" fontWeight="500">
      {formatTitle(children)}
    </TitleText>
  </Box>
)

const SurveyCard = ({
  children,
  title,
  onNext,
  onPrevious,
  type,
  isValid = true,
  isLast = false,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(true)
  const { direction, setDirection } = useSliderContext()

  const handleNext = () => {
    setDirection("forward")
    setIsVisible(false)

    setTimeout(() => onNext(), ANIMATION_DURATION)
  }

  const handlePrevious = () => {
    setDirection("backward")
    setIsVisible(false)

    setTimeout(() => onPrevious(), ANIMATION_DURATION)
  }

  const handleKeyPress = event => {
    event.preventDefault()
    const keyCode = event.which
    if (!isLast && (keyCode === 39 || keyCode === 13 || keyCode === 78) && isValid) {
      handleNext()
    } else if (keyCode === 37) {
      handlePrevious()
    }
  }

  useEffect(() => {
    // if (type === "scale") {
    if (type !== "short-text" && isValid) {
      window.addEventListener("keydown", handleKeyPress)
      return () => window.removeEventListener("keydown", handleKeyPress)
    }

    return undefined

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  const { t } = useTranslation(["happiness_survey"])

  const rightButtonText = isLast ? t("ui.submit", "") : t("questions.next", "")
  const leftButtonText = t("questions.previous", "")

  return (
    <Transition timeout={ANIMATION_DURATION} appear in={isVisible}>
      {state => (
        <AnimatedCard
          bg="light.0"
          {...props}
          mt={[5, 5, 6]}
          mb={[6, 5]}
          elevation={8}
          state={state}
          direction={direction}
          width={1 / 1}
          maxWidth={560}
        >
          {title && <Title>{title}</Title>}
          <Box p={3}>{children}</Box>
          <Flex flexDirection="row-reverse" justifyContent="space-between" p={3} pt={0}>
            <Button variant="primary.0" onClick={handleNext} disabled={!isValid}>
              {rightButtonText}
            </Button>
            <Button variant="text.dark.2" elevation={0} onClick={handlePrevious}>
              {leftButtonText}
            </Button>
          </Flex>
        </AnimatedCard>
      )}
    </Transition>
  )
}

export default SurveyCard
