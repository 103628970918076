import { CustomHooks } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"

import MinifiedNav from "../features/ui/containers/MinifiedNav"
import GradientLayout from "./GradientLayout"

const SurveyLayout = ({ children }) => {
  const router = useRouter()
  const paths = usePaths()
  CustomHooks.useFreshChatDeactivate()

  const { downForMaintenance } = getConfig()

  if (downForMaintenance.all || downForMaintenance.surveys) {
    router.navigate(paths.downForMaintenance)
  }

  return (
    <GradientLayout px={[3, 3, 4]} mb={[60]} banner={MinifiedNav}>
      {children}
    </GradientLayout>
  )
}

export default SurveyLayout
