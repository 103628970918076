import { createSelector } from "reselect"

const bipProfile = state => state.bipProfile

export const getBipInfo = createSelector(bipProfile, profile => profile?.bipInformation)

export const getCompanyName = createSelector(getBipInfo, bipInfo => bipInfo?.company)

export const getIndicators = createSelector(getBipInfo, bipInfo => bipInfo?.indicators)

export const getIndicatorFlightRiskDataSet = createSelector(getIndicators, indicators => {
  if (indicators?.flightRisk) {
    return {
      score: (indicators.flightRisk.count / indicators.flightRisk.total) * 100,
      global: indicators.flightRisk.global,
      industry: indicators.flightRisk.industry
    }
  }
  return null
})

export const getIndicatorDataSet = createSelector([getIndicators, (_, type) => type], (indicators, type) => {
  if (indicators && indicators[type]) {
    return {
      score: indicators[type].average.score,
      global: indicators[type].average.global,
      industry: indicators[type].average.industry
    }
  }
  return null
})

export const getIndicatorWellBeingDataSet = createSelector(getIndicators, indicators => {
  if (indicators?.wellBeingRisk) {
    return {
      score: Math.round((indicators.wellBeingRisk.count / indicators.wellBeingRisk.total) * 100),
      global: indicators.wellBeingRisk.global,
      industry: indicators.wellBeingRisk.industry
    }
  }
  return null
})

export const getIndicatorDAndIDataSet = createSelector(getIndicators, indicators => {
  if (!indicators?.diIndex) return null
  const foundIndex = indicators.diIndex.find(i => i.section === "index")
  if (!foundIndex) return null
  const { minority, majority } = foundIndex

  const score = minority.score - majority.score
  const industry = minority.industry - majority.industry
  const global = minority.global - majority.global

  return { score, industry, global }
})

export const getRanking = createSelector(getBipInfo, bipInfo => bipInfo?.ranking)

export const getHappinessScores = createSelector(getBipInfo, bipInfo => bipInfo?.happinessScores)

export const getSixSteps = createSelector(getBipInfo, bipInfo => bipInfo?.sixSteps)

export const getDiversityAndInclusionChartDatas = createSelector(getBipInfo, bipInfo => {
  const output = bipInfo?.diversityAndInclusion
    ? bipInfo?.diversityAndInclusion.map(g => {
        return {
          ...g,
          scores: g.scores.map(s => {
            return {
              ...s,
              label: s.label.charAt(0).toUpperCase() + s.label.slice(1),
              values: Object.keys(s.values).reduce((collector, key) => {
                const localCollector = collector
                localCollector[key] = Math.round(s.values[key])

                return localCollector
              }, {})
            }
          }),
          graphType: "comparison_bar_chart",
          unit: "%",
          graphOptions: {
            scales: {
              y: {
                min: 0,
                max: 100
              }
            }
          }
        }
      })
    : null

  return output
})

const getChartTypeInfo = type => {
  switch (type) {
    case "flightRisk": {
      return {
        title: "Flight Risk"
      }
    }
    case "wellBeingRisk": {
      return {
        title: "Wellbeing Risk"
      }
    }
    case "managementConfidence": {
      return {
        title: "Confidence in Management"
      }
    }
    default: {
      return {
        title: "Unknown"
      }
    }
  }
}

export const getIndicatorChartData = createSelector(
  [getBipInfo, getCompanyName, (_, type) => type],
  (bipInfo, companyName, type) => {
    if (bipInfo?.indicators[type]) {
      const { questions } = bipInfo?.indicators[type]
      if (questions?.length) {
        const scores = questions.map(q => ({
          label: q.question,
          values: {
            [companyName]: q.score,
            industry: q.industry,
            global: q.global
          }
        }))
        const chartInfo = getChartTypeInfo(type)
        return {
          name: chartInfo.title,
          graphType: "comparison_bar_chart",
          unit: "%",
          scores,
          graphOptions: {
            scales: {
              y: {
                title: {
                  display: true,
                  text: "Average Score"
                }
              }
            }
          }
        }
      }
    }

    return null
  }
)

export const getDandIChartData = createSelector([getBipInfo, getCompanyName], (bipInfo, companyName) => {
  if (bipInfo?.indicators?.diIndex) {
    const questions = bipInfo.indicators.diIndex.filter(i => i.section !== "index")

    if (questions.length) {
      const scores = []

      questions.forEach(q => {
        scores.push({
          label: q.questions[0]?.question,
          values: {
            [companyName]: q.minority.score - q.majority.score,
            industry: q.minority.industry - q.majority.industry,
            global: q.minority.global - q.majority.global
          }
        })
      })

      return {
        name: "Diversity & Inclusion",
        graphType: "comparison_bar_chart",
        yAxisDecimalPlaces: 1,
        scores,
        unit: "%",
        graphOptions: {
          scales: {
            y: {
              title: {
                display: true,
                text: "Percentage Difference in Average Score"
              }
            }
          }
        }
      }
    }
  }

  return null
})

export const getCompanyNameKey = createSelector(getBipInfo, bipInfo => bipInfo?.companyNameKey)

export const getRecommendation = createSelector(getBipInfo, bipInfo => bipInfo?.recommendation)

export const getBipProfile = createSelector(getBipInfo, bipInfo => bipInfo?.profile)

export const getProfileId = createSelector(getBipProfile, profile => profile?.id)

export const getHasInfo = createSelector(getBipProfile, info => !!info)

export const getSummary = createSelector(getBipProfile, profile => profile && profile.summary)

export const getOrganistionId = createSelector(getBipProfile, profile => profile && profile.organisationId)

export const getCulture = createSelector(getBipProfile, profile => profile && profile.culture)

export const getIsLoading = createSelector(bipProfile, profile => profile?.isLoading)

export const getDunnsData = createSelector(bipProfile, state => state.dunnData)
