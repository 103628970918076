/* eslint-disable import/no-named-as-default-member */
import { useEffect } from "react"
import { connect } from "react-redux"

import { yourProfileSelectors } from ".."
import { cvLibraryActions } from "../../cvLibrary"
import { load as yourProfileLoad } from "../store/yourProfile.actions"

const LoaderComponent = ({ onLoad, userId, cvId, onLoadCV }) => {
  useEffect(() => {
    if (userId) onLoad(userId)

    if (cvId) onLoadCV({ id: cvId })
  }, [onLoad, userId, cvId, onLoadCV])
  return null
}

const mapState = state => ({
  userId: yourProfileSelectors.getUserId(state)
})

const mapDispatch = dispatch => ({
  onLoad: userId => dispatch(yourProfileLoad({ userId })),
  onLoadCV: id => {
    dispatch(cvLibraryActions.loadSelectedCV(id))
  }
})

const Loader = connect(mapState, mapDispatch)(LoaderComponent)

export default Loader
