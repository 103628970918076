import React from "react"
import { Spinner, Flex } from "@engaging-tech/components"
import PrimaryButton from "../../../ui/components/PrimaryButton"

const LoadMore = ({ from, isLoadingMore, hasLoadedAll, onLoad }) => {
  return (
    <Flex maxWidth={700} ml="auto" justifyContent="center" alignItems="center" height={60}>
      {isLoadingMore ? (
        <Spinner py={3} color="primary.0" width={1 / 1} />
      ) : (
        <PrimaryButton onClick={() => onLoad(from)} width={300} disabled={hasLoadedAll} color="light.0" text={hasLoadedAll ? "Loaded all books" : "Load more"} />
      )}
    </Flex>
  )
}

export default LoadMore
