import { connect } from "react-redux"

import Posts from "../components/Posts/Posts"
import { load } from "../store/globalHub.actions"
import { getIsLoading, getIsPostCreationVisible, getPosts } from "../store/globalHub.selectors"

const mapState = state => ({
  posts: getPosts(state),
  isLoading: getIsLoading(state),
  isPostCreationVisible: getIsPostCreationVisible(state)
})

const mapDispatch = dispatch => ({
  onLoad: params => dispatch(load(params))
})

const PostList = connect(mapState, mapDispatch)(Posts)

export default PostList
