import { Flex } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import HappiestCompanies from "../../features/happinessSurvey/containers/Welcome"
import gradientCss from "../../helpers/gradientCss"
import AppViewBaseLayout from "../../layouts/AppViewBaseLayout"

const GradientBox = styled(Flex)`
  ${({ theme }) => gradientCss(theme)}
`

export default function SurveyWelcome() {
  return (
    <AppViewBaseLayout mx={3}>
      <GradientBox as="main" flexDirection="column" minHeight="100vh">
        <Flex width={1 / 1} flex="1" alignItems="center" justifyContent="center" px={[3, 3, 4]} mb={[60]}>
          <Flex justifyContent="center">
            <HappiestCompanies />
          </Flex>
        </Flex>
      </GradientBox>
    </AppViewBaseLayout>
  )
}
