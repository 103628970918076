import { Box, Button, Flex, Text, TextField } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React from "react"

import AccountDialog from "../../../ui/components/AccountDialog"

export default function ForgotPasswordFormContent({
  handleSubmit,
  errors,
  touched,
  values,
  isValid,
  handleChange,
  handleBlur,
  isLoading,
  siteLogo
}) {
  return (
    <AccountDialog
      title="Forgotten your password?"
      subtitle="Send password reset via email"
      renderLogo={siteLogo}
    >
      <form onSubmit={handleSubmit}>
        <Box>
          <TextField
            width={1 / 1}
            id="forgot-pw-email"
            borderRadius="4px 4px 0px 0px"
            name="email"
            type="email"
            label="Email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={touched.email && errors.email}
          />
        </Box>
        <Box mb={3}>
          <Text fontSize={[4, 3]}>Remembered your password?</Text>
          <Link to="/account/sign-in">
            <Text fontSize={[4, 3]} color="primary.0">
              {" "}
              Go back to sign in
            </Text>
          </Link>
        </Box>
        <Flex width={1 / 1} flexDirection="row-reverse" alignItems="center">
          <Button variant="primary.0" type="submit" disabled={!isValid || isLoading} ml={2} width="auto">
            Send Email
          </Button>

          <Link to="/account/sign-up" mr="auto">
            <Button variant="text.noBg.primary.0" type="button">
              Sign Up
            </Button>
          </Link>
        </Flex>
      </form>
    </AccountDialog>
  )
}
