import { Box, H3 } from "@engaging-tech/components"
import React from "react"
import { formatTitle } from "./helpers"

const Title = ({ children }) => (
  <Box bg="dark.7" py={[2, 3]} px={3}>
    <H3 fontSize={[3, 6]} textAlign="center" fontWeight="500">
      {formatTitle(children)}
    </H3>
  </Box>
)

export default Title
