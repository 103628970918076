import React from "react"
import { useSelector } from "react-redux"

import { getIsPremium } from "../../yourProfile/store/yourProfile.selectors"
import JobMatches from "../components/jobMatches"

const JobMatchesFeed = () => {
  const isPremium = useSelector(getIsPremium)

  return <JobMatches isPremium={isPremium} />
}

export default JobMatchesFeed
