import { connect } from "react-redux"
import { yourProfileSelectors } from "../../yourProfile"

import {
  updateOrder,
  payOrderSuccess,
  resetBasket,
  payOrderFail,
  setDeliveryType,
  resetOrder
} from "../store/libraryBooks.actions"

import {
  getOrderId,
  getIntentSecret,
  getIsLoading,
  getHasShippingCosts,
  getHasPaid
} from "../store/libraryBooks.selectors"

import { getIsPremium } from "../../yourProfile/store/yourProfile.selectors"

import PaymentDialog from "../components/Checkout/PaymentDialog"

const mapState = state => ({
  orderId: getOrderId(state),
  intentSecret: getIntentSecret(state),
  isLoading: getIsLoading(state),
  hasDeliveryCosts: getHasShippingCosts(state),
  userDetails: yourProfileSelectors.getProfile(state),
  isPremium: getIsPremium(state),
  hasPaid: getHasPaid(state)
})

const mapDispatch = dispatch => ({
  updateOrder: (orderId, orderInfo) =>
    dispatch(updateOrder({ orderId, orderInfo })),
  onPaymentActionSuccess: () => [
    dispatch(payOrderSuccess()),
    dispatch(resetBasket())
  ],
  onPaymentActionFail: () => dispatch(payOrderFail()),
  setDeliveryType: code => dispatch(setDeliveryType(code)),
  onCancelPayment: () => dispatch(resetOrder())
})

const CheckoutPayment = connect(mapState, mapDispatch)(PaymentDialog)

export default CheckoutPayment
