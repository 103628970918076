import { Flex } from "@engaging-tech/components"
import styled from "styled-components"

import { ShareIcon } from "../../../../../../../assets/icons"

export const JobSharePopover = styled.div`
  position: absolute;
  top: 24px;
  right: 0;
  z-index: 10;

  min-width: 305px;
  width: auto;
  height: fit-content;

  padding: 15px;

  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
`

export const JobShareIcon = styled(ShareIcon)`
  cursor: pointer;
`

export const JobShareParagraph = styled.p`
  height: auto;
  width: auto;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #003e4c;
`

export const ShareViaContainer = styled(Flex)`
  height: auto;
  width: auto;

  padding: 0 10px;

  justify-content: space-between;
  align-items: center;

  p {
    margin-right: 18px;
  }
`

export const ShareViaLogoContainer = styled(Flex)`
  svg:not(:last-child) {
    margin-right: 9px;
  }
`

export const ShareIconButton = styled.button`
  background-color: transparent;

  border: none;
  border-radius: 5px;

  padding: 6px;

  display: flex;
  align-items: center;

  cursor: pointer;

  :hover {
    background-color: #f5f5f5;
  }
`
