import React from "react"
import { Text, Box, Paragraph, Flex, List } from "@engaging-tech/components"

const Resources = ({ resources, stageIndex }) => {
  return (
    <Box
      stageIndex={stageIndex}
      bg="primary.5"
      width="auto"
      px={3}
      pt={3}
      pb={0}
    >
      <Paragraph color="dark.2" fontSize={3} fontWeight={500} textAlign="left">
        Resources:
      </Paragraph>
      <Paragraph fontSize={3} color="dark.2" my={3} py={1} textAlign="left">
        Use the list below to discover materials that could be helpful on this
        topic.
      </Paragraph>
      <List.Wrapper pt={2} width="auto">
        {resources?.supportiveMaterials?.map(resource => (
          <List.Item>
            {" "}
            <Flex key={resource?.id}>
              <Text ml={2} color="#003E4C" fontSize={3}>
                <span style={{ textTransform: "capitalize" }}>
                  {" "}
                  {resource?.type} -{" "}
                </span>{" "}
                <a href={resource?.url} target="_blank" rel="noreferrer">
                  <span> {resource?.title}</span>
                </a>{" "}
                {resource.author ? (
                  <span fontWeight={700}>- {resource?.author}</span>
                ) : null}
              </Text>
            </Flex>
          </List.Item>
        ))}
      </List.Wrapper>
    </Box>
  )
}

export default Resources
