import { Box, Button, Flex, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React from "react"

import AccountDialog from "../../ui/components/AccountDialog"

export default function MagicLinkMessage({ siteLogo }) {
  return (
    <AccountDialog title="" subtitle="" renderLogo={siteLogo}>
      <Box mb={3}>
        <Flex width={1 / 1} flexDirection="row" alignItems="center">
          <Text mx={1} fontSize={[0, 5]} color="primary.0">
            Please check your email inbox for the magic link.
          </Text>
        </Flex>
      </Box>
      <Box mb={3}>
        <Link to="/account/sign-in">
          <Text fontSize={[4, 3]}>If there are any issues,</Text>
          <Text fontSize={[4, 3]} color="primary.0">
            {" "}
            Go back to sign in
          </Text>
        </Link>
      </Box>
      <Flex width={1 / 1} flexDirection="row-reverse" alignItems="center">
        <Link to="/account/magic-link">
          <Button variant="primary.0" type="submit" ml={2} width="auto">
            Resend magic link
          </Button>
        </Link>
      </Flex>
    </AccountDialog>
  )
}
