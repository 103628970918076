import React, { useState } from "react"
import { Formik } from "formik"
import { Spinner } from "@engaging-tech/components"

import CreationRedirect from "../../userGeneratedContent/components/PublishForm/CreationRedirect"
import AdviceFormCard from "./AdviceFormCard"

const Form = ({
  render: Render,
  handleSubmit,
  isValid,
  isLoading,
  postType,
  ...props
}) => {
  const [closeDropdown, setCloseDropdown] = useState(0)

  const forceCloseDropdown = () => {
    setCloseDropdown(closeDropdown + 1)
  }

  return (
    <AdviceFormCard
      title="Ask for expert advice"
      onSubmit={handleSubmit}
      disableSubmitWhen={!isValid || isLoading}
      postType={postType}
    >
      <Render
        handleSelect={forceCloseDropdown}
        handleCloseDropdown={closeDropdown}
        {...props}
      />
    </AdviceFormCard>
  )
}

const AdviceForm = ({
  onSubmit,
  initialValues,
  validation,
  isLoading,
  hasCreated,
  postType,
  render
}) => {
  if (hasCreated) {
    return <CreationRedirect />
  }

  if (isLoading) {
    return <Spinner color="primary.0" />
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={onSubmit}
    >
      {props => (
        <Form
          {...props}
          isLoading={isLoading}
          render={render}
          postType={postType}
        />
      )}
    </Formik>
  )
}

export default AdviceForm
