import { Button, Flex, UserDetails } from "@engaging-tech/components"
import React, { useState } from "react"

import AddComment from "../../components/Comments/AddComment"
import { TextAreaCustom } from "../shared/styled"
import { FullWidthForm } from "./form.styled"

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const Form = ({ isLoading, handleSubmit, user, values, isValid, handleChange, handleBlur }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return isExpanded ? (
    <FullWidthForm onSubmit={handleSubmit}>
      {user && <UserDetails user={values.anonymous ? null : user} userHoldingImage={profilePlaceholder} />}
      <TextAreaCustom
        name="content"
        value={values.content}
        onChange={handleChange}
        onBlur={handleBlur}
        width={1 / 1}
        label="Comment"
        style={{ maxWidth: "100%", minWidth: "100%" }}
        height="auto"
        autoFocus
        noLabel
      />
      <Flex flexDirection="row-reverse" flexWrap="wrap" mt={3}>
        <Button
          alignSelf="flex-end"
          width="auto"
          variant="primary.0"
          type="submit"
          ml={[3, 3, 2]}
          disabled={isLoading || !isValid}
        >
          Publish
        </Button>
        {isExpanded && (
          <Button
            width="auto"
            type="button"
            variant="text.dark.2"
            elevation={0}
            onClick={() => setIsExpanded(false)}
          >
            Cancel
          </Button>
        )}
      </Flex>
    </FullWidthForm>
  ) : (
    <AddComment
      avatar={(user && user.picture && user.picture.small) || profilePlaceholder}
      onClick={() => setIsExpanded(true)}
    />
  )
}

export default Form
