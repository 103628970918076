import React from "react"

import { libraryPostTypes } from "../../../lib/postTypes"
import CreateArticle from "./CreateArticle"
import CreateLecture from "./CreateLecture"
import CreatePodcast from "./CreatePodcast"
import CreateResearch from "./CreateResearch"

const CreateMaterialForm = ({ type, ...props }) => {
  switch (type) {
    case libraryPostTypes.articles:
      return <CreateArticle {...props} />

    case libraryPostTypes.podcasts:
      return <CreatePodcast {...props} />

    case libraryPostTypes.research:
      return <CreateResearch {...props} />

    case libraryPostTypes.lectures:
      return <CreateLecture {...props} />

    default:
      return null
  }
}

export default CreateMaterialForm
