import { useLocation } from "@engaging-tech/routing"
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"

import PremiumProductsList from "../../features/premium/containers/PremiumProducts"
import SolidLayout from "../../layouts/SolidLayout"

const ProductsList = PremiumProductsList()

const AccountComparison = () => {
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const lastRoute = queryParams.get("lastRoute")

  useEffect(() => {
    const unlisten = history.listen((_, action) => {
      if (action === "POP") {
        history.push(lastRoute)
      }
    })

    return () => {
      unlisten()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  return (
    <SolidLayout alignItems="flex-start">
      <ProductsList />
    </SolidLayout>
  )
}

export default AccountComparison
