import React from "react"

import { Card, Box } from "@engaging-tech/components"

import SummaryHeader from "./SummaryHeader"
import SummaryBody from "./SummaryBody"
import BodyWrapper from "../../BodyWrapper"
import Interactions from "../../Interactions/Interactions"
import CommentWrapper from "../../CommentWrapper/CommentWrapper"

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const Summary = ({
  post,
  hubPostId,
  interactions,
  publishedAt,
  user,
  userDetails: UserDetails,
  postInteractions: RenderInteractions,
  postComments: RenderComments,
  commentForm: RenderCommentForm
}) => {
  return (
    <Card width={1 / 1} mb={[3, 5, 5]} bg="light.0" position="relative">
      <SummaryHeader type={post.type} />
      <Box p={3}>
        {UserDetails && (
          <UserDetails
            userHoldingImage={profilePlaceholder}
            interactions={interactions}
            publishedAt={publishedAt}
            user={user}
          />
        )}
        <BodyWrapper>
          <SummaryBody post={post} postId={hubPostId} />
        </BodyWrapper>
      </Box>
      <Interactions>
        {RenderInteractions && <RenderInteractions />}
      </Interactions>
      <CommentWrapper>{RenderComments && <RenderComments />}</CommentWrapper>
      <RenderCommentForm />
    </Card>
  )
}
export default Summary
