import { useLocation } from "@engaging-tech/routing"
import React from "react"
import "url-search-params-polyfill"

import ChipBar from "../../ui/components/ChipBar"
import pluralise from "../../userGeneratedContent/lib/pluralise"
import { hubPostTypes } from "../../userGeneratedContent/lib/postTypes"

const POST_TYPES = {
  all: "all categories",
  ...hubPostTypes
}

const HubNav = ({ selected }) => {
  const location = useLocation()

  const chips = Object.values(POST_TYPES).map(type => {
    return {
      name: pluralise(type),
      linkTo: location.pathname?.includes("in-app-view") ? "/in-app-view/global-hub" : "/global-hub",
      queryParameters: type !== POST_TYPES.all ? `tab=${type}` : "",
      isActive: selected === type || (type === POST_TYPES.all && !selected)
    }
  })

  return <ChipBar chips={chips} pb={3} />
}

export default HubNav
