import React from "react"
import { Redirect, usePaths } from "@engaging-tech/routing"

import Checkout from "./Checkout"

const PaymentDialog = ({
  orderId,
  intentSecret,
  hasPaid,
  isLoading,
  updateOrder,
  onPaymentActionSuccess,
  onPaymentActionFail,
  onCancelPayment,
  setDeliveryType,
  hasDeliveryCosts,
  userDetails,
  isPremium
}) => {
  const paths = usePaths()

  if (hasPaid) {
    return (
      <Redirect to={`${paths.resourceCentre.purchased.index}/${orderId}`} />
    )
  }
  if (!orderId) {
    return <Redirect to={paths.resourceCentre.basket.index} />
  }
  return (
    <Checkout
      isLoading={isLoading}
      updateOrder={updateOrder}
      orderId={orderId}
      intentSecret={intentSecret}
      userDetails={userDetails}
      isPremium={isPremium}
      onPaymentActionSuccess={onPaymentActionSuccess}
      onPaymentActionFail={onPaymentActionFail}
      onCancelPayment={onCancelPayment}
      setDeliveryType={setDeliveryType}
      hasDeliveryCosts={hasDeliveryCosts}
    />
  )
}

export default PaymentDialog
