import { Spinner } from "@engaging-tech/components"
import { useParams } from "@engaging-tech/routing"
import React, { useEffect } from "react"

import Post from "./Post"

const SelectedPost = ({ selectedPost, onLoad }) => {
  const { id } = useParams()

  useEffect(() => {
    onLoad(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!selectedPost) {
    return <Spinner color="primary.0" py={3} />
  }

  return <Post post={selectedPost} />
}

export default SelectedPost
