import { getConfig } from "@engaging-tech/ssr-config"
import { apollo } from "../../../lib/Apollo"
import { LOAD, LOAD_BY_ID, LOAD_FEATURED } from "./businessLibrary.queries"

const GRAPHQL_API_URL = () =>
  `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

/**
 * Fetches all content of a specific post type from the backend.
 * @param {string} postType The type of posted content to load.
 */
export const loadAll = (postType, searchTerm, categoryId) =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD,
    variables: {
      type: postType,
      searchTerm,
      categoryId
    }
  })

/**
 * Fetches more content of a specific post type from the backend.
 * @param {string} postType The type of posted content to load.
 * @param {int} from The starting index/position of the content
 * @param {string} searchTerm The term that is used in the search bar
 */
export const loadMore = (postType, from = 0, searchTerm, categoryId) =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD,
    variables: {
      type: postType,
      from,
      searchTerm,
      categoryId
    }
  })

/**
 * Fetches a post of a specific post type from the backend, based on its ID.
 * @param {string} id The id of the post to load.
 */
export const loadById = id =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD_BY_ID,
    variables: { id }
  })

/**
 * Loads all featured (promoted) content. This is shown on the "Discover" page
 * of the Business Library (/business-library/discover).
 */
export const loadFeatured = () =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD_FEATURED
  })

export default loadAll
