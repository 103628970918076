import { connect } from "react-redux"

import {
  getBasket,
  getSubTotalPrice,
  getOrderId,
  getDeliveryPrice
} from "../store/libraryBooks.selectors"
import { getIsPremium } from "../../yourProfile/store/yourProfile.selectors"

import Summary from "../components/Checkout/Summary"

const mapState = state => ({
  isPremium: getIsPremium(state),
  basket: getBasket(state),
  subTotalPrice: getSubTotalPrice(state),
  deliveryPrice: getDeliveryPrice(state),
  hasProducts: !!getBasket(state).length,
  orderId: getOrderId(state)
})

const CheckoutSummary = connect(mapState)(Summary)

export default CheckoutSummary
