import React from "react"
import { useSelector } from "react-redux"
import { Text, Flex, Icon } from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"
import TrendGraph from "../containers/TrendGraph"
import SurveysList from "../containers/SurveysList"
import * as Selectors from "../store/developCareer.selectors"
import { yourProfileSelectors } from "../../yourProfile/index"

const EmptyHeading = () => {
  const paths = usePaths()
  return (
    <Flex
      width="auto"
      height="auto"
      p={3}
      bg="light.1"
      borderRadius={5}
      elevation={1}
      mb={3}
      ml={[0, 5]}
      alignItems="center"
      alignContent="center"
    >
      <Icon name="priority_high" color="primary.0" mr={2} />
      <Text color="dark.1" fontSize={3} mr={1}>
        You haven’t taken any surveys.{" "}
        <Link to={paths.happinessSurvey.index}>
          <Text fontSize={3} color="primary.0" fontWeight={700}>
            Get Started
          </Text>
        </Link>{" "}
        measuring your workplace happiness now!
      </Text>
    </Flex>
  )
}

const PremiumBanner = () => {
  const paths = usePaths()
  const isPremium = useSelector(yourProfileSelectors.getIsPremium)
  if (isPremium) return <></>

  return (
    <Flex
      width="auto"
      height="auto"
      p={3}
      bg="primary.0"
      borderRadius={5}
      elevation={1}
      mb={3}
      alignItems="center"
      alignContent="center"
    >
      <Icon name="stars" color="yellow.0" mr={2} />
      <Text color="light.0" fontSize={3} mr={1}>
        Not sure what steps to take next after completing the survey? Sign up to
        our{" "}
        <Link to={paths.getPremium.index}>
          <Text fontSize={3} color="yellow.0" fontWeight={700}>
            Premium Service
          </Text>
        </Link>{" "}
        to get dedicated action plans for each of the Six Steps.
      </Text>
    </Flex>
  )
}

const YourSurveys = () => {
  const surveys = useSelector(Selectors.getSurveys)

  return (
    <Flex flexDirection="column" width={95 / 100} justifyContent="center">
      <Text
        fontFamily="Tiempos"
        fontSize={6}
        color="dark.2"
        fontWeight={600}
        mb={3}
      >
        Your Surveys
      </Text>
      {!surveys.loading && surveys.data.length === 0 ? <EmptyHeading /> : <PremiumBanner />}
      <TrendGraph />
      <SurveysList />
    </Flex>
  )
}

export default YourSurveys
