import { gql } from "../../../lib/Apollo"

export const LOAD = gql`
  query ($type: String, $from: Int, $id: String, $userId: ID, $limit: Int) {
    allHubPosts(limit: $limit, type: $type, skip: $from, id: $id, userId: $userId) {
      id
      publishedAt
      attachments {
        type
        source
        caption
      }
      content {
        type
        body
      }
      likes {
        id
        firstName
        lastName
        picture {
          small
        }
        jobTitle
      }
      user {
        id
        isConnected
        firstName
        lastName
        jobTitle
        companyName
        picture {
          small
        }
      }
      comments {
        id
        content {
          body
        }
        childs {
          id
          content {
            body
          }
          user {
            firstName
            lastName
            jobTitle
            picture {
              small
            }
          }
          publishedAt
        }
        user {
          firstName
          lastName
          picture {
            small
          }
        }
        publishedAt
      }
      attachments {
        type
        source
        caption
      }
      eventRef {
        id
        title
        summary
        location {
          name
        }
        startDate
        endDate
        email
        url
        maxAttendees
      }
      adviceRef {
        id
        publishedAt
        expertType {
          name
        }
        question
      }
      materialRef {
        id
        type
        title
        summary
        coverImage {
          source
          caption
        }
        content {
          type
          body
          source
          caption
        }
      }
    }
  }
`

export const POST = gql`
  mutation ($content: [HubContentInput]!, $attachments: [HubAttachmentInput]) {
    createPost(content: $content, attachments: $attachments) {
      id
    }
  }
`

export const ENDORSE = gql`
  mutation ($id: ID!, $isEndorsing: Boolean!) {
    endorsePost(id: $id, isEndorsing: $isEndorsing) {
      id
    }
  }
`

export const COMMENT = gql`
  mutation ($postId: ID!, $content: [HubContentInput]!) {
    createComment(postId: $postId, content: $content) {
      id
      anonymous
      content {
        body
      }
      user {
        firstName
        lastName
        jobTitle
        picture {
          small
        }
      }
      childs {
        id
        parentId
        content {
          body
        }
        user {
          firstName
          lastName
          picture {
            small
          }
        }
      }
    }
  }
`

export const REPLY = gql`
  mutation ($postId: ID!, $parentId: ID, $content: [HubContentInput]!) {
    createComment(postId: $postId, parentId: $parentId, content: $content) {
      id
      parentId
      anonymous
      content {
        body
      }
      user {
        firstName
        lastName
        jobTitle
        picture {
          small
        }
      }
    }
  }
`

export const REPORT_POST = gql`
  mutation ($id: ID!, $reason: String!) {
    reportPost(id: $id, reason: $reason) {
      id
      reason {
        key
      }
      userId
      postId
      reportedAt
      isReportedByMe
    }
  }
`
