import { connect } from "react-redux"
import { submit } from "../store/survey.actions"
import { getHasSubmitted, getIsSubmitting } from "../store/survey.selectors"

const mapState = state => ({
  isLoading: getIsSubmitting(state),
  isSubmitted: getHasSubmitted(state)
})

const mapDispatch = dispatch => ({
  onLoad: isSubmitted => !isSubmitted && dispatch(submit())
})

const submitterContainer = connect(mapState, mapDispatch)

export default submitterContainer
