import React from "react"
import { usePaths, Link } from "@engaging-tech/routing"
import { H3, Text, Button, Flex } from "@engaging-tech/components"

import Hero from "./Hero"

const GetStarted = () => {
  const paths = usePaths()

  return (
    <Hero py={[5, 6]} px={[3, 4]}>
      <H3
        fontWeight="600"
        color="dark.1"
        fontSize="24px"
        fontFamily="serif"
        textAlign="center"
        mb={3}
      >
        Get Started
      </H3>
      <Text color="dark.2" textAlign="center" display="block" mb={[3, 5]}>
        You can sign up here to access all our services
      </Text>
      <Flex justifyContent="center" width="auto">
        <Link to={paths.account.signUp}>
          <Button variant="primary.0">Sign up</Button>
        </Link>
      </Flex>
    </Hero>
  )
}

export default GetStarted
