import { Flex, Icon, Spinner, Text } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import EndorsementList from "./EndorsementList"

const InteractiveText = styled(Text)`
  cursor: pointer;
`

const InteractiveIcon = styled(Icon)`
  cursor: pointer;
`

const Endorsements = ({ endorsements, user, postId, isLoading, isAuthenticated, onToggle }) => {
  const router = useRouter()
  const paths = usePaths()

  const [hasEndorsed, setHasEndorsed] = useState(false)
  const [showEndorsements, setShowEndorsements] = useState(false)

  useEffect(() => {
    setHasEndorsed(!!endorsements.find(endorsement => endorsement.id === (user && user.id)))
  }, [endorsements, user])

  const getEndorsementText = length => `${length} ${length === 1 ? "endorsement" : "endorsements"}`

  const getProfile = () => ({
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    profile: {
      picture: {
        small: user.picture.small
      },
      jobTitle: user.jobTitle
    }
  })

  const handleToggle = () => {
    if (!isAuthenticated) {
      return router.navigate(paths.account.signIn)
    }

    if (isLoading) {
      return null
    }

    return onToggle({
      user: getProfile(),
      id: postId,
      isEndorsing: !hasEndorsed
    })
  }

  const handleToggleEndorsementList = () => {
    setShowEndorsements(!showEndorsements)
  }

  return (
    <>
      <Flex alignItems="center" width="max-content" minWidth="124px">
        {isLoading === postId ? (
          <Spinner color="primary.0" size={24} m="auto" />
        ) : (
          <>
            <InteractiveIcon
              m={0}
              name="brightness_high"
              mr={2}
              width="auto"
              onClick={handleToggle}
              color={hasEndorsed ? "primary.0" : "dark.2"}
              title={hasEndorsed ? "Unendorse" : "Endorse"}
            />
            <InteractiveText
              fontSize={2}
              color="dark.2"
              onClick={endorsements.length ? handleToggleEndorsementList : () => null}
            >
              {getEndorsementText(endorsements.length)}
            </InteractiveText>
          </>
        )}
      </Flex>
      {showEndorsements && <EndorsementList users={endorsements} onClose={handleToggleEndorsementList} />}
    </>
  )
}

export default Endorsements
