import { connect } from "react-redux"
import SideBarComponent from "../components/SideBar"

const SideBar = materialType => {
  const mapState = () => ({
    materialType
  })

  return connect(mapState)(SideBarComponent)
}
export default SideBar
