import React from "react"
import { useSelector } from "react-redux"
import { usePaths } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import { useTranslation } from "react-i18next"
import { happinessSurveySelectors } from "../../features/happinessSurvey"

import WelcomeCard from "../../features/happinessSurvey/containers/WelcomeSavanta"
import SurveyLayout from "../../layouts/SurveyLayout"
import Loader from "../../features/happinessSurvey/containers/Loader"
import CountriesLoader from "../../features/misc/containers/CountriesLoader"
import IndustriesLoader from "../../features/misc/containers/IndustriesLoader"
import SurveyJobRolesLoader from "../../features/misc/containers/SurveyJobRolesLoader"

const Welcome = () => {
  const paths = usePaths()
  const hasLoaded = useSelector(happinessSurveySelectors.getHasLoaded)
  const surveyId = getConfig().surveyIds.savantaWhs
  const { t } = useTranslation(["happiness_survey"])

  return (
    <SurveyLayout>
      <Loader surveyId={surveyId} />
      <CountriesLoader />
      <IndustriesLoader />
      <SurveyJobRolesLoader />
      {hasLoaded && (
        <WelcomeCard
          title={t("welcome_slide.title", "")}
          content={t("welcome_slide.content", { returnObjects: true }, "")}
          startRoute={paths.happinessSurvey.rankYourCompany}
          closeRoute={paths.home}
        />
      )}
    </SurveyLayout>
  )
}

export default Welcome
