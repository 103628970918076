import { types } from "./notifications.actions"

const DEFAULT_MODAL_TYPE = "manage"

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  hasError: false,
  alerts: {
    jobs: [],
    events: [],
    materials: []
  },
  alertSuccess: false,
  activeAlert: null,
  modalType: DEFAULT_MODAL_TYPE // 'manage', 'edit', 'delete'
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD:
    case types.CREATE_JOB_NOTIFICATION:
    case types.CREATE_EVENT_NOTIFICATION:
    case types.CREATE_MATERIAL_NOTIFICATION:
    case types.UPDATE_JOB_ALERT:
    case types.UPDATE_EVENT_ALERT:
    case types.UPDATE_MATERIAL_ALERT:
    case types.DELETE_ALERT:
      return {
        ...state,
        isLoading: true
      }

    case types.LOAD_SUCCESS:
      return {
        ...state,
        alerts: action.payload,
        isLoading: false,
        hasLoaded: true
      }

    case types.SET_MODAL_TYPE:
      return {
        ...state,
        modalType: action.payload
      }

    case types.LOAD_FAIL:
      return {
        ...state,
        isLoading: false,
        hasLoaded: true
      }

    case types.NOTIFICATION_FAIL:
      return {
        ...state,
        isLoading: false,
        hasLoaded: true,
        alertSuccess: false,
        latestAlert: null,
        hasError: action.payload
      }

    case types.UPDATE_JOB_ALERT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        modalType: DEFAULT_MODAL_TYPE,
        alerts: {
          ...state.alerts,
          jobs: state.alerts.jobs.map(job => (job.id === action.payload.id ? action.payload : job))
        }
      }
    case types.UPDATE_EVENT_ALERT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        modalType: DEFAULT_MODAL_TYPE,
        alerts: {
          ...state.alerts,
          events: state.alerts.events.map(event => (event.id === action.payload.id ? action.payload : event))
        }
      }
    case types.UPDATE_MATERIAL_ALERT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        modalType: DEFAULT_MODAL_TYPE,
        alerts: {
          ...state.alerts,
          materials: state.alerts.materials.map(material =>
            material.id === action.payload.id ? action.payload : material
          )
        }
      }

    case types.CREATE_JOB_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        hasError: false,
        alerts: {
          ...state?.alerts,
          jobs: [...state.alerts.jobs, action.payload]
        },
        alertSuccess: true,
        latestAlert: action.payload
      }
    }

    case types.CREATE_EVENT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        alerts: {
          ...state.alerts,
          events: [...state.alerts.events, action.payload]
        },
        alertSuccess: true,
        latestAlert: action.payload
      }
    case types.CREATE_MATERIAL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        alerts: {
          ...state.alerts,
          materials: [...state.alerts.materials, action.payload]
        },
        alertSuccess: true,
        latestAlert: action.payload
      }

    case types.TOGGLE:
      return {
        ...state,
        hasError: false,
        alertSuccess: false,
        latestAlert: null
      }

    case types.SET_ACTIVE_ALERT:
      return {
        ...state,
        activeAlert: action.payload
      }

    case types.DELETE_ALERT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        activeAlert: null,
        modalType: DEFAULT_MODAL_TYPE,
        alerts: {
          ...state.alerts,
          [action.payload.type]: [
            ...state.alerts[action.payload.type].filter(alert => alert.id !== action.payload.id)
          ]
        }
      }

    default:
      return state
  }
}
