import React, { useState } from "react"

import Question from "../containers/Question"
import { useQuestionContext } from "../lib/QuestionContext"
import CustomIntro from "./CustomIntro"

const Section = ({ name, description, questions }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const { onNextSection, onPreviousSection } = useQuestionContext()
  let questionList

  const onComplete = () => {
    onNextSection()
  }

  const onCancel = () => {
    onPreviousSection()
  }

  const onNextQuestion = () =>
    currentQuestion < questionList.length - 1 ? setCurrentQuestion(currentQuestion + 1) : onComplete()

  const onPreviousQuestion = () => {
    if (currentQuestion) {
      setCurrentQuestion(currentQuestion - 1)
    } else {
      onCancel()
    }
  }

  const generateQuestions = () =>
    questions.map(questionId => (
      <Question
        currentQuestion={currentQuestion}
        questionId={questionId}
        questionCount={questions?.length}
        sectionTitle={name}
        onNext={onNextQuestion}
        onPrevious={onPreviousQuestion}
        key={questionId}
      />
    ))

  questionList = description.length
    ? [
        <CustomIntro
          title={name}
          content={description}
          onNext={onNextQuestion}
          onPrevious={onPreviousQuestion}
        />,
        ...generateQuestions()
      ]
    : generateQuestions()

  return questionList[currentQuestion]
}

export default Section
