import { Flex } from "@engaging-tech/components"
import React from "react"

import Pillar from "./Pillar"

const Pillars = ({ pillars }) => (
  <Flex flexWrap="wrap" alignItems="stretch" justifyContent="space-between">
    {pillars?.map((pillar, index) => (
      <Pillar key={pillar.title} {...pillar} index={index} length={pillars?.length} flex={1} height="100%" />
    ))}
  </Flex>
)

export default React.memo(Pillars)
