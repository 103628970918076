import React from "react"
import styled from "styled-components"
import { Text } from "@engaging-tech/components"

const ReadMoreText = styled(Text)`
  cursor: pointer;
`

const ReadMore = ({ onClick }) => (
  <ReadMoreText
    display="block"
    color="primary.0"
    fontWeight="500"
    fontSize={[4, 3]}
    mb={2}
    onClick={onClick}
  >
    Read More
  </ReadMoreText>
)

export default ReadMore
