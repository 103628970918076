import React from "react"
import { Helmet } from "react-helmet"

const SEO = ({ title, keywords, url, image }) => (
  <Helmet>
    <title>WorkL | {title}</title>

    <meta property="description" content={title} />
    {keywords ? <meta property="keywords" content={keywords} /> : null}
    <meta property="DC.title" content={title} />

    <meta property="og:title" content={title} />
    {url ? <meta property="og:url" content={url} /> : null}
    {image ? <meta property="og:image" content={image} /> : null}
  </Helmet>
)

export default SEO
