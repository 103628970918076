/* eslint-disable no-underscore-dangle */
import { Button, Flex, H4, Text } from "@engaging-tech/components"
import { titleCaps } from "@engaging-tech/frontend-utils"
import React from "react"

import ClickableIcon from "../../../ui/components/ClickableIcon"
import Separator from "../../../ui/components/Separator"

const determineAlertName = alert => {
  if (alert.__typename === "JobAlert") return alert?.jobRole || "Job Alert"
  if (alert.__typename === "EventAlert") return alert?.location?.name || "Event Alert"
  if (alert.__typename === "MaterialAlert") {
    if (alert?.category?.name && alert?.materialType)
      return `${alert.category.name} ${titleCaps(alert.materialType)}s`
    if (alert?.category?.name) return `${alert.category.name}`
    if (alert?.materialType)
      return `${titleCaps(alert.materialType)}${alert.materialType === "research" ? "" : "s"}`
    return "Material Alert"
  }

  return "this notification"
}

const mapTypenames = {
  JobAlert: "jobs",
  EventAlert: "events",
  MaterialAlert: "materials"
}

const DeleteAlert = ({ setModalType, activeAlert, deleteAlert }) => {
  const { id, __typename } = activeAlert

  const handleDelete = () => {
    deleteAlert(id, mapTypenames[__typename])
  }

  return (
    <Flex width={1 / 1} flexDirection="column">
      <Flex width={1 / 1} justifyContent="space-between" my={2}>
        <H4
          width={1 / 1}
          textAlign="flex-start"
          color="dark.1"
          fontFamily="Tiempos"
          fontWeight={800}
          fontSize={5}
        >
          Delete Alert
        </H4>
        <ClickableIcon name="close" onClick={() => setModalType("manage")} />
      </Flex>
      <Separator width={1 / 1} lineColor="dark.5" my={3} />
      <Text fontSize={4} color="dark.1">
        You are about to delete the alert for “{determineAlertName(activeAlert)}
        ”. This cannot be undone.
      </Text>
      <Separator width={1 / 1} lineColor="dark.5" my={3} />
      <Flex flexDirection="row" width={1 / 1} height="100%" justifyContent="space-between">
        <Button
          elevation={0}
          variant="outline.primary.0"
          bg="light.0"
          onClick={() => setModalType("manage")}
          px={3}
        >
          Cancel
        </Button>
        <Button bg="secondary.0" onClick={handleDelete} px={3}>
          Delete
        </Button>
      </Flex>
    </Flex>
  )
}

export default DeleteAlert
