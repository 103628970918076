import { Box } from "@engaging-tech/components"
import { usePaths } from "@engaging-tech/routing"
import React from "react"
import { useSelector } from "react-redux"

import CompanyInformationButton from "../../../../components/companyInformationButton/companyInformationButton"
import Kpis from "../../../../components/jobRankings/kpis"
import { getJobRanking } from "../../../../store/jobFinder.selectors"
import { parseJobLocationAndSalary, parseJobTypeAndPostedDate } from "../../utils"
import { ButtonWrapper, JobSubtitle } from "../jobDetail.styled"
import {
  ApplyButton,
  ApplyIcon,
  Header,
  JobLocationAndSalary,
  JobTitle,
  JobTitleContainer,
  JobTitleWrapper,
  JobTypeAndPostDate
} from "./jobDetailHeader.styled"
import JobShare from "./jobShare"

const JobDetailHeader = ({ job }) => {
  const paths = usePaths()

  const jobRanking = useSelector(getJobRanking(job.company_name_key))

  const onGoToDetails = jobId => {
    window.open(`${paths.findAJob.jobFinder.index}/${jobId}`, "_blank", "noreferrer")
  }

  return (
    <Header>
      <JobTitleWrapper>
        <JobTitleContainer>
          <JobTitle onClick={() => onGoToDetails(job.job_id)}>{job.title}</JobTitle>
          <JobShare />
        </JobTitleContainer>
        <JobSubtitle>{job.company}</JobSubtitle>
        <JobLocationAndSalary>
          {parseJobLocationAndSalary({
            jobLocation: job.location,
            jobMinSalary: job.min_salary,
            jobMaxSalary: job.max_salary
          })}
        </JobLocationAndSalary>
        <JobTypeAndPostDate>
          {parseJobTypeAndPostedDate({ jobType: job.job_type, jobAge: job.age })}
        </JobTypeAndPostDate>

        <Box mt="9px">
          <Kpis companyScore={jobRanking?.score} />
          {jobRanking ? <CompanyInformationButton companyNameKey={job.company_name_key} /> : null}
        </Box>
      </JobTitleWrapper>

      <ButtonWrapper>
        <ApplyButton
          color="white"
          onClick={() => {
            if (typeof window !== "undefined" && window.gtag) {
              window.gtag("event", "JOB_APPLY_CLICK", {
                event_category: "page_view",
                event_label: "Apply to a job",
                job_title: job.title,
                job_company: job.company,
                job_location: job.location
              })
            }

            window.open(job.url, "_blank")
          }}
        >
          Apply Now
          <ApplyIcon />
        </ApplyButton>
      </ButtonWrapper>
    </Header>
  )
}

export default JobDetailHeader
