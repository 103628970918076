import { useEffect } from "react"
import { attachUser } from "../store/survey.actions"
import attachUserContainer from "../containers/attachUser.container"

const AttachUserLoader = ({
  onAttachUser,
  hasAttachedUser,
  surveySubmissionId,
  isPrivate
}) => {
  useEffect(() => {
    if (!hasAttachedUser && surveySubmissionId && !isPrivate) {
      attachUser(surveySubmissionId)
    }
  }, [onAttachUser, hasAttachedUser, isPrivate, surveySubmissionId])

  return null
}

export default attachUserContainer(AttachUserLoader)
