import { useParams, usePaths } from "@engaging-tech/routing"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import SelectedCVLoader from "../../features/cvLibrary/containers/SelectedCVLoader"
import SelectedCVView from "../../features/cvLibrary/views/SelectedCV"
import { loadHobbies, loadSkills } from "../../features/misc/store/misc.actions"
import DetailsLayout from "../../layouts/DetailsLayout"

const SelectedCV = () => {
  const { id } = useParams()
  const paths = usePaths()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadSkills())
    dispatch(loadHobbies())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DetailsLayout backTo={paths.careerDeveloper.cvLibrary.index} actionName="BACK">
      <SelectedCVLoader id={id} />
      <SelectedCVView />
    </DetailsLayout>
  )
}

export default SelectedCV
