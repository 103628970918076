import { Button, Flex, MotionFlex, Text, Toggle } from "@engaging-tech/components"
import { titleCaps } from "@engaging-tech/frontend-utils"
import { AnimatePresence } from "framer-motion"
import React from "react"
import { connect } from "react-redux"
import * as Yup from "yup"

import { toggleServices } from "../../../yourProfile/store/yourProfile.actions"
import { getServices } from "../../../yourProfile/store/yourProfile.selectors"

const icon = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/thumb.png`)

const info = {
  icon,
  title: "You're all set!",
  submitData: true
}

const validationSchema = Yup.object({
  desiredJobLocation: Yup.object()
    .shape({
      name: Yup.string()
    })
    .nullable()
})

const FooterOverrideComponent = ({
  handleBack,
  formInvalid,
  formik,
  handleNext,
  handleNextTouch,
  jobMatcherService
}) => (
  <Flex width={1 / 1} justifyContent="space-between" alignItems="center" alignContent="center" mt={4}>
    {jobMatcherService === "ON" && (
      <Button
        elevation={0}
        width={125}
        bg="transparent"
        onClick={() => handleBack({ values: formik.values, goToStep: 0 })}
      >
        <Text fontWeight={700} color="primary.0" fontSize={3}>
          EDIT PREFENCES
        </Text>
      </Button>
    )}

    {jobMatcherService === "OFF" && (
      <Button
        elevation={0}
        width={100}
        bg="transparent"
        onClick={() => handleBack({ values: formik.values })}
      >
        <Text fontWeight={700} color="primary.0" fontSize={3}>
          Back
        </Text>
      </Button>
    )}

    <Button
      elevation={0}
      bg={formInvalid ? "dark.5" : "secondary.0"}
      width={150}
      onClick={formInvalid ? handleNextTouch : () => handleNext({ values: formik.values })}
    >
      <Text fontWeight={700} color={formInvalid ? "dark.2" : "primary.0"} fontSize={3}>
        Done
      </Text>
    </Button>
  </Flex>
)

const FeatureToggleTemplateComponent = ({ formik, jobMatcherService, updateService }) => {
  const profileInfomationPanelVariants = {
    hidden: {
      height: 0,
      transition: {
        when: "afterChildren"
      },
      transitionEnd: {
        display: "none"
      }
    },
    visable: {
      height: 160,
      display: "flex",
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.3
      }
    }
  }

  const profileInfomationPanelInnerVariants = {
    hidden: {
      opacity: 0,
      transitionEnd: {
        display: "none"
      }
    },
    visable: {
      opacity: 1,
      display: "flex"
    }
  }

  const generateCurentRoleString = () => {
    if (formik?.values?.currentJobRole && formik?.values?.currentJobSalary) {
      return `${titleCaps(formik?.values?.currentJobRole)} (£${formik?.values?.currentJobSalary})`
    }

    if (formik?.values?.currentJobSalary) {
      return `Unknown Current Job Role - Salary £${formik?.values?.currentJobSalary}`
    }

    if (formik?.values?.currentJobRole) {
      return titleCaps(formik?.values?.currentJobRole)
    }

    return "No details available"
  }

  const generateDesiredJobRolesList = roleArray =>
    roleArray.reduce((outputString, role, index) => {
      if (index === roleArray.length - 1) {
        return outputString + titleCaps(role)
      }

      return `${outputString + titleCaps(role)} / `
    }, "")

  const generateDesiredRoleString = () => {
    if (
      formik?.values?.desiredJobRoles &&
      formik?.values?.desiredJobType &&
      formik?.values?.desiredJobSalary
    ) {
      return `${generateDesiredJobRolesList(formik.values.desiredJobRoles)}, ${titleCaps(
        formik?.values?.desiredJobType
      )} (£${formik?.values.desiredJobSalary.min} - £${formik?.values?.desiredJobSalary.max})`
    }

    return "No details available"
  }

  const generateDesiredLocationString = () => formik.values.desiredJobLocation?.name || "No details available"

  return (
    <Flex width={1 / 1} flexDirection="column" alignItems="center" alignContent="center">
      <Text textAlign="center">
        {formik?.values?.enabled ? "You have turned on job matching" : "You have turned off job matching"}
      </Text>
      <Flex flexDirection="row" alignItems="center" alignContent="center" justifyContent="center" mt={3}>
        <Text color="primary.0" width="auto" fontWeight={700} fontSize={3} mr={3}>
          {formik?.values?.enabled
            ? "Turn off Job Matcher"
            : "Want to turn on your job search? Just turn on matching!"}
        </Text>
        <Toggle
          id="toggle-job-matcher"
          onChange={updateService}
          size={40}
          initialState={jobMatcherService === "ON"}
          onColour="secondary.0"
          offColour="light.0"
          borderColor="primary.0"
        />
      </Flex>
      <AnimatePresence>
        {jobMatcherService === "ON" && (
          <MotionFlex
            width={1 / 1}
            flexDirection="column"
            alignItems="center"
            alignContent="center"
            mt={2}
            initial="hidden"
            animate="visable"
            exit="hidden"
            pt={2}
            variants={profileInfomationPanelVariants}
          >
            <MotionFlex
              width={1 / 1}
              flexDirection="column"
              alignItems="center"
              alignContent="center"
              variants={profileInfomationPanelInnerVariants}
            >
              <Text textAlign="center" m={1}>
                {"Current Role: "}
                <Text fontWeight={700}>{generateCurentRoleString()}</Text>
              </Text>
              <Text textAlign="center" m={1}>
                {formik?.values?.desiredJobRoles?.length > 1 ? "Desired Roles: " : "Desired Role: "}
                <Text fontWeight={700}>{generateDesiredRoleString()}</Text>
              </Text>
              <Text textAlign="center" m={1}>
                {"Desired Location: "}
                <Text fontWeight={700}>{generateDesiredLocationString()}</Text>
              </Text>
              <Text textAlign="center" m={1} mt={3}>
                We will be in contact with you when you get matched with a job!
              </Text>
            </MotionFlex>
          </MotionFlex>
        )}
      </AnimatePresence>
    </Flex>
  )
}

const mapState = state => ({
  jobMatcherService: getServices(state)?.jobMatcher
})

const mapDispatch = dispatch => ({
  updateService: value => dispatch(toggleServices({ jobMatcher: value ? "ON" : "OFF" }))
})

const FeatureToggleTemplate = connect(mapState, mapDispatch)(FeatureToggleTemplateComponent)

const FooterOverride = connect(mapState)(FooterOverrideComponent)

export default {
  component: FeatureToggleTemplate,
  validationSchema,
  info,
  FooterOverride
}
