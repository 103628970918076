import React from "react"

import { libraryPostTypes } from "../../features/userGeneratedContent/lib/postTypes"

import AuthGuard from "../../features/ui/containers/AuthGuard"
import BookmarkedMaterials from "../../features/myLibrary/containers/BookmarkedMaterials"
import BackBar from "../../features/ui/components/Backbar"
import PageLayout from "../../layouts/PageLayout"

const BookmarkedArticles = BookmarkedMaterials(libraryPostTypes.articles)

const Articles = () => (
  <AuthGuard>
    <PageLayout
      bg="dark.7"
      banner={() => (
          <BackBar />
      )}
    >
      <BookmarkedArticles />
    </PageLayout>
  </AuthGuard>
)

export default Articles
