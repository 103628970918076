import { Flex, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React from "react"
import styled from "styled-components"
import SiteLogo from "../features/ui/components/SiteLogo"
import TestingSiteBanner from "../features/ui/components/TestingSiteBanner"
import gradientCss from "../helpers/gradientCss"

const GradientBox = styled(Flex)`
  ${({ theme }) => gradientCss(theme)}
`

const CompanyLayout = ({ children, banner: Banner, ...props }) => (
  <>
    <TestingSiteBanner />

    <Flex p={3} justifyContent="space-between">
      <SiteLogo />
      <Link to="https://www.managers.org.uk/" external newTab>
        {" "}
        <Text fontWeight={500} mt={1}>
          CMI
        </Text>
      </Link>
    </Flex>
    <GradientBox as="main" flexDirection="column" minHeight="100vh">
      {Banner && <Banner />}
      <Flex
        width={1 / 1}
        flex="1"
        alignItems="center"
        justifyContent="center"
        {...props}
      >
        {children}
      </Flex>
    </GradientBox>
  </>
)

export default CompanyLayout
