import { Box, Card, Flex, Paragraph, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React from "react"
import styled from "styled-components"

import { isValidEmail, isValidHttpUrl } from "../../../lib/utils"
import BodyWrapper from "../../BodyWrapper"
import CommentWrapper from "../../CommentWrapper/CommentWrapper"
import Interactions from "../../Interactions/Interactions"

const HeaderText = styled(Text)`
  text-transform: capitalize;
`

const HeaderWrapper = styled(Box)`
  border-radius: 4px 4px 0 0;
`

const OverFlowParagraph = styled(Paragraph)`
  overflow-wrap: break-word;
`

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const SummaryHeader = () => (
  <HeaderWrapper bg="#90FCF9" py={2} px={3}>
    <HeaderText fontWeight="500">Event</HeaderText>
  </HeaderWrapper>
)

const EventDates = ({ startDate, endDate }) => {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  }
  const sDate = new Date(startDate)
  const eDate = new Date(endDate)
  const startDateString = sDate.toLocaleDateString("en-GB", options).replace(",", "")
  const endDateString = eDate.toLocaleDateString("en-GB", options).replace(",", "")

  return (
    <Text color="dark.1" fontSize={3}>
      {startDateString} - {endDateString}
    </Text>
  )
}

const AdviceSummary = ({
  user,
  post,
  publishedAt,
  interactions: RenderShare,
  userDetails: UserDetails,
  postInteractions: RenderInteractions,
  postComments: RenderComments,
  commentForm: RenderCommentForm
}) => {
  const hasValidEmail = post?.email ? isValidEmail(post.email) : false
  const hasValidURL = post?.url ? isValidHttpUrl(post.url) : false
  return (
    <Card width={1 / 1} bg="light.0" position="relative" mb={[3, 5, 5]}>
      <SummaryHeader />
      <BodyWrapper p={3} mb={3}>
        {UserDetails && (
          <UserDetails
            userHoldingImage={profilePlaceholder}
            interactions={RenderShare}
            publishedAt={publishedAt}
            user={user}
          />
        )}
        <OverFlowParagraph letterSpacing="0.02rem" color="dark.0" fontSize={3} fontWeight={500}>
          {post.title}
        </OverFlowParagraph>
        <Paragraph width="100%" letterSpacing="0.02rem" color="dark.2" fontSize={3}>
          {post.summary}
        </Paragraph>
        <Paragraph fontWeight={500} letterSpacing="0.02rem" color="dark.2" fontSize={3}>
          <Flex flexDirection="column">
            <Box>
              <Text>RSVP: </Text>
              {hasValidEmail && (
                <Text color="primary.0">
                  <a href={`mailto:${post.email}`}>{post.email}</a>
                </Text>
              )}
            </Box>

            {post?.maxAttendees ? (
              <Text color="primary.0" fontSize={2}>
                Max attendees: {post.maxAttendees}
              </Text>
            ) : null}
          </Flex>
        </Paragraph>
        {hasValidURL && (
          <Paragraph fontWeight={500} xletterSpacing="0.02rem" color="primary.0" fontSize={3}>
            <Link to={post.url} external newTab>
              <Text color="primary.0" style={{ wordBreak: "break-all" }}>
                {post.url}
              </Text>
            </Link>
          </Paragraph>
        )}
        {post.location && post.location.name && (
          <Paragraph letterSpacing="0.02rem" color="dark.1" fontSize={3}>
            {post.location.name}
          </Paragraph>
        )}
        <EventDates startDate={post.startDate} endDate={post.endDate} />
      </BodyWrapper>
      <Interactions>{RenderInteractions && <RenderInteractions />}</Interactions>
      <CommentWrapper>{RenderComments && <RenderComments />}</CommentWrapper>
      <RenderCommentForm />
    </Card>
  )
}

export default AdviceSummary
