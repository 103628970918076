export const types = {
  LOAD_SURVEYS: "[Develop Career] Load Surveys",
  UPDATE_SURVEYS: "[Develop Career] Update Surveys",
  LOAD_ALL_WIDGETS: "[Develop Career] Load All Widgets",
  UPDATE_ALL_WIDGETS: "[Develop Career] Update All Widgets",
  LOAD_WIDGET_LAYOUT: "[Develop Career] Load Widget Layout",
  UPDATE_WIDGET_LAYOUT: "[Develop Career] Update Widget Layout",
  ADD_WIDGETS: "[Develop Career] Add Widgets to Layout",
  REMOVE_WIDGET: "[Develop Career] Remove Widget from layout",
  UPDATE_GOALS_VIEWED:
    "[Develop Career] Update the status of goals being viewed",
  LOAD_ALL_GOALS: "[Develop Career] Load All Goals",
  UPDATE_ALL_GOALS: "[Develop Career] Update All Goals",
  LOCAL_LAYOUT_CHANGE:
    "[Develop Career] Update local widget layout after widget resize/drag",
  POST_LAYOUT:
    "[Develop Career] Post the local layout stored within redux to the backend",
  CLOSE_OPTION_BOXES: "[Develop Career] Closes all widget option boxes",
  LOAD_CONTENT_FOR_YOU: "[Develop Career] Loads posts for 'Content For You' widget",
  UPDATE_CONTENT_FOR_YOU: "[Develop Career] Updates posts for 'Content For You' widget",
  UPDATE_GRID_CUSTOMISATION_FLAG: "[Develop Career] Updates grid customisation flag"
}

export const loadAllGoals = payload => ({
  type: types.LOAD_ALL_GOALS,
  payload
})

export const updateAllGoals = ({
  payload = null,
  error = null,
  loading = null
}) => ({
  type: types.UPDATE_ALL_GOALS,
  payload,
  error,
  loading
})

export const addWidgets = (payload, useLocalWidgetLayout = false) => ({
  type: types.ADD_WIDGETS,
  payload,
  useLocalWidgetLayout
})

export const removeWidget = payload => ({
  type: types.REMOVE_WIDGET,
  payload
})

export const loadSurveys = payload => ({
  type: types.LOAD_SURVEYS,
  payload
})

export const updateSurveys = ({
  payload = null,
  error = null,
  loading = null
}) => ({
  type: types.UPDATE_SURVEYS,
  payload,
  error,
  loading
})

export const loadAllWidgets = () => ({
  type: types.LOAD_ALL_WIDGETS
})

export const updateAllWidgets = ({
  payload = null,
  error = null,
  loading = null
}) => ({
  type: types.UPDATE_ALL_WIDGETS,
  payload,
  error,
  loading
})

export const loadWidgetLayout = () => ({
  type: types.LOAD_WIDGET_LAYOUT
})

export const closeOptionBoxes = () => ({
  type: types.CLOSE_OPTION_BOXES
})

export const updateWidgetLayout = ({
  payload = null,
  error = null,
  loading = null
}) => ({
  type: types.UPDATE_WIDGET_LAYOUT,
  payload,
  error,
  loading
})


export const loadContentForYou = () => ({
  type: types.LOAD_CONTENT_FOR_YOU
})

export const updateContentForYou = ({
  payload = null,
  error = null,
  loading = null
}) => ({
  type: types.UPDATE_CONTENT_FOR_YOU,
  payload,
  error,
  loading
})

export const localLayoutChange = payload => ({
  type: types.LOCAL_LAYOUT_CHANGE,
  payload
})

export const postLayout = () => ({
  type: types.POST_LAYOUT
})

export const updateGridCustomisationFlag = payload => ({
  type: types.UPDATE_GRID_CUSTOMISATION_FLAG,
  payload
})
