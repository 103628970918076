import React from "react"
import { Text, Flex, MotionFlex } from "@engaging-tech/components"
import styled from "styled-components"
import PrimaryButton from "../../ui/components/PrimaryButton"
import TextButton from "../../ui/components/TextButton"
import CircleIcon from "../../ui/components/CircleIcon"

const PlaceHolderProfileImage = ({ userProfile }) => {
  const getFirstLetter = string => string.slice(0, 1)
  return (
    <Flex
      width={130}
      height={130}
      borderRadius="50%"
      justifyContent="center"
      alignItems="center"
      bg="#A5D2FF"
    >
      <Text width="auto" fontSize={55} color="#1087FD">
        {getFirstLetter(userProfile.firstName)}
        {getFirstLetter(userProfile.lastName)}
      </Text>
    </Flex>
  )
}

const ProfileImage = styled.img`
  object-fit: cover;
  border-radius: 50%;
  width: 130px;
`

const ProfilePicture = ({ userProfile }) => {
  return (
    <Flex
      width={160}
      height={160}
      justifyContent="center"
      alignItems="center"
      style={{ position: "relative" }}
    >
      {userProfile?.picture?.large || userProfile?.picture?.small ? (
        <ProfileImage
          alt="Profile image"
          src={userProfile?.picture?.large || userProfile?.picture?.small}
        />
      ) : (
        <PlaceHolderProfileImage userProfile={userProfile} />
      )}
      <MotionFlex
        style={{ position: "absolute", right: "10px", bottom: "10px" }}
        width="auto"
        height="auto"
        initial={{ opacity: 0 }}
        animate={{ scale: [0.9, 1.1, 0.9], opacity: 1 }}
        transition={{
          scale: {
            repeatType: "loop",
            repeat: Infinity,
            ease: "easeInOut",
            duration: 3,
            delay: 0.1
          },
          delay: 1.4,
          duration: 0.6
        }}
      >
        <CircleIcon
          name="gradhat"
          bg="primary.0"
          color="secondary.0"
          size={35}
        />
      </MotionFlex>
      <MotionFlex
        style={{ position: "absolute", left: "4px", top: "50px" }}
        width="auto"
        initial={{ opacity: 0 }}
        animate={{ scale: [0.9, 1.1, 0.9], opacity: 1 }}
        transition={{
          scale: {
            repeatType: "loop",
            repeat: Infinity,
            ease: "easeInOut",
            duration: 3,
            delay: 0.5
          },
          delay: 1,
          duration: 0.6
        }}
        height="auto"
      >
        <CircleIcon
          name="show_chart"
          bg="primary.0"
          color="secondary.0"
          size={30}
        />
      </MotionFlex>
      <MotionFlex
        style={{ position: "absolute", right: "4px", top: "35px" }}
        width="auto"
        initial={{ opacity: 0 }}
        animate={{ scale: [0.9, 1.1, 0.9], opacity: 1 }}
        transition={{
          scale: {
            repeatType: "loop",
            repeat: Infinity,
            ease: "easeInOut",
            duration: 3,
            delay: 0.3
          },
          delay: 0.8,
          duration: 0.6
        }}
        height="auto"
      >
        <CircleIcon name="poll" bg="primary.0" color="secondary.0" size={27} />
      </MotionFlex>
    </Flex>
  )
}

const WelcomeScreen = ({
  userProfile,
  seenBefore,
  setWelcomeShowing,
  toggle,
  updateSeenBefore
}) => (
  <Flex
    flexDirection="column"
    width="100%"
    height="100%"
    justifyContent="space-between"
  >
    <Flex
      py={[3, 4]}
      width={1 / 1}
      bg="tertiary.0"
      flexDirection="column"
      alignItems="center"
    >
      <ProfilePicture userProfile={userProfile} />

      <Text
        width={1 / 1}
        mt={3}
        textAlign="center"
        fontSize={5}
        color="primary.0"
        fontWeight={600}
      >
        Hello {userProfile?.firstName}!
      </Text>
    </Flex>
    <Flex
      width={1 / 1}
      p={[3, 4]}
      bg="light.0"
      flexDirection="column"
      alignItems="center"
    >
      <Text fontSize={[4, 5]} mb={3}>
        Welcome to your Career Developer
      </Text>
      <Text fontSize={[2, 4]} mb={3} color="dark.2">
        Here, you can <strong>set your goals</strong> to help you advance your
        career, <strong>find job opportunities</strong>,{" "}
        <strong>improve your skills</strong>,{" "}
        <strong>find or become a mentor</strong>,{" "}
        <strong>update your CV</strong> and more.
      </Text>
      <Text fontSize={[2, 4]} mb={4} color="dark.2">
        To get started click Pick My Goals below or click Dismiss to go straight
        to your profile.
      </Text>
    </Flex>
    <Flex width={1 / 1} justifyContent="space-between" p={[3, 4]}>
      <TextButton
        onClick={seenBefore ? () => toggle() : () => updateSeenBefore(true)}
      >
        DISMISS
      </TextButton>

      <PrimaryButton width="auto" onClick={() => setWelcomeShowing(false)}>
        PICK MY GOALS
      </PrimaryButton>
    </Flex>
  </Flex>
)
export default WelcomeScreen
