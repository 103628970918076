export const types = {
  LOAD: "[My Library] Load",
  LOAD_SUCCESS: "[My Library] Load Success",
  LOAD_FAIL: "[My Library] Load Fail",
  SELECT: "[My Library] Select",
  SELECT_SUCCESS: "[My Library] Select Success",
  SELECT_FAIL: "[My Library] Select Fail",
  RESET: "[My Library] Reset",
  DOWNLOAD_EBOOK: "[My Library] Download eBook",
  DOWNLOAD_AUDIO_BOOK: "[My Library] Download audioBook"
}

export const load = postType => ({
  type: types.LOAD,
  payload: postType
})

export const loadSuccess = posts => ({
  type: types.LOAD_SUCCESS,
  payload: posts
})

export const loadFail = () => ({
  type: types.LOAD_FAIL
})

export const select = postId => ({
  type: types.SELECT,
  payload: postId
})

export const selectSuccess = post => ({
  type: types.SELECT_SUCCESS,
  payload: post
})

export const selectFail = () => ({
  type: types.SELECT_FAIL
})

export const reset = () => ({
  type: types.RESET
})

export const downloadEBook = () => ({
  type: types.DOWNLOAD_EBOOK
})

export const downloadAudioBook = () => ({
  type: types.DOWNLOAD_AUDIO_BOOK
})
