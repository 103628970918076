import React from "react"
import { Text } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import { Link } from "@engaging-tech/routing"
import { useTranslation } from "react-i18next"

const toBusinessLibrary = () => `${getConfig().contentUrls.businessLibrary}`
const toPodcasts = () => `${getConfig().contentUrls.podcasts}`
const toSixSteps = () =>
  `${
    getConfig().contentUrls.businessLibrary
  }/browse/articles/5d15edf9dcb3ad8a5440ed1f`

const SixStepsLabel = () => {
  const { t } = useTranslation(["wellbeing_results"])
  return (
    <Text fontSize={3}>
      {t("six_steps.read_more.0", "")}{" "}
      <Link color="primary.0" to={toSixSteps()}>
        {t("six_steps.read_more.1", "")}
      </Link>{" "}
      {t("six_steps.read_more.2", "")}{" "}
      <Link color="primary.0" to={toPodcasts()}>
        {t("six_steps.read_more.3", "")}
      </Link>{" "}
      {t("six_steps.read_more.4", "")}{" "}
      <Link color="primary.0" to={toBusinessLibrary()}>
        {t("six_steps.read_more.5", "")}
      </Link>
      .
    </Text>
  )
}

export default SixStepsLabel
