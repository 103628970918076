import React, { useState } from "react"
import { Text, Flex, CustomHooks, MotionFlex } from "@engaging-tech/components"
import { AnimatePresence } from "framer-motion"

import { Link, usePaths } from "@engaging-tech/routing"
import HomepageBlock from "../../ui/components/HomepageBlock"
import sixSteps from "../lib/sixSteps"
import IconCircle from "../../ui/components/CircleIcon"

const SixSteps = () => {
  const [stepToShow, setStepToShow] = useState(sixSteps[0])
  const [iconHighlight, setIconHighlight] = useState(false)
  const device = CustomHooks.useClientDevice()
  const paths = usePaths()
  const textSplitRequired = stepData =>
    stepData.placeholder && stepData.text.includes(stepData.placeholder.key)

  const determineText = stepData =>
    textSplitRequired(stepData)
      ? stepData.text.split(stepData.placeholder.key).map((textFragment, i) =>
          i === 1 ? (
            <Link
              to={`${paths.resourceCentre.browse.books.index}${stepData.placeholder.url}`}
              width="auto"
              key={`${paths.resourceCentre.browse.books.index}${stepData.placeholder.url}`}
            >
              <strong>{textFragment}</strong>
            </Link>
          ) : (
            textFragment
          )
        )
      : stepData.text

  const iconCircleVariants = {
    unActive: {
      scale: 1,
      rotate: 0
    },
    active: {
      scale: 1.1,
      rotate: [0, 20, -20],
      transition: {
        rotate: {
          repeat: Infinity,
          repeatType: "reverse"
        }
      }
    },
    highlighted: index => ({
      scale: [1, 1.2, 1],
      transition: {
        delay: index * 0.1
      }
    })
  }

  const iconCircleBorderVariants = {
    hidden: {
      scale: 0,
      opacity: 0
    },
    unActive: {
      scale: 1,
      opacity: 1
    },
    highlighted: index => ({
      scale: [1, 1.2, 1],
      opacity: 1,
      transition: {
        scale: {
          delay: index * 0.1 + 0.05
        }
      }
    })
  }

  const stepTextVariants = {
    hidden: {
      opacity: 0,
      x: -200
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        opacity: {
          duration: 0.2
        }
      }
    },
    exitHidden: {
      opacity: 0,
      x: 200,
      transition: {
        opacity: {
          duration: 1
        }
      }
    }
  }

  CustomHooks.useInterval(() => {
    setIconHighlight(!iconHighlight)
  }, 3000)

  return (
    <HomepageBlock bg="light.0">
      <Flex flexDirection="column" justifyContent="center" width={1 / 1}>
        <Text
          fontFamily="serif"
          textAlign="center"
          mb={5}
          fontSize={[7]}
          fontWeight="600"
          color="primary.0"
        >
          Our Six Steps Framework
        </Text>
        <MotionFlex
          layout
          initial={{
            height: (() => {
              const heightArray = sixSteps[0].height
              if (device === "MOBILE") {
                return heightArray[0]
              }
              if (device === "TABLET") {
                return heightArray[1]
              }
              return heightArray[2]
            })()
          }}
          animate={{
            height: (() => {
              const heightArray = stepToShow.height
              if (device === "MOBILE") {
                return heightArray[0]
              }
              if (device === "TABLET") {
                return heightArray[1]
              }
              return heightArray[2]
            })()
          }}
          position="relative"
          flexDirection="column"
          alignItems="center"
          width={1 / 1}
          mb={5}
        >
          <AnimatePresence>
            <MotionFlex
              justifyContent="center"
              position="absolute"
              variants={stepTextVariants}
              flexDirection="column"
              initial="hidden"
              alignItems="center"
              animate="visible"
              exit="exitHidden"
              key={stepToShow.title}
            >
              <IconCircle
                name={stepToShow.icon}
                size="100"
                ICON_SIZE_RATIO={2}
                bg="primary.0"
                color="secondary.0"
              />
              <Text textAlign="center" fontSize={6} my={3} color="primary.0">
                {stepToShow.title}
              </Text>
              <Text
                textAlign="center"
                fontSize="18px"
                width={[1 / 1, 4 / 5, 3 / 5]}
                color="primary.0"
              >
                {determineText(stepToShow)}
              </Text>
            </MotionFlex>
          </AnimatePresence>
        </MotionFlex>
      </Flex>
      <Flex
        flexDirection="row"
        justifyContent="center"
        width={1 / 1}
        mt={[stepToShow.height * 0.6, 6]}
        style={{ marginTop: `calc(60 + 20vw)` }}
        flexWrap="wrap"
      >
        {sixSteps.map((step, index) => (
          <Flex
            position="relative"
            justifyContent="center"
            alignItems="center"
            width={80}
            height={80}
            mr={4}
            mt={2}
            key={step.title}
          >
            <AnimatePresence>
              {step.title === stepToShow.title && (
                <MotionFlex
                  key={step.title}
                  style={{
                    borderRadius: 99,
                    position: "absolute",
                    border: "solid",
                    borderWidth: 4,
                    borderColor: "rgba(1,62,76,1)"
                  }}
                  custom={index}
                  variants={iconCircleBorderVariants}
                  initial="hidden"
                  animate={iconHighlight ? "highlighted" : "unActive"}
                  exit="hidden"
                  width={90}
                  height={90}
                />
              )}
            </AnimatePresence>

            <IconCircle
              key={step.icon}
              name={step.icon}
              bg="primary.0"
              color="secondary.0"
              size="68"
              custom={index}
              variants={iconCircleVariants}
              initial="unActive"
              animate={iconHighlight ? "highlighted" : "unActive"}
              whileHover={stepToShow.icon === step.icon ? "unActive" : "active"}
              style={{
                cursor: stepToShow.icon === step.icon ? "initial" : "pointer",
                zIndex: 10
              }}
              onClick={() => setStepToShow(sixSteps[index])}
            />
          </Flex>
        ))}
      </Flex>
    </HomepageBlock>
  )
}

export default SixSteps
