import { createSelector } from "reselect"

const expertAdviceState = state => state.expertAdvice

export const getIsLoading = createSelector(
  expertAdviceState,
  state => state.isLoading
)

export const getIsPosting = createSelector(
  expertAdviceState,
  state => state.isPosting
)

export const getRegions = createSelector(
  expertAdviceState,
  state => state.regions
)

export const getJobRoles = createSelector(
  expertAdviceState,
  state => state.jobRoles
)

export const getHasCreated = createSelector(
  expertAdviceState,
  state => state.hasCreated
)

export const getTextSearch = createSelector(
  expertAdviceState,
  state => state.textSearch
)
