import { Text } from "@engaging-tech/components"
import React from "react"
import { useTranslation } from "react-i18next"
import { emailEB } from "../../../../lib/globalConstants"
import invalidCodeContainer from "../../containers/invalidCode.container"
import SurveyCard from "../surveyCard"

const InvalidCode = ({ resetCode }) => {
  const { t } = useTranslation(["organisation_survey"])

  return (
    <SurveyCard
      title={t("code_check.invalid_code", "")}
      leftButtonText={t("code_check.cancel", "")}
      rightButtonText={t("code_check.retry", "")}
      onPrevious={resetCode}
    >
      <Text
        color="dark.2"
        my={3}
        px={[3, 5]}
        textAlign="center"
        display="block"
        fontWeight={500}
      >
        {t("code_check.invalid_code1", "")}
      </Text>
      <Text
        color="dark.2"
        my={3}
        px={[3, 5]}
        textAlign="center"
        display="block"
        fontWeight={500}
      >
        {t("code_check.invalid_code2", "")}{" "}
        <Text color="primary.0">
          <a
            href={`mailto:${emailEB}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {emailEB}
          </a>
        </Text>
      </Text>
    </SurveyCard>
  )
}

export default invalidCodeContainer(InvalidCode)
