import { useEffect } from "react"
import { connect } from "react-redux"

import { loadAllJobRoles } from "../store/misc.actions"
import {
  getAllJobRoles,
  getHasLoaded,
  getIsLoading
} from "../store/misc.selectors"

const JobRolesLoaderComponent = ({
  loading,
  hasLoaded,
  allJobRoles,
  loadJobRoles
}) => {
  useEffect(() => {
    if (loading) return
    if (hasLoaded && !allJobRoles.length) {
      setTimeout(loadJobRoles, 1000)
    } else if (!allJobRoles.length) {
      loadJobRoles()
    }
  }, [allJobRoles, hasLoaded, loadJobRoles, loading])

  return null
}

const mapState = state => ({
  allJobRoles: getAllJobRoles(state),
  loading: getIsLoading(state, "JobRoles"),
  hasLoaded: getHasLoaded(state, "JobRoles")
})

const mapDispatch = dispatch => ({
  loadJobRoles: () => dispatch(loadAllJobRoles())
})

const JobRolesLoader = connect(mapState, mapDispatch)(JobRolesLoaderComponent)

export default JobRolesLoader
