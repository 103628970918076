/* eslint-disable global-require */
import { Flex, UserInterfaceNotifications, Utils } from "@engaging-tech/components"
import { AllRoutes } from "@engaging-tech/routing"
import { ConsentBanner, PageviewTracker } from "@engaging-tech/third-party-cookies"
import { Amplify } from "aws-amplify"
import React, { useCallback, useEffect, useState } from "react"
import Favicon from "react-favicon"
import { Helmet } from "react-helmet"
import { connect as connectToPass } from "react-redux"
import { createGlobalStyle } from "styled-components"

import awsconfig from "./aws-exports"
import "./carounsel.css"
import Bootstrap from "./features/ui/containers/Bootstrap"
import UrlTracker from "./features/ui/containers/UrlTracker"
import routes from "./routes"

Amplify.configure({
  ...awsconfig
})

const favicons = {
  light: require(`${process.env.RAZZLE_PUBLIC_DIR}/favicon-light-mode.ico`),
  dark: require(`${process.env.RAZZLE_PUBLIC_DIR}/favicon-dark-mode.ico`)
}

const cookieIconImg = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/cookie.png`)

/* eslint-disable import/no-dynamic-require */
const woffIcons = require(`${process.env.RAZZLE_PUBLIC_DIR}/fonts/EngagingTechIcons.woff`)
const ttfIcons = require(`${process.env.RAZZLE_PUBLIC_DIR}/fonts/EngagingTechIcons.ttf`)
/* eslint-enable import/no-dynamic-require */

const tiemposWoff = require(`${process.env.RAZZLE_PUBLIC_DIR}/fonts/tiempos-text-web-bold.woff`)
const tiemposWoff2 = require(`${process.env.RAZZLE_PUBLIC_DIR}/fonts/tiempos-text-web-bold.woff2`)
const centraWoff = require(`${process.env.RAZZLE_PUBLIC_DIR}/fonts/CentraNo2-Book.woff`)
const centraWoff2 = require(`${process.env.RAZZLE_PUBLIC_DIR}/fonts/CentraNo2-Book.woff2`)
const centraBoldWoff = require(`${process.env.RAZZLE_PUBLIC_DIR}/fonts/CentraNo2-Bold.woff`)
const centraBoldWoff2 = require(`${process.env.RAZZLE_PUBLIC_DIR}/fonts/CentraNo2-Bold.woff2`)
const centraLightWoff = require(`${process.env.RAZZLE_PUBLIC_DIR}/fonts/CentraNo2-Light.woff`)
const centraLightWoff2 = require(`${process.env.RAZZLE_PUBLIC_DIR}/fonts/CentraNo2-Light.woff2`)

const GlobalStyles = createGlobalStyle`

  @font-face {
   font-family: "Centra";
   src: url(${centraWoff}) format("woff"),
        url(${centraWoff2}) format("woff2");
        
   font-weight: 400;
   font-style: normal;
  }
  @font-face {
   font-family: "Centra";
   src: url(${centraBoldWoff}) format("woff"),
        url(${centraBoldWoff2}) format("woff2");
        
   font-weight: 700;
   font-style: normal;
  }
  @font-face {
   font-family: "Centra";
   src: url(${centraLightWoff}) format("woff"),
        url(${centraLightWoff2}) format("woff2");
        
   font-weight: 300;
   font-style: normal;
  }

  @font-face {
   font-family: "Tiempos";
   src: url(${tiemposWoff}) format("woff"),
        url(${tiemposWoff2}) format("woff2");
        
   font-weight: 400;
   font-style: normal;
  }
  @font-face {
    font-family: "EngagingTechIcons";
    font-style: normal;
    font-weight: 400;
    src: 
      url(${woffIcons}) format("woff"),
      url(${ttfIcons}) format("truetype");
  }

  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  html {
    width: 100vw;
    background-color: rgb(255, 255, 255);
  }

  hr {
    border: none;
  }
  
  /* #fc_frame {
    left: calc(100vw - 100px);
    top: calc(100vh - 100px);
  } */
`

const App = () => {
  const [favicon, setFavicon] = useState(
    favicons[
      (typeof window !== "undefined" &&
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: light)").matches
        ? "light"
        : "dark") || "light"
    ]
  )

  const setFaviconHandler = useCallback(
    e => {
      if (e.matches) {
        setFavicon(favicons.light)
      } else {
        setFavicon(favicons.dark)
      }
    },
    [setFavicon]
  )

  useEffect(() => {
    const MediaQueryList = window.matchMedia("(prefers-color-scheme: light)")
    if ("addEventListener" in MediaQueryList) {
      MediaQueryList.addEventListener("change", setFaviconHandler)
      return () => MediaQueryList.removeEventListener("change", setFaviconHandler)
    }
    // addListener required for safari versions 13 and below
    MediaQueryList.addListener(setFaviconHandler)
    return () => MediaQueryList.removeListener(setFaviconHandler)
  }, [setFaviconHandler])

  return (
    <Flex id="app-wrapper" flexDirection="column" alignItems="center" width="100%" bg="light.0">
      <Helmet>
        <title>WorkL</title>
        <meta
          name="description"
          content="WorkL is a digital career development tool on a mission to help you enjoy a better work life. We transform working life for millions of people around the world"
        />
      </Helmet>
      <GlobalStyles />
      <Favicon url={favicon} />

      <UserInterfaceNotifications connect={connectToPass} />
      <Bootstrap />
      <UrlTracker />
      <AllRoutes routes={routes} />
      {typeof window !== "undefined" && (
        <ConsentBanner iconImage={cookieIconImg} exceptions={["in-app-view"]} />
      )}
      <PageviewTracker />
    </Flex>
  )
}

export default App
