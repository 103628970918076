import { connect } from "react-redux"

import ContentForYouWidgetComponent from "../components/ContentForYouWidget"

import { loadContentForYou } from "../../store/developCareer.actions"
import { getContentForYou } from "../../store/developCareer.selectors"
import { getIsPremium } from "../../../yourProfile/store/yourProfile.selectors"

const mapState = state => ({
  contentForYou: getContentForYou(state),
  isPremium: getIsPremium(state)
})

const mapDispatch = dispatch => ({
  loadContentForYou: () => dispatch(loadContentForYou())
})

const ContentForYouWidget = connect(
  mapState,
  mapDispatch
)(ContentForYouWidgetComponent)

export default ContentForYouWidget
