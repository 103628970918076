import { connect } from "react-redux"
import { answer, removeAnswer } from "../store/survey.actions"
import {
  getAllAnswers,
  getAnswerById,
  getData,
  getQuestionById,
  getQuestionIndexById
} from "../store/survey.selectors"

const mapState = (state, { id }) => ({
  data: getData(state),
  answers: getAllAnswers(state),
  isFirstQuestion: getQuestionIndexById(state, id) === 1,
  question: getQuestionById(state, id),
  previousAnswer: getAnswerById(state, id)
})

const mapDispatch = dispatch => ({
  onAnswer: ({ id, value }) => dispatch(answer({ questionId: id, value })),
  onRemoveAnswer: id => dispatch(removeAnswer(id))
})

const questionContainer = connect(mapState, mapDispatch)

export default questionContainer
