import styled from "styled-components"
import { MotionBox } from "@engaging-tech/components"

const SliderKnob = styled(MotionBox)`
  width: ${({ knobSize }) => knobSize}px;
  height: ${({ knobSize }) => knobSize}px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: ${({ knobSize, trackHeight }) => {
    if (trackHeight >= knobSize) {
      return (trackHeight - knobSize) / 2
    }

    return -((knobSize - trackHeight) / 2)
  }}px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  left: -${({ knobSize }) => knobSize / 2}px;
`

export default SliderKnob