import React, { useState } from "react"
import {
  Avatar,
  Box,
  Button,
  Icon,
  Chip,
  Flex,
  Modal,
  Spinner,
  Text
} from "@engaging-tech/components"
import styled from "styled-components"
import { titleCaps } from "@engaging-tech/frontend-utils"
import { createPortal } from "react-dom"
import ReportUser from "../containers/ReportUser"

const ClickableIcon = styled(Icon)`
  cursor: pointer;
  border-radius: 100%;
  :hover {
    background-color: ${({ theme }) => theme.colors.dark[6]};
  }
`

const getJobText = (jobTitle, companyName) => {
  if (jobTitle && companyName) return `${jobTitle} at ${companyName}`
  if (jobTitle && !companyName) return jobTitle
  return companyName
}

const placeholderImage = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/career-developer/profile-image-placeholder.svg`)

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const ConnectModal = ({
  id,
  firstName,
  lastName,
  profile,
  onToggle,
  connectUser,
  isLoading,
  hasLoaded,
  hasError,
  isConnected,
  connectResponse,
  currentInvite
}) => {
  const [showUser, setShowUser] = useState(false)

  return showUser ? (
    <ReportUser id={id} onCloseParent={onToggle} />
  ) : (
    createPortal(
      <Modal
        bg="light.0"
        onToggle={onToggle}
        flexDirection="column"
        disableScrollbar
        animated
        alignItems="center"
        maxWidth={270}
        pb={4}
        px={2}
      >
        <>
          <Flex width={1 / 1} justifyContent="space-between">
            <Box width={1} pt={3} pl={1}>
              <ClickableIcon name="close" p={3} onClick={onToggle} />
            </Box>
            <Chip mt={3} trailingIcon="flag" onClick={() => setShowUser(true)}>
              Report
            </Chip>
          </Flex>

          <Avatar
            picture={profile?.picture?.large || placeholderImage}
            size={84}
            mb={4}
          />
          <Text
            fontSize={5}
            width={1}
            display="block"
            textAlign="center"
            fontWeight={700}
            color="primary.0"
            mb={3}
          >
            {titleCaps(`${firstName} ${lastName}`)}
          </Text>
          {(profile?.jobTitle || profile?.companyName) && (
            <Text
              fontSize={4}
              width={1}
              display="block"
              fontFamily="Centra"
              textAlign="center"
              color="primary.0"
              mb={3}
            >
              {getJobText(profile?.jobTitle, profile?.companyName)}
            </Text>
          )}
          {profile?.location && (
            <Text
              fontSize={4}
              width={1}
              display="block"
              fontFamily="Centra"
              textAlign="center"
              color="primary.0"
              mb={3}
            >
              {profile?.location.name}
            </Text>
          )}
          {!currentInvite && !isConnected && (
            <>
              {!isLoading && !hasLoaded && (
                <Button
                  variant="primary.0"
                  width={8 / 10}
                  onClick={() => connectUser(id)}
                >
                  Connect
                </Button>
              )}
              {isLoading && <Spinner color="primary.0" />}
              {hasLoaded && connectResponse === true && (
                <Text width={1} textAlign="center" color="primary.0">
                  Connection Request Sent
                </Text>
              )}
              {hasLoaded && connectResponse === false && (
                <Text width={1} textAlign="center" color="primary.0">
                  Connection Request Failed
                </Text>
              )}
              {hasError && hasLoaded && (
                <Text width={1} textAlign="center" color="primary.0">
                  Something went wrong
                </Text>
              )}
            </>
          )}
          {currentInvite && !isConnected && (
            <Text width={1} textAlign="center" color="primary.0">
              {currentInvite.isRecipient
                ? "This user has sent you a connection request. Please check your emails or your notifications on the app."
                : "Awaiting invite response"}
            </Text>
          )}
          {isConnected && (
            <Text width={1} textAlign="center" color="primary.0">
              You are connected with this user!
            </Text>
          )}
        </>
      </Modal>,
      appRoot()
    )
  )
}

export default ConnectModal
