import { connect } from "react-redux"

import { getFilter, getHasFiltered } from "../store/libraryBooks.selectors"
import { filter, resetFilters } from "../store/libraryBooks.actions"

import Filter from "../../businessLibrary/components/Search/Filter"

const mapDispatch = dispatch => ({
  onFilter: category => dispatch(filter(category)),
  onReset: () => dispatch(resetFilters())
})

const mapState = state => ({
  activeFilter: getFilter(state),
  hasFiltered: getHasFiltered(state)
})

const CategoryFilters = connect(mapState, mapDispatch)(Filter)

export default CategoryFilters
