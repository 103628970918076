const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_SECTIONS_LIST":
      return { ...state, sectionsList: action.payload }
    case "UPDATE_CURRENT_SECTION":
      return { ...state, currentSection: action.payload, currentQuestion: 0 }
    case "QUESTION_HISTORY_UPDATE":
      return { ...state, questionsHistory: action.payload }
    case "QUESTION_CURRENT_UPDATE":
      return { ...state, currentQuestion: action.payload }
    case "QUESTION_LIST_UPDATE":
      return { ...state, questionsList: action.payload }
    case "BRANCHING_LOGICS_UPDATE":
      return { ...state, branchingLogics: action.payload }
    default:
      return state
  }
}

export default reducer
