import React, { useEffect } from "react"
import { Flex, Text, Select } from "@engaging-tech/components"
import { useField } from "formik"
import InputLoadError from "./InputLoadError"

const DropdownInput = ({
  field,
  name,
  meta,
  label,
  placeholderLabel,
  options,
  helpers,
  errorTextOverride,
  defaultOptionLabel,
  helperText,
  onChangeSideEffect,
  isLoading,
  ...other
}) => {
  const optionsWithBlank = options
  optionsWithBlank.push({ id: "", label: "" })

  const findMatchingValue = id => {
    const blankOption = optionsWithBlank.find(option => option.id === "")
    if (id === null && defaultOptionLabel) {
      return (
        optionsWithBlank.find(option => option.label === defaultOptionLabel) ||
        blankOption
      )
    }
    if (id === null) {
      return blankOption
    }
    return optionsWithBlank.find(option => option.id === id) || blankOption
  }

  const generateOptions = () => {
    return optionsWithBlank.map(option => (
      <option value={option.id} id={option.id} key={option.id}>
        {option.label}
      </option>
    ))
  }

  const generateErrors = errors => {
    if (errorTextOverride) {
      return (
        <Text ml={2} mt={2} fontSize={2} color="error.0">
          {errorTextOverride}
        </Text>
      )
    }
    if (typeof errors === "string") {
      return (
        <Text ml={2} mt={2} fontSize={2} color="error.0">
          {errors}
        </Text>
      )
    }
    if (typeof errors === "object") {
      const errorStringArray = Object.values(errors)
      return errorStringArray.map(errorString => (
        <Text ml={2} mt={2} fontSize={2} color="error.0" key={errorString}>
          {errorString}
        </Text>
      ))
    }
    return (
      <Text ml={2} mt={2} fontSize={2} color="error.0">
        Input Error
      </Text>
    )
  }

  useEffect(() => {
    if (!field.value) {
      helpers.setValue(findMatchingValue(null).id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!("value" in field)) {
    return <InputLoadError />
  }

  return (
    <Flex width={1 / 1} flexDirection="column" {...other}>
      {label && <Text mb={3}>{label}</Text>}
      <Select
        width={1 / 1}
        label={placeholderLabel}
        name={name}
        disabled={isLoading}
        helperText={isLoading ? "Loading options..." : helperText}
        onChange={event => {
          helpers.setTouched(true)
          helpers.setValue(findMatchingValue(event.target.value))
          if (onChangeSideEffect) {
            onChangeSideEffect(event.target.value)
          }
        }}
        id={name}
        value={findMatchingValue(field.value ? field.value.id : null).id}
      >
        {generateOptions()}
      </Select>
      {meta.error && meta.touched && <>{generateErrors(meta.error)}</>}
    </Flex>
  )
}

const DropdownInputHandler = ({
  label,
  defaultOptionLabel,
  placeholderLabel,
  name,
  helperText,
  errorTextOverride,
  onChangeSideEffect,
  isLoading,
  options = [],
  ...other
}) => {
  const [field, meta, helpers] = useField({ name })

  return (
    <DropdownInput
      label={label}
      placeholderLabel={placeholderLabel}
      name={name}
      errorTextOverride={errorTextOverride}
      helpers={helpers}
      field={field}
      meta={meta}
      options={options}
      defaultOptionLabel={defaultOptionLabel}
      helperText={helperText}
      onChangeSideEffect={onChangeSideEffect}
      isLoading={isLoading}
      {...other}
    />
  )
}

export default DropdownInputHandler
