const POST_TYPES = {
  posts: "post",
  podcasts: "podcast",
  articles: "article",
  research: "research",
  lectures: "lecture",
  jobs: "job",
  books: "book",
  eBooks: "eBooks"
}

export const hubPostTypes = {
  post: "post",
  podcast: "podcast",
  article: "article",
  research: "research",
  lecture: "lecture",
  advice: "advice",
  event: "event"
}

export const libraryPostTypes = {
  podcasts: POST_TYPES.podcasts,
  articles: POST_TYPES.articles,
  lectures: POST_TYPES.lectures,
  research: POST_TYPES.research,
  books: POST_TYPES.books,
  eBooks: POST_TYPES.eBooks
}
