import { connect } from "react-redux"
import { attachUser } from "../store/survey.actions"
import {
  getHasAttachedUser,
  getIsPrivate,
  getSubmissionId
} from "../store/survey.selectors"

const mapState = state => ({
  isPrivate: getIsPrivate(state),
  hasAttachedUser: getHasAttachedUser(state),
  surveySubmissionId: getSubmissionId(state)
})

const mapDispatch = dispatch => ({
  attachUser: submissionId => dispatch(attachUser(submissionId))
})

export default connect(mapState, mapDispatch)
