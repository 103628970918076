import { useEffect } from "react"
import { connect } from "react-redux"

import { loadAllCountries } from "../store/misc.actions"
import { getAllCountries, getHasLoaded, getIsLoading } from "../store/misc.selectors"

const CountriesLoaderComponent = ({ allCountries, loadCountries, loading, hasLoaded }) => {
  useEffect(() => {
    if (loading) return

    if (hasLoaded && !allCountries.length) setTimeout(loadCountries, 1000)
    else if (!allCountries.length) loadCountries()
  }, [allCountries, hasLoaded, loadCountries, loading])

  return null
}

const mapState = state => ({
  allCountries: getAllCountries(state),
  loading: getIsLoading(state, "Countries"),
  hasLoaded: getHasLoaded(state, "Countries")
})

const mapDispatch = dispatch => ({
  loadCountries: () => dispatch(loadAllCountries())
})

const CountriesLoader = connect(mapState, mapDispatch)(CountriesLoaderComponent)

export default CountriesLoader
