export const LOAD_SIZE = 10

export const initialState = {
  isLoading: false,
  isLoadingJobRanking: false,
  isLoadingJobRankingMore: false,
  isLoadingMore: false,

  // jobs list
  jobs: [],
  jobsRanking: [],
  nextToken: undefined,

  jobSelected: null,

  isLoadingJobDetails: false,
  jobDetails: null
}
