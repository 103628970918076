import { Flex, Icon, Text } from "@engaging-tech/components"
import React from "react"

const Banner = () => (
  <Flex bg="dark.7" borderRadius={6} p={3} width={1 / 1} m={1} mb={3}>
    <Icon name="error" color="primary.0" mr={2} size={20} />
    <Text fontSize={3}>
      Not finding the right job matches? The more you complete your profile, the more intelligent our Job
      Matcher gets. Make sure you fill in your profile as much as you can to match with the right job
      vacancies!
    </Text>
  </Flex>
)

export default Banner
