import { connect } from "react-redux"
import {
  closeOptionBox,
  getGridCustomisationFlag
} from "../store/developCareer.selectors"
import { updateGridCustomisationFlag, postLayout } from "../store/developCareer.actions"
import OptionsBoxComponent from "../components/OptionsBox"

const mapState = state => ({
  close: closeOptionBox(state),
  gridCustomisationFlag: getGridCustomisationFlag(state)
})

const mapDispatch = dispatch => ({
  updateGridCustomisationFlag: boolean =>
    dispatch(updateGridCustomisationFlag(boolean)),
  postLayout: () => dispatch(postLayout())
})

const OptionsBox = connect(mapState, mapDispatch)(OptionsBoxComponent)

export default OptionsBox
