import { connect } from "react-redux"

import { notificationsActions, notificationsSelectors } from "../../../Notifications"
import AlertsListComponent from "../components/AlertsList"

const mapState = state => ({
  type: "Event",
  alerts: notificationsSelectors.getEventAlerts(state)
})

const mapDispatch = dispatch => ({
  setActiveAlert: id => dispatch(notificationsActions.setActiveAlert(id))
})

const EventsAlerts = connect(mapState, mapDispatch)(AlertsListComponent)

export default EventsAlerts
