import { TextField } from "@engaging-tech/components"
import { useLocation, useRouter } from "@engaging-tech/routing"
import React, { useEffect, useState } from "react"
import { useDebouncedCallback } from "use-debounce"

import { createUrl } from "../../../../../lib/utils"
import { Container, Wrapper } from "./textSearch.styled"

const TextSearch = ({ placeholder = "" }) => {
  const location = useLocation()
  const router = useRouter()

  const optionSearchParams = new URLSearchParams(location.search)
  const searchTermFilterValue = optionSearchParams.get("search_term")
  const companyFilterValue = optionSearchParams.get("company")

  const [searchTerm, setSearchTerm] = useState(searchTermFilterValue || companyFilterValue)
  const searchTermDebounce = useDebouncedCallback(value => {
    if (value) {
      optionSearchParams.set("search_term", value)
    } else {
      optionSearchParams.delete("search_term")
    }
    const optionUrl = createUrl(location.pathname, optionSearchParams)
    router.navigate(optionUrl)
  }, 500)

  const handleInputChange = event => {
    const { value } = event.target

    setSearchTerm(value)
    searchTermDebounce(value)
  }

  useEffect(() => {
    if (companyFilterValue) {
      setSearchTerm(companyFilterValue)
    } else if (searchTerm && searchTermFilterValue === null && companyFilterValue === null) {
      setSearchTerm("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyFilterValue, searchTermFilterValue])

  return (
    <Wrapper>
      <Container as="form">
        <TextField
          id="searchTextField"
          noLabel
          bg="light.0"
          iconStyle={{
            color: searchTerm ? "primary.0" : "dark.3",
            width: "20px"
          }}
          fontSize={16}
          height={40}
          border="1px solid rgb(1, 62, 76)"
          borderRadius={20}
          iconName="search"
          noHighlight
          onChange={handleInputChange}
          value={searchTerm ?? ""}
          placeholder={placeholder}
        />
      </Container>
    </Wrapper>
  )
}

export default TextSearch
