import React from "react"

import SurveyResults from "../../features/surveyResults/views/Results"
import ResultsLayout from "../../layouts/ResultsLayout"

const Results = () => {
  return (
    <ResultsLayout>
      <SurveyResults />
    </ResultsLayout>
  )
}

export default Results
