import React from "react"
import {
  Flex,
  Modal,
  Paragraph,
  Text,
  MotionFlex
} from "@engaging-tech/components"
import { createPortal } from "react-dom"
import styled, { createGlobalStyle } from "styled-components"

import { usePaths, Link } from "@engaging-tech/routing"
import PrimaryButton from "../../ui/components/PrimaryButton"
import ClickableIcon from "../../ui/components/ClickableIcon"

const SeparatorBox = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[4]};
`

const ClickableFlex = styled(Flex)`
  :hover {
    cursor: pointer;
  }
`

const DisableScrollPadding = createGlobalStyle`
  body {
    overflow: hidden;
    margin-right: 14px !important;
  }
`

const BookModal = ({ book, setVisibility }) => {
  const paths = usePaths()

  const handleKeyPress = event => {
    event.preventDefault()
    const keyCode = event.which
    if (keyCode === 27) {
      setVisibility(false)
    }
  }

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyPress)
    return () => window.removeEventListener("keydown", handleKeyPress)
  })
  const appRoot = () =>
    typeof document !== "undefined" && document.getElementById("root")

  const url = `${paths.resourceCentre.browse.books.index}${book.id}`
  return createPortal(
    <Modal
      animated
      disableScrollbar
      onToggle={() => setVisibility(false)}
      book={book}
      key="book-modal"
      bg="light.0"
      px={[2, 6]}
      py={[2, 4]}
      my={3}
      minHeight={9 / 10}
    >
      <DisableScrollPadding />
      <MotionFlex
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2 }}
        flexDirection="column"
        width={1 / 1}
        justifyContent="center"
      >
        <SeparatorBox flexDirection="row" alignItems="center" pb={2}>
          <Text
            fontSize={5}
            textAlign="center"
            fontWeight={600}
            maxWidth={[300, 300, 1000]}
          >
            {book.title}
          </Text>
          <ClickableIcon
            name="close"
            p={3}
            ml={-5}
            onClick={() => setVisibility(false)}
          />
        </SeparatorBox>{" "}
        <ClickableFlex width={1 / 1} justifyContent="center">
          <Link to={url}>
            <img src={book.image} height="190px" alt={book.title} />
          </Link>
        </ClickableFlex>
        {book.children.map(child => (
          <Paragraph fontSize={3} pb={2} lineHeight="1.4">
            {child}
          </Paragraph>
        ))}
        <Flex width={1 / 1} justifyContent="center">
          <Link to={url}>
            <PrimaryButton width={150} my={3} alignItem="center">
              Buy the book
            </PrimaryButton>
          </Link>
        </Flex>
      </MotionFlex>
    </Modal>,
    appRoot()
  )
}

export default BookModal
