import PremiumFeatures from "../features/premium/components/PremiumFeatures"
import PremiumSuccess from "../features/premium/components/Success"
import About from "./About/About"
import CMI from "./Companies/CMI"
import connectUsers from "./ConnectUsers"
import DevelopCareer from "./DevelopCareer"
import CVLibrary from "./DevelopCareer/CVLibrary"
import CreateCV from "./DevelopCareer/CreateCV"
import DevelopCareerIndex from "./DevelopCareer/Index"
import MentorMatcher from "./DevelopCareer/MentorMatcher"
import SelectedCV from "./DevelopCareer/SelectedCV"
import YourSurveys from "./DevelopCareer/YourSurveys"
import YourTests from "./DevelopCareer/YourTests"
import JobMatcher from "./DevelopCareer/jobMatcher"
import JobMatcherForm from "./DevelopCareer/jobMatcherForm"
import DownForMaintenance from "./DownForMaintenance"
import Home from "./Home"
import individualJob from "./InAppView/Job"
import appJobs from "./InAppView/Jobs"
import BusinessProfileAppView from "./InAppView/business-profile"
import CreateCVInAppView from "./InAppView/develop-career/create-cv"
import CVLibraryInAppView from "./InAppView/develop-career/cv-library"
import SelectedCVInAppView from "./InAppView/develop-career/selected-cv"
import GlobalHubAppView from "./InAppView/globalhub"
import GlobalHubPostAppView from "./InAppView/globalhubPost"
import OrganisationSurveyResultInAppView from "./InAppView/organisation-survey/results"
import OrganisationSurveyStartInAppView from "./InAppView/organisation-survey/start"
import OrganisationSurveySubmitInAppView from "./InAppView/organisation-survey/submit"
import OrganisationSurveyWelcomeInAppView from "./InAppView/organisation-survey/welcome"
import QuestionsInAppView from "./InAppView/questions"
import RankCompanyInAppView from "./InAppView/rank-company"
import ResultsInAppView from "./InAppView/results"
import SubmitSurveyInAppView from "./InAppView/submit"
import SurveyWelcomeInAppView from "./InAppView/survey-welcome"
import Messenger from "./Messenger"
import Onboarding from "./Onboarding"
import Subscribe from "./Premium/Subscribe"
import AccountComparison from "./account/AccountComparison"
import ConfirmEmail from "./account/ConfirmEmail"
import Saml from "./account/Saml"
import ThankYou from "./account/ThankYou"
import ForgotPassword from "./account/forgotPassword"
import MagicLinkMessage from "./account/magicLinkMessage"
import MagicLinkRedirect from "./account/magicLinkRedirect"
import ResetPassword from "./account/resetPassword"
import SendMagicLink from "./account/sendMagicLink"
import SignIn from "./account/signIn"
import SignUp from "./account/signUp"
import BusinessProfile from "./business-information"
import Discover from "./businessLibrary/Discover"
import Checkout from "./businessLibrary/basket/Checkout"
import Basket from "./businessLibrary/basket/Index"
import BrowseArticles from "./businessLibrary/browse/Articles"
import BrowseBooks from "./businessLibrary/browse/Books"
import BrowseLectures from "./businessLibrary/browse/Lectures"
import BrowsePodcasts from "./businessLibrary/browse/Podcasts"
import BrowseResearch from "./businessLibrary/browse/Research"
import PublishArticle from "./businessLibrary/publish/Article"
import PublishLecture from "./businessLibrary/publish/Lecture"
import PublishPodcast from "./businessLibrary/publish/Podcast"
import PublishResearch from "./businessLibrary/publish/Research"
import Purchased from "./businessLibrary/purchased/Index"
import SelectedOrder from "./businessLibrary/purchased/Selected"
import SelectedArticle from "./businessLibrary/selected/Article"
import SelectedBook from "./businessLibrary/selected/Book"
import SelectedLecture from "./businessLibrary/selected/Lecture"
import SelectedPodcast from "./businessLibrary/selected/Podcast"
import SelectedResearch from "./businessLibrary/selected/Research"
import Feed from "./globalHub/Feed"
import PublishPost from "./globalHub/Publish"
import SelectedPost from "./globalHub/SelectedPost"
import SurveyQuestions from "./happinessSurvey/Questions"
import RankCompany from "./happinessSurvey/RankCompany"
import SubmitSurvey from "./happinessSurvey/Submit"
import SurveyWelcome from "./happinessSurvey/Welcome"
import SurveyWelcomeSavanta from "./happinessSurvey/WelcomeSavanta"
import JobDetails from "./jobFinder/jobDetail"
import Jobs from "./jobFinder/jobsFeed"
import Cookies from "./legal/Cookies"
import Privacy from "./legal/Privacy"
import Terms from "./legal/Terms"
import BookmarkedArticles from "./myLibrary/Articles"
import BookmarkedAudiobooks from "./myLibrary/Audiobooks"
import BookmarkedEbooks from "./myLibrary/Ebooks"
import MyLibraryIndex from "./myLibrary/Index"
import BookmarkedLectures from "./myLibrary/Lectures"
import BookmarkedPodcasts from "./myLibrary/Podcasts"
import BookmarkedResearch from "./myLibrary/Research"
import Selected from "./myLibrary/Selected"
import CompanyRankings from "./rankings/companyRankings"
import OrganisationSurveyStart from "./survey/surveyContent"
import OrganisationSurveySubmit from "./survey/surveySubmit"
import OrganisationSurveyWelcome from "./survey/surveyWelcome"
import SurveyResults from "./surveyResults/Results"
import translation from "./translation"

const companies = {
  CMI
}

const account = {
  index: SignIn,
  signIn: SignIn,
  signUp: SignUp,
  forgotPassword: ForgotPassword,
  resetPassword: ResetPassword,
  confirmEmail: ConfirmEmail,
  thankYou: ThankYou,
  saml: Saml,
  accountComparison: AccountComparison,
  sendMagicLink: SendMagicLink,
  magicLinkMessage: MagicLinkMessage,
  magicLinkRedirect: MagicLinkRedirect
}

const jobs = {
  index: Jobs,
  jobs: Jobs,
  details: JobDetails
}

const happiestCompanies = {
  index: CompanyRankings
}

const rankings = {
  companies: CompanyRankings
}

const globalHub = {
  index: Feed,
  selected: SelectedPost,
  publish: PublishPost
}

const businessLibrary = {
  index: Discover,
  discover: Discover,
  browse: {
    books: BrowseBooks,
    podcasts: BrowsePodcasts,
    articles: BrowseArticles,
    lectures: BrowseLectures,
    research: BrowseResearch
  },
  selected: {
    book: SelectedBook,
    podcast: SelectedPodcast,
    article: SelectedArticle,
    lecture: SelectedLecture,
    research: SelectedResearch
  },
  publish: {
    podcast: PublishPodcast,
    article: PublishArticle,
    lecture: PublishLecture,
    research: PublishResearch
  },
  basket: {
    index: Basket,
    checkout: Checkout
  },
  purchased: {
    index: Purchased,
    selected: SelectedOrder
  }
}

const myLibrary = {
  index: MyLibraryIndex,
  articles: BookmarkedArticles,
  podcasts: BookmarkedPodcasts,
  lectures: BookmarkedLectures,
  research: BookmarkedResearch,
  ebooks: BookmarkedEbooks,
  audiobooks: BookmarkedAudiobooks,
  selected: Selected
}

const happinessSurvey = {
  rankCompany: RankCompany,
  welcome: SurveyWelcome,
  welcomeSavanta: SurveyWelcomeSavanta,
  questions: SurveyQuestions,
  submit: SubmitSurvey,
  results: SurveyResults
}

const organisationSurvey = {
  welcome: OrganisationSurveyWelcome,
  start: OrganisationSurveyStart,
  submit: OrganisationSurveySubmit,
  results: SurveyResults
}

const premium = {
  subscribe: Subscribe,
  features: PremiumFeatures,
  success: PremiumSuccess
}

const legal = {
  cookies: Cookies,
  privacy: Privacy,
  terms: Terms
}

const developCareer = {
  yourSurveys: YourSurveys,
  resultsPage: SurveyResults,
  index: DevelopCareerIndex,
  jobMatcher: { index: JobMatcher, setCriteria: JobMatcherForm },
  createCV: CreateCV,
  mentorMatcher: MentorMatcher,
  selectedCV: SelectedCV,
  cvLibrary: CVLibrary,
  yourTests: YourTests
}

const appView = {
  appJobs,
  job: individualJob,
  globalHub: GlobalHubAppView,
  globalHubPost: GlobalHubPostAppView,
  businessProfile: BusinessProfileAppView,
  happinessSurvey: {
    welcome: SurveyWelcomeInAppView,
    rankCompany: RankCompanyInAppView,
    questions: QuestionsInAppView,
    submit: SubmitSurveyInAppView,
    results: ResultsInAppView
  },
  organisationSurvey: {
    welcome: OrganisationSurveyWelcomeInAppView,
    start: OrganisationSurveyStartInAppView,
    submit: OrganisationSurveySubmitInAppView,
    results: OrganisationSurveyResultInAppView
  },
  developCareer: {
    cvLibrary: CVLibraryInAppView,
    selectedCv: SelectedCVInAppView,
    createCV: CreateCVInAppView
  }
}

export default {
  Home,
  Messenger,
  About,
  Onboarding,
  BusinessProfile,
  DevelopCareer,
  translation,
  appView,
  account,
  developCareer,
  jobs,
  globalHub,
  businessLibrary,
  myLibrary,
  happinessSurvey,
  organisationSurvey,
  premium,
  legal,
  rankings,
  companies,
  connectUsers,
  DownForMaintenance,
  happiestCompanies
}
