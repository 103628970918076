import { createSelector } from "reselect"

const jobFinderState = state => state.jobFinder

export const getFilters = createSelector(jobFinderState, state => state?.filters)

export const getFiltersValues = createSelector(jobFinderState, state => state?.filtersValues)

export const getFilterValueById = filterId =>
  createSelector(jobFinderState, state => {
    const filterInfo = state?.filters?.find(item => item.id === filterId)

    if (state?.filtersValues && state?.filtersValues[filterId]) {
      return state?.filtersValues[filterId]
      // eslint-disable-next-line no-else-return
    } else if (filterInfo?.type === "integer") {
      return 0
    } else if (filterInfo?.type === "array_string") {
      return []
    } else {
      return ""
    }
  })

export const getJobs = createSelector(jobFinderState, state => state?.jobs)

export const getIsLoading = createSelector(jobFinderState, state => state?.isLoading)

export const getProgressState = createSelector(jobFinderState, state => state?.progressState)

export const getIsLoadingMore = createSelector(jobFinderState, state => state?.isLoadingMore)

export const getJobsNextToken = createSelector(jobFinderState, state => state.nextToken)

export const getJobSelected = createSelector(jobFinderState, state => state.jobSelected)

export const getJobDetailsIsLoading = createSelector(jobFinderState, state => state.isLoadingJobDetails)

export const getJobDetails = createSelector(jobFinderState, state => state.jobDetails)

export const getJobsRanking = createSelector(jobFinderState, state => state.jobsRanking)

export const getJobRanking = companyNameKey =>
  createSelector(jobFinderState, state => {
    const data = state.jobsRanking.find(item => item?.companyNameKey === companyNameKey)

    return data?.happinessScores
      ? {
          score: parseFloat(data.happinessScores.score * 100).toFixed(2),
          industry: parseFloat(data.happinessScores.industry * 100).toFixed(2)
        }
      : null
  })
