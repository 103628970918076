import React from "react"
import { Flex } from "@engaging-tech/components"
import HomepageBlock from "../../ui/components/HomepageBlock"
import SectionPoint from "../../ui/components/SectionPoint"

const whenAndWhyWorkLSvg = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/about-us/when-and-why-workl.svg`)
const whereWorkLSvg = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/about-us/where-workl.svg`)
const involvedWorkLSvg = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/about-us/involved-workl.svg`)
const missionStatementWorkLSvg = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/about-us/mission-statement-workl.svg`)

const WorkLSetUpDisplayComponent = () => {
  return (
    <Flex
      width={["80vw", "40vw", "400px"]}
      ml={[0, 70]}
      my={[30, 0]}
      maxWidth={400}
    >
      <img
        alt="When and why was WorkL set up?"
        src={whenAndWhyWorkLSvg}
        loading="lazy"
        width="100%"
      />
    </Flex>
  )
}

const WorkLBasedDisplayComponent = () => {
  return (
    <Flex
      width={["80vw", "35vw", "300px"]}
      mr={[0, 70]}
      my={[30, 0]}
      maxWidth={[300, 300]}
    >
      <img
        alt="Where is WorkL based?"
        src={whereWorkLSvg}
        loading="lazy"
        width="100%"
      />
    </Flex>
  )
}

const MissionStatementDisplayComponent = () => {
  return (
    <Flex
      width={["80vw", "35vw", "350px"]}
      ml={[0, 60]}
      my={[30, 0]}
      maxWidth={[340, 350]}
    >
      <img
        alt="What is your mission statement?"
        src={missionStatementWorkLSvg}
        loading="lazy"
        width="100%"
      />
    </Flex>
  )
}

const GetInvolvedDisplayComponent = () => {
  return (
    <Flex
      width={["80vw", "35vw", "360px"]}
      mr={[0, 60]}
      my={[30, 0]}
      maxWidth={[350, 360]}
    >
      <img
        alt="How do I get involved?"
        src={involvedWorkLSvg}
        loading="lazy"
        width="100%"
      />
    </Flex>
  )
}

const CompanyValues = () => {
  return (
    <HomepageBlock bg="light.0">
      <Flex flexDirection="column" width={1 / 1} justifyContent="space-between">
        <SectionPoint
          my={5}
          title="When and why was WorkL set up?"
          text1={[
            "WorkL was set up in 2017 by Lord Mark Price, the former Managing Director of Waitrose and Deputy Chairman of the John Lewis Partnership. Driven by his belief in the potential of happier employees to transform business and wider society, Mark developed WorkL to help individuals unlock a happier working life."
          ]}
          infoColumnProps={{ maxWidth: "550px" }}
          titleProps={{ color: "primary.0" }}
          textProps={{ color: "primary.0" }}
          DisplayComponent={<WorkLSetUpDisplayComponent />}
        />
        <SectionPoint
          my={5}
          title="Where is WorkL based?"
          text1={[
            "WorkL HQ is in central London, but we also have offices in Dorset and our employees work throughout the UK - from Scotland to Cornwall!"
          ]}
          infoColumnProps={{ maxWidth: "550px" }}
          titleProps={{ color: "primary.0" }}
          textProps={{ color: "primary.0" }}
          displayComponentLeft
          DisplayComponent={<WorkLBasedDisplayComponent />}
        />
        <SectionPoint
          my={5}
          title="What is your mission statement?"
          text1={[
            "Our mission is to transform the way millions of people think about work, in turn becoming happier at work."
          ]}
          infoColumnProps={{ maxWidth: "550px" }}
          titleProps={{ color: "primary.0" }}
          textProps={{ color: "primary.0" }}
          DisplayComponent={<MissionStatementDisplayComponent />}
        />
        <SectionPoint
          my={5}
          title="How do I get involved?"
          text1={[
            "Get in touch at hello@workl.co to enquire about vacancies, becoming an ambassador or partnerships."
          ]}
          infoColumnProps={{ maxWidth: "550px" }}
          linkedTexts={[
            {
              linkedText: "hello@workl.co",
              linkTo: "mailto:hello@workl.co",
              externalLink: true
            }
          ]}
          titleProps={{ color: "primary.0" }}
          textProps={{ color: "primary.0" }}
          displayComponentLeft
          DisplayComponent={<GetInvolvedDisplayComponent />}
        />
      </Flex>
    </HomepageBlock>
  )
}

export default CompanyValues
