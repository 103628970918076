import { LocationAutocomplete } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import { useField } from "formik"
import React, { useEffect, useState } from "react"

import InputLoadError from "./InputLoadError"

const LocationInput = ({ name, selectedLocationCallback }) => {
  const [field] = useField({ name })

  const [location, setLocation] = useState("")

  useEffect(() => {
    if (field?.value?.name) {
      setLocation(field.value?.name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!("value" in field)) {
    return <InputLoadError />
  }

  return (
    <LocationAutocomplete
      id="job-matcher-location-name"
      googleApiKey={getConfig().keys.googleApiKey}
      libraries={["places"]}
      borderRadius="4px 4px 0px 0px"
      width={1 / 1}
      height={50}
      initialValue={field?.value || ""}
      value={location}
      onChange={setLocation}
      selectedLocationCallback={selectedLocationCallback}
    />
  )
}

export default LocationInput
