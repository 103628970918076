/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import styled from "styled-components"
import {
  Text,
  Box,
  Flex,
  Button,
  Card,
  Paragraph
} from "@engaging-tech/components"
import { useRouter, useLocation } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import { useTranslation } from "react-i18next"
import SiteLogo from "../../ui/components/SiteLogo"
import NoSavantaTerritory from "./NoSavantaTerritory"

const ContentWrapper = styled(Box)`
  & > p:last-child {
    margin-bottom: 0;
  }
`

const WelcomeSavanta = ({
  startRoute,
  title,
  onLoad,
  setSavantaId,
  setSavantaTerritory
}) => {
  const router = useRouter()
  const search = new URLSearchParams(useLocation().search)
  const urlSavantaId = new URLSearchParams(search).get("id")
  const savantaTerritory = new URLSearchParams(search).get("territory")

  const startSurvey = id => {
    onLoad(id)
    router.navigate(startRoute)
  }

  const { t } = useTranslation(["happiness_survey"])

  useEffect(() => {
    if (urlSavantaId) setSavantaId(urlSavantaId)
    if (savantaTerritory) setSavantaTerritory(savantaTerritory)
  }, [])

  if (!savantaTerritory) return <NoSavantaTerritory />

  return (
    <Card
      bg="light.0"
      mt={[5, 5, 6]}
      mb={[6, 5]}
      elevation={8}
      width={1 / 1}
      maxWidth={560}
    >
      <Flex flexDirection="column" py={3} px={[3, 4]}>
        <Flex width={1 / 1} justifyContent="center" mb={[3, 4]}>
          <SiteLogo />
        </Flex>
        <Text
          fontSize={5}
          fontWeight="600"
          color="dark.2"
          mb={3}
          textAlign="center"
        >
          {title}
        </Text>
        <ContentWrapper>
          <Paragraph color="dark.2" fontSize={3} mb={2}>
            You're about to take the WorkL Happy At Work test, it will take
            about 5 minutes to complete and at the end you'll see how happy you
            are at work compared to others and get advice about how to improve.
          </Paragraph>
        </ContentWrapper>
      </Flex>
      <Flex
        flexDirection={["column", "row"]}
        justifyContent="center"
        px={[3, 4]}
        py={3}
      >
        <Button
          variant="primary.0"
          width={["100%", "initial"]}
          my={[1, 0]}
          p={2}
          onClick={() => startSurvey(`${getConfig().surveyIds.savantaWhs}`)}
        >
          {t("choose.wfh_button", "")}
        </Button>
      </Flex>
    </Card>
  )
}

export default WelcomeSavanta
