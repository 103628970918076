import { getConfig } from "@engaging-tech/ssr-config"

import { apollo } from "../../../lib/Apollo"
import { LOAD, LOAD_BY_TYPE, TOGGLE_BOOKMARK } from "./bookmark.queries"

const GRAPHQL_API_URL = () => `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

/**
 * Loads the IDs of all items a user has bookmarked.
 */
export const load = () =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD
  })

/**
 * Loads the IDs of all items a user has bookmarked by material type.
 */
export const loadByMaterialType = variables =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD_BY_TYPE,
    variables
  })

/**
 * Toggles a bookmark (creates or deletes).
 * @param {string} refId The ID of the item to toggle a bookmark against.
 * @param {string} refType The type of content that is being bookmarked (job, material etc.)
 * @param {string} refSubType The sub-type of the content that is being bookmarked. This is
 * mainly post types from the business library (articles, lectures, podcasts, research)
 * @param {boolean} isBookmarking A flag to determine if a bookmark is being created or deleted.
 */
export const toggleBookmark = toggle =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: TOGGLE_BOOKMARK,
    variables: toggle
  })
