import { Spinner } from "@engaging-tech/components"
import { Formik } from "formik"
import React, { useState } from "react"

import CreationRedirect from "./CreationRedirect"
import PublishFormCard from "./PublishFormCard"

const Form = ({ render: Render, handleSubmit, isValid, isLoading, postType, ...props }) => {
  const [closeDropdown, setCloseDropdown] = useState(0)

  const forceCloseDropdown = () => {
    setCloseDropdown(closeDropdown + 1)
  }

  return (
    <PublishFormCard
      title={`Publish ${postType}`}
      onSubmit={handleSubmit}
      disableSubmitWhen={!isValid || isLoading}
      postType={postType}
    >
      <Render handleSelect={forceCloseDropdown} handleCloseDropdown={setCloseDropdown} {...props} />
    </PublishFormCard>
  )
}

const PublishForm = ({ onSubmit, initialValues, validation, isLoading, hasCreated, postType, render }) => {
  if (hasCreated) {
    return <CreationRedirect />
  }

  if (isLoading) {
    return <Spinner color="primary.0" />
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={onSubmit}
      validateOnChange
      validateOnBlur
    >
      {props => <Form {...props} isLoading={isLoading} render={render} postType={postType} />}
    </Formik>
  )
}

export default PublishForm
