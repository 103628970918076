import React from "react"

import AppPromotion from "./AppPromotion/AppPromotion"
import ContentBlock from "./ContentBlock"

const MessengerApp = () => (
  <ContentBlock
    noGutter
    // title="The WorkL Messenger App"
    title="The WorkL App"
    subtitle="This mobile app for iOS and Android keeps you supported on the move."
  >
    <AppPromotion />
  </ContentBlock>
)

export default MessengerApp
