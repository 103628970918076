import React from "react"
import { useSelector } from "react-redux"

import NotificationsLoader from "../../features/Notifications/containers/Loader"
import CVListLoader from "../../features/cvLibrary/containers/CVListLoader"
import CareerDeveloperGrid from "../../features/developCareer/containers/Grid"
import { getSurveys } from "../../features/developCareer/store/developCareer.selectors"
import UsersAdviceLoader from "../../features/globalHub/containers/UsersAdviceLoader"
import MentorMatcherLoader from "../../features/mentorMatcher/containers/Loader"
import {
  CountriesLoader,
  HobbiesLoader,
  IndustriesLoader,
  LanguagesLoader,
  SkillsLoader
} from "../../features/misc"
import SurveyDataLoader from "../../features/surveyResults/containers/Loader"
import Loader from "../../features/yourProfile/containers/Loader"
import YourTestsLoader from "../../features/yourTests/containers/Loader"
import PageLayout from "../../layouts/PageLayout"

const YourProfile = () => {
  const surveys = useSelector(getSurveys)

  return (
    <PageLayout bg="dark.7" flexDirection="column" px={[0, 3]} style={{ overflowX: "inherit" }}>
      <Loader />
      {!surveys.loading && surveys.data[0] && <SurveyDataLoader submissionId={surveys.data[0]?.id} />}
      <SkillsLoader />
      <HobbiesLoader />
      <IndustriesLoader />
      <LanguagesLoader />
      <CountriesLoader />
      <NotificationsLoader />
      <CVListLoader />
      <MentorMatcherLoader />
      <YourTestsLoader />
      <UsersAdviceLoader />
      <CareerDeveloperGrid />
    </PageLayout>
  )
}

export default YourProfile
