import React from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"
import { Dialog, UserDetails, Box } from "@engaging-tech/components"

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const UserWrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[5]};
`

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const EndorsementList = ({ users, onClose }) =>
  ReactDOM.createPortal(
    <Dialog
      onToggle={onClose}
      header="Endorsements"
      footer={[
        {
          label: "Close",
          action: onClose
        }
      ]}
    >
      {users.map(user => (
        <UserWrapper key={user.id} pt={1}>
          <UserDetails
            isSmall
            user={user}
            userHoldingImage={profilePlaceholder}
          />
        </UserWrapper>
      ))}
    </Dialog>,
    appRoot()
  )

export default EndorsementList
