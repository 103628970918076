import React from "react"

import { SelectField, Wrapper } from "./selectInput.styled"

export default function SelectInput({ options, value, onChange }) {
  return (
    <Wrapper>
      <SelectField id="select-input" width={1 / 1} value={value} onChange={onChange}>
        <option value="0"> -- </option>
        {options?.map(item => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </SelectField>
    </Wrapper>
  )
}
