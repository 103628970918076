import { call, put, select, takeLatest } from "redux-saga/effects"
import { AccountSelectors } from "../../account"
import { checkcode, load, submit } from "../services/survey.service"
import * as Actions from "./survey.actions"
import { getFormattedSubmission, getIsTestSurvey } from "./survey.selectors"

export function* loadSaga(action) {
  try {
    const id = action.payload.id || action.payload
    const res = yield call(load, id)

    yield put(Actions.loadSuccess(res.data.getSurvey))
    if (action.payload.lang)
      yield put(Actions.changeLanguage(action.payload.lang))
  } catch {
    yield put(Actions.loadFail())
  }
}

export function* submitSaga() {
  try {
    const isAuthenticated = yield select(AccountSelectors.getIsAuthenticated)
    const completedSurvey = yield select(getFormattedSubmission)
    const isTestSurvey = yield select(getIsTestSurvey)
    if (isTestSurvey) {
      yield put(Actions.submitSuccess())
    } else {
      const res = yield call(submit, completedSurvey)
      const submissionId = res.data.createSurveySubmission
      yield put(Actions.submitSuccess(submissionId, isAuthenticated))
    }
  } catch {
    yield put(Actions.submitFail())
  }
}

export function* checkCodeSaga(action) {
  try {
    const res = yield call(checkcode, action.payload)
    const resCode = res.data.isSubmissionCodeValid

    if (resCode > 1) {
      yield put(Actions.checkCodeFail(resCode))
    } else yield put(Actions.checkCodeSuccess())
  } catch (e) {
    yield put(Actions.checkCodeFail())
  }
}

export function* saga() {
  yield takeLatest(Actions.types.LOAD, loadSaga)
  yield takeLatest(Actions.types.SUBMIT, submitSaga)
  yield takeLatest(Actions.types.CHECK_CODE, checkCodeSaga)
}
