const flattenFilters = arr => {
  const result = []
  arr.forEach(item => {
    if (Array.isArray(item)) {
      result.push(...flattenFilters(item))
    } else {
      // if age dropdown select only id
      const itemKey = item?.value ? item.value : item
      if (itemKey?.length) result.push(itemKey)
    }
  })
  return result
}

export const flattenFiltersCustom = arr => {
  const result = []
  arr.forEach(item => {
    if (Array.isArray(item)) {
      result.push(...flattenFiltersCustom(item))
    } else {
      // if age dropdown select only id
      const itemKey = item?.label ? item.label : item
      if (itemKey?.length) result.push(itemKey)
    }
  })
  return result
}

export default flattenFilters
