import React from "react"
import { Modal, Box, Icon, CustomHooks } from "@engaging-tech/components"
import { createPortal } from "react-dom"
import styled from "styled-components"

const ClickableIcon = styled(Icon)`
  cursor: pointer;
  border-radius: 100%;
  :hover {
    background-color: ${({ theme }) => theme.colors.dark[6]};
  }
`

const StyledIFrame = styled.iframe`
  width: 100%;
  height: 100%;
`
const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const TestModal = ({ onToggle, link }) => {
  CustomHooks.useFreshChatDeactivate()
  return createPortal(
    <Modal
      onToggle={onToggle}
      pb={4}
      px={2}
      maxHeight="80vh"
      mb={100}
      flexDirection="column"
      bg="light.0"
      height="90%"
      maxWidth="768px"
    >
      <Box width={1} pt={3} pl={1}>
        <ClickableIcon name="close" p={3} onClick={onToggle} />
      </Box>
      <StyledIFrame src={link} frameBorder="no" />
    </Modal>,
    appRoot()
  )
}

export default TestModal
