import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "@engaging-tech/routing"
import { Flex, Text, MotionFlex } from "@engaging-tech/components"

const cmiLogo = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/third-party-logos/cmi-colour.png`)

const Thumbnail = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px;
`

const CmiLogoImg = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 10px;
`

const TrainingLink = ({ title, url, thumbnailSrc, props }) => {
  const [hovered, setHovered] = useState(false)
  const logoVariants = {
    unhovered: {
      width: "75px",
      height: "47px"
    },
    hovered: {
      width: "78px",
      height: "50px"
    }
  }
  return (
    <Link to={url} newTab external>
      <MotionFlex
        m={2}
        width={["97%", 370]}
        height={[260, 280]}
        elevation={3}
        onHoverStart={() => setHovered(true)}
        onHoverEnd={() => setHovered(false)}
        borderRadius="10px"
        style={{ position: "relative" }}
        {...props}
      >
        <MotionFlex
          bg="light.0"
          justifyContent="center"
          alignItems="center"
          variants={logoVariants}
          animate={hovered ? "hovered" : "unhovered"}
          borderRadius="10px 0 10px 0"
          p={1}
          style={{ position: "absolute", top: "0px", left: "0px" }}
        >
          <CmiLogoImg src={cmiLogo} />
        </MotionFlex>
        <Thumbnail src={thumbnailSrc} />
        <Flex
          width="100%"
          height={60}
          bg="light.0"
          justifyContent="center"
          alignItems="center"
          borderRadius="0 0 10px 10px"
          style={{ position: "absolute", bottom: "0px" }}
        >
          <Text color="primary.0" fontWeight={700} width="auto" fontSize={5}>
            {title}
          </Text>
        </Flex>
      </MotionFlex>
    </Link>
  )
}

export default TrainingLink
