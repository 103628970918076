import { createSelector } from "reselect"

export const getSamlState = state => state.saml

export const getIsLoading = createSelector(
  getSamlState,
  state => state?.isLoading
)

export const getHasLoaded = createSelector(
  getSamlState,
  state => state?.hasLoaded
)

export const getHasLoadedSurveys = createSelector(
  getSamlState,
  state => state?.hasLoadedSurveys
)

export const getIsLoadingSurveys = createSelector(
  getSamlState,
  state => state?.isLoadingSurveys
)

export const getSurveys = createSelector(getSamlState, state => state?.surveys)
