import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Spinner } from "@engaging-tech/components"

import { happinessSurveySelectors, happinessSurveyActions } from "../index"

const SubmitterComponent = ({ onLoad, isSubmitting, hasSubmitted }) => {
  useEffect(() => {
    if (!hasSubmitted) {
      onLoad()
    }
  }, [onLoad, hasSubmitted])

  return isSubmitting ? <Spinner color="light.0" /> : null
}

const mapState = state => ({
  isSubmitting: happinessSurveySelectors.getIsSubmitting(state),
  hasSubmitted: happinessSurveySelectors.getHasSubmitted(state)
})

const mapDispatch = (dispatch) => ({
  onLoad: () => dispatch(happinessSurveyActions.submit())
})

const Submitter = connect(mapState, mapDispatch)(SubmitterComponent)

export default Submitter
