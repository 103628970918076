import { useEffect } from "react"
import { withRouter } from "react-router-dom"

import { apolloInAppView } from "../../../lib/ApolloInAppView"

const TokenAuth = ({ location }) => {
  useEffect(() => {
    const token = new URLSearchParams(location.search).get("token")

    apolloInAppView.saveToken(token)
  }, [location.search])
  return null
}

export default withRouter(TokenAuth)
