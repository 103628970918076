import { pipe } from "@engaging-tech/frontend-utils"

const extractPaths = paths => Object.keys(paths)
const removeIndexPath = paths => paths.filter(path => path !== "index")
const removeBookmarkString = paths =>
  paths.map(path => ({
    ...path,
    description: path.description.replace("bookmarked", "")
  }))

const mapCategoryRoutes = (paths, allPaths) =>
  paths.map(path => ({
    description: path,
    path: allPaths[path].index
  }))

const mapPathsToCategories = paths =>
  pipe(
    extractPaths,
    removeIndexPath,
    filteredPaths => mapCategoryRoutes(filteredPaths, paths),
    removeBookmarkString
  )(paths)

export default mapPathsToCategories
