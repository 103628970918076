import { connect } from "react-redux"
import LatestScoreComponent from "../components/LatestScore"
import { getHappinessSixSteps } from "../../../surveyResults/HappinessSixSteps/store/selectors"
import { surveyResultsSelectors } from "../../../surveyResults"
import { getSurveys } from "../../store/developCareer.selectors"

const mapState = state => ({
  isLoading: surveyResultsSelectors.getIsLoading(state),
  latestSurveys: getSurveys(state),
  sixSteps: getHappinessSixSteps(state)
})

const LatestScore = connect(mapState, null)(LatestScoreComponent)

export default LatestScore
