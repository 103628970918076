import { Text } from "@engaging-tech/components"
import styled from "styled-components"

export const Title = styled(Text)`
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
`

export const Subtitle = styled(Text)`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`

export const FilterSearchTitle = styled(Text)`
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
`

export const FilterTitle = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`
