import React from "react"
import { UserDetails, Card, Text } from "@engaging-tech/components"

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const FeaturedText = ({ content }) => (
  <Card width={1 / 1} p={3}>
    <UserDetails
      user={content.user}
      time={content.publishedAt}
      isSmall
      userHoldingImage={profilePlaceholder}
    />
    <Text fontSize={3} fontWeight="500">
      {content.title}
    </Text>
  </Card>
)

export default FeaturedText
