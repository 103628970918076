import { Card, Flex, Icon, MotionFlex, Text, ToggleLogic, UserDetails } from "@engaging-tech/components"
import { Link, usePaths, useRouter } from "@engaging-tech/routing"
import { AnimatePresence } from "framer-motion"
import React, { useCallback } from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"

import BookmarkToggle from "../../../bookmarks/containers/BookmarkToggle"
import ConnectModal from "../../../connect/containers/ConnectModal"
import Thumbnail from "../../../userGeneratedContent/components/Attachments/Thumbnail"
import { getUserId } from "../../../yourProfile/store/yourProfile.selectors"

const postPlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/WorkL_Logo_TEAL.png`)

const EllipsisText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const getContentTypeAttributes = (type, paths) => {
  switch (type) {
    case "articles":
      return {
        colour: "#8ABFEC",
        title: "Article",
        icon: "edit",
        key: "materialRef",
        url: paths.resourceCentre.browse.articles.selectedArticle,
        enableBookmarkToggle: true
      }
    case "research":
      return {
        colour: "#ed8a92",
        title: "Research",
        icon: "rate_review",
        key: "materialRef",
        url: paths.resourceCentre.browse.research.selectedResearch,
        enableBookmarkToggle: true
      }
    case "lectures":
      return {
        colour: "#91f2aa",
        title: "Lecture",
        icon: "record_voice_over",
        key: "materialRef",
        url: paths.resourceCentre.browse.lectures.selectedLecture,
        enableBookmarkToggle: true
      }
    case "podcasts":
      return {
        colour: "#FFEA76",
        title: "Podcast",
        icon: "wifi_tethering",
        key: "materialRef",
        url: paths.resourceCentre.browse.podcasts.selectedPodcast,
        enableBookmarkToggle: true
      }
    case "events":
      return {
        colour: "#ABFAF8",
        title: "Event",
        icon: "insert_invitation",
        key: "eventRef",
        url: paths.globalHub.selectedPost
      }
    default:
      return {
        colour: "#8ABFEC",
        title: "Post",
        icon: "edit",
        key: "materialRef",
        url: paths.globalHub.index
      }
  }
}

const getDayDiff = publishedAt => {
  const dateCreatedOn = new Date(publishedAt)
  const dateNow = new Date()
  dateCreatedOn.setHours(0, 0, 0, 0)
  dateNow.setHours(0, 0, 0, 0)
  const msInDay = 24 * 60 * 60 * 1000
  const dayDiff = (+dateNow - +dateCreatedOn) / msInDay
  return dayDiff
}

const PostUserDetails = ({ post }) => {
  const localPost = { ...post, user: { ...post.user, jobTitle: "" } }
  const currentUserId = useSelector(getUserId)
  const router = useRouter()
  const paths = usePaths()

  const handleOnUserClick = useCallback(
    toggle => {
      if (!localPost.user) return null
      if (localPost.user.id !== currentUserId) return toggle()
      return router.navigate(paths.careerDeveloper.yourProfile)
    },
    [currentUserId, paths.careerDeveloper.yourProfile, router, localPost]
  )
  return (
    <ToggleLogic>
      {({ on, toggle }) => (
        <>
          <UserDetails
            width="100%"
            user={localPost.user}
            timeMargin="4px"
            disableAvatar
            mb={2}
            handleOnUserClick={() => handleOnUserClick(toggle)}
            time={localPost.publishedAt}
          />

          <AnimatePresence>{on && <ConnectModal {...post.user} onToggle={toggle} />}</AnimatePresence>
        </>
      )}
    </ToggleLogic>
  )
}

const NewLabel = () => {
  return (
    <MotionFlex
      position="absolute"
      animate={{ scale: [1, 1.15] }}
      transition={{
        repeatType: "mirror",
        repeat: Infinity
      }}
      style={{ bottom: "10px", right: "10px" }}
      bg="#5AEEB8"
      width={44}
      height={24}
      justifyContent="center"
      alignItems="center"
      borderRadius={3}
    >
      <Text fontSize="12px" width="auto" fontWeight={700} style={{ cursor: "default" }}>
        NEW!
      </Text>
    </MotionFlex>
  )
}

const ContentPost = ({ post, type }) => {
  const paths = usePaths()

  const dayDiff = getDayDiff(post.publishedAt)

  const contentAttributes = getContentTypeAttributes(type, paths)

  const postId = type === "events" ? post?.id : post?.[contentAttributes.key]?.id

  return (
    <Link
      newTab
      to={`${contentAttributes.url.replace(":id", postId)}?returnTo=${paths.careerDeveloper.index}`}
    >
      <Card
        bg="light.0"
        my={1}
        mr={2}
        ml={1}
        width={[230, 310]}
        height={[230, 240]}
        style={{ flexShrink: 0 }}
        position="relative"
      >
        <Flex
          height={22}
          width="100%"
          bg={contentAttributes.colour}
          style={{ borderRadius: "6px 6px 0px 0px" }}
          justifyContent="center"
          alignItems="center"
        >
          <Text width="auto" fontWeight="700" fontSize="14px">
            {contentAttributes.title}
          </Text>
        </Flex>
        <Flex width="100%" height={130} position="relative">
          {post?.[contentAttributes.key]?.coverImage?.source ? (
            <Thumbnail
              src={post[contentAttributes.key].coverImage?.source}
              width="100%"
              disableScaling
              minHeight={0}
              height="100%"
              hasPlayButton={type === "podcast"}
            />
          ) : (
            <Flex
              height="100%"
              width="100%"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              bg="dark.4"
            >
              <Flex flexDirection="column" width="auto" height="auto" pt={2}>
                <img src={postPlaceholder} height="40px" alt="Post placeholder" />
                <Text ml={2} mt={2} fontSize={5} width="auto" fontWeight={700}>
                  {contentAttributes?.title}
                </Text>
              </Flex>
            </Flex>
          )}
          {dayDiff < 31 && <NewLabel />}
        </Flex>

        <Flex width="100%" flexDirection="row" px={2} mt={2}>
          <Flex
            width={22}
            height={22}
            mr="10px"
            flexShrink={0}
            bg={contentAttributes.colour}
            borderRadius={4}
            justifyContent="center"
            alignItems="center"
          >
            <Icon name={contentAttributes.icon} color="white" size={17} />
          </Flex>
          <Flex flexDirection="column" width="calc(100% - 22px - 10px)">
            <EllipsisText fontWeight={700} fontSize={4} width="100%">
              {post?.[contentAttributes.key]?.title}
            </EllipsisText>
            <PostUserDetails post={post} />
          </Flex>
        </Flex>
        {contentAttributes?.enableBookmarkToggle && (
          <Flex width="auto" position="absolute" style={{ right: "5px", bottom: "5px" }}>
            <BookmarkToggle
              id={post[contentAttributes.key]?.id}
              postType={post[contentAttributes.key]?.type}
            />
          </Flex>
        )}
      </Card>
    </Link>
  )
}

export default ContentPost
