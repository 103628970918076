/* eslint-disable react/jsx-curly-newline */
import { Flex, MobileOnly, Radio, Spinner, Table, TabletUp, Text } from "@engaging-tech/components"
import { titleCaps } from "@engaging-tech/frontend-utils"
import { Link, useLocation, usePaths } from "@engaging-tech/routing"
import { format } from "date-fns"
import React from "react"
import styled from "styled-components"

import ClickableIcon from "../../ui/components/ClickableIcon"
import { CVColumnHeaders, CVTableConfig } from "./CVTableLib"

const BorderBottom = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[5]};
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
  :last-child {
    border-bottom: none;
  }
`

const EmptyState = () => <Text color="dark.2">Your created CVs will appear below once completed</Text>

const EditIcons = ({ id, defaultCv, deleteCV }) => {
  const paths = usePaths()
  const { pathname } = useLocation()

  return (
    <Flex justifyContent={["flex-end", "flex-start"]}>
      <Link
        to={
          pathname.includes("in-app-view")
            ? paths.inAppViewSelectedCV.replace(":id", id)
            : `${paths.careerDeveloper.cvLibrary.index}/cv/${id}`
        }
      >
        <ClickableIcon name="open_in_new" color="primary.0" />
      </Link>

      <Link
        to={
          pathname.includes("in-app-view")
            ? paths.inAppViewEditCV.replace(":id", id)
            : `${paths.careerDeveloper.cvLibrary.index}/${id}/edit`
        }
      >
        <ClickableIcon name="edit" color="primary.0" mx="14%" />
      </Link>

      <ClickableIcon name="delete" color="primary.0" onClick={() => deleteCV({ id, isDefault: defaultCv })} />
    </Flex>
  )
}

const DefaultRadio = ({ setDefaultCV, defaultCv, id }) => (
  <Radio
    type="radio"
    colour={defaultCv ? "primary.0" : "dark.2"}
    checked={defaultCv}
    // checked
    onClick={() => {
      setDefaultCV(id)
    }}
    onChange={() => null}
    value={defaultCv}
    id={id}
  />
)

const TabletUpView = ({ cvList, deleteCV, setDefaultCV }) => {
  const location = useLocation()

  const inAppView = location.pathname.includes("in-app-view")

  const rowData = cvList?.map(cv => {
    const cvUrl = `/develop-career/cv-library/cv/${cv.id}`

    return {
      role: (
        <Link to={inAppView ? `/in-app-view${cvUrl}` : cvUrl}>{titleCaps(cv.targetPosition.jobTitle)}</Link>
      ),
      company: cv.targetPosition.company,
      date: format(new Date(cv.createdAt), "dd MMM yyyy"),
      isDefault: <DefaultRadio setDefaultCV={setDefaultCV} {...cv} />,
      edit: <EditIcons {...cv} deleteCV={deleteCV} />
    }
  })
  return (
    <TabletUp width="100%">
      <Table headings={CVColumnHeaders} config={CVTableConfig} rows={rowData} />
    </TabletUp>
  )
}

const MobileOnlyView = ({ cvList, deleteCV, setDefaultCV }) => (
  <MobileOnly width="100%">
    {cvList?.map(cv => (
      <BorderBottom key={`Mobile-${cv.id}`} flexDirection="column" mb={2}>
        <Flex justifyContent="space-between">
          <Text color="dark.2">{CVColumnHeaders[0].title}</Text>
          <EditIcons {...cv} deleteCV={deleteCV} />
        </Flex>
        <Text color="primary.0" fontWeight={700} mb={3}>
          <Link to={`/develop-career/cv-library/cv/${cv.id}`}>{cv.targetPosition?.jobTitle}</Link>
        </Text>
        <Text color="dark.2" mb={2}>
          {CVColumnHeaders[1].title}
        </Text>
        <Text mb={3}>{cv.targetPosition?.company}</Text>
        <Text color="dark.2" mb={2}>
          {CVColumnHeaders[2].title}
        </Text>
        <Text mb={3}>{format(new Date(cv.createdAt), "dd MMM yyyy")}</Text>
        <Flex mb={3}>
          <DefaultRadio setDefaultCV={setDefaultCV} {...cv} />
          <Text color="dark.2" mb={2}>
            {CVColumnHeaders[3].title}
          </Text>
        </Flex>
      </BorderBottom>
    ))}
  </MobileOnly>
)

const CVList = ({ cvList, deleteCV, setDefaultCV, isLoadingCVs }) => {
  const location = useLocation()

  const isInAppView = location.pathname.includes("in-app-view")

  if (isLoadingCVs) return <Spinner color="primary.0" width={1 / 1} />
  if (cvList?.length === 0) return <EmptyState />
  return (
    <Flex flexDirection="column">
      <TabletUpView
        cvList={cvList}
        deleteCV={props => deleteCV({ ...props, place: isInAppView ? "in-app-view" : "" })}
        setDefaultCV={id => setDefaultCV({ id, place: isInAppView ? "in-app-view" : "" })}
      />
      <MobileOnlyView
        cvList={cvList}
        deleteCV={props => deleteCV({ ...props, place: isInAppView ? "in-app-view" : "" })}
        setDefaultCV={id => setDefaultCV({ id, place: isInAppView ? "in-app-view" : "" })}
      />
    </Flex>
  )
}

export default CVList
