import { getConfig } from "@engaging-tech/ssr-config"

import { apollo } from "../../../lib/Apollo"
import { newConfirmSubmissionToken } from "../../../lib/SubmissionTokenCookies"
import { ATTACH_USER, LOAD, SUBMIT } from "./happinessSurvey.queries"

const GRAPHQL_API_URL = () => `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

export const load = id =>
  apollo.query({
    uri: GRAPHQL_API_URL,
    query: LOAD,
    variables: { id }
  })

export const submit = ({
  id,
  templateId,
  answers,
  groups,
  companyName,
  savantaId,
  savantaTerritory,
  surveyCompanyId
}) =>
  apollo.mutate({
    uri: GRAPHQL_API_URL,
    mutation: SUBMIT,
    variables: {
      id,
      templateId,
      answers,
      groups,
      companyName,
      savantaId,
      savantaTerritory,
      surveyCompanyId
    }
  })

export const attachUserToSubmission = (id, isAuthenticated) =>
  apollo.mutate({
    uri: GRAPHQL_API_URL,
    mutation: ATTACH_USER,
    variables: {
      id,
      token: !isAuthenticated ? newConfirmSubmissionToken() : null
    }
  })
