import { connect } from "react-redux"
import { checkCode } from "../store/survey.actions"
import {
  getCodeAlreadyUsed,
  getHasValidCode,
  getId,
  getIndividualCode,
  getStatus
} from "../store/survey.selectors"

const mapState = state => ({
  individualCode: getIndividualCode(state),
  isValidCode: getHasValidCode(state),
  codeAlreadyUsed: getCodeAlreadyUsed(state),
  surveyId: getId(state),
  status: getStatus(state)
})

const mapDispatch = dispatch => ({
  checkCode: payload => dispatch(checkCode(payload))
})

const codeCheckContainer = connect(mapState, mapDispatch)

export default codeCheckContainer
