/* eslint-disable import/no-dynamic-require, react/jsx-props-no-spreading */
import { Box, Button } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React from "react"

const BackBar = ({ text, to, visible }) => {
  return visible ? (
    <Box width="100vw" bg="white" px={3} py={3}>
      <Link to={to}>
        <Button elevation={0} variant="text.dark.2" leadingIcon="arrow_back">
          {text}
        </Button>
      </Link>
    </Box>
  ) : null
}

export default BackBar
