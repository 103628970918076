import { connect } from "react-redux"
import { setShowModal } from "../../../../yourProfile/store/yourProfile.actions"
import * as yourProfileSelectors from "../../../../yourProfile/store/yourProfile.selectors"
import { getIsPremium } from "../../../../yourProfile/store/yourProfile.selectors"
import CancelledMembershipModalComponent from "../../components/ProfileSettingsModal/CancelledMembershipModal"

const mapState = state => ({
  isPremium: getIsPremium(state),
  premiumExpiration: yourProfileSelectors.getPremiumExpiration(state)
})

const mapDispatch = dispatch => ({
  setVisibility: type => dispatch(setShowModal(type))
})

const CancelledMembershipModal = connect(
  mapState,
  mapDispatch
)(CancelledMembershipModalComponent)

export default CancelledMembershipModal
