const EbookTypes = {
  2: "DRM PDF",
  6: "DRM UPUB"
}

export const extractBooksFromOrder = order => {
  if (!order.eBooks) return {}
  const myEbooks = {}
  order.eBooks.forEach(eBook => {
    const { ean, eBookType, maxDownloads, downloadLink } = eBook
    if (!ean || !downloadLink) return
    const type = EbookTypes[eBookType] ? "ebook" : "audiobook"
    myEbooks[ean] = {
      ean,
      type,
      maxDownloads,
      downloadLink,
      source: "gardners"
    }
  })
  return myEbooks
}

export const addBooksExtraInfo = (booksPurchasedObj, booksInfo) => {
  booksInfo.forEach(bookInfo => {
    const { ean, image, title, authors } = bookInfo
    const bicCodes = bookInfo.bic_codes
    const categories = bicCodes.map(bicCode => ({
      code: bicCode.code
    }))
    const myAuthors = authors.map(author => author.name)
    Object.assign(booksPurchasedObj[ean], {
      image: `https://gardnersmedia.engaging.works${image}`,
      title,
      authors: myAuthors,
      categories
    })
  })
}
