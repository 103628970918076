import { connect } from "react-redux"

import UnverifiedStepComponent from "../components/UnverifiedStep"

import { yourProfileActions, yourProfileSelectors } from "../../yourProfile"

const mapState = state => ({
  isVerificationEmailSending: yourProfileSelectors.getIsVerificationEmailSending(
    state
  ),
  isVerificationEmailSent: yourProfileSelectors.getIsVerificationEmailSent(
    state
  )
})

const mapDispatch = dispatch => ({
  sendVerification: () => dispatch(yourProfileActions.requestConfirmation())
})

/**
 * A banner prompting a user to confirm their email address.
 */
const UnverifiedStep = connect(mapState, mapDispatch)(UnverifiedStepComponent)

export default UnverifiedStep
