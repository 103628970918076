/* eslint-disable import/prefer-default-export */
import { Flex, Text } from "@engaging-tech/components"
import styled from "styled-components"

export const Subtitle = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 20.8px;
  color: #003e4c;
`

export const SubtitleUnderline = styled(Text)`
  font-weight: 700;
  font-size: 16px;
  line-height: 20.8px;
  color: #003e4c;

  text-decoration: underline;
`

export const LoadMoreWrapper = styled(Flex)`
  width: 100%;
  height: 60px;

  margin-bottom: 20px;

  justify-content: center;
  align-items: center;
`
