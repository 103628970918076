import React from "react"
import { H2, Text, Box, Paragraph } from "@engaging-tech/components"
import { useTranslation } from "react-i18next"
import { translationMap } from "../../lib/utils"

import { getLabelData } from "../../../../../lib/utils"
import SuggestedAction from "./SuggestedAction"

const ActionPlansPoint = ({ questionInfo, modalInfoIndex, stageIndex }) => {
  const { t } = useTranslation(["results"])

  if (typeof modalInfoIndex !== "number") return null
  const score = questionInfo.values.score * 10
  return (
    <Box width={1 / 1} pb={5}>
      <H2 fontSize={5} fontWeight={500} color="dark.1" pt={3} textAlign="left">
        {questionInfo.question}
      </H2>
      <Paragraph color="dark.2" fontSize={3} fontWeight={500} textAlign="left">
        {t("action_plan.yourScore", "")}:
        <Text
          fontSize={3}
          fontWeight={500}
          color={getLabelData(score).color}
          pl={1}
          width="auto"
        >
          {` ${score}%`}
        </Text>
      </Paragraph>

      <Paragraph
        textAlign="left"
        fontSize={3}
        pb={2}
        fontWeight={500}
        color="dark.2"
        lineHeight="1.4"
      >
        {t(
          `action_plan.stage.${stageIndex}.modalInfo.${modalInfoIndex}.explanation`,
          ""
        )}
      </Paragraph>

      {translationMap(
        t,
        `action_plan.stage.${stageIndex}.modalInfo.${modalInfoIndex}.explanationChildren`
      ).map(child => (
        <Paragraph
          textAlign="left"
          fontSize={3}
          fontWeight={500}
          color="dark.2"
          lineHeight="1.4"
        >
          {child}
        </Paragraph>
      ))}
      {translationMap(
        t,
        `action_plan.stage.${stageIndex}.modalInfo.${modalInfoIndex}.suggestedActions`
      ).map((_, index) => (
        <SuggestedAction
          suggestedActionIndex={index}
          modalInfoIndex={modalInfoIndex}
          stageIndex={stageIndex}
        />
      ))}
    </Box>
  )
}

export default ActionPlansPoint
