import React from "react"
import styled from "styled-components"
import { Flex, Text } from "@engaging-tech/components"

const Wrapper = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[5]};
  cursor: pointer;
`

const Expander = ({ onExpand, text }) => (
  <Wrapper pb={3} mb={3} onClick={onExpand}>
    <Text fontSize={3} color="primary.0" fontWeight="500">
      {text}
    </Text>
  </Wrapper>
)

export default Expander
