import { connect } from "react-redux"

import { getSurveyType } from "../../../surveyResults/store/surveyResults.selectors"
import HappinessDataHighlightComponent from "../components/HappinessDataHighlight"
import * as Selectors from "../store/happinessdatahighlights.selectors"

const mapState = (state, props) => ({
  // happinessDatahighlightData: Selectors.getHappinessDatahighlightData(state)
  happinessScore: Selectors.getHappinessScore(state),
  industryScore: Selectors.getIndustryScore(state),
  globalScore: Selectors.getGlobalScore(state),
  completedAt: Selectors.getCompletedAt(state),
  happinessChange: Selectors.getHappinessScoreChange(state, props.submissionId),
  type: getSurveyType(state),
  sharable: !props.isolated
})

const HappinessDataHighlight = connect(mapState)(HappinessDataHighlightComponent)

export default HappinessDataHighlight
