import { useEffect } from "react"
import { connect } from "react-redux"

import { loadSurveys } from "../store/developCareer.actions"

const LoaderComponent = ({ onLoadSurveys }) => {
  useEffect(() => {
    onLoadSurveys()
  }, [onLoadSurveys])
  return null
}

const mapDispatch = dispatch => ({
  onLoadSurveys: () => dispatch(loadSurveys())
})

const Loader = connect(null, mapDispatch)(LoaderComponent)

export default Loader
