import {
  Text,
  TextField,
  Box,
  Spinner,
  Select,
  Checkbox
} from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"
import { getCountries } from "@engaging-tech/frontend-utils"
import { Formik } from "formik"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { string, object } from "yup"
import PrimaryButton from "../../../ui/components/PrimaryButton"
import { Types } from "../../lib/deliveryTypes"
import CardDetails from "./CardDetails"

const deliveryTypes = Types

/**
 * A form with a visibility toggle. We need to use this because Stripe
 * will break if we use conditional rendering.
 */
const HidableForm = styled.form`
  display: ${({ isVisible }) => (isVisible ? "block" : "none ")};
  width: 100%;
`

const Form = ({
  handleSubmit,
  errors,
  touched,
  values,
  handleChange,
  handleBlur,
  isLoading,
  isValid,
  submitContent,
  setDeliveryType,
  hasDeliveryCosts
}) => {
  const [error, setError] = useState(null)
  const [isComplete, setIsComplete] = useState(false)
  const countries = getCountries()
  const paths = usePaths()

  const handleStripeChange = ({ error: stripeError, complete }) => {
    setIsComplete(complete)
    setError(stripeError ? stripeError.message : null)
  }
  if (!hasDeliveryCosts) setDeliveryType("0")

  return (
    <>
      {isLoading && (
        <Spinner
          color="primary.0"
          width={1 / 1}
          py={3}
          justifyContent="center"
        />
      )}
      <HidableForm onSubmit={handleSubmit} isVisible={!isLoading}>
        <Box mb={2}>
          <TextField
            label="Email Address"
            width={1 / 1}
            name="emailAddress"
            id="email-address"
            type="email"
            value={values.emailAddress}
            error={touched.emailAddress && errors.emailAddress}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Address - first line"
            width={1 / 1}
            name="addressFirstLine"
            id="address-first-line"
            type="text"
            value={values.addressFirstLine}
            error={touched.addressFirstLine && errors.addressFirstLine}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Address - second line"
            width={1 / 1}
            name="addressSecondLine"
            id="address-second-line"
            helperText="Optional"
            type="text"
            value={values.addressSecondLine}
            error={touched.addressSecondLine && errors.addressSecondLine}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Post code"
            width={1 / 1}
            name="postcode"
            id="postcode"
            type="text"
            value={values.postcode}
            error={touched.postcode && errors.postcode}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="City"
            width={1 / 1}
            name="city"
            id="city"
            type="text"
            value={values.city}
            error={touched.city && errors.city}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="County/province"
            width={1 / 1}
            name="province"
            id="province"
            type="text"
            helperText="Optional"
            value={values.province}
            error={touched.province && errors.province}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Box>
        <Box mb={2}>
          <Select
            name="country"
            id="country"
            onChange={e => {
              setDeliveryType("")
              handleChange(e)
            }}
            onBlur={handleBlur}
            value={values.country}
            label="Country"
          >
            {countries.map(country => (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </Select>
        </Box>

        <Box mb={2}>
          <Select
            name="deliveryType"
            id="deliveryType"
            onChange={e => {
              handleChange(e)
              setDeliveryType(e.target.value)
            }}
            onBlur={handleBlur}
            value={values.deliveryType}
            label="DeliveryType"
          >
            <option value=""> </option>
            {deliveryTypes.map(dType => (
              <option
                key={dType.code}
                value={dType.code}
                disabled={
                  dType.code === "0" ||
                  (values.country === "GB" && dType.code !== "1") ||
                  (values.country !== "GB" && dType.code === "1") ||
                  !hasDeliveryCosts
                }
              >
                {`${dType.name} - £${dType.price.toFixed(2)}`}
              </option>
            ))}
          </Select>
        </Box>

        <Box mb={2}>
          <TextField
            label="Cardholder Name"
            width={1 / 1}
            name="cardholderName"
            id="cardholder-name"
            type="text"
            value={values.cardholderName}
            error={touched.cardholderName && errors.cardholderName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Box>
        <Box mb={2}>
          <CardDetails onChange={handleStripeChange} />
        </Box>
        <Box mb={2}>
          <Checkbox
            mb={3}
            ml={3}
            colour="dark.2"
            id="sameBilling"
            checked={values.sameBilling}
            onChange={handleChange}
            onBlur={handleBlur}
            label={() => (
              <Text color="dark.2" fontSize={[4, 3]}>
                Billing address same as delivery address
              </Text>
            )}
          />
        </Box>
        {!values.sameBilling && (
          <>
            <Box mb={2}>
              <Text color="dark.2" fontSize={4}>
                Billing informations
              </Text>
            </Box>
            <Box mb={2}>
              <TextField
                label="Address - first line"
                width={1 / 1}
                name="addressFirstLineBilling"
                id="address-first-line-billing"
                type="text"
                value={values.addressFirstLineBilling}
                error={
                  touched.addressFirstLineBilling &&
                  errors.addressFirstLineBilling
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
            <Box mb={2}>
              <TextField
                label="Address - second line"
                width={1 / 1}
                name="addressSecondLineBilling"
                id="address-second-line-billing"
                helperText="Optional"
                type="text"
                value={values.addressSecondLineBilling}
                error={
                  touched.addressSecondLineBilling &&
                  errors.addressSecondLineBilling
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
            <Box mb={2}>
              <TextField
                label="Post code"
                width={1 / 1}
                name="postcodeBilling"
                id="postcode-billing"
                type="text"
                value={values.postcodeBilling}
                error={touched.postcodeBilling && errors.postcodeBilling}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
            <Box mb={2}>
              <TextField
                label="City"
                width={1 / 1}
                name="cityBilling"
                id="city-billing"
                type="text"
                value={values.cityBilling}
                error={touched.cityBilling && errors.cityBilling}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
            <Box mb={2}>
              <TextField
                label="County/province"
                width={1 / 1}
                name="provinceBilling"
                id="province-billing"
                type="text"
                helperText="Optional"
                value={values.provinceBilling}
                error={touched.provinceBilling && errors.provinceBilling}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
            <Box mb={26}>
              <Select
                name="country"
                id="country"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.country}
                label="Country"
              >
                {countries.map(country => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </Select>
            </Box>
          </>
        )}
        <PrimaryButton
          type="submit"
          disabled={!isValid || !isComplete || error || isLoading}
          width={260}
          color="grey.0"
          text={submitContent.text}
          ml="auto"
          mr="auto"
          mt={4}
          mb={4}
        />
        <Box>
          <Text color="dark.2" fontSize={2}>
            By placing your order you agree to our{" "}
            <Link
              color="primary.0"
              fontWeight={700}
              to={paths.legal.termsAndConditions}
            >
              {" "}
              Terms & Conditions
            </Link>
            ,{" "}
            <Link
              color="primary.0"
              fontWeight={700}
              to={paths.legal.privacyPolicy}
            >
              {" "}
              privacy{" "}
            </Link>{" "}
            and{" "}
            <Link
              color="primary.0"
              fontWeight={700}
              to={paths.legal.returnPolicy}
            >
              {" "}
              returns policy{" "}
            </Link>
            . You also consent to some of your data being data being stored by
            WorkL, which may be used to make future future shopping experiences
            better for you.
          </Text>
        </Box>
      </HidableForm>
    </>
  )
}

const validation = object().shape({
  cardholderName: string("Please enter the name on the card").required(
    "A cardholder name is required"
  ),
  emailAddress: string("Please enter your email address")
    .email("Please enter a valid email address")
    .required("An email address is required"),
  addressFirstLine: string("Please enter your address").required(
    "An address is required"
  ),
  addressSecondLine: string(),
  postcode: string("Please enter your postal code").required(
    "A valid postal code is required"
  ),
  city: string("Please enter your town or city").required(
    "A valid town or city is required"
  ),
  province: string("Please enter your county, province or state"),
  country: string().required(),
  deliveryType: string().required()
})

const initialValues = {
  cardholderName: "",
  emailAddress: "",
  addressFirstLine: "",
  addressSecondLine: "",
  postcode: "",
  city: "",
  province: "",
  country: "GB",
  addressFirstLineBilling: "",
  addressSecondLineBilling: "",
  postcodeBilling: "",
  cityBilling: "",
  provinceBilling: "",
  countryBilling: "GB",
  sameBilling: true,
  deliveryType: ""
}

const CheckoutForm = ({
  stripe,
  onSubmit,
  isLoading,
  onPaymentActionSuccess,
  onPaymentActionFail,
  intentSecret,
  userDetails,
  isPremium,
  setDeliveryType,
  hasDeliveryCosts
}) => {
  const [isStripeLoading, setIsStripeLoading] = useState(false)
  const [billingEmail, setBillingEmail] = useState("")

  const { firstName, lastName, email } = userDetails
  const userName = `${firstName} ${lastName}`

  const handleSubmit = async formDetails => {
    setIsStripeLoading(true)
    const delivery = deliveryTypes.find(
      deliveryItem => deliveryItem.code === formDetails.deliveryType
    )
    const order = {
      Order: {
        TitleName: userName,
        InvoiceName: email,
        InvoiceAddressLine1:
          formDetails.addressFirstLineBilling || formDetails.addressFirstLine,
        InvoiceAddressLine2:
          formDetails.addressSecondLineBilling || formDetails.addressSecondLine,
        InvoicePostCode: formDetails.postcodeBilling || formDetails.postcode,
        InvoiceCountry: formDetails.countryBilling || formDetails.country,
        DeliveryTitleName: "Dear",
        DeliveryName: userName,
        DeliveryAddressLine1: formDetails.addressFirstLine,
        DeliveryAddressLine2: formDetails.addressSecondLine,
        DeliveryPostCode: formDetails.postcode,
        DeliveryCountry: formDetails.country,
        Delivery: delivery.price || "0"
      },
      PostageMethod: parseInt(delivery.code, 10) || 0,
      Ewp: isPremium
    }

    const deliveryAddress = {
      line1: formDetails.addressFirstLine,
      line2: formDetails.addressSecondLine,
      city: formDetails.city,
      state: formDetails.province,
      country: formDetails.country,
      postal_code: formDetails.postcode
    }
    const billingAddress = {
      line1: formDetails.addressFirstLineBilling,
      line2: formDetails.addressSecondLineBilling,
      city: formDetails.cityBilling,
      state: formDetails.provinceBilling,
      country: formDetails.countryBilling,
      postal_code: formDetails.postcodeBilling
    }

    await stripe.createSource({
      type: "card",
      owner: {
        name: formDetails.cardholderName,
        email: formDetails.emailAddress,
        address: formDetails.sameBilling ? deliveryAddress : billingAddress
      }
    })

    setBillingEmail(formDetails.emailAddress)
    onSubmit.action(order)
    setIsStripeLoading(false)
  }

  const handleCardPayment = async () => {
    setIsStripeLoading(true)
    const { error, paymentIntent } = await stripe.handleCardPayment(
      intentSecret,
      {
        receipt_email: billingEmail
      }
    )
    setBillingEmail("")
    if (error || !paymentIntent) onPaymentActionFail.action()
    else onPaymentActionSuccess.action()
    setIsStripeLoading(false)
  }

  useEffect(() => {
    if (intentSecret) handleCardPayment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intentSecret])

  return (
    <Formik
      initialValues={{
        ...initialValues,
        deliveryType: hasDeliveryCosts ? "" : "0"
      }}
      validationSchema={validation}
      onSubmit={handleSubmit}
      render={props => (
        <Form
          isLoading={isStripeLoading || isLoading}
          submitContent={onSubmit}
          setDeliveryType={setDeliveryType}
          hasDeliveryCosts={hasDeliveryCosts}
          {...props}
        />
      )}
    />
  )
}

export default CheckoutForm
