/* eslint-disable react/no-array-index-key,no-nested-ternary */
import { Box, Flex, Modal } from "@engaging-tech/components"
import React, { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { useSelector } from "react-redux"

import OutlineButton from "../../../features/ui/components/OutlineButton"
import PrimaryButton from "../../../features/ui/components/PrimaryButton"
import { FilterSearchTitle, FilterTitle, Subtitle, Title } from "./demographicFilter.styles"
import MultiSelectInput from "./multiSelectInput"
import SelectInput from "./selectInput"
import { getDemographicFiltersList } from "./store/demographicFilter.selectors"

const appRoot = () => typeof document !== "undefined" && document.getElementById("root")

const capitalise = text => {
  let textResult = ""

  text.split("_").forEach((part, index) => {
    if (index === 0) textResult += part.charAt(0).toUpperCase() + part.slice(1)
    else textResult += ` ${part.charAt(0).toUpperCase() + part.slice(1)}`
  })

  return textResult
}

export default function DemographicFilter({ filters, toggle, onApplyFilters }) {
  const [filtersValues, setFiltersValues] = useState({})

  const demographicFiltersList = useSelector(getDemographicFiltersList)

  useEffect(() => {
    if (Object.keys(filters)?.length) {
      const newFilters = {}

      Object.keys(filters).forEach(filter => {
        if (Array.isArray(filters[filter])) {
          newFilters[filter] = filters[filter]?.map(item => ({
            ...item,
            label: item.label.split(": ")[1]
          }))
        } else {
          newFilters[filter] = {
            ...filters[filter],
            label: filters[filter].label?.split(": ")[1]
          }
        }
      })

      setFiltersValues(newFilters)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return createPortal(
    <Modal
      bg="light.0"
      flexDirection="column"
      animated
      disableScrollbar
      maxHeight={685}
      maxWidth={982}
      mb={100}
      onToggle={() => toggle(false)}
      p={[2, 3, 3]}
      zIndex={950} // google maps api autocomplete has z-index of 1000
    >
      <Flex flexDirection="column" width={1 / 1}>
        <Title>Apply Filters to match your profile</Title>
        <Subtitle mt={12}>
          Add and apply filters to find the happiest workplaces for people with your profile
        </Subtitle>
        <FilterSearchTitle mt={13}>Filter Search Results</FilterSearchTitle>

        {demographicFiltersList?.map((filter, index) => (
          <Box key={index} mt={12}>
            <FilterTitle>{filter.label}</FilterTitle>
            {filter.type === "select" ? (
              <SelectInput
                options={filter.items}
                value={filtersValues[filter.id]?.value}
                onChange={e => {
                  const { value } = e.target

                  const item = demographicFiltersList
                    .find(i => i.id === filter.id)
                    .items.find(i => i?.value === value)
                  setFiltersValues({ ...filtersValues, [filter.id]: item ?? { value: "0" } })
                }}
              />
            ) : filter.type === "multiselect" ? (
              <MultiSelectInput
                options={filter.items}
                filterValue={filtersValues[filter.id]}
                onFilter={value => {
                  setFiltersValues({ ...filtersValues, [filter.id]: value })
                }}
              />
            ) : null}
          </Box>
        ))}

        <Flex justifyContent="flex-end" mt={40}>
          <OutlineButton onClick={() => toggle(false)}>Cancel</OutlineButton>
          <PrimaryButton
            width={141}
            ml={20}
            onClick={() => {
              const newFilterValues = {}

              Object.keys(filtersValues).forEach(filter => {
                const filterTitle = capitalise(filter)

                if (Array.isArray(filtersValues[filter])) {
                  newFilterValues[filter] = filtersValues[filter]?.map(item => ({
                    ...item,
                    label: `${filterTitle}: ${item.label}`
                  }))
                } else {
                  newFilterValues[filter] = {
                    ...filtersValues[filter],
                    label: `${filterTitle}: ${filtersValues[filter].label}`
                  }
                }
              })

              onApplyFilters(newFilterValues)
            }}
          >
            Apply Filters
          </PrimaryButton>
        </Flex>
      </Flex>
    </Modal>,
    appRoot()
  )
}
