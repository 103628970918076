import { Flex } from "@engaging-tech/components"
import React from "react"
import { useSelector } from "react-redux"

import { CountriesLoader, IndustriesLoader, SurveyJobRolesLoader } from "../../misc"
import ErrorCard from "../components/errorCard"
import WelcomeContent from "../components/welcomeContent"
import BrandingWrapper from "../layouts/brandingWrapper"
import IsolatedWrapper from "../layouts/isolatedWrapper"
import SurveyLoader from "../loaders/surveyLoader"
import { getHasError, getHasLoaded, getIsIsolated, getIsIsolatedLoading } from "../store/survey.selectors"

const SurveyWelcome = () => {
  const hasError = useSelector(getHasError)
  const hasLoaded = useSelector(getHasLoaded)

  const isIsolated = useSelector(getIsIsolated)
  const isIsolatedLoading = useSelector(getIsIsolatedLoading)
  const shouldShowIsolatedContent = isIsolated || isIsolatedLoading

  const Wrapper = shouldShowIsolatedContent ? IsolatedWrapper : BrandingWrapper

  return (
    <Wrapper>
      <Flex justifyContent="center" alignItems="center">
        <SurveyLoader />
      </Flex>
      <CountriesLoader />
      <IndustriesLoader />
      <SurveyJobRolesLoader />
      {hasError && <ErrorCard />}
      {hasLoaded && <WelcomeContent isIsolated={shouldShowIsolatedContent} />}
    </Wrapper>
  )
}

export default SurveyWelcome
