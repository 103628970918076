import { Box, Text } from "@engaging-tech/components"
import styled from "styled-components"

export const Title = styled(Text)`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`

export const FilterWrapper = styled(Box)`
  width: 100%;
  max-width: 244px;
`
