import { useParams } from "@engaging-tech/routing"
import React from "react"

import ActionPlans from "../ActionPlans/components/ActionPlans"
import HappinessDataHighlight from "../HappinessDataHighlight/containers/HappinessDataHighlight"
import HappinessSixSteps from "../HappinessSixSteps/components/HappinessSixSteps"
import Matrices from "../Matrices/containers/Matrices"
import SignUpWidget from "../SignUpWidget/components/SignUpWidget"
import WellbeingDataHighlightContainer from "../WellbeingDataHighlight/containers/WellbeingDataHighlightContainer"
import WellbeingSixSteps from "../WellbeingSixSteps/components/WellbeingSixSteps"

const mapComponents = {
  default: React.Fragment,
  happinessDataHighlight: HappinessDataHighlight,
  happinessSixSteps: HappinessSixSteps,
  matrices: Matrices,
  actionPlans: ActionPlans,
  signUpWidget: SignUpWidget,
  wellbeingDataHighlight: WellbeingDataHighlightContainer,
  wellbeingSixSteps: WellbeingSixSteps
}

export default ({ tag, isolated }) => {
  const { id } = useParams()
  const Component = mapComponents[tag]
  return <Component submissionId={id} isolated={isolated} />
}
