import React, { useState } from "react"
import { Box, Select } from "@engaging-tech/components"

const isVowel = letter => {
  const letterToTest = letter.charAt(0).toUpperCase()

  return (
    letterToTest === "A" ||
    letterToTest === "E" ||
    letterToTest === "I" ||
    letterToTest === "O" ||
    letterToTest === "U"
  )
}

const capitalise = text => text.charAt(0).toUpperCase() + text.slice(1)

const makeHelperText = label => {
  const startsWithVowel = isVowel(label)

  return `Select ${startsWithVowel ? "an" : "a"} ${label}`
}

const Dropdown = ({ onAnswer, values, label, initialValue }) => {
  const [selected, setSelected] = useState(
    (initialValue && initialValue.value[0]) || ""
  )

  const handleSelect = e => {
    setSelected(e.target.value)
    onAnswer(e.target.value)
  }

  return (
    <Box mt={[3, 5]}>
      <Select
        label={capitalise(label)}
        defaultValue=""
        value={selected}
        onChange={handleSelect}
      >
        <option disabled value="">
          {/* {makeHelperText(label)} */}
          {label}
        </option>
        {values.map(val => (
          <option key={val.id} value={val.id}>
            {val.value}
          </option>
        ))}
      </Select>
    </Box>
  )
}

export default Dropdown
