import { usePaths, useRouter } from "@engaging-tech/routing"
import React from "react"

import SurveyQuestions from "../../features/happinessSurvey/views/Questions"
import SurveyLayout from "../../layouts/SurveyLayout"

const Questions = () => {
  const paths = usePaths()
  const router = useRouter()

  const toSurveyStart = () => {
    router.navigate(paths.happinessSurvey.language)
  }
  const toSubmit = () => router.navigate(paths.happinessSurvey.submit)

  return (
    <SurveyLayout>
      <SurveyQuestions onComplete={toSubmit} onCancel={toSurveyStart} />
    </SurveyLayout>
  )
}

export default Questions
