import React from "react"
import styled from "styled-components"
import { Flex, Box } from "@engaging-tech/components"

const PhoneButton = styled(Box)`
  border: 3px solid ${({ theme }) => theme.colors.dark[4]};
`

const Image = styled.img`
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.dark[4]};
  border-radius: 8px;
` 

const Phone = ({ src }) => (
  <Flex width={[1 / 1, 1 / 3]} mt={[3, 0]} justifyContent="center" px={4}>
    <Flex
      pt={2}
      px={2}
      bg="light.0"
      width={[2 / 3, "auto"]}
      flexDirection="column"
      alignItems="center"
      borderRadius={20}
      elevation={8}
    >
      <Box bg="dark.4" height="4px" width={1 / 3} my={2} borderRadius={99} />
      <Image src={src} />
      <PhoneButton
        my={2}
        width={[30, 40]}
        height={[30, 40]}
        borderRadius={99}
      />
    </Flex>
  </Flex>
)

export default Phone
