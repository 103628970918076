import React from "react"
import styled from "styled-components"
import { Flex } from "@engaging-tech/components"
import NoMatches from "./NoMatches"
import DisplayMatch from "../containers/DisplayMatch"

const BottomBorderedFlex = styled(Flex)`
  :last-child {
    border-bottom: none;
  }
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary[1]};
`

const YourMatches = ({ tabState, matches, services, mentor, mentee }) => {
  if (!matches || matches.length === 0)
    return (
      <NoMatches
        tabState={tabState}
        services={services}
        mentor={mentor}
        mentee={mentee}
      />
    )

  return matches.map(match => (
    <BottomBorderedFlex flexDirection="column" mt={4}>
      <DisplayMatch tabState={tabState} match={match} />
    </BottomBorderedFlex>
  ))
}

export default YourMatches
