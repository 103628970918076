import { usePaths } from "@engaging-tech/routing"
import React from "react"

import {
  TestButton,
  TestButtonIcon,
  TestButtonText,
  TestMessage,
  TestMessageWrapper,
  TestWrapper
} from "./happyAtWorkTest.styled"

const HappyAtWorkTest = ({ flexDirection = "column" }) => {
  const paths = usePaths()

  return (
    <TestWrapper flexDirection={flexDirection}>
      <TestButton
        onClick={() => {
          window.open(`${paths.happinessSurvey.index}`)
        }}
      >
        <TestButtonText>Happy at Work Test</TestButtonText>
        <TestButtonIcon name="stars" />
      </TestButton>
      <TestMessageWrapper>
        <TestMessage>
          No Happiness Score for this company? If you work here rank your company with our free Happy at Work
          Test
        </TestMessage>
      </TestMessageWrapper>
    </TestWrapper>
  )
}

export default HappyAtWorkTest
