import { Flex } from "@engaging-tech/components"
import React from "react"
import * as Yup from "yup"

import FieldInput from "../../../formFlowWizard/components/formElements/FieldInput"
import SingleHandleValueSlider from "../../../formFlowWizard/components/formElements/sliders/SingleHandleValueSlider"

const icon = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/briefcase.png`)

const info = {
  icon,
  title: "Find your perfect job",
  submitData: false
}

const validationSchema = Yup.object({
  currentJobRole: Yup.string().nullable(),
  currentJobSalary: Yup.number().min(0, "Current salary can not be less than £0")
})

const CurrentRoleTemplate = () => (
  <Flex width={1 / 1} flexDirection="column">
    <FieldInput
      name="currentJobRole"
      label="What is your currrent role?"
      inputLabel="Current Job Role"
      type="text"
      mb={1}
    />
    <SingleHandleValueSlider
      name1="currentJobSalary"
      label="What is your current salary?"
      max={150000}
      increment={1000}
      trackHeight={15}
      knobSize={18}
      symbol="£"
      defaultValue={0}
      width={1 / 1}
    />
  </Flex>
)

export default { component: CurrentRoleTemplate, validationSchema, info }
