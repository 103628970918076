import React from "react"
import { Flex } from "@engaging-tech/components"
import { useTranslation } from "react-i18next"
import Benefit from "./Benefit"

const SignupBenefits = () => {
  const { t } = useTranslation(["results"])
  return (
    <Flex flexDirection="column">
      <Flex flexDirection={["column", "row"]}>
        <Benefit
          {...t("sign_up.benefits.global_hub", { returnObjects: true })}
        />
        <Benefit {...t("sign_up.benefits.jobs", { returnObjects: true })} />
      </Flex>
      <Flex flexDirection={["column", "row"]}>
        <Benefit
          {...t("sign_up.benefits.business_lib", { returnObjects: true })}
        />
        <Benefit
          {...t("sign_up.benefits.action_plans", { returnObjects: true })}
        />
      </Flex>
    </Flex>
  )
}

export default SignupBenefits
