import { Box, Flex, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import MessengerComingSoonImage from "../assets/images/MessengerComingSoonImage"
import BaseLayout from "../layouts/BaseLayout"

const appStoreQrcode = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/app-store.png`)
const playStoreQrcode = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/play-store.png`)

const MessengerComingSoonContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  height: fit-content;
`

const MessengerComingSoonTitle = styled(Text)`
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 1px;
  text-align: center;

  margin-bottom: 36px;

  color: #003e4c;
`

const MessengerComingSoonSubtitle = styled(Text)`
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: center;

  color: #003e4c;
`

const GetTheAppText = styled(Text)`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;

  color: #000000;
`

const Qrcode = styled.img`
  width: 48px;
  height: 43.8px;
`

const Messenger = () => (
  <BaseLayout>
    <Flex mt={68} mb={140} width={1 / 1} justifyContent="center">
      <MessengerComingSoonContainer>
        <MessengerComingSoonTitle>Coming soon to desktop. Live on Mobile now.</MessengerComingSoonTitle>
        <MessengerComingSoonImage />
        <MessengerComingSoonSubtitle mt={36}>
          Our new messenger is on its way - be sure to check back soon!
        </MessengerComingSoonSubtitle>
        <Flex mt={24} alignItems="center">
          <GetTheAppText>Get the App:</GetTheAppText>
          <Flex ml={20} alignItems="center">
            <GetTheAppText>Apple Store - </GetTheAppText>
            <Qrcode src={appStoreQrcode} alt="" />
          </Flex>
          <Flex ml={20} alignItems="center">
            <GetTheAppText>Android Play Store - </GetTheAppText>
            <Qrcode src={playStoreQrcode} alt="" />
          </Flex>
        </Flex>
      </MessengerComingSoonContainer>
    </Flex>
  </BaseLayout>
)

export default Messenger
