import React from "react"
import GetNotifications from "../../Notifications/containers/GetNotifications"

const getFormatedMaterialType = type => {
  let formatedType = null
  switch (type) {
    case "article":
      formatedType = "Articles"
      break
    case "lecture":
      formatedType = "Lectures"
      break
    case "podcast":
      formatedType = "Podcasts"
      break
    case "research":
      formatedType = "Research"
      break
    case "book":
      formatedType = "Books"
      break
    default:
      break
  }
  return formatedType
}

const TopBar = ({
  bookSearchTerm,
  bookFilter,
  handleSubmit,
  searchTerm,
  filter,
  materialType
}) => {
  return (
    <GetNotifications
      type="Business Library"
      filters={[
        getFormatedMaterialType(materialType),
        materialType === "book" ? bookFilter?.name : filter?.value,
        materialType === "book" ? bookSearchTerm : searchTerm
      ]}
      onSubmit={frequency => handleSubmit(frequency, searchTerm, filter?.id)}
      materialType={materialType}
    />
  )
}

export default TopBar
