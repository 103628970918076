import { connect } from "react-redux"

import FormFlowWizard from "../../../formFlowWizard/components/formFlowWizard"
import { createCV } from "../../store/cvLibrary.actions"
import { getInitialData, getIsLoading } from "../../store/cvLibrary.selectors"

const mapState = state => ({
  isLoading: getIsLoading(state),
  initialData: getInitialData(state)
})

const mapDispatch = dispatch => ({
  onSubmit: cvParams => dispatch(createCV(cvParams))
})

const Form = connect(mapState, mapDispatch)(FormFlowWizard)

export default Form
