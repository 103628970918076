import { Box, Text } from "@engaging-tech/components"
import styled from "styled-components"

export const Container = styled(Box)`
  width: 264px;

  display: flex;
  flex-direction: column;
`

export const ItemContainer = styled(Box)`
  width: auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;

  :not(:first-child) {
    margin-top: 2px;
  }
`

export const ItemTitle = styled(Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  margin: 0;

  color: #003e4c;
`

export const ItemGraphWrapper = styled(Box)`
  width: auto;

  display: inline;
`

export const ItemGraphContainer = styled(Box)`
  width: auto;

  display: flex;
  width: 76px;
  height: 12px;
`

export const ItemGraphActiveArea = styled(Box)`
  background-color: #a9f0d5;
  width: ${({ width }) => width}%;
`

export const ItemGraphActiveAreaDescription = styled(Text)`
  margin-left: 5px;

  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;

  color: #003e4c;
`
