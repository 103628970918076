import { takeLatest, call, put } from "redux-saga/effects"
import {
  CognitoUser,
  CognitoUserSession,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken
} from "amazon-cognito-identity-js"
import { decode } from "@engaging-tech/jwt"

import { apollo } from "../../../../lib/Apollo"

import * as Actions from "./saml.actions"
import {
  fetchSurveyForSamlUser,
  handleSamlSignIn
} from "../service/saml.service"
import { yourProfileActions } from "../../../yourProfile"
import { AccountActions } from "../.."

export function* fetchSurveyForSamlUserSaga() {
  try {
    const res = yield call(fetchSurveyForSamlUser)
    yield put(
      Actions.fetchSurveyForSamlUserSuccess(res.data.fetchSurveyForSamlUser)
    )
  } catch (error) {
    console.log({ error })
  }
}

const handleCognitoSignIn = ({ idToken, accessToken, refreshToken }) => {
  apollo.useSessionStorage()
  const cognitoUser = new CognitoUser({
    Username: decode(idToken).email,
    Pool: apollo.UserPool(),
    Storage: sessionStorage
  })
  const session = new CognitoUserSession({
    IdToken: new CognitoIdToken({ IdToken: idToken }),
    AccessToken: new CognitoAccessToken({ AccessToken: accessToken }),
    RefreshToken: new CognitoRefreshToken({ RefreshToken: refreshToken })
  })
  cognitoUser.setSignInUserSession(session)
}

export function* samlSaga(action) {
  try {
    yield put(AccountActions.signOut())
    yield put(yourProfileActions.clearProfile())
    const { idToken, accessToken, refreshToken } = action.payload
    if (!idToken || !accessToken || !refreshToken) {
      yield put(AccountActions.signInFail("ERR006"))
    } else {
      handleCognitoSignIn({ idToken, accessToken, refreshToken })
      const res = yield call(handleSamlSignIn)
      yield put(yourProfileActions.loadSuccess(res.data.handleSamlUserProfile))
      yield put(Actions.samlSignInSuccess())
    }
  } catch (error) {
    yield put(yourProfileActions.loadFail())
  }
}

export function* saga() {
  yield takeLatest(
    Actions.types.FETCH_SURVEY_FOR_SAML_USER,
    fetchSurveyForSamlUserSaga
  )
  yield takeLatest([Actions.types.SAML_SIGN_IN], samlSaga)
}
