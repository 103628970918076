import uniqid from "uniqid"
import { getConfig } from "@engaging-tech/ssr-config"

import { apollo, gql } from "./Apollo"

const GRAPHQL_API_URL = () =>
  `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

const SUBMISSION_TOKENS_COOKIE = "SubmissionTokens"

const ATTACH_USER_BY_TOKENS = gql`
  mutation($tokens: [String]!) {
    attachUserBySubmissionTokens(tokens: $tokens)
  }
`

const attachUser = tokens =>
  apollo.mutate({
    uri: GRAPHQL_API_URL,
    mutation: ATTACH_USER_BY_TOKENS,
    variables: { tokens }
  })

export const newConfirmSubmissionToken = () => {
  const token = uniqid()
  const storage = sessionStorage.getItem(SUBMISSION_TOKENS_COOKIE)
  const allSubmissionTokens = storage ? JSON.parse(storage) : []
  const newStorage = JSON.stringify([...allSubmissionTokens, token])
  sessionStorage.setItem(SUBMISSION_TOKENS_COOKIE, newStorage)
  return token
}

export const attachUserToSubmissionByTokens = async () => {
  const storage = sessionStorage.getItem(SUBMISSION_TOKENS_COOKIE)
  if (!storage) return
  const tokens = JSON.parse(storage)
  if (!tokens.length) return

  const res = await attachUser(tokens)
  if (res.data.attachUserBySubmissionTokens) {
    sessionStorage.removeItem(SUBMISSION_TOKENS_COOKIE)
  }
}
