import React from "react"
import { Flex, Text, Avatar } from "@engaging-tech/components"

const ReplySummary = ({ lastReply, numberOfReplies, onClick }) => {
  const getName = () =>
    lastReply.anonymous
      ? "Anomyous"
      : `${lastReply.user.firstName} ${lastReply.user.lastName}`

  const getReplyText = () =>
    `${numberOfReplies} ${numberOfReplies === 1 ? "reply" : "replies"}`

  return (
    <Flex
      onClick={onClick}
      width="95%"
      alignItems="center"
      style={{ cursor: "pointer" }}
    >
      {!lastReply.anonymous && lastReply.user.picture.small && (
        <Flex mr={2} width={30} height={30}>
          <Avatar picture={lastReply.user.picture.small} size={30} />
        </Flex>
      )}
      <Flex
        width="calc(100% - 30px)"
        alignItems="flex-start"
        flexDirection="column"
        pt={3}
      >
        <Text
          color="dark.2"
          width="auto"
          maxWidth="100%"
          style={{
            flexShrink: "0",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis"
          }}
        >
          <Text fontWeight="500">{getName()}</Text> replied
        </Text>
        <Text color="dark.2" fontSize={[3, 4]} mt={[1, 0]}>
          {getReplyText()}
        </Text>
      </Flex>
    </Flex>
  )
}

export default ReplySummary
