/* eslint-disable no-nested-ternary */
import styled from "styled-components"

import {
  ArrowDownIcon as ArrowDownBaseIcon,
  FilterDropdownIcon as FilterDropdownBaseIcon
} from "../../../../assets/icons"

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  min-width: 128px;
  max-width: 244px;
  width: 100%;
`

export const DropdownButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 32px;
  padding: 0 0 0 14px;
  /* padding: 8px 16px; */
  background-color: ${({ selected, awards, theme }) =>
    selected ? "#003E4C" : awards ? theme.colors.secondary[0] : "#d7d7d7"};
  border: 0px;

  cursor: pointer;

  font-style: normal;
  font-weight: "400";
  font-size: 14px;
  line-height: 32px;
  color: ${({ selected }) => (selected ? "#FFFFFF" : "#000000DE")};

  border-top-right-radius: ${({ isOpen }) => (isOpen ? "15px" : "20px")};
  border-top-left-radius: ${({ isOpen }) => (isOpen ? "15px" : "20px")};
  border-bottom-right-radius: ${({ isOpen }) => (isOpen ? 0 : "20px")};
  border-bottom-left-radius: ${({ isOpen }) => (isOpen ? 0 : "20px")};

  & path {
    fill: ${({ selected }) => (selected ? "#FFFFFF" : "#003E4C")};
  }
`

export const DropdownList = styled.ul`
  width: 100%;
  position: absolute;
  top: 14px;
  left: 0;
  z-index: 10;
  background-color: #f5f5f5;
  overflow-y: scroll;
  height: 400px;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 20px 20px;

  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  padding-left: 0;
  padding: 11px 0 20px;

  li:not(:first-child) {
    margin-top: 10px;
  }
`

export const DropdownItem = styled.li`
  width: 100%;
  list-style: none;

  text-align: start;

  display: flex;
  justify-content: start;
  align-content: start;

  cursor: pointer;

  background-color: ${({ selected }) => (selected ? "#d7d7d7" : "")};

  padding: 0 3px 0 9px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #003e4c;

  &:hover {
    background-color: #d7d7d7;
  }
`

export const ArrowDownIcon = styled(ArrowDownBaseIcon)`
  margin-right: 5px;
  transform: ${({ isopen }) => (isopen ? "rotate(180deg)" : "rotate(0deg)")};
`

export const FilterDropdownIcon = styled(FilterDropdownBaseIcon)`
  margin-right: 7px;
  margin-left: 12px;
`
