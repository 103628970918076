import { Box, Card, Flex, MobileOnly, TabletUp } from "@engaging-tech/components"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useDebounce } from "use-debounce"

import DemographicFilter from "../../../../components/shared/demographicFilter"
import flattenFilters from "../../../utils/flattenFilters"
import DailyTelegraphPlaceHolder from "../../components/DailyTelegraphPlaceHolder"
import SideCards from "../../components/SideCards"
import { loadCompanies } from "../../store/rankings.actions"
import HappiestCompaniesContent from "./happiestCompaniesContent"

const categoryList = [
  { id: "award-winner", name: "Award Winner" },
  { id: "award", name: "Happiest Workplace" },
  { id: "best-for-19-24", name: "Best for 19-24" },
  { id: "best-for-55", name: "Best for 55+" },
  { id: "best-for-development", name: "Best for Development" },
  { id: "best-for-disabled", name: "Best for Disabled Employees" },
  { id: "best-for-lgbtq", name: "Best for LGBTQ+ Employees" },
  { id: "best-for-management", name: "Best for Management" },
  { id: "best-for-women", name: "Best for Women" },
  { id: "most-engaged", name: "Most Engaged Employees" },
  { id: "most-inclusive", name: "Most Inclusive" },
  { id: "well-being", name: "Best for Wellbeing" },
  { id: "industry-star", name: "Industry Star" }
]

export default function HappiestCompanies() {
  const [searchTerm, setSearchTerm] = useState("")
  const [searchTermValue] = useDebounce(searchTerm, 500)
  const [industryFilter, setIndustryFilter] = useState(undefined)
  const [countryFilter, setCountryFilter] = useState(undefined)
  const [categoryFilter, setCategoryFilter] = useState(undefined)
  const [demographicFilters, setDemographicFilters] = useState({})
  const [demographicsFilterIsOpen, setDemographicsFilterIsOpen] = useState(false)

  const onRemoveFilterItem = filterValue => {
    const newFilters = {}

    Object.keys(demographicFilters).forEach(item => {
      if (demographicFilters[item] && demographicFilters[item]?.length) {
        newFilters[item] = demographicFilters[item].filter(i => i.value !== filterValue)
      } else if (demographicFilters[item] && demographicFilters[item].value === filterValue) {
        demographicFilters[item] = undefined
      } else {
        newFilters[item] = demographicFilters[item]
      }
    })

    setDemographicFilters(newFilters)
  }

  const isDemographicFiltersSelected =
    Object.values(demographicFilters)?.length && flattenFilters(Object.values(demographicFilters))?.length

  const dispatch = useDispatch()

  const onFilterChange = useCallback(() => {
    dispatch(
      loadCompanies({
        searchTerm: searchTermValue,
        industryFilter,
        countryFilter,
        categoryFilter,
        filters: demographicFilters
      })
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTermValue, industryFilter, countryFilter, categoryFilter, demographicFilters])

  useEffect(() => {
    onFilterChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTermValue, industryFilter, countryFilter, categoryFilter, demographicFilters])

  return (
    <Box width={1} m={2}>
      <DailyTelegraphPlaceHolder />
      <Flex flexDirection={["column", "column", "row"]}>
        <MobileOnly>
          <Flex flexDirection="column" mt={3}>
            <HappiestCompaniesContent
              setDemographicsFilterIsOpen={setDemographicsFilterIsOpen}
              isDemographicFiltersSelected={isDemographicFiltersSelected}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              countryFilter={countryFilter}
              setCountryFilter={setCountryFilter}
              industryFilter={industryFilter}
              setIndustryFilter={setIndustryFilter}
              categoryList={categoryList}
              categoryFilter={categoryFilter}
              setCategoryFilter={setCategoryFilter}
              demographicsFilter={demographicFilters}
              onRemoveFilterItem={onRemoveFilterItem}
            />
          </Flex>
        </MobileOnly>
        <TabletUp>
          <Card width={1} px={[0, 3]} py={4}>
            <HappiestCompaniesContent
              setDemographicsFilterIsOpen={setDemographicsFilterIsOpen}
              isDemographicFiltersSelected={isDemographicFiltersSelected}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              countryFilter={countryFilter}
              setCountryFilter={setCountryFilter}
              industryFilter={industryFilter}
              setIndustryFilter={setIndustryFilter}
              categoryList={categoryList}
              categoryFilter={categoryFilter}
              setCategoryFilter={setCategoryFilter}
              demographicsFilter={demographicFilters}
              onRemoveFilterItem={onRemoveFilterItem}
            />
          </Card>
        </TabletUp>
        <Flex px={[3, 0]} flexDirection="column">
          <SideCards />
        </Flex>
      </Flex>

      {demographicsFilterIsOpen ? (
        <DemographicFilter
          filters={demographicFilters}
          toggle={setDemographicsFilterIsOpen}
          onApplyFilters={filters => {
            setDemographicsFilterIsOpen(false)
            setDemographicFilters(filters)
          }}
        />
      ) : null}
    </Box>
  )
}
