import { connect } from "react-redux"
import { AccountSelectors } from "../../account"

import {
  UserContentActions,
  UserContentSelectors
} from "../../userGeneratedContent"

import CreateSeriesForm from "../../userGeneratedContent/components/PublishForm/CreateSeries"

const mapDispatch = dispatch => ({
  onLoad: userId => dispatch(UserContentActions.loadSeries(userId)),
  onCreate: newSeries => dispatch(UserContentActions.createSeries(newSeries))
})

const mapState = state => ({
  series: UserContentSelectors.getSeries(state),
  hasSeries: UserContentSelectors.getHasSeries(state),
  hasCreated: UserContentSelectors.getHasCreatedSeries(state),
  isLoading: UserContentSelectors.getIsSeriesLoading(state),
  token: AccountSelectors.getToken(state)
})

const CreateSeries = connect(mapState, mapDispatch)(CreateSeriesForm)

export default CreateSeries
