import { LocationAutocomplete } from "@engaging-tech/components"
import styled from "styled-components"

// eslint-disable-next-line import/prefer-default-export
export const LocationAutocompleteInput = styled(LocationAutocomplete)`
  height: 40px;

  > div {
    height: 40px;
  }

  > input {
    height: 40px;
    width: 278px;

    background-color: #ffffff;
    border: 1px solid #043e4c;
    border-radius: 20px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: 580px) {
    margin-top: 12px;
  }
`
