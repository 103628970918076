/* eslint-disable no-restricted-syntax */
import { Flex } from "@engaging-tech/components"
import { useLocation, usePaths, useRouter } from "@engaging-tech/routing"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import DemographicFilter from "../../../../components/shared/demographicFilter"
import DemographicChips from "../../../../components/shared/demographicFilter/demographicChips"
import DemographicFilterButton from "../../../../components/shared/demographicFilter/demographicFilterButton"
import { demographicFilterActions } from "../../../../components/shared/demographicFilter/store"
import { getDemographicFiltersList } from "../../../../components/shared/demographicFilter/store/demographicFilter.selectors"
import { createUrl } from "../../../../lib/utils"
import flattenFilters from "../../../utils/flattenFilters"
import { getProfile } from "../../../yourProfile/store/yourProfile.selectors"
import { filterJobs, loadFilters } from "../../store/jobFinder.actions"
import { getFilters } from "../../store/jobFinder.selectors"
import {
  ClearAllButton,
  FilterContent,
  FilterWrapper,
  FiltersContainer,
  FiltersContent
} from "./filters.styled"
import LocationSearchFilter from "./locationSearchFilter/locationSearchFilter"
import ProgressBar from "./progressBar"
import { MultiSelectInput, SelectInput } from "./selectInputs"
import TextSearch from "./textSearch"

const Filters = () => {
  const location = useLocation()
  const paths = usePaths()
  const router = useRouter()

  const [componentLoaded, setComponentLoaded] = useState(false)
  const [demographicFilters, setDemographicFilters] = useState({})
  const [demographicsFilterIsOpen, setDemographicsFilterIsOpen] = useState(false)

  const dispatch = useDispatch()
  const filters = useSelector(getFilters)
  const profile = useSelector(getProfile)
  const demographicFiltersList = useSelector(getDemographicFiltersList)

  const isDemographicFiltersSelected =
    Object.values(demographicFilters)?.length && flattenFilters(Object.values(demographicFilters))?.length

  // URL filters
  const optionSearchParams = new URLSearchParams(location.search)
  const companyNameFilter = optionSearchParams.get("company")
  const searchTermFilter = optionSearchParams.get("search_term")
  const experienceFilter = optionSearchParams.get("experience")
  const positionFilter = optionSearchParams.get("position")
  const jobTypeFilter = optionSearchParams.get("job_type")
  const industryFilter = optionSearchParams.get("industry")
  const salaryFilter = optionSearchParams.get("salary")
  const salaryMinFilter = optionSearchParams.get("salary_min")
  const datePostedFilter = optionSearchParams.get("date_posted")
  const salaryNumberFilter = salaryFilter
    ? parseInt(salaryFilter.replace("£", "").replace("+", ""), 10)
    : null
  const locationFilter = optionSearchParams.get("location")
  const locationTypeFilter = optionSearchParams.get("location_type")
  const countryCodeFilter = optionSearchParams.get("country_code")
  const demographicQueryFilters = optionSearchParams.get("demographic")

  useEffect(() => {
    if (demographicQueryFilters && demographicFiltersList?.length) {
      const demographicFiltersSelected = {}
      demographicQueryFilters.split(",").forEach(item => {
        for (const filter of demographicFiltersList) {
          for (const filterItem of filter.items) {
            if (filterItem.value === item) {
              demographicFiltersSelected[filter.id] = filterItem
            }
          }
        }
      })
      setDemographicFilters(demographicFiltersSelected)
    }
  }, [demographicQueryFilters, demographicFiltersList])

  // initial search
  useEffect(() => {
    if (
      !componentLoaded &&
      profile &&
      profile.location &&
      !companyNameFilter &&
      !locationFilter &&
      !countryCodeFilter
    ) {
      setComponentLoaded(true)
      optionSearchParams.set("location", profile.location.vicinity)
      optionSearchParams.set("location_type", "locality")
      optionSearchParams.set("country_code", profile.location.country.shortName)

      // if (demographicQueryFilters){
      //   setDemographicFilters(demographicQueryFilters.split(","))
      // }
    }
    const optionUrl = createUrl(location.pathname, optionSearchParams)
    router.navigate(optionUrl)
    setComponentLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentLoaded])

  const onFilterJobs = useCallback(() => {
    dispatch(
      filterJobs([
        { name: "search_term", value: searchTermFilter },
        { name: "company_name", value: companyNameFilter },
        { name: "job_type", value: jobTypeFilter },
        { name: "salary", value: salaryNumberFilter ?? salaryMinFilter },
        { name: "experience", value: experienceFilter },
        { name: "position", value: positionFilter },
        { name: "industry", value: industryFilter },
        { name: "date_posted", value: datePostedFilter },
        {
          name: "location",
          value: locationTypeFilter === "locality" ? locationFilter.split(",")[0] : locationFilter
        },
        { name: "country_code", value: countryCodeFilter },
        {
          name: "demographicFilters",
          value: demographicQueryFilters ? demographicQueryFilters.split(",") : []
        }
      ])
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    demographicFilters,
    searchTermFilter,
    companyNameFilter,
    jobTypeFilter,
    salaryNumberFilter,
    salaryMinFilter,
    experienceFilter,
    positionFilter,
    industryFilter,
    datePostedFilter,
    locationTypeFilter,
    locationFilter,
    countryCodeFilter
  ])

  useEffect(() => {
    onFilterJobs()
  }, [onFilterJobs])

  const onRemoveFilterItem = filterSelectedValue => {
    const filtersArr = Object.values(demographicFilters)?.length
      ? flattenFilters(Object.values(demographicFilters))
      : []
    if (
      filtersArr?.length &&
      filtersArr.filter(i => i !== "0").filter(i => i !== filterSelectedValue)?.length !== 0
    ) {
      optionSearchParams.set(
        "demographic",
        filtersArr
          .filter(i => i !== "0")
          .filter(i => i !== filterSelectedValue)
          .toString()
      )
    } else {
      optionSearchParams.delete("demographic")
      setDemographicFilters({})
    }
    const optionUrl = createUrl(location.pathname, optionSearchParams)
    router.navigate(optionUrl)
  }

  // reset filters
  const onResetFilters = () => {
    setDemographicFilters({})
    router.navigate(paths.findAJob.index)
  }

  useEffect(() => {
    dispatch(loadFilters())
    dispatch(demographicFilterActions.loadFilters())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex flexDirection="column">
      <ProgressBar />
      <FilterWrapper bg="dark.7">
        <FilterContent>
          <FiltersContainer>
            <FiltersContent>
              <TextSearch />
              <LocationSearchFilter />
            </FiltersContent>

            <DemographicFilterButton
              isSelected={isDemographicFiltersSelected}
              onOpen={() => setDemographicsFilterIsOpen(true)}
            />
          </FiltersContainer>
          <FiltersContainer dynamic>
            {filters?.length > 0 &&
              filters.map((item, index) => {
                if (item.type === "select") {
                  let options = item

                  if (item.id === "date_posted") {
                    options = { ...options, items: options.items.map(i => `${i} days`) }
                  }

                  return (
                    <SelectInput
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      options={options}
                    />
                  )
                }

                if (item.type === "multiselect") {
                  return (
                    <MultiSelectInput
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      options={item}
                    />
                  )
                }

                return null
              })}

            <ClearAllButton color="primary.0" bg="light.0" onClick={() => onResetFilters()}>
              Clear All
            </ClearAllButton>
          </FiltersContainer>

          <DemographicChips demographicsFilter={demographicFilters} onRemoveFilterItem={onRemoveFilterItem} />
        </FilterContent>
      </FilterWrapper>

      {demographicsFilterIsOpen ? (
        <DemographicFilter
          filters={demographicFilters}
          toggle={setDemographicsFilterIsOpen}
          onApplyFilters={filtersSelected => {
            setDemographicsFilterIsOpen(false)
            const filtersArr = Object.values(filtersSelected)?.length
              ? flattenFilters(Object.values(filtersSelected))
              : []
            if (filtersArr?.length && filtersArr.filter(i => i !== "0")?.length !== 0) {
              optionSearchParams.set("demographic", filtersArr.filter(i => i !== "0").toString())
            } else {
              optionSearchParams.delete("demographic")
              setDemographicFilters({})
            }
            const optionUrl = createUrl(location.pathname, optionSearchParams)
            router.navigate(optionUrl)
          }}
        />
      ) : null}
    </Flex>
  )
}

export default Filters
