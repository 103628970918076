import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { jobMatcherActions } from "../../features/jobMatcher"
import JobMatchesFeed from "../../features/jobMatcher/views/jobMatchesFeed"
import { yourProfileActions, yourProfileSelectors } from "../../features/yourProfile"
import DetailsLayout from "../../layouts/DetailsLayout"

const JobMatcher = () => {
  const userId = useSelector(yourProfileSelectors.getUserId)

  const dispatch = useDispatch()

  useEffect(() => {
    if (userId) dispatch(yourProfileActions.load(userId))

    dispatch(jobMatcherActions.loadJobMatches())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  return (
    <DetailsLayout actionName="CAREER DEVELOPER" backTo="/develop-career" bg="light.0">
      <JobMatchesFeed />
    </DetailsLayout>
  )
}

export default JobMatcher
