import { usePaths } from "@engaging-tech/routing"
import React from "react"

import { ButtonContent, ButtonIcon, ButtonWrapper } from "./companyInformationButton.styled"

const CompanyInformationButton = ({ companyNameKey }) => {
  const paths = usePaths()

  const onGoToCompanyProfile = () => {
    window.open(`${paths.companyBusinessProfile.index}/${companyNameKey}`, "_blank", "noreferrer")
  }

  return (
    <ButtonWrapper mt={27}>
      <ButtonContent onClick={() => onGoToCompanyProfile()}>
        View Company Information
        <ButtonIcon />
      </ButtonContent>
    </ButtonWrapper>
  )
}

export default CompanyInformationButton
