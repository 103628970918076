export const types = {
  TOKEN_SIGN_IN: "[Account] Token Sign In",
  TOKEN_SIGN_IN_FAIL: "[Account] Token Sign In Fail",
  SIGN_IN_SUCCESS: "[Account] Sign In Success",
  SIGN_IN_FAIL: "[Account] Sign In Fail",
  SIGN_IN_MAGIC_LINK_SUCCESS: "[Account] Magic Link Sign In Success",
  SIGN_IN_MAGIC_LINK_FAIL: "[Account] Magic Link Sign In Fail",
  SEND_MAGIC_LINK_SUCCESS: "[Account] Magic Link Sent Success",
  SEND_MAGIC_LINK_FAIL: "[Account] Magic Link Sent Fail",
  RESET_SEND_MAGIC_LINK: "[Account] Reset Send Magic Link"
}

export const tokenSignIn = () => ({ type: types.TOKEN_SIGN_IN })

export const signInSuccess = token => ({
  type: types.SIGN_IN_SUCCESS,
  payload: token
})

export const signInFail = payload => ({
  type: types.SIGN_IN_FAIL,
  payload
})

export const tokenSignInFail = () => ({ type: types.TOKEN_SIGN_IN_FAIL })

export const loginWithMagicLinkSuccess = token => ({
  type: types.SIGN_IN_MAGIC_LINK_SUCCESS,
  payload: token
})

export const loginWithMagicLinkFail = payload => ({
  type: types.SIGN_IN_MAGIC_LINK_FAIL,
  payload
})

export const sendMagicLinkSuccess = () => ({
  type: types.SEND_MAGIC_LINK_SUCCESS
})

export const sendMagicLinkFail = payload => ({
  type: types.SEND_MAGIC_LINK_FAIL,
  payload
})

export const resetSendMagicLink = () => ({
  type: types.RESET_SEND_MAGIC_LINK
})
