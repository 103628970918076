import React from "react"

import { libraryPostTypes } from "../../../features/userGeneratedContent/lib/postTypes"

import AllPosts from "../../../features/businessLibrary/containers/AllPosts"
import BrowseLibraryLayout from "../../../layouts/BrowseLibraryLayout"
import SideBar from "../../../features/businessLibrary/containers/SideBar"
import TopBar from "../../../features/businessLibrary/containers/TopBar"

const AllArticles = AllPosts(libraryPostTypes.articles)

const Articles = () => (
  <BrowseLibraryLayout
    sideColumn={SideBar(libraryPostTypes.articles)}
    topRow={TopBar(libraryPostTypes.articles)}
  >
    <AllArticles />
  </BrowseLibraryLayout>
)

export default Articles
