import { Flex, Themes } from "@engaging-tech/components"
import React from "react"
import { useSelector } from "react-redux"
import styled, { ThemeProvider } from "styled-components"

import gradientCss from "../../../helpers/gradientCss"
import { getBranding } from "../store/survey.selectors"

const GradientBox = styled(Flex)`
  ${({ theme }) => gradientCss(theme)}
`

const BrandingWrapper = ({ children }) => {
  const brand = useSelector(getBranding)

  return (
    <ThemeProvider theme={brand.theme || Themes.workL}>
      <GradientBox width={1} px={[3, 3, 4]} alignItems="center" justifyContent="center" flex="1 1 auto">
        <Flex width={1} maxWidth={560} flexDirection="column" alignItems="center" mb={100}>
          {children}
        </Flex>
      </GradientBox>
    </ThemeProvider>
  )
}

export default BrandingWrapper
