import { takeLatest, put, call, select, all } from "redux-saga/effects"
// import { AccountSelectors } from "features/account"

import * as Actions from "./libraryBooks.actions"
import {
  getBooks,
  getBookDetails,
  createOrder,
  updateOrder,
  createOrderIntent,
  getOrderDetails,
  getOrders,
  getEbookDetails,
  getEbookDetailsByEan,
  getBookDetailsByEan
} from "../services/libraryBooks.service"
import featuredBookEans from "../lib/featuredBookEans"
import { getFilter, getSearchTerm } from "./libraryBooks.selectors"
import { getIsPremium } from "../../yourProfile/store/yourProfile.selectors"

import { extractBooksFromOrder, addBooksExtraInfo } from "../lib/utils"

const getOrderInfo = orderInfo => {
  const order = {}
  order.id = orderInfo.id || ""
  order.address = orderInfo.deliveryAddressLine1 || ""
  order.postCode = orderInfo.deliveryPostCode || ""
  order.name = orderInfo.deliveryName || ""
  order.price = orderInfo.price || 0
  order.delivery = orderInfo.delivery || 0
  order.country = orderInfo.deliveryCountry || ""
  order.orderStatus = orderInfo.orderStatus || ""
  order.books = orderInfo.physicalBooks || []
  order.ebooks = orderInfo.eBooks || []
  return order
}

const getProductInfo = (format, quantity, book) => {
  const product = {}
  if (!book) return null
  const isPhysicalBook = format === "hardCover"
  const bookInfo = { ...book }
  let productFormat = "Hard Cover"
  if (format === 5) productFormat = "Audiobook"
  if (format === 6) productFormat = "Ebook"
  product.title = book.title
  product.authors = book.authors
  product.ean = isPhysicalBook ? book.eanNumber : bookInfo.ean
  product.image = `https://gardnersmedia.engaging.works${book.image}`
  product.price = isPhysicalBook
    ? bookInfo.price
    : bookInfo.format_data.find(
        formatData => formatData.gardnerEBookFormatId === format
      ).price * 1.2
  product.id = bookInfo.id
  product.quantity = isPhysicalBook ? quantity : 1
  product.format = productFormat
  product.weight = isPhysicalBook ? book.weight : 0
  product.url = book.id

  return product
}

function* getFormattedOrder(orderData) {
  let ebooksData = []
  let booksData = []

  const order = getOrderInfo(orderData)
  const booksEan = order.books.map(book => book.ean).join()
  const eBooksEan = order.ebooks.map(ebook => ebook.ean).join()
  if (eBooksEan) {
    const ebooksRes = yield call(getEbookDetailsByEan, eBooksEan)

    ebooksData = order.ebooks.map(orderEbook => {
      const ebookData = ebooksRes.data.results.find(
        ebook => ebook.ean === orderEbook.ean
      )
      const ebookInfo = getProductInfo(orderEbook.eBookType, 1, ebookData)
      return ebookInfo
    })
  }
  if (booksEan) {
    const booksRes = yield call(getBookDetailsByEan, booksEan)
    booksData = order.books.map(orderBook => {
      const bookData = booksRes.data.results.find(
        book => book.eanNumber === orderBook.ean
      )
      const ebookInfo = getProductInfo(
        "hardCover",
        orderBook.quantity,
        bookData
      )
      return ebookInfo
    })
  }
  order.booksData = ebooksData.concat(booksData).filter(Boolean)
  return order
}

export function* loadBooksSaga(action, api = getBooks) {
  try {
    const filter = yield select(getFilter)
    const searchTerm = yield select(getSearchTerm)
    const params = {
      filter: filter !== "0" ? filter : null,
      searchTerm,
      ...action.payload
    }
    const res = yield call(api, params)
    const data = res.data.results
    if (data) yield put(Actions.loadAllBooksSuccess(data))
    else yield put(Actions.loadAllBooksFail())
  } catch {
    yield put(Actions.loadAllBooksFail())
  }
}

export function* loadMoreSaga(action, api = getBooks) {
  try {
    const filter = yield select(getFilter)
    const searchTerm = yield select(getSearchTerm)
    const params = { from: action.payload.from, filter, searchTerm }
    const res = yield call(api, params)
    const data = res.data.results
    yield put(Actions.loadMoreSuccess(data))
  } catch {
    yield put(Actions.loadMoreFail())
  }
}

export function* loadBookSaga(action) {
  try {
    const res = yield call(getBookDetails, action.payload)
    yield put(Actions.loadBookSuccess(res.data.results[0]))
  } catch {
    yield put(Actions.loadBookFail())
  }
}

export function* createOrderSaga(action) {
  try {
    const isPremium = yield select(getIsPremium)

    const res = yield call(createOrder, { books: action.payload, isPremium })

    if (res.data.OrderId) {
      yield put(Actions.createOrderSuccess(res.data.OrderId))
    } else {
      yield put(
        Actions.createOrderFail("Some products in your basket are out of stock")
      )
    }
  } catch (e) {
    yield put(
      Actions.createOrderFail(
        "There was a problem setting up your order. Please try again"
      )
    )
  }
}

export function* updateOrderSaga(action) {
  try {
    const res = yield call(updateOrder, action.payload)
    if (res.data.Success) yield put(Actions.createOrderIntent(res.data.OrderId))
    else yield put(Actions.updateOrderFail())
  } catch {
    yield put(Actions.updateOrderFail())
  }
}

export function* createOrderIntentSaga(action) {
  try {
    const res = yield call(createOrderIntent, action.payload)
    if (res.data.payBookOrder) {
      yield put(Actions.createOrderIntentSuccess(res.data.payBookOrder))
    } else yield put(Actions.createOrderIntentFail())
  } catch {
    yield put(Actions.createOrderIntentFail())
  }
}

export function* loadOrderSaga(action) {
  try {
    const res = yield call(getOrderDetails, action.payload)
    let order = {}
    const orderData = res && res.data && res.data.results && res.data.results[0]
    if (orderData) {
      order = yield call(getFormattedOrder, orderData)
    } else {
      yield put(Actions.loadOrderFail())
    }

    if (order.booksData && order.booksData.length) {
      yield put(Actions.loadOrderSuccess(order))
    } else {
      yield put(Actions.loadOrderFail())
    }
  } catch (e) {
    yield put(Actions.loadOrderFail())
  }
}

export function* loadUserDigitalPurchases(action) {
  try {
    const orderRes = yield call(getOrderDetails, action.payload)
    const orders = orderRes.data.results
    if (!orders) {
      yield put(Actions.loadDigitalPurchasesSuccess([]))
    } else {
      const eBooksPurchased = orders.reduce((result, order) => {
        const eBooks = extractBooksFromOrder(order)
        Object.assign(result, eBooks)
        return result
      }, {})
      const eBookEans = Object.keys(eBooksPurchased).join(",")
      const booksExtraInfoRes = yield call(getEbookDetails, { ean: eBookEans })
      const booksExtraInfo = booksExtraInfoRes.data.results
      addBooksExtraInfo(eBooksPurchased, booksExtraInfo)
      yield put(
        Actions.loadDigitalPurchasesSuccess(Object.values(eBooksPurchased))
      )
    }
  } catch (e) {
    yield put(Actions.loadDigitalPurchasesFail())
  }
}

export function* loadUserOrders(action) {
  try {
    const orderRes = yield call(getOrders, action.payload)
    const ordersData = orderRes.data.results
    if (!ordersData) {
      yield put(Actions.loadOrdersFail())
    } else {
      const orders = yield all(
        ordersData.map(orderData => call(getFormattedOrder, orderData))
      )
      yield put(Actions.loadOrdersSuccess(orders))
    }
  } catch (e) {
    yield put(Actions.loadOrdersFail())
  }
}

export function* loadDiscoveryBooks() {
  try {

    const sections = Object.keys(featuredBookEans)

    const results = yield all(
      sections.map(section => {
        const sectionEans = featuredBookEans[section].join(",")
        return call(getBookDetailsByEan, sectionEans)
      })
    )
    
    const discoveryBooks = sections.reduce((output, section, index) => {
      const scopedOutput = output
      if (!scopedOutput[section]) {
        scopedOutput[section] = []
      }
      scopedOutput[section] = results[index]?.data?.results || []
      return scopedOutput
    }, {})

  
    yield put(Actions.loadDiscoveryBooksSuccess(discoveryBooks))
  } catch (e) {
    console.log(e)
    yield put(Actions.loadDiscoveryBooksFail())
  }
}

export function* saga() {
  yield takeLatest(Actions.types.LOAD_ALL_BOOKS, loadBooksSaga)
  yield takeLatest(Actions.types.LOAD_MORE, loadMoreSaga)
  yield takeLatest(Actions.types.LOAD_BOOK, loadBookSaga)
  yield takeLatest(Actions.types.FILTER, loadBooksSaga)
  yield takeLatest(Actions.types.SEARCH, loadBooksSaga)
  yield takeLatest(Actions.types.RESET_FILTER, loadBooksSaga)
  yield takeLatest(Actions.types.RESET_SEARCH, loadBooksSaga)

  yield takeLatest(Actions.types.CREATE_ORDER, createOrderSaga)
  yield takeLatest(Actions.types.UPDATE_ORDER, updateOrderSaga)
  yield takeLatest(Actions.types.CREATE_ORDER_INTENT, createOrderIntentSaga)
  yield takeLatest(Actions.types.LOAD_ORDER, loadOrderSaga)
  yield takeLatest(Actions.types.LOAD_ORDERS, loadUserOrders)
  yield takeLatest(
    Actions.types.LOAD_DIGITAL_PURCHASES,
    loadUserDigitalPurchases
  )
  yield takeLatest(Actions.types.LOAD_DISCOVERY_BOOKS, loadDiscoveryBooks)
}
