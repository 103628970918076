import React, { useEffect } from "react"
import { Text } from "@engaging-tech/components"
import { useTranslation } from "react-i18next"

const translation = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t, i18n } = useTranslation(["translations", "survey"])
  const changeLanguage = code => {
    i18n.changeLanguage(code)
  }
  

  useEffect(() => {
    changeLanguage("en")
  }, [])

  return (
    <>
      <Text>Hi there</Text>
      <p>{t("translations:surveyCard.title", "")}</p>
      <p>{t("translations:hello", "")}</p>
      <p>{t("survey:surveyIntro", "")}</p>
      <button type="button" onClick={() => changeLanguage("en")}>
        {t("translations:en")}
      </button>
      <button type="button" onClick={() => changeLanguage("it")}>
        {t("translations:it")}
      </button>
    </>
  )
}

export default translation
