import { Text } from "@engaging-tech/components"
import { useRouter, usePaths } from "@engaging-tech/routing"
import React from "react"
import { useTranslation } from "react-i18next"
import codeAlreadyUsedContainer from "../../containers/codeAlreadyUsed.container"
import SurveyCard from "../surveyCard"

const CodeAlreadyUsed = ({ resetCode }) => {
  const router = useRouter()
  const paths = usePaths()

  const { t } = useTranslation("organisation_survey")

  return (
    <SurveyCard
      title={t("code_check.code_used", "")}
      leftButtonText={t("code_check.exit", "")}
      rightButtonText={t("code_check.retry", "")}
      noActions
      onNext={resetCode}
      onPrevious={() => router.navigate(paths.home)}
    >
      <Text
        color="dark.2"
        my={3}
        px={[3, 5]}
        textAlign="center"
        display="block"
        fontWeight={500}
      >
        {t("code_check.code_used1", "")}
      </Text>
      <Text
        color="dark.2"
        my={3}
        px={[3, 5]}
        textAlign="center"
        display="block"
        fontWeight={500}
      >
        {t("code_check.code_used2", "")}
      </Text>
    </SurveyCard>
  )
}

export default codeAlreadyUsedContainer(CodeAlreadyUsed)
