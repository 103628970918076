import { connect } from "react-redux"

import ExperienceComponent from "../components/Experience"
import { updateYourProfile } from "../store/yourProfile.actions"
import { getPositionsHeld } from "../store/yourProfile.selectors"

const mapState = state => ({
  positionsHeld: getPositionsHeld(state)
})

const mapDispatch = dispatch => ({
  onUpdate: params => dispatch(updateYourProfile(params))
})

const Experience = connect(mapState, mapDispatch)(ExperienceComponent)

export default Experience
