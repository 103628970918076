/**
 * A list of potential job types that a vacancy can be assigned to.
 * This list is used for client-side filtering of job vacancies.
 */
const jobTypes = [
  {
    id: "fullTime",
    value: "Full-time"
  },
  {
    id: "partTime",
    value: "Part-time"
  },
  {
    id: "internships",
    value: "Internship"
  },
  {
    id: "volunteer",
    value: "Volunteer"
  },
  {
    id: "graduateSchemes",
    value: "Graduate Scheme"
  },
  {
    id: "placements",
    value: "Placement"
  },
  {
    id: "apprenticeship",
    value: "Apprenticeship"
  }
]

const experienceLevels = [
  {
    id: "internship",
    value: "Internship"
  },
  {
    id: "entryLevel",
    value: "Entry level"
  },
  {
    id: "associate",
    value: "Associate"
  },
  {
    id: "midLevel",
    value: "Mid level"
  },
  {
    id: "seniorLevel",
    value: "Senior level"
  },
  {
    id: "director",
    value: "Director"
  },
  {
    id: "executive",
    value: "Executive"
  }
]

const remote = [
  { id: "true", value: "Remote Position" },
  { id: "false", value: "Non-Remote Position" }
]

const salaries = [
  { id: "20000", value: "£20,000 +" },
  { id: "25000", value: "£25,000 +" },
  { id: "30000", value: "£30,000 +" },
  { id: "35000", value: "£35,000 +" },
  { id: "40000", value: "£40,000 +" },
  { id: "50000", value: "£50,000 +" },
  { id: "60000", value: "£60,000 +" },
  { id: "70000", value: "£70,000 +" },
  { id: "80000", value: "£80,000 +" },
  { id: "90000", value: "£90,000 +" },
  { id: "100000", value: "£100,000 +" }
]

const radius = [
  { id: "1", value: "1 km" },
  { id: "2", value: "2 km" },
  { id: "5", value: "5 km" },
  { id: "10", value: "10 km" },
  { id: "20", value: "20 km" },
  { id: "40", value: "40 km" },
  { id: "60", value: "60 km" },
  { id: "80", value: "80 km" },
  { id: "100", value: "100 km" },
  { id: "250", value: "250 km" },
  { id: "500", value: "500 km" }
]

export const sortFilters = [
  {
    id: 1,
    label: "Date - newest",
    criteria: "createdAt",
    order: -1
  }
  // happiestIndustry: "Happiest industry",
  // happiestCompany: "Happiest company"
]

export const filterOptions = {
  jobType: {
    options: jobTypes,
    style: { minWidth: 150 },
    title: "Job type",
    defaultOptionId: null
  },
  industryId: {
    options: [],
    style: { minWidth: 200 },
    title: "Industry",
    defaultOptionId: null
  },
  salary: {
    options: salaries,
    style: {},
    title: "Salary",
    defaultOptionId: null
  },
  radius: {
    options: radius,
    style: { disableClearButton: true },
    title: "Distance",
    defaultOptionId: null
  },
  remote: {
    options: remote,
    style: {},
    title: "Remote",
    defaultOptionId: null
  },
  experienceLevel: {
    options: experienceLevels,
    style: {},
    title: "Experience Level",
    defaultOptionId: null
  }
}

export const getTypeNameById = id =>
  jobTypes.find(jobType => jobType.id === id)?.value

export const getExperienceNameById = id =>
  experienceLevels.find(level => level.id === id)?.value
