import { Box, Spinner, Text } from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"
import React, { useEffect } from "react"

import BookmarkToggle from "../../bookmarks/containers/BookmarkToggle"
import LibraryPreview from "../../userGeneratedContent/components/BusinessLibrary/Preview/Preview"
import pluralise from "../../userGeneratedContent/lib/pluralise"

const PostList = ({ isLoading, content, postType, onLoad, onDismount }) => {
  const paths = usePaths()

  useEffect(() => {
    onLoad()

    return () => {
      onDismount()
    }
  }, [onLoad, onDismount])

  if (isLoading) {
    return <Spinner color="primary.0" justifyContent="center" py={3} />
  }

  return (
    <Box width={1 / 1} px={1}>
      {content.length > 0 ? (
        content.map(post => (
          <LibraryPreview
            post={post}
            key={post.id}
            interactions={() => <BookmarkToggle id={post.id} postType={post.type} />}
          />
        ))
      ) : (
        <>
          <Text mb={3}>Your bookmarked {pluralise(postType)} will appear here</Text>
          <Text fontSize={3} mb={3}>
            Visit the{" "}
            <Link to={paths.resourceCentre.index}>
              <Text color="primary.1">Business Library</Text>
            </Link>{" "}
            or the{" "}
            <Link to={paths.globalHub.index}>
              <Text color="primary.1">Global Hub</Text>
            </Link>{" "}
            to start bookmarking
          </Text>
        </>
      )}
    </Box>
  )
}

export default PostList
