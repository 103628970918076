import React from "react"

import BackBar from "../features/ui/components/Backbar"
import PageLayout from "./PageLayout"

const DetailsLayout = ({ bg = "dark.7", backTo = "", children, actionName, ...props }) => (
  <PageLayout
    bg={bg}
    banner={() => <BackBar to={backTo} actionName={actionName} />}
    minHeight="100vh"
    {...props}
  >
    {children}
  </PageLayout>
)

export default DetailsLayout
