/* eslint-disable react/no-array-index-key */
import { Box, TextField } from "@engaging-tech/components"
import React, { useEffect, useState } from "react"

const ShortText = ({ fields, onAnswer, initialValue }) => {
  const [values, setValues] = useState(
    initialValue?.value && initialValue.value?.length ? initialValue.value : []
  )

  const handleUpdateInputValue = (value, index) => {
    const current = [...values]
    current[index] = value

    setValues(current)
  }

  useEffect(() => {
    const isValidInput = () => !values?.filter(value => !value).length

    if (isValidInput()) {
      onAnswer(values)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [values])

  return (
    <Box mt={[3, 5]}>
      {fields.map((field, index) => (
        <Box mb={3} key={index}>
          <TextField
            width={1 / 1}
            label={field.label}
            value={values[index] ?? " "}
            maxLength={field.limit}
            onChange={e => handleUpdateInputValue(e.target.value, index)}
          />
        </Box>
      ))}
    </Box>
  )
}

export default ShortText
