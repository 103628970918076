export const types = {
  SET_ROUTE: "[UI] Set Previous Route",
  SHOW_NOTIFICATION: "[UI] Show notification bar"
}

export const setRoute = url => ({
  type: types.SET_ROUTE,
  payload: url
})
export const showNotification = message => ({
  type: types.SHOW_NOTIFICATION,
  payload: message
})