const messengerFeatures = [
  {
    icon: "message",
    title: "Business Messenger",
    content: "Communicate securely with your network.",
    points: ["Add project groups"]
  },
  {
    icon: "videocam",
    title: "Video and conference calling",
    content: "Available as a subscription for £1.99 per quarter.",
    points: [
      "Unlimited calls and participants",
      "More than 100 available countries"
    ]
  },
  {
    icon: "email",
    title: "Email",
    content:
      "Integrate your business emails to have all your business communications in one place.",
    points: ["(Coming soon)"]
  },
  {
    icon: "language",
    title: "Global Hub",
    content:
      "Access jobs, forthcoming events, lectures and tutorials, articles, posts and ask for expert advice."
  },
  {
    icon: "local_grocery_store",
    title: "Marketplace",
    content: "Buy and sell for your business needs."
  },
  {
    icon: "collections_bookmark",
    title: "My Business library",
    content:
      "Read your ebooks and articles and watch and listen to lectures and tutorials."
  }
]

export default messengerFeatures
