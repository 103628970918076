import { Box, Button, Flex, Text, UserDetails } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React from "react"
import styled from "styled-components"

import Attachment from "../../Attachments"
import Post from "../../Post"
import AuthGuard from "./AuthGuard"

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const FullWidthLink = styled(Link)`
  width: 100%;
`

const extractFileName = url => {
  const splitUrl = url.split("/")
  return splitUrl[splitUrl.length - 1]
}

const formatPrice = research => {
  const {
    access: { price }
  } = research
  return price.toFixed(2)
}

const BusinessNetworkPromotion = () => (
  <Post.Card bg="light.0" p={3} width={1 / 1}>
    <Text fontWeight="500" mb={3}>
      Get employee experience research packs for free
    </Text>
    <Text fontSize={3} color="dark.2" mb={4}>
      Interested in improving employee experience at your organisation? If you are a business owner or leader
      sign up to WorkL for Business to get research packs and other helpful resources to benefit your
      organisation for free.
    </Text>
    <FullWidthLink to="/business">
      <Button variant="primary.0" width={1 / 1}>
        Join Network
      </Button>
    </FullWidthLink>
  </Post.Card>
)

const ResearchCard = ({ research, interactions, isAuthenticated, onPurchase }) => (
  <>
    <Post.Card bg="light.0" mb={3}>
      <Post.Header bg="secondary.1" mb={3} title="research" />
      <Post.Content>
        <UserDetails
          userHoldingImage={profilePlaceholder}
          user={research.user}
          time={research.publishedAt}
          interactions={interactions}
        />
        <Flex mb={4} flexDirection="column">
          <Box mb={3}>
            <Text width="auto" fontSize={4} fontWeight="500">
              {research.title}
            </Text>
          </Box>
          <Flex flexDirection={["column", "column", "row"]}>
            {research.coverImage && (
              <Attachment.Thumbnail
                minHeight={["186px", "186px", "200px"]}
                width={[1, 1 / 2, "186px"]}
                mb={[3, 3, 0]}
                mr={[0, 0, 3]}
                src={research.coverImage.source}
              />
            )}
            <Box>
              <Box mb={1}>
                {research.series && (
                  <Text fontSize={4} fontWeight="500">
                    {research.series.title}
                  </Text>
                )}
              </Box>

              <Box mb={3}>
                <Text fontSize={3} fontWeight="500" color="dark.2">
                  £{formatPrice(research)}
                </Text>
              </Box>
              <Box>
                <Text color="dark.2" lineHeight="1.6" fontSize={3}>
                  {research.summary}
                </Text>
              </Box>
            </Box>
          </Flex>
        </Flex>
        <AuthGuard isAuthenticated={isAuthenticated} callToActionText="Buy/Download research">
          <Flex mb={3} justifyContent="center">
            {research.content.length > 0 ? (
              research.content.map(document => (
                <Attachment.File
                  key={document.source}
                  name={extractFileName(document.source)}
                  url={document.source}
                />
              ))
            ) : (
              <Button py={2} px={5} variant="primary.0" width="auto" onClick={onPurchase}>
                BUY NOW
              </Button>
            )}
          </Flex>
        </AuthGuard>
      </Post.Content>
    </Post.Card>
    <BusinessNetworkPromotion />
  </>
)

export default ResearchCard
