/* eslint-disable import/prefer-default-export */
import { call, put, takeLatest } from "redux-saga/effects"

import { loadDemographicFilters } from "../services/demographicFilter.service"
import * as Actions from "./demographicFilter.actions"

export function* loadDemographicFiltersSaga() {
  try {
    const res = yield call(loadDemographicFilters)

    const filtersList = res?.data?.getdemoGraphicFilters

    const filtersListParsed = filtersList.map(({ items, ...filter }) => {
      return {
        ...filter,
        id: filter.label.toLocaleLowerCase().replace(" ", "_"),
        items: JSON.parse(items)
      }
    })

    yield put(Actions.loadFiltersSuccess(filtersListParsed))
  } catch (error) {
    yield put(Actions.loadFiltersFail())
  }
}

export function* saga() {
  yield takeLatest(Actions.types.LOAD_FILTERS, loadDemographicFiltersSaga)
}
