import { Flex } from "@engaging-tech/components"
import React from "react"

import SurveyContentView from "../../../features/survey/views/surveyContent"

const SurveyContent = () => (
  <Flex minHeight="100vh" flexDirection="column">
    <Flex as="main" flex="1" alignItems="stretch">
      <SurveyContentView />
    </Flex>
  </Flex>
)

export default SurveyContent
