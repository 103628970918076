import React from "react"

import DynamicState from "../../../../containers/DynamicState"

const nonPremiumImage = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/career-developer/widget-images/job-matcher-free-no-bg.svg`)

const NonPremiumView = ({ id }) => (
  <DynamicState
    id={id}
    removable
    hasBanner
    title="Find Great Job Vacancies"
    subTitle="Sign up to our Premium Service to get automatically matched with a job vacancy based on your skill set and career goals."
    image={nonPremiumImage}
    bgColor="yellow.4"
    btnText="GET PREMIUM"
    linkTo="/premium"
  />
)

export default NonPremiumView
