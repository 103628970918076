import { Button, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const ButtonText = styled(Text)`
  font-size: 14px;
  font-weight: 700;
`

const SecondaryButton = ({ text = null, children, color = "quaternary.0", ...props }) => (
  <Button alignItems="center" width="auto" variant="secondary.0" {...props}>
    <ButtonText color={color}>{children || text}</ButtonText>
  </Button>
)

export default SecondaryButton
