import React from "react"
import styled from "styled-components"

import ComparisonBarChart from "./comparison-bar-chart"

const GridContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const chartSelector = (dataSet, graphDescription, disableAnimationTrigger, props) => {
  if (dataSet.graphType === "comparison_bar_chart") {
    return (
      <ComparisonBarChart
        dataSet={dataSet}
        key={dataSet.name}
        singular={dataSet.singular}
        disableAnimationTrigger={disableAnimationTrigger}
        yAxisDecimalPlaces={dataSet.yAxisDecimalPlaces}
        unit={dataSet.unit}
        graphDescription={graphDescription}
        {...props}
      />
    )
  }
  return <></>
}

export default function ChartsHandler({ chartData, disableAnimationTrigger, graphDescription, ...props }) {
  if (!chartData) return <></>

  if (Array.isArray(chartData)) {
    const items = chartData.map(dataSet => {
      return <div>{chartSelector(dataSet, graphDescription, disableAnimationTrigger, props)}</div>
    })

    return <GridContainer>{items}</GridContainer>
  }
  return chartSelector(chartData, graphDescription, disableAnimationTrigger, props)
}
