import React from "react"
import { H4, Paragraph, Text, Box } from "@engaging-tech/components"

import { getJobTypeById } from "../../lib/jobs"

const InfoBox = ({ children, ...props }) => (
  <Box mb={2} {...props}>
    <Text
      color="dark.2"
      fontSize={[2, 1]}
      p={1}
      bg="dark.7"
      letterSpacing="0.031rem"
    >
      {children}
    </Text>
  </Box>
)

const Summary = ({ job }) => (
  <>
    <H4 fontSize="4" fontWeight="500" mb={2}>
      {job.role}
    </H4>
    <Paragraph fontSize={3} color="dark.2" fontWeight="500" mb={2}>
      {job.organisation.name}
    </Paragraph>
    <Paragraph fontSize={2} color="dark.2" mb={3}>
      {job.location.city}, {job.location.country}
    </Paragraph>
    <InfoBox>{getJobTypeById(job.jobType)}</InfoBox>
    <InfoBox>
      {job.salary.private
        ? "Salary: Competitive"
        : `£${job.salary.min} - £${job.salary.max}`}
    </InfoBox>
  </>
)

export default Summary
