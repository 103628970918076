import React, { useState } from "react"
import { Chip, Flex, Select, Button, Text } from "@engaging-tech/components"
import styled from "styled-components"
import ProfileSection from "./ProfileSection"

const StyledChip = styled(Chip)`
  &:hover {
    cursor: auto;
  }
`

const AddLink = styled(Text)`
  cursor: ${({ disabled }) => !disabled && "pointer"};
  color: ${({ theme }) => theme.colors.primary[0]};
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
`

const FormShowing = ({ toggle, skills, onUpdate, allSkills }) => {
  const [skill, setSkill] = useState("")
  const [mySkills, setMySkills] = useState(skills)
  const handleSelect = e =>
    setSkill({
      id: e.target.value,
      name: allSkills.filter(ski => ski.id === e.target.value)[0].name
    })
  return (
    <Flex flexDirection="column" mt={2}>
      <Text fontSize={3} color="dark.2" my={2}>
        Your skills
      </Text>
      <Select
        label="Add Skill"
        defaultValue=""
        value={skill.id}
        onChange={handleSelect}
      >
        <option disabled value="" selected>
          {" "}
        </option>
        {allSkills.map(ski => (
          <option key={ski.id} value={ski.id}>
            {ski.name}
          </option>
        ))}
      </Select>
      <AddLink
        disabled={skill === ""}
        mt={3}
        onClick={() => {
          if (!mySkills.find(h => h.id === skill.id) && !!skill) {
            setMySkills([...mySkills, skill])
          }
        }}
      >
        + ADD SKILL
      </AddLink>
      {mySkills && (
        <Flex flexWrap="wrap">
          {mySkills.length > 0 &&
            mySkills.map(point => (
              <Chip
                key={point.id}
                mr={2}
                mt={2}
                trailingIcon="close"
                color="primary.0"
                onClick={() => {
                  setMySkills([...mySkills.filter(el => el.id !== point.id)])
                }}
              >
                {point.name}
              </Chip>
            ))}
        </Flex>
      )}
      <Button
        mt={3}
        elevation={0}
        variant="outline.primary.0"
        width="120px"
        onClick={() => {
          onUpdate({ skills: mySkills.map(ski => ski.id) })
          toggle()
        }}
      >
        SAVE
      </Button>
    </Flex>
  )
}

const DataShowing = ({ skills }) =>
  skills ? (
    <>
      <Text mt={2}>Your selected skills:</Text>
      <Flex flexWrap="wrap">
        {skills.length > 0 &&
          skills.map(point => (
            <StyledChip key={point.id} mr={2} mt={2} cursor="none">
              {point.name}
            </StyledChip>
          ))}
      </Flex>
    </>
  ) : (
    <Text mt={2}>You have not selected any skill yet</Text>
  )

const Skills = ({
  skills,
  onUpdate,
  allSkills,
  toggle,
  hideCancelButton = false
}) => {
  const [dataShowing, setDataShowing] = useState(true)

  return (
    <ProfileSection
      title="Skills"
      icon="build"
      onClick={() => setDataShowing(!dataShowing)}
      toggle={toggle}
      dataShowing={dataShowing}
      hideCancelButton={hideCancelButton}
    >
      {dataShowing ? (
        <DataShowing skills={skills} />
      ) : (
        <FormShowing
          toggle={toggle || (() => setDataShowing(true))}
          skills={skills}
          onUpdate={onUpdate}
          allSkills={allSkills}
        />
      )}
    </ProfileSection>
  )
}

export default Skills
