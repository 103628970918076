import React from "react"
import styled from "styled-components"
import { Text, Box } from "@engaging-tech/components"

const HEADER_COLOURS = {
  article: "#8ABFEC",
  podcast: "#FFEA76",
  lecture: "#C3FCC3",
  research: "#EC8A92"
}

const HeaderText = styled(Text)`
  text-transform: capitalize;
`

const HeaderWrapper = styled(Box)`
  border-radius: 4px 4px 0 0;
`

const SummaryHeader = ({ type }) => (
  <HeaderWrapper bg={HEADER_COLOURS[type] || "transparent"} py={2} px={3}>
    <HeaderText fontWeight="500">{type}</HeaderText>
  </HeaderWrapper>
)

export default SummaryHeader
