import { gql } from "../../../lib/Apollo"

export const LOAD = gql`
  query ($id: ID!) {
    getSurvey(surveyId: $id) {
      type
      id
      title
      status
      introduction {
        type
        content
      }
      languages {
        code
        name
        nativeName
      }
      meta {
        brand {
          colour
          logo
        }
      }
      template {
        id
        questions {
          type
          id
          title
          required
          description
          icon
          ... on ScaleQuestion {
            min
            max
          }
          ... on DropdownQuestion {
            label
            values {
              id
              value
            }
          }
          ... on RadioQuestion {
            values {
              id
              value
            }
          }
          ... on ShortTextQuestion {
            fields {
              label
              limit
              type
            }
          }
        }
        sections {
          name
          description {
            type
            content
          }
          questions
        }
      }
    }
  }
`

export const SUBMIT = gql`
  mutation (
    $id: ID!
    $templateId: ID!
    $answers: [SurveyAnswerInput]!
    $companyName: String
    $savantaId: ID
    $savantaTerritory: String
    $surveyCompanyId: ID
  ) {
    createSurveySubmission(
      surveyId: $id
      surveyTemplateId: $templateId
      answers: $answers
      companyName: $companyName
      savantaId: $savantaId
      savantaTerritory: $savantaTerritory
      surveyCompanyId: $surveyCompanyId
    )
  }
`

export const ATTACH_USER = gql`
  mutation ($id: ID!, $token: String) {
    attachUserToSurveySubmission(surveySubmissionId: $id, token: $token)
  }
`
