import React from "react"

import CVListLoader from "../../features/cvLibrary/containers/CVListLoader"
import CVLibraryView from "../../features/cvLibrary/views/CVLibrary"
import DetailsLayout from "../../layouts/DetailsLayout"

const YourProfile = () => {
  return (
    <DetailsLayout actionName="CAREER DEVELOPER" backTo="/develop-career" bg="light.0">
      <CVListLoader />
      <CVLibraryView />
    </DetailsLayout>
  )
}

export default YourProfile
