import { Themes } from "@engaging-tech/components"
import { createSelector } from "reselect"

import surveyLifecycle from "../utils/surveyLifecycle"
import { castAnswersToString, formatLanguages, generateBrandTheme } from "./helpers"

export const surveyState = state => state.organisationSurveys
export const surveyDataMetaState = state => state.organisationSurveys?.data?.meta
const surveyDataTemplateState = state => state.organisationSurveys?.data?.template

// data

export const getIsLoading = createSelector(surveyState, state => state.isLoading)

export const getIsSubmitting = createSelector(surveyState, state => state.isSubmitting)

export const getHasError = createSelector(surveyState, state => state.hasError)

export const getHasLoaded = createSelector(surveyState, state => state.hasLoaded)

export const getHasSubmitted = createSelector(surveyState, state => state.hasSubmitted || state.isLoading)

export const getHeaderLogo = createSelector(surveyState, state => state?.data?.features?.headerLogo)

export const getBranding = createSelector([surveyState, surveyDataMetaState], (state, meta) => {
  const brand = meta?.brand
  const colour = brand?.colour
  const logo = brand?.logo?.includes("https") ? brand?.logo : null
  const fallbackImage = state.hasLoaded
    ? "https://app.workl.co/static/media/WorkL_Logo_TEAL.dd3f186b.png"
    : ""

  return {
    theme: colour ? generateBrandTheme(colour) : Themes.workL,
    logo: {
      url: logo || fallbackImage,
      isFallback: !logo && state.hasLoaded
    },
    colour
  }
})

export const getData = createSelector(surveyState, state => {
  return state.data
})

export const getLanguage = createSelector(surveyState, state => state?.language)

export const getLanguages = createSelector(
  surveyState,
  state => state.data?.languages && formatLanguages(state.data.languages)
)

// questions

export const getAllQuestions = createSelector(surveyDataTemplateState, template =>
  template?.sections?.reduce((acc, cur) => [...acc, ...cur.questions], [])
)

export const getQuestionTotal = createSelector(getAllQuestions, questionList => questionList.length)

export const getQuestionIndexById = (state, id) => {
  const questionList = getAllQuestions(state)

  return questionList.indexOf(id) + 1
}

export const getQuestionById = createSelector(
  (state, questionId) => {
    const { questions } = surveyDataTemplateState(state)
    // return data.questions[questionId]
    return questions.find(i => i.id === questionId)
  },
  question => question || {}
)

export const getQuestionLogics = createSelector(surveyDataTemplateState, state => state?.questionLogics)

// question choices

export const getChoiceLogics = createSelector(surveyDataTemplateState, state => state?.choiceLogics)

// answers

const getAnswer = (state, id) => {
  const {
    organisationSurveys: { answers }
  } = state

  return answers ? answers?.find(answer => answer.questionId === id) : []
}

export const getAnswerById = createSelector(getAnswer, answer =>
  answer
    ? {
        id: answer.questionId,
        value: answer.value
      }
    : null
)

export const getAllAnswers = createSelector(surveyState, state => state?.answers)

// code

const getMetaMode = createSelector(surveyDataMetaState, metaState => metaState && metaState.mode)

export const getRequiresCode = createSelector(getMetaMode, mode => mode?.uniqueSubmission)

export const getCodeAlreadyUsed = createSelector(surveyState, state => state.hasSubmitted)

export const getHasValidCode = createSelector(surveyState, state => state && state.isValidCode)

export const getIsValidatingCode = createSelector(surveyState, state => state?.isValidatingCode)

// others

export const getStatus = createSelector(surveyState, state => state.data?.status)

export const getIsTestSurvey = createSelector(getStatus, status => status !== surveyLifecycle.live)

export const getIsPrivate = createSelector(surveyDataMetaState, meta => meta && meta.private)

export const getSubmissionId = createSelector(surveyState, state => state.submissionId)

export const getHasAttachedUser = createSelector(surveyState, state => state?.hasAttachedUser)

export const getId = createSelector(surveyState, state => state.data.surveyId)

export const getTemplateId = createSelector(surveyState, state => state?.data?.template?.id)

export const getSavantaId = createSelector(surveyState, state => state?.savantaId)

export const getIndividualCode = createSelector(surveyState, state => state?.individualCode || "")

export const getFormattedSubmission = createSelector(
  [getId, getTemplateId, getAllAnswers, getIndividualCode, getLanguage, getSavantaId],
  (id, templateId, answers, code, language, savantaId) => {
    return {
      id,
      templateId,
      answers: castAnswersToString(answers),
      code,
      language,
      savantaId
    }
  }
)

export const getIsIsolated = createSelector(surveyDataMetaState, meta => meta && meta.isolated)
export const getIsIsolatedLoading = createSelector(
  [surveyDataMetaState, getIsLoading],
  (meta, isLoading) => !meta || isLoading
)
