import React from "react"
import { Box, Paragraph, UserDetails, Flex } from "@engaging-tech/components"

const AVATAR_SIZE = 30 // size in px for user pictures

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const Reply = ({ reply }) => (
  <Flex mt={3} width="95%" flexDirection="column">
    <Flex width="100%">
      <UserDetails
        user={reply.anonymous ? null : reply.user}
        userHoldingImage={profilePlaceholder}
        avatarSize={AVATAR_SIZE}
        avatarMargin={0}
        timeMargin={1}
        time={reply.publishedAt || Date.now()}
      />
    </Flex>

    {reply.content.map(paragraph => (
      <Paragraph
        ml={AVATAR_SIZE + 10}
        my={0}
        letterSpacing="0.02rem"
        fontSize={3}
        color="dark.2"
        key={paragraph.body}
        width={`calc(100% - ${AVATAR_SIZE + 10}px)`}
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap"
        }}
      >
        {paragraph.body}
      </Paragraph>
    ))}
  </Flex>
)

export default Reply
