import { Flex } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const logoUrl = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/WorkL_Logo_TEAL.png`)

const LogoWrapper = styled(Flex)`
  user-select: none;
`

const Logo = styled.img`
  width: 128px;
  height: 30px;
  margin-right: ${({ theme }) => theme.space[2]}px;
`

const SiteLogo = () => (
  <LogoWrapper alignItems="center" width="auto">
    <Logo src={logoUrl} />
  </LogoWrapper>
)

export default SiteLogo
