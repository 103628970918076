import { connect } from "react-redux"

import Selected from "../components/Posts/SelectedPost"
import { select } from "../store/globalHub.actions"
import { getIsLoading, getSelectedPost } from "../store/globalHub.selectors"

const mapState = state => ({
  selectedPost: getSelectedPost(state),
  isLoading: getIsLoading(state)
})

const mapDispatch = dispatch => ({
  onLoad: selectedPostId => dispatch(select(selectedPostId))
})

const SelectedPost = connect(mapState, mapDispatch)(Selected)

export default SelectedPost
