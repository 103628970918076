import styled from "styled-components"

import { FilterDropdownIcon } from "../../../../../../assets/icons"

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  min-width: 128px;
  width: auto;
`

export const DropdownButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 8px 16px;
  background-color: transparent;
  border: 0.25px solid #003e4c;

  cursor: pointer;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #003e4c;

  border-top-right-radius: ${({ isOpen }) => (isOpen ? "15px" : "20px")};
  border-top-left-radius: ${({ isOpen }) => (isOpen ? "15px" : "20px")};
  border-bottom-right-radius: ${({ isOpen }) => (isOpen ? 0 : "20px")};
  border-bottom-left-radius: ${({ isOpen }) => (isOpen ? 0 : "20px")};

  & path {
    fill: #003e4c;
  }
`

export const StyledIcon = styled(FilterDropdownIcon)`
  margin-left: 20px;
`

export const DropdownList = styled.ul`
  width: 100%;
  position: absolute;
  top: 14px;
  left: 0;
  z-index: 10;
  background-color: #f5f5f5;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 20px 20px;

  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  padding-left: 0;
  padding: 11px 0 20px;

  li:not(:first-child) {
    margin-top: 10px;
  }
`

export const DropdownItem = styled.li`
  width: 100%;
  list-style: none;

  text-align: start;

  display: flex;
  justify-content: start;
  align-content: start;

  cursor: pointer;

  background-color: ${({ selected }) => (selected ? "#d7d7d7" : "")};

  padding: 0 3px 0 9px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #003e4c;

  &:hover {
    background-color: #d7d7d7;
  }
`
