import React, { useEffect } from "react"
import { Spinner, Box, Text } from "@engaging-tech/components"

import DigitalBookPreview from "./DigitalBookPreview"
import AdobeBadges from "./AdobeBadges"

const ACCESS = {
  signup: 1,
  premium: 2
}

const freeEbooks = [
  {
    id: "1234",
    source: "engaging-works",
    title: "Free eBook: Workplace Fables",
    type: "ebook",
    authors: ["Price, Mark"],
    image:
      "https://media.engaging.works/production/media/ebooks/workplacefables.png",
    categories: ["5d149ad7dcb3ad8a5440ecda", "5d80b3e9aabef3ae1a7b971a"],
    downloadLink:
      "https://media.engaging.works/production/media/ebooks/epub/WorkplaceFables.epub",
    maxDownloads: null,
    access: ACCESS.signup
  },
  {
    id: "5234",
    source: "engaging-works",
    title:
      "Free eBook: Fairness for All: Unlocking the Power of Employee Engagement",
    type: "ebook",
    authors: ["Price, Mark"],
    image:
      "https://media.engaging.works/production/media/ebooks/fairnessforall.png",
    categories: ["5d149ad7dcb3ad8a5440ecda", "5d80b3e9aabef3ae1a7b971a"],
    downloadLink:
      "https://media.engaging.works/production/media/ebooks/epub/FairnessForAll.epub",
    maxDownloads: null,
    access: ACCESS.premium
  }
]

const hasAccess = (ebook, isPremium, isAuthenticated) => {
  const { access } = ebook
  if (access === ACCESS.signup) return isAuthenticated
  if (access === ACCESS.premium) return isPremium
  return false
}

const EBookList = ({
  eBooks,
  isLoading,
  isPremium,
  isAuthenticated,
  onDownload,
  onLoad,
  userDetails
}) => {
  useEffect(() => {
    if (userDetails.userId) onLoad(userDetails.userId)
  }, [])

  if (isLoading) {
    return <Spinner color="primary.0" justifyContent="center" py={3} />
  }
  return (
    <Box width={1 / 1} px={1}>
      <Text mb={3} fontSize={5} fontWeight={500} display="block">
        My eBooks
      </Text>
      <Box p={3} bg="dark.6" mb="4" borderRadius={4}>
        <Text fontWeight={500} fontSize={4}>
          Download Adobe Digital Editions to read your ebook
        </Text>
        <AdobeBadges
          appleUrl="https://apps.apple.com/us/app/adobe-digital-editions/id952977781"
          googleUrl="https://play.google.com/store/apps/details?id=com.adobe.digitaleditions"
          desktopUrl="https://www.adobe.com/solutions/ebook/digital-editions/download.html"
          mt={[2]}
          alignItems="flex-start"
          justifyContent="flex-start"
          alignment="flex-start"
        />
      </Box>
      {freeEbooks
        .filter(ebook => hasAccess(ebook, isPremium, isAuthenticated))
        .map(ebook => (
          <DigitalBookPreview
            book={ebook}
            key={ebook.id}
            onDownload={onDownload}
          />
        ))}
      {eBooks &&
        eBooks.map(ebook => (
          <DigitalBookPreview
            book={ebook}
            key={ebook.id}
            onDownload={onDownload}
          />
        ))}
    </Box>
  )
}

export default EBookList
