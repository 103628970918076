import { takeLatest, put, call } from "redux-saga/effects"

import { load, update } from "../services/yourTests.service"

import * as Actions from "./yourTests.actions"

export function* loadSaga(action) {
  try {
    const res = yield call(load, action.payload)
    yield put(Actions.loadSuccess(res.data.getProfileTests))
  } catch {
    yield put(Actions.loadFail())
  }
}

export function* updateSaga(action) {
  try {
    const res = yield call(update, action.payload)
    yield put(Actions.updateSuccess(res.data.updateProfileTest))
  } catch (error) {
    console.log({ error })
    yield put(Actions.updateFail())
  }
}

export function* saga() {
  yield takeLatest(Actions.types.LOAD, loadSaga)
  yield takeLatest(Actions.types.UPDATE, updateSaga)
}
