import React, { useState, useEffect, useRef } from "react"
import { Text, Icon, MotionFlex, CustomHooks } from "@engaging-tech/components"

const OptionsBox = ({
  id,
  removeWidget,
  close,
  gridCustomisationFlag,
  updateGridCustomisationFlag,
  removable
}) => {
  const [active, setActive] = useState(false)
  const wrapperRef = useRef(null)

  // If the close state gets updated, close the options box
  useEffect(() => {
    let isCancelled = false
    if (!isCancelled) {
      setActive(false)
    }

    return () => {
      isCancelled = true
    }
  }, [close])
  // If user clicks outside of options box, close it
  CustomHooks.useOutsideAlerter(wrapperRef, () => setActive(false))
  const optionHeight = 32
  const optionBoxContainerVariants = {
    active: optionCount => ({
      width: 205,
      height: 13 + optionCount * optionHeight,
      boxShadow: "rgba(0, 0, 0, 0.2) -1px 3px 5px 0px",
      backgroundColor: "white"
    }),
    unActive: {
      width: 30,
      height: 30,
      boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0.0)",
      backgroundColor: "transparent",
      transition: {
        delay: 0.1
      }
    }
  }
  const optionVariants = {
    active: {
      opacity: 1,
      display: "flex",
      transition: {
        display: {
          delay: 0.1
        },
        opacity: {
          delay: 0.15
        }
      }
    },
    unActive: {
      display: "none",
      opacity: 0
    }
  }

  const optionCount = () => {
    let count = 0
    if (!gridCustomisationFlag) {
      count += 1
    }
    if (removable) {
      count += 1
    }
    return count
  }
  return (
    <>
      {optionCount() > 0 && (
        <MotionFlex
          onHoverStart={() => setActive(true)}
          onHoverEnd={() => setActive(false)}
          variants={optionBoxContainerVariants}
          custom={optionCount()}
          initial="unActive"
          animate={active ? "active" : "unActive"}
          style={{
            position: "absolute",
            right: "10px",
            borderRadius: "8px",
            zIndex: 10000000000000
          }}
          ref={wrapperRef}
          onTap={() => setActive(!active)}
        >
          <Icon
            name="more_vert"
            color="dark.0"
            style={{
              position: "absolute",
              right: "3px",
              top: "10px",
              cursor: "pointer"
            }}
          />

          <MotionFlex
            style={{ position: "absolute", left: "15px", top: "10px" }}
            variants={optionVariants}
            initial="unActive"
            animate={active ? "active" : "unActive"}
            flexDirection="column"
            width="170px"
            height="50px"
          >
            {removable && (
              <MotionFlex
                className="widget-remove-button"
                id="widget-remove-button"
                style={{ cursor: "pointer" }}
                mb={2}
                onTap={() => {
                  removeWidget(id)
                  setActive(false)
                }}
              >
                <Icon name="cancel" mr={2} />
                <Text id="widget-remove-button">Remove Widget</Text>
              </MotionFlex>
            )}

            {!gridCustomisationFlag && (
              <MotionFlex
                className="grid-edit-button"
                id="grid-edit-button"
                style={{ cursor: "pointer" }}
                onTap={() => {
                  updateGridCustomisationFlag(true)
                  setActive(false)
                }}
              >
                <Icon name="edit" mr={2} />
                <Text id="grid-edit-button">Edit Widget</Text>
              </MotionFlex>
            )}
          </MotionFlex>
        </MotionFlex>
      )}
    </>
  )
}

export default OptionsBox
