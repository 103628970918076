import React, { useEffect, useState, useCallback } from "react"
import styled from "styled-components"
import {
  Box,
  Button,
  Card,
  H2,
  Paragraph,
  Spinner
} from "@engaging-tech/components"
import { useLocation, useRouter } from "@engaging-tech/routing"
import { replyToInvitation } from "../services/connect.service"
import { emailEW } from "../../../lib/globalConstants"

const Link = styled.a`
  color: ${({ theme }) => theme.colors.primary[0]};
`

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function getAcceptValue(acceptParam) {
  if (acceptParam === "true") return true
  if (acceptParam === "false") return false
  return null
}

const InvitationResponse = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [response, setResponse] = useState()
  const router = useRouter()
  const query = useQuery()
  const acceptParam = query.get("accept")
  const invitationId = query.get("invitationId")

  const sendReply = useCallback(async () => {
    try {
      const params = {
        accept: getAcceptValue(acceptParam),
        invitationId
      }
      const res = await replyToInvitation(params)
      setResponse(res.data.replyConnectInvitation)
      setIsLoading(false)
    } catch (error) {
      setHasError(true)
      setIsLoading(false)
    }
  }, [acceptParam, invitationId])

  useEffect(() => {
    if (!invitationId || !acceptParam) {
      router.navigate("/")
    }
    if (invitationId && acceptParam) {
      sendReply()
    }
  }, [acceptParam, invitationId, router, sendReply])

  if (isLoading)
    return (
      <Card width={1}>
        <Spinner color="primary.0" />
      </Card>
    )

  if (hasError)
    return (
      <Card width={1} alignItems="center">
        <Box maxWidth={700}>
          <H2 fontSize={6}>Something went wrong...</H2>
          <Paragraph mt={3}>
            Please try again at later stage or if the problem persists please
            contact us at <Link href={`mailto:${emailEW}`}>{emailEW}</Link>
          </Paragraph>
        </Box>
      </Card>
    )

  return (
    <Card width={1} alignItems="center">
      {response === true && (
        <>
          <H2 fontSize={6} textAlign="center" mb={4} color="dark.1">
            Connection {acceptParam === "true" ? "Accepted" : "Declined"}{" "}
            Successfully
          </H2>
          <Link to="/">
            <Button variant="primary.0">Global Hub</Button>
          </Link>
        </>
      )}
      {response === false && (
        <>
          <H2 fontSize={6} textAlign="center" mb={4} color="dark.1">
            Connection Unsuccessful
          </H2>
          <Link to="/">
            <Button variant="primary.0">Global Hub</Button>
          </Link>
        </>
      )}
    </Card>
  )
}

export default InvitationResponse
