import { connect } from "react-redux"

import {
  getBooksResults,
  getDiscoveryBooksTop5,
  getIsLoading,
  getHasResults
} from "../store/libraryBooks.selectors"

import { loadDiscoveryBooks, loadAllBooks } from "../store/libraryBooks.actions"

import List from "../components/Browse/BookList"

const mapState = state => ({
  books: getBooksResults(state),
  top5Books: getDiscoveryBooksTop5(state),
  isLoading: getIsLoading(state),
  noResults: !getHasResults(state)
})

const mapDispatch = dispatch => ({
  onLoadTop5: () => dispatch(loadDiscoveryBooks()),
  onLoad: (payload) => dispatch(loadAllBooks(payload))
})

const AllBooks = connect(mapState, mapDispatch)(List)

export default AllBooks
