import React from "react"
import { Flex, Card, H3, Text, Icon } from "@engaging-tech/components"
import { useSelector } from "react-redux"
import { Link, usePaths } from "@engaging-tech/routing"
import PrimaryButton from "../../../ui/components/PrimaryButton"
import { getIsPremium } from "../../../yourProfile/store/yourProfile.selectors"

const EmptyBasket = () => {
  const paths = usePaths()
  const isPremium = useSelector(getIsPremium)
  return (
    <Card bg="light.0" flexDirection="column" width={1 / 1}>
      <Flex alignItems="center" alignContent="center" p={3}>
        <Icon name="shopping_cart" size={40} color="quaternary.0" />
        <H3 pl={3} width={1 / 1} fontSize={18} fontWeight={700}>
          Your shopping basket is empty
        </H3>
      </Flex>
      <Flex flexDirection="column" pl={70} pr={2}>
        <Text mb={isPremium ? 4 : 2}>
          <Link
            color="primary.0"
            fontWeight={700}
            to={paths.resourceCentre.browse.index}
          >
            Browse{" "}
          </Link>
          and
          <Link
            color="primary.0"
            fontWeight={700}
            to={paths.resourceCentre.discover}
          >
            {" "}
            Discover{" "}
          </Link>
          new content we know you&apos;ll love in the Buiness Library
        </Text>
        {!isPremium && (
          <>
            <Text mb={2}>
              Remember if you sign up to WorkL Premium today you&apos;ll receive
              20% off ALL books, ebooks and audiobooks
            </Text>
            <PrimaryButton
              text="GET PREMIUM"
              mt={3}
              mb={3}
              width={150}
              color="grey.0"
            />
          </>
        )}
      </Flex>
    </Card>
  )
}

export default EmptyBasket
