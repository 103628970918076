import React from "react"
import CompaniesLayout from "../../layouts/CompaniesLayout"
import CMIComponent from "../../features/companies/components/CMI"

const CMI = () => (
  <CompaniesLayout>
    <CMIComponent />
  </CompaniesLayout>
)

export default CMI
