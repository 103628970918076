/* eslint-disable import/prefer-default-export */
import { getConfig } from "@engaging-tech/ssr-config"
import { apollo } from "../../../lib/Apollo"
import { LOAD } from "./surveyResults.queries"

const GRAPHQL_API_URL = () =>
  `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

export const load = id =>
  apollo.query({
    uri: GRAPHQL_API_URL,
    query: LOAD,
    variables: { id }
  })
