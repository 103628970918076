import { Formik } from "formik"
import React from "react"
import { object, string } from "yup"

import Form from "./form"

const initialValues = {
  content: ""
}

const validation = object().shape({
  content: string().required("Reply text is required")
})

const CreateReplyForm = ({ isCommenting, onSubmit, user, postId, parentId, onClose }) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validation}
    onSubmit={comment => onSubmit({ ...comment, postId, parentId })}
  >
    {props => <Form {...props} isLoading={isCommenting} user={user} onClose={onClose} />}
  </Formik>
)

export default CreateReplyForm
