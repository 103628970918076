import React from "react"
import { OrderList } from "../../../features/gardners"

import AuthGuard from "../../../features/ui/containers/AuthGuard"
import PageLayout from "../../../layouts/PageLayout"

const Purchased = () => (
  <AuthGuard>
    <PageLayout
      flexDirection="column"
      width={1 / 1}
      px={1}
      pt={1}
    >
      <OrderList />
    </PageLayout>
  </AuthGuard>
)

export default Purchased
