import React from "react"
import { usePaths } from "@engaging-tech/routing"

import mapPathsToCategories from "../lib/mapPathsToCategories"
import CategoryGrid from "./CategoryGrid"

const LibraryLanding = () => {
  const paths = usePaths()
  const bookmarkedRoutes = mapPathsToCategories(paths.resourceCentre.myLibrary)

  return (
    <CategoryGrid
      title="Bookmarked"
      icon="bookmarks"
      routes={bookmarkedRoutes}
    />
  )
}

export default LibraryLanding
