/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import { Flex, Box, H3, Text } from "@engaging-tech/components"

const ContentBlock = ({
  title,
  subtitle,
  noGutter,
  isFullWidth,
  children,
  ...props
}) => (
  <Flex
    as="section"
    justifyContent="center"
    alignItems="center"
    py={[5, 6]}
    px={noGutter ? 0 : [3, 4]}
    {...props}
  >
    <Box m={0} p={0} maxWidth={isFullWidth ? "100%" : 1024}>
      <Box mb={[5, 6]}>
        {title && (
          <H3
            fontWeight="600"
            color="dark.1"
            fontSize="24px"
            fontFamily="serif"
            textAlign="center"
            mb={subtitle ? 3 : 0}
          >
            {title}
          </H3>
        )}
        {subtitle && (
          <Text textAlign="center" display="block">
            {subtitle}
          </Text>
        )}
      </Box>
      {children}
    </Box>
  </Flex>
)

export default ContentBlock
