import { PrivacyPolicy } from "@engaging-tech/legal"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"

import PageLayout from "../../layouts/PageLayout"
import { emailEW } from "../../lib/globalConstants"

const Privacy = () => (
  <PageLayout isPublic flexDirection="column">
    <PrivacyPolicy
      date={new Date("2024-01-01")}
      contactEmail={emailEW}
      cookiesUrl={`${getConfig().workL.app}/legal/cookies`}
    />
  </PageLayout>
)

export default Privacy
