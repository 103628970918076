import { CustomHooks } from "@engaging-tech/components"
import React from "react"

import { MagicLinkRedirect as MagicLinkRedirectView } from "../../features/account"
import SiteLogo from "../../features/ui/components/SiteLogo"
import GradientLayout from "../../layouts/GradientLayout"

export default function MagicLinkRedirect() {
  CustomHooks.useFreshChatDeactivate(true)

  return (
    <GradientLayout>
      <MagicLinkRedirectView redirectTo="/" siteLogo={SiteLogo} cancelRoute="/" />
    </GradientLayout>
  )
}
