import React from "react"

import ForgotPasswordForm from "../forms/forgotPassword"

/**
 * The "Forgot Password" dialog box.
 * @param {React.Component} siteLogo The brand logo to show within the dialog.
 * @param {string} cancelRoute The route to redirect a user to if they click "Cancel".
 */
export default function ForgotPassword({ siteLogo, cancelRoute }) {
  return <ForgotPasswordForm siteLogo={siteLogo} cancelRoute={cancelRoute} />
}
