import { Redirect, usePaths } from "@engaging-tech/routing"
import React, { useEffect } from "react"
import { connect } from "react-redux"

import { attachUserToSubmissionByTokens } from "../../../lib/SubmissionTokenCookies"
import { AccountSelectors } from "../../account"
import { yourProfileSelectors } from "../../yourProfile"

const Guard = ({ isAuthenticated, isAuthenticating, children }) => {
  const paths = usePaths()

  useEffect(() => {
    if (isAuthenticated) {
      attachUserToSubmissionByTokens()
    }
  }, [isAuthenticated])

  if (!isAuthenticated && !isAuthenticating) {
    return <Redirect to={paths.account.signIn} />
  }
  if (!isAuthenticated && isAuthenticating) {
    return <p>LOading...</p>
  }
  return children
}

const mapState = state => ({
  isAuthenticated: AccountSelectors.getIsAuthenticated(state) && !!yourProfileSelectors.getUserId(state),
  isAuthenticating: AccountSelectors.getIsLoading(state)
})

const AuthGuard = connect(mapState)(Guard)

export default AuthGuard
