import { Avatar, Box, Flex, Paragraph, Text } from "@engaging-tech/components"
import { formatDistanceToNow } from "date-fns"
import React from "react"
import styled from "styled-components"

import Replies from "../Replies/Replies"
import ReplyLink from "../Replies/ReplyLink"

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const toTimeDifference = dateString =>
  formatDistanceToNow(new Date(dateString || Date.now()), { addSuffix: true })

const PostedAt = styled(Text)`
  flex-shrink: 0;
`

const BreakParagraph = styled(Paragraph)`
  word-break: break-word;
`

const Comment = ({ comment, isSelected, onReply, postId }) => (
  <Flex mb={3} width="100%">
    <Flex mr={2} width={40} height={40}>
      <Avatar
        picture={comment.anonymous ? profilePlaceholder : comment?.user?.picture?.small || profilePlaceholder}
        mr={3}
      />
    </Flex>

    <Flex flexDirection="column" width="calc(100% - 40px)">
      <Flex flexDirection={["column", "row"]} justifyContent="space-between" width="100%">
        <Text
          fontSize={[3, 4]}
          fontWeight="500"
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap"
          }}
        >
          {comment.anonymous
            ? "Posted Anonymously"
            : `${comment?.user?.firstName} ${comment?.user?.lastName}`}
        </Text>
        <PostedAt color="dark.2" mt={[1, 0]} fontSize={2} ml={[0, 3]} width="auto">
          {toTimeDifference(comment?.publishedAt)}
        </PostedAt>
      </Flex>
      <Flex flexDirection={["column", "row"]} justifyContent="space-between" mt={[2, 1]} width="100%">
        <Box>
          {comment.content.map(paragraph => (
            <BreakParagraph
              mb={[3, 0]}
              letterSpacing="0.02rem"
              fontSize={3}
              color="dark.2"
              key={paragraph.body}
            >
              {paragraph.body}
            </BreakParagraph>
          ))}
        </Box>
        <ReplyLink onClick={onReply} />
      </Flex>
      <Replies
        replies={comment.childs}
        isFormOpen={isSelected}
        onClose={onReply}
        postId={postId}
        commentId={comment.id}
      />
    </Flex>
  </Flex>
)

export default Comment
