import React, { useRef, useEffect } from "react"
import { Flex, Text } from "@engaging-tech/components"
import styled from "styled-components"
import { useField } from "formik"
import useTimedReRender from "../../../hooks/useTimedReRender"
import useWindowSize from "../../../hooks/useWindowSize"

import InputLoadError from "../InputLoadError"

import SingleKnobSliderTrack from "./components/SingleKnobSliderTrack"
import SliderNumberField from "./components/SliderNumberField"
import SliderKnob from "./components/SliderKnob"

const Holder = styled(Flex)`
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`

// We calculate the value of the knob on the track.
const getValueOfKnob = (knob, trackWidth, max, increment) => {
  const value = parseInt((max / (trackWidth / knob)).toFixed(0), 10)
  return Math.ceil(value / increment) * increment
}

// Places the knob on the track as a percentage
const getPixelValue = (value, max, trackWidth) => {
  return (value / max) * trackWidth
}

const updateKnob1ValueInterceptor = (
  xValue1,
  constraintsRef,
  trackWidth,
  helpers1,
  max,
  increment
) => {
  const limitedValueCalculator = value => {
    if (value - constraintsRef.current.offsetLeft < 0) {
      return 0
    }
    if (value - constraintsRef.current.offsetLeft > trackWidth) {
      return trackWidth
    }
    return value - constraintsRef.current.offsetLeft
  }
  const limitedValue = limitedValueCalculator(xValue1)

  helpers1.setValue(
    Number(getValueOfKnob(limitedValue, trackWidth, max, increment))
  )
}

const calculateSliderKnobPosition = (sliderValue, trackWidth, max) => {
  const v = getPixelValue(sliderValue, max, trackWidth)
  if (v >= trackWidth) {
    return trackWidth
  }
  if (v <= 0) {
    return 0
  }
  return v
}

const SingleHandleValueSlider = ({
  field1,
  meta1,
  helpers1,
  label,
  max,
  increment,
  trackHeight,
  knobSize,
  symbol,
  defaultValue,
  sliderContainerRef
}) => {
  // A constraintsRef to use set on the track and disallow the knobs to move further than the knob
  const constraintsRef = useRef(null)
  // Causes component re-render on window resize
  useWindowSize()

  const invalidValue = field1.value
    ? field1.value > max || field1.value < 0
    : true

  useEffect(() => {
    if (invalidValue) {
      helpers1.setValue(
        typeof defaultValue === "number" ? defaultValue : max / 2
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useTimedReRender(500)

  if (!sliderContainerRef?.current) {
    return <></>
  }

  const trackWidth = sliderContainerRef.current.offsetWidth

  if (!("value" in field1)) {
    return <InputLoadError />
  }

  return (
    <Flex flexDirection="column" justifyContent="flex-start" mb={2} mt={2}>
      {label && <Text mb={2}>{label}</Text>}
      <Holder>
        <SingleKnobSliderTrack
          ref={constraintsRef}
          trackWidth={trackWidth}
          trackHeight={trackHeight}
          knob1Position={getPixelValue(field1.value, max, trackWidth)}
        >
          <SliderKnob
            trackHeight={trackHeight}
            knobSize={knobSize}
            drag="x"
            initial={false}
            animate={{
              x: calculateSliderKnobPosition(field1.value, trackWidth, max)
            }}
            transition={{ delay: 0.1 }}
            dragMomentum={false}
            onDragEnd={(event, info) => {
              updateKnob1ValueInterceptor(
                info.point.x,
                constraintsRef,
                trackWidth,
                helpers1,
                max,
                increment
              )
            }}
            onDrag={(event, info) => {
              updateKnob1ValueInterceptor(
                info.point.x,
                constraintsRef,
                trackWidth,
                helpers1,
                max,
                increment
              )
            }}
            dragConstraints={{ left: 0, right: trackWidth }}
            dragElastic={0}
          />
        </SingleKnobSliderTrack>
      </Holder>
      <Flex
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        mt={2}
      >
        {symbol && (
          <Text
            width={10}
            mr="4px"
            fontSize={5}
            fontWeight={700}
            color={meta1.error ? "error.0" : "primary.0"}
            height={20}
          >
            {symbol}
          </Text>
        )}
        <SliderNumberField
          width={1 / 1}
          id={field1.name}
          name={field1.name}
          type="number"
          onChange={field1.onChange}
          onBlur={field1.onBlur}
          value={field1.value}
          color={meta1.error ? "error.0" : "primary.0"}
          error={meta1.error && meta1.touched}
        />
      </Flex>
      {meta1.error && meta1.touched && (
        <Text ml={2} mt={2} fontSize={2} color="error.0">
          {meta1.error}
        </Text>
      )}
    </Flex>
  )
}

const SingleHandleValueSliderHandler = ({
  label,
  name1,
  max,
  increment,
  trackHeight,
  knobSize,
  symbol,
  defaultValue,
  ...props
}) => {
  const [field1, meta1, helpers1] = useField({ name: name1, type: "number" })

  const sliderContainerRef = useRef()
  return (
    <Flex ref={sliderContainerRef} {...props}>
      <SingleHandleValueSlider
        field1={field1}
        meta1={meta1}
        helpers1={helpers1}
        label={label}
        max={max}
        increment={increment}
        trackHeight={trackHeight}
        knobSize={knobSize}
        symbol={symbol}
        defaultValue={defaultValue}
        sliderContainerRef={sliderContainerRef}
      />
    </Flex>
  )
}

export default SingleHandleValueSliderHandler
