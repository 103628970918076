import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Text, Flex, Box, Button, Chip } from "@engaging-tech/components"
import { hideVisually } from "polished"

const MIME_TYPES = {
  audio: "audio/mpeg3",
  image: "image/jpeg,image/png",
  document: "application/pdf",
  video: "video/mp4"
}

const HiddenInput = styled.input.attrs({ type: "file" })`
  ${hideVisually()}
`

const Wrapper = styled(Flex)`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`

const FilePicker = ({ stateFns, type }) => {
  const [file, setFile] = stateFns
  const id = `upload-${type}`

  return file ? (
    <Chip
      mr={[3, 3, 2]}
      isActive
      trailingIcon="close"
      onClick={() => setFile(null)}
    >
      {file.name}
    </Chip>
  ) : (
    <Flex as="label" htmlFor={id} onChange={e => setFile(e.target.files[0])}>
      <HiddenInput id={id} accept={MIME_TYPES[type]} />
      <Button elevation={0} variant="text.primary.0" mr={[3, 3, 2]} as="div">
        Add {type}
      </Button>
    </Flex>
  )
}

const UploadContent = ({ onSelect, type, helperTexts, hideHeader, error }) => {
  const [content, setContent] = useState(null)
  const [coverImage, setCoverImage] = useState(null)

  useEffect(() => {
    onSelect([content, coverImage].filter(attachment => !!attachment))
  }, [content, coverImage])

  return (
    <Box mb={[4, 3]}>
      {!hideHeader && (
        <Text mb={3} display="block" fontSize={3} fontWeight="500">
          Content
        </Text>
      )}
      {helperTexts &&
        helperTexts.length &&
        helperTexts.map(helperText => (
          <Text
            key={helperText}
            mb={1}
            color="dark.2"
            fontWeight="500"
            display="block"
            fontSize={3}
          >
            {helperText}
          </Text>
        ))}
      <Wrapper width="auto">
        {type && <FilePicker stateFns={[content, setContent]} type={type} />}
        <FilePicker stateFns={[coverImage, setCoverImage]} type="image" />
      </Wrapper>
      <Text
        display="block"
        mt={2}
        pl={3}
        fontSize={2}
        color="error.0"
        height={15}
      >
        {error && error}
      </Text>
    </Box>
  )
}

export default UploadContent
