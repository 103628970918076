import { Button, Flex, Text } from "@engaging-tech/components"
import { AnimatePresence } from "framer-motion"
import React, { useState } from "react"
import { useSelector } from "react-redux"

import { businessProfilePageSelectors } from "../../.."
import PercentageGraphic from "../../../../ui/components/PercentageGraphic"
import Explanation from "./common/explanation"
import WidgetContainer from "./widget-container"

export default function SixSteps() {
  const sixSteps = useSelector(businessProfilePageSelectors.getSixSteps)

  const [showExplanation, setShowExplanation] = useState(false)

  return (
    <WidgetContainer mb={4} width="100%" title="Workplace Happiness Scores">
      <Flex flexWrap="wrap" mt={4} mb={4}>
        {sixSteps?.map((step, i, arr) => {
          return (
            <Flex
              flexDirection="column"
              flexBasis={[1 / 2, 1 / 3]}
              key={step.name}
              alignItems="center"
              mb={[i === arr.length - 1 || i === arr.length - 2 ? 0 : 5, i < arr.length / 2 ? 5 : 0]}
            >
              <Text textAlign="center" height={44} maxWidth={[170, "100%"]}>
                {step.name}
              </Text>
              <PercentageGraphic
                graphicWidth="60%"
                height="100%"
                score={step.score}
                noData={!step.score}
                noScoreLabel
                animate
                animationDelay={i}
              />
              <Button
                variant="primary.0"
                disabled={step && !step.score}
                onClick={() => setShowExplanation(step.name)}
              >
                Explanation
              </Button>
              <AnimatePresence>
                {showExplanation === step.name && (
                  <Explanation
                    setVisibility={() => setShowExplanation(false)}
                    score={step.score}
                    heading={step.name}
                    breakdown={step.breakdown}
                  />
                )}
              </AnimatePresence>
            </Flex>
          )
        })}
      </Flex>
    </WidgetContainer>
  )
}
