import React from "react"
import styled from "styled-components"
import { H1, Flex, Box, Text } from "@engaging-tech/components"
import SiteLogo from "./SiteLogo"

const Dialog = styled(Flex)`
  width: 90%;
  margin: 0 auto;
  margin-bottom: ${({ theme }) => theme.space[7]}px;
  margin-top: ${({ theme }) => theme.space[6]}px;
  justify-content: center;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[0]}px) {
    margin-top: ${({ theme }) => theme.space[6]}px;
    margin-bottom: ${({ theme }) => theme.space[6]}px;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[1]}px) {
    margin-top: ${({ theme }) => theme.space[5]}px;
    margin-bottom: ${({ theme }) => theme.space[5]}px;
    max-width: ${({ isTwoColumnForm }) => (isTwoColumnForm ? 1060 : 560)}px;
  }
`

/**
 * Primative dialog for account-related UI. This is used with all of the account
 * forms. (sign in, sign up, etc.)
 * @param {React.Component} renderLogo A brand logo to render at the top of the dialog.
 * @param {string} title The title text for the dialog.
 * @param {string} subtitle Optional subtitle text for the dialog.
 * @param {React.Component} children Child components to be rendered as the body of the dialog.
 * @package
 */
const AccountDialog = ({
  title,
  subtitle,
  isTwoColumnForm = false,
  children
}) => (
  <Dialog
    bg="light.0"
    borderRadius={4}
    elevation={12}
    isTwoColumnForm={isTwoColumnForm}
  >
    <Flex flexDirection="column" alignItems="center" width="100%">
      <Flex width="auto" mt="48px" mb={2}>
        <SiteLogo />
      </Flex>

      <Box px={[4, 5]} py={3} width="100%">
        <H1
          fontSize={5}
          color="primary.0"
          fontWeight="700"
          mb={subtitle ? 2 : 3}
        >
          {title}
        </H1>
        {subtitle && (
          <Box mb={3}>
            <Text color="dark.2" fontSize={[4, 3]}>
              {subtitle}
            </Text>
          </Box>
        )}
        {children}
      </Box>
    </Flex>
  </Dialog>
)

export default AccountDialog
