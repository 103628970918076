import { Button, CustomHooks, Flex } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React from "react"

import AccountDialog from "../../../ui/components/AccountDialog"
import MyInfomationSubForm from "../MyInfomationSubForm"

export default function SignUpFormContent({
  handleSubmit,
  errors,
  touched,
  values,
  isValid,
  handleChange,
  handleBlur,
  isLoading,
  cancelRoute,
  setFieldValue,
  setFieldTouched
}) {
  const device = CustomHooks.useClientDevice()

  return (
    <AccountDialog title="My Information">
      <form onSubmit={handleSubmit}>
        <MyInfomationSubForm
          device={device}
          errors={errors}
          touched={touched}
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        />

        <Flex width={1 / 1} flexDirection="row" justifyContent="space-between" alignItems="center">
          <Link to={cancelRoute} external>
            <Button variant="text.noBg.primary.0" type="button">
              Cancel
            </Button>
          </Link>
          <Button variant="primary.0" type="submit" disabled={!isValid || isLoading} ml={2} width={100}>
            SIGN UP
          </Button>
        </Flex>
      </form>
    </AccountDialog>
  )
}
