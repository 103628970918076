const featuredBookEans = {
  businessBooks: [
    "9781471146732",
    "9781612680507",
    "9781781253397",
    "9781984842305"
  ],
  popularReleases: [
    "9781788451680",
    "9781788451659",
    "9781472972224",
    "9781786896070",
    "9781847942388"
  ],
  recommendedBooks: [
    "9781472956255",
    "9781910200780",
    "9781910989920",
    "9780241341247",
    "9781473613775"
  ]
}

export default featuredBookEans
