import { Icon, Text, Button, Flex } from "@engaging-tech/components"
import React, { useState } from "react"

const SiteBanner = ({
  text,
  children,
  disableClose,
  icon,
  initialVisibility = true,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(initialVisibility)

  return (
    isVisible && (
      <Flex
        px={[3, 3, 4]}
        py={3}
        justifyContent="space-between"
        alignItems="center"
        width={1 / 1}
        bg="tertiary.0"
        height={["60px", "52px"]}
        {...props}
      >
        <Flex>
          {icon && <Icon name={icon} color="dark.1" />}
          {text && <Text ml={icon ? 3 : 0}>{text}</Text>}
          {children}
        </Flex>

        {!disableClose && (
          <Button
            variant="text.dark.2"
            elevation="0"
            width="auto"
            onClick={() => setIsVisible(false)}
            css={`
              margin-right: -${({ theme }) => theme.space[3]}px;
            `}
          >
            <Icon name="close" color="dark.1" />
          </Button>
        )}
      </Flex>
    )
  )
}

export default SiteBanner
