import { Box } from "@engaging-tech/components"
import styled from "styled-components"

import { FilterDropdownIcon as FilterDropdownBaseIcon } from "../../../assets/icons"

export const DemographicFilterWrapper = styled(Box)`
  max-width: 400px;
  width: 100%;

  @media (min-width: 768px) {
    max-width: fit-content;
  }
`

export const DemographicFilterButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  width: 100%;
  height: 32px;

  background-color: #003e4c;

  cursor: pointer;

  border: 0px;
  border-radius: 5px;

  font-weight: 700;
  font-size: 14px;
  line-height: 18.2px;
  color: ${({ selected }) => (selected ? "#00e96e" : "#FFFFFF")};

  & path {
    fill: ${({ selected }) => (selected ? "#00e96e" : "#FFFFFF")};
  }
`

export const FilterDropdownIcon = styled(FilterDropdownBaseIcon)`
  margin-right: 12px;
  margin-left: 7px;
`
