import { Flex, Paragraph, Box, Button, Icon } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React from "react"
import styled, { css } from "styled-components"

const UnderLinedLink = styled(Link)`
  text-decoration: underline;
  font-size: ${({ theme }) => theme.fontSizes[3]}px;
`

const UnderLinedButton = styled(Button)`
  text-transform: none;
  padding: 0;
  margin: 0;
  background: none;
  box-shadow: none;
  outline: none;
  height: auto;
  width: auto;

  text-decoration: underline;
`

const BorderedBox = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.dark[4]};
  padding: ${({ theme }) => theme.space[4]}px;

  ${({ isPremium }) =>
    isPremium &&
    css`
      &:last-child {
        border-top: none;
      }
    `}
`

const PremiumSettings = ({ setVisibility, isPremium }) => (
  <Flex flexDirection="column">
    <Paragraph
      fontWeight={700}
      fontSize={5}
      color="primary.0"
      textAlign="center"
    >
      Manage Your Premium Account
    </Paragraph>
    <BorderedBox>
      <Paragraph>Account Type</Paragraph>

      {isPremium ? (
        <>
          <Paragraph color="primary.0" fontWeight={700} fontSize={[4, 5]}>
            WorkL Premium
          </Paragraph>
          <Flex alignItems="center">
            <UnderLinedLink to="/premium">
              Learn about Premium Membership
            </UnderLinedLink>
            <Icon name="navigate_next" />
          </Flex>
          <Flex alignItems="center">
            <UnderLinedButton
              fontSize={3}
              onClick={() => setVisibility("subscription")}
            >
              Manage premium subscription
            </UnderLinedButton>
            <Icon name="navigate_next" />
          </Flex>
        </>
      ) : (
        <>
          <Paragraph color="primary.0" fontWeight={700} fontSize={[4, 5]}>
            WorkL Standard
          </Paragraph>
          <Flex alignItems="center">
            <UnderLinedLink to="/premium">
              Learn about Premium Membership
            </UnderLinedLink>
            <Icon name="navigate_next" />
          </Flex>
        </>
      )}
    </BorderedBox>
  </Flex>
)

export default PremiumSettings
