import React from "react"
import MentorMatcherView from "../../features/mentorMatcher/views/MentorMatcher"
import DetailsLayout from "../../layouts/DetailsLayout"
import Loader from "../../features/mentorMatcher/containers/Loader"

const MentorMatcher = () => {
  return (
    <DetailsLayout
      actionName="CAREER DEVELOPER"
      backTo="/develop-career"
      bg="light.0"
    >
      <Loader />
      <MentorMatcherView />
    </DetailsLayout>
  )
}

export default MentorMatcher
