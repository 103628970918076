import { Flex, MotionFlex } from "@engaging-tech/components"
import React from "react"
import { useSelector } from "react-redux"

import { getProgressState } from "../../store/jobFinder.selectors"

const variants = {
  unActive: {
    width: "0%",
    transition: { duration: 0 }
  },
  loading: {
    width: "90%",
    transition: { ease: "easeInOut", duration: 2 }
  },
  loaded: {
    width: "100%",
    transition: { ease: "easeInOut" }
  }
}

export default function ProgressBar() {
  const state = useSelector(getProgressState)

  return (
    <Flex width={1 / 1} height="6px" bg="dark.7">
      <MotionFlex variants={variants} initial="unActive" animate={state} height="6px" bg="primary.0" />
    </Flex>
  )
}
