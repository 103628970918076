import {
  Box,
  Button,
  Checkbox,
  DatePicker,
  Flex,
  Icon,
  LocationAutocomplete,
  Text,
  TextArea,
  TextField
} from "@engaging-tech/components"
import { getCountries } from "@engaging-tech/frontend-utils"
import { getConfig } from "@engaging-tech/ssr-config"
import { format, parse } from "date-fns"
import { Formik } from "formik"
import React, { Fragment, useEffect, useState } from "react"
import styled from "styled-components"
import * as Yup from "yup"

import { deleteTypeNames } from "../../../utils"
import ProfileSection from "./ProfileSection"

const BorderedBox = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[3]};
  margin-top: 10px;
  margin-bottom: 10px;
`

const FormWrapper = styled.form`
  width: 100%;
`
const EditIcon = styled(Icon)`
  cursor: pointer;
`

const Form = ({
  handleSubmit,
  values,
  errors,
  handleChange,
  handleBlur,
  isValid,
  setFieldValue,
  setFieldTouched
}) => {
  const [currentWork, setCurrentWork] = useState(false)
  const [countries, setCountries] = useState([])

  useEffect(() => {
    setCountries(getCountries())
  }, [])

  const handleLocationChange = e => {
    if (e && e.geometry) {
      setFieldValue("location", {
        countryId: countries.filter(
          country =>
            country.code ===
            e.address_components.filter(component => component.types.includes("country"))[0].short_name
        )[0].id,
        name: e.formatted_address,
        point: {
          coordinates: [e.geometry.location.lat(), e.geometry.location.lng()]
        },
        country: {
          shortName: e.address_components.filter(country => country.types.includes("country", "political"))[0]
            .short_name,

          name: e.address_components.filter(country => country.types.includes("country", "political"))[0]
            .long_name
        },
        vicinity: e.vicinity
      })
    }
  }

  return (
    <FormWrapper>
      <Flex mt={3} flexDirection="column" alignItems="stretch">
        <TextField
          width={1 / 1}
          color="primary.0"
          placeholder="Course Title"
          error={errors.courseTitle}
          value={values?.courseTitle ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
          id="courseTitle"
        />
        <TextField
          width={1 / 1}
          color="primary.0"
          placeholder="Institution"
          error={errors.institution}
          value={values?.institution ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
          id="institution"
        />
        <LocationAutocomplete
          id="location.name"
          name="location.name"
          type="text"
          label=""
          placeholder="Location"
          value={values.location.name || ""}
          googleApiKey={getConfig().keys.googleApiKey}
          width={1 / 1}
          selectedLocationCallback={handleLocationChange}
          onFocus={() => setFieldTouched("location.name")}
          error={errors.location && errors.location.name}
          onKeyDown={e => {
            if (e.key === "Backspace") {
              setFieldValue("location", {
                countryId: null,
                name: "",
                coordinates: { lat: 0, lng: 0 },
                country: {
                  name: "",
                  shortName: ""
                }
              })
            }
          }}
        />
        <Flex
          width={1 / 1}
          flexDirection={["column", "column", "row"]}
          alignItems="center"
          justifyContent="space-between"
        >
          <DatePicker
            mr={2}
            name="start"
            width={1 / 1}
            type="date"
            format="dd/MM/yyyy"
            color="primary.0"
            label="Start Date"
            error={errors.start}
            value={values?.start ?? ""}
            onBlur={e => setFieldValue("start", e.target.value)}
            id="start"
          />
          {!currentWork && (
            <DatePicker
              width={1 / 1}
              name="end"
              color="primary.0"
              type="date"
              format="dd/MM/yyyy"
              label="End Date"
              error={errors.end}
              value={values?.end ?? ""}
              onBlur={e => setFieldValue("end", e.target.value)}
              id="end"
              dayPickerInputProps={{
                modifiers: {
                  disabled: [
                    {
                      before: values.startDate || null
                    }
                  ]
                }
              }}
            />
          )}
        </Flex>
        <Checkbox
          id="profile-education-current-work"
          width={1 / 1}
          mb={2}
          checked={currentWork}
          onChange={() => setCurrentWork(!currentWork)}
          label={() => <Text>I currently study here</Text>}
        />
        <TextArea
          width={1 / 1}
          color="primary.0"
          mt={2}
          placeholder="What skills did you develop during your studies"
          maxLength="200"
          error={errors.description}
          value={values?.description ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
          height={150}
          id="description"
        />
        <Button
          mt={2}
          elevation={0}
          variant="outline.primary.0"
          width="120px"
          disabled={!isValid}
          type="button"
          onClick={e => {
            e.preventDefault()

            handleSubmit()
          }}
        >
          SAVE
        </Button>
      </Flex>
    </FormWrapper>
  )
}

const FormShowing = ({ toggle, qualifications, onUpdate }) => {
  const handleSubmit = async values => {
    const startDate = parse(values.start, "dd/MM/yyyy", new Date())
    const startDateUs = format(startDate, "MM/dd/yyyy")

    let endDate = ""
    let endDateUs = null

    if (values?.end) {
      endDate = parse(values.end, "dd/MM/yyyy", new Date())
      endDateUs = format(endDate, "MM/dd/yyyy")
    }

    const valuesUpdated = { ...values, start: startDateUs, end: endDateUs }

    if (qualifications && qualifications?.length) {
      const noTypeName = qualifications.map(pos => {
        const obj = {
          ...pos,
          location: {
            countryId: pos.location.countryId,
            name: pos.location.name,
            point: pos.location.point,
            country: {
              shortName: values.location.country.shortName,
              name: values.location.country.name
            }
          }
        }
        // eslint-disable-next-line no-underscore-dangle
        delete obj.__typename

        return deleteTypeNames(obj)
      })

      onUpdate({ qualifications: [...noTypeName, valuesUpdated] })
    } else {
      onUpdate({ qualifications: [valuesUpdated] })
    }

    toggle()
  }

  const validationSchema = Yup.object().shape({
    description: Yup.string().max(200, "Must be less than or equal to 200 characters"),
    location: Yup.object().shape({
      name: Yup.string().required("Required")
    }),
    institution: Yup.string().max(50, "Must be less than or equal to 50 characters").required("Required"),
    courseTitle: Yup.string().max(50, "Must be less than or equal to 50 characters").required("Required"),
    start: Yup.string().required("Required"),
    end: Yup.string()
  })

  const initialValues = {
    description: "",
    location: {
      countryId: "",
      name: "",
      coordinates: {
        lat: "",
        lng: ""
      }
    },
    institution: "",
    courseTitle: "",
    start: "",
    end: ""
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
    >
      {props => <Form {...props} />}
    </Formik>
  )
}

const DataShowing = ({ qualifications, onUpdate }) => {
  const removeQualification = education => {
    const filteredQualifications = qualifications
      .filter(qual => {
        return !(qual.courseTitle === education.courseTitle && qual.description === education.description)
      })
      .map(qual => {
        deleteTypeNames(qual)
        const qualUpdated = {
          ...qual,
          location: {
            countryId: qual.location.country.id,
            name: qual.location.name,
            coordinates: qual.location.coordinates
          }
        }
        // eslint-disable-next-line no-underscore-dangle
        delete qualUpdated?.__typename
        return qualUpdated
      })

    onUpdate({ qualifications: filteredQualifications })
  }

  return qualifications?.length > 0 ? (
    <Flex flexDirection="column" mt={2}>
      {qualifications.map((education, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          <Flex flexDirection={["column", "row"]} justifyContent="space-between" mt={2}>
            <>
              <Text fontSize={3} fontWeight={600} color="dark.2" mt={[3, 0]} mb={1} mr={2}>
                {education.courseTitle}
              </Text>
              <Text fontSize={3} color="dark.2" mb={1} mr={2}>
                {education.institution}
              </Text>
              <Text fontSize={3} color="dark.2" mb={1} mr={2}>
                {format(new Date(education.start), "dd MMM yyyy")} -{" "}
                {education.end ? format(new Date(education.end), "dd MMM yyyy") : "present"}{" "}
              </Text>
              <Text fontSize={3} color="dark.2" mb={[2, 1]} mr={2}>
                {education.location.name}
              </Text>
              <Text fontSize={3} color="dark.2" mb={[3, 1]}>
                {education.description}
              </Text>
            </>
            <EditIcon name="delete" onClick={() => removeQualification(education)} />
          </Flex>
          {index !== qualifications.length - 1 && <BorderedBox />}
        </Fragment>
      ))}
    </Flex>
  ) : (
    <Text mt={2}>You have not added any qualifications yet</Text>
  )
}

const Education = ({ qualifications, onUpdate, toggle, hideCancelButton = false }) => {
  const [dataShowing, setDataShowing] = useState(true)

  return (
    <ProfileSection
      title="Education"
      icon="gradhat"
      icon2="add_circle"
      onClick={() => setDataShowing(!dataShowing)}
      toggle={toggle}
      dataShowing={dataShowing}
      hideCancelButton={hideCancelButton}
    >
      {dataShowing ? (
        <DataShowing qualifications={qualifications} onUpdate={onUpdate} />
      ) : (
        <FormShowing
          toggle={toggle || (() => setDataShowing(true))}
          qualifications={qualifications}
          onUpdate={onUpdate}
        />
      )}
    </ProfileSection>
  )
}

export default Education
