import { getConfig } from "@engaging-tech/ssr-config"

import { apollo } from "../../../lib/Apollo"
import {
  CREATE_EVENT_ALERT,
  CREATE_JOB_ALERT,
  CREATE_MATERIAL_ALERT,
  DELETE_ALERT,
  LOAD,
  UPDATE_EVENT_ALERT,
  UPDATE_JOB_ALERT,
  UPDATE_MATERIAL_ALERT
} from "./notifications.queries"

const GRAPHQL_API_URL = () => `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

export const loadAlerts = () =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD
  })

export const createJobAlert = ({ filters, frequency }) =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: CREATE_JOB_ALERT,
    variables: {
      params: {
        frequency,
        jobRole: filters.jobRole,
        salary: filters.salary,
        industry: filters.industry,
        location: filters.location,
        jobType: filters.jobType
      }
    }
  })

export const updateJobAlert = jobAlert =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: UPDATE_JOB_ALERT,
    variables: { jobAlert }
  })

export const createEventAlert = eventAlert =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: CREATE_EVENT_ALERT,
    variables: { eventAlert }
  })

export const updateEventAlert = eventAlert =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: UPDATE_EVENT_ALERT,
    variables: { eventAlert }
  })

export const createMaterialAlert = materialAlert =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: CREATE_MATERIAL_ALERT,
    variables: { materialAlert }
  })

export const updateMaterialAlert = materialAlert =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: UPDATE_MATERIAL_ALERT,
    variables: { materialAlert }
  })

export const deleteAlert = alertId =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: DELETE_ALERT,
    variables: { alertId }
  })
