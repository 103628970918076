const businessFeatures = [
  {
    title: "Retain",
    icon: "accessibility",
    points: [
      {
        heading: "Workplace happiness survey",
        description:
          "Helping you identify where your business can build greater workplace engagement.",
        icon: "assignment"
      },
      {
        heading: "Consulting and training",
        description:
          "We can help you to create a happiness driven company culture.",
        icon: "supervised_user_circle"
      }
    ]
  },
  {
    title: "Recruit",
    icon: "group_add",
    points: [
      {
        heading: "Job advertising",
        description: "We offer a range of options to recruit new employees.",
        icon: "shopping-bags"
      },
      {
        heading: "Premium service",
        description:
          "Post targeted job ads, business events, receive notifications when expert advice requested.",
        icon: "stars"
      }
    ]
  },
  {
    title: "Communicate",
    icon: "forum",
    points: [
      {
        heading: "Workplace messenger",
        description:
          "We offer an enterprise version to meet business requirements.",
        icon: "message"
      },
      {
        heading: "Conference apps",
        description:
          "Designed to your needs, and can be used for conferences and meetings.",
        icon: "event"
      }
    ]
  }
]

export default businessFeatures
