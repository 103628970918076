import { Flex, H1, MotionFlex, Text } from "@engaging-tech/components"
import { BillingForm, StripeWrapper } from "@engaging-tech/payments"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"
import subscriptionDetails from "../lib/subscriptionDetails"

const stripeLogo = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/payment-options/stripe-logo.png`)

const STRIPE_KEY = () => getConfig().keys.stripe

const SubscriptionForm = ({
  paymentIntent,
  onSubmit,
  startingDate,
  onSuccess,
  onFail,
  total,
  onStripeLoadingUpdate,
  cancel
}) => {
  return (
    <MotionFlex
      animate={{ opacity: 1, transition: { delay: 0.1, duration: 0.2 } }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      width="100%"
      height="100%"
      flexDirection="column"
    >
      <Flex justifyContent="space-between" width="100%">
        <H1 fontSize={5} color="primary.0" fontWeight={700} mb={3}>
          Pay With Card
        </H1>
        <img src={stripeLogo} height={30} alt="powered with stripe" />
      </Flex>

      <Text fontSize="14px" width="100%">
        Your free trial will last until{" "}
        <Text fontSize="14px" fontWeight="700">
          {startingDate}
        </Text>
      </Text>
      <Text mt="16px" fontSize="14px" width="100%">
        {`You will pay a £${subscriptionDetails.premiumPrice} monthly subscription after that includes VAT`}
      </Text>
      <Text mt="16px" fontSize="14px" width="100%" mb={3}>
        You can cancel your subscription at anytime
      </Text>

      <StripeWrapper apiKey={STRIPE_KEY()}>
        <BillingForm
          onStripeLoadingUpdate={onStripeLoadingUpdate}
          paymentAction={paymentIntent}
          onSubmit={{
            action: source => onSubmit(source),
            text: "Complete Purchase"
          }}
          onCancel={{
            action: cancel,
            text: "Cancel"
          }}
          onPaymentActionSuccess={{
            action: onSuccess,
            text: "Success Payment Action"
          }}
          onPaymentActionFail={{
            action: onFail,
            text: "Failed Payment Action"
          }}
        >
          <Flex width="100%" justifyContent="flex-end">
            <Flex flexDirection="column" width="auto" alignItems="flex-end">
              <Text
                mb={2}
                fontWeight={700}
                fontSize="16px"
              >{`Total: £${total}`}</Text>
              <Text fontSize="12px">
                You will be charged:{" "}
                <Text fontSize="12px" fontWeight={700}>
                  £{subscriptionDetails.premiumPrice}
                </Text>
              </Text>
              <Text fontSize="12px">
                monthly from{" "}
                <Text fontSize="12px" fontWeight={700}>
                  {startingDate}
                </Text>
              </Text>
            </Flex>
          </Flex>
        </BillingForm>
      </StripeWrapper>
    </MotionFlex>
  )
}

export default SubscriptionForm
