import React, { useState } from "react"
import styled from "styled-components"
import { CardElement } from "react-stripe-elements"
import { Box, Text } from "@engaging-tech/components"

const PlaceholderInput = styled.input`
  font-size: 16px;
  border: none;
  background-color: transparent;
  width: 100%;
  padding-bottom: 8px;
`

const CardInputBox = styled(Box)`
  position: relative;
  margin-top: 8px;
  background-color: ${({ theme }) => theme.colors.dark[7]};

  border-bottom: ${({ theme, isTouched, hasError }) => {
    if (hasError) {
      return `2px solid ${theme.colors.error[0]}`
    }
    if (isTouched) {
      return `2px solid ${theme.colors.primary[0]}`
    }
    return `1px solid ${theme.colors.dark[3]}`
  }};

  padding: ${({ isTouched }) =>
    isTouched ? "27px 16px 6px" : "20px 16px 6px"};
  border-radius: 4px 4px 0 0;
  transition: 0.15s;

  :hover {
    background-color: ${({ theme }) => theme.colors.dark[6]};
    border-bottom: ${({ theme, isTouched }) =>
      isTouched
        ? `2px solid ${theme.colors.primary[0]}`
        : `1px solid ${theme.colors.dark[1]}`};
  }

  label {
    color: ${({ theme, isTouched, hasError }) => {
      if (hasError) {
        return theme.colors.error[0]
      }
      if (isTouched) {
        return theme.colors.primary[0]
      }
      return theme.colors.dark[2]
    }};

    font-family: ${({ theme }) => theme.fonts.sansSerif};
    left: 16px;
    right: auto;
    top: 18px;
    pointer-events: none;
    position: absolute;
    line-height: 1.15rem;
    transition: 0.15s;
    transform: ${({ isTouched }) =>
      isTouched ? "translateY(-50%) translateX(-15%) scale(0.75)" : "none"};
  }
`

const style = {
  style: {
    base: {
      fontSize: "16px",
      color: "rgba(0,0,0,0.87)",
      "::placeholder": {
        color: "rgba(0,0,0,0.6)"
      }
    },
    invalid: {
      color: "rgba(191,70,48,1)"
    }
  }
}

/* eslint-disable jsx-a11y/label-has-associated-control */
const CardDetails = ({ onChange }) => {
  const [isTouched, setIsTouched] = useState(false)
  const [error, setError] = useState(null)

  const handleChange = e => {
    setError(e.error)
    onChange(e)
  }

  return (
    <Box onClick={() => setIsTouched(true)} onFocus={() => setIsTouched(true)}>
      <CardInputBox isTouched={isTouched} hasError={!!error}>
        <label>Card details</label>
        {isTouched ? (
          <CardElement
            {...style}
            hidePostalCode
            onChange={handleChange}
            onReady={el => el.focus()}
          />
        ) : (
          <PlaceholderInput type="text" />
        )}
      </CardInputBox>
      <Box>
        <Text fontSize={2} pl={3} color="error.0">
          {error && error.message}
        </Text>
      </Box>
    </Box>
  )
}

export default CardDetails
