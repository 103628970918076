import { Button, Flex, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React, { useState } from "react"
import * as Yup from "yup"

import CVFormModal from "../../../../pages/DevelopCareer/cvFormModal"
import MarkPriceQuote from "./MarkPriceQuote"
import PositionsHeld from "./PositionsHeld"

const icon = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/create.png`)

const info = {
  icon,
  title: "Create a CV",
  submitData: false
}

const FooterOverride = ({ cancelPath, handleBack, handleNext, formik }) => {
  return (
    <Flex width={1 / 1} justifyContent="space-between" alignItems="center" alignContent="center" mt={4}>
      <Link to={cancelPath}>
        <Button elevation={0} bg="transparent">
          <Text fontWeight={700} color="primary.0" fontSize={3}>
            Cancel
          </Text>
        </Button>
      </Link>
      <Flex justifyContent="flex-end" alignItems="center" alignContent="center">
        <Button
          elevation={0}
          width={100}
          bg="transparent"
          onClick={() => handleBack({ values: formik.values })}
        >
          <Text fontWeight={700} color="primary.0" fontSize={3}>
            Back
          </Text>
        </Button>
        <Button
          elevation={0}
          bg="secondary.0"
          width={100}
          onClick={() => handleNext({ values: formik.values })}
        >
          <Text fontWeight={700} color="primary.0" fontSize={3}>
            Next
          </Text>
        </Button>
      </Flex>
    </Flex>
  )
}

const validationSchema = Yup.object({
  positionToAdd: Yup.object({
    jobTitle: Yup.string().required("This field cannot be left blank"),
    company: Yup.string().required("This field cannot be left blank"),
    location: Yup.object({
      name: Yup.string()
    }).nullable(),
    endDate: Yup.date().nullable(),
    startDate: Yup.date().required("This field must be a date"),
    description: Yup.string()
      .required("This field cannot be left blank")
      .max(600, "Must be less than or equal to 600 characters")
  }).nullable()
})

const WorkExperience = ({ formik, handleTouchAllInputs }) => {
  const { values } = formik
  const { positionsHeld } = values
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Flex width={1 / 1} flexDirection="column">
      <MarkPriceQuote quote="Make it relevant - include specific skills and experience that will be required in the role you’re applying for." />
      <Text my={3} fontWeight={700}>
        Work Experience
      </Text>

      {positionsHeld && <PositionsHeld formik={formik} onEdit={() => setIsOpen(true)} />}

      <Button
        variant="outline.primary.0"
        my={3}
        leadingIcon="add"
        elevation={0}
        type="button"
        onClick={() => {
          setIsOpen(true)
        }}
      >
        <Text fontWeight={700}>Add Position</Text>
      </Button>

      {isOpen && (
        <CVFormModal formik={formik} handleTouchAllInputs={handleTouchAllInputs} setIsOpen={setIsOpen} />
      )}
    </Flex>
  )
}

export default {
  component: WorkExperience,
  validationSchema,
  info,
  FooterOverride
}
