import { Card, Text } from "@engaging-tech/components"
import React from "react"

const TEXTS = {
  text1: "We couldn't find any jobs matching your search criteria."
}

const NoResults = () => (
  <Card bg="secondary.4" p={3} mb={3} flexDirection="column">
    <Text fontWeight="500" mb={2}>
      {TEXTS.text1}
    </Text>
  </Card>
)

export default NoResults
