import File from "./File"
import Image from "./Image"
import Video from "./Video"
import Thumbnail from "./Thumbnail"

export default {
  File,
  Image,
  Video,
  Thumbnail
}
