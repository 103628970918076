import React from "react"
import styled from "styled-components"
import { Utils, Flex } from "@engaging-tech/components"

const gradient = theme =>
  Utils.createGradient(
    {
      from: "secondary.0",
      to: "light.7",
      transition: "95%",
      direction: "180deg"
    },
    theme
  )

const Wrapper = styled(Flex)`
  ${({ theme }) => gradient(theme)};
`

const GradientWrapper = ({ children }) => (
  <Wrapper
    mt={[5, 3]}
    borderRadius={4}
    width={1 / 1}
    py={3}
    pr={[3, 4]}
    pl={[3, 0]}
    flexDirection={["column-reverse", "row"]}
  >
    {children}
  </Wrapper>
)

export default GradientWrapper
