import { connect } from "react-redux"
import { yourProfileSelectors } from "../../yourProfile"

import {
  getIsLoading,
  getOrder,
  getHasPaid
} from "../store/libraryBooks.selectors"
import { getIsPremium } from "../../yourProfile/store/yourProfile.selectors"

import { loadOrder, resetOrder } from "../store/libraryBooks.actions"

import SelectedOrder from "../components/Orders/SelectedOrder"

const mapState = state => ({
  isLoading: getIsLoading(state),
  order: getOrder(state),
  hasPaid: getHasPaid(state),
  userDetails: yourProfileSelectors.getProfile(state),
  isPremium: getIsPremium(state)
})

const mapDispatch = dispatch => ({
  onLoad: ({ id, userId }) => dispatch(loadOrder({ id, userId })),
  onLeave: () => dispatch(resetOrder())
})

const OrderDetails = connect(mapState, mapDispatch)(SelectedOrder)

export default OrderDetails
