export const types = {
  LOAD: "[Global Hub] Load",
  LOAD_SUCCESS: "[Global Hub] Load Success",
  LOAD_FAIL: "[Global Hub] Load Fail",
  LOAD_MORE: "[Global Hub] Load More",
  LOAD_MORE_SUCCESS: "[Global Hub] Load More Success",
  LOAD_MORE_FAIL: "[Global Hub] Load More Fail",
  POST: "[Global Hub] Post",
  POST_SUCCESS: "[Global Hub] Post Success",
  POST_FAIL: "[Global Hub] Post Fail",
  ENDORSE: "[Global Hub] Endorse",
  ENDORSE_SUCCESS: "[Global Hub] Endorse Success",
  UNENDORSE_SUCCESS: "[Global Hub] Unendorse Success",
  ENDORSE_FAIL: "[Global Hub] Endorse Fail",
  COMMENT: "[Global Hub] Comment",
  COMMENT_SUCCESS: "[Global Hub] Comment Success",
  COMMENT_FAIL: "[Global Hub] Comment Fail",
  REPLY: "[Global Hub] Reply",
  REPLY_SUCCESS: "[Global Hub] Reply Success",
  REPLY_FAIL: "[Global Hub] Reply Fail",
  SELECT: "[Global Hub] Select",
  SELECT_SUCCESS: "[Global Hub] Select Success",
  SELECT_FAIL: "[Global Hub] Select Fail",
  LOAD_USERS_ADVICE_POSTS: "[Global Hub] Load This Users Advice Posts",
  LOAD_USERS_ADVICE_POSTS_SUCCESS: "[Global Hub] Load This Users Advice Posts Success",
  LOAD_USERS_ADVICE_POSTS_FAIL: "[Global Hub] Load This Users Advice Posts Fail",
  REPORT_POST: "[Global Hub] Report Post",
  REPORT_POST_SUCCESS: "[Global Hub] Report Post Success",
  REPORT_POST_FAIL: "[Global Hub] Report Post Fail"
}

export const load = postType => ({
  type: types.LOAD,
  payload: postType
})

export const loadSuccess = posts => ({
  type: types.LOAD_SUCCESS,
  payload: posts
})

export const loadFail = () => ({
  type: types.LOAD_FAIL
})

export const loadMore = ({ postType, from, place }) => ({
  type: types.LOAD_MORE,
  payload: { postType, from, place }
})

export const loadMoreSuccess = posts => ({
  type: types.LOAD_MORE_SUCCESS,
  payload: posts
})

export const loadMoreFail = () => ({
  type: types.LOAD_MORE_FAIL
})

export const post = ({ data, place = "" }) => ({
  type: types.POST,
  payload: {
    data: { content: data.content, attachments: data.attachments, anonymous: data.anonymous },
    place
  }
})

export const postSuccess = newPost => ({
  type: types.POST_SUCCESS,
  payload: newPost
})

export const postFail = () => ({
  type: types.POST_FAIL
})

export const endorse = ({ id, isEndorsing, user }) => ({
  type: types.ENDORSE,
  payload: { id, isEndorsing, user }
})

export const endorseSuccess = ({ id, user }) => ({
  type: types.ENDORSE_SUCCESS,
  payload: { id, user }
})

export const unendorseSuccess = ({ id, user }) => ({
  type: types.UNENDORSE_SUCCESS,
  payload: { id, user }
})

export const endorseFail = () => ({
  type: types.ENDORSE_FAIL
})

export const comment = ({ postId, content, anonymous }) => ({
  type: types.COMMENT,
  payload: { postId, content, anonymous }
})

export const commentSuccess = ({ postId, id, content, anonymous, childs, user }) => ({
  type: types.COMMENT_SUCCESS,
  payload: { postId, id, content, anonymous, childs, user }
})

export const commentFail = () => ({
  type: types.COMMENT_FAIL
})

export const reply = ({ postId, parentId, content, anonymous }) => ({
  type: types.REPLY,
  payload: { postId, parentId, content, anonymous }
})

export const replySuccess = ({ postId, id, parentId, content, anonymous, user }) => ({
  type: types.REPLY_SUCCESS,
  payload: { postId, id, parentId, content, anonymous, user }
})

export const replyFail = () => ({
  type: types.REPLY_FAIL
})

export const select = id => ({
  type: types.SELECT,
  payload: id
})

export const selectSuccess = selectedPost => ({
  type: types.SELECT_SUCCESS,
  payload: selectedPost
})

export const selectFail = () => ({
  type: types.SELECT_FAIL
})

export const loadUsersAdvicePosts = payload => ({
  type: types.LOAD_USERS_ADVICE_POSTS,
  payload
})

export const loadUsersAdvicePostsSuccess = posts => ({
  type: types.LOAD_USERS_ADVICE_POSTS_SUCCESS,
  payload: posts
})

export const loadUsersAdvicePostsFail = () => ({
  type: types.LOAD_USERS_ADVICE_POSTS_FAIL
})

export const reportPost = (id, reason) => ({
  type: types.REPORT_POST,
  payload: { id, reason }
})

export const reportPostSuccess = responsePost => ({
  type: types.REPORT_POST_SUCCESS,
  payload: responsePost
})

export const reportPostFail = () => ({
  type: types.REPORT_POST_FAIL
})
