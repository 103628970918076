import { connect } from "react-redux"
import MarketingSettingsComponent from "../../components/ProfileSettingsModal/MarketingSettings"
import { updateYourProfile } from "../../../../yourProfile/store/yourProfile.actions"
import { getAllowMarketing } from "../../../../yourProfile/store/yourProfile.selectors"

const mapState = state => ({
  allowMarketing: getAllowMarketing(state)
})

const mapDispatch = dispatch => ({
  submitProfile: data => dispatch(updateYourProfile(data))
})

const MarketingSettings = connect(
  mapState,
  mapDispatch
)(MarketingSettingsComponent)

export default MarketingSettings
