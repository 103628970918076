import { Button, Flex, Text } from "@engaging-tech/components"
import { AnimatePresence } from "framer-motion"
import React, { useState } from "react"
import { useSelector } from "react-redux"

import { businessProfilePageSelectors } from "../../.."
import { getLabelData } from "../../../../../lib/utils"
import PercentageGraphic from "../../../../ui/components/PercentageGraphic"
import ModalChartViewer from "./common/modal-chart-viewer"
import IndustryAndGlobalScoreBox from "./industry-and-global-score-box"
import WidgetContainer from "./widget-container"

const ManagementConfidenceIndicator = () => {
  const [showModal, setShowModal] = useState(false)

  const dataSet = useSelector(state =>
    businessProfilePageSelectors.getIndicatorDataSet(state, "managementConfidence")
  )
  const chartData = useSelector(state =>
    businessProfilePageSelectors.getIndicatorChartData(state, "managementConfidence")
  )

  return (
    <>
      <WidgetContainer
        mb={4}
        width={["100%", "48%"]}
        height={["auto", "500px"]}
        title="Confidence In Management"
      >
        <Flex width={["60%", "70%"]} height="auto" mt={[3, 0]}>
          <PercentageGraphic
            graphicWidth="auto"
            height="auto"
            score={dataSet?.score}
            noData={typeof (dataSet ? dataSet.score : null) !== "number"}
            noScoreLabel
            animate
          />
        </Flex>

        <Text
          color={getLabelData(dataSet?.score || 0).color}
          width="100%"
          textAlign="center"
          mb={3}
          height={42}
        >
          {typeof (dataSet ? dataSet.score : null) === "number"
            ? `${Math.round(dataSet?.score)}%  confidence score in management`
            : ""}
        </Text>
        <IndustryAndGlobalScoreBox
          globalScore={dataSet?.global ? Math.round(dataSet.global) : null}
          industryScore={dataSet?.industry ? Math.round(dataSet.industry) : null}
          mb={3}
          mt={[3, 0]}
        />
        <Button variant="primary.0" onClick={() => setShowModal(!showModal)} height={32}>
          Find out more
        </Button>
      </WidgetContainer>
      <AnimatePresence>
        {showModal && (
          <ModalChartViewer
            onToggle={() => setShowModal(!showModal)}
            chartData={chartData}
            graphDescription="The Confidence in Management score is an average across four questions."
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default ManagementConfidenceIndicator
