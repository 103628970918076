import { Card, Flex, Icon, MotionSelect, Text, ToggleLogic } from "@engaging-tech/components"
import { titleCaps } from "@engaging-tech/frontend-utils"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React, { useState } from "react"
import styled from "styled-components"

import { filterOptions } from "../../ui/components/Jobs/lib/filters"
import PrimaryButton from "../../ui/components/PrimaryButton"
import EventsAlertsOptions from "../containers/EventsAlertsOptions"
import FailBox from "./FailBox"
import PremiumModal from "./PremiumModal"
import SuccessBox from "./SuccessBox"

const PremiumBanner = styled(Flex)`
  border-radius: 4px 4px 0px 0px;
  height: 38px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const FREQUENCIES = ["Daily", "Weekly", "Monthly"]

const getInitialString = ({ type, filters }) => {
  if (type !== "job") return ""
  const { searchTerm, location } = filters
  return [searchTerm, location?.name].filter(a => !!a).join(", ")
}

const getFilterText = ({ key, value, industries = [] }) => {
  const options = key === "industryId" ? industries : filterOptions[key]?.options
  if (!options) {
    return titleCaps(value)
  }
  const option = options.find(o => o.id === value)
  if (key === "industryId") return titleCaps(option?.name)
  return titleCaps(option?.value)
}

const DEFAULT_TEXTS = {
  job: "All Jobs",
  event: "All Events",
  "Business Library": "All Material"
}

const generateAlertText = ({ filters, type, industries }) => {
  const mergedFilters = Object.entries(filters).reduce((mergedText, [key, filter]) => {
    if (!filter || ["searchTerm", "location", "organisationId"].includes(key)) return mergedText
    let displayFilter = filter
    if (type === "job") {
      displayFilter = getFilterText({ key, value: filter, industries })
    }
    if (!mergedText) return mergedText + displayFilter
    return `${mergedText}, ${displayFilter}`
  }, getInitialString({ type, filters }))

  if (mergedFilters) {
    return mergedFilters
  }
  return DEFAULT_TEXTS[type] || "All"
}

const Frequencies = ({ setSelectedFrequency }) => {
  return (
    <MotionSelect
      id="frequency"
      name="frequency"
      borderRadius={20}
      height={40}
      bg="light.0"
      buttonTextStyling={{
        fontSize: 14,
        ml: 3,
        mr: 0,
        fontWeight: 400,
        color: "primary.0"
      }}
      mx={2}
      iconColor="primary.0"
      dropdownBg="grey.0"
      defaultOptionId={FREQUENCIES[0]}
      disableClearButton
      options={FREQUENCIES.map(frequency => ({
        id: frequency,
        value: frequency
      }))}
      zIndex={10}
      border={{
        style: "solid",
        width: "1px",
        color: "rgba(1,62,76,1)"
      }}
      onChange={e => setSelectedFrequency(e)}
    />
  )
}

const TogglePremium = ({ type, isAuthenticated, materialType }) => {
  const router = useRouter()
  const paths = usePaths()

  return (
    <ToggleLogic>
      {({ on, toggle }) => (
        <>
          <PrimaryButton
            type="button"
            color="grey.0"
            width={1 / 1}
            maxWidth="200px"
            onClick={toggle}
            text={`Create ${materialType || type} Alert`}
            height={37}
          />
          {on && isAuthenticated && <PremiumModal type={type} toggle={toggle} />}
          {on && !isAuthenticated && router.navigate(paths.getPremium.index)}
        </>
      )}
    </ToggleLogic>
  )
}

const GetNotifications = ({
  type,
  onSubmit,
  isPremium,
  isAuthenticated,
  success,
  fail,
  latestAlert,
  toggle,
  maxWidth = null,
  materialType,
  industries,
  filters
}) => {
  const [selectedFrequency, setSelectedFrequency] = useState(FREQUENCIES[0])
  const [eventAlert, setEventAlert] = useState({
    category: {
      id: 0,
      value: ""
    }
  })

  return (
    <Flex flexDirection="column" width={1 / 1}>
      {success && <SuccessBox alert={latestAlert} successToggle={toggle} type={type} />}
      {fail && <FailBox errorCode={fail} failToggle={toggle} type={type} />}
      <Card maxWidth={maxWidth} width={1 / 1}>
        {!isPremium && (
          <PremiumBanner bg="primary.0">
            <Icon name="stars" color="yellow.0" />
            <Text fontSize={14} color="yellow.0" ml={2} fontWeight={700} width="auto">
              PREMIUM SERVICE
            </Text>
          </PremiumBanner>
        )}
        <Flex
          width={1 / 1}
          p={3}
          justifyContent="space-between"
          alignItems="center"
          flexDirection={["column", "row", "row"]}
          flexWrap="wrap"
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            width="auto"
            flexWrap="wrap"
            flexDirection={["column", "row", "row"]}
          >
            <Flex
              alignItems="center"
              flexDirection="row"
              width="auto"
              alignContent="center"
              justifyContent={["center", "flex-start", "flex-start"]}
            >
              <Text fontWeight="500" color="dark.2" fontSize={3} width="auto" mr={1}>
                Receive
              </Text>
              <Frequencies setSelectedFrequency={setSelectedFrequency} />
              <Text fontWeight="500" color="dark.2" fontSize={3} width="100px" mx={1}>
                email alerts for
              </Text>
            </Flex>
            {(type === "job" || type === "Business Library") && (
              <Text color="dark.2" width="auto" fontWeight={700} mt={[2, 0, 0]}>
                {generateAlertText({ filters, type, industries })}
              </Text>
            )}
            {type === "event" && (
              <EventsAlertsOptions setEventAlert={setEventAlert} eventAlert={eventAlert} />
            )}
          </Flex>
          {isPremium ? (
            <PrimaryButton
              alignSelf={["center"]}
              color="grey.0"
              width="auto"
              maxWidth="200px"
              text={`Create ${materialType || type} Alert`}
              m={2}
              disabled={type === "event" && (!eventAlert?.location || eventAlert?.category?.id === 0)}
              onClick={() => {
                if (type === "event") {
                  return onSubmit(selectedFrequency.id, eventAlert.location, eventAlert.category)
                }
                return onSubmit(selectedFrequency.id)
              }}
            />
          ) : (
            <TogglePremium type={type} materialType={materialType} isAuthenticated={isAuthenticated} />
          )}
        </Flex>
      </Card>
    </Flex>
  )
}

export default GetNotifications
