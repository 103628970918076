import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"

import { SignInView } from "../../features/account"
import SiteLogo from "../../features/ui/components/SiteLogo"
import useFreschatWidget from "../../hooks/useFreschatWidget"
import SolidLayout from "../../layouts/SolidLayout"

export default function SignIn() {
  useFreschatWidget()
  return (
    <SolidLayout>
      <SignInView
        siteLogo={SiteLogo}
        title="Sign in"
        subtitle="to continue your journey to workplace happiness"
        cancelRoute={`${getConfig().workL.public.index}/home`}
      />
    </SolidLayout>
  )
}
