import React from "react"
import styled from "styled-components"
import { Radio, Text } from "@engaging-tech/components"

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`

const RadioListItemWrapper = styled.label`
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
`

const ReportComponent = ({ onReportChange, reportReason, reportMetadata }) => {
  const handleReasonChange = e => onReportChange(e.target.id)

  const renderReasons = () => (
    <RadioWrapper>
      {reportMetadata.map(reason => {
        return (
          <RadioListItemWrapper key={reason.key}>
            <Radio
              id={reason.key}
              label={() => <Text color="dark.2">{reason.label}</Text>}
              checked={reportReason == reason.key}
              onChange={handleReasonChange}
              name="report-reason"
            />
          </RadioListItemWrapper>
        )
      })}
    </RadioWrapper>
  )

  return renderReasons()
}

export default ReportComponent
