import { connect } from "react-redux"

import { getFeatured } from "../store/businessLibrary.selectors"

import FeaturedPosts from "../components/Featured/FeaturedPosts"

const Featured = postType => {
  const mapState = state => ({
    posts: getFeatured(state, { type: postType }),
    type: postType
  })

  return connect(mapState)(FeaturedPosts)
}

export default Featured
