import React, { useState, useEffect } from "react"
import { Chip, Flex, Select, Button, Text } from "@engaging-tech/components"
import { getIndustries } from "@engaging-tech/frontend-utils"
import styled from "styled-components"
import ProfileSection from "./ProfileSection"

const StyledChip = styled(Chip)`
  &:hover {
    cursor: auto;
  }
`

const AddLink = styled(Text)`
  cursor: ${({ disabled }) => !disabled && "pointer"};
  color: ${({ theme }) => theme.colors.primary[0]};
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
`

const FormShowing = ({ toggle, industries, onUpdate }) => {
  const [industry, setIndustry] = useState("")
  const [myIndustries, setMyIndustries] = useState(industries)
  const [allIndustries, setAllIndustries] = useState([])
  const handleSelect = e => {
    setIndustry({
      id: e.target.value,
      name: allIndustries.filter(ind => ind.id === e.target.value)[0].name
    })
  }

  useEffect(() => {
    setAllIndustries(getIndustries())
  }, [])

  return (
    <Flex flexDirection="column">
      <Select
        label="Add Industry"
        defaultValue=""
        value={industry.id}
        onChange={handleSelect}
      >
        <option disabled value="" selected>
          {" "}
        </option>
        {allIndustries.map(ind => (
          <option key={ind.id} value={ind.id}>
            {ind.name}
          </option>
        ))}
      </Select>
      <AddLink
        disabled={industry === ""}
        mt={3}
        onClick={() => {
          if (!myIndustries.find(h => h.id === industry.id) && !!industry) {
            setMyIndustries([...myIndustries, industry])
            setIndustry("")
          }
        }}
      >
        + ADD INDUSTRY
      </AddLink>
      {myIndustries && (
        <Flex flexWrap="wrap">
          {myIndustries.length > 0 &&
            myIndustries.map(point => (
              <Chip
                mr={2}
                key={point.id}
                mt={2}
                trailingIcon="close"
                color="primary.0"
                onClick={() => {
                  setMyIndustries([
                    ...myIndustries.filter(inds => inds.id !== point.id)
                  ])
                }}
              >
                {point.name}
              </Chip>
            ))}
        </Flex>
      )}

      <Button
        mt={3}
        elevation={0}
        variant="outline.primary.0"
        width="120px"
        onClick={() => {
          onUpdate({ industries: myIndustries.map(ind => ind.id) })
          toggle()
        }}
      >
        SAVE
      </Button>
    </Flex>
  )
}

const DataShowing = ({ industries }) =>
  industries ? (
    <>
      <Text mt={2}>Your selected industries:</Text>
      <Flex flexWrap="wrap">
        {industries.length > 0 &&
          industries.map(point => (
            <StyledChip key={point.id} mr={2} mt={2} cursor="none">
              {point.name}
            </StyledChip>
          ))}
      </Flex>
    </>
  ) : (
    <Text mt={2}>You have not selected any industries yet</Text>
  )

const Industries = ({
  industries,
  onUpdate,
  toggle,
  hideCancelButton = false
}) => {
  const [dataShowing, setDataShowing] = useState(true)

  return (
    <ProfileSection
      title="Industries"
      text="Adding which industries you've worked in helps showcase your professional experiences."
      icon="industry"
      onClick={() => setDataShowing(!dataShowing)}
      toggle={toggle}
      dataShowing={dataShowing}
      hideCancelButton={hideCancelButton}
    >
      {dataShowing ? (
        <DataShowing industries={industries} />
      ) : (
        <FormShowing
          toggle={toggle || (() => setDataShowing(true))}
          industries={industries}
          onUpdate={onUpdate}
        />
      )}
    </ProfileSection>
  )
}

export default Industries
