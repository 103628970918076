import React from "react"
import { Flex, Text, Icon } from "@engaging-tech/components"

import styled from "styled-components"

const StyledFlex = styled(Flex)`
  border-radius: ${({ radius }) => `${radius}px ${radius}px 0px 0px`};
`

const PremiumHeading = ({ radius = 7, ...props }) => (
  <StyledFlex
    elevation={0}
    bg="primary.0"
    width={1 / 1}
    flexDirection="row"
    alignItems="center"
    justifyContent="center"
    py={1}
    px={2}
    mt={0}
    radius={radius}
    {...props}
  >
    <Icon name="stars" color="light.0" {...props} />
    <Text
      color="light.0"
      fontSize={3}
      fontWeight={700}
      px={2}
      width="auto"
      {...props}
    >
      Premium Member
    </Text>
  </StyledFlex>
)

export default PremiumHeading
