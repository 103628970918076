import { createSelector } from "reselect"

const demographicFilterState = state => state.demographicFilter

export const getDemographicFiltersList = createSelector(demographicFilterState, state => state.filters)

export const getDemographicFiltersIsLoading = createSelector(
  demographicFilterState,
  state => state.isFiltersLoading
)
