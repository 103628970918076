import React from "react"

import DetailsLayout from "../../../layouts/DetailsLayout"
import Post from "../../../features/businessLibrary/containers/Post"

const Podcast = () => (
  <DetailsLayout>
    <Post />
  </DetailsLayout>
)

export default Podcast
