import React from "react"
import { Flex, Text, Icon } from "@engaging-tech/components"

const InputLoadError = () => (
  <Flex width={1 / 1} mb={2} mt={2}>
    <Icon name="warning" color="dark.2" size={20} />
    <Text ml={2}>Error Loading Input</Text>
  </Flex>
)

export default InputLoadError
