import { types } from "./happinessSurvey.actions"

export const initialState = {
  isLoading: false,
  isSubmitting: false,
  hasSubmitted: false,
  hasAttachedUser: false,
  hasLoaded: false,
  hasError: false,
  meta: null,
  // content: null,
  submissionId: null,
  answers: [],
  selectedGroups: [],
  status: null,
  surveyId: null,
  title: null,
  type: null,
  introduction: null,
  template: null,
  questions: null,
  languages: null,
  savantaTerritory: null
}

const buildQuestionMap = questions =>
  questions.reduce((map, question) => {
    return { ...map, [question.id]: question }
  }, {})

const parseSurveyResponse = response => {
  const { id, meta, template, languages, title, type, introduction, status } = response

  return {
    status,
    surveyId: id,
    meta,
    title,
    type,
    introduction,
    template,
    questions: buildQuestionMap(template.questions),
    languages
  }
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD:
      return { ...state, isLoading: true, hasError: false }

    case types.LOAD_SUCCESS:
      return {
        ...state,
        ...parseSurveyResponse(action.payload),
        isLoading: false,
        hasLoaded: true,
        hasSubmitted: false,
        hasAttachedUser: false,
        answers: [],
        selectedGroups: []
      }

    case types.LOAD_FAIL:
      return { ...state, isLoading: false, hasError: true }

    case types.ANSWER:
      return {
        ...state,
        answers: [
          ...state.answers.filter(answer => answer.questionId !== action.payload.questionId),
          action.payload
        ]
      }

    case types.SET_GROUPS:
      return { ...state, selectedGroups: action.payload }

    case types.SUBMIT:
      return { ...state, isSubmitting: true, hasError: false }

    case types.SUBMIT_SUCCESS:
      return {
        ...state,
        submissionId: action.payload.id,
        isSubmitting: false,
        hasSubmitted: true,
        hasAttachedUser: action.payload.isAuthenticated,
        hasError: false
      }

    case types.SUBMIT_FAIL:
      return { ...state, isSubmitting: false, hasError: true }

    case types.ATTACH_USER_SUCCESS:
      return { ...state, hasAttachedUser: true }

    case types.SET_SAVANTA_ID:
      return { ...state, savantaId: action.payload }

    case types.SET_SAVANTA_TERRITORY:
      return { ...state, savantaTerritory: action.payload }

    case types.SET_COMPANY_NAME:
      return { ...state, companyName: action.payload }

    case types.SET_COMPANY_ID:
      return { ...state, companyId: action.payload }

    case types.CLEAR_COMPANY_NAME:
      return { ...state, companyId: "", companyName: "" }

    default:
      return state
  }
}
