import React from "react"
import styled from "styled-components"
import { Spinner, Flex } from "@engaging-tech/components"
import PrimaryButton from "../../ui/components/PrimaryButton"

const logo = require(`${process.env.RAZZLE_PUBLIC_DIR}/favicon.ico`)

const Image = styled.img`
  width: 24px;
`

const LoadedAllIndicator = () => (
  <Flex width={1 / 1} justifyContent="center">
    <Image src={logo} />
  </Flex>
)

const LoadMore = ({
  postType,
  from,
  isLoadingMore,
  hasLoadedAll,
  onLoad,
  searchTerm,
  categoryId
}) => {
  if (hasLoadedAll) {
    return <LoadedAllIndicator />
  }

  if (isLoadingMore) {
    return (
      <Spinner py={3} color="primary.0" justifyContent="center" width={1 / 1} />
    )
  }

  return (
    <Flex
      maxWidth={700}
      ml="auto"
      justifyContent="center"
      alignItems="center"
      height={60}
    >
      {isLoadingMore ? (
        <Spinner py={3} color="primary.0" width={1 / 1} />
      ) : (
        <PrimaryButton
          onClick={() => onLoad(postType, from, searchTerm, categoryId)}
          width={300}
          disabled={hasLoadedAll}
          color="light.0"
          text={hasLoadedAll ? "Loaded all content" : "Load more"}
        />
      )}
    </Flex>
  )
}

export default LoadMore
