import { userInterfaceNotificationsStore } from "@engaging-tech/components"
import { useLocation } from "@engaging-tech/routing"
import { Auth } from "aws-amplify"
import { Formik } from "formik"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import ForgotPasswordFormContent from "./forgotPasswordFormContent"
import schemaForm from "./schema.form"

export default function ForgotPasswordForm({ siteLogo, cancelRoute }) {
  const [isLoading, setIsLoading] = useState(false)

  const email = new URLSearchParams(useLocation().search).get("email")?.replace("|", "")

  const dispatch = useDispatch()

  const onSubmit = useCallback(async data => {
    try {
      setIsLoading(true)

      await Auth.forgotPassword(data.email)

      dispatch(
        userInterfaceNotificationsStore.actions.addNotification({
          message: "Send password reset email"
        })
      )
    } catch (error) {
      dispatch(
        userInterfaceNotificationsStore.actions.addNotification({
          message:
            typeof error.message === "string"
              ? error.message
              : "Failed to send password reset email, please try again"
        })
      )
    } finally {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (email) {
      onSubmit({ email })
    }
  }, [email, onSubmit])

  return (
    <Formik initialValues={{ email: email || "" }} validationSchema={schemaForm} onSubmit={onSubmit}>
      {props => (
        <ForgotPasswordFormContent
          siteLogo={siteLogo}
          isLoading={isLoading}
          cancelRoute={cancelRoute}
          {...props}
        />
      )}
    </Formik>
  )
}
