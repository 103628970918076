export const types = {
  LOAD: "[OrganisationSurvey] Load",
  LOAD_SUCCESS: "[OrganisationSurvey] Load Success",
  LOAD_FAIL: "[OrganisationSurvey] Load Fail",
  ANSWER: "[OrganisationSurvey] Answer",
  SUBMIT: "[OrganisationSurvey] Submit",
  SUBMIT_SUCCESS: "[OrganisationSurvey] Submit Success",
  SUBMIT_FAIL: "[OrganisationSurvey] Submit Fail",
  SET_CODE: "[OrganisationSurvey] Set Code",
  CHECK_CODE: "[OrganisationSurvey] Check Code",
  CHECK_CODE_SUCCESS: "[OrganisationSurvey] Check Code Success",
  CHECK_CODE_FAIL: "[OrganisationSurvey] Check Code Fail",
  ATTACH_USER: "[OrganisationSurvey] Attach User",
  ATTACH_USER_SUCCESS: "[OrganisationSurvey] Attach User Success",
  ATTACH_USER_FAIL: "[OrganisationSurvey] Attach User Fail",
  CHANGE_LANGUAGE: "[OrganisationSurvey] Change Language",
  REMOVE_ANSWER: "[OrganisationSurvey] Remove Answer",
  SECTIONS_LIST_UPDATE: "[Section] Update List",
  SECTION_CURRENT_UPDATE: "[Section] Update Current",
  QUESTION_HISTORY_UPDATE: "[Question] History Update",
  QUESTION_CURRENT_UPDATE: "[Question] Update Current",
  QUESTION_LIST_UPDATE: "[Question] Update List",
  BRANCHING_LOGICS_UPDATE: "[Branching] Update Logics",
  SET_SAVANTA_ID: "[OrganisationSurvey] Set Savanta Id"
}

export const setSavantaId = payload => ({
  type: types.SET_SAVANTA_ID,
  payload
})

export const load = payload => ({
  type: types.LOAD,
  payload
})

export const loadSuccess = survey => ({
  type: types.LOAD_SUCCESS,
  payload: survey
})

export const loadFail = () => ({
  type: types.LOAD_FAIL
})

export const checkCode = ({ code, surveyId }) => ({
  type: types.CHECK_CODE,
  payload: { code, surveyId }
})

export const checkCodeSuccess = () => ({
  type: types.CHECK_CODE_SUCCESS
})

export const checkCodeFail = errorCode => ({
  type: types.CHECK_CODE_FAIL,
  payload: errorCode
})

export const setCode = code => ({
  type: types.SET_CODE,
  payload: code
})

export const answer = ({ questionId, value }) => ({
  type: types.ANSWER,
  payload: { questionId, value }
})

export const removeAnswer = payload => ({
  type: types.REMOVE_ANSWER,
  payload
})

export const submit = () => ({
  type: types.SUBMIT
})

export const submitSuccess = (id, isAuthenticated) => ({
  type: types.SUBMIT_SUCCESS,
  payload: { id, isAuthenticated }
})

export const submitFail = () => ({
  type: types.SUBMIT_FAIL
})

export const attachUser = payload => ({
  type: types.ATTACH_USER,
  payload
})

export const attachUserSuccess = () => ({
  type: types.ATTACH_USER_SUCCESS
})

export const attachUserFail = () => ({
  type: types.ATTACH_USER_FAIL
})

export const changeLanguage = language => ({
  type: types.CHANGE_LANGUAGE,
  payload: language
})
