import { useEffect } from "react"
import { connect } from "react-redux"

import { loadSurveyJobRoles } from "../store/misc.actions"
import {
  getHasLoaded,
  getIsLoading,
  getSurveyJobRoles
} from "../store/misc.selectors"

const SurveyJobRolesLoaderComponent = ({
  surveyJobRoles,
  loadJobRoles,
  loading,
  hasLoaded
}) => {
  useEffect(() => {
    if (loading) return
    if (hasLoaded && !surveyJobRoles.length) {
      setTimeout(loadJobRoles, 1000)
    } else if (!surveyJobRoles.length) {
      loadJobRoles()
    }
  }, [surveyJobRoles, loadJobRoles, loading, hasLoaded])

  return null
}

const mapState = state => ({
  surveyJobRoles: getSurveyJobRoles(state),
  loading: getIsLoading(state, "SurveyJobRoles"),
  hasLoaded: getHasLoaded(state, "SurveyJobRoles")
})

const mapDispatch = dispatch => ({
  loadJobRoles: () => dispatch(loadSurveyJobRoles())
})

const SurveyJobRolesLoader = connect(
  mapState,
  mapDispatch
)(SurveyJobRolesLoaderComponent)

export default SurveyJobRolesLoader
