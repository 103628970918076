import { connect } from "react-redux"

import {
  load,
  setSavantaTerritory,
  setSavantaId
} from "../store/happinessSurvey.actions"

import WelcomeComponent from "../components/WelcomeSavanta"

const mapDispatch = dispatch => ({
  onLoad: id => dispatch(load(id)),
  setSavantaId: id => dispatch(setSavantaId(id)),
  setSavantaTerritory: geolocation => dispatch(setSavantaTerritory(geolocation))
})

const WelcomeSavanta = connect(null, mapDispatch)(WelcomeComponent)

export default WelcomeSavanta
