export default [
  {
    title: "WorkL Answers - How to succeed in mentoring with Julie Starr",
    url: "https://vimeo.com/583365809",
    runTimeInMinutes: 16
  },
  {
    title:
      "WorkL Answers, insights into being an entrepreneur with Oli Co Founder, Saasha Celestial-One",
    url: "https://youtu.be/FmcU1xb0_bg",
    runTimeInMinutes: 7
  },
  {
    title:
      "WorkL Answers - Niamh O’Keeffe a ‘Leadership Advisor & Author’, discusses how to manage and lead.",
    url: "https://youtu.be/7AeR9Q_Jr2Q",
    runTimeInMinutes: 7
  },
  {
    title: "How to deal with the impact of the menopause at work",
    url: "https://youtu.be/7QP_DDL1Pqg",
    runTimeInMinutes: 16,
    description:
      "WorkL Answers talks to Founder of MPowder, Rebekah Brown to discuss the impact menopause has on women in the workplace"
  },
  {
    title: "How to Overcome your Fear of presenting",
    url: "https://youtu.be/hq2eNCAPYtM",
    runTimeInMinutes: 30,
    description: "Esther Stanhope provides advice about presenting"
  },
  {
    title: "How to Influence Big Egos",
    url: "https://youtu.be/WQh_UW9KARo",
    runTimeInMinutes: 14,
    description: "Esther Stanhope talks about how you can influence big egos"
  },
  {
    title: "How to Pivot your Career",
    url: "https://youtu.be/tywv6DAnSIo",
    runTimeInMinutes: 14,
    description: "Esther Stanhope talks about how you can pivot your career"
  },
  {
    title: "How to Ask for More Money at Work",
    url: "https://youtu.be/N5dMUvwKpXU",
    runTimeInMinutes: 16,
    description:
      "Esther Stanhope gives advice on how to ask for more money at work"
  },
  {
    title: "Imposter Syndrome - How to tackle it",
    url: "https://youtu.be/75p7kJPjrSE",
    runTimeInMinutes: 15,
    description: "Esther Stanhope talks about managing the Imposter Syndrome"
  },
  {
    title: "How to Ace your Job Interview",
    url: "https://youtu.be/Bn-4P7Afocc",
    runTimeInMinutes: 20,
    description:
      "Esther Stanhope shares advice to help you ace your job interview"
  },
  {
    title: "How to Improve Leadership for Women",
    url: "https://youtu.be/njsmdBgt9Ak",
    runTimeInMinutes: 14,
    description:
      "Esther Stanhope looks at how leadership for women can be improved"
  },
  {
    title: "How to Present using Video Calls",
    url: "https://youtu.be/Oas8FJRBHGg",
    runTimeInMinutes: 23,
    description:
      "John Hammond gives advice on how to best present when on a video call"
  },
  {
    title: "Wellness in the workplace",
    url: "https://youtu.be/AAkkgb1Gudk",
    runTimeInMinutes: 22,
    description:
      "Lucy Cavendish talks about how you can add some wellness into the workplace"
  },
  {
    title: "Learning and Training Virtually",
    url: "https://youtu.be/IroOM8_Iwnc",
    runTimeInMinutes: 22,
    description:
      "Paul Matthews and the People Academy talk about how you can learn & train virtually"
  },
  {
    title:
      "How to deal with discrimination in the workplace when related to race.",
    url: "https://www.youtube.com/watch?v=qcci6X3ZTG8",
    runTimeInMinutes: 4,
    description:
      "With Belton Flournoy a founding member of the Protiviti UK LGBTQ+ Network who founded Pride in the City for Pride in London, and supports The Inclusivity Initiative for the LSE"
  }
]
