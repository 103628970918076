import { useLocation, usePaths, useRouter } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React, { useCallback, useEffect, useState } from "react"

import STATUSES from "../lib/samlStatuses"
import SamlStatus from "./SamlStatus"

export const getCognitoTokens = async (code, provider) => {
  const { ClientId, RedirectUri, TokenUrl } = getConfig().cognito
  const params = new URLSearchParams()
  params.append("grant_type", "authorization_code")
  params.append("code", code)
  params.append("client_id", ClientId)
  params.append("redirect_uri", `${RedirectUri}?provider=${provider}`)

  return fetch(`${TokenUrl}?${params.toString()}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    data: params
  }).then(res => res.json())
}

const MorrisonsSaml = ({
  onLoad,
  profile,
  isSigningIn,
  hasSignedIn,
  onFetchSurvey,
  isLoadingSurveys,
  hasLoadedSurveys,
  surveys
}) => {
  const router = useRouter()
  const paths = usePaths()
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const [showError, setShowError] = useState(false)
  const [isFetchingToken, setIsFetchingToken] = useState(true)
  const [noSurveys, setNoSurveys] = useState(false)

  const code = params.get("code")

  const getStatus = () => {
    if (hasLoadedSurveys && surveys.length > 1) return STATUSES.CHOOSE_SURVEY
    if (hasLoadedSurveys && surveys.length <= 1) return STATUSES.REDIRECTING
    if (isLoadingSurveys) return STATUSES.FETCHING_SURVEYS
    if (isSigningIn) return STATUSES.SIGN_IN
    return STATUSES.SIGN_IN
  }

  const getTokens = useCallback(async () => {
    try {
      const res = await getCognitoTokens(code, "morrisons")
      if (res.error) throw new Error(res.error)
      const { id_token: idToken, access_token: accessToken, refresh_token: refreshToken } = res
      onLoad({ idToken, accessToken, refreshToken })
    } catch (error) {
      setShowError(true)
      onLoad({})
    }
    setIsFetchingToken(false)
  }, [onLoad, code])

  useEffect(() => {
    getTokens()
  }, [getTokens])

  useEffect(() => {
    if (!isFetchingToken && hasSignedIn) {
      if (!profile) {
        setShowError(true)
      } else {
        onFetchSurvey()
      }
    }
  }, [profile, onFetchSurvey, hasSignedIn, isFetchingToken])

  useEffect(() => {
    if (hasLoadedSurveys && surveys.length === 1) {
      const [survey] = surveys
      setTimeout(() => {
        router.navigate(`${paths.survey.index.replace(":id", survey.surveyId)}?code=${survey.code}`)
      }, 200)
    }
    if (hasLoadedSurveys && (!surveys || !surveys.length)) {
      setTimeout(() => {
        setNoSurveys(true)
      }, 200)
    }
  }, [surveys, hasLoadedSurveys, router, paths.survey.index])

  return (
    <SamlStatus
      hasError={showError && !isFetchingToken}
      status={getStatus()}
      surveys={surveys}
      noSurveys={noSurveys}
    />
  )
}

export default MorrisonsSaml
