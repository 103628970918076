import React from "react"
import { useSelector } from "react-redux"
import { usePaths, Link } from "@engaging-tech/routing"
import { Button, Flex, Text } from "@engaging-tech/components"
import { AccountSelectors } from "../../../../account"

const AuthGuard = ({ callToActionText, children }) => {
  const paths = usePaths()
  const isAuthenticated = useSelector(AccountSelectors.getIsAuthenticated)

  return isAuthenticated ? (
    children
  ) : (
    <Flex
      width={1 / 1}
      alignItems={["flex-start", "center"]}
      flexDirection={["column", "row"]}
      mb={3}
    >
      <Text color="dark.2" mr={[0, 3]} mb={[3, 0]}>
        {callToActionText}:
      </Text>
      <Link to={paths.account.signUp}>
        <Button
          variant="primary.0"
          width={[1 / 1, 1 / 4]}
          mr={[0, 2]}
          mb={[3, 0]}
        >
          Sign Up
        </Button>
      </Link>

      <Link to={paths.account.signIn}>
        <Button variant="secondary.4" width={[1 / 1, 1 / 4]}>
          Sign In
        </Button>
      </Link>
    </Flex>
  )
}

AuthGuard.defaultProps = {
  callToActionText: "Listen to podcast"
}

export default AuthGuard
