import { Card, Flex, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React from "react"

import PrimaryButton from "../../ui/components/PrimaryButton"

const SideCards = () => {
  return (
    <Flex flexDirection="column" width={[1 / 1, 1 / 1, 1 / 3]}>
      <Card ml={[0, 0, 4]} mb={3} mt={[3, 3, 0]} p={3} width={[1 / 1, 1 / 1, "240px"]} height="183px">
        <Text fontSize={4} fontWeight={500} mb={2} color="dark.1" width="auto">
          Rank your Organisation
        </Text>
        <Text fontSize={3} mb={4} color="dark.2" width="auto" height="59px">
          Rank your organisation anonymously to help others find the best job role for them
        </Text>
        <Link to="/happiness-survey" mx="auto">
          <PrimaryButton color="light.0" width="208px" elevation={4} text="Rank Organisation" />
        </Link>
      </Card>
      <Card ml={[0, 0, 4]} p={3} width={[1 / 1, 1 / 1, "240px"]} height="183px">
        <Text fontSize={4} fontWeight={500} mb={2} color="dark.1" width="auto">
          Find the right job
        </Text>
        <Text fontSize={3} mb={4} color="dark.2" width="auto" height="59px">
          Find the perfect job for you based on your demographics.
        </Text>
        <Link to="/find-work/jobs" mx="auto">
          <PrimaryButton color="light.0" width="208px" elevation={4} text="Job Finder" />
        </Link>
      </Card>
    </Flex>
  )
}

export default SideCards
