import { getConfig } from "@engaging-tech/ssr-config"

import { apollo } from "../../../lib/Apollo"
import { apolloInAppView } from "../../../lib/ApolloInAppView"
import POSTS_TO_LOAD from "../lib/postsToLoad"
import { COMMENT, ENDORSE, LOAD, POST, REPLY, REPORT_POST } from "./globalHub.queries"

const GRAPHQL_API_URL = () => `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

/**
 * Loads Global Hub posts of a specific type (jobs, articles etc.)
 * @param {string} type The type of post to load.
 * @param {number} from The starting point/index for loading posts.
 * @param {number} from Limit the amount of posts to receive from request.
 */
export const load = ({ place = "", type = "", limit = POSTS_TO_LOAD, from = 0 }) => {
  if (place === "in-app-view") {
    return apolloInAppView.query({
      uri: GRAPHQL_API_URL(),
      query: LOAD,
      variables: { type, from, limit }
    })
  }

  return apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD,
    variables: { type, from, limit }
  })
}

/**
 * Loads a selected Global Hub post by its ID.
 * @param {string} id The ID of the psot to be loaded.
 */
export const loadSelected = id =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD,
    variables: { id }
  })

/**
 * Creates a new post on the Global Hub.
 * @param {object} newPost The new post to create.
 */
export const post = ({ newPost, place }) => {
  if (place === "in-app-view") {
    return apolloInAppView.mutate({
      uri: GRAPHQL_API_URL(),
      mutation: POST,
      variables: newPost
    })
  }

  return apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: POST,
    variables: newPost
  })
}

/**
 * Attaches/removes an endorsement from a Global Hub post.
 * The isEndorsing flag determines if an endorsement is being
 * created or removed.
 * @param {string} id The ID of the hub post to endorse/unendorse.
 * @param {boolean} isEndorsing The flag to determine if an endorsement is being
 * created or removed.
 */
export const endorse = ({ id, isEndorsing }) =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: ENDORSE,
    variables: { id, isEndorsing }
  })

/**
 * Creates a new comment against a global hub post.
 * @param {object} newComment The new comment to create.
 */
export const comment = newComment =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: COMMENT,
    variables: newComment
  })

/**
 * Creates a new reply against a global hub comment.
 * @param {object} newReply The new reply to create
 */
export const reply = newReply =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: REPLY,
    variables: newReply
  })

/**
 * Loads Global Hub posts with a specific type, made by this user.
 */
export const loadUsersAdvice = ({ type, userId }) =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD,
    variables: { type, userId }
  })

export const reportPost = ({ id, reason }) => {
  return apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: REPORT_POST,
    variables: { id, reason }
  })
}
