import { Box } from "@engaging-tech/components"
import { useLocation } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"
import styled from "styled-components"

import { ConfirmBanner } from "../features/account"
import SiteBanner from "../features/cms/containers/SiteBanner"
import DownForMaintenance from "../features/ui/components/DownForMaintenance"
import Footer from "../features/ui/components/Footer"
import TestingSiteBanner from "../features/ui/components/TestingSiteBanner"
import Nav from "../features/ui/components/nav"
import AuthGuard from "../features/ui/containers/AuthGuard"
import MinifiedNav from "../features/ui/containers/MinifiedNav"
import useFreschatWidget from "../hooks/useFreschatWidget"

const MainContainer = styled(Box)`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const BaseLayout = ({
  before: Before,
  banner: Banner,
  bg = "transparent",
  forceDownForMaintenance,
  children,
  isPublic,
  ...props
}) => {
  const location = useLocation()
  // show/hide freshchatwidget
  useFreschatWidget()
  const { downForMaintenance, websiteInfoBanner } = getConfig()

  const showDownForMaintenance = forceDownForMaintenance || downForMaintenance.all

  if (isPublic || forceDownForMaintenance) {
    return (
      <>
        <TestingSiteBanner />
        {websiteInfoBanner && <SiteBanner text={websiteInfoBanner} disableClose icon="warning" />}
        {location.pathname === "/" && <SiteBanner />}

        {Before && <Before />}
        {downForMaintenance.all ? <MinifiedNav disableAccountRoutes /> : <Nav />}
        {downForMaintenance.all ? <></> : <ConfirmBanner />}

        {Banner && <Banner />}

        <Box as="main" bg={bg} {...props}>
          {showDownForMaintenance ? <DownForMaintenance /> : children}
        </Box>
        <Footer />
      </>
    )
  }

  return (
    <AuthGuard>
      <TestingSiteBanner />

      {websiteInfoBanner && <SiteBanner text={websiteInfoBanner} disableClose icon="warning" />}

      {location.pathname === "/" && <SiteBanner />}

      {Before && <Before />}

      <Nav />

      <ConfirmBanner />

      {Banner && <Banner />}

      <MainContainer as="main" bg={bg} {...props}>
        {children}
      </MainContainer>

      <Footer />
    </AuthGuard>
  )
}

export default BaseLayout
