import { takeLatest, put } from "redux-saga/effects"
import { userInterfaceNotificationsStore } from "@engaging-tech/components"
import { LibraryBooksActions } from "../../gardners"

import { yourProfileActions } from "../../yourProfile"
import { UserContentActions } from "../../userGeneratedContent"
import { GlobalHubActions } from "../../globalHub"
import { MyLibraryActions } from "../../myLibrary"
import { ExpertAdviceActions } from "../../expertAdvice"

import * as Actions from "./ui.actions"

export function* showNotificationSaga(action) {
  yield put(
    userInterfaceNotificationsStore.actions.addNotification({
      message: action.payload
    })
  )
}

export function* saga() {
  yield takeLatest(
    yourProfileActions.types.SUBSCRIBE_FAIL,
    showNotificationSaga,
    {
      payload: "There was a problem subscribing. Your card has not been charged"
    }
  )
  yield takeLatest(
    UserContentActions.types.ADD_MATERIAL_SUCCESS,
    showNotificationSaga
  )
  yield takeLatest(
    UserContentActions.types.ADD_MATERIAL_FAIL,
    showNotificationSaga,
    { payload: "There was a problem creating your post. Please try again" }
  )
  yield takeLatest(
    ExpertAdviceActions.types.CREATE_SUCCESS,
    showNotificationSaga,
    { payload: "Your question was successfully published!" }
  )
  yield takeLatest(
    ExpertAdviceActions.types.CREATE_FAIL,
    showNotificationSaga,
    {
      payload: "There was a problem creating your question. Please try again"
    }
  )
  yield takeLatest(
    UserContentActions.types.CREATE_SERIES_FAIL,
    showNotificationSaga,
    {
      payload:
        "A series with that name already exists. Please try a different name"
    }
  )
  yield takeLatest(
    UserContentActions.types.PURCHASE_MATERIAL_FAIL,
    showNotificationSaga,
    { payload: "Unable to purchase material. Please try again" }
  )
  yield takeLatest(GlobalHubActions.types.POST_FAIL, showNotificationSaga, {
    payload: "There was a problem creating your post. Please try again"
  })
  yield takeLatest(GlobalHubActions.types.POST_SUCCESS, showNotificationSaga, {
    payload: "Your post has been created"
  })
  yield takeLatest(
    GlobalHubActions.types.COMMENT_FAIL,
    showNotificationSaga,

    { payload: "There was a problem creating your comment. Please try again" }
  )
  yield takeLatest(GlobalHubActions.types.ENDORSE_FAIL, showNotificationSaga, {
    payload: "There was a problem endorsing. Please try again"
  })

  yield takeLatest(
    LibraryBooksActions.types.ADD_TO_BASKET,
    showNotificationSaga,
    { payload: "This item has been added to your basket" }
  )

  yield takeLatest(
    LibraryBooksActions.types.CREATE_ORDER_FAIL,
    showNotificationSaga
  )

  yield takeLatest(
    LibraryBooksActions.types.UPDATE_ORDER_FAIL,
    showNotificationSaga,
    { payload: "There was a problem setting up your order. Please try again" }
  )

  yield takeLatest(
    LibraryBooksActions.types.CREATE_ORDER_INTENT_FAIL,
    showNotificationSaga,
    {
      payload:
        "There was a problem with your payment details. Your card has not been charged"
    }
  )

  yield takeLatest(
    LibraryBooksActions.types.PAY_ORDER_FAIL,
    showNotificationSaga,
    {
      payload:
        "There was a problem with your payment details. Your card has not been charged"
    }
  )

  yield takeLatest(
    MyLibraryActions.types.DOWNLOAD_EBOOK,
    showNotificationSaga,
    {
      payload: "Your eBook is downloading"
    }
  )

  yield takeLatest(
    MyLibraryActions.types.DOWNLOAD_AUDIO_BOOK,
    showNotificationSaga,
    { payload: "Your audiobook is downloading" }
  )

  yield takeLatest(
    MyLibraryActions.types.DOWNLOAD_AUDIO_BOOK,
    showNotificationSaga,
    { payload: "Your audiobook is downloading" }
  )

  yield takeLatest(Actions.types.SHOW_NOTIFICATION, showNotificationSaga)
}
