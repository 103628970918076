import { connect } from "react-redux"
import AboutYouComponent from "../components/AboutYou"
import { updateYourProfile } from "../store/yourProfile.actions"
import { getAge, getGender } from "../store/yourProfile.selectors"

const mapState = state => ({
  age: getAge(state),
  gender: getGender(state)
})

const mapDispatch = dispatch => ({
  onUpdate: params => dispatch(updateYourProfile(params))
})

const AboutYou = connect(mapState, mapDispatch)(AboutYouComponent)

export default AboutYou
