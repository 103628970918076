/* eslint-disable no-case-declarations */
import { types } from "./yourProfile.actions"

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  hasError: false,
  isUploading: false,
  showModal: false,
  isVerificationEmailSent: false,
  isVerificationEmailSending: false,
  profile: null,
  promoId: null,
  discountCode: null
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_YOUR_PROFILE:
      return {
        ...state,
        ...action.payload
      }

    case types.CLEAR_PROFILE:
      return initialState

    case types.UPDATE_YOUR_GOALS:
      return {
        ...state,
        isLoading: true
      }
    case types.LOAD_SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, ...action.payload },
        isLoading: false,
        hasError: false,
        hasLoaded: true,
        uploadingImageError: false
      }

    case types.REQUEST_CONFIRMATION:
      return {
        ...state,
        hasLoaded: false,
        hasError: false,
        isLoading: true,
        isVerificationEmailSending: true,
        isVerificationEmailSent: false
      }

    case types.SET_SHOW_MODAL:
      return {
        ...state,
        showModal: action.payload
      }

    case types.UPDATE_YOUR_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        isLoading: false,
        showModal: false
      }

    case types.TOGGLE_SERVICES_SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, services: action.payload }
      }

    case types.REQUEST_CONFIRMATION_FAIL:
      return {
        ...state,
        isVerificationEmailSending: false,
        isVerificationEmailSent: false
      }
    case types.SET_HAS_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: action.payload
      }

    case types.LOAD_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoadingCVs: false
      }

    case types.REQUEST_CONFIRMATION_SUCCESS:
      return {
        ...state,
        hasLoaded: true,
        hasError: false,
        isLoading: false,
        isVerificationEmailSending: false,
        isVerificationEmailSent: true
      }
    case types.UPLOAD_IMAGE:
      return {
        ...state,
        isUploading: true,
        uploadingImageError: false
      }

    case types.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        isUploading: false,
        uploadingImageError: false,
        profile: {
          ...state.profile,
          picture: action.payload
        }
      }
    case types.UPLOAD_IMAGE_FAIL:
      return {
        ...state,
        isUploading: false,
        uploadingImageError: true
      }

    // PREMIUM
    case types.CANCEL_PREMIUM_SUBSCRIPTION: {
      const premium = state.profile ? state.profile.premium : {}
      return {
        ...state,
        profile: {
          ...state.profile,
          premium: {
            ...premium,
            isLoadingPremium: true,
            hasErrorPremium: false
          }
        }
      }
    }

    case types.SUBSCRIBE_PENDING:
      return {
        ...state,
        profile: {
          ...state.profile,
          premium: {
            ...state.profile.premium,
            paymentIntent: action.payload
          }
        }
      }

    case types.SUBSCRIBE_SUCCESS:
      return {
        ...state,
        promoId: null,
        discountCode: null,
        isLoading: false,
        profile: {
          ...state.profile,
          showSuccessPage: true,
          premium: {
            ...state.profile.premium,
            isLoadingPremium: false,
            active: true,
            paymentIntent: null
          }
        }
      }

    case types.SET_SHOW_SUCCESS_PAGE:
      return {
        ...state,
        profile: {
          ...state.profile,
          premium: {
            ...state.profile.premium,
            showSuccessPage: action.payload
          }
        }
      }

    case types.SUBSCRIBE_FAIL:
      return {
        ...state,
        promoId: null,
        discountCode: null,
        profile: {
          ...state.profile,
          premium: {
            ...state.profile.premium,
            isLoadingPremium: false
          }
        }
      }

    case types.SET_STATUS:
      return {
        ...state,
        profile: {
          ...state.profile,
          premium: {
            ...state.profile.premium,
            active: action.payload
          }
        }
      }

    case types.SET_ATTRIBUTES:
      return {
        ...state,
        isLoading: false,
        profile: {
          ...state.profile,
          premium: {
            ...state?.profile?.premium,
            ...action.payload
          }
        }
      }
    case types.CANCEL_PREMIUM_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          premium: {
            ...state.profile.premium,
            premiumCancelled: true,
            premiumExpiration: action.payload,
            isLoadingPremium: false,
            hasErrorPremium: false
          }
        }
      }

    case types.CANCEL_PREMIUM_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        profile: {
          ...state.profile,
          premium: {
            ...state.profile.premium,
            isLoadingPremium: false,
            hasErrorPremium: true
          }
        }
      }

    case types.APPLY_DISCOUNT:
      return {
        ...state,

        isLoading: true,
        hasError: false,
        discountCode: action.payload
      }
    case types.APPLY_DISCOUNT_SUCCESS:
      return {
        ...state,

        isLoading: false,
        promoId: action.payload
      }

    case types.APPLY_DISCOUNT_FAIL:
      return {
        ...state,

        isLoading: false,
        promoId: null,
        discountCode: null
      }

    case types.RESET_ERRORS:
      return {
        ...state,
        hasError: false,
        profile: {
          ...state.profile,
          premium: {
            ...state.profile.premium,
            hasErrorPremium: false
          }
        }
      }
    case types.RESET:
      return initialState
    default:
      return state
  }
}
