import { Flex, SmileyFace, Text } from "@engaging-tech/components"
import React, { useState } from "react"

const Smileys = ({ onAnswer, values, initialValue }) => {
  const [selected, setSelected] = useState(
    (initialValue && initialValue.value[0]) || null
  )

  const handleClick = value => {
    setSelected(value)
    onAnswer(value)
  }
  return (
    <>
      <Flex>
        {values.map(val => (
          <>
            <Flex
              flexDirection="column"
              alignItems="center"
              mr={2}
              my={3}
              onClick={() => handleClick(val.id)}
            >
              <SmileyFace key={val} smiley={val.smileyValue} width="30" />
              <Text
                mt={1}
                fontSize={2}
                textAlign="center"
                fontWeight={selected === val.id ? 600 : 500}
              >
                {val.value}
              </Text>
            </Flex>
          </>
        ))}
      </Flex>
    </>
  )
}

export default Smileys
