import React from "react"
import { useSelector } from "react-redux"
import { Paragraph } from "@engaging-tech/components"
import { titleCaps } from "@engaging-tech/frontend-utils"
import { useTranslation } from "react-i18next"
import { AccountSelectors } from "../../../account"
import ResultCard from "../../components/ResultCard"
import ReportItem from "../../components/ReportItem"
import SignUpBenefits from "./SignUpBenfits"
import SignUpPrompt from "./SignUpPrompt"

const SignUpWidget = () => {
  const isAuthenticated = useSelector(AccountSelectors.getIsAuthenticated)
  const { t } = useTranslation(["results"])
  return !isAuthenticated ? (
    <ResultCard>
      <ReportItem
        heading={titleCaps(t("sign_up.heading", ""))}
        subHeading={t("sign_up.sub_heading", "")}
      >
        <Paragraph fontSize={3} color="dark.2">
          {t("sign_up.paragraph", "")}
        </Paragraph>
        <SignUpBenefits />
        <SignUpPrompt />
      </ReportItem>
    </ResultCard>
  ) : null
}

export default SignUpWidget
