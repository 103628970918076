export const types = {
  LOAD: "[Job Finder] Load",
  LOAD_SUCCESS: "[Job Finder] Load Success",
  LOAD_FAIL: "[Job Finder] Load Fail",
  LOAD_MORE: "[Job Finder] Load More",
  LOAD_MORE_SUCCESS: "[Job Finder] Load More Success",
  LOAD_MORE_FAIL: "[Job Finder] Load More Fail",

  FILTER: "[Job Finder] Filter Vacancies",
  CLEAR_FILTERS: "[Job Finder] Clear Filters",

  LOAD_FILTERS: "[Job Finder] Load Filters",
  LOAD_FILTERS_SUCCESS: "[Job Finder] Load Filters Success",
  LOAD_FILTERS_FAIL: "[Job Finder] Load Filters Fail",

  UPDATE_PROGRESS_STATE: "[Job Finder] Set Progress bar progress",

  JOB_SELECT: "[Job Finder] Select Job",

  LOAD_JOB: "[Job Finder] Load Job by Id",
  LOAD_JOB_SUCCESS: "[Job Finder] Load Job by Id Success",
  LOAD_JOB_FAIL: "[Job Finder] Load Job by Id Fail",

  LOAD_JOBS_RANKINGS: "[Job Finder] Load Job Rankings",
  LOAD_JOBS_RANKINGS_SUCCESS: "[Job Finder] Load Job Rankings Success",
  LOAD_JOBS_RANKINGS_FAIL: "[Job Finder] Load Job Rankings Fail",

  LOAD_JOBS_RANKINGS_MORE: "[Job Finder] Load Job Rankings More",
  LOAD_JOBS_RANKINGS_MORE_SUCCESS: "[Job Finder] Load Job Rankings More Success",
  LOAD_JOBS_RANKINGS_MORE_FAIL: "[Job Finder] Load Job Rankings More Fail"
}

export const load = payload => ({
  type: types.LOAD,
  payload
})
export const loadSuccess = jobs => ({
  type: types.LOAD_SUCCESS,
  payload: jobs
})
export const loadFail = () => ({
  type: types.LOAD_FAIL
})

export const loadMore = payload => ({
  type: types.LOAD_MORE,
  payload
})
export const loadMoreSuccess = payload => ({
  type: types.LOAD_MORE_SUCCESS,
  payload
})
export const loadMoreFail = payload => ({
  type: types.LOAD_MORE_FAIL,
  payload
})

export const filterJobs = filters => ({
  type: types.FILTER,
  payload: filters
})
export const loadFilters = payload => ({
  type: types.LOAD_FILTERS,
  payload
})
export const loadFiltersSuccess = jobs => ({
  type: types.LOAD_FILTERS_SUCCESS,
  payload: jobs
})
export const loadFiltersFail = () => ({
  type: types.LOAD_FILTERS_FAIL
})

export const clearFilters = () => ({
  type: types.CLEAR_FILTERS
})

export const updateProgressState = payload => ({
  type: types.UPDATE_PROGRESS_STATE,
  payload
})

export const selectJob = payload => ({
  type: types.JOB_SELECT,
  payload
})

export const loadJob = payload => ({
  type: types.LOAD_JOB,
  payload
})
export const loadJobSuccess = jobs => ({
  type: types.LOAD_JOB_SUCCESS,
  payload: jobs
})
export const loadJobFail = () => ({
  type: types.LOAD_JOB_FAIL
})

export const loadJobRankings = payload => ({
  type: types.LOAD_JOBS_RANKINGS,
  payload
})
export const loadJobRankingsSuccess = payload => ({
  type: types.LOAD_JOBS_RANKINGS_SUCCESS,
  payload
})
export const loadJobRankingsFail = () => ({
  type: types.LOAD_JOBS_RANKINGS_FAIL
})

export const loadJobRankingsMore = payload => ({
  type: types.LOAD_JOBS_RANKINGS_MORE,
  payload
})
export const loadJobRankingsMoreSuccess = payload => ({
  type: types.LOAD_JOBS_RANKINGS_MORE_SUCCESS,
  payload
})
export const loadJobRankingsMoreFail = () => ({
  type: types.LOAD_JOBS_RANKINGS_MORE_FAIL
})
