import { connect } from "react-redux"

import JobFinderWidgetComponent from "../components/JobFinderWidget"

import { getIsPremium } from "../../../yourProfile/store/yourProfile.selectors"

const mapState = state => ({
  isPremium: getIsPremium(state)
})

const JobFinderWidget = connect(mapState)(JobFinderWidgetComponent)

export default JobFinderWidget
