import { gql } from "../../../../lib/Apollo"

export const FETCH_SURVEY_FOR_SAML_USER = gql`
  query {
    fetchSurveyForSamlUser {
      surveyId
      surveyName
      code
    }
  }
`

export const HANDLE_SAML_USER_PROFILE = gql`
  mutation handleSamlUserProfile {
    handleSamlUserProfile {
      id
      firstName
      lastName
      email
      confirmed
      premium {
        active
        expireDate
        cancelled
      }
      jobTitle
      companyName
      location {
        name
        country {
          id
          name
          code
        }
        coordinates {
          lat
          lng
        }
      }
      websiteUrl
      currentGoal
      employmentStatus
      picture {
        small
        large
      }
      goalsViewed
      goals {
        id
        key
        text
      }
      jobMatcher {
        currentJobRole
        currentJobSalary
        desiredJobRoles
        desiredJobType
        desiredJobSalary {
          min
          max
          private
        }
        desiredJobLocation {
          name
          country {
            id
            name
            code
          }
          coordinates {
            lat
            lng
          }
        }
      }
      skills {
        id
        name
      }
      positionsHeld {
        jobTitle
        location {
          name
          country {
            id
            name
            code
          }
          coordinates {
            lat
            lng
          }
        }
        company
        startDate
        endDate
        description
      }
      qualifications {
        institution
        courseTitle
        location {
          name
          country {
            id
            name
            code
          }
          coordinates {
            lat
            lng
          }
        }
        description
        start
        end
      }
      personalStatement
      age
      gender
      industries {
        id
        name
      }
      hobbies {
        id
        name
      }
      languages {
        code
        name
        nativeName
      }
      services {
        jobMatcher
        mentorMatcher
        menteeMatcher
      }
    }
  }
`
