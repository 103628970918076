import { getConfig } from "@engaging-tech/ssr-config"

import { apollo } from "../../../lib/Apollo"
import { apolloInAppView } from "../../../lib/ApolloInAppView"
import { CREATE_CV, DELETE_CV, LOAD_MY_CVS, LOAD_SELECTED_CV, SET_DEFAULT_CV } from "./cvLibrary.queries"

const GRAPHQL_API_URL = () => `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

export const loadCVs = ({ place = "" }) => {
  if (place && place === "in-app-view") {
    return apolloInAppView.query({
      uri: GRAPHQL_API_URL(),
      query: LOAD_MY_CVS
    })
  }

  return apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD_MY_CVS
  })
}

export const loadSelectedCV = ({ id, place = "" }) => {
  if (place === "in-app-view") {
    return apolloInAppView.query({
      uri: GRAPHQL_API_URL(),
      query: LOAD_SELECTED_CV,
      variables: { id }
    })
  }

  return apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD_SELECTED_CV,
    variables: { id }
  })
}

// export const updateCV = ({ id, defaultCv }) =>
//   apollo.mutate({
//     uri: GRAPHQL_API_URL,
//     mutation: UPDATE_CV,
//     variables: {
//       id,
//       defaultCv
//     }
//   })

export const deleteCV = ({ id, place = "" }) => {
  if (place === "in-app-view") {
    return apolloInAppView.mutate({
      uri: GRAPHQL_API_URL,
      mutation: DELETE_CV,
      variables: {
        id
      }
    })
  }

  return apollo.mutate({
    uri: GRAPHQL_API_URL,
    mutation: DELETE_CV,
    variables: {
      id
    }
  })
}

export const setDefaultCV = ({ id, place = "" }) => {
  if (place === "in-app-view") {
    return apolloInAppView.mutate({
      uri: GRAPHQL_API_URL,
      mutation: SET_DEFAULT_CV,
      variables: {
        id
      }
    })
  }

  return apollo.mutate({
    uri: GRAPHQL_API_URL,
    mutation: SET_DEFAULT_CV,
    variables: {
      id
    }
  })
}

export const createCV = ({ dataToSend, place = "" }) => {
  if (place === "in-app-view") {
    return apolloInAppView.mutate({
      uri: GRAPHQL_API_URL,
      mutation: CREATE_CV,
      variables: dataToSend
    })
  }

  return apollo.mutate({
    uri: GRAPHQL_API_URL,
    mutation: CREATE_CV,
    variables: dataToSend
  })
}
