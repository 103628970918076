import { Utils } from "@engaging-tech/components"

export const buildQuestionMap = questions =>
  questions.reduce((map, question) => {
    return { ...map, [question.id]: question }
  }, {})

export const parseSurveyResponse = response => {
  const {
    id,
    meta,
    template,
    languages,
    title,
    type,
    introduction,
    status,
    features
  } = response

  return {
    status,
    surveyId: id,
    meta,
    title,
    type,
    introduction,
    template,
    questions: buildQuestionMap(template.questions),
    languages,
    language: languages[0].code,
    features
  }
}

export const generateBrandTheme = colour =>
  Utils.createTheme({
    colours: Utils.createColourPalette(colour),
    fonts: { sansSerif: "Centra", serif: "Tiempos" }
  })

export const formatLanguages = languages =>
  languages.reduce((acc, language) => {
    if (!language) return acc
    if (language.code === "en") {
      return [language, ...acc]
    }
    return [...acc, language]
  }, [])

export const castAnswersToString = answers =>
  answers.map(answer => ({
    questionId: answer.questionId,
    values: answer.value.map(val => `${val}`)
  }))
