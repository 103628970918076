import { Box, Button, Flex, Text } from "@engaging-tech/components"
import styled from "styled-components"

export const JobMatcherAccessContainer = styled(Flex)`
  min-width: 328px;
  max-width: 387px;
  width: 100%;
  margin: 20px 0 0;
  background-color: #f5f5f5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  flex-direction: column;
  @media (max-width: 1080px) {
    background-color: #f5f5f5 !important;
  }
  @media (max-width: 480px) {
    min-height: 176px;
    height: 100%;
    min-width: unset;
    max-width: unset;
    width: 328px;
  }
`

export const JobMatcherAccessTitleContainer = styled(Flex)`
  padding: 10px 20px 0;
  flex-direction: column;
`

export const JobMatcherAccessTitle = styled(Text)`
  width: auto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  margin: 0;
  color: #003e4c;
`

export const JobMatcherAccessMessage = styled(Text)`
  width: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 8px 0 0;
  color: #003e4c;
`

export const JobMatcherAccessButtonWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0;
`

export const JobMatcherAccessButton = styled(Button)`
  margin-top: 14px;
  background: #003e4c;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`
