import { Card, Flex, Icon, Spinner, Text } from "@engaging-tech/components"
import { useLocation } from "@engaging-tech/routing"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { notificationsActions, notificationsSelectors } from "../../../../Notifications"
import PremiumModal from "../../../../Notifications/components/PremiumModal"
import { MiscSelectors } from "../../../../misc"
import { getIsPremium } from "../../../../yourProfile/store/yourProfile.selectors"
import { getFilterValueById } from "../../../store/jobFinder.selectors"
import { FREQUENCIES } from "./frequencies"
import { CreateJobAlertButton, PremiumBanner } from "./jobAlerts.styled"
import SelectFilter from "./selectFilter"

export default function JobAlerts() {
  const location = useLocation()

  const [selectedFrequency, setSelectedFrequency] = useState(FREQUENCIES[0])
  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false)

  const optionSearchParams = new URLSearchParams(location.search)
  const locationFilter = optionSearchParams.get("location")
  const countryCodeFilter = optionSearchParams.get("country_code")

  const isPremium = useSelector(getIsPremium)
  const locationDetailsFilter = useSelector(getFilterValueById("locationDetails"))
  const isLoading = useSelector(notificationsSelectors.getIsLoading)
  const allCountries = useSelector(MiscSelectors.getAllCountries)

  const dispatch = useDispatch()

  const handleSubmit = () => {
    const countrySelected = allCountries.find(c => c.code === countryCodeFilter)

    dispatch(
      notificationsActions.createJobNotification({
        frequency: selectedFrequency,
        location: {
          point: { coordinates: [] },
          name: locationFilter,
          countryId: countrySelected.id,
          country: {
            shortName: countryCodeFilter,
            name: countrySelected.name
          }
        }
      })
    )
  }

  return (
    <Card minWidth={[0, 1070]} width={1 / 1} mb={3}>
      {!isPremium ? (
        <PremiumBanner bg="primary.0">
          <Icon name="stars" color="yellow.0" />
          <Text fontSize={14} color="yellow.0" ml={2} fontWeight={700} width="auto">
            PREMIUM SERVICE
          </Text>
        </PremiumBanner>
      ) : null}
      <Flex
        p={3}
        width={1 / 1}
        flexDirection={["column", "row"]}
        justifyContent="space-between"
        alignContent="center"
        alignItems={["flex-start", "center"]}
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          width="auto"
          flexWrap="wrap"
          flexDirection={["column", "row", "row"]}
        >
          <Flex
            alignItems="center"
            flexDirection="row"
            width="auto"
            alignContent="center"
            justifyContent={["center", "flex-start", "flex-start"]}
          >
            <Text color="dark.2" mr={[0, 2]} fontSize={15}>
              Receive
            </Text>
            <SelectFilter
              items={FREQUENCIES}
              value={selectedFrequency}
              onFilter={value => setSelectedFrequency(value)}
            />
            <Text color="dark.2" ml={[0, 2]} mr={[0, 3]} fontSize={15}>
              emails alert for
            </Text>
          </Flex>
          <Text color="dark.2" width="auto" fontWeight={700} mt={[2, 0, 0]}>
            {locationDetailsFilter?.name}
          </Text>
        </Flex>
        <Flex width="auto">
          {isLoading ? <Spinner color="primary.0" size={30} mr={4} /> : null}
          <CreateJobAlertButton
            color="white"
            elevation={0}
            disabled={isLoading}
            onClick={() => {
              if (isPremium) {
                handleSubmit()
              } else {
                setAlertModalIsOpen(true)
              }
            }}
          >
            Create Job Alert
          </CreateJobAlertButton>
        </Flex>
      </Flex>

      {alertModalIsOpen && <PremiumModal type="job" toggle={() => setAlertModalIsOpen(!alertModalIsOpen)} />}
    </Card>
  )
}
