import React from "react"
import { Flex } from "@engaging-tech/components"

import BrowseFilters from "../../containers/BrowseFilters"
import LibrarySearch from "../../containers/LibrarySearch"
import libraryCategories from "../../../userGeneratedContent/lib/categories"

const SearchBar = () => (
  <Flex
    px={[3, 3, 4]}
    pt={[4, 2]}
    height="auto"
    flexDirection={["column", "column", "row"]}
    justifyContent={["flex-start", "flex-start", "space-between"]}
    alignItems={["flex-start", "flex-start", "center"]}
  >
    <LibrarySearch />
    <BrowseFilters categories={libraryCategories} mt={[3, 3, 0]} width={200} />
  </Flex>
)

export default SearchBar
