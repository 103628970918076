import React from "react"
import { Card, Text } from "@engaging-tech/components"

const NoResults = ({ type, isPlural }) => (
  <Card bg="secondary.4" p={3} mb={3} flexDirection="column" width={1 / 1}>
    <Text fontWeight="500" mb={2}>
      We couldn&apos;t find any {type}
      {isPlural && "s"} matching your search criteria.
    </Text>
    <Text fontSize={3} color="dark.2">
      However, we might have something below that could be of interest!
    </Text>
  </Card>
)

export default NoResults
