import { connect } from "react-redux"
import {
  notificationsSelectors,
  notificationsActions
} from "../../../Notifications"
import AlertsListComponent from "../components/AlertsList"

const mapState = state => ({
  type: "Business Library",
  alerts: notificationsSelectors.getMaterialsAlerts(state)
})

const mapDispatch = dispatch => ({
  setActiveAlert: id => dispatch(notificationsActions.setActiveAlert(id))
})

const BusinessLibraryAlerts = connect(
  mapState,
  mapDispatch
)(AlertsListComponent)

export default BusinessLibraryAlerts
