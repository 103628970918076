import React from "react"

import ArticleContent from "./ArticleContent"
import PodcastContent from "./PodcastContent"
import ResearchContent from "./ResearchContent"
import LectureContent from "./LectureContent"

import { libraryPostTypes } from "../../../lib/postTypes"

const LibraryContent = ({ post, ...props }) => {
  switch (post.type) {
    case libraryPostTypes.articles:
      return <ArticleContent article={post} {...props} />

    case libraryPostTypes.podcasts:
      return <PodcastContent podcast={post} {...props} />

    case libraryPostTypes.research:
      return <ResearchContent research={post} {...props} />

    case libraryPostTypes.lectures:
      return <LectureContent lecture={post} {...props} />

    default:
      return null
  }
}

export default LibraryContent
