/* eslint-disable react/no-array-index-key,no-nested-ternary */
import { Spinner } from "@engaging-tech/components"
import { useLocation, usePaths, useRouter } from "@engaging-tech/routing"
import React, { Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"

import PrimaryButton from "../../../../ui/components/PrimaryButton"
import { getIsPremium, getJobMatcherStatus } from "../../../../yourProfile/store/yourProfile.selectors"
import { loadMore } from "../../../store/jobFinder.actions"
import { getIsLoading, getIsLoadingMore, getJobs, getJobsNextToken } from "../../../store/jobFinder.selectors"
import JobListItem from "./jobListItem"
import {
  JobMatcherAccessButton,
  JobMatcherAccessButtonWrapper,
  JobMatcherAccessContainer,
  JobMatcherAccessMessage,
  JobMatcherAccessTitle,
  JobMatcherAccessTitleContainer
} from "./jobMatcherAccess.styled"
import { LoadMoreWrapper } from "./jobsList.styled"
import NoResults from "./noResults"

const JobsList = () => {
  const location = useLocation()
  const path = usePaths()
  const router = useRouter()

  const dispatch = useDispatch()

  const jobs = useSelector(getJobs)
  const isLoading = useSelector(getIsLoading)
  const isLoadingMore = useSelector(getIsLoadingMore)
  const hasNextToken = useSelector(getJobsNextToken)
  const userIsPremium = useSelector(getIsPremium)
  const jobMatcherIsActive = useSelector(getJobMatcherStatus)

  const optionSearchParams = new URLSearchParams(location.search)
  const companyNameFilter = optionSearchParams.get("company")
  const searchTermFilter = optionSearchParams.get("search_term")
  const experienceFilter = optionSearchParams.get("experience")
  const positionFilter = optionSearchParams.get("position")
  const jobTypeFilter = optionSearchParams.get("job_type")
  const industryFilter = optionSearchParams.get("industry")
  const salaryFilter = optionSearchParams.get("salary")
  const salaryMinFilter = optionSearchParams.get("salary_min")
  const datePostedFilter = optionSearchParams.get("date_posted")
  const salaryNumberFilter = salaryFilter
    ? parseInt(salaryFilter.replace("£", "").replace("+", ""), 10)
    : null
  const locationFilter = optionSearchParams.get("location")
  const locationTypeFilter = optionSearchParams.get("location_type")
  const countryCodeFilter = optionSearchParams.get("country_code")
  const demographicFilters = optionSearchParams.get("demographic")

  const onLoadMore = () => {
    dispatch(
      loadMore([
        { name: "search_term", value: searchTermFilter },
        { name: "company_name", value: companyNameFilter },
        { name: "job_type", value: jobTypeFilter },
        { name: "salary", value: salaryNumberFilter ?? salaryMinFilter },
        { name: "experience", value: experienceFilter },
        { name: "position", value: positionFilter },
        { name: "industry", value: industryFilter },
        { name: "date_posted", value: datePostedFilter },
        {
          name: "location",
          value: locationTypeFilter === "locality" ? locationFilter.split(",")[0] : locationFilter
        },
        { name: "country_code", value: countryCodeFilter },
        { name: "demographicFilters", value: demographicFilters ? demographicFilters.split(",") : [] }
      ])
    )
  }

  const isLoadingUI = <Spinner color="primary.0" />

  const hasNoResultsUI = <NoResults />

  const jobMatcherAccessMessages = {
    message1: "Sit back and we'll match you with registered jobs.",
    message2:
      "Just fill in your Job Matcher profile and you'll be automatically shortlisted for suitable jobs."
  }

  const jobMatcherAccessUI = (
    <JobMatcherAccessContainer>
      <JobMatcherAccessTitleContainer>
        <JobMatcherAccessTitle>Job Matcher</JobMatcherAccessTitle>
        <JobMatcherAccessMessage>{jobMatcherAccessMessages.message1}</JobMatcherAccessMessage>
        <JobMatcherAccessMessage>{jobMatcherAccessMessages.message2}</JobMatcherAccessMessage>
      </JobMatcherAccessTitleContainer>
      <JobMatcherAccessButtonWrapper>
        {userIsPremium ? (
          <JobMatcherAccessButton
            color="white"
            onClick={() => router.navigate(path.careerDeveloper.jobMatcher.index)}
          >
            Get Matched
          </JobMatcherAccessButton>
        ) : (
          <JobMatcherAccessButton color="white" onClick={() => router.navigate(path.getPremium.index)}>
            Get Premium
          </JobMatcherAccessButton>
        )}
      </JobMatcherAccessButtonWrapper>
    </JobMatcherAccessContainer>
  )

  const jobsListUI = (
    <>
      {jobs?.map((job, index) => {
        if (index === 0) {
          return (
            <Fragment key={index}>
              <JobListItem job={job} index={index} />
              {jobMatcherIsActive ? jobMatcherAccessUI : null}
            </Fragment>
          )
        }

        return <JobListItem key={index} job={job} index={index} />
      })}
    </>
  )

  return (
    <>
      {isLoading ? isLoadingUI : jobs.length ? jobsListUI : hasNoResultsUI}

      <LoadMoreWrapper>
        {isLoadingMore ? (
          <Spinner mt={4} mb={4} py={3} color="primary.0" width={1 / 1} />
        ) : (
          <PrimaryButton
            onClick={() => {
              onLoadMore(jobs.length)
            }}
            width={300}
            disabled={!hasNextToken || isLoading || isLoadingMore}
            color="light.0"
            text={!hasNextToken ? "Loaded all jobs" : "Load more"}
          />
        )}
      </LoadMoreWrapper>
    </>
  )
}

export default JobsList
