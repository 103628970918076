const sixSteps = [
  {
    icon: "thumb_up",
    title: "01. Reward & Recognition",
    text:
      "Being fairly paid is a huge concern for everyone, and if you are not paying a fair salary no amount of recognition for a job well done will be enough to make your employees forget they are not being paid enough. Your pay scale has to meet expectations. Learn how good you are at Reward and Recognition with our Workplace Happiness Measuring questionnaire and see what else this chapter entails by @LINK@reading the book@LINK@.",
    placeholder: {
      key: "@LINK@",
      url: "/74424"
    },
    height: [420, 300, 300]
  },
  {
    icon: "info",
    title: "02. Information Sharing",
    height: [500, 330, 320],
    text:
      "Everybody in your organisation needs to have a realistic and well-sourced view of where they work. Not sharing information makes employees feel an unimportant part of the business. Engagement and commitment can be eroded by this. The closed-door approach doesn’t just have a negative impact on engagement, it can directly impact on decision-making and therefore profitability and success. How free is the information in your organisation? How do you compare to others around the world? Find out by taking the questionnaire today."
  },
  {
    icon: "account_circle",
    title: "03. Empowerment",
    height: [440, 320, 300],
    text:
      "Nobody is perfect but a team can be. The aim of any business must surely be to make their employees feel empowered and this means making them a key part of the decision-making process, listening to their ideas and integrating their suggestions to build and refine into your strategy. Our experiences inevitably bring us all to different solutions and ways of achieving them, but only by listening to all views can the bests outcomes be reached."
  },
  {
    icon: "favorite",
    title: "04. Wellbeing",
    height: [400, 290, 280],
    text:
      "There is a growing body of evidence to support the idea that wellbeing is an essential aspect of employee engagement. It leads to improved production, lower rates of absence and stress, and higher levels of motivation. In other words, employee health and wellbeing has become a hard economic factor. Measure how well your organisational wellbeing compares to others."
  },
  {
    icon: "stars",
    title: "05. Instilling Pride",
    height: [600, 380, 370],
    text:
      "Have you ever heard any of your employees respond to ‘where do you work'? Employees who love what they do and feel proud of where they work will speak openly and positively about it to colleagues, potential employees, customers and people in their community. Instilling such pride is not just about stirring speeches, sharing growth figures, or saying a few well-placed thank yous. It centres on having a purpose and helping everyone see that what they do each day is worthwhile. A big part of that is to do with how your business interacts with the wider world. People want to work for an organisation that cares about how it impacts on society. How proud are your employees to work in your organisation?"
  },
  {
    icon: "briefcase",
    title: "06. Job Satisfaction",
    height: [430, 290, 280],
    text:
      "We have nothing of greater value than our people. High levels of employee engagement is the key to unlock organisational success. Happy employees equal a solid, successful and long-lasting business. But what makes people tick on a personal level? In other words, what makes workers happy and satisfied at work? More importantly, what can we, as business leaders, do about it?"
  }
]

export default sixSteps
