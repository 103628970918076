export const types = {
  LOAD: "[Business Profile Information] Load",
  LOAD_SUCCESS: "[Business Profile Information] Load Success",
  LOAD_FAIL: "[Business Profile Information] Load Fail",
  LOAD_DUNNS_DATA: "[Business Profile Information] Load DB Data",
  LOAD_DUNNS_DATA_SUCCESS: "[Business Profile Information] Load DB Data Success",
  LOAD_DUNNS_DATA_FAIL: "[Business Profile Information] Load DB Data Fail"
}

export const load = payload => ({
  type: types.LOAD,
  payload
})

export const loadSuccess = payload => ({
  type: types.LOAD_SUCCESS,
  payload
})

export const loadFail = () => ({
  type: types.LOAD_FAIL
})

export const loadDunnsData = payload => ({
  type: types.LOAD_DUNNS_DATA,
  payload
})

export const loadDunnsDataSuccess = payload => ({
  type: types.LOAD_DUNNS_DATA_SUCCESS,
  payload
})

export const loadDunnsDataFail = () => ({
  type: types.LOAD_DUNNS_DATA_FAIL
})
