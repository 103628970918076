import { PercentageBar } from "@engaging-tech/charts"
import { Flex, Text } from "@engaging-tech/components"
import { useParams } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import CircleIcon from "../../../ui/components/CircleIcon"
import {
  toEmail,
  toFacebook,
  toLinkedIn,
  toTwitter
} from "../../../userGeneratedContent/services/sharing.service"
import ReportItem from "../../components/ReportItem"

const MediaIcon = styled(CircleIcon)`
  width: 40px;
  height: 40px;
  margin-left: 16px;
  background-color: ${({ theme }) => theme.colors.dark[6]};
  &:hover {
    cursor: pointer;
  }
`

const icons = [
  {
    name: "facebook",
    url: toFacebook, // facebook doesnt do prepopultaion via url
    subject: ""
  },
  {
    name: "linkedin",
    url: toLinkedIn,
    subject: ""
  }, // linkedin doesnt do prepopulation via url
  {
    name: "twitter",
    url: toTwitter,
    subject: ""
  },
  {
    name: "markunread",
    url: toEmail,
    subject: "My Survey Results"
  }
]

const HappinessComparisonChart = ({ score, industryScore, globalScore, type, sharable = true }) => {
  const params = useParams()
  const config = getConfig()
  const { id } = params
  const { t } = useTranslation(["results"])

  const getSurveyResultsType = type === "organisation" ? "organisationSurvey" : "happiness-survey"
  const getDomainLink = config?.workL?.app?.index

  return (
    <ReportItem
      heading={t("happiness_comparison.heading", "")}
      subHeading={t("happiness_comparison.sub_heading", "")}
    >
      <Flex flexDirection="column">
        <PercentageBar
          data={[
            {
              x: `${t("happiness_comparison.you", "")}`,
              y: score
            },
            {
              x: `${t("happiness_comparison.industry", "")}`,
              y: industryScore
            },
            {
              x: `${t("happiness_comparison.global", "")}`,
              y: globalScore
            }
          ]}
          labels={[
            `${t("happiness_comparison.you", "")}`,
            `${t("happiness_comparison.industry", "")}`,
            `${t("happiness_comparison.global", "")}`
          ]}
        />
        {sharable && (
          <>
            <Flex width={1 / 1} justifyContent="center">
              <Text fontWeight={500} color="dark.2" mb={2} mt={-3} width={4 / 5} textAlign="center">
                {t("happiness_comparison.share", "")}
              </Text>
            </Flex>
            <Flex flexDirection="row" justifyContent="center">
              {icons.map(icon => (
                <MediaIcon
                  key={id + icon.name}
                  onClick={() =>
                    icon.url(
                      `${getDomainLink}/${getSurveyResultsType}/results/${id}`,
                      "I have taken the organisation survey and this is my results page: ",
                      icon.subject
                    )
                  }
                  name={icon.name}
                  size={38}
                />
              ))}
            </Flex>
          </>
        )}
      </Flex>
    </ReportItem>
  )
}

export default HappinessComparisonChart
