import { usePaths, useRouter } from "@engaging-tech/routing"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import Section from "../components/Section"
import CustomIntro from "../containers/CustomIntro"
import { QuestionProvider } from "../lib/QuestionContext"
import { SliderProvider } from "../lib/SliderContext"
import { getAllSections, getHasCustomIntro, getSurveyId } from "../store/happinessSurvey.selectors"

const Questions = ({ onComplete, onCancel }) => {
  const currentSurveyId = useSelector(getSurveyId)

  const allSections = useSelector(getAllSections)
  const hasIntro = useSelector(getHasCustomIntro)
  const [currentSection, setCurrentSection] = useState(0)

  const router = useRouter()
  const paths = usePaths()
  let sectionsList

  useEffect(() => {
    if (!currentSurveyId) {
      router.navigate(paths.happinessSurvey.index)
    }
  }, [currentSurveyId, router, paths])

  const onNextSection = () =>
    currentSection < sectionsList.length - 1 ? setCurrentSection(currentSection + 1) : onComplete()

  const onPreviousSection = () => {
    if (currentSection) {
      setCurrentSection(currentSection - 1)
    } else {
      onCancel()
    }
  }

  const generateSections = () => {
    let list = []

    // redux-persist will persist the answers if you move to a new route
    if (hasIntro) {
      list = [
        <CustomIntro
          onNext={onNextSection}
          onPrevious={() => {
            router.navigate(paths.happinessSurvey.language)
          }}
        />
      ]
    }

    list = [
      ...list,
      ...allSections.map(section => (
        <Section
          name={section.name}
          questions={section.questions}
          key={section.name}
          description={section.description}
        />
      ))
    ]

    return list
  }

  sectionsList = allSections ? generateSections() : []

  return (
    <QuestionProvider
      onNextSection={onNextSection}
      onPreviousSection={onPreviousSection}
      currentSection={currentSection}
      sectionsList={sectionsList}
    >
      <SliderProvider>{sectionsList[currentSection]}</SliderProvider>
    </QuestionProvider>
  )
}

export default Questions
