import React from "react"
import styled from "styled-components"
import { hideVisually } from "polished"
import { getOrientation } from "get-orientation/browser"

import { Text, Flex, Icon, Chip, Utils } from "@engaging-tech/components"

const HiddenInput = styled.input.attrs({ type: "file" })`
  ${hideVisually()}
`
const readFile = file => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.addEventListener("load", () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

const HoverIcon = styled(Icon)`
  cursor: pointer;
`
const HoverText = styled(Text)`
  cursor: pointer;
`

const ORIENTATION_TO_ANGLE = {
  "3": 180,
  "6": 90,
  "8": -90
}

const FilePicker = ({
  setImageToCrop,
  setImage,
  image,
  imageName,
  onCancel
}) => {
  const onFileChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]

      let imageDataUrl = await readFile(file)

      // apply rotation if needed
      const orientation = await getOrientation(file)
      const rotation = ORIENTATION_TO_ANGLE[orientation]
      if (rotation) {
        imageDataUrl = await Utils.canvasHelpers.getRotatedImage(
          imageDataUrl,
          rotation
        )
      }

      setImageToCrop(imageDataUrl, file.name)
    }
  }
  return image ? (
    <Chip
      mr={[3, 3, 2]}
      isActive
      trailingIcon="close"
      onClick={() => {
        setImage(null)
        onCancel()
      }}
    >
      {imageName}
    </Chip>
  ) : (
    <Flex
      as="label"
      htmlFor="upload-image"
      onChange={e => {
        onFileChange(e)
      }}
      alignItems="center"
    >
      <HiddenInput id="upload-image" accept="image/jpeg,image/png" />
      <Flex width={1 / 1} justifyContent="center" alignItems="center">
        <Flex width="auto">
          <HoverIcon color="primary.0" mt={-1} name="edit" />
          <HoverText color="primary.0" ml={2} mb={4}>
            Upload a new photo
          </HoverText>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default FilePicker
