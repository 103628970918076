import { useEffect } from "react"
import { connect } from "react-redux"

import { happinessSurveySelectors, happinessSurveyActions } from "../index"

const SubmitterComponent = ({
  attachUser,
  hasAttachedUser,
  surveySubmissionId
}) => {
  useEffect(() => {
    if (!hasAttachedUser && surveySubmissionId) {
      attachUser(surveySubmissionId)
    }
  }, [attachUser, hasAttachedUser, surveySubmissionId])

  return null
}

const mapState = state => ({
  hasAttachedUser: happinessSurveySelectors.getHasAttachedUser(state),
  surveySubmissionId: happinessSurveySelectors.getSubmissionId(state)
})

const mapDispatch = dispatch => ({
  attachUser: submissionId =>
    dispatch(happinessSurveyActions.attachUser(submissionId))
})

export default connect(mapState, mapDispatch)(SubmitterComponent)
