import React from "react"

import { libraryPostTypes } from "../../../features/userGeneratedContent/lib/postTypes"

import AllPosts from "../../../features/businessLibrary/containers/AllPosts"
import SideBar from "../../../features/businessLibrary/containers/SideBar"
import BrowseLibraryLayout from "../../../layouts/BrowseLibraryLayout"
import TopBar from "../../../features/businessLibrary/containers/TopBar"

const AllPodcasts = AllPosts(libraryPostTypes.podcasts)

const Podcasts = () => (
  <BrowseLibraryLayout
    sideColumn={SideBar(libraryPostTypes.podcasts)}
    topRow={TopBar(libraryPostTypes.podcasts)}
  >
    <AllPodcasts />
  </BrowseLibraryLayout>
)

export default Podcasts
