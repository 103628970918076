import { userInterfaceNotificationsStore } from "@engaging-tech/components"
import { useRouter } from "@engaging-tech/routing"
import { Formik } from "formik"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import AccountDialog from "../../../ui/components/AccountDialog"
import { sendMagicLinkService } from "../../services/account.service"
import { resetSendMagicLink, sendMagicLinkFail, sendMagicLinkSuccess } from "../../store/account.actions"
import { getIsLoading, isMagicLinkSent } from "../../store/account.selectors"
import formSchema from "./form-schema"
import MagicLinkFormContent from "./magicLinkFormContent"

export default function MagicLinkForm({ siteLogo, cancelRoute, redirectTo }) {
  const router = useRouter()

  const isLoading = useSelector(getIsLoading)
  const isMagicLinkSentSuccess = useSelector(isMagicLinkSent)

  const dispatch = useDispatch()

  const onSubmit = async values => {
    try {
      const result = await sendMagicLinkService(values)

      if (result?.data?.getMagicLink?.statusCode === 200) {
        dispatch(sendMagicLinkSuccess(result))
        dispatch(
          userInterfaceNotificationsStore.actions.addNotification({
            message: "Magic link sent over email successfully"
          })
        )
      } else {
        dispatch(
          userInterfaceNotificationsStore.actions.addNotification({
            message: "Unable to send magic link over email"
          })
        )
      }
    } catch (error) {
      dispatch(sendMagicLinkFail(error))
      dispatch(
        userInterfaceNotificationsStore.actions.addNotification({
          message: "Unable to send magic link over email"
        })
      )
    }
  }

  useEffect(() => {
    if (isMagicLinkSentSuccess) {
      dispatch(resetSendMagicLink())
      router.redirect(redirectTo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMagicLinkSentSuccess])

  const renderFormik = () => {
    return (
      <Formik
        initialValues={{
          email: ""
        }}
        validationSchema={formSchema}
        onSubmit={onSubmit}
      >
        {props => (
          <MagicLinkFormContent
            siteLogo={siteLogo}
            isLoading={isLoading}
            cancelRoute={cancelRoute}
            {...props}
          />
        )}
      </Formik>
    )
  }

  return (
    <AccountDialog title="Login using Magic Link?" subtitle="Login without password" renderLogo={siteLogo}>
      {renderFormik()}
    </AccountDialog>
  )
}
