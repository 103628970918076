/* eslint-disable import/no-named-as-default-member */
import { connect } from "react-redux"
import ProfileWidgetComponent from "../components/ProfileWidget"
import { setShowModal } from "../../../yourProfile/store/yourProfile.actions"
import {
  getJobTitle,
  getCompany,
  getFirstName,
  getLastName,
  getShowModal,
  getProfilePictureUrl,
  getIsPremium
} from "../../../yourProfile/store/yourProfile.selectors"

import { getProfileCompletionPercentage } from "../../store/developCareer.selectors"

const mapState = state => ({
  isPremium: getIsPremium(state),
  jobTitle: getJobTitle(state),
  company: getCompany(state),
  firstName: getFirstName(state),
  lastName: getLastName(state),
  showModal: getShowModal(state),
  picture: getProfilePictureUrl(state),
  profileCompletionPercentage: getProfileCompletionPercentage(state)
})

const mapDispatch = dispatch => ({
  setShowModal: type => dispatch(setShowModal(type))
})

const ProfileWidget = connect(mapState, mapDispatch)(ProfileWidgetComponent)

export default ProfileWidget
