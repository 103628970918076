import { getConfig } from "@engaging-tech/ssr-config"

import { apollo } from "../../../lib/Apollo"
import { DELETE_JOB_MATCH, LOAD_JOB_MATCHES, UPDATE_JOB_MATCHER_PROFILE } from "./jobMatcher.queries"

const GRAPHQL_API_URL = () => `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

export const loadJobMatches = () =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD_JOB_MATCHES
  })

export const deleteJobMatch = ({ jobMatchId }) =>
  apollo.mutate({
    uri: GRAPHQL_API_URL,
    mutation: DELETE_JOB_MATCH,
    variables: {
      jobMatchedId: jobMatchId
    }
  })

export const updateJobMatcherProfile = variables => {
  return apollo.mutate({
    uri: GRAPHQL_API_URL,
    mutation: UPDATE_JOB_MATCHER_PROFILE,
    variables: {
      currentJobRole: variables?.currentJobRole,
      currentJobSalary: variables?.currentJobSalary,
      desiredJobRoles: variables?.desiredJobRoles,
      desiredJobType: variables?.desiredJobType,
      desiredJobSalary: {
        min: variables?.desiredJobSalary?.min,
        max: variables?.desiredJobSalary?.max
      },
      desiredJobLocation: {
        name: variables?.desiredJobLocation?.name,
        countryId: variables?.desiredJobLocation?.countryId,
        point: {
          coordinates: [
            variables?.desiredJobLocation?.point?.coordinates[0],
            variables?.desiredJobLocation?.point?.coordinates[1]
          ]
        },
        country: {
          name: variables?.desiredJobLocation?.country?.name,
          shortName: variables?.desiredJobLocation?.country?.shortName
        },
        vicinity: variables?.desiredJobLocation.vicinity
      }
    }
  })
}
