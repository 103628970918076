import { useEffect } from "react"
import { connect } from "react-redux"
import {
  getAllHobbies,
  getHasLoaded,
  getIsLoading
} from "../store/misc.selectors"
import { loadHobbies } from "../store/misc.actions"

const HobbiesLoaderComponent = ({ allHobbies, load, loading, hasLoaded }) => {
  useEffect(() => {
    if (loading) return
    if (hasLoaded && !allHobbies.length) {
      setTimeout(loadHobbies, 1000)
    } else if (!allHobbies.length) {
      load()
    }
  }, [allHobbies, hasLoaded, load, loading])

  return null
}

const mapState = state => ({
  allHobbies: getAllHobbies(state),
  loading: getIsLoading(state, "Hobbies"),
  hasLoaded: getHasLoaded(state, "Hobbies")
})

const mapDispatch = dispatch => ({
  load: () => dispatch(loadHobbies())
})

const HobbiesLoader = connect(mapState, mapDispatch)(HobbiesLoaderComponent)

export default HobbiesLoader
