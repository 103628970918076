import { Flex, Text } from "@engaging-tech/components"
import { format } from "date-fns"
import React from "react"
import { useDispatch } from "react-redux"

import { toggleServices } from "../../../../../../yourProfile/store/yourProfile.actions"
import DynamicState from "../../../../../containers/DynamicState"
import { EllipisText } from "./jobMatcherOnView.styled"

const searchingImage = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/career-developer/widget-images/job-matcher-in-progress-premium-no-bg.svg`)

const JobMatcherOnView = ({ matches, id }) => {
  const dispatch = useDispatch()

  const jobMatcherTurnOff = () => dispatch(toggleServices({ jobMatcher: "OFF" }))

  return (
    <DynamicState
      id={id}
      removable
      outlineBtn2
      title="Job Matcher"
      subTitle={
        matches && matches.length > 0
          ? null
          : "Our Job Matching service is taking your preferences and skills into account. Please hold tight and check back in a few days for your results."
      }
      image={matches && matches.length > 0 ? null : searchingImage}
      btnText2={matches.length > 0 ? "Turn off job matcher" : "Cancel Job Matcher"}
      onClick2={() => jobMatcherTurnOff()}
      btnText={matches && matches.length > 0 ? "View all matches" : null}
      linkTo="/develop-career/job-matcher"
    >
      {matches && matches.length > 0 && (
        <Flex mt={2} style={{ overflowY: "auto" }} height="100%" flexDirection="column">
          {matches.slice(0, 3).map(match => (
            <Flex p={3} key={match.id} bg="dark.6" my={1} alignItems="center" justifyContent="space-between">
              <EllipisText fontWeight={600} fontSize={4} mr={2}>
                {match.job.organisation.name}
              </EllipisText>
              <Flex width="190%" maxWidth={280} alignItems="center">
                <Text fontSize0={4}>{format(new Date(match.job.postedDate), "dd/MM/yyyy")}</Text>
                <Text fontSize={5} style={{ whiteSpace: "noWrap" }}>
                  <strong>{match.job.match}%</strong> Match
                </Text>
              </Flex>
            </Flex>
          ))}
        </Flex>
      )}
    </DynamicState>
  )
}

export default JobMatcherOnView
