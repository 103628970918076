import React from "react"
import { Text, Paragraph } from "@engaging-tech/components"

const MarkPriceQuote = ({ quote }) => (
  <>
    <Text
      mb={3}
      width="auto"
      fontSize={5}
      fontFamily="tiempos"
      textAlign="center"
    >
      “{quote}”
    </Text>
    <Paragraph
      textAlign="center"
      fontWeight={300}
      mb={5}
    >
      - Lord Mark Price, former Managing Director of Waitrose
    </Paragraph>
  </>
)

export default MarkPriceQuote
