import { getConfig } from "@engaging-tech/ssr-config"
import { apollo } from "../../../../lib/Apollo"
import {
  FETCH_SURVEY_FOR_SAML_USER,
  HANDLE_SAML_USER_PROFILE
} from "./saml.queries"

const GRAPHQL_API_URL = () =>
  `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

export const fetchSurveyForSamlUser = () =>
  apollo.query({
    uri: GRAPHQL_API_URL,
    query: FETCH_SURVEY_FOR_SAML_USER
  })

export const handleSamlSignIn = () =>
  apollo.mutate({ uri: GRAPHQL_API_URL, mutation: HANDLE_SAML_USER_PROFILE })
