import { useEffect } from "react"
import { connect } from "react-redux"

import { mentorMatcherActions, mentorMatcherSelectors } from ".."
import { yourProfileSelectors } from "../../yourProfile"
import { load } from "../../yourProfile/store/yourProfile.actions"

const Loader = ({ onLoad, isLoading, hasLoaded, userId, onLoadServices }) => {
  useEffect(() => {
    if (userId) onLoadServices(userId)
    if (isLoading || hasLoaded) return
    if (!isLoading && !hasLoaded && userId) {
      onLoad(userId)
    }
  }, [hasLoaded, isLoading, onLoad, onLoadServices, userId])

  return null
}

const mapState = state => ({
  isLoading: mentorMatcherSelectors.getIsLoading(state),
  hasLoaded: mentorMatcherSelectors.getHasLoaded(state),
  userId: yourProfileSelectors.getUserId(state)
})

const mapDispatch = dispatch => ({
  onLoad: userId => dispatch(mentorMatcherActions.load(userId)),
  onLoadServices: userId => dispatch(load({ userId }))
})

const DiscoverLoader = connect(mapState, mapDispatch)(Loader)

export default DiscoverLoader
