import { connect } from "react-redux"
import ProfileImageComponent from "../components/ProfileImage"
import {
  getProfilePictureUrl,
  getUploadingImageError
} from "../../../yourProfile/store/yourProfile.selectors"
import { uploadImage } from "../../../yourProfile/store/yourProfile.actions"

const mapState = state => ({
  picture: getProfilePictureUrl(state),
  uploadingImageError: getUploadingImageError(state)
})

const mapDispatch = dispatch => ({
  onImageSelect: image => dispatch(uploadImage(image))
})

const ProfileImage = connect(mapState, mapDispatch)(ProfileImageComponent)

export default ProfileImage
