import { usePaths } from "@engaging-tech/routing"
import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"

import { CopyContentIcon, WhatsAppIcon } from "../../../../../../../assets/icons"
import { getJobSelected } from "../../../../../store/jobFinder.selectors"
import {
  JobShareIcon,
  JobShareParagraph,
  JobSharePopover,
  ShareIconButton,
  ShareViaContainer,
  ShareViaLogoContainer
} from "./jobShare.styled"

const JobShare = () => {
  const popoverRef = useRef()
  const paths = usePaths()

  const [popoverIsOpen, setPopoverIsOpen] = useState(false)

  const jobSelected = useSelector(getJobSelected)

  useEffect(() => {
    const handleClickOutside = event => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setPopoverIsOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const shareViaUI = (
    <ShareViaContainer>
      <JobShareParagraph>Share via</JobShareParagraph>
      <ShareViaLogoContainer>
        <ShareIconButton
          type="button"
          onClick={() => {
            setPopoverIsOpen(false)
            window.open(
              // eslint-disable-next-line no-restricted-globals
              `https://wa.me/?text=${location.href}/jobs/${jobSelected.job_id}`,
              "_blank",
              "noreferrer"
            )
          }}
        >
          <WhatsAppIcon />
        </ShareIconButton>
        <ShareIconButton
          type="button"
          onClick={() => {
            setPopoverIsOpen(false)
            navigator.clipboard.writeText(
              `${window.location.origin}${paths.findAJob.jobFinder.index}/${jobSelected.job_id}`
            )
          }}
        >
          <CopyContentIcon />
        </ShareIconButton>
      </ShareViaLogoContainer>
    </ShareViaContainer>
  )

  return (
    <>
      <JobShareIcon
        onClick={() => {
          setPopoverIsOpen(true)
        }}
      />
      {popoverIsOpen && <JobSharePopover ref={popoverRef}>{shareViaUI}</JobSharePopover>}
    </>
  )
}

export default JobShare
