import { Box, Select } from "@engaging-tech/components"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { MiscSelectors } from "../../../misc"

const Country = ({ onAnswer, initialValue }) => {
  const { t } = useTranslation(["organisation_survey"])
  const countries = useSelector(MiscSelectors.getAllCountries)
  const [selected, setSelected] = useState(
    (initialValue && initialValue.value[0]) || ""
  )

  const handleSelect = e => {
    setSelected(e.target.value)
    onAnswer(e.target.value)
  }

  return (
    <Box mt={[3, 5]}>
      <Select
        label={t("questions.country", "")}
        defaultValue=""
        value={selected}
        onChange={handleSelect}
      >
        <option disabled value="">
          {t("questions.country_select", "")}
        </option>
        {countries.map(country => (
          <option key={country.id} value={country.id}>
            {country.name}
          </option>
        ))}
      </Select>
    </Box>
  )
}

export default Country
