import { connect } from "react-redux"

import { getTitle, getIntroduction } from "../store/happinessSurvey.selectors"

import CustomIntroComponent from "../components/CustomIntro"

const mapState = state => ({
  title: getTitle(state),
  content: getIntroduction(state)
})

const CustomIntro = connect(mapState)(CustomIntroComponent)

export default CustomIntro
