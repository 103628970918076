import React from "react"
import styled from "styled-components"
import { Card, Flex, Box, H3, Text } from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"
import { titleCaps } from "@engaging-tech/frontend-utils"

const CardTitle = styled(H3)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[4]};
`
const OrderSummaryBox = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[4]};
`

const Thumbnail = styled.img`
  max-height: 125px;
  max-width: 86px;
  width: auto;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
`

const Summary = ({ basket, deliveryPrice, subTotalPrice, isPremium }) => {
  const paths = usePaths()
  const totalPrice = isPremium
    ? subTotalPrice * 0.8 + deliveryPrice
    : subTotalPrice + deliveryPrice

  return (
    <Card bg="light.0" flexDirection="column" width={1 / 1}>
      <CardTitle width={1 / 1} fontSize={18} fontWeight={700} p={3}>
        Order
      </CardTitle>
      <OrderSummaryBox>
        {basket.map(book => (
          <Flex
            p={3}
            width={1 / 1}
            justifyContent="space-between"
            alignItems="flex-start"
            key={book.id}
          >
            <Thumbnail src={book.image} />
            <Flex
              width={1 / 1}
              justifyContent="flex-start"
              alignItems="flex-start"
              key={book.id}
              flexDirection="column"
              pl={2}
            >
              <Link
                width={1 / 1}
                to={`${paths.resourceCentre.browse.books.index}/${book.url}`}
              >
                <Text mb={1} color="primary.0" fontSize={4} fontWeight={700}>
                  {book.title}
                </Text>
              </Link>
              <Text mb={3} fontSize={3} color="dark.2">
                {titleCaps(book.format)}
              </Text>
              <Text width="auto" fontSize={3} mt={3} color="dark.2">
                Quantity:{" "}
                <Text width="auto" fontSize={3} color="dark.2" fontWeight={700}>
                  {book.quantity}
                </Text>
              </Text>
              <Text width="auto" fontSize={3} color="dark.2" fontWeight={700}>
                £{(book.quantity * parseFloat(book.price)).toFixed(2)}
              </Text>
            </Flex>
          </Flex>
        ))}
      </OrderSummaryBox>
      <Flex
        px={3}
        py={2}
        width={1 / 1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Text>Subtotal</Text>
        <Text width="auto">£{subTotalPrice.toFixed(2)}</Text>
      </Flex>
      <Flex
        px={3}
        py={2}
        width={1 / 1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Text>Delivery</Text>
        <Text width="auto">£{deliveryPrice.toFixed(2)}</Text>
      </Flex>

      {isPremium && (
        <Flex
          px={3}
          py={2}
          width={1 / 1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Text color="dark.2">Discount</Text>
          <Text color="dark.2">20%</Text>
          <Flex justifyContent="flex-end" alignItems="center">
            <Text color="dark.2" width="auto">
              - £
            </Text>
            <Text color="dark.2" width="auto">
              {(subTotalPrice - subTotalPrice * 0.8).toFixed(2)}
            </Text>
          </Flex>
        </Flex>
      )}
      <Flex
        px={3}
        py={2}
        width={1 / 1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Text fontWeight="500">TOTAL TO PAY</Text>
        <Text width="auto">£{totalPrice.toFixed(2)}</Text>
      </Flex>
    </Card>
  )
}

export default Summary
