import React from "react"
import styled from "styled-components"
import { Text, Flex, Box, Icon } from "@engaging-tech/components"

const FeatureIcon = styled(Box)`
  cursor: pointer;
`

const IconContainer = styled(Flex)`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`

const Wrapper = styled(Flex)`
  position: relative;

  &:before {
    border-top: 2px solid ${({ theme }) => theme.colors.dark[6]};
    content: "";
    margin: 0 auto;
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
  }
`

const MobileScroll = styled(Flex)`
  flex-shrink: 0;

  @media (min-width: 768px) {
    flex-shrink: 1;
  }
`

const IconWrapper = ({ children }) => (
  <Wrapper justifyContent="center" mb={0}>
    <IconContainer py={4} maxWidth={1024}>
      {children}
    </IconContainer>
  </Wrapper>
)

const SiteFeature = ({ feature, onSelect, index, isActive }) => (
  <MobileScroll
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    onClick={() => onSelect(index)}
    width={[1 / 2, "inherit"]}
    cursor="pointer"
  >
    <FeatureIcon
      elevation={4}
      borderRadius={99}
      bg={isActive ? "secondary.0" : "light.0"}
      p="20px"
      mb={3}
      width="80px"
      height="80px"
    >
      <Icon m={0} size={40} name={feature.icon} />
    </FeatureIcon>
    <Text textAlign="center" color="dark.2" fontSize={3}>
      {feature.title}
    </Text>
  </MobileScroll>
)

const FeatureSelector = ({ features, onSelect, selected }) => (
  <IconWrapper>
    {features.map((feature, index) => (
      <SiteFeature
        feature={feature}
        key={feature.title}
        onSelect={onSelect}
        index={index}
        isActive={selected === index}
      />
    ))}
  </IconWrapper>
)

export default FeatureSelector
