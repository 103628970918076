import { Flex, IconButton } from "@engaging-tech/components"
import { Redirect } from "@engaging-tech/routing"
import React, { useState } from "react"

const Toggle = ({ id, isBookmarked, isAuthenticated, postType, onToggle, selectedTabCategory }) => {
  const [anonymousInteraction, setAnonymousInteraction] = useState(false)
  const handleToggle = e => {
    e.preventDefault()

    // only logged in users can bookmark stuff
    return isAuthenticated
      ? onToggle({
          id,
          isBookmarking: !isBookmarked,
          postType,
          selectedTabCategory
        })
      : setAnonymousInteraction(true)
  }

  return anonymousInteraction ? (
    <Redirect to="/account/sign-in" />
  ) : (
    <Flex height={35} width={35} justifyContent="center" alignItems="center">
      <IconButton icon={isBookmarked ? "bookmark_filled" : "bookmark_border"} onClick={handleToggle} />
    </Flex>
  )
}

export default Toggle
