import React from "react"

import HobbiesLoader from "../../misc/containers/HobbiesLoader"
import SkillsLoader from "../../misc/containers/SkillsLoader"
import AllSet from "../createCV/components/AllSet"
import ContactDetails from "../createCV/components/ContactDetails"
import CreateCVIntro from "../createCV/components/CreateCVIntro"
import Hobbies from "../createCV/components/Hobbies"
import PersonalStatement from "../createCV/components/PersonalStatement"
import Qualifications from "../createCV/components/Qualifications"
import WorkExperience from "../createCV/components/WorkExperience"
import FormHandler from "../createCV/containers/FormHandler"

const CreateCV = ({ redirectTo }) => {
  return (
    <>
      <SkillsLoader />
      <HobbiesLoader />
      <FormHandler
        redirectTo={redirectTo}
        formStepTemplates={[
          CreateCVIntro,
          ContactDetails,
          PersonalStatement,
          WorkExperience,
          Qualifications,
          Hobbies,
          AllSet
        ]}
      />
    </>
  )
}

export default CreateCV
