import { Box, Chip, Flex, Spinner, Text } from "@engaging-tech/components"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"

import { jobMatcherActions, jobMatcherSelectors } from ".."
import JobInfo from "../../ui/components/Jobs/JobInfo"
import { yourProfileSelectors } from "../../yourProfile"
import DeleteWarningOverlay from "./deleteWarningOverlay"
import NotifiedOverlay from "./notifiedOverlay"

const ColoredChip = styled(Chip)`
  background-color: rgba(225, 244, 244, 1);

  :hover {
    cursor: default;
  }
`

const BorderTopBox = styled(Box)`
  border-top: ${({ theme, isFirst }) => (isFirst ? "none" : `1px solid ${theme.colors.primary[0]}`)};
`

const Job = ({ isFirst, job, id, deleteCallback, setShowNotifiedOverlay, yourSkills }) => {
  const [showWarningOverlay, setShowWarningOverlay] = useState(false)

  const handleDelete = () => setShowWarningOverlay(true)

  return (
    <>
      <BorderTopBox isFirst={isFirst} pt={4}>
        <JobInfo
          jobDetails={job}
          deleteCallback={handleDelete}
          setShowNotifiedOverlay={setShowNotifiedOverlay}
        >
          {yourSkills && yourSkills.length > 0 && (
            <Box mt={4}>
              <Text display="block">Your Skills:</Text>
              <Flex py={2}>
                {yourSkills.map(skill => (
                  <ColoredChip mx={1}>{skill.name}</ColoredChip>
                ))}
              </Flex>
            </Box>
          )}
        </JobInfo>
      </BorderTopBox>
      <DeleteWarningOverlay
        showWarningOverlay={showWarningOverlay}
        setShowWarningOverlay={setShowWarningOverlay}
        id={id}
        deleteCallback={deleteCallback}
      />
    </>
  )
}

const MatchesList = () => {
  const [showNotifiedOverlay, setShowNotifiedOverlay] = useState(false)

  const jobMatches = useSelector(jobMatcherSelectors.getJobMatches)
  const isJobMatchesLoading = useSelector(jobMatcherSelectors.getIsJobMatchesLoading)
  const yourSkills = useSelector(yourProfileSelectors.getSkills)

  const dispatch = useDispatch()

  const deleteJobMatch = jobMatchId => dispatch(jobMatcherActions.deleteJobMatch(jobMatchId))

  if (isJobMatchesLoading) {
    return <Spinner color="primary.0" width={1 / 1} mt={3} mb={5} />
  }

  return (
    <>
      {jobMatches.length > 0 && (
        <BorderTopBox px={[0, 3]}>
          {jobMatches.map((match, i) => (
            <Job
              isFirst={i === 0}
              {...match}
              key={match.id}
              deleteCallback={deleteJobMatch}
              setShowNotifiedOverlay={setShowNotifiedOverlay}
              yourSkills={yourSkills}
            />
          ))}
        </BorderTopBox>
      )}

      <NotifiedOverlay
        showNotifiedOverlay={showNotifiedOverlay}
        setShowNotifiedOverlay={setShowNotifiedOverlay}
      />
    </>
  )
}

export default MatchesList
