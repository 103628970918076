import React, { useState, useEffect } from "react"
import { getConfig } from "@engaging-tech/ssr-config"
import { usePaths, Link } from "@engaging-tech/routing"
import styled, { createGlobalStyle } from "styled-components"
import {
  Flex,
  Text,
  TextField,
  Button,
  Box,
  Select,
  LocationAutocomplete
} from "@engaging-tech/components"
import { getCountries } from "@engaging-tech/frontend-utils"
import PrimaryButton from "../../../../ui/components/PrimaryButton"

const GlobalStyle = createGlobalStyle`
 .pac-container {
   z-index: 9999999999999999999999;
 }
`

const FormWrapper = styled.form`
  width: 100%;
`

const DivideLine = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[4]};
  margin-top: 5px;
  margin-bottom: 20px;
`

const currentGoalsOptions = [
  "",
  "I want to find a new job",
  "I want to improve my workplace happiness in my current job",
  "I want to assess my career path",
  "I want to learn more and improve my skills"
]

const employemntStatusOptions = [
  "",
  "Student",
  "Graduate",
  "Full Time Employment",
  "Part Time Employment",
  "Freelancer",
  "Business Owner/CEO"
]

const EditProfileForm = ({
  errors,
  handleChange,
  handleBlur,
  setVisibility,
  handleSubmit,
  values,
  touched,
  setFieldValue,
  setFieldTouched
}) => {
  const [countries, setCountries] = useState([])
  const paths = usePaths()

  useEffect(() => {
    setCountries(getCountries())
  }, [])

  const handleLocationChange = e => {
    if (e && e.geometry) {
      setFieldValue("location", {
        countryId: countries.filter(
          country =>
            country.code ===
            e.address_components.filter(component =>
              component.types.includes("country")
            )[0].short_name
        )[0].id,
        name: e.formatted_address,
        point: {
          coordinates: [e.geometry.location.lat(), e.geometry.location.lng()]
        },
        country: {
          shortName: e.address_components.filter(country =>
            country.types.includes("country", "political")
          )[0].short_name,

          name: e.address_components.filter(country =>
            country.types.includes("country", "political")
          )[0].long_name
        },
        vicinity: e.vicinity
      })
    }
  }

  return (
    <>
      <GlobalStyle />
      <FormWrapper onSubmit={handleSubmit}>
        <Flex flexDirection={["column", "row"]} justifyContent="space-between">
          <Flex flexDirection="column" pr={[0, 2]} alignItems="stretch">
            <Text mb={2} color="dark.3" fontWeight={500}>
              Name
            </Text>
            <TextField
              name="firstName"
              type="text"
              width={1 / 1}
              error={errors.firstName}
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              id="firstName"
              label="First Name"
            />
            <TextField
              name="lastName"
              type="text"
              width={1 / 1}
              error={errors.lastName}
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              id="lastName"
              label="Surname"
            />

            <Flex alignItems="center" justifyContent="space-between">
              <Text mb={2} color="dark.3" fontWeight={500}>
                Location
              </Text>

              <Text
                fontSize="12px"
                width="220px"
                color={values.location.countryId ? "#49b762" : "rgb(169 70 70)"}
                style={{ textAlign: "right" }}
              >
                {values.location.countryId
                  ? "Location selected"
                  : "No location selected"}
              </Text>
            </Flex>

            <LocationAutocomplete
              width={1 / 1}
              height={50}
              id="location.name"
              name="location.name"
              type="text"
              label=""
              initialValue={values?.location?.name || null}
              placeholder="Location"
              googleApiKey={getConfig().keys.googleApiKey}
              selectedLocationCallback={handleLocationChange}
              onKeyDown={e => {
                if (e.key === "Backspace") {
                  setFieldValue("location", {
                    countryId: null,
                    name: "",
                    coordinates: { lat: 0, lng: 0 },
                    country: {
                      name: "",
                      shortName: ""
                    }
                  })
                }
              }}
              onFocus={() => setFieldTouched("location.name")}
              error={
                touched.location &&
                errors.location &&
                touched.location.name &&
                errors.location.name
              }
            />
          </Flex>
          <Flex flexDirection="column" pl={[0, 2]}>
            <Text mb={2} color="dark.3" fontWeight={500}>
              Job Details
            </Text>
            <TextField
              name="jobTitle"
              type="text"
              width={1 / 1}
              error={errors.jobTitle}
              value={values.jobTitle}
              onChange={handleChange}
              onBlur={handleBlur}
              id="jobTitle"
              label="Job Title"
            />
            <TextField
              name="companyName"
              type="text"
              width={1 / 1}
              error={errors.companyName}
              value={values.companyName}
              onChange={handleChange}
              onBlur={handleBlur}
              id="companyName"
              label="Company Name"
            />
            <Text mb={2} color="dark.3" fontWeight={500}>
              Website URL
            </Text>
            <TextField
              name="websiteUrl"
              type="text"
              width={1 / 1}
              error={errors.websiteUrl}
              value={values.websiteUrl}
              onChange={handleChange}
              onBlur={handleBlur}
              id="websiteUrl"
              label="Website Address"
            />
          </Flex>
        </Flex>
        <DivideLine />
        <Flex
          flexDirection={["column", "row"]}
          width={1 / 1}
          justifyContent="space-between"
        >
          <Flex width={1 / 1} flexDirection="column" pr={[0, 2]}>
            <Text mb={2} color="dark.3" fontWeight={500}>
              Your employment status
            </Text>
            <Select
              name="employmentStatus"
              type="text"
              width={1 / 1}
              error={errors.employmentStatus}
              value={values.employmentStatus}
              onChange={handleChange}
              onBlur={handleBlur}
              id="employmentStatus"
              label="Employment Status"
            >
              {employemntStatusOptions.map(optionString => (
                <option value={optionString} key={optionString}>
                  {optionString}
                </option>
              ))}
            </Select>
          </Flex>
          <Flex flexDirection="column" pl={[0, 2]}>
            <Text mb={2} color="dark.3" fontWeight={500}>
              Your current goal
            </Text>
            <Select
              name="currentGoal"
              type="text"
              width={1 / 1}
              error={errors.currentGoal}
              value={values.currentGoal}
              onChange={handleChange}
              onBlur={handleBlur}
              id="currentGoal"
              label="Current Goal"
            >
              {currentGoalsOptions.map(optionString => (
                <option value={optionString} key={optionString}>
                  {optionString}
                </option>
              ))}
            </Select>
          </Flex>
        </Flex>
        <Flex
          flexDirection="row"
          width={1 / 1}
          mt={4}
          justifyContent="space-between"
        >
          <Link
            to={paths.careerDeveloper.index}
            onClick={() => {
              setVisibility(false)
            }}
          >
            <Button type="button" elevation={0} color="primary.0" bg="light.0">
              Cancel
            </Button>
          </Link>

          <PrimaryButton type="submit" width={168}>
            Save
          </PrimaryButton>
        </Flex>
      </FormWrapper>
    </>
  )
}

export default EditProfileForm
