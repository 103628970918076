import React, { useCallback } from "react"

import { useSectionContext } from "../contexts/sectionContext"
import CodeCheck from "./codeCheck"
import CustomIntro from "./customIntro"
import Question from "./question"

const SurveySection = ({ onMoveToPreviousStep }) => {
  const {
    currentQuestion,
    currentSection,
    sectionsList,
    onNextQuestion,
    onPreviousQuestion,
    onUpdateCurrentSection
  } = useSectionContext()

  const sectionData = sectionsList[currentSection]

  const onNextSection = useCallback(() => {
    onUpdateCurrentSection(currentSection + 1)
  }, [currentSection, onUpdateCurrentSection])

  const onPreviousSection = useCallback(() => {
    if (currentSection) onUpdateCurrentSection(currentSection - 1)
    else onMoveToPreviousStep()
  }, [currentSection, onMoveToPreviousStep, onUpdateCurrentSection])

  // eslint-disable-next-line no-underscore-dangle
  if (sectionData?.__typename === "SurveyIntro")
    return (
      <CustomIntro
        title={sectionData.title}
        description={sectionData.description}
        onNext={onNextSection}
        onPrevious={onPreviousSection}
        isFirstSection
      />
    )

  // eslint-disable-next-line no-underscore-dangle
  if (sectionData?.__typename === "SurveyCodeCheck")
    return <CodeCheck onNext={onNextSection} onPrevious={onPreviousSection} />

  // eslint-disable-next-line no-underscore-dangle
  if (sectionData?.__typename) {
    const questionId = sectionData.questions[currentQuestion]

    if (questionId === "-1") {
      return (
        <CustomIntro
          title={sectionData.name}
          description={sectionData.description}
          onNext={onNextQuestion}
          onPrevious={onPreviousQuestion}
        />
      )
    }

    const questionIndex = sectionData.questions.indexOf(questionId)

    return (
      <Question
        key={questionId}
        id={questionId}
        sectionTitle={sectionData.name}
        onNext={onNextQuestion}
        onPrevious={onPreviousQuestion}
        isLastQuestion={questionIndex === sectionData.questions.length - 1}
      />
    )
  }

  return null
}

export default SurveySection
