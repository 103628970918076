import { Box, Button, Flex, Icon, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import IconCircle from "../../ui/components/CircleIcon"

const reactStringReplace = require("react-string-replace")

const LinkText = styled(Text)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary[0]};
  font-weight: 700;
`
const Link = ({ to = "/", children }) => <a href={to}>{children}</a>

const addLinkText = (text, linkText, linkTo) => {
  if (linkText) {
    return reactStringReplace(text, linkText, (match, i) => (
      <Link key={i} to={linkTo}>
        <LinkText key={i}>{match}</LinkText>
      </Link>
    ))
  }
  return text
}

const EditIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary[0]};
  cursor: pointer;
`

const StyledBorderBox = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[5]};
  &:nth-last-child(-n + 1) {
    border-bottom: none;
  }
`
const ProfileSection = ({
  icon2,
  icon,
  title,
  text,
  linkText,
  linkTo,
  onClick,
  dataShowing,
  toggle,
  hideCancelButton,
  children
}) => {
  return (
    <StyledBorderBox flexDirection="column" width={1 / 1} p={[2, 3, 3]}>
      <Flex flexDirection="row" alignItems="center" justifyContent="space-between">
        <Flex alignItems="center" flexDirection="row">
          <IconCircle name={icon} mr={3} bg="secondary.3" color="primary.0" />
          <Text fontSize={4} color="dark.1" fontWeight={600} width="auto" mb={1}>
            {title}
          </Text>
        </Flex>
        {dataShowing ? (
          <EditIcon name={icon2 || "edit"} onClick={onClick} />
        ) : (
          <EditIcon name="close" onClick={onClick} />
        )}
      </Flex>
      {text && (
        <Text fontSize={3} color="dark.2" width="auto" mt={3} mb={2}>
          {addLinkText(text, linkText, linkTo)}
        </Text>
      )}
      {children}
      {!hideCancelButton && (
        <Button variant="text.dark.3" elevation={0} onClick={toggle} mt={2}>
          CANCEL
        </Button>
      )}
    </StyledBorderBox>
  )
}

export default ProfileSection
