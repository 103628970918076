import { getConfig } from "@engaging-tech/ssr-config"
import { apollo } from "../../../lib/Apollo"

import { LOAD, UPDATE_TEST } from "./yourTests.queries"

const GRAPHQL_API_URL = () =>
  `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

export const load = () =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD
  })

export const update = ({ testId, score, completed }) =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: UPDATE_TEST,
    variables: { testId, score, completed }
  })
