/**
 * Opens a window to share a page to Facebook
 * @param {string} url The URL of the page to be shared
 * facebook does not support sending prepopulated message via url
 */
export const toFacebook = url =>
  typeof window !== "undefined" &&
  window.open(
    `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(url)}`,
    "_blank",
    "width=300,height=300toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes"
  )

/**
 * Opens a window to share a page to Twitter
 * @param {string} url The URL of the page to be shared
 */

export const toTwitter = (url, message = "") =>
  typeof window !== "undefined" &&
  window.open(
    `https://twitter.com/share?text=${message}&url=${encodeURI(url)}`,
    "_blank",
    "width=500,height=300toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes"
  )

/**
 * Opens a window to share a page to LinkedIn
 * @param {string} url The URL of the page to be shared
 * linked in does not support sending prepopulated message via url
 */

export const toLinkedIn = url =>
  typeof window !== "undefined" &&
  window.open(
    `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(url)}&title=&summary=&source=`,
    "_blank",
    "width=500,height=300toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes"
  )

export const toEmail = (url, message = "", subject = "") =>
  typeof window !== "undefined" &&
  window.open(`mailto:user@example.com?subject=${subject}&body=${message}${url}`)
