import React from "react"
import { MotionSelect } from "@engaging-tech/components"

const getIconColor = ({ iconColor, active, disabled }) => {
  if (iconColor) {
    return iconColor
  }
  if (disabled) {
    return "dark.2"
  }
  return active ? "light.0" : "dark.0"
}

const FilterDropdown = ({
  active = false,
  bg = null,
  disabled = false,
  dropdownBg = null,
  buttonTextStyling = null,
  selectedTextStyling = null,
  iconColor = null,
  criteria,
  valueId,
  ...props
}) => {
  return (
    <MotionSelect
      bg={bg || (active ? "primary.0" : "rgba(215,215,215, 1)")}
      dropdownBg={
        dropdownBg || (active ? "tertiary.0" : "rgba(234,234,234, 1)")
      }
      buttonTextStyling={
        buttonTextStyling || {
          fontSize: 14,
          ml: 3,
          mr: 0,
          fontFamily: "Centra",
          color: (active && "light.0") || (disabled ? "dark.2" : "dark.0")
        }
      }
      valueId={valueId}
      selectedTextStyling={
        selectedTextStyling || {
          fontSize: 14,
          ml: 3,
          mr: 3,

          fontWeight: 700
        }
      }
      disabled={disabled}
      height={34}
      iconColor={getIconColor({ iconColor, active, disabled })}
      borderRadius={17}
      {...props}
    />
  )
}

export default FilterDropdown
