import { Button, Checkbox, Flex, Spinner, TabletUp, Text, ToggleLogic } from "@engaging-tech/components"
import { useRouter } from "@engaging-tech/routing"
import React from "react"

import PrimaryButton from "../../../ui/components/PrimaryButton"
import TestModal from "../../../yourTests/components/TestModal"
import testDirectory from "../../../yourTests/lib/testDirectory"
import WidgetCard from "../../components/WidgetCard"
import DynamicState from "../../containers/DynamicState"

const getBoolean = (testStatus, id, latestIndividualSurveySubmission) => {
  // If Happy at Work Test, check if individual survey submission is present
  if (id === "5ff31b9d3349cf6957b600c0") return !!latestIndividualSurveySubmission

  return testStatus.find(el => id === el.testId)?.completed
}

const TestsWidget = ({ isLoading, testStatus, updateTestStatus, id, latestIndividualSurveySubmission }) => {
  const router = useRouter()

  const filteredTestDirectory = testDirectory.filter(test => test.widgetShow)

  return (
    <WidgetCard width={1 / 1} bg="light.0" height="100%">
      <DynamicState
        id={id}
        removable
        title="Discover More With Our Range Of Tests"
        btnText="View All Tests"
        linkTo="/develop-career/your-tests"
      >
        {isLoading ? (
          <Spinner py={3} color="primary.0" width={1 / 1} />
        ) : (
          <Flex flexDirection="column" width={1 / 1} height="100%">
            {filteredTestDirectory.map(test => (
              <Flex key={test.id} alignItems="center" my="5px" height={100} justifyContent="space-between">
                <Checkbox
                  width={18}
                  mr={[2, 6]}
                  checked={
                    testStatus.length > 0 && getBoolean(testStatus, test.id, latestIndividualSurveySubmission)
                  }
                  disabled={test.id === "5ff31b9d3349cf6957b600c0"}
                  id={test.id}
                  onChange={() => {
                    updateTestStatus({
                      testId: test.id,
                      completed: !getBoolean(testStatus, test.id, latestIndividualSurveySubmission)
                    })
                  }}
                />
                <Flex justifyContent="space-between" width={["auto", 350]}>
                  <Text fontWeight={600} color="primary.0" fontSize={4} width="auto">
                    {test.title}
                  </Text>
                  <TabletUp>
                    <Text fontWeight={300} color="primary.0" fontSize={4} width="150px">
                      {test.id === "5ff31b9d3349cf6957b600c0" &&
                        latestIndividualSurveySubmission &&
                        `SCORE: ${latestIndividualSurveySubmission?.score}%`}
                    </Text>
                  </TabletUp>
                </Flex>

                <ToggleLogic>
                  {({ on, toggle }) => (
                    <>
                      {testStatus.length > 0 &&
                      getBoolean(testStatus, test.id, latestIndividualSurveySubmission) ? (
                        <Button
                          width="15%"
                          minWidth="111px"
                          variant="outline.primary.0"
                          elevation={0}
                          ml="auto"
                          onClick={() => {
                            if (test.title === "Career Path Finder") window.open(test.link, "_blank")
                            else toggle()
                          }}
                        >
                          <strong>retake test</strong>
                        </Button>
                      ) : (
                        <PrimaryButton
                          color="light.0"
                          text="take test"
                          width="15%"
                          ml="auto"
                          minWidth="111px"
                          onClick={() => {
                            if (test.redirectLink) router.redirect(test.link)

                            if (test.title === "Career Path Finder") window.open(test.link, "_blank")
                            else toggle()
                          }}
                        />
                      )}

                      {on && <TestModal onToggle={toggle} link={test.link} />}
                    </>
                  )}
                </ToggleLogic>
              </Flex>
            ))}
          </Flex>
        )}
      </DynamicState>
    </WidgetCard>
  )
}

export default TestsWidget
