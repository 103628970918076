import { useState, useEffect } from "react"
import createSleep from "../helpers/createSleep"

const useTimedReRender = ms => {
  const [, updateState] = useState(0)

  useEffect(() => {
    ;(async () => {
      await createSleep(ms)
      updateState(1)
    })()
  }, [ms])
}

export default useTimedReRender
