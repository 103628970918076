import { createSelector } from "reselect"
import { surveyResultsSelectors } from "../.."

export const getHappinessSixSteps = createSelector(
  surveyResultsSelectors.getResultsData,
  data => data && data.happinessSixSteps
)

const getResultBySection = (state, section) => {
  const scoresState = getHappinessSixSteps(state)
  const test = scoresState?.find(sectionResult => {
    return sectionResult.name === section
  })

  return test
}

export const getStepResult = createSelector(
  getResultBySection,
  result => result
)
export const getResult = createSelector(
  surveyResultsSelectors.getResultsData,
  data => data
)
