import { Button, Flex, Text } from "@engaging-tech/components"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const CircleButton = styled(Button)`
  border-radius: 99px;
  min-width: 40px;
  min-height: 40px;
  background-color: ${({ theme, isSelected }) =>
    !isSelected && theme.colors.dark[6]};

  &:focus,
  &:active,
  &:hover {
    background-color: ${({ theme, isSelected }) =>
      isSelected && theme.colors.secondary[0]};
  }

  @media only screen and (min-width: 768px) {
    min-width: 32px;
    min-height: 32px;
  }
`

const Scale = ({ min, max, onAnswer, initialValue }) => {
  const { t } = useTranslation(["organisation_survey"])

  const scaleValues = Array.from({ length: 100 }, (_, i) => i).slice(
    min,
    max + 1
  )
  const [selected, setSelected] = useState(null)

  const isSelected = value => value === selected

  const handleSelect = value => {
    setSelected(value)
    onAnswer(value)
  }
  const handleKeyPress = event => {
    const keyCode = event.which
    const KEY_CODES = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
    const res = KEY_CODES.indexOf(keyCode)
    if (res === -1) return
    event.preventDefault()
    handleSelect(res)
  }

  useEffect(() => {
    if (
      initialValue &&
      initialValue?.value &&
      initialValue?.value.length > 0 &&
      initialValue.value[0]
    )
      setSelected(initialValue?.value[0])
  }, [initialValue])

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress)
    return () => window.removeEventListener("keydown", handleKeyPress)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Flex
        flexWrap="wrap"
        justifyContent={["flex-start", "space-between"]}
        alignContent="center"
        mt={[3, 4]}
        mb={[2, 3]}
      >
        {scaleValues.map((val, index) => (
          <CircleButton
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            isSelected={isSelected(val)}
            variant={isSelected(val) ? "secondary.0" : "light.0"}
            elevation={0}
            mr={[2, 0]}
            mb={[2, 0]}
            onClick={() => handleSelect(val)}
          >
            {`${val}`}
          </CircleButton>
        ))}
      </Flex>
      <Text color="dark.2" fontSize={1}>
        {min} = {t("questions.disagree", "").toUpperCase()}{" "}
        {t("questions.and", "")} {max} ={" "}
        {t("questions.agree", "").toUpperCase()}
      </Text>
    </>
  )
}

export default Scale
