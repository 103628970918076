import React, { useEffect } from "react"
import styled from "styled-components"
import { Flex, Spinner, Card, Text } from "@engaging-tech/components"
import { Link, Redirect, usePaths } from "@engaging-tech/routing"
import { titleCaps } from "@engaging-tech/frontend-utils"
import PrimaryButton from "../../../ui/components/PrimaryButton"

const CardHeader = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[4]};
`

const Badge = styled.img`
  max-height: 40px;
  margin-right: ${({ theme, hasMargin }) => (hasMargin ? theme.space[2] : 0)}px;
`

const CardSubHeader = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.dark[4]};
`

const Thumbnail = styled.img`
  max-height: 125px;
  max-width: 86px;
  width: auto;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
`

const BooksGroup = styled(Flex)`
  align-items: center;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

const appStoreLogo = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/app-store.svg`)
const googlePlayLogo = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/google-play.png`)

const OrderList = ({ orders, userDetails, isLoading, onLoad }) => {
  const paths = usePaths()

  useEffect(() => {
    if (userDetails.userId) onLoad(userDetails.userId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return (
      <Spinner
        color="primary.0"
        py={3}
        width={[1 / 1, 1 / 1, 3 / 4]}
        MaxWidth={700}
      />
    )
  }
  if (!userDetails.userId) return <Redirect to={paths.resourceCentre.index} />
  if (!orders || !orders.length) {
    return (
      <Flex flexDirection="column" width={[1 / 1, 1 / 1, 3 / 4]} MaxWidth={700}>
        <Text fontSize={20} pb={2}>
          No Orders
        </Text>
      </Flex>
    )
  }

  return orders.map(order => (
    <Card
      key={order.id}
      bg="light.0"
      flexDirection="column"
      mb={3}
      width={[1 / 1, 1 / 1, 3 / 4]}
      MaxWidth={700}
      ml="auto"
      mr="auto"
    >
      <CardHeader
        flexDirection="row"
        p={3}
        justifyContent="space-between"
        width={1 / 1}
      >
        <Text fontSize={18} fontWeight={700}>
          Order Date: {order.date ? order.date : "-"}
        </Text>
        <Text fontSize={3} color="dark.2" width={160}>
          Order Number: {order.id}
        </Text>
      </CardHeader>
      <BooksGroup
        width={1 / 1}
        p={3}
        justifyContent="flex-start"
        flexDirection="row"
      >
        {order.booksData.map(bookData => (
          <Flex>
            {bookData.image && <Thumbnail src={bookData.image} />}
            <Flex flexDirection="column" width={1 / 1} pl={3}>
              <Link
                width={1 / 1}
                to={`${paths.resourceCentre.browse.books.index}/${bookData.url}`}
              >
                <Text mb={1} color="primary.0" fontSize={4} fontWeight={700}>
                  {bookData.title}
                </Text>
              </Link>

              {bookData.authors &&
                bookData.authors.map((author, aIndex) => (
                  <Text
                    mb={1}
                    mr={2}
                    fontSize={2}
                    fontWeight="500"
                    key={author.name + aIndex.toString()}
                  >
                    {author.name}
                  </Text>
                ))}
              <Text fontSize={4} fontWeight={700} mt={2}>
                {titleCaps(bookData.format)}
              </Text>
              <Text fontSize={4} mt={2} ml="auto" width={140}>
                Price
                <Text fontSize={4} pl={2} fontWeight={700}>
                  {`£${bookData.price.toFixed(2)}`}
                </Text>
              </Text>
            </Flex>
          </Flex>
        ))}
      </BooksGroup>
      {order.booksData.find(
        book => book.format === "audiobook" || book.format === "ebook"
      ) && (
        <>
          <CardSubHeader
            flexDirection="row"
            p={3}
            justifyContent="space-between"
            width={1 / 1}
          >
            <Text fontSize={18} fontWeight={700}>
              Download Now
            </Text>
          </CardSubHeader>
          <Flex pl={3} flexDirection="column">
            <Text fontSize={4} fontWeight={500} mb={2}>
              Use Adobe Digital Editions to open the{" "}
              {order.booksData.find(book => book.format === "audiobook")
                ? "audiobook file"
                : "eBook"}
            </Text>
            <Link
              external
              newTab
              href="https://www.adobe.com/uk/solutions/ebook/digital-editions/download.html"
            >
              <PrimaryButton
                mb={2}
                width={200}
                height={38}
                color="grey.0"
                text="DOWNLOAD eBOOK"
              />
            </Link>
            <Text fontSize={4} fontWeight={500} mt={3}>
              Download Adobe Digital Editions to{" "}
              {order.booksData.find(book => book.format === "audiobook")
                ? "listen to your audiobook"
                : "read your eBook"}
            </Text>
            <Flex
              mt={2}
              mb={2}
              width="auto"
              alignItems={["flex-start", "flex-start", "center"]}
              flexDirection={["column", "column", "row"]}
            >
              <Flex flexDirection="row">
                <Link
                  newTab
                  external
                  to="https://itunes.apple.com/gb/app/adobe-digital-editions/id952977781?mt=8 "
                >
                  <Badge src={appStoreLogo} hasMargin alt="iTunes App Store" />
                </Link>
                <Link
                  newTab
                  external
                  to="https://play.google.com/store/apps/details?id=com.adobe.digitaleditions&hl=en_GB "
                >
                  <Badge src={googlePlayLogo} alt="Google Play store" />
                </Link>
                <Link
                  newTab
                  external
                  to="https://www.adobe.com/uk/solutions/ebook/digital-editions/download.html"
                >
                  <PrimaryButton
                    ml={2}
                    width={250}
                    height={38}
                    text="Download for PC and Mac"
                  />
                </Link>
              </Flex>
            </Flex>
          </Flex>
        </>
      )}
      {order.booksData.find(book => book.format === "Hard Cover") && (
        <>
          <CardSubHeader
            flexDirection="row"
            p={3}
            justifyContent="space-between"
            width={1 / 1}
          >
            <Text fontSize={18} fontWeight={700}>
              Delivery Details
            </Text>
          </CardSubHeader>
          <Flex flexDirection="column" pl={3} mb={2}>
            <Text fontSize={3} py={2} color="dark.2">
              {order.name}
            </Text>
            <Text fontSize={3} py={2} color="dark.2">
              {order.address}
            </Text>
            <Text fontSize={3} py={2} color="dark.2">
              {order.postCode}
            </Text>
            <Text fontSize={3} py={2} color="dark.2">
              {order.country}
            </Text>
          </Flex>
        </>
      )}
    </Card>
  ))
}

export default OrderList
