import { connect } from "react-redux"
import { AccountSelectors } from "../../account"

import {
  getBasket,
  getHasShippingCosts,
  getSubTotalPrice,
  getOrderId,
  getIsCheckoutLoading
} from "../store/libraryBooks.selectors"
import { getIsPremium } from "../../yourProfile/store/yourProfile.selectors"

import { resetOrder, createOrder } from "../store/libraryBooks.actions"

import Total from "../components/Basket/Total"

const mapState = state => ({
  basket: getBasket(state),
  hasShippingCosts: getHasShippingCosts(state),
  subTotalPrice: getSubTotalPrice(state),
  isPremium: getIsPremium(state),
  isAuthenticated: AccountSelectors.getIsAuthenticated(state),
  hasProducts: !!getBasket(state).length,
  orderId: getOrderId(state),
  isCheckoutLoading: getIsCheckoutLoading(state)
})

const mapDispatch = dispatch => ({
  onLoad: () => dispatch(resetOrder()),
  createOrder: books => dispatch(createOrder(books))
})

const BasketTotal = connect(mapState, mapDispatch)(Total)

export default BasketTotal
