import { Flex, Text } from "@engaging-tech/components"
import React from "react"
import { useSelector } from "react-redux"

import CreatePost from "../../features/globalHub/containers/CreatePost"
import HubNav from "../../features/globalHub/containers/HubNav"
import PostList from "../../features/globalHub/containers/PostList"
import { getError } from "../../features/globalHub/store/globalHub.selectors"
import TokenAuth from "../../features/misc/components/TokenAuth"
import CountriesLoader from "../../features/misc/containers/CountriesLoader"
import AppViewBaseLayout from "../../layouts/AppViewBaseLayout"

const Globalhub = () => {
  const error = useSelector(getError)

  return (
    <AppViewBaseLayout mx={3} mt={4} banner={HubNav}>
      <TokenAuth />
      {error && (
        <Text width={1 / 1} textAlign="center">
          {error}
        </Text>
      )}
      <Flex width={[1 / 1, "auto"]} flexDirection="column" flex={1}>
        <CountriesLoader />
        <CreatePost />
        <PostList />
      </Flex>
    </AppViewBaseLayout>
  )
}

export default Globalhub
