import { all, call, put, select, takeLatest } from "redux-saga/effects"

import { getCurrentUser } from "../../account/services/account.service"
import { load as globalHubPostsLoad } from "../../globalHub/services/globalHub.service"
import layoutManager from "../helpers/layoutManager"
import {
  axiosLoadWidgetLayout,
  loadAllGoals,
  loadAllWidgets,
  loadSurveyResults,
  updateWidgetLayout
} from "../services/developCareer.service"
import * as Actions from "./developCareer.actions"
import { getLocalWidgetLayout, getProfileCompletionPercentage } from "./developCareer.selectors"

export function* loadSurveysSaga(action) {
  try {
    const res = yield call(loadSurveyResults, action.payload)

    yield put(Actions.updateSurveys({ payload: res.data.getSurveySubmissions }))
  } catch (error) {
    yield put(
      Actions.updateSurveys({
        error: {
          message: "Unable to retrieve survey data",
          type: "BACKEND_QUERY"
        }
      })
    )
  }
}

export function* loadAllWidgetsSaga() {
  try {
    const allWidgetsRes = yield call(loadAllWidgets)

    yield put(
      Actions.updateAllWidgets({
        payload: allWidgetsRes.data.getAllProfileWidgets
      })
    )
  } catch (error) {
    yield put(
      Actions.updateAllWidgets({
        error: { message: error, type: "BACKEND_QUERY" }
      })
    )
  }
}

export function* loadWidgetLayoutSaga() {
  try {
    const user = yield call(getCurrentUser)

    const myWidgetsRes = yield axiosLoadWidgetLayout(user.sub)

    const profileCompletionPercentage = yield select(getProfileCompletionPercentage)
    yield put(
      Actions.updateWidgetLayout({
        payload: {
          layout: myWidgetsRes.data?.data?.getUserProfile?.widgetLayout,
          profileCompletionPercentage
        }
      })
    )
  } catch (error) {
    yield put(
      Actions.updateWidgetLayout({
        error: {
          message: error,
          type: "BACKEND_QUERY"
        }
      })
    )
  }
}

export function* postLayout() {
  const localWidgetLayout = yield select(getLocalWidgetLayout)
  const preparedWidgetLayout = layoutManager.prepareLayoutForBackend(localWidgetLayout.data)

  try {
    const res = yield call(updateWidgetLayout, preparedWidgetLayout)
    const profileCompletionPercentage = yield select(getProfileCompletionPercentage)
    if (res.data?.updateMyProfile?.widgetLayout) {
      yield put(
        Actions.updateWidgetLayout({
          payload: {
            layout: res.data.updateMyProfile.widgetLayout,
            profileCompletionPercentage
          }
        })
      )
    } else {
      throw new Error("Failed to update widgetLayout on users profile")
    }
  } catch (error) {
    yield put(
      Actions.updateWidgetLayout({
        error: {
          message: "Failed to update widgetLayout on users profile",
          type: "BACKEND_MUTATION"
        }
      })
    )
  }
}

export function* loadAllGoalsSaga() {
  try {
    const res = yield call(loadAllGoals)

    yield put(Actions.updateAllGoals({ payload: res.data?.getAllProfileGoals }))
  } catch (error) {
    yield put(
      Actions.updateAllGoals({
        error: {
          message: error,
          type: "BACKEND_QUERY"
        }
      })
    )
  }
}

export function* loadContentForYouPosts() {
  try {
    const [events, podcasts, articles] = yield all([
      call(globalHubPostsLoad, { type: "event", limit: 5 }),
      call(globalHubPostsLoad, { type: "podcast", limit: 5 }),
      call(globalHubPostsLoad, { type: "article", limit: 5 })
    ])

    yield put(
      Actions.updateContentForYou({
        payload: {
          events: events.data.allHubPosts,
          podcasts: podcasts.data.allHubPosts,
          articles: articles.data.allHubPosts
        }
      })
    )
  } catch (error) {
    yield put(
      Actions.updateContentForYou({
        error: { message: error, type: "BACKEND_QUERY" }
      })
    )
  }
}

export function* saga() {
  yield takeLatest(Actions.types.LOAD_SURVEYS, loadSurveysSaga)
  yield takeLatest(Actions.types.LOAD_ALL_WIDGETS, loadAllWidgetsSaga)
  yield takeLatest(Actions.types.LOAD_WIDGET_LAYOUT, loadWidgetLayoutSaga)
  yield takeLatest(Actions.types.POST_LAYOUT, postLayout)
  yield takeLatest(Actions.types.LOAD_ALL_GOALS, loadAllGoalsSaga)
  yield takeLatest(Actions.types.LOAD_CONTENT_FOR_YOU, loadContentForYouPosts)
}
