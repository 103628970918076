import { gql } from "../../../lib/Apollo"

export const LOAD = gql`
  query {
    getProfileTests {
      testId
      name
      completed
      completedAt
      score
      link
    }
  }
`

export const UPDATE_TEST = gql`
  mutation updateProfileTest(
    $testId: ID!
    $score: String
    $completed: Boolean
  ) {
    updateProfileTest(testId: $testId, score: $score, completed: $completed) {
      testId
      name
      completed
      completedAt
      score
      link
    }
  }
`
