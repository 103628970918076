import { useParams, usePaths } from "@engaging-tech/routing"
import React from "react"

import CreateCVView from "../../features/cvLibrary/views/CreateCV"
import AuthGuard from "../../features/ui/containers/AuthGuard"
import Loader from "../../features/yourProfile/containers/Loader"
import MinifiedNavLayout from "../../layouts/MinifiedNavLayout"

const CreateCV = () => {
  const { id } = useParams()
  const paths = usePaths()

  const redirectTo = paths.careerDeveloper.cvLibrary.index

  return (
    <AuthGuard>
      <MinifiedNavLayout bg="primary.0" pb={32}>
        <Loader cvId={id} />
        <CreateCVView redirectTo={redirectTo} />
      </MinifiedNavLayout>
    </AuthGuard>
  )
}

export default CreateCV
