import { createContext } from "react"

const headings = [
  {
    id: "ranking",
    title: "Ranking"
  },
  {
    id: "company",
    title: "Organisation"
  },
  {
    id: "industry",
    title: "Industry"
  },
  {
    id: "country",
    title: "Country"
  },
  {
    id: "awards",
    title: "Awards"
  },
  {
    id: "happinessScore",
    title: "Happiness Score"
  }
]

const mobileHeadings = [
  {
    id: "ranking/company",
    title: "Ranking/Company"
  },
  {
    id: "industry/country",
    title: "Industry/Country"
  },
  {
    id: "awards",
    title: "Awards"
  },
  {
    id: "happinessScore",
    title: "Happiness Score"
  }
]

const config = {
  stylesPerColumn: [
    {
      fontSize: [5, 6, 6],
      fontWeight: 600,
      color: "dark.1",
      fontFamily: "Tiempos"
    },
    {
      fontSize: [3, 4, 4],
      fontWeight: 600,
      color: "primary.0"
    },
    {
      fontSize: [3, 4, 4]
    },
    {
      fontSize: [3, 4, 4]
    },
    { fontSize: [3, 4, 4], fontWeight: 600 },
    {
      fontSize: 6,
      fontWeight: 500
    }
  ],
  columnWidths: [8 / 100, 18 / 100, 18 / 100, 18 / 100, 15 / 100, 15 / 100],
  main: {
    borderRadius: "8px"
  },
  heading: {
    fontSize: [1, 3, 3],
    fontWeight: 500,
    color: "dark.2",
    py: 3
  },
  head: {
    bg: "dark.7"
  },
  data: {
    py: 3,
    borderTop: "1px solid",
    borderColor: "dark.6"
  }
}

const mobileConfig = {
  stylesPerColumn: [
    {
      fontSize: [5, 6, 6],
      fontWeight: 600,
      color: "dark.1",
      fontFamily: "Tiempos"
    },
    {
      fontSize: [3, 4, 4]
    },
    { fontSize: [3, 4, 4], fontWeight: 600 },
    {
      fontSize: 6,
      fontWeight: 500
    }
  ],
  columnWidths: [31 / 100, 31 / 100, 15 / 100, 15 / 100],
  main: {
    borderRadius: "8px"
  },
  heading: {
    fontSize: 2,
    fontWeight: 500,
    color: "dark.2",
    py: 3
  },
  head: {
    bg: "dark.7"
  },
  data: {
    py: 3,
    borderTop: "1px solid",
    borderColor: "dark.6"
  }
}

const context = createContext()

export { headings, config, context, mobileConfig, mobileHeadings }
