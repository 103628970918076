import React from "react"
import { Flex, Box, H3, H4, Text, Card } from "@engaging-tech/components"

import Thumbnail from "../../Attachments/Thumbnail"
import CategoryChips from "./CategoryChips"

const getFullName = user => `${user.firstName} ${user.lastName}`

const PodcastPreview = ({ podcast, interactions }) => (
  <Card
    flexDirection={["column", "row"]}
    p={3}
    mb={3}
    bg="light.0"
    width={1 / 1}
  >
    <Thumbnail
      hasPlayButton
      minHeight={["186px", "200px"]}
      width={[1, 1 / 3]}
      mb={[3, 0]}
      src={podcast.coverImage.source}
    />
    <Box ml={[0, 3]} width={1 / 1}>
      <Flex width={[1 / 1]} flexDirection="row" justifyContent="space-between">
        <Box width={[1 / 1]}>
          <H3 fontSize={4} fontWeight={500} mb={1}>
            {podcast.title}
          </H3>
          <H4 color="dark.2" fontSize={3} mb={3}>
            {podcast.series && podcast.series.title
              ? `${podcast.series.title} by ${getFullName(podcast.user)}`
              : getFullName(podcast.user)}
          </H4>
        </Box>
        {interactions()}
      </Flex>
      <Box mb={3}>
        <Text color="dark.2" lineHeight="1.4">
          {podcast.summary}
        </Text>
      </Box>
      {podcast.categories.length > 0 && (
        <CategoryChips ids={podcast.categories} />
      )}
    </Box>
  </Card>
)

export default PodcastPreview
