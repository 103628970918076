import { Box, Button, Card, Flex } from "@engaging-tech/components"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import Title from "./title"

const SurveyCard = ({
  children,
  title,
  onNext,
  onPrevious,
  isLastQuestion,
  isValid = true,
  answer,
  noActions,
  isLastSection,
  type
}) => {
  const handleKeyPress = event => {
    event.preventDefault()

    const handleNextKeyCodes = [39, 13]
    const handlePreviousKeyCodes = [37]
    const keyCode = event.which

    if (!(isLastQuestion && isLastSection) && handleNextKeyCodes.includes(keyCode) && isValid) {
      onNext()
    } else if (handlePreviousKeyCodes.includes(keyCode)) {
      onPrevious()
    }
  }

  useEffect(() => {
    if (type !== "short-text") {
      window.addEventListener("keydown", handleKeyPress)

      return () => window.removeEventListener("keydown", handleKeyPress)
    }

    return undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, answer])

  const { t } = useTranslation(["organisation_survey"])

  const rightButtonText =
    isLastQuestion && isLastSection ? t("code_check.submit", "") : t("questions.next", "")

  const leftButtonText = t("questions.previous", "")

  return (
    <Card bg="light.0">
      {title && <Title>{title}</Title>}
      <Box p={3}>{children}</Box>
      {!noActions && (
        <Flex flexDirection="row-reverse" justifyContent="space-between" p={3} pt={0}>
          <Box width="auto">
            {onNext && (
              <Button variant="primary.0" onClick={onNext} disabled={!isValid}>
                {rightButtonText}
              </Button>
            )}
          </Box>
          <Box width="auto">
            {onPrevious && (
              <Button variant="text.dark.2" elevation={0} onClick={onPrevious}>
                {leftButtonText}
              </Button>
            )}
          </Box>
        </Flex>
      )}
    </Card>
  )
}

export default SurveyCard
