import React from "react"

import { Checkout } from "../../../features/gardners"
import AuthGuard from "../../../features/ui/containers/AuthGuard"
import GlobalHubSideColumnLayout from "../../../layouts/GlobalHubSideColumnLayout"

const CheckoutPage = () => (
  <AuthGuard>
    <GlobalHubSideColumnLayout sideColumn={() => <Checkout.Summary />} isSticky>
      <Checkout.Payment />
    </GlobalHubSideColumnLayout>
  </AuthGuard>
)

export default CheckoutPage
