import React from "react"

import ComparisonFooter from "./ComparisonFooter"
import ComparisonSelector from "./ComparisonSelector"

const ComparisonCard = ({ onCancel, ...props }) => (
  <>
    <ComparisonSelector {...props} />
    <ComparisonFooter onCancel={onCancel} />
  </>
)

export default ComparisonCard
