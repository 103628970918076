/* eslint-disable import/prefer-default-export */
import { gql } from "../../../lib/Apollo"

export const LOAD_SURVEYS = gql`
  query {
    getSurveySubmissions {
      id
      surveyId
      surveyType
      companyName
      completedAt
      score
    }
  }
`
export const LOAD_ALL_WIDGETS = gql`
  query {
    getAllProfileWidgets {
      id
    }
  }
`

export const UPDATE_MY_WIDGETS = gql`
  mutation updateMyProfile($widgetLayout: WidgetLayoutInput!) {
    updateMyProfile(widgetLayout: $widgetLayout) {
      widgetLayout {
        mobile {
          id
          x
          y
          h
          w
        }
        tablet {
          id
          x
          y
          h
          w
        }
        desktop {
          id
          x
          y
          h
          w
        }
      }
    }
  }
`

export const LOAD_ALL_GOALS = gql`
  query {
    getAllProfileGoals {
      id
      key
      text
    }
  }
`
