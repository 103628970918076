const aboutBooks = [
  {
    image:
      "https://media.engaging.works/production/media/siteImages/SixSteps-book.png",
    title: "Six Steps to Workplace Happiness",
    children: [
      "Accompanying Mark’s book Fairness For All this is a ‘how to’ guide to workplace happiness. With helpful space for making your own notes and lots of practical suggestions.",
      "Six Steps to Workplace Happiness looks at: reward and recognition, information sharing, empowerment, wellbeing, Instilling pride and job satisfaction. However it’s not a one-size-fits-all solution. You can tailor practical steps according to what your company needs. Each one of these steps makes a substantial difference, but the decision is yours on how important it is to make your employees feel better informed in your organisation, or the benefits of giving them more power and influence, or whether wellbeing should be the top of your priority list."
    ],
    id: "/74424",
    bg: ""
  },
  {
    image:
      "https://media.engaging.works/production/media/siteImages/Fairness-book.png",
    title: "Fairness For All",
    children: [
      "This is the first time a book has ever explored the unique and much admired model of the John Lewis Partnership. Through looking at it alongside best practice from other companies, Mark Price demonstrates how to help all businesses be more successful.",
      "Delivered in easy-to-digest sections, Part One of the book looks at the principle behind the theory of being employee inclusive whilst Part Two explains how to deliver in practical terms, using the six steps to workplace happiness.",
      "This book focuses on inclusive capitalism, and acting responsibly to all stakeholders is central to this theme, with employees, the most important stakeholders, the drivers of it all.",
      "This book is about another way to do things... A fairer way..."
    ],
    id: "/30421",
    bg: "secondary.4"
  },
  {
    image:
      "https://media.engaging.works/production/media/siteImages/Fables-book.png",
    title: "Workplace Fables",
    children: [
      "True stories from one of the UK’s most experienced businessmen. After thirty years of life in the John Lewis Partnership Mark Price imparts some wisdom and gentle humour with these workplace fables.",
      "Divided into thirteen chapters each containing around ten fables this is a heartwarming (and highly instructive) look at best practice in business and how to learn from your mistakes."
    ],
    id: "/70575",
    bg: ""
  }
]

export default aboutBooks
