/* eslint-disable import/prefer-default-export */
import { gql } from "../../../lib/Apollo"

export const LOAD_SELECTED_CV = gql`
  query getSelectedCV($id: ID!) {
    getSelectedCV(id: $id) {
      id
      userId
      positionsHeld {
        id
        jobRole {
          id
        }
        industry {
          id
        }
        jobTitle
        location {
          name
          country {
            name
          }
        }
        company
        startDate
        endDate
        description
      }
      targetPosition {
        jobTitle
        company
      }
      defaultCv
      phone
      email
      address {
        name
        country {
          name
          shortName
        }
      }
      skills {
        id
      }
      references
      hobbies {
        id
      }
      personalStatement
      qualifications {
        id
        institution
        courseTitle
        location {
          name
          country {
            name
          }
        }
        description
        start
        end
      }
      createdAt
    }
  }
`

export const LOAD_MY_CVS = gql`
  query {
    getCVs {
      id
      userId
      positionsHeld {
        jobRole {
          id
        }
        industry {
          id
        }
        jobTitle
        location {
          name
          country {
            name
          }
        }
        company
        startDate
        endDate
        description
      }
      targetPosition {
        jobTitle
        company
      }
      defaultCv
      phone
      email
      address {
        name
        country {
          name
          shortName
        }
      }
      skills {
        id
      }
      references
      hobbies {
        id
      }
      personalStatement
      qualifications {
        id
        institution
        courseTitle
        location {
          name
          country {
            name
          }
        }
        description
        start
        end
      }
      createdAt
    }
  }
`

export const CREATE_CV = gql`
  mutation setCV(
    $id: ID
    $address: ProfileCreationLocationInput
    $hobbies: [String]
    $personalStatement: String
    $phone: String!
    $positionsHeld: [PositionHeldInput]
    $qualifications: [QualificationsInput]
    $references: Boolean
    $skills: [String]
    $languages: [String]
    $targetPosition: TargetPositionInput
    $defaultCv: Boolean
    $email: String
  ) {
    setCV(
      id: $id
      address: $address
      hobbies: $hobbies
      personalStatement: $personalStatement
      phone: $phone
      positionsHeld: $positionsHeld
      qualifications: $qualifications
      references: $references
      skills: $skills
      languages: $languages
      targetPosition: $targetPosition
      defaultCv: $defaultCv
      email: $email
    ) {
      id
      userId
      personalStatement
      positionsHeld {
        jobRole {
          id
        }
        industry {
          id
        }
        jobTitle
        location {
          name
          country {
            name
          }
        }
        company
        startDate
        endDate
        description
      }
      targetPosition {
        jobTitle
        company
      }
      defaultCv
      phone
      email
      address {
        name
        country {
          name
          shortName
        }
      }
      skills {
        id
      }
      references
      hobbies {
        id
      }
      personalStatement
      qualifications {
        id
        institution
        courseTitle
        location {
          name
          country {
            name
          }
        }
        description
        start
        end
      }
      createdAt
    }
  }
`

export const DELETE_CV = gql`
  mutation deleteCV($id: ID!) {
    deleteCV(id: $id)
  }
`

export const SET_DEFAULT_CV = gql`
  mutation setDefaultCV($id: ID!) {
    setDefaultCV(id: $id)
  }
`
