import React from "react"
import { Flex, Text, Icon } from "@engaging-tech/components"

const CommentCounter = ({ count }) => (
  <Flex alignItems="center" flexDirection="row-reverse">
    <Icon ml={2} name="chat_bubble" color={count ? "primary.0" : "dark.2"} />
    <Text fontSize={2} color="dark.2" width="auto">
      {`${count} ${count === 1 ? "comment" : "comments"}`}
    </Text>
  </Flex>
)

export default CommentCounter
