export const types = {
  LOAD: "[Premium] Load",
  LOAD_SUCCESS: "[Premium] Load Success",
  LOAD_FAIL: "[Premium] Load Fail",
  STRIPE_CHECKOUT: "[Premium] Stripe Checkout",
  STRIPE_CHECKOUT_SUCCESS: "[Premium] Stripe Checkout Success",
  STRIPE_CHECKOUT_FAIL: "[Premium] Stripe Checkout Fail"
}

export const load = payload => ({
  type: types.LOAD,
  payload
})

export const loadSuccess = payload => ({
  type: types.LOAD_SUCCESS,
  payload
})

export const loadFail = () => ({
  type: types.LOAD_FAIL
})

export const stripeCheckout = payload => ({
  type: types.STRIPE_CHECKOUT,
  payload
})

export const stripeCheckoutSuccess = payload => ({
  type: types.STRIPE_CHECKOUT_SUCCESS,
  payload
})

export const stripeCheckoutFail = () => ({
  type: types.STRIPE_CHECKOUT_FAIL
})
