import React from "react"
import {
  Flex,
  Text,
  Icon,
  Avatar,
  CustomHooks
} from "@engaging-tech/components"
import styled from "styled-components"
import { AnimatePresence } from "framer-motion"
import { titleCaps } from "@engaging-tech/frontend-utils"
import { usePaths, useRouter } from "@engaging-tech/routing"

import PremiumHeading from "./PremiumHeading"
import ProgressBar from "../../../ui/components/ProgressBar"
import WidgetCard from "../../components/WidgetCard"

import ProfileSettingsModal from "../containers/ProfileSettingsModal/ProfileSettingsModal"
import ManageSubscriptionModal from "../containers/ProfileSettingsModal/ManageSubscriptionModal"
import CancelledMembershipModal from "../containers/ProfileSettingsModal/CancelledMembershipModal"

const StyledIcon = styled(Icon)`
  cursor: pointer;
  transform: translate(25px, 10px) !important;
  transition: 0.25s;
  position: absolute;
  right: 0px;
  transform: rotate(0deg);
  :hover {
    transition: 0.25s;
    transform: rotate(90deg);
  }
`

const StyledText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  width: auto;
  font-size: 16px;
  text-align: center;
`

const holdingImage = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const Modals = {
  settings: <ProfileSettingsModal />,
  subscription: <ManageSubscriptionModal />,
  cancelled: <CancelledMembershipModal />
}

const ProfileWidget = ({
  isPremium,
  firstName,
  lastName,
  jobTitle,
  company,
  setShowModal,
  showModal,
  picture,
  profileCompletionPercentage,
  elevation = 2
}) => {
  const paths = usePaths()
  const router = useRouter()
  const device = CustomHooks.useClientDevice()
  const name = `${firstName && titleCaps(firstName)} ${lastName &&
    titleCaps(lastName)}`

  if (router.isMatch(paths.careerDeveloper.yourProfile) && !showModal) {
    setShowModal("settings")
  }

  return (
    <WidgetCard
      elevation={elevation}
      width="100%"
      height="100%"
      bg="#CCFBE2"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      pt={isPremium ? 0 : 10}
    >
      {isPremium && <PremiumHeading />}
      <Flex position="relative" width={device === "MOBILE" ? 70 : 100}>
        <StyledIcon
          name="settings"
          color="primary.0"
          isPremium={isPremium}
          onClick={() => {
            setShowModal("settings")
            router.navigate(paths.careerDeveloper.yourProfile)
          }}
        />

        <Avatar
          picture={picture || holdingImage}
          size={device === "MOBILE" ? 70 : 100}
          mb={[1, 3]}
          mt={2}
          style={{ objectFit: "cover" }}
        />
      </Flex>

      <StyledText mb={[1, 3]} color="dark.1" fontWeight={700}>
        {firstName ? name : "My Name"}
      </StyledText>
      <StyledText color="dark.2">{jobTitle || "My Job Title"}</StyledText>
      <StyledText color="dark.2" mb={[3, "58px", "50px"]}>
        {company || "My Company"}
      </StyledText>
      <Flex
        width={1 / 1}
        height="auto"
        flexDirection="column"
        style={{ borderRadius: "0px 0px 4px 4px" }}
      >
        <ProgressBar height="5px" percentage={profileCompletionPercentage} />
        <Flex
          width={1 / 1}
          bg="light.0"
          py={2}
          px={3}
          justifyContent="space-between"
          borderRadius="0px 0px 7px 7px"
        >
          <Text width="auto" minWidth="max-content" fontSize={2}>
            Profile completion
          </Text>
          <Text fontSize={3} fontWeight={700} width="auto">
            {profileCompletionPercentage}%
          </Text>
        </Flex>
      </Flex>
      <AnimatePresence>{showModal && Modals[showModal]}</AnimatePresence>
    </WidgetCard>
  )
}

export default ProfileWidget
