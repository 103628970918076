import { connect } from "react-redux"

import { getIsPremium } from "../../../yourProfile/store/yourProfile.selectors"
import { getSurveyTemplateId } from "../../store/surveyResults.selectors"
import Modal from "../components/Modal/Modal"
import * as Selectors from "../store/actionplans.selectors"

const mapState = (state, props) => {
  const { section, furtherReading = true } = props
  return {
    isPremium: getIsPremium(state),
    stepResult: Selectors.getStepResult(state, section),
    surveyTemplateId: getSurveyTemplateId(state),
    furtherReading
  }
}

const ActionPlanModal = connect(mapState)(Modal)

export default ActionPlanModal
