import { call, put, select, takeLatest } from "redux-saga/effects"

import { AccountSelectors } from "../../account"
import { attachUserToSubmission, load, submit } from "../services/happinessSurvey.service"
import * as Actions from "./happinessSurvey.actions"
import {
  getCompanyId,
  getCompanyName,
  getFormattedSubmission,
  getIsTestSurvey,
  getSavantaId,
  getSavantaTerritory
} from "./happinessSurvey.selectors"

export function* loadSaga(action) {
  try {
    const res = yield call(load, action.payload)
    yield put(Actions.loadSuccess(res.data.getSurvey))
  } catch {
    yield put(Actions.loadFail())
  }
}

export function* submitSaga() {
  try {
    const isAuthenticated = yield select(AccountSelectors.getIsAuthenticated)
    const completedSurvey = yield select(getFormattedSubmission)
    const isTestSurvey = yield select(getIsTestSurvey)
    const savantaId = yield select(getSavantaId)
    const savantaTerritory = yield select(getSavantaTerritory)
    const companyName = yield select(getCompanyName)
    const companyId = yield select(getCompanyId)

    if (isTestSurvey) {
      yield put(Actions.submitSuccess())
    } else {
      const res = yield call(submit, {
        ...completedSurvey,
        savantaId,
        companyName,
        savantaTerritory,
        surveyCompanyId: companyId
      })
      const submissionId = res.data.createSurveySubmission

      yield put(Actions.submitSuccess(submissionId, isAuthenticated))
    }
  } catch (e) {
    yield put(Actions.submitFail())
  }
}

export function* attachUserToSubmissionSaga(action) {
  try {
    const submissionId = action.payload
    const isAuthenticated = yield select(AccountSelectors.getIsAuthenticated)

    yield call(attachUserToSubmission, submissionId, isAuthenticated)

    yield put(Actions.attachUserSuccess())
  } catch (error) {
    yield put(Actions.attachUserFail())
  }
}

export function* saga() {
  yield takeLatest(Actions.types.LOAD, loadSaga)
  yield takeLatest(Actions.types.SUBMIT, submitSaga)
  yield takeLatest(Actions.types.ATTACH_USER, attachUserToSubmissionSaga)
}
