import React from "react"
import styled from "styled-components"
import { Flex, Box, Text } from "@engaging-tech/components"

import categories from "../../lib/categories"

const Chip = styled(Box)`
  flex-shrink: 0;
`

const BookCategoryChip = ({ code }) => (
  <Chip
    mb={2}
    mr={[0, 2]}
    borderRadius={99}
    bg="dark.7"
    width="auto"
    py={2}
    px={3}
  >
    <Text fontSize={3}>
      {categories.find(category => category.code === code) &&
        categories.find(category => category.code === code).description}
    </Text>
  </Chip>
)

const BookCategoryChips = ({ classifications }) => {
  return (
    <Flex mt={3} flexDirection={["column", "row"]} flexWrap="wrap">
      {classifications.map(classification => {
        if (classification) {
          return (
            <BookCategoryChip
              key={classification.code}
              code={classification.code}
            />
          )
        }
        return <></>
      })}
    </Flex>
  )
}

export default BookCategoryChips
