import { connect } from "react-redux"
import "url-search-params-polyfill"
import { load, setCode, checkCode, setSavantaId } from "../store/survey.actions"
import { getHasLoaded, getIsLoading } from "../store/survey.selectors"

const mapState = state => ({
  hasLoaded: getHasLoaded(state),
  isLoading: getIsLoading(state)
})

const mapDispatch = dispatch => ({
  onLoadSurvey: id => dispatch(load(id)),
  onSetCode: code => dispatch(setCode(code)),
  onCheckCode: (code, surveyId) => dispatch(checkCode(code, surveyId)),
  onSavantaId: savantaId => dispatch(setSavantaId(savantaId))
})

const surveyLoaderContainer = connect(mapState, mapDispatch)

export default surveyLoaderContainer
