/* eslint-disable react/no-array-index-key */
import { Flex, MotionBox, MotionFlex, Text } from "@engaging-tech/components"
import { useField } from "formik"
import React, { useState } from "react"
import styled from "styled-components"

import InputLoadError from "./InputLoadError"

const RadioDot = styled(MotionFlex)`
  width: ${({ buttonSize }) => buttonSize}px;
  height: ${({ buttonSize }) => buttonSize}px;
  flex-shrink: 0;
  border-style: solid;
  border-width: ${({ buttonSize }) => (buttonSize / 100) * 10}px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`

const RadioInsideDot = styled(MotionBox)`
  width: ${({ buttonSize }) => buttonSize / 2}px;
  height: ${({ buttonSize }) => buttonSize / 2}px;
  border-radius: 50%;
`

const RadioButton = ({
  label,
  id,
  onChange,
  selected,
  buttonSize,
  fontSize,
  color,
  labelTextColor,
  error
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const insideDotVariants = {
    selected: { scale: 1, opacity: 1 },
    hovered: { scale: 0.7, opacity: 0.8 },
    notHoveredOrSelected: { scale: 0, opacity: 0.8 }
  }

  const calculateVariant = () => {
    if (selected) {
      return "selected"
    }
    if (isHovered) {
      return "hovered"
    }
    return "notHoveredOrSelected"
  }

  return (
    <Flex alignItems="center" id={id} key={id} flexDirection="row" width="auto" mr={4} ml={0} mb={2} mt={2}>
      <RadioDot
        onTap={() => onChange(id)}
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
        buttonSize={buttonSize}
        borderColor={error ? "error.0" : color}
      >
        <RadioInsideDot
          variants={insideDotVariants}
          initial="notHoveredOrChecked"
          animate={calculateVariant()}
          buttonSize={buttonSize}
          bg={error ? "error.0" : color}
        />
      </RadioDot>
      <Text fontSize={fontSize} ml={3} color={error ? "error.0" : labelTextColor}>
        {label}
      </Text>
    </Flex>
  )
}

const RadioButtonInputHandler = ({
  label,
  radioOptions,
  color = "primary.1",
  buttonSize = 24,
  fontSize = 4,
  labelTextColor = "primary.0",
  name,
  errorTextOverride
}) => {
  const [field, meta, helpers] = useField({ name })

  if (!("value" in field)) {
    return <InputLoadError />
  }

  const onChangeIntercept = id => {
    helpers.setTouched(true, true)
    helpers.setValue(id)
  }

  const generateRadioButtons = options => {
    return options.map((option, index) => (
      <RadioButton
        key={index}
        label={option.optionLabel}
        id={option.id}
        onChange={onChangeIntercept}
        selected={field.value === option.id}
        color={color}
        buttonSize={buttonSize}
        fontSize={fontSize}
        labelTextColor={labelTextColor}
        error={meta.error && meta.touched}
      />
    ))
  }

  return (
    <Flex width={1 / 1} flexDirection="column" mt={2} mb={2} name={name} type="input" id={name}>
      <Text mb={2}>{label}</Text>
      <Flex width={1 / 1} flexWrap="wrap" flexDirection="row" mt={2} mb={2}>
        {generateRadioButtons(radioOptions)}
      </Flex>
      {meta.error && meta.touched && (
        <Text ml={2} fontSize={2} color="error.0">
          {errorTextOverride || meta.error}
        </Text>
      )}
    </Flex>
  )
}

export default RadioButtonInputHandler
