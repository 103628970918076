/* eslint-disable import/prefer-default-export */
import { getConfig } from "@engaging-tech/ssr-config"
import { API, Amplify } from "aws-amplify"

import { LOAD_DEMOGRAPHIC_FILTERS } from "./demographicFilter.queries"

export const loadDemographicFilters = () => {
  const config = getConfig()
  Amplify.configure({
    aws_appsync_graphqlEndpoint: config.appSync.publicGatewayURL,
    aws_appsync_authenticationType: config.appSync.publicGatewayAuthType,
    aws_appsync_apiKey: config.appSync.publicGatewayApiKey,
    aws_appsync_region: config.appSync.publicGatewayRegion
  })

  return API.graphql({
    query: LOAD_DEMOGRAPHIC_FILTERS
  })
}
