import React from "react"
import { Flex, Spinner } from "@engaging-tech/components"
import ComparisonBox from "./ComparisonBox"

const ComparisonSelector = ({ isLoading, products, ...props }) => {
  if (isLoading) {
    return <Spinner color="primary.0" width={1 / 1} />
  }

  return (
    <>
      <Flex
        mt={6}
        flexDirection={["column", "row"]}
        mb={5}
        alignItems="stretch"
        height="100%"
        justifyContent="space-between"
      >
        {products.map(item => (
          <ComparisonBox key={item.product.title} product={item} {...props} />
        ))}
      </Flex>
    </>
  )
}

export default ComparisonSelector
