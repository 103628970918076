import { gql } from "../../../lib/Apollo"

export const LOAD_MY_MENTOR_MATCHES = gql`
  query getMyMentorMatches {
    getMyMentorMatches {
      mentors {
        id
        user {
          id
          isConnected
          firstName
          lastName
          picture {
            small
          }
          location {
            point {
              coordinates
            }
            country {
              name
              shortName
            }
            name
            countryId
          }
          jobTitle
          companyName
        }
        score
        status
        matchedCriteria {
          industries {
            id
            name
          }
          skills {
            id
            name
          }
          jobRoles {
            id
            name
          }
          experience
          languages {
            code
            name
            nativeName
          }
          hobbies {
            id
            name
          }
        }
      }
      mentees {
        id
        user {
          id
          isConnected
          firstName
          lastName
          picture {
            small
          }
          location {
            point {
              coordinates
            }
            country {
              name
              shortName
            }
            countryId
            name
          }
          jobTitle
          companyName
        }
        score
        status
        matchedCriteria {
          industries {
            id
            name
          }
          skills {
            id
            name
          }
          jobRoles {
            id
            name
          }
          experience
          languages {
            code
            name
            nativeName
          }
          hobbies {
            id
            name
          }
        }
      }
    }
  }
`

export const DELETE_MATCH = gql`
  mutation deleteMentorMatch($id: ID!) {
    deleteMentorMatch(id: $id)
  }
`
