import React from "react"

import {
  Container,
  ItemContainer,
  ItemGraphActiveArea,
  ItemGraphActiveAreaDescription,
  ItemGraphContainer,
  ItemGraphWrapper,
  ItemTitle
} from "./kpis.styled"

const Kpis = ({ companyScore }) => {
  const companyScoreRounded = companyScore ? `${Math.round(companyScore)}%` : "N/A"

  return (
    <Container>
      <ItemContainer>
        <ItemTitle>Happiest Workplaces Score:</ItemTitle>
        <ItemGraphWrapper ml={10}>
          <ItemGraphContainer>
            <ItemGraphActiveArea width={companyScore || 0} />
          </ItemGraphContainer>
        </ItemGraphWrapper>
        <ItemGraphActiveAreaDescription>{companyScoreRounded}</ItemGraphActiveAreaDescription>
      </ItemContainer>
    </Container>
  )
}

export default Kpis
