const CVTableConfig = {
  stylesPerColumn: [
    {
      fontSize: 4,
      fontWeight: 600,
      color: "primary.0",
      textAlign: "left"
    },
    {
      fontSize: 4,
      fontWeight: 500,
      color: "primary.0",
      textAlign: "left"
    },
    {
      fontSize: 4,
      textAlign: "left"
    },
    { textAlign: "center" },
    { textAlign: "left" }
  ],
  columnWidths: [25 / 100, 20 / 100, 20 / 100, 15 / 100, 20 / 100],
  main: {
    borderRadius: "10px"
  },
  heading: {
    fontSize: 4,
    fontWeight: 500,
    color: "dark.2",
    py: 3,
    textAlign: "left",
    px: 3
  },
  head: {
    bg: null
  },
  data: {
    py: 3,
    borderTop: "1px solid",
    borderColor: "dark.6",
    px: 3
  }
}

const CVColumnHeaders = [
  {
    id: "role",
    title: "Role"
  },
  {
    id: "company",
    title: "Company"
  },
  {
    id: "date",
    title: "Date Created"
  },
  {
    id: "isDefault",
    title: "Default"
  },
  {
    id: "edit",
    title: "Edit"
  }
]

export { CVTableConfig, CVColumnHeaders }
