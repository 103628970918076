import { types } from "./cvLibrary.actions"

export const initialState = {
  isLoadingCVs: false,
  hasLoaded: false,
  hasError: false,
  cvs: null,
  selectedCV: null
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_SELECTED_CV:
    case types.DELETE_CV:
    case types.SET_DEFAULT_CV:
      return {
        ...state,
        isLoading: true
      }
    case types.CREATE_CV:
      return {
        ...state,
        isLoading: true,
        isCreatingCV: true,
        hasCreatedCV: false
      }
    case types.LOAD_CVS:
      return {
        ...state,
        isLoading: true,
        isLoadingCVs: true
      }

    case types.LOAD_CVS_SUCCESS:
      return {
        ...state,
        cvs: action.payload,
        isLoading: false,
        isLoadingCVs: false,
        hasLoaded: true,
        selectedCV: null
      }
    case types.LOAD_SELECTED_CV_SUCCESS:
      return {
        ...state,
        selectedCV: action.payload,
        isLoading: false,
        hasLoaded: true
      }
    case types.SET_HAS_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: action.payload
      }
    case types.LOAD_CVS_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoadingCVs: false,
        hasLoaded: true,
        hasCreatedCV: false,
        isCreatingCV: false
      }
    case types.LOAD_SELECTED_CV_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoadingCVs: false,
        hasLoaded: true
      }

    case types.CREATE_CV_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedCV: action.payload,
        isCreatingCV: false,
        hasCreatedCV: true
      }

    case types.CREATE_CV_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoadingCVs: false,
        hasLoaded: true,
        hasCreatedCV: false,
        isCreatingCV: false
      }
    default:
      return state
  }
}
