import { connect } from "react-redux"
import SavedContentWidgetComponent from "../components/SavedContentWidget"

import { getBookmarkedMaterials } from "../../../bookmarks/store/bookmark.selectors"

const mapState = state => ({
  bookmarks: getBookmarkedMaterials(state)
})

const SavedContentWidget = connect(mapState)(SavedContentWidgetComponent)

export default SavedContentWidget
