import React from "react"
import { Helmet } from "react-helmet"

import AboutUs from "../../features/aboutUs/components/AboutUs"
import CompanyValues from "../../features/aboutUs/components/CompanyValues"
import MeetTheTeam from "../../features/aboutUs/components/MeetTheTeam"
import OurBooks from "../../features/aboutUs/components/OurBooks"
import SixSteps from "../../features/aboutUs/components/SixSteps"
import BaseLayout from "../../layouts/BaseLayout"

const About = () => (
  <BaseLayout isPublic>
    <Helmet>
      <title>About Us | Online Business Networking & Corporate Messenger App</title>
      <meta
        name="description"
        content="At WorkL we help you to find jobs in the happiest companies, develop your career with personalised advice and resources and build your network and knowledge to have a happy and successful time at work"
      />
    </Helmet>
    <AboutUs />
    <CompanyValues />
    <SixSteps />
    <OurBooks />
    <MeetTheTeam />
  </BaseLayout>
)

export default About
