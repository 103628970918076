import { Box, Button, Flex, TextField } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React from "react"

import AccountDialog from "../../../ui/components/AccountDialog"
import PasswordHint from "../../components/PasswordHint"

export default function ResetPasswordFormContent({
  handleSubmit,
  errors,
  touched,
  values,
  isValid,
  handleChange,
  handleBlur,
  isLoading,
  siteLogo,
  cancelRoute,
  email
}) {
  return (
    <AccountDialog title="Forgotten your password?" subtitle="Reset your password" renderLogo={siteLogo}>
      <form onSubmit={handleSubmit}>
        <Box display="none">
          <input
            width={1 / 1}
            id="email"
            name="email"
            type="hidden"
            label="Email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={email}
          />
        </Box>
        <Box display="none">
          <TextField
            width={1 / 1}
            id="code"
            name="code"
            type="hidden"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.code}
            error={touched.code && errors.code}
          />
        </Box>
        <Box mb={2}>
          <TextField
            width={1 / 1}
            id="sign-up-password"
            name="password"
            type="password"
            borderRadius="4px 4px 0px 0px"
            label="Password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            error={touched.password && errors.password}
          />
        </Box>
        <Box mb={2}>
          <TextField
            width={1 / 1}
            id="sign-up-confirm-password"
            name="confirmPassword"
            type="password"
            borderRadius="4px 4px 0px 0px"
            label="Confirm password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.confirmPassword}
            error={touched.confirmPassword && errors.confirmPassword}
          />
        </Box>
        <PasswordHint />
        <Flex width={1 / 1} flexDirection="row-reverse" alignItems="center">
          <Button variant="secondary.0" type="submit" disabled={!isValid || isLoading} ml={2} width="auto">
            Submit
          </Button>
          <Link to={cancelRoute}>
            <Button variant="secondary.4" type="button" ml={2}>
              Cancel
            </Button>
          </Link>
        </Flex>
      </form>
    </AccountDialog>
  )
}
