import React from "react"
import { Flex, Spinner, Button, Link } from "@engaging-tech/components"
import { Formik } from "formik"
import * as Yup from "yup"
import { usePaths, useRouter } from "@engaging-tech/routing"
import ProfileImage from "../../containers/ProfileImage"
import EditProfileForm from "./EditProfileForm"

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(50, "Must be less than or equal to 50 characters")
    .required("Required"),
  lastName: Yup.string()
    .max(50, "Must be less than or equal to 50 characters")
    .required("Required"),
  location: Yup.object().shape({
    name: Yup.string(),
    countryId: Yup.string(),
    country: Yup.object().shape({
      name: Yup.string(),
      shortName: Yup.string()
    })
  }),
  jobTitle: Yup.string().max(50, "Must be less than or equal to 50 characters"),
  companyName: Yup.string().max(
    50,
    "Must be less than or equal to 50 characters"
  ),
  websiteUrl: Yup.string().max(
    50,
    "Must be less than or equal to 50 characters"
  )
})

const ManageProfile = ({
  setVisibility,
  profile,
  submitProfile,
  forwardedRef
}) => {
  const paths = usePaths()
  const router = useRouter()
  const {
    firstName,
    location,
    lastName,
    jobTitle,
    companyName,
    websiteUrl,
    currentGoal,
    employmentStatus
  } = profile || {}

  const initialValues = {
    firstName: firstName || "",
    lastName: lastName || "",
    location: {
      countryId: location?.country?.id || null,
      name: location ? location.name : "",
      coordinates: {
        lat: location ? location.point.coordinates[0] : "",
        lng: location ? location.point.coordinates[1] : ""
      },
      country: {
        shortName: location?.country?.shortName || " ",
        name: location?.country?.name || ""
      }
    },
    jobTitle: jobTitle || "",
    companyName: companyName || "",
    websiteUrl: websiteUrl || "",
    currentGoal: currentGoal || "",
    employmentStatus: employmentStatus || ""
  }

  const handleSubmit = values => {
    submitProfile({
      ...values,
      picture: { small: profile.picture.small, large: profile.picture.large }
    })
    setVisibility(false)
    router.navigate(paths.careerDeveloper.index)
  }

  return (
    <>
      <Flex
        flexDirection="column"
        width={1 / 1}
        ref={forwardedRef}
        height="100%"
      >
        {profile ? (
          <>
            <ProfileImage />
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              validateOnMount
            >
              {props => {
                return (
                  <EditProfileForm
                    {...props}
                    setVisibility={setVisibility}
                    mt={[0, 3]}
                  />
                )
              }}
            </Formik>
          </>
        ) : (
          <Flex
            width="100%"
            height="100%"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Spinner color="primary.0" width={1 / 1} />
            <Link
              to={paths.careerDeveloper.index}
              onClick={() => {
                setVisibility(false)
              }}
            >
              <Button
                type="button"
                elevation={0}
                color="primary.0"
                bg="light.0"
                mx="auto"
                mt={4}
              >
                Cancel
              </Button>
            </Link>
          </Flex>
        )}
      </Flex>
    </>
  )
}

export default ManageProfile
