import { types } from "./developCareer.actions"
import layoutManager from "../helpers/layoutManager"

export const initialState = {
  surveys: { data: [], error: { message: "", type: null }, loading: false },
  allWidgets: { data: [], error: { message: "", type: null }, loading: false },
  allGoals: { data: [], error: { message: "", type: null }, loading: false },
  widgetLayout: {
    data: { tablet: [], desktop: [], mobile: [] },
    error: { message: "", type: null },
    loading: false
  },
  localWidgetLayout: {
    data: { tablet: [], desktop: [], mobile: [] },
    error: { message: "", type: null },
    loading: false
  },
  contentForYou: {
    data: {
      articles: [],
      podcasts: [],
      events: []
    },
    error: { message: "", type: null },
    loading: false
  },
  optionBoxesClose: 0,
  gridCustomisationFlag: false
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.POST_LAYOUT:
      // Posts local layout to backend
      return {
        ...state,
        widgetLayout: {
          ...state.widgetLayout,
          loading: true
        }
      }
    case types.LOAD_CONTENT_FOR_YOU:
      // Request posts for content for you widget
      return {
        ...state,
        contentForYou: {
          ...state.contentForYou,
          error: { message: "", type: null },
          loading: true
        }
      }
    case types.UPDATE_CONTENT_FOR_YOU:
      // Updates posts for content for you widget
      return {
        ...state,
        contentForYou: {
          ...state.contentForYou,
          data: action.payload
            ? {
                articles: action.payload.articles || [],
                podcasts: action.payload.podcasts || [],
                events: action.payload.events || []
              }
            : state.contentForYou.data,
          error: {
            message: action.error?.message || "",
            type: action.error?.type || null
          },
          loading: action.loading || false
        }
      }
    case types.LOAD_ALL_GOALS:
      // Request all available widgets
      return {
        ...state,
        allGoals: {
          ...state.allGoals,
          error: { message: "", type: null },
          loading: true
        }
      }

    case types.UPDATE_ALL_GOALS:
      // Adds all goals
      return {
        ...state,
        allGoals: {
          ...state.allGoals,
          data: action.payload || state.allGoals.data,
          error: {
            message: action.error?.message || "",
            type: action.error?.type || null
          },
          loading: action.loading || false
        }
      }

    case types.LOAD_SURVEYS:
      // Request all user surveys
      return {
        ...state,
        surveys: {
          ...state.surveys,
          error: { message: "", type: null },
          loading: true
        }
      }

    case types.UPDATE_SURVEYS:
      // Adds all user surveys
      return {
        ...state,
        surveys: {
          ...state.surveys,
          data: action.payload || state.surveys.data,
          error: {
            message: action.error?.message || "",
            type: action.error?.type || null
          },
          loading: action.loading || false
        }
      }
    case types.LOAD_ALL_WIDGETS:
      // Request all available widgets
      return {
        ...state,
        allWidgets: {
          ...state.allWidgets,
          error: { message: "", type: null },
          loading: true
        }
      }

    case types.UPDATE_ALL_WIDGETS:
      // Adds all available widgets
      return {
        ...state,
        allWidgets: {
          ...state.allWidgets,
          data: action.payload || state.allWidgets.data,
          error: {
            message: action.error?.message || "",
            type: action.error?.type || null
          },
          loading: action.loading || false
        }
      }

    case types.LOAD_WIDGET_LAYOUT:
      // Request widget layout data from backend
      return {
        ...state,
        widgetLayout: {
          ...state.widgetLayout,
          error: { message: "", type: null },
          loading: true
        },
        localWidgetLayout: {
          ...state.localWidgetLayout,
          error: { message: "", type: null },
          loading: true
        }
      }

    case types.UPDATE_WIDGET_LAYOUT: {
      // Configuring device layouts from backend request data

      let configuredWidgetLayout = state.widgetLayout.data
      if (action.payload?.layout) {
        configuredWidgetLayout =
          action.payload.layout.tablet &&
          action.payload.layout.desktop &&
          action.payload.layout.mobile
            ? layoutManager.mapNewLayout(
                action.payload.layout,
                action.payload.profileCompletionPercentage === 100
              )
            : { tablet: [], desktop: [], mobile: [] }
      }

      return {
        ...state,
        widgetLayout: {
          ...state.widgetLayout,
          data: configuredWidgetLayout,
          error: {
            message: action.error?.message || "",
            type: action.error?.type || null
          },
          loading: action.loading || false
        },
        localWidgetLayout: {
          ...state.localWidgetLayout,
          data: configuredWidgetLayout,
          error: {
            message: action.error?.message || "",
            type: action.error?.type || null
          },
          loading: action.loading || false
        }
      }
    }

    case types.ADD_WIDGETS: {
      // Goes through array of payload widgets, adding one at a time to all device layouts
      // Optional layout can be passed in to action to use external layout

      const finishedLayout = action.payload.reduce(
        (latestLayout, widget) => layoutManager.add(widget, latestLayout),
        action.useLocalWidgetLayout
          ? state.localWidgetLayout.data
          : state.widgetLayout.data
      )

      return {
        ...state,
        widgetLayout: { ...state.widgetLayout, data: finishedLayout },
        localWidgetLayout: { ...state.localWidgetLayout, data: finishedLayout }
      }
    }

    case types.LOCAL_LAYOUT_CHANGE:
      // Directly setting the local widget layout
      return {
        ...state,
        localWidgetLayout: {
          ...state.localWidgetLayout,
          data: action.payload
        }
      }

    case types.REMOVE_WIDGET: {
      // Remove widget from all device layouts
      const newLayout = layoutManager.remove(
        action.payload,
        state.localWidgetLayout.data
      )
      return {
        ...state,
        widgetLayout: {
          ...state.widgetLayout,
          data: newLayout
        },
        localWidgetLayout: {
          ...state.localWidgetLayout,
          data: newLayout
        }
      }
    }
    case types.CLOSE_OPTION_BOXES:
      // Triggers all options boxes connected to this state to close
      return {
        ...state,
        optionBoxesClose: state.optionBoxesClose + 1
      }

    case types.UPDATE_GRID_CUSTOMISATION_FLAG:
      // Allows grid elements to be moved, resized and dragged
      return {
        ...state,
        gridCustomisationFlag: action.payload
      }

    default:
      return state
  }
}
