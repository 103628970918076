export default [
  {
    title: "Happy at Work Test",
    icon: "sunny",
    link: "/happiness-survey",
    redirectLink: true,
    widgetShow: true,
    id: "5ff31b9d3349cf6957b600c0"
  },
  {
    title: "Career Path Finder",
    icon: "compass",
    link: "https://www.yourfreecareertest.com/",
    widgetShow: true,
    id: "5ff31b9d3349cf6957b672c0"
  },
  {
    title: "Personality profile (MBTI)",
    icon: "conference_speaker",
    link: "https://www.arealme.com/16types/en/",
    widgetShow: true,
    id: "5ff31b9d3349cf6957b672bd"
  },
  {
    title: "EQ Test",
    icon: "face",
    link: "https://www.arealme.com/eq/en/",
    widgetShow: true,
    id: "5ff31b9d3349cf6957b672bc"
  },
  {
    title: "IQ Test",
    icon: "extension",
    link: "https://www.arealme.com/iq/en/",
    id: "5ff31b0ad95fbbe0741654ac",
    widgetShow: true
  },
  {
    title: "Brain Teaser",
    icon: "pie_chart",
    link: "https://www.arealme.com/left-right-brain/en/",
    id: "5ff31b9d3349cf6957b672bf"
  }
  // {
  //   title: "Wellbeing assessment",
  //   icon: "accessibility",
  //   link:
  //     "https://app.workl.co/ew/me/profile/wellbeing-assessment-survey/start"
  // }
]
