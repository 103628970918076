import { useParams } from "@engaging-tech/routing"
import { useEffect } from "react"
import { connect } from "react-redux"

import { cvLibraryActions } from ".."

const SelectedCVLoaderComponent = ({ onLoad }) => {
  const { id } = useParams()
  useEffect(() => {
    onLoad({ id })
  }, [onLoad, id])
  return null
}

const mapDispatch = dispatch => ({
  onLoad: id => {
    dispatch(cvLibraryActions.loadSelectedCV(id))
  }
})

const SelectedCVLoader = connect(null, mapDispatch)(SelectedCVLoaderComponent)

export default SelectedCVLoader
