import { Flex } from "@engaging-tech/components"
import React from "react"

const RoundedImageContainer = ({ icon }) => (
  <Flex
    borderRadius={99}
    justifyContent="center"
    flexDirection="column"
    alignItems="center"
    alignContent="center"
    width={65}
    height={65}
    mt={4}
    bg="rgba(0, 233, 110, 0.2)"
  >
    <img width="40px" src={icon} alt="Icon" />
  </Flex>
)

export default RoundedImageContainer
