import { useLocation } from "@engaging-tech/routing"
import { Formik } from "formik"
import React from "react"
import { object, string } from "yup"

import Form from "./form"

const initialValues = {
  content: "",
  attachments: []
}

const validation = object().shape({
  content: string().required("Post text is required")
})

const CreatePostForm = ({ isPosting, onSubmit, onClose, user }) => {
  const location = useLocation()

  const isInAppView = location.pathname.includes("/in-app-view")

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={values => onSubmit({ data: values, place: isInAppView ? "in-app-view" : "" })}
    >
      {props => <Form {...props} isLoading={isPosting} user={user} handleClose={onClose} />}
    </Formik>
  )
}

export default CreatePostForm
