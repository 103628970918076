import React from "react"

import RankYourCompany from "../../features/happinessSurvey/containers/RankYourCompany"
import GradientLayout from "../../layouts/GradientLayout"

const RankCompany = () => (
  <GradientLayout>
    <RankYourCompany />
  </GradientLayout>
)

export default RankCompany
