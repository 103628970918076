import { Button, Chip, Flex, H4 } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React, { useState } from "react"

import BusinessLibraryAlerts from "../containers/BusinessLibraryAlerts"
import EventsAlerts from "../containers/EventsAlerts"
import JobAlerts from "../containers/JobAlerts"

const NAV_ITEMS = ["Jobs", "Events", "Business Library"]

const AlertsNav = ({ active, setActive }) => (
  <Flex width={1} my={4}>
    {NAV_ITEMS.map(item => (
      <Chip
        key={item}
        mr={[1, 4]}
        isActive={active === item}
        onClick={e => {
          e.stopPropagation()
          setActive(item)
        }}
      >
        {item}
      </Chip>
    ))}
  </Flex>
)

const ManageAlerts = ({ toggle, setModalType, initialNavItem }) => {
  const paths = usePaths()
  const router = useRouter()
  const [active, setActive] = useState(NAV_ITEMS[NAV_ITEMS.findIndex(item => item === initialNavItem) || 0])

  const determinePath = () => {
    if (active === NAV_ITEMS[0]) return paths.findAJob.jobFinder.index
    if (active === NAV_ITEMS[1]) return `${paths.globalHub.index}?tab=event`
    return paths.resourceCentre.browse.podcasts.index
  }

  const navigationPath = determinePath()
  return (
    <>
      <H4 width={1} textAlign="center" color="dark.1" fontFamily="Tiempos" fontWeight={800} fontSize={5}>
        Manage Alerts and Notifications
      </H4>
      <AlertsNav active={active} setActive={setActive} />
      {active === NAV_ITEMS[0] && <JobAlerts setModalType={setModalType} />}
      {active === NAV_ITEMS[1] && <EventsAlerts setModalType={setModalType} />}
      {active === NAV_ITEMS[2] && <BusinessLibraryAlerts setModalType={setModalType} />}
      <Flex flexDirection="row" width={1 / 1} height="100%" justifyContent="flex-end">
        <Button px={5} elevation={0} variant="outline.primary.0" bg="light.0" onClick={toggle} mx={2}>
          Close
        </Button>
        <Button
          variant="primary.0"
          elevation={0}
          bg="light.0"
          px={3}
          onClick={() => router.navigate(navigationPath)}
        >
          Create Alert
        </Button>
      </Flex>
    </>
  )
}

export default ManageAlerts
