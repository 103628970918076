import { gql } from "../../../lib/Apollo"

export const CREATE_EXPERT_ADVICE = gql`
  mutation(
    $expertTypeId: ID!
    $industriesId: [ID]!
    $question: String!
    $location: ExpertLocationInput!
  ) {
    createExpertAdvice(
      expertTypeId: $expertTypeId
      industriesId: $industriesId
      question: $question
      location: $location
    ) {
      id
    }
  }
`

export const LOAD_REGIONS = gql`
  query($countryId: ID!) {
    allRegions(countryId: $countryId) {
      id
      name
    }
  }
`

export const LOAD_JOB_ROLES = gql`
  query($text: String) {
    allJobRoles(text: $text) {
      id
      name
    }
  }
`
