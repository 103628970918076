import React, { useState } from "react"
import { AnimatePresence } from "framer-motion"
import styled from "styled-components"
import { Text, MotionFlex, Flex } from "@engaging-tech/components"
import HomepageBlock from "../../ui/components/HomepageBlock"
import aboutBooks from "../lib/aboutBooks"
import PrimaryButton from "../../ui/components/PrimaryButton"
import BookModal from "./BookModal"

const ClickableFlex = styled(Flex)`
  :hover {
    cursor: pointer;
  }
`



const Book = ({ book }) => {
  const [showModal, setShowModal] = useState(false)
  return (
    <MotionFlex
      width={[1 / 1, 1 / 1, 1 / 3]}
      bg={book.bg}
      pb={4}
      mx={3}
      mb={4}
      style={{ cursor: "pointer" }}
      onClick={() => setShowModal(true)}
      whileHover={{ scale: 1.02 }}
      transition={{ ease: "easeInOut", duration: 0.2 }}
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex width={1 / 1} px={3} flexDirection="column">
        <ClickableFlex justifyContent="center">
          <img src={book.image} height="190px" alt={book.title} />
        </ClickableFlex>
        <Text
          color="primary.0"
          fontWeight={600}
          fontSize="18px"
          textAlign="center"
          my={3}
        >
          {book.title}
        </Text>
        <Text fontSize={4} color="primary.0" px={2} textAlign="center">
          {book.children[0]}
        </Text>
      </Flex>

      <PrimaryButton
        variant="primary.0"
        width={1 / 2}
        onClick={() => setShowModal(true)}
        my={4}
      >
        Find Out More
      </PrimaryButton>

      <AnimatePresence>
        {showModal ? (
          <BookModal setVisibility={setShowModal} book={book} />
        ) : null}
      </AnimatePresence>
    </MotionFlex>
  )
}

const OurBooks = () => {
  return (
    <HomepageBlock bg="light.0">
      <Flex flexDirection="column" justifyContent="center" width={1 / 1}>
        <Text
          fontFamily="serif"
          textAlign="center"
          mb={[3, 6]}
          fontSize={[7]}
          fontWeight="600"
          color="primary.0"
        >
          Our Books
        </Text>
        <Flex
          alignItems="flex-start"
          flexDirection={["column", "column", "row"]}
          flexWrap="nowrap"
        >
          {aboutBooks.map(book => (
            <Book key={book.title} book={book} />
          ))}
        </Flex>
      </Flex>
    </HomepageBlock>
  )
}

export default OurBooks
