import { Flex, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const StyledFlex = styled(Flex)`
  background-color: white;
  border-radius: 10px;
  flex-direction: column;
  padding: 16px;
  justify-content: space-between;
  align-items: center;

  box-shadow: 0px 0px 4px 1px #00000040;
`

export default function WidgetContainer({ title, children, ...props }) {
  return (
    <StyledFlex elevation={2} {...props}>
      {title && (
        <Text textAlign="center" width={1 / 1} fontWeight={700} color="primary.0" fontSize={5}>
          {title}
        </Text>
      )}

      {children}
    </StyledFlex>
  )
}
