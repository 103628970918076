import { gql } from "@apollo/client"

import COMPANIES_TO_LOAD from "../lib/companiesToLoad"

export const LOAD_COMPANY_RANKINGS = gql`
  query($from: Int, $term: String, $industryId: ID, $countryId: ID, $type: String) {
    allCompanyRanking(limit: ${COMPANIES_TO_LOAD}, skip: $from, searchTerm: $term, industryId: $industryId,countryId: $countryId,  sortType: $type) {
      company
      ranking
      companyNameKey
      country {
        id
        name
      }
      industry {
        id
        name
      }
      happinessScores {
        global
        industry
        score
      }
      recommendation
    }
  }
`

export const LOAD_SURVEY_COMPANIES = gql`
  query ($searchTerm: String) {
    getSurveyCompanies(searchTerm: $searchTerm) {
      id
      companyName
    }
  }
`

export const LOAD_COMPANIES = gql`
  query getRankingData(
    $companyName: String
    $companyNameKey: String
    $countryId: String
    $filters: [String]
    $happinessScores: Int
    $industryId: String
    $category: String
    $submissions: Int
    $nextToken: String
  ) {
    getRankingData(
      input: {
        companyName: $companyName
        companyNameKey: $companyNameKey
        countryId: $countryId
        filters: $filters
        happinessScores: $happinessScores
        industryId: $industryId
        category: $category
        submissions: $submissions
      }
      nextToken: $nextToken
    ) {
      body {
        age_1618
        age_1924
        age_2534
        age_4554
        age_3544
        age_5564
        age_65
        company
        companyNameKey
        surveyCompanyId
        countryId
        disability_no
        disability_prefernottosay
        disability_yes
        ethnicity_arab
        ethnicity_asian
        ethnicity_blackafricancaribbean
        ethnicity_mixedmultipleethnicgroups
        ethnicity_otherethnicgroup
        ethnicity_prefernottosay
        ethnicity_white
        filteredHappinessScores
        gender_female
        gender_male
        gender_other
        gender_prefernottosay
        happinessScores
        industryId
        ranking
        recommendation
        sexualorientation_heterosexualstraight
        sexualorientation_lgbtq
        sexualorientation_other
        sexualorientation_prefernottosay
        submissions
        category_wins
      }
      nextToken
    }
  }
`
