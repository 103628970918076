import { connect } from "react-redux"
import HobbiesComponent from "../components/Hobbies"
import { updateYourProfile } from "../store/yourProfile.actions"
import { getHobbies} from "../store/yourProfile.selectors"
import { getAllHobbies } from "../../misc/store/misc.selectors"

const mapState = state => ({
  hobbies: getHobbies(state),
  allHobbies: getAllHobbies(state)
})

const mapDispatch = dispatch => ({
  onUpdate: params => dispatch(updateYourProfile(params))
})

const Hobbies = connect(mapState, mapDispatch)(HobbiesComponent)

export default Hobbies
