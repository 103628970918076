import { Flex, Icon, Text, TextArea } from "@engaging-tech/components"
import { useField } from "formik"
import React from "react"
import styled from "styled-components"

const TextAreaCustom = styled(TextArea)`
  > label {
    margin-left: 20px;
    padding: 10px 0;
  }

  border-top: 0;
`

const FieldAreaInput = ({
  field,
  meta,
  label,
  type = "text",
  helperText,
  height,
  width = "100%",
  inputLabel
}) => {
  if (!("value" in field)) {
    return (
      <Flex width={1 / 1}>
        <Icon name="warning" color="dark.2" size={20} />
        <Text ml={2}>Error Loading Input</Text>
      </Flex>
    )
  }
  return (
    <Flex width={1 / 1} flexDirection="column" mt={2} mb={2}>
      {label && <Text mb={3}>{label}</Text>}
      <TextAreaCustom
        width={width}
        id={field.name}
        name={field.name}
        type={type}
        label={inputLabel}
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value || ""}
        error={meta.error && meta.touched ? meta.error : ""}
        helperText={helperText}
        height={height}
      />
    </Flex>
  )
}

const FieldAreaInputHandler = ({ label, ...props }) => {
  const [field, meta] = useField({ ...props })

  // @ts-ignore
  return <FieldAreaInput label={label} field={field} meta={meta} {...props} />
}

export default FieldAreaInputHandler
