import React, { useEffect, useRef, useState } from "react"

import {
  ArrowDownIcon,
  DropdownButton,
  DropdownContainer,
  DropdownItem,
  DropdownList,
  FilterDropdownIcon
} from "./selectInputs.styled"

const SelectInput = ({ type = "filter", options, filterValue, onFilter, awards }) => {
  const dropdownRef = useRef()

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const toggleDropdown = () => {
    setIsOpen(prevIsOpen => !prevIsOpen)
  }

  const handleOptionClick = option => {
    if (option.id === filterValue?.id) {
      onFilter(undefined)
    } else {
      onFilter(option)
      toggleDropdown()
    }
  }

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownButton isOpen={isOpen} onClick={toggleDropdown} selected={filterValue} awards={awards}>
        {type === "sort" && filterValue?.name ? filterValue.name : options?.label}
        {type === "filter" ? <ArrowDownIcon isopen={isOpen ? "true" : undefined} /> : <FilterDropdownIcon />}
      </DropdownButton>
      {isOpen && (
        <DropdownList>
          {options.items.map((item, index) => (
            <DropdownItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              selected={filterValue?.id === item.id}
              onClick={() => handleOptionClick(item)}
            >
              {item.name}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  )
}

export default SelectInput
