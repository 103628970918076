import { Paragraph, Text } from "@engaging-tech/components"
import { isWebView } from "@engaging-tech/frontend-utils"
import { Link } from "@engaging-tech/routing"
import React from "react"

import pluralise from "../../../lib/pluralise"
import { libraryPostTypes } from "../../../lib/postTypes"
import Attachment from "../../Attachments"

const getLinkCTA = type => {
  switch (type) {
    case libraryPostTypes.articles:
      return "Read the full article in the Resource Centre"

    case libraryPostTypes.podcasts:
      return "Listen to this podcast in the Resource Centre"

    case libraryPostTypes.lectures:
      return "View the full lecture in the Resource Centre"

    case libraryPostTypes.research:
      return "Read the full research in the Resource Centre"

    default:
      return "View this content in the Resource Centre"
  }
}

const Summary = ({ post, postId }) => (
  <>
    <Text as="h3" mb={3} mt={0} fontSize={4} fontWeight="500">
      {post.title}
    </Text>
    <Paragraph letterSpacing="0.02rem" color="dark.2" fontSize={3}>
      {post.summary}
    </Paragraph>
    <Link
      to={
        isWebView()
          ? `/in-app-view/global-hub/${postId}`
          : `/business-library/browse/${pluralise(post.type)}/${post.id}`
      }
    >
      <Text
        display="block"
        color="primary.0"
        fontWeight="500"
        fontSize={[4, 3]}
        mb={post.coverImage.source && 2}
      >
        {getLinkCTA(post.type)}
      </Text>
    </Link>
    {post.coverImage.source && (
      <Attachment.Thumbnail src={post.coverImage.source} alt={post.coverImage.caption} />
    )}
  </>
)

export default Summary
