/* eslint-disable react/no-array-index-key */
import { Flex, Paragraph, Text } from "@engaging-tech/components"
import { format } from "date-fns"
import React from "react"

import PrimaryButton from "../../../ui/components/PrimaryButton"
import Separator from "../../../ui/components/Separator"

const formattedDate = date => format(new Date(date), "MMM yyyy")

const PositionField = ({ heading, value }) => (
  <Flex flexDirection="column" mb={1}>
    <Text my={1}>{heading}</Text>
    <Paragraph fontWeight={700}>{value}</Paragraph>
  </Flex>
)

const PositionsAdded = ({
  jobTitle,
  company,
  location,
  startDate,
  endDate,
  description,
  id,
  handleEdit,
  positionToAdd
}) => (
  <Flex flexDirection="column">
    <PositionField heading="Job Title" value={jobTitle} />
    <PositionField heading="Company Name" value={company} />
    <PositionField heading="City" value={location?.name} />
    <Flex flexDirection="row">
      <PositionField heading="Start Date" value={startDate && formattedDate(startDate)} />
      <PositionField heading="End Date" value={endDate ? formattedDate(endDate) : "I currently work here"} />
    </Flex>
    <Flex flexDirection="column">
      <Text>Achievement and Skills</Text>
      {description &&
        description.split(/\n/gi).map((skill, index) => (
          <Paragraph key={index} fontWeight={700}>
            {skill}
          </Paragraph>
        ))}
    </Flex>
    <Flex justifyContent="flex-end" my={4}>
      <PrimaryButton
        text="EDIT"
        px={5}
        onClick={e => {
          e.preventDefault()
          handleEdit(id)
        }}
        type="button"
        disabled={positionToAdd && id === positionToAdd?.id}
      />
    </Flex>
    <Separator width={1 / 1} color="primary.0" lineHeight="2px" />
  </Flex>
)

const PositionsHeld = ({ formik, onEdit }) => {
  const { values, setFieldValue } = formik
  const { positionsHeld, positionToAdd } = values

  const handleEdit = async id => {
    const posToEdit = positionsHeld.find(pos => pos.id === id)
    try {
      setFieldValue("positionToAdd", {
        id: posToEdit.id,
        jobTitle: posToEdit.jobTitle,
        company: posToEdit.company,
        location: posToEdit.location,
        endDate: posToEdit.endDate,
        startDate: posToEdit.startDate,
        description: posToEdit.description
      })
    } catch (e) {
      console.log({ e })
    }
    onEdit()
  }

  return (
    <>
      <Separator width={1 / 1} color="primary.0" lineHeight="2px" />
      {positionsHeld.map((pos, index) => {
        return <PositionsAdded key={index} {...pos} handleEdit={handleEdit} positionToAdd={positionToAdd} />
      })}
    </>
  )
}

export default PositionsHeld
