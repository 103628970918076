import { Flex } from "@engaging-tech/components"
import React from "react"
import * as Yup from "yup"

import RadioButtonInput from "../../../formFlowWizard/components/formElements/RadioButtonInput"
import DoubleHandleValueSlider from "../../../formFlowWizard/components/formElements/sliders/DoubleHandleValueSlider"

const icon = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/heart.png`)

const info = {
  icon,
  title: "What is your desired role?",
  submitData: false
}

const validationSchema = Yup.object({
  desiredJobType: Yup.string().required("A desired job type needs to be selected"),
  desiredJobSalary: Yup.object().shape({
    min: Yup.number()
      .required("Minimum desired salary required")
      .min(0, "Minimum desired salary can not be less than £0"),
    max: Yup.number().required("Maximum desired salary required")
  })
})

const DesiredSalaryTemplate = () => {
  return (
    <Flex width={1 / 1} flexDirection="column">
      <RadioButtonInput
        name="desiredJobType"
        label="Please select which job type you are interested in:"
        radioOptions={[
          { id: "Full-time", optionLabel: "Full Time" },
          { id: "Part-time", optionLabel: "Part Time" },
          { id: "Placement", optionLabel: "Placement" },
          { id: "Volunteer", optionLabel: "Volunteer" },
          { id: "Internship", optionLabel: "Internship" },
          { id: "Graduate Scheme", optionLabel: "Graduate Scheme" }
        ]}
        color="quaternary.1"
        labelTextColor="quaternary.0"
        errorTextOverride="A desired job type needs to be selected"
      />
      <DoubleHandleValueSlider
        name1="desiredJobSalary.min"
        name2="desiredJobSalary.max"
        label="What is your desired salary?"
        max={150000}
        increment={1000}
        trackHeight={15}
        width={1 / 1}
        knobSize={18}
        minimumDifference={5000}
        symbol="£"
        defaultMin={20000}
        defaultMax={30000}
      />
    </Flex>
  )
}

export default { component: DesiredSalaryTemplate, validationSchema, info }
