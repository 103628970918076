import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import { cvLibraryActions } from "../../../features/cvLibrary"
import CVLibraryView from "../../../features/cvLibrary/views/CVLibrary"
import TokenAuth from "../../../features/misc/components/TokenAuth"
import AppViewBaseLayout from "../../../layouts/AppViewBaseLayout"

export default function CVLibrary() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(cvLibraryActions.loadCVs({ place: "in-app-view" }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AppViewBaseLayout mx={3} mt={4}>
      <TokenAuth />
      <CVLibraryView />
    </AppViewBaseLayout>
  )
}
