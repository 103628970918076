import { useRouter, useLocation } from "@engaging-tech/routing"
import { goBack } from "@engaging-tech/frontend-utils"

const CreationRedirect = () => {
  const router = useRouter()
  const { pathname } = useLocation()
  const { url } = goBack(pathname)

  router.navigate(url)

  return null
}

export default CreationRedirect
