import { types } from "./businessLibrary.actions"
import ITEMS_TO_LOAD from "../lib/itemsToLoad"

export const initialState = {
  isLoading: false,
  isLoadingMore: false,
  hasLoadedAll: false,
  content: [],
  featured: [],
  filter: {
    id: "0",
    value: ""
  },
  selected: "",
  searchTerm: "",
  postType: "",
  loadFrom: 0
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_ALL:
      return {
        ...state,
        isLoading: true,
        selected: initialState.selected,
        postType: action.payload.postType,
        searchTerm: action.payload.searchTerm,
        filter: action.payload.category,
        hasLoadedAll: false
      }

    case types.LOAD_SELECTED:
    case types.LOAD_FEATURED:
      return { ...state, isLoading: true, selected: initialState.selected }

    case types.LOAD_MORE:
      return {
        ...state,
        isLoadingMore: true
      }

    case types.LOAD_ALL_SUCCESS:
      return {
        ...state,
        content: [...action.payload],
        loadFrom: action.payload.length,
        isLoading: false,
        hasLoadedAll: action.payload.length < ITEMS_TO_LOAD
      }

    case types.LOAD_SELECTED_SUCCESS:
      return {
        ...state,
        selected: action.payload,
        isLoading: false
      }

    case types.LOAD_FEATURED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        featured: action.payload
      }

    case types.LOAD_MORE_SUCCESS:
      return {
        ...state,
        content: [...state.content, ...action.payload],
        loadFrom: state.loadFrom + action.payload.length,
        isLoadingMore: false,
        hasLoadedAll: action.payload.length < ITEMS_TO_LOAD
      }

    case types.LOAD_ALL_FAIL:
    case types.LOAD_MORE_FAIL:
    case types.LOAD_SELECTED_FAIL:
    case types.CREATE_FAIL:
      return { ...state, isLoading: false, isLoadingMore: false }

    case types.FILTER:
      return { ...state, filter: action.payload }

    case types.RESET_FILTER:
      return { ...state, filter: initialState.filter }

    case types.SEARCH:
      return { ...state, searchTerm: action.payload }

    case types.RESET_SEARCH:
      return { ...state, searchTerm: initialState.searchTerm }

    default:
      return state
  }
}
