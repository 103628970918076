import { Box, Flex } from "@engaging-tech/components"
import { useParams } from "@engaging-tech/routing"
import { ResultsSelectors } from "@engaging-tech/survey-results"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"

import { AccountSelectors } from "../../../features/account"
import SurveyListLoader from "../../../features/developCareer/containers/Loader"
import HoldingPage from "../../../features/surveyResults/components/HoldingPage"
import Loader from "../../../features/surveyResults/containers/Loader"
import SurveyResults from "../../../features/surveyResults/views/Results"
import { attachUserToSubmissionByTokens } from "../../../lib/SubmissionTokenCookies"

const Results = () => {
  const { id } = useParams()
  const hasResults = useSelector(ResultsSelectors.getHasResults)
  const isAuthenticated = useSelector(AccountSelectors.getIsAuthenticated)

  useEffect(() => {
    if (isAuthenticated) {
      attachUserToSubmissionByTokens()
    }
  }, [isAuthenticated])

  return (
    <Box as="main" id="results-layout">
      <Flex as="main" flexDirection="column" minHeight="100vh" alignItems="center">
        <Flex
          width={1 / 1}
          px={[3, 3, 4]}
          py={5}
          flex="1"
          justifyContent="flex-start"
          flexDirection="column"
          maxWidth={1080}
        >
          <Loader submissionId={id} />
          <SurveyListLoader />
          {hasResults ? <SurveyResults /> : <HoldingPage />}
        </Flex>
      </Flex>
    </Box>
  )
}

export default Results
