import React, { useEffect, useRef, useState } from "react"

import { DropdownButton, DropdownContainer, DropdownItem, DropdownList, StyledIcon } from "./styled"

export default function SelectFilter({ items, value, onFilter }) {
  const dropdownRef = useRef()

  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(value)

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const handleOptionClick = option => {
    setSelectedOption(option)
    onFilter(option)
    setIsOpen(false)
  }

  const toggleDropdown = () => {
    setIsOpen(prevIsOpen => !prevIsOpen)
  }

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownButton isOpen={isOpen} onClick={toggleDropdown} selected={selectedOption}>
        {selectedOption}
        <StyledIcon />
      </DropdownButton>
      {isOpen && (
        <DropdownList>
          {items.map((item, index) => (
            <DropdownItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              selected={selectedOption === item}
              onClick={() => handleOptionClick(item)}
            >
              {item}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  )
}
