export const types = {
  LOAD: "[Results] Load",
  LOAD_SUCCESS: "[Results] Load Success",
  LOAD_FAIL: "[Results] Load Fail",
  RETRY_LOAD: "[Results] Retry Load",
  RESET: "[Results] Reset",
  LOAD_SUBMISSION: "[Results] Load Submission Again"
}

export const load = submissionId => ({
  type: types.LOAD,
  payload: submissionId
})

export const loadSuccess = results => ({
  type: types.LOAD_SUCCESS,
  payload: results
})

export const loadFail = () => ({
  type: types.LOAD_FAIL
})

export const retryLoad = submissionId => ({
  type: types.RETRY_LOAD,
  payload: submissionId
})

export const resetSurveyResults = () => ({
  type: types.RESET
})

export const loadSubmission = payload => ({
  type: types.LOAD_SUBMISSION,
  payload
})
