import { createSelector } from "reselect"

import * as ResultsSelectors from "../../store/surveyResults.selectors"

export const pipePassedInProp = (state, prop) => prop

export const getHappinessSixSteps = createSelector(
  ResultsSelectors.getResultsData,
  data => data && data.happinessSixSteps
)

const formatQuestions = question => ({
  ...question,
  values: {
    score: parseFloat(question.values.score * 10, 10),
    industry: parseFloat(question.values.industry * 10, 10),
    global: parseFloat(question.values.global * 10, 10)
  }
})

const formatSixSteps = step => ({
  ...step,
  score: parseFloat(step.score, 10),
  industry: parseFloat(step.industry, 10),
  global: parseFloat(step.global, 10),
  questions: step.questions.map(formatQuestions)
})

export const mappedHappinessSixSteps = createSelector(
  getHappinessSixSteps,
  sixSteps => sixSteps && sixSteps.map(formatSixSteps)
)

export const getStep = createSelector(
  mappedHappinessSixSteps,
  pipePassedInProp,
  (sixSteps, currentStep) => sixSteps && sixSteps.find(step => step.name === currentStep)
)

export const getQuestions = createSelector(getStep, step => step && step.questions)
export const getQuestionsLength = createSelector(getQuestions, questions => questions && questions.length)
