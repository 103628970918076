import React, { useEffect } from "react"
import { usePaths, useRouter, Link } from "@engaging-tech/routing"
import { useDispatch } from "react-redux"
import {
  Card,
  Text,
  Spinner,
  Button,
  userInterfaceNotificationsStore
} from "@engaging-tech/components"
import STATUSES from "../lib/samlStatuses"

const STATUS_TEXT = {
  [STATUSES.SIGN_IN]: "Signing In...",
  [STATUSES.FETCHING_SURVEYS]: "Fetching Survey...",
  [STATUSES.REDIRECTING]: "Redirecting..."
}

const SpinnerText = ({ status }) => (
  <>
    <Text width="auto">
      {STATUS_TEXT[status] || STATUS_TEXT[STATUSES.SIGN_IN]}
    </Text>
    <Spinner color="primary.0" m={3} />
  </>
)

const ChooseSurvey = ({ surveys = [] }) => {
  const paths = usePaths()
  return (
    <Card
      width={1}
      border="solid 2px"
      borderColor="primary.0"
      elevation={0}
      p={3}
    >
      <Text width={1} textAlign="center">
        Choose A Survey
      </Text>
      {surveys.map(survey => (
        <Link
          to={`${paths.survey.index.replace(":id", survey.surveyId)}?code=${
            survey.code
          }`}
        >
          <Button width={1} my={2} variant="primary.0">
            {survey.surveyName}
          </Button>
        </Link>
      ))}
    </Card>
  )
}

const SamlStatus = ({ hasError, status, surveys, noSurveys }) => {
  const router = useRouter()
  const paths = usePaths()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Something went wrong!",
        icon: "reply",
        action: () => router.redirect(paths.account.index)
      })
    )
  }, [hasError])

  if (noSurveys)
    return (
      <Card bg="light.0" width={[2 / 3, 1 / 2]} p={4} alignItems="center">
        <Text textAlign="center" width={1}>
          You don&apos;t have any surveys to take.
        </Text>
      </Card>
    )

  return (
    <Card bg="light.0" width={[2 / 3, 1 / 2]} p={4} alignItems="center">
      {status !== STATUSES.CHOOSE_SURVEY && <SpinnerText status={status} />}
      {status === STATUSES.CHOOSE_SURVEY && <ChooseSurvey surveys={surveys} />}
    </Card>
  )
}

export default SamlStatus
