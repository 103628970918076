import { Utils } from "@engaging-tech/components"

const gradientCss = theme =>
  Utils.createGradient(
    {
      from: "secondary.1",
      to: "secondary.0",
      transition: "50%",
      direction: "122deg"
    },
    theme
  )

export default gradientCss
