import styled from "styled-components"

// eslint-disable-next-line import/prefer-default-export
export const Content = styled.div`
  min-width: 1080px;
  max-width: 1280px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1080px) {
    min-width: unset;
    max-width: unset;
  }

  @media (max-width: 480px) {
    width: auto;
  }
`
