import { types } from "./jobMatcher.actions"

export const initialState = {
  isLoading: false,
  isJobMatchesLoading: false,
  isSubmitting: false,
  hasError: null,
  jobMatches: []
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_JOB_MATCHES:
      return { ...state, isJobMatchesLoading: true, hasError: null }
    case types.LOAD_JOB_MATCHES_SUCCESS:
      return {
        ...state,
        isJobMatchesLoading: false,
        hasError: null,
        jobMatches: action.payload
      }
    case types.SUBMIT:
      return {
        ...state,
        isSubmitting: true
      }
    case types.SUBMIT_SUCCESS:
      return {
        ...state,
        isSubmitting: false
      }
    case types.SET_HAS_ERROR:
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        hasError: action.payload
      }
    default:
      return state
  }
}
