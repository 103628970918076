import React from "react"
import { Flex, Icon, Text, Button } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import { useTranslation } from "react-i18next"

const SignUpPrompt = () => {
  const paths = usePaths()
  const router = useRouter()
  const toSignUp = () => router.navigate(paths.account.signUp)
  const { t } = useTranslation(["results"])
  return (
    <Flex alignItems="center" flexDirection={["column", "row"]}>
      <Flex
        bg="secondary.5"
        px={3}
        py={1}
        alignItems="center"
        width={[1 / 1, 7 / 10]}
        mb={[4, 0]}
      >
        <Icon name="error" p={20} mr={3} color="dark.2" />
        <Text color="dark.2" fontSize={3}>
          {t("sign_up.leave", "")}
        </Text>
      </Flex>
      <Flex justifyContent="center" width={[1 / 1, 3 / 10]}>
        <Button
          bg="secondary.0"
          width={[1 / 2, 7 / 10]}
          borderRadius={24}
          fontWeight={500}
          onClick={toSignUp}
        >
          {t("sign_up.button_text", "")}
        </Button>
      </Flex>
    </Flex>
  )
}

export default SignUpPrompt
