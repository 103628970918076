import React from "react"
import { Flex } from "@engaging-tech/components"
import PremiumButton from "./PremiumButton"
import CancelButton from "./CancelButton"

const FourPillarsFooter = ({
  premiumProduct,
  isLoading,
  handleStripeCheckout,
  stripeCheckoutUrl,
  onCancel
}) => (
  <Flex
    justifyContent="space-around"
    flexDirection="row"
    alignItems="flex-start"
    pt={2}
  >
    <CancelButton onCancel={onCancel} px={4} />
    <PremiumButton
      ml="auto"
      isLoading={isLoading}
      premiumProduct={premiumProduct}
      handleStripeCheckout={handleStripeCheckout}
      stripeCheckoutUrl={stripeCheckoutUrl}
    />
  </Flex>
)

export default FourPillarsFooter
