import { Box, Button, Flex, H1, H2, Icon, Paragraph, Text, Utils } from "@engaging-tech/components"
import { WorkplaceHappiness } from "@engaging-tech/happiness"
import { Link, usePaths } from "@engaging-tech/routing"
import React from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"

import { MiscSelectors } from "../../misc"
import Hero from "./Hero"

const defaultCountry = "United Kingdom"
const defaultIndustry = "Aerospace and Defence"

const dailyTelegraphLogo = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/third-party-logos/telegraph.jpg`)
const cmiLogo = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/third-party-logos/cmi.png`)

const Logo = styled.img`
  display: block;
  margin: 0 auto;
  margin-top: ${({ theme }) => theme.space[1]}px;

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}px) {
    display: inline-block;
    margin: 0;
    margin-bottom: -${({ theme }) => theme.space[1]}px;
    margin-left: ${({ theme }) => theme.space[2]}px;
  }
`

const ImageHero = styled(Hero)`
  & > div {
    height: 100%;
    padding: ${({ theme }) => theme.space[5]}px 0;
    max-width: 1024px;

    @media (min-width: ${({ theme }) => theme.breakpoints[0]}px) {
      padding-top: ${({ theme }) => theme.space[6]}px 0 0 0;
    }
  }
`

const happinessWidgetGradient = theme =>
  Utils.createGradient(
    {
      from: "secondary.0",
      to: "light.0",
      transition: "95%",
      direction: "180deg"
    },
    theme
  )

const WidgetWrapper = styled(Box)`
  ${({ theme }) => happinessWidgetGradient(theme)};
`

const IconBullet = ({ name, children }) => (
  <Flex alignItems="center" width={1 / 1}>
    <Icon name={name} color="dark.2" mr={2} />
    {children}
  </Flex>
)

const Banner = () => {
  const paths = usePaths()

  const allCountries = useSelector(MiscSelectors.getAllCountries)

  return (
    <ImageHero height={["auto", 504]} alignItems="flex-start" mt={[0, 5]} mb={[0, 6]}>
      <Flex
        px={[0, 4]}
        flexDirection={["column", "row"]}
        alignItems={["center", "flex-start"]}
        justifyContent="space-between"
      >
        <Box width={[1 / 1, 0.75]} mr={[0, 4]} mb={[4, 0]} px={[3, 0]}>
          <Box width={1 / 1} mb={5}>
            <H2 fontWeight="600" fontSize={[6, 6, 7]} fontFamily="serif" mb={1} width="auto" color="dark.2">
              Find jobs at the happiest places to work.
            </H2>
            <Flex flexDirection={["column", "row"]} alignItems={["flex-start", "center"]} mb={2}>
              <Text fontFamily="serif" fontSize={[5, 5, 6]} color="dark.2" width="auto">
                In association with{" "}
              </Text>
              <Logo src={dailyTelegraphLogo} alt="The Telegraph" width="184px" />
            </Flex>

            <IconBullet name="mood">
              <Paragraph fontWeight="500" color="dark.2" mb={0}>
                Rank your company and discover the{" "}
                <Link color="primary.0" to={paths.happiestWorkplaces}>
                  Happiest Places to Work
                </Link>
              </Paragraph>
            </IconBullet>
          </Box>
          <Box width={1 / 1} mb={5}>
            <H1 fontWeight="600" fontSize={[6, 6, 7]} fontFamily="serif" mb={3} width="auto" color="dark.2">
              Check your happiness at work.
            </H1>
            <IconBullet name="assignment">
              <Paragraph fontWeight="500" color="dark.2" mb={0}>
                Measure, track and improve with our free{" "}
                <Link color="primary.0" to={paths.happinessSurvey.index}>
                  Workplace Happiness Survey
                </Link>
              </Paragraph>
            </IconBullet>
          </Box>
          <Box width={1 / 1} mb={5}>
            <H2 fontWeight="600" fontSize={[6, 6, 7]} fontFamily="serif" mb={1} width="auto" color="dark.2">
              Achieve more at work.
            </H2>
            <Flex flexDirection={["column", "row"]} alignItems={["flex-start", "flex-end"]} mb={3}>
              <Text fontFamily="serif" fontSize={[5, 5, 6]} color="dark.2" width="auto" mr={2}>
                In association with{" "}
              </Text>
              <Logo src={cmiLogo} alt="CMI" height="40px" />
            </Flex>

            <IconBullet name="trending_up">
              <Paragraph fontWeight="500" color="dark.2" mb={0}>
                Use our services to build your career. Whether you’re starting out or moving on we can help
              </Paragraph>
            </IconBullet>
          </Box>
          <Flex
            justifyContent={["center", "flex-start"]}
            alignItems={["center", "flex-start"]}
            flexDirection={["column", "column", "row"]}
          >
            <Link to={paths.getPremium.index}>
              <Button variant="primary.0" px={3} mr={[0, 0, 3]} mb={[3, 3, 0]} height={36} width={3 / 5}>
                Sign up for free
              </Button>
            </Link>

            <Link to={paths.getPremium.index}>
              <Button variant="outline.primary.0" elevation={0} height={36} width={3 / 5}>
                Premium services
              </Button>
            </Link>
          </Flex>
        </Box>
        <WidgetWrapper width={[1 / 1, "auto"]} maxWidth={["100%", 304]} borderRadius={4} p={3}>
          <WorkplaceHappiness
            defaultCountry={defaultCountry}
            defaultIndustry={defaultIndustry}
            title="Live workplace happiness scores from around the world"
            allCountries={allCountries}
          />
        </WidgetWrapper>
      </Flex>
    </ImageHero>
  )
}

export default Banner
