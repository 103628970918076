import React from "react"

import SignUpForm from "../forms/signUp"

/**
 * The "Reset Password" dialog box.
 * @param {React.Component} footer Optional footer component to show underneath the sign in form.
 * @param {string} redirectTo The location to redirect a user to after they submit the reset password form.
 * @param {string} cancelRoute The route to redirect a user to if they click "Cancel".
 * @param {React.Component} marketingLabel A label component to show beside the marketing consent checkbox.
 * @param {React.Component} termsAndConditionsLabel A label component to show beside the terms and conditions acceptance
 * checkbox.
 * @param {string} domain The domain name to be assigned to the cookie when signing in. This is used for accounts
 * that might require authentication across multiple front-ends.
 */
export default function SignUp({ footer, redirectTo, cancelRoute, config, isFromPremium }) {
  return (
    <SignUpForm
      footer={footer}
      isFromPremium={isFromPremium}
      redirectTo={redirectTo}
      cancelRoute={cancelRoute}
      config={config}
    />
  )
}
