import { connect } from "react-redux"
import EducationComponent from "../components/Education"
import { updateYourProfile } from "../store/yourProfile.actions"
import { getQualifications } from "../store/yourProfile.selectors"

const mapState = state => ({
  qualifications: getQualifications(state)
})

const mapDispatch = dispatch => ({
  onUpdate: params => dispatch(updateYourProfile(params))
})

const Education = connect(mapState, mapDispatch)(EducationComponent)

export default Education
