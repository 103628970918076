import { HappinessActions } from "@engaging-tech/happiness"
import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { useDispatch } from "react-redux"

import { loadAllCountries, loadAllIndustries } from "../../features/misc/store/misc.actions"
import HappiestCompanies from "../../features/rankings/views/happiestCompanies"
import HappiestLayout from "../../layouts/happiestCompaniesLayout"

export default function CompanyRankings() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadAllIndustries())
    dispatch(loadAllCountries())
    dispatch(HappinessActions.loadReport())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <HappiestLayout isPublic>
      <Helmet>
        <title>Workplace Happiness Survey Results | The UK&apos;s Happiest Companies</title>
        <meta
          name="description"
          content="See the results of our Happiest Places to Work Survey. The career happiness test ranks the best jobs for you to improve workplace life and stay happy at work."
        />
      </Helmet>

      <HappiestCompanies />
    </HappiestLayout>
  )
}
