import { Box, Flex } from "@engaging-tech/components"
import React from "react"

const ProgressBar = ({
  percentage,
  backgroundColor = "#00000061",
  primaryColor = "#5AEEB8",
  borderRadius = 0,
  height
}) => {
  return (
    <Flex width={1 / 1} height={height} bg={backgroundColor} borderRadius={borderRadius}>
      <Box borderRadius={borderRadius} height={height} bg={primaryColor} width={`${percentage}%`} />
    </Flex>
  )
}

export default ProgressBar
