import { connect } from "react-redux"
import ConnectionTypeManager from "../components/ConnectionTypeManager"

import { mentorMatcherSelectors } from ".."
import {
  getMentorMatcherStatus,
  getServices,
  // getIsPremium
} from "../../yourProfile/store/yourProfile.selectors"

const mapState = state => ({
  // isPremium: getIsPremium(state),
  matches: mentorMatcherSelectors.getMentors(state),
  serviceStatus: getMentorMatcherStatus(state),
  services: getServices(state)
})

const Mentor = connect(mapState)(ConnectionTypeManager)

export default Mentor
