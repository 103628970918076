export const types = {
  LOAD: "[Survey] Load",
  LOAD_SUCCESS: "[Survey] Load Success",
  LOAD_FAIL: "[Survey] Load Fail",
  SET_COMPANY_NAME: "[UI] Set Company Name",
  SET_COMPANY_ID: "[UI] Set Company Id",
  CLEAR_COMPANY_NAME: "[UI] Clear Company Name",
  ANSWER: "[Survey] Answer",
  SET_GROUPS: "[Survey] Set Business Groups",
  SUBMIT: "[Survey] Submit",
  SUBMIT_SUCCESS: "[Survey] Submit Success",
  SUBMIT_FAIL: "[Survey] Submit Fail",
  ATTACH_USER: "[Survey] Attach User",
  ATTACH_USER_SUCCESS: "[Survey] Attach User Success",
  ATTACH_USER_FAIL: "[Survey] Attach User Fail",
  SET_SAVANTA_ID: "[Survey] Set savanta id",
  SET_SAVANTA_TERRITORY: "[Survey] Set Savanta Territory"
}

export const setSavantaTerritory = payload => ({
  type: types.SET_SAVANTA_TERRITORY,
  payload
})

export const load = id => ({
  type: types.LOAD,
  payload: id
})

export const loadSuccess = survey => ({
  type: types.LOAD_SUCCESS,
  payload: survey
})

export const loadFail = () => ({
  type: types.LOAD_FAIL
})

export const answer = ({ questionId, value }) => ({
  type: types.ANSWER,
  payload: { questionId, value }
})

export const setGroups = groups => ({
  type: types.SET_GROUPS,
  payload: groups
})

export const submit = payload => ({
  type: types.SUBMIT,
  payload
})

export const submitSuccess = (id, isAuthenticated) => ({
  type: types.SUBMIT_SUCCESS,
  payload: { id, isAuthenticated }
})

export const submitFail = () => ({
  type: types.SUBMIT_FAIL
})

export const attachUser = payload => ({
  type: types.ATTACH_USER,
  payload
})

export const attachUserSuccess = () => ({
  type: types.ATTACH_USER_SUCCESS
})

export const attachUserFail = () => ({
  type: types.ATTACH_USER_FAIL
})

export const setSavantaId = id => ({
  type: types.SET_SAVANTA_ID,
  payload: id
})

export const setCompanyName = companyName => ({
  type: types.SET_COMPANY_NAME,
  payload: companyName
})

export const setCompanyId = companyId => ({
  type: types.SET_COMPANY_ID,
  payload: companyId
})

export const clearCompanyName = () => ({
  type: types.CLEAR_COMPANY_NAME
})
