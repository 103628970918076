/* eslint-disable react/no-array-index-key */
import { Card, Flex, List, Paragraph, Spinner, Text } from "@engaging-tech/components"
import { format } from "date-fns"
import React, { Fragment } from "react"
import { useSelector } from "react-redux"

import { getAllHobbies, getAllSkills } from "../../misc/store/misc.selectors"

const formattedDate = date => (date ? format(new Date(date), "MMM yyyy") : "current")

const ContactDetails = ({ firstName, lastName, address, phone, email }) => (
  <Flex flexDirection="column" alignItems="center">
    <Text fontSize={6} fontWeight={600} color="primary.0" mb={3}>
      {firstName} {lastName}
    </Text>
    <Flex mb={5}>
      <Text>
        {address?.name} | {phone} |{" "}
        <a href={`mailto:${email}`}>
          <Text color="primary.0">{email}</Text>
        </a>
      </Text>
    </Flex>
  </Flex>
)

const CVSection = ({ heading, mb = "2", children }) => (
  <>
    <Text fontWeight={600} color="primary.0" mb={mb} mt={3}>
      {heading}
    </Text>
    {children}
  </>
)

const Summary = ({ personalStatement }) => (
  <CVSection heading="SUMMARY">
    {personalStatement?.split(/\n/gi).map((para, index) => (
      <Paragraph key={index} fontWeight={700}>
        {para}
      </Paragraph>
    ))}
  </CVSection>
)

const ProfessionalExperience = ({ positionsHeld }) => (
  <CVSection heading="PROFESSIONAL EXPERIENCE">
    {positionsHeld.map((job, index) => {
      const { jobTitle, company, startDate, endDate, description } = job

      return (
        <Fragment key={index}>
          <Text>
            <strong>{jobTitle}</strong>, {company}, {formattedDate(startDate)} - {formattedDate(endDate)}
          </Text>
          <Text mb={2}>Skills: {description}</Text>
        </Fragment>
      )
    })}
  </CVSection>
)

const Qualifications = ({ qualifications }) => (
  <CVSection heading="QUALIFICATIONS">
    {qualifications.map((data, index) => (
      <Text key={index} mb={2}>
        <strong>{data.institution}</strong>, {data.courseTitle}, {formattedDate(data.start)} -{" "}
        {formattedDate(data.end)}
      </Text>
    ))}
  </CVSection>
)

const Skills = ({ skills }) => {
  const skillsData = useSelector(getAllSkills)

  return (
    <CVSection heading="SKILLS" mb={0}>
      <List.Wrapper spacing="5px">
        {skills.map((skill, index) => {
          const skillFound = skillsData.find(item => item.id === skill.id)

          if (skillFound) {
            return <List.Item key={index}>{skillFound.name}</List.Item>
          }

          return null
        })}
      </List.Wrapper>
    </CVSection>
  )
}

const Hobbies = ({ hobbies }) => {
  const hobbiesData = useSelector(getAllHobbies)

  return (
    <CVSection heading="HOBBIES & INTERESTS" mb={0}>
      <List.Wrapper spacing="5px">
        {hobbies.map((hobby, index) => {
          const hobbieFound = hobbiesData.find(item => item.id === hobby.id)

          if (hobbieFound) {
            return <List.Item key={index}>{hobbieFound.name}</List.Item>
          }

          return null
        })}
      </List.Wrapper>
    </CVSection>
  )
}

const SelectedCV = ({ cv, firstName, lastName }) => {
  return !cv ? (
    <Spinner />
  ) : (
    <Card bg="light.0" maxWidth="800px" width={1 / 1} p={5}>
      <ContactDetails firstName={firstName} lastName={lastName} {...cv} />
      {cv.personalStatement && <Summary {...cv} />}
      {cv.positionsHeld.length > 0 && <ProfessionalExperience {...cv} />}
      {cv.qualifications?.length > 0 && <Qualifications {...cv} />}
      {cv.skills?.length > 0 && <Skills {...cv} />}
      {cv.hobbies?.length > 0 && <Hobbies {...cv} />}
      {cv.references && <Text mt={3}>References available upon request</Text>}
    </Card>
  )
}

export default SelectedCV
