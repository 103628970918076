import { Button, Chip, Flex, Text } from "@engaging-tech/components"
import React from "react"
import { useSelector } from "react-redux"

import DropdownInput from "../../../formFlowWizard/components/formElements/DropdownInput"
import { getAllSkills } from "../../../misc/store/misc.selectors"

const SkillsAdded = ({ values, setFieldValue, skills, findSkillById }) => (
  <Flex flexWrap="wrap">
    {values.skills.length > 0 &&
      values.skills.map(skillId => (
        <Chip
          key={skillId}
          mr={2}
          mt={2}
          trailingIcon="close"
          color="primary.0"
          onClick={() => {
            setFieldValue(
              "skills",
              skills.filter(skill => skill !== skillId)
            )
          }}
        >
          {findSkillById(skillId)}
        </Chip>
      ))}
  </Flex>
)

const Skills = ({ formik, handleTouchAllInputs }) => {
  const { values, errors, setFieldValue, setFieldTouched } = formik
  const { skills, skillToAdd } = values

  const allSkills = useSelector(getAllSkills).map(skill => {
    return {
      id: skill.id,
      label: skill.name
    }
  })

  const findSkillById = id => allSkills.find(skill => skill.id === id)?.label

  const handleSkillsSave = async () => {
    if (skills) await setFieldValue("skills", [...skills, skillToAdd.id])
    if (!skills) await setFieldValue("skills", [skillToAdd.id])
    setFieldValue("skillToAdd", null)
    setFieldTouched("skillToAdd", false)
  }

  const buttonDisable = errors.skillToAdd || values.skillToAdd === "" || values.skillToAdd === null

  return (
    <>
      <Text fontWeight={700} my={3}>
        Skills
      </Text>
      <DropdownInput
        name="skillToAdd"
        placeholderLabel="Please Select"
        label="Add a skill"
        options={allSkills.filter(item => !skills?.includes(item.id))}
      />
      <Flex justifyContent="flex-end" my={4}>
        <Button
          elevation={0}
          px={5}
          type="button"
          disable={buttonDisable}
          bg={buttonDisable ? "dark.5" : "secondary.0"}
          onClick={
            buttonDisable
              ? () =>
                  handleTouchAllInputs({
                    formik,
                    containerElement: document.querySelector("#skill-add-form")
                  })
              : handleSkillsSave
          }
        >
          <Text fontWeight={700} color={buttonDisable ? "dark.2" : "primary.0"} fontSize={3}>
            SAVE
          </Text>
        </Button>
      </Flex>
      {values.skills && (
        <SkillsAdded
          values={values}
          setFieldValue={setFieldValue}
          skills={skills}
          findSkillById={findSkillById}
        />
      )}
    </>
  )
}

export default Skills
