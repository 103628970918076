import { Button, Flex } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"

import { businessProfilePageSelectors } from "../.."
import ChartsHandler from "./charts/common/charts-handler"
import DiversityAndInclusionIndicator from "./charts/diversity-and-inclusion-indicator"
import FlightRiskIndicator from "./charts/flight-risk-indicator"
import IndustryGlobalComp from "./charts/industry-global-comp"
import ManagementConfidenceIndicator from "./charts/management-confidence-indicator"
import SixSteps from "./charts/six-steps"
import WellBeingIndicator from "./charts/well-being-indicator"
import WorkplaceHappinessScore from "./charts/workplace-happiness-score"

const WorkLDataTabWrapper = styled.div`
  margin-top: 24px;
  width: 100%;
  max-width: fit-content;
  overflow-x: hidden;
`

const WorkLDataTabContainer = styled(Flex)`
  width: auto;
  overflow-x: hidden;

  padding: 5px;

  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 10px 64px 0;
  }
`

const WorkLDataTabClaimPageContainer = styled(Flex)`
  width: 100%;
  max-width: 392px;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: column;
  }
`

const WorkLDataTabClaimPageTitle = styled.p`
  width: fit-content;
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.4px;
  text-align: center;
  color: #003e4c;
`

const WorkLDataTabClaimPageSubtitle = styled.p`
  width: fit-content;
  margin: 4px 0 0 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: center;
  color: #003e4c;
`

function WorkLDataTabClaimPage() {
  return (
    <Flex justifyContent="center" mt="22px" mb="60px" ml={[18, 0]}>
      <WorkLDataTabClaimPageContainer>
        <Flex flexDirection="column" width="fit-content">
          <WorkLDataTabClaimPageTitle>Business owner or leader?</WorkLDataTabClaimPageTitle>
          <WorkLDataTabClaimPageSubtitle>Claim and manage this page</WorkLDataTabClaimPageSubtitle>
        </Flex>
        <Button
          variant="primary.0"
          mt={[0, 14]}
          ml={[20, 0]}
          onClick={e => {
            e.preventDefault()

            window.open(
              `${getConfig().worklForBusiness.public.index}${
                getConfig().worklForBusiness.public.paths.worklBusinessServices
              }`,
              "__blank"
            )
          }}
        >
          CLAIM PAGE
        </Button>
      </WorkLDataTabClaimPageContainer>
    </Flex>
  )
}

export default function WorkLDataTab() {
  const bipInfo = useSelector(businessProfilePageSelectors.getBipInfo)
  const companyName = useSelector(businessProfilePageSelectors.getBipInfo)?.company
  const chartData = useSelector(businessProfilePageSelectors.getDiversityAndInclusionChartDatas)

  return (
    <WorkLDataTabWrapper>
      {bipInfo?.profile?.status !== "PUBLISHED" ? <WorkLDataTabClaimPage /> : null}

      <WorkLDataTabContainer>
        <WorkplaceHappinessScore score={bipInfo?.happinessScores?.score} />
        <IndustryGlobalComp
          width={["100%", "58%"]}
          height={["300px", "500px"]}
          happinessScores={{
            score: bipInfo?.happinessScores.score,
            global: bipInfo?.happinessScores.global,
            industry: bipInfo?.happinessScores.industry
          }}
          company={companyName}
        />
      </WorkLDataTabContainer>

      <WorkLDataTabContainer>
        <SixSteps />
      </WorkLDataTabContainer>

      <WorkLDataTabContainer>
        <DiversityAndInclusionIndicator />
        <FlightRiskIndicator />
      </WorkLDataTabContainer>

      <WorkLDataTabContainer>
        <ManagementConfidenceIndicator />
        <WellBeingIndicator />
      </WorkLDataTabContainer>

      <WorkLDataTabContainer>
        <ChartsHandler chartData={chartData} companyName={companyName} />
      </WorkLDataTabContainer>
    </WorkLDataTabWrapper>
  )
}
