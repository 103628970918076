import React from "react"
import styled from "styled-components"
import { Flex, Text, Card, Box } from "@engaging-tech/components"

import CircleIcon from "../../../ui/components/CircleIcon"
import AppBadges from "../../../ui/components/AppBadges"

const Grid = styled(Flex)`
  flex-wrap: wrap;
  align-items: stretch;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[0]}px) {
    & > :nth-child(2n - 1) {
      width: calc(50% - 16px);
      margin-right: 16px;
    }

    & > :nth-child(2n) {
      width: calc(50% - 16px);
      margin-left: 16px;
    }
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[1]}px) {
    & > :nth-child(2n - 1) {
      width: calc(50% - 24px);
      margin-right: 24px;
    }

    & > :nth-child(2n) {
      width: calc(50% - 24px);
      margin-left: 24px;
    }
  }
`

const CardGrid = ({ items }) => (
  <Grid width={[1 / 1, 2 / 3]}>
    {items &&
      items.map(item => (
        <Card key={item.title} bg="light.0" mb={3} p={3}>
          <Flex width="auto" flexDirection={["row", "column", "row"]}>
            <CircleIcon name={item.icon} mr={2} />
            <Box>
              <Text fontWeight="500" display="block" mb={2} mt={[0, 2]}>
                {item.title}
              </Text>
              <Text color="dark.2" fontSize={3}>
                {item.content}
              </Text>
              {item.points &&
                item.points.map(point => (
                  <Text
                    fontSize={2}
                    color="dark.2"
                    key={point}
                    display="block"
                    my={2}
                  >
                    {point}
                  </Text>
                ))}
            </Box>
          </Flex>
        </Card>
      ))}
    <AppBadges justifyContent="flex-start" px={0} />
  </Grid>
)

export default CardGrid
