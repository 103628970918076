import React from "react"
import GradientLayout from "../../layouts/GradientLayout"
import SamlEntryPointView from "../../features/account/views/SamlEntryPointView"

const Saml = () => {
  return (
    <GradientLayout>
      <SamlEntryPointView />
    </GradientLayout>
  )
}

export default Saml
