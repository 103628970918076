import React from "react"

import { ForgotPassword as ForgotPasswordView } from "../../features/account"
import SiteLogo from "../../features/ui/components/SiteLogo"
import GradientLayout from "../../layouts/GradientLayout"

export default function ForgotPassword() {
  return (
    <GradientLayout>
      <ForgotPasswordView siteLogo={SiteLogo} cancelRoute="/" />
    </GradientLayout>
  )
}
