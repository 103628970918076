import { connect } from "react-redux"

import { getIsAuthenticated } from "../../account/store/account.selectors"
import { getSelectedCategory } from "../../globalHub/store/globalHub.selectors"
import Toggle from "../components/Toggle"
import { toggle } from "../store/bookmark.actions"
import { getIsBookmarked } from "../store/bookmark.selectors"

const mapState = (state, props) => ({
  isBookmarked: getIsBookmarked(state, props),
  isAuthenticated: getIsAuthenticated(state),
  selectedTabCategory: getSelectedCategory(state)
})

const mapDispatch = dispatch => ({
  onToggle: bookmark => dispatch(toggle(bookmark))
})

const BookmarkToggle = connect(mapState, mapDispatch)(Toggle)

export default BookmarkToggle
