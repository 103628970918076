export const types = {
  LOAD: "[Mentor Matcher] Load",
  LOAD_SUCCESS: "[Mentor Matcher] Load Success",
  LOAD_FAIL: "[Mentor Matcher] Load Fail",
  CONNECT: "[Mentor Matcher] Connect",
  CONNECT_SUCCESS: "[Mentor Matcher] Connect Success",
  DELETE_MATCH: "[Mentor Matcher] Delete Match",
  DELETE_MATCH_SUCCESS: "[Mentor Matcher] Delete Match Success"
}

export const load = () => ({
  type: types.LOAD
})

export const loadSuccess = posts => ({
  type: types.LOAD_SUCCESS,
  payload: posts
})

export const loadFail = () => ({
  type: types.LOAD_FAIL
})

export const deleteMatch = payload => ({
  type: types.DELETE_MATCH,
  payload
})

export const deleteMatchSuccess = payload => ({
  type: types.DELETE_MATCH_SUCCESS,
  payload
})

export const connect = ({ matchId, userId }) => ({
  type: types.CONNECT,
  payload: { matchId, userId }
})

export const connectSuccess = matchId => ({
  type: types.CONNECT_SUCCESS,
  payload: matchId
})
