import React from "react"

import Scale from "./Scale"
import ShortText from "./ShortText"
import Radio from "./Radio"
import Industry from "./Industry"
import Country from "./Country"
import Dropdown from "./Dropdown"
import JobRole from "./JobRole"

const questionTypes = {
  scale: "scale",
  shortText: "short-text",
  radio: "radio",
  country: "country",
  industry: "industry",
  jobRole: "job-role",
  dropdown: "dropdown"
}

const Answer = ({ type, ...props }) => {
  switch (type) {
    case questionTypes.scale:
      return <Scale {...props} />

    case questionTypes.shortText:
      return <ShortText {...props} />

    case questionTypes.radio:
      return <Radio {...props} />

    case questionTypes.industry:
      return <Industry {...props} />

    case questionTypes.country:
      return <Country {...props} />

    case questionTypes.jobRole:
      return <JobRole {...props} />

    case questionTypes.dropdown:
      return <Dropdown {...props} />

    default:
      return <Scale {...props} />
  }
}

export default Answer
