import { Flex } from "@engaging-tech/components"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import DemographicChips from "../../../../components/shared/demographicFilter/demographicChips"
import DemographicFilterButton from "../../../../components/shared/demographicFilter/demographicFilterButton"
import { loadFilters } from "../../../../components/shared/demographicFilter/store/demographicFilter.actions"
import { getDemographicFiltersList } from "../../../../components/shared/demographicFilter/store/demographicFilter.selectors"
import { getAllCountries, getAllIndustries } from "../../../misc/store/misc.selectors"
import { FilterWrapper, Title } from "./filters.styled"
import SelectInput from "./selectInput"
import TextSearchFilter from "./textSearch"

export default function Filters({
  setDemographicsFilterIsOpen,
  isDemographicFiltersSelected,
  searchTerm,
  setSearchTerm,
  countryFilter,
  setCountryFilter,
  industryFilter,
  setIndustryFilter,
  categoryList,
  categoryFilter,
  setCategoryFilter,
  demographicsFilter,
  onRemoveFilterItem
}) {
  const countriesList = useSelector(getAllCountries)
  const industriesList = useSelector(getAllIndustries)
  const demographicFiltersList = useSelector(getDemographicFiltersList)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadFilters())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Flex flexDirection={["column", "row"]} width="100%" justifyContent="space-between" alignItems="center">
        <Title mb={13}>Organisation Ranking</Title>

        {demographicFiltersList ? (
          <DemographicFilterButton
            isSelected={isDemographicFiltersSelected}
            onOpen={() => setDemographicsFilterIsOpen(true)}
          />
        ) : null}
      </Flex>
      <Flex
        flexDirection={["column", "row"]}
        width={1 / 1}
        justifyContent={["center", "flex-start"]}
        alignContent={["center", "flex-start"]}
        alignItems="center"
      >
        <Flex
          py={[1, 0]}
          width={1 / 1}
          alignItems="center"
          justifyContent={["center", "flex-start"]}
          alignContent={["center", "flex-start"]}
          mr={[0, 18]}
        >
          <TextSearchFilter
            placeholder="Search by organisation name"
            value={searchTerm}
            onChangeValue={setSearchTerm}
          />
        </Flex>

        <FilterWrapper mr={[0, 16]} py={[1, 0]}>
          <SelectInput
            options={{
              id: "filter-country",
              label: "Country",
              items: countriesList
            }}
            filterValue={countryFilter}
            onFilter={setCountryFilter}
          />
        </FilterWrapper>
        <FilterWrapper mr={[0, 16]} py={[1, 0]}>
          <SelectInput
            options={{
              id: "filter-industry",
              label: "Industry",
              items: industriesList
            }}
            filterValue={industryFilter}
            onFilter={setIndustryFilter}
          />
        </FilterWrapper>
        <FilterWrapper mr={[0, 16]} py={[1, 0]}>
          <SelectInput
            awards
            options={{
              id: "filter-category",
              label: "Awards",
              items: categoryList
            }}
            filterValue={categoryFilter}
            onFilter={setCategoryFilter}
          />
        </FilterWrapper>
      </Flex>

      <DemographicChips demographicsFilter={demographicsFilter} onRemoveFilterItem={onRemoveFilterItem} />
    </>
  )
}
