import { Box, Button, Flex, H4, Radio, Spinner, Text } from "@engaging-tech/components"
import { useFormik } from "formik"
import React from "react"
import styled from "styled-components"
import * as yup from "yup"

import ClickableIcon from "../../../ui/components/ClickableIcon"
import Separator from "../../../ui/components/Separator"

const LabelText = ({ children, ...props }) => (
  <Text color="dark.2" fontSize={3} {...props}>
    {children}
  </Text>
)

const FormWrapper = styled.form`
  margin-bottom: ${({ theme }) => theme.space[3]}px;
  width: 100%;
`

const EditAlert = ({ setModalType, onSubmit, activeAlert, isLoading }) => {
  const { __typename, id, frequency } = activeAlert
  const FREQUENCIES = ["Daily", "Weekly", "Monthly"]
  const validationSchema = yup.object().shape({
    frequency: yup.string().required("Please Select a Frequency")
  })
  const initialValues = {
    frequency,
    type: __typename,
    id
  }

  const Formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  const { values, errors, handleBlur, handleSubmit, handleChange } = Formik

  if (isLoading) return <Spinner color="primary.0" />

  return (
    <FormWrapper onSubmit={handleSubmit} width={1 / 1}>
      <Flex width={1 / 1} flexDirection="column" justifyContent="center">
        <Flex width={1 / 1} justifyContent="space-between" my={3}>
          <H4
            width={1 / 1}
            textAlign="flex-start"
            color="dark.1"
            fontFamily="Tiempos"
            fontWeight={800}
            fontSize={5}
          >
            Manage Alert
          </H4>
          <ClickableIcon name="close" onClick={() => setModalType("manage")} />
        </Flex>
        <Separator width={1 / 1} lineColor="dark.5" />
        <Text fontWeight={500} fontSize={4} py={3}>
          Frequency
        </Text>

        {FREQUENCIES.map(freq => (
          <Box mb={3} key={freq}>
            <Radio
              key={freq}
              type="radio"
              colour={values.frequency === freq ? "primary.0" : "dark.2"}
              name="frequency"
              id={freq}
              value={freq}
              checked={values.frequency === freq}
              onChange={handleChange}
              defaultChecked={values.frequency === freq}
              onBlur={handleBlur}
              label={() => (
                <LabelText color="dark.2" ml={5} mb={3}>
                  {freq}
                </LabelText>
              )}
            />
            <Separator width={1 / 1} lineColor="dark.5" />
          </Box>
        ))}

        {errors.frequency && (
          <LabelText color="error.0" mb={2}>
            {errors.frequency}
          </LabelText>
        )}
        <Flex flexDirection="row" width={1 / 1} height="100%" justifyContent="space-between">
          <Button
            elevation={0}
            variant="outline.primary.0"
            bg="light.0"
            onClick={() => setModalType("manage")}
            px={3}
            type="button"
          >
            Cancel
          </Button>

          <Button bg="secondary.0" type="submit" px={3}>
            Save
          </Button>
        </Flex>
      </Flex>
    </FormWrapper>
  )
}

export default EditAlert
