import * as y from "yup"

const schemaForm = y.object().shape({
  password: y
    .string()
    .required("A password is required")
    .min(8, "At least 8 characters")
    .matches(/[0-9]|[^a-zA-Z\d\s:\u00C0-\u00FF]/, "A number or special character is required"),
  confirmPassword: y
    .string()
    .oneOf([y.ref("password")], "Passwords must match")
    .required("Please confirm your password"),
  code: y.string().required("A code is required")
})

export default schemaForm
