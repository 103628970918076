import { Chip, Flex, IconButton, Text } from "@engaging-tech/components"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import { toFacebook, toLinkedIn, toTwitter } from "../../../userGeneratedContent/services/sharing.service"
import ReportPostContainer from "../../containers/ReportPostContainer"
import createShareableUrl from "../../lib/createShareableUrl"

const facebookLogo = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/social-media/facebook.png`)
const linkedInLogo = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/social-media/linkedin.png`)
const twitterLogo = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/social-media/twitter.png`)

const SharePopover = styled.div`
  position: absolute;
  top: 24px;
  right: 0;
  z-index: 10;

  width: auto;
  height: fit-content;

  padding: 15px;

  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
`

const ShareViaContainer = styled(Flex)`
  height: auto;
  width: auto;

  flex-direction: column;
`

const ShareIconButton = styled.button`
  width: 100%;

  background-color: transparent;

  border: none;
  border-radius: 5px;

  display: flex;
  align-items: center;

  cursor: pointer;

  :hover {
    background-color: #f5f5f5;
  }

  @media (max-width: 1024px) {
    margin: 4px 0;
  }
`

const ShareCustomIcon = styled.img`
  height: 24px;
  width: 24px;

  background-color: ${({ theme }) => theme.colors.light[0]};

  margin-right: 2px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}px) {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`

const ShareText = styled(Text)`
  width: auto;

  margin-left: 10px;
`

const Share = ({ id }) => {
  const popoverRef = useRef()

  const [popoverIsOpen, setPopoverIsOpen] = useState(false)
  const [showReportPost, setShowReportPost] = useState(false)

  useEffect(() => {
    const handleClickOutside = event => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setPopoverIsOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const handleReportPostClose = () => {
    setShowReportPost(false)
    setPopoverIsOpen(false)
  }

  const shareViaUI = (
    <ShareViaContainer>
      <Flex flexDirection="column">
        <ShareIconButton
          type="button"
          onClick={() => {
            setPopoverIsOpen(false)
            toFacebook(createShareableUrl(id))
          }}
        >
          <ShareCustomIcon src={facebookLogo} alt="Facebook icon" />
          <ShareText>Facebook</ShareText>
        </ShareIconButton>
        <ShareIconButton
          type="button"
          onClick={() => {
            setPopoverIsOpen(false)
            toTwitter(createShareableUrl(id))
          }}
        >
          <ShareCustomIcon src={twitterLogo} alt="Twitter icon" />
          <ShareText>Twitter</ShareText>
        </ShareIconButton>
        <ShareIconButton
          type="button"
          onClick={() => {
            setPopoverIsOpen(false)
            toLinkedIn(createShareableUrl(id))
          }}
        >
          <ShareCustomIcon src={linkedInLogo} alt="LinkedIn icon" />
          <ShareText>LinkedIn</ShareText>
        </ShareIconButton>
        <Chip
          mx={3}
          my={3}
          mt={3}
          trailingIcon="flag"
          onClick={() => {
            setShowReportPost(true)
          }}
        >
          Report this post
        </Chip>
      </Flex>
    </ShareViaContainer>
  )

  return (
    <>
      <Flex height={35} width={35} justifyContent="center" alignItems="center">
        <IconButton
          icon="more_vert"
          onClick={() => {
            setPopoverIsOpen(true)
          }}
        />
      </Flex>
      {popoverIsOpen && <SharePopover ref={popoverRef}>{shareViaUI}</SharePopover>}
      {showReportPost && <ReportPostContainer id={id} onParentClose={handleReportPostClose} />}
    </>
  )
}

export default Share
