import { connect } from "react-redux"

import Cta from "../components/Posts/CallToAction"
import getCTA from "../lib/callsToAction"
import { getSelectedCategory } from "../store/globalHub.selectors"

const mapState = state => ({
  content: getCTA(getSelectedCategory(state))
})

const CallToAction = connect(mapState)(Cta)

export default CallToAction
