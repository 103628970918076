import { Spinner } from "@engaging-tech/components"
import React from "react"
import { useSelector } from "react-redux"

import { getJobMatches } from "../../../../jobMatcher/store/jobMatcher.selectors"
import {
  getIsLoading,
  getIsPremium,
  getJobMatcherStatus
} from "../../../../yourProfile/store/yourProfile.selectors"
import TurnOnView from "./components/jobMatcherOffView"
import JobMatcherOnView from "./components/jobMatcherOnView"
import NonPremiumView from "./components/nonPremiumView"

const JobMatcherWidgetContent = ({ id }) => {
  const isLoading = useSelector(getIsLoading)
  const isPremium = useSelector(getIsPremium)

  const matches = useSelector(getJobMatches)
  const jobMatcherStatus = useSelector(getJobMatcherStatus)

  if (isLoading) {
    return <Spinner py={3} color="primary.0" width={1 / 1} />
  }

  if (!jobMatcherStatus) {
    return <TurnOnView id={id} />
  }

  if (!isPremium) {
    return <NonPremiumView id={id} />
  }

  return <JobMatcherOnView matches={matches} id={id} />
}

export default JobMatcherWidgetContent
