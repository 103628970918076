import { connect } from "react-redux"

import { AccountSelectors } from "../../account"
import { yourProfileSelectors } from "../../yourProfile"
import EndorsementComponent from "../components/Endorsements/Endorsements"
import { endorse } from "../store/globalHub.actions"
import { getIsEndorsing } from "../store/globalHub.selectors"

const mapState = state => ({
  user: yourProfileSelectors.getProfile(state),
  isAuthenticated: AccountSelectors.getIsAuthenticated(state),
  isLoading: getIsEndorsing(state)
})

const mapDispatch = dispatch => ({
  onToggle: endorsement => dispatch(endorse(endorsement))
})

const Endorsements = connect(mapState, mapDispatch)(EndorsementComponent)

export default Endorsements
