import React, { useEffect } from "react"
import submitterContainer from "../containers/submitter.container"

const SubmitterLoader = ({ onLoad, isSubmitting, isSubmitted }) => {
  useEffect(() => {
    onLoad(isSubmitted)
  }, [isSubmitted, onLoad])

  return isSubmitting ? <Spinner color="light.0" /> : null
}

export default submitterContainer(SubmitterLoader)
