import React from "react"
import { useDispatch } from "react-redux"

import { toggleServices } from "../../../../../yourProfile/store/yourProfile.actions"
import DynamicState from "../../../../containers/DynamicState"

const premiumImage = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/career-developer/widget-images/job-matcher-premium-no-bg.svg`)

const JobMatcherOffView = ({ id }) => {
  const dispatch = useDispatch()

  const jobMatcherTurnOn = () => dispatch(toggleServices({ jobMatcher: "ON" }))

  return (
    <DynamicState
      id={id}
      removable
      title="Find Great Job Vacancies"
      subTitle="Turn on Job Matcher to get automatically matched with a job vacancy based on your skill set and career goals."
      image={premiumImage}
      btnText="TURN ON JOB MATCHER"
      bgColor="secondary.5"
      onClick={() => jobMatcherTurnOn()}
    />
  )
}

export default JobMatcherOffView
