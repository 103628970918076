import { types } from "./demographicFilter.actions"

export const initialState = {
  isFiltersLoading: false,
  filters: [],
  error: null
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_FILTERS:
      return { ...state, isFiltersLoading: true }
    case types.LOAD_FILTERS_SUCCESS:
      return { ...state, isFiltersLoading: false, filters: action.payload }
    case types.LOAD_FILTERS_FAIL:
      return { ...state, isFiltersLoading: false }
    default:
      return state
  }
}
