import { Spinner } from "@engaging-tech/components"
import React from "react"
import { useHistory } from "react-router-dom"

import AuthGuard from "../../../ui/containers/AuthGuard"
import FourPillarsFooter from "./FourPillarsFooter"
import FourPillarsGrid from "./FourPillarsGrid"
import FourPillarsHeader from "./FourPillarsHeader"

const FourPillars = ({ isLoading, products, ...props }) => {
  const history = useHistory()

  if (isLoading) {
    return <Spinner color="primary.0" width={1 / 1} />
  }

  if (!products || products.length === 0) {
    return null
  }

  const premiumProduct = products.find(i => i.product.title.toLowerCase() === "premium")

  const getPremiumProductFeatureData = type => {
    const data = premiumProduct?.features?.find(i => i.type === type)

    return {
      title: data.title,
      type: data.type,
      paragraphs: data.paragraphs
    }
  }

  const onCancel = () => {
    history.goBack()
  }

  const pillars = [
    {
      icon: "store",
      ...getPremiumProductFeatureData("job_matcher")
    },
    {
      icon: "trending_up",
      ...getPremiumProductFeatureData("action_plans")
    },

    {
      icon: "notifications",
      ...getPremiumProductFeatureData("tailored_notifications")
    },
    {
      icon: "people",
      ...getPremiumProductFeatureData("mentor_matcher")
    },

    {
      icon: "shopping_basket",
      ...getPremiumProductFeatureData("exclusive_discounts")
    }
  ]

  return (
    <AuthGuard>
      <FourPillarsHeader />
      <FourPillarsGrid pillars={pillars} />
      <FourPillarsFooter {...props} premiumProduct={premiumProduct} onCancel={onCancel} />
    </AuthGuard>
  )
}

export default FourPillars
