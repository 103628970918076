export const types = {
  CONNECT_USER: "[Connect] Send Invite",
  CONNECT_USER_SUCCESS: "[Connect] Send Invite Success",
  CONNECT_USER_FAIL: "[Connect] Send Invite Fail",
  LOAD_INVITES: "[Connect] Load Invites ",
  LOAD_INVITES_SUCCESS: "[Connect] Load Invites Success",
  LOAD_INVITES_FAIL: "[Connect] Load Invites Fail",
  REPORT_USER: "[Global Hub] Report User",
  REPORT_USER_SUCCESS: "[Global Hub] Report User Success",
  REPORT_USER_FAIL: "[Global Hub] Report User Fail"
}

export const connectUser = payload => ({
  type: types.CONNECT_USER,
  payload
})

export const connectUserSuccess = payload => ({
  type: types.CONNECT_USER_SUCCESS,
  payload
})

export const connectUserFail = payload => ({
  type: types.CONNECT_USER_FAIL,
  payload
})

export const loadInvites = () => ({
  type: types.LOAD_INVITES
})

export const loadInvitesSuccess = payload => ({
  type: types.LOAD_INVITES_SUCCESS,
  payload
})
export const loadInvitesFail = payload => ({
  type: types.LOAD_INVITES_FAIL,
  payload
})

export const reportUser = (reportedUserId, reason) => {
  return {
    type: types.REPORT_USER,
    payload: { reason, reportedUserId }
  }
}

export const reportUserSuccess = responseUser => ({
  type: types.REPORT_USER_SUCCESS,
  payload: responseUser
})

export const reportUserFail = () => ({
  type: types.REPORT_USER_FAIL
})
