import { createSelector } from "reselect"

const byBookmarkDate = (a, b) =>
  new Date(a.bookmarkedAt).getTime() > new Date(b.bookmarkedAt).getTime()
    ? -1
    : 1

const myLibrary = state => state.myLibrary

export const getIsLoading = createSelector(myLibrary, state => state.isLoading)

export const getContent = createSelector(myLibrary, state => state.content)

export const getContentByBookmarkedDate = createSelector(getContent, content =>
  content.sort(byBookmarkDate)
)

export const getSelected = createSelector(myLibrary, state => state.selected)
