export const types = {
  LOAD: "[Bookmark] Load",
  LOAD_SUCCESS: "[Bookmark] Load Success",
  LOAD_FAIL: "[Bookmark] Load Fail",
  TOGGLE: "[Bookmark] Toggle Bookmark",
  BOOKMARK_SUCCESS: "[Bookmark] Bookmark Success",
  UNBOOKMARK_SUCCESS: "[Bookmark] Unbookmark Success",
  TOGGLE_FAIL: "[Bookmark] Toggle Bookmark Fail",
  RESET: "[Bookmark] Reset",
  LOAD_BY_MATERIAL_TYPE: "[Bookmark] LOAD_BY_MATERIAL_TYPE",
  LOAD_BY_MATERIAL_TYPE_SUCCESS: "[Bookmark] LOAD_BY_MATERIAL_TYPE Success",
  LOAD_BY_MATERIAL_TYPE_FAIL: "[Bookmark] LOAD_BY_MATERIAL_TYPE Fail"
}

export const load = () => ({
  type: types.LOAD
})

export const loadSuccess = bookmarks => ({
  type: types.LOAD_SUCCESS,
  payload: bookmarks
})

export const loadFail = () => ({
  type: types.LOAD_FAIL
})

export const toggle = ({ isBookmarking, id, postType, type = "material", selectedTabCategory }) => ({
  type: types.TOGGLE,
  payload: {
    isBookmarking,
    refId: id,
    refSubType: postType,
    refType: type,
    selectedTabCategory
  }
})

export const bookmarkSuccess = id => ({
  type: types.BOOKMARK_SUCCESS,
  payload: id
})

export const unbookmarkSuccess = id => ({
  type: types.UNBOOKMARK_SUCCESS,
  payload: id
})

export const toggleFail = () => ({
  type: types.TOGGLE_FAIL
})

export const reset = () => ({
  type: types.RESET
})

export const loadByMaterialType = action => ({
  type: types.LOAD_BY_MATERIAL_TYPE,
  payload: action.payload
})

export const loadByMaterialTypeSuccess = bookmarks => ({
  type: types.LOAD_BY_MATERIAL_TYPE_SUCCESS,
  payload: bookmarks
})

export const loadByMaterialTypeFail = () => ({
  type: types.LOAD_BY_MATERIAL_TYPE_FAIL
})
