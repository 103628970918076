import { connect } from "react-redux"
import IndustriesComponent from "../components/Industries"
import { updateYourProfile } from "../store/yourProfile.actions"
import { getIndustries } from "../store/yourProfile.selectors"

const mapState = state => ({
  industries: getIndustries(state)
})

const mapDispatch = dispatch => ({
  onUpdate: params => dispatch(updateYourProfile(params))
})

const Industries = connect(mapState, mapDispatch)(IndustriesComponent)

export default Industries
