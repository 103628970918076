import { connect } from "react-redux"
import { yourProfileSelectors } from "../../yourProfile"
import { LibraryBooksActions, LibraryBooksSelectors } from "../../gardners"

import { getIsLoading } from "../store/myLibrary.selectors"
import { downloadAudioBook } from "../store/myLibrary.actions"

import AudioBookList from "../components/Ebooks/AudiobookList"

const mapState = state => ({
  isLoading: getIsLoading(state),
  userDetails: yourProfileSelectors.getProfile(state),
  audioBooks: LibraryBooksSelectors.getAudioBooks(state)
})

const mapDispatch = dispatch => ({
  onDownload: () => dispatch(downloadAudioBook()),
  onLoad: userId => dispatch(LibraryBooksActions.loadDigitalPurchases(userId))
})

const Audiobooks = connect(mapState, mapDispatch)(AudioBookList)

export default Audiobooks
