import { Spinner } from "@engaging-tech/components"
import { useLocation, useParams } from "@engaging-tech/routing"
import React, { useEffect } from "react"
import surveyLoaderContainer from "../containers/surveyLoader.container"

const SurveyLoader = ({
  hasLoaded,
  isLoading,
  onLoadSurvey,
  onSetCode,
  onCheckCode,
  surveyId,
  onSavantaId
}) => {
  const { id } = useParams()
  const queryParams = new URLSearchParams(useLocation().search)
  const code = queryParams.get("code")
  const savantaId = queryParams.get("id")

  useEffect(() => {
    if (!hasLoaded) onLoadSurvey(id)

    if (savantaId) onSavantaId(savantaId)

    if (code) {
      onSetCode(code)
      onCheckCode({ code, surveyId: id })
    }
  }, [
    id,
    hasLoaded,
    onLoadSurvey,
    code,
    onSetCode,
    onCheckCode,
    surveyId,
    savantaId,
    onSavantaId
  ])

  return isLoading && <Spinner color="light.0" />
}

export default surveyLoaderContainer(SurveyLoader)
