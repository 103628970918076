import { getConfig } from "@engaging-tech/ssr-config"
import { apollo } from "../../../lib/Apollo"
import {
  LOAD_ALL_COUNTRIES,
  LOAD_ALL_INDUSTRIES,
  LOAD_ALL_JOB_ROLES,
  LOAD_SURVEY_JOB_ROLES,
  LOAD_SKILLS,
  LOAD_LANGUAGES,
  LOAD_HOBBIES,
  LOAD_REGIONS
} from "./misc.queries"

const GRAPHQL_API_URL = () =>
  `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

export const loadAllCountriesService = () =>
  apollo.query({
    uri: GRAPHQL_API_URL,
    query: LOAD_ALL_COUNTRIES
  })

export const loadAllIndustriesService = () =>
  apollo.query({
    uri: GRAPHQL_API_URL,
    query: LOAD_ALL_INDUSTRIES
  })

export const loadAllJobRolesService = () =>
  apollo.query({
    uri: GRAPHQL_API_URL,
    query: LOAD_ALL_JOB_ROLES
  })

export const loadSurveyJobRolesService = () =>
  apollo.query({
    uri: GRAPHQL_API_URL,
    query: LOAD_SURVEY_JOB_ROLES
  })

export const loadAllSkills = () =>
  apollo.query({
    uri: GRAPHQL_API_URL,
    query: LOAD_SKILLS
  })

export const loadAllLanguages = () =>
  apollo.query({
    uri: GRAPHQL_API_URL,
    query: LOAD_LANGUAGES
  })

export const loadAllHobbies = () =>
  apollo.query({
    uri: GRAPHQL_API_URL,
    query: LOAD_HOBBIES
  })

export const loadAllRegions = () =>
  apollo.query({
    uri: GRAPHQL_API_URL,
    query: LOAD_REGIONS
  })
