import { useParams } from "@engaging-tech/routing"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import { cvLibraryActions } from "../../../features/cvLibrary"
import SelectedCVView from "../../../features/cvLibrary/views/SelectedCV"
import TokenAuth from "../../../features/misc/components/TokenAuth"
import AppViewBaseLayout from "../../../layouts/AppViewBaseLayout"

export default function SelectedCV() {
  const { id } = useParams()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(cvLibraryActions.loadSelectedCV({ id, place: "in-app-view" }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <AppViewBaseLayout mx={3} mt={4}>
      <TokenAuth />
      <SelectedCVView />
    </AppViewBaseLayout>
  )
}
