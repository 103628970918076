import { getConfig } from "@engaging-tech/ssr-config"
import { apollo } from "../../../lib/Apollo"
import { newConfirmSubmissionToken } from "../../../lib/SubmissionTokenCookies"
import { ATTACH_USER, CHECKCODE, LOAD, SUBMIT } from "./survey.queries"

const GRAPHQL_API_URL = () =>
  `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

export const load = id =>
  apollo.query({
    uri: GRAPHQL_API_URL,
    query: LOAD,
    variables: { id }
  })

export const checkcode = ({ code, surveyId }) =>
  apollo.query({
    uri: GRAPHQL_API_URL,
    query: CHECKCODE,
    variables: { code, surveyId }
  })

export const submit = ({
  id,
  templateId,
  answers,
  groups,
  code,
  language,
  savantaId
}) =>
  apollo.mutate({
    uri: GRAPHQL_API_URL,
    mutation: SUBMIT,
    variables: { id, templateId, answers, groups, code, language, savantaId }
  })

export const attachUserToSubmission = (id, isAuthenticated) =>
  apollo.mutate({
    uri: GRAPHQL_API_URL,
    mutation: ATTACH_USER,
    variables: {
      id,
      token: !isAuthenticated ? newConfirmSubmissionToken() : null
    }
  })
