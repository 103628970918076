import { Box, Button, Card, Flex, Text } from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"
import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { getSubmissionId } from "../store/survey.selectors"

const PublicResults = ({ t }) => {
  const history = useHistory()
  const paths = usePaths()

  const submissionId = useSelector(getSubmissionId)

  const isInAppView = history.location.pathname.includes("/in-app-view")
  const linkPath = isInAppView
    ? paths.inAppViewOrganisationSurvey.results.replace(":id", submissionId)
    : paths.survey.results.replace(":id", submissionId)

  return (
    <Link to={linkPath}>
      <Button variant="primary.0" height={36} px={4}>
        {t("thankyou.continue", "")}
      </Button>
    </Link>
  )
}

const SuccessDialog = ({ isPrivate, title, savantaId }) => {
  const { t } = useTranslation(["organisation_survey"])

  if (savantaId)
    return (
      <Card mt={[5, 5, 6]} mb={[6, 5]} elevation={8} p={4} bg="light.0" alignItems="center">
        <Text fontSize={[3, 4]} textAlign="center" fontWeight="500" display="block" mb={2}>
          Thank you for completing the survey. Click the button bellow to collect your reward
        </Text>
        <Button variant="primary.0" mb={3}>
          <a rel="noreferrer" target="_blank" href={`https://sample.savanta.com/V2/c/${savantaId}`}>
            Collect reward
          </a>
        </Button>
      </Card>
    )

  return (
    <Card mt={[5, 5, 6]} mb={[6, 5]} elevation={8} bg="light.0" alignItems="center">
      <Box bg="dark.7" py={[2, 3]} px={3} width={1 / 1} mb={5}>
        <Text fontSize={[3, 6]} textAlign="center" fontWeight="500" display="block">
          {title}
        </Text>
      </Box>
      <Flex flexDirection="column" alignItems="center" px={3} pb={3}>
        <Text color="dark.2" fontWeight="500" mb={3} width="auto" textAlign="center">
          {t("thankyou.text1", "")}
        </Text>

        <Text fontWeight="500" fontSize={3} mb={5} width="auto" textAlign="center">
          {t("thankyou.text2", "")}
        </Text>
        {!isPrivate && <PublicResults t={t} />}
      </Flex>
    </Card>
  )
}

export default SuccessDialog
