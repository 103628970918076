import React from "react"
import { usePaths } from "@engaging-tech/routing"
import PromotionCard from "../../../ui/components/PromotionCard"

const Podcast = () => {
  const paths = usePaths()

  return (
    <PromotionCard
      title="Publish a podcast"
      content={[
        "Upload your own podcast onto WorkL via the audio uploader. Your podcast will be published here and on the Global Hub."
      ]}
      action={{
        description: "Publish podcast",
        linkTo: paths.resourceCentre.publish.podcast
      }}
    />
  )
}

export default Podcast
