import React from "react"
import styled from "styled-components"
import { Text, Box, Flex, UserDetails } from "@engaging-tech/components"

import AuthGuard from "./AuthGuard"
import Thumbnail from "../../Attachments/Thumbnail"
import Post from "../../Post"

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const Audio = styled.audio`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.space[2]}px;
`

const PodcastCard = ({ podcast, interactions, isAuthenticated }) => (
  <Post.Card bg="light.0">
    <Post.Header bg="secondary.1" mb={3} title="podcast" />
    <Post.Content>
      <UserDetails
        userHoldingImage={profilePlaceholder}
        user={podcast.user}
        time={podcast.publishedAt}
        interactions={interactions}
      />
      <Flex mb={3} flexDirection={["column", "row"]}>
        {podcast.coverImage && (
          <Thumbnail
            minHeight={["186px", "200px"]}
            width={[1, 1 / 3, "186px"]}
            mb={[3, 0]}
            mr={[0, 3]}
            src={podcast.coverImage.source}
          />
        )}
        <Box>
          <Box mb={1}>
            <Text fontWeight="500">{podcast.title}</Text>
          </Box>
          <Box mb={2}>
            {podcast.series && (
              <Text color="dark.2" fontSize={3}>
                {podcast.series.title}
              </Text>
            )}
          </Box>
          <Box>
            <Text color="dark.2" lineHeight="1.4">
              {podcast.summary}
            </Text>
          </Box>
        </Box>
      </Flex>
      <AuthGuard isAuthenticated={isAuthenticated}>
        <Audio controls>
          <source src={podcast.content[0].source} type="audio/mpeg" />
          Your browser does not support the audio element.
        </Audio>
      </AuthGuard>
    </Post.Content>
  </Post.Card>
)

export default PodcastCard
