import React from "react"
import styled from "styled-components"
import { Button, Text } from "@engaging-tech/components"

const ButtonText = styled(Text)`
  font-weight: 700;
  white-space: nowrap;
`

const StyledButton = styled(Button)`
  &:hover {
    box-shadow: ${({ theme }) => theme.elevations[1]};
    transform: ${({ disabled }) =>
      disabled ? "translateY(0px)" : "translateY(0.5px)"};
  }
`

const PrimaryButton = ({
  text = null,
  children = null,
  color = "light.0",
  disabled = false,
  fontSize = "14px",
  ...props
}) => {
  return (
    <StyledButton
      alignItems="center"
      width="auto"
      bg={disabled ? "primary.2" : "primary.0"}
      disabled={disabled}
      {...props}
    >
      <ButtonText color={color} width="auto" fontSize={fontSize}>
        {children || text}
      </ButtonText>
    </StyledButton>
  )
}

export default PrimaryButton
