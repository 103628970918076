import React from "react"
import { Text, Box, Flex, IconButton } from "@engaging-tech/components"

const ExplanationTopBar = ({ setVisibility, heading }) => {
  return (
    <Flex width={1 / 1} height={[2, 3]} bg="dark.7" alignItems="center">
      <Flex justifyContent="space-between" alignItems="center">
        <Box width={1 / 10} />
        <Text
          width={8 / 10}
          fontSize={[3, 5]}
          fontWeight={500}
          textAlign="center"
        >
          {heading}
        </Text>
        <IconButton onClick={() => setVisibility(false)} icon="clear" />
      </Flex>
    </Flex>
  )
}

export default ExplanationTopBar
