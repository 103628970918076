import { connect } from "react-redux"
import PremiumSettingsComponent from "../../components/ProfileSettingsModal/PremiumSettings"
import { setShowModal } from "../../../../yourProfile/store/yourProfile.actions"
import {
  getIsPremium,
  getPremiumExpiration
} from "../../../../yourProfile/store/yourProfile.selectors"

const mapState = state => ({
  isPremium: getIsPremium(state),
  premiumExpiration: getPremiumExpiration(state)
})

const mapDispatch = dispatch => ({
  setVisibility: type => dispatch(setShowModal(type))
})

const PremiumSettings = connect(mapState, mapDispatch)(PremiumSettingsComponent)

export default PremiumSettings
