import React, { useState } from "react"

import { Flex, Text, H1, MotionFlex } from "@engaging-tech/components"
import styled from "styled-components"
import ApplyDiscount from "../components/ApplyDiscount"

import PrimaryButton from "../../ui/components/PrimaryButton"
import TextButton from "../../ui/components/TextButton"
import CircleIcon from "../../ui/components/CircleIcon"
import subscriptionDetails from "../lib/subscriptionDetails"

const visaLogo = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/payment-options/visa-logo.png`)
const mastercardLogo = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/payment-options/mastercard-logo.png`)
const directDebitLogo = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/payment-options/direct-debit-logo.png`)

const Image = styled.img`
  margin: 3px;
`

const paymentOptions = [
  {
    id: "credit/debit",
    text: "credit/debit card",
    image: (
      <Flex>
        <Image src={visaLogo} height={24} />
        <Image src={mastercardLogo} height={24} />
        <Image src={directDebitLogo} height={24} />
      </Flex>
    )
  }
]

const PaymentOption = ({ details, selected, onSelect }) => {
  return (
    <Flex
      width={200}
      height={100}
      mr={3}
      style={{ cursor: "pointer" }}
      p="12px"
      onClick={() => onSelect(details.id)}
      borderRadius={7}
      bg={selected ? "#DEFCE9" : "#F0F1F3"}
      border={selected ? "2px solid #25E96E" : ""}
      flexDirection="column"
      position="relative"
    >
      <Flex mb={2} width="100%">
        {details.image}
      </Flex>
      <Text fontSize={2} width="100%">
        {`Choose this if you want to pay with your ${details.text}`}
      </Text>
      {selected && (
        <Flex
          position="absolute"
          style={{ top: "-10px", right: "-10px" }}
          width={25}
          height={25}
        >
          <CircleIcon name="check" bg="#25E96E" color="light.0" size={25} />
        </Flex>
      )}
    </Flex>
  )
}

const PaymentMethodForm = ({
  setPaymentMethod,
  cancel,
  promoId,
  onDiscountSubmit,
  isDiscountCodeLoading,
  discountCodeError,
  discountInfo,
  startingDate,
  total,
  discountInput,
  setDiscountInput
}) => {
  const [internalPaymentMethod, setInternalPaymentMethod] = useState(
    paymentOptions[0].id
  )

  return (
    <MotionFlex
      animate={{ opacity: 1 }}
      initial={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      width="100%"
      height="100%"
      transition={{ duration: 0.2 }}
      flexDirection="column"
    >
      <H1
        fontSize={5}
        color="primary.0"
        fontWeight={700}
        mb={3}
     
      >
        Account Order Details
      </H1>
      <Flex flexDirection="column" width="100%">
        <ApplyDiscount
          onSubmit={onDiscountSubmit}
          isDiscountCodeLoading={isDiscountCodeLoading}
          discountCodeError={discountCodeError}
          promoId={promoId}
          discountInfo={discountInfo}
          discountInput={discountInput}
          setDiscountInput={setDiscountInput}
        />
        <Flex alignItems="flex-end" flexDirection="column">
          <Flex justifyContent="space-between">
            <Text width="auto">Free Trial:</Text>
            <Text textAlign="right" width="auto" fontWeight={700}>
              {`${subscriptionDetails.defaultTrialPeriodDays} Days`}
            </Text>
          </Flex>
        </Flex>
        <Flex alignItems="flex-end" flexDirection="column">
          <Flex justifyContent="space-between" mt={2}>
            <Text width="auto">Premium Membership:</Text>
            <Text textAlign="right" width="auto" fontWeight={700}>
              {`£${subscriptionDetails.premiumPrice} a month`}
            </Text>
          </Flex>
          <Text width="auto" fontSize={3}>
            {`Starting from ${startingDate}`}
          </Text>
        </Flex>
        <Text width="auto" fontSize={3} mt={4}>
          You can cancel your Premium membership at any time.
        </Text>
        <Flex justifyContent="space-between" mt={3}>
          <Text width="auto" fontWeight={700}>
            TOTAL:
          </Text>
          <Text textAlign="right" width="auto" fontWeight={700}>
            {`£${total}`}
          </Text>
        </Flex>
      </Flex>
      <H1 fontSize={5} color="primary.0" fontWeight="700" mb={4} mt={5}>
        Select Payment Method
      </H1>
      <Flex mb={3}>
        {paymentOptions.map(details => (
          <PaymentOption
            key={details.id}
            details={details}
            onSelect={setInternalPaymentMethod}
            selected={details.id === internalPaymentMethod}
          />
        ))}
      </Flex>
      <Flex justifyContent="space-between" width="100%">
        <TextButton onClick={cancel}>Cancel</TextButton>
        <PrimaryButton
          onClick={() => {
            setPaymentMethod(internalPaymentMethod)
          }}
        >
          Enter Card Details
        </PrimaryButton>
      </Flex>
    </MotionFlex>
  )
}

export default PaymentMethodForm
