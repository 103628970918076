import { Box, Button } from "@engaging-tech/components"
import styled from "styled-components"

import { CompanyIcon } from "../../../../assets/icons"

export const ButtonWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const ButtonContent = styled(Button)`
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 5px;

  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  text-align: center;

  > svg > path {
    fill: ${({ disabled }) => (disabled ? "#1010104d" : "#003E4C")};
  }
`

export const ButtonIcon = styled(CompanyIcon)`
  margin-left: 13px;
`
