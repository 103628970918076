import { takeLatest, call, put } from "redux-saga/effects"
import {
  loadMyMentorMatches,
  deleteMentorMatch
} from "../services/mentorMatcher.service"
import { connectToUser } from "../../connect/services/connect.service"

import * as Actions from "./mentorMatcher.actions"
import { connectActions } from "../../connect"

export function* loadSaga() {
  try {
    const res = yield call(loadMyMentorMatches)
    if (res?.data?.getMyMentorMatches) {
      yield put(Actions.loadSuccess(res.data.getMyMentorMatches))
    }
  } catch (error) {
    yield put(Actions.loadFail())
  }
}

export function* deleteMatchSaga(action) {
  try {
    const res = yield call(deleteMentorMatch, action.payload)
    if (res.data.deleteMentorMatch)
      yield put(Actions.deleteMatchSuccess(action.payload))
  } catch (error) {
    yield put(Actions.loadFail())
  }
}

export function* connectSaga(action) {
  try {
    const res = yield call(connectToUser, action.payload.userId)

    yield put(
      connectActions.connectUserSuccess({
        id: action.payload.userId,
        response: res.data.connectToUser
      })
    )

    yield put(Actions.connectSuccess(action.payload.matchId))
  } catch {
    yield put(Actions.loadFail())
  }
}

export function* saga() {
  yield takeLatest(Actions.types.LOAD, loadSaga)
  yield takeLatest(Actions.types.DELETE_MATCH, deleteMatchSaga)
  yield takeLatest(Actions.types.CONNECT, connectSaga)
}
