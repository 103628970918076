import React from "react"
import styled from "styled-components"
import { Flex, Card, Text, Box } from "@engaging-tech/components"

const Wrapper = styled(Card)`
  width: 100%;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[1]}px) {
    order: 2;
    max-width: 265px;
    margin-left: ${({ theme }) => theme.space[3]}px;
  }
`

const BorderedBox = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[3]};
`

const BorderedFLex = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[3]};
`

const Thumbnail = styled.img`
  height: 120px;
  width: 80px;
`

const formatPrice = research => {
  const {
    access: { price }
  } = research
  return price.toFixed(2)
}

const BillingSummary = ({ post }) => (
  <Wrapper bg="light.0" mb={3} width={1 / 1}>
    <BorderedBox p={3}>
      <Text fontWeight={500} fontSize={4}>
        Buy Now
      </Text>
    </BorderedBox>
    <BorderedFLex p={3}>
      <Box width="auto" mr={3}>
        <Thumbnail src={post.coverImage.source} />
      </Box>
      <Flex flexDirection="column">
        <Text mb={3} width="auto" fontSize={4} fontWeight={500}>
          {post.title}
        </Text>
        <Text width="auto" fontSize={3} color="dark.2">
          {`${post.user.firstName} ${post.user.lastName}`}
        </Text>
      </Flex>
    </BorderedFLex>
    <Box p={3}>
      <Text fontSize={3} fontWeight={500} color="dark.2">
        All Price include VAT
      </Text>
      <Flex mt={3} justifyContent="space-between">
        <Text fontSize={4} fontWeight={500}>
          Price:
        </Text>
        <Text fontSize={4} fontWeight={500}>
          £{formatPrice(post)}
        </Text>
      </Flex>
    </Box>
  </Wrapper>
)

export default BillingSummary
