import { userInterfaceNotificationsStore } from "@engaging-tech/components"
import { useRouter } from "@engaging-tech/routing"
import { Auth } from "aws-amplify"
import { Formik } from "formik"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { signInSuccess } from "../../store/account.actions"
import { getIsAuthenticated } from "../../store/account.selectors"
import schemaForm from "./schema.form"
import SignInFormContent from "./signInFormContent"

export default function SignIn({ cancelRoute, redirectTo, siteLogo, footer, title }) {
  const router = useRouter()

  const [isLoading, setIsLoading] = useState(false)

  const isAuthenticated = useSelector(getIsAuthenticated)

  const dispatch = useDispatch()

  const onSubmit = async data => {
    try {
      setIsLoading(true)

      const result = await Auth.signIn({ username: data.email, password: data.password })

      dispatch(signInSuccess(result.signInUserSession.accessToken.payload.sub))
    } catch (error) {
      dispatch(
        userInterfaceNotificationsStore.actions.addNotification({
          message: typeof error.message === "string" ? error.message : "Unable to sign in"
        })
      )
    } finally {
      setIsLoading(false)
    }
  }

  const onGetCurrentSession = useCallback(async () => {
    const result = await Auth.currentSession()

    if (result?.accessToken?.payload?.sub) {
      dispatch(signInSuccess(result.accessToken.payload.sub))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    onGetCurrentSession()
  }, [onGetCurrentSession])

  useEffect(() => {
    if (isAuthenticated) {
      router.redirect(redirectTo)
    }
  }, [isAuthenticated, redirectTo, router, onGetCurrentSession])

  return (
    <Formik
      initialValues={{
        email: "",
        password: ""
      }}
      validationSchema={schemaForm}
      onSubmit={accountDetails => onSubmit({ ...accountDetails })}
    >
      {props => (
        <SignInFormContent
          cancelRoute={cancelRoute}
          siteLogo={siteLogo}
          title={title}
          footer={footer}
          isLoading={isLoading}
          {...props}
        />
      )}
    </Formik>
  )
}
