import { Button, Card, Flex, Spinner, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import { format } from "date-fns"
import React from "react"
import styled from "styled-components"

import PercentageGraphic from "../../ui/components/PercentageGraphic"
import EmptyState from "./EmptyState"

const wfhIcon = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/work-from-home-icon.png`)

const Icon = styled.img`
  width: 26px;
  position: absolute;
  margin-left: -35px;
  margin-top: -13px;
`

const surveyTypes = {
  workFromHome: "Working From Home Survey",
  organisation: "Organisation Survey",
  individualWorkFromHome: "Working From Home Happiness Survey",
  happiness: "Workplace Happiness Survey",
  employeeStarter: "Workplace Starter Survey",
  employeeExit: "Workplace Exit Survey"
}

const SurveyHeader = ({ type }) => (
  <Text fontWeight={500} fontSize={4} textAlign="center" mb={3}>
    {type === "workFromHome" && <Icon alt="" src={wfhIcon} />}{" "}
    {surveyTypes[type] || "Workplace Happiness Survey"}
  </Text>
)

const SurveysList = ({ surveys }) => {
  if (surveys.isLoading)
    return (
      <Flex width={1 / 1} justifyContent="center">
        <Spinner color="primary.0" />
      </Flex>
    )

  if (!surveys.isLoading && surveys.data.length === 0) return <EmptyState />

  return (
    <Flex flexDirection="row" flexWrap="wrap" justifyContent="space-between" width={1 / 1}>
      {surveys.data?.map(survey => (
        <Card key={survey?.id} bg="light.0" width={["100%", 380]} pt={4} pb={3} mx={[0]} my={[2, 3]}>
          <Flex flexDirection="column">
            <SurveyHeader type={survey?.surveyType} />
            <Text fontSize={3} textAlign="center">
              {format(new Date(survey?.completedAt), "'Survey completed on: 'do MMMM yyyy")}
            </Text>
            <PercentageGraphic score={survey.score} />
            <Flex flexDirection="row" width="100%" justifyContent="center" mt={2}>
              <Link to={`/develop-career/your-surveys/results/${survey.id}`}>
                <Button bg="secondary.0">View Results</Button>
              </Link>
            </Flex>
          </Flex>
        </Card>
      ))}
    </Flex>
  )
}

export default SurveysList
