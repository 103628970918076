import React from "react"

import AuthGuard from "../../features/ui/containers/AuthGuard"
import BookmarkedMaterial from "../../features/myLibrary/containers/BookmarkedMaterial"
import DetailsLayout from "../../layouts/DetailsLayout"

const Research = () => (
  <AuthGuard>
    <DetailsLayout>
      <BookmarkedMaterial />
    </DetailsLayout>
  </AuthGuard>
)

export default Research
