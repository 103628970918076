/* eslint-disable no-else-return */
import React from "react"

import { XIcon } from "../../../assets/icons"
import { Chip, ChipContainer, ChipText } from "./demographicChips.styled"

export default function DemographicChips({ demographicsFilter, onRemoveFilterItem }) {
  return (
    <>
      {Object.values(demographicsFilter)?.length ? (
        <ChipContainer>
          {Object.values(demographicsFilter).map(item => {
            if (item && item?.length) {
              return item.map(subItem => (
                <Chip
                  key={subItem.value}
                  width="auto"
                  borderRadius={99}
                  height={32}
                  elevation={0}
                  onClick={() => onRemoveFilterItem(subItem.value)}
                >
                  <ChipText>{subItem.label}</ChipText>
                  <XIcon />
                </Chip>
              ))
            } else if (item?.value && item.value !== "0") {
              return (
                <Chip
                  key={item.value}
                  width="auto"
                  borderRadius={99}
                  height={32}
                  elevation={0}
                  onClick={() => onRemoveFilterItem(item.value)}
                >
                  <ChipText>{item.label}</ChipText>
                  <XIcon />
                </Chip>
              )
            }

            return null
          })}
        </ChipContainer>
      ) : null}
    </>
  )
}
