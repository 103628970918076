import React from "react"

import BaseLayout from "../layouts/BaseLayout"
import ContentBlock from "../features/landingPage/components/ContentBlock"
import Description from "../features/landingPage/components/SiteFeatures/Description"
import homepageFeatures from "../features/landingPage/lib/homepageFeatures"

const DevelopCareer = () => (
  <BaseLayout>
    <ContentBlock py={0}>
      <Description feature={homepageFeatures[2]} />
    </ContentBlock>
  </BaseLayout>
)

export default DevelopCareer
