export const types = {
  LOAD_ALL_COUNTRIES: "[MISC] Load All Countries",
  LOAD_ALL_INDUSTRIES: "[MISC] Load All Industries",
  LOAD_ALL_JOB_ROLES: "[MISC] Load All Job Roles",
  LOAD_SURVEY_JOB_ROLES: "[MISC] Load Survey Job Roles",
  LOAD_ALL_COUNTRIES_SUCCESS: "[MISC] Load All Countries Success",
  LOAD_ALL_INDUSTRIES_SUCCESS: "[MISC] Load All Industries Success",
  LOAD_ALL_JOB_ROLES_SUCCESS: "[MISC] Load All Job Roles Success",
  LOAD_SURVEY_JOB_ROLES_SUCCESS: "[MISC] Load All Survey Job Roles Success",
  LOAD_FAIL_COUNTRIES: "[MISC] Load Fail Countries",
  LOAD_FAIL_INDUSTRIES: "[MISC] Load Fail Industries",
  LOAD_FAIL_JOB_ROLES: "[MISC] Load Fail Job Roles",
  LOAD_FAIL_SURVEY_JOB_ROLES: "[MISC] Load Fail Survey Job Roles",
  LOAD_SKILLS: "[MISC] Load Skills",
  LOAD_SKILLS_SUCCESS: "[MISC] Load Skills Success",
  LOAD_SKILLS_FAIL: "[MISC] LOAD Skills Fail",
  LOAD_LANGUAGES: "[MISC] Load LANGUAGES",
  LOAD_LANGUAGES_SUCCESS: "[MISC] Load LANGUAGES Success",
  LOAD_LANGUAGES_FAIL: "[MISC] LOAD Skills Fail",
  LOAD_HOBBIES: "[MISC] Load Hobbies",
  LOAD_HOBBIES_SUCCESS: "[MISC] Load Hobbies Success",
  LOAD_HOBBIES_FAIL: "[MISC] LOAD Hobbies Fail",
  LOAD_REGIONS: "[MISC] Load Regions",
  LOAD_REGIONS_SUCCESS: "[MISC] Load Regions Success",
  LOAD_REGIONS_FAIL: "[MISC] LOAD Regions Fail"
}

export const loadAllCountries = () => ({
  type: types.LOAD_ALL_COUNTRIES
})
export const loadAllIndustries = () => ({
  type: types.LOAD_ALL_INDUSTRIES
})
export const loadAllJobRoles = () => ({
  type: types.LOAD_ALL_JOB_ROLES
})
export const loadSurveyJobRoles = () => ({
  type: types.LOAD_SURVEY_JOB_ROLES
})

export const loadAllIndustriesSuccess = payload => ({
  type: types.LOAD_ALL_INDUSTRIES_SUCCESS,
  payload
})
export const loadAllJobRolesSuccess = payload => ({
  type: types.LOAD_ALL_JOB_ROLES_SUCCESS,
  payload
})
export const loadSurveyJobRolesSuccess = payload => ({
  type: types.LOAD_SURVEY_JOB_ROLES_SUCCESS,
  payload
})

export const loadAllCountriesSuccess = payload => ({
  type: types.LOAD_ALL_COUNTRIES_SUCCESS,
  payload
})

export const loadFailCountries = () => ({
  type: types.LOAD_FAIL_COUNTRIES
})
export const loadFailIndustries = () => ({
  type: types.LOAD_FAIL_INDUSTRIES
})
export const loadFailJobRoles = () => ({
  type: types.LOAD_FAIL_JOB_ROLES
})
export const loadFailSurveyJobRoles = () => ({
  type: types.LOAD_FAIL_SURVEY_JOB_ROLES
})

export const loadSkills = () => ({
  type: types.LOAD_SKILLS
})

export const loadSkillsSuccess = payload => ({
  type: types.LOAD_SKILLS_SUCCESS,
  payload
})

export const loadSkillsFail = () => ({
  type: types.LOAD_SKILLS_FAIL
})

export const loadLanguages = () => ({
  type: types.LOAD_LANGUAGES
})

export const loadLanguagesSuccess = payload => ({
  type: types.LOAD_LANGUAGES_SUCCESS,
  payload
})

export const loadLanguagesFail = () => ({
  type: types.LOAD_LANGUAGES_FAIL
})

export const loadHobbies = () => ({
  type: types.LOAD_HOBBIES
})

export const loadHobbiesSuccess = payload => ({
  type: types.LOAD_HOBBIES_SUCCESS,
  payload
})

export const loadHobbiesFail = () => ({
  type: types.LOAD_HOBBIES_FAIL
})

export const loadRegions = () => ({
  type: types.LOAD_REGIONS
})

export const loadRegionsSuccess = payload => ({
  type: types.LOAD_REGIONS_SUCCESS,
  payload
})

export const loadRegionsFail = () => ({
  type: types.LOAD_REGIONS_FAIL
})
