import React, { useRef, useEffect } from "react"
import { Flex, Text } from "@engaging-tech/components"
import styled from "styled-components"
import { useField } from "formik"
import useTimedReRender from "../../../hooks/useTimedReRender"
import useWindowSize from "../../../hooks/useWindowSize"

import InputLoadError from "../InputLoadError"

import DoubleKnobSliderTrack from "./components/DoubleKnobSliderTrack"
import SliderNumberField from "./components/SliderNumberField"
import SliderKnob from "./components/SliderKnob"

const Holder = styled(Flex)`
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`

// We calculate the value of the knob on the track.
const getValueOfKnob = (knob, trackWidth, max, increment) => {
  const value = parseInt((max / (trackWidth / knob)).toFixed(0), 10)
  return Math.ceil(value / increment) * increment
}

// Places the knob on the track as a percentage
const getPixelValue = (value, max, trackWidth) => {
  return (value / max) * trackWidth
}

const updateKnob1ValueInterceptor = ({
  xPoint1,
  xPoint2,
  constraintsRef,
  trackWidth,
  helpers1,
  max,
  increment,
  minimumDifference
}) => {
  const limitedValueCalculator = value => {
    if (value - constraintsRef.current.offsetLeft < 0) {
      return 0
    }
    if (
      value - constraintsRef.current.offsetLeft >
      xPoint2 - getPixelValue(minimumDifference, max, trackWidth)
    ) {
      return xPoint2 - getPixelValue(minimumDifference, max, trackWidth)
    }
    return value - constraintsRef.current.offsetLeft
  }
  const limitedValue = limitedValueCalculator(xPoint1)

  helpers1.setValue(
    Number(getValueOfKnob(limitedValue, trackWidth, max, increment))
  )
}

const updateKnob2ValueInterceptor = ({
  xPoint1,
  xPoint2,
  constraintsRef,
  trackWidth,
  helpers2,
  max,
  increment,
  minimumDifference
}) => {
  const limitedValueCalculator = value => {
    if (
      value - constraintsRef.current.offsetLeft <
      xPoint1 + getPixelValue(minimumDifference, max, trackWidth)
    ) {
      return xPoint1 + getPixelValue(minimumDifference, max, trackWidth)
    }
    if (value - constraintsRef.current.offsetLeft > trackWidth) {
      return trackWidth
    }
    return value - constraintsRef.current.offsetLeft
  }
  const limitedValue = limitedValueCalculator(xPoint2)

  helpers2.setValue(
    Number(getValueOfKnob(limitedValue, trackWidth, max, increment))
  )
}

const calculateSliderKnobPosition = (sliderValue, trackWidth, max) => {
  const v = getPixelValue(sliderValue, max, trackWidth)
  if (v >= trackWidth) {
    return trackWidth
  }
  if (v <= 0) {
    return 0
  }
  return v
}

const DoubleHandleValueSlider = ({
  field1,
  meta1,
  helpers1,
  field2,
  meta2,
  helpers2,
  label,
  max,
  increment,
  trackHeight,
  knobSize,
  minimumDifference,
  symbol,
  defaultMin,
  defaultMax,
  sliderContainerRef
}) => {
  // A constraintsRef to use set on the track and disallow the knobs to move further than the knob
  const constraintsRef = useRef(null)
  // Causes component re-render on window resize
  useWindowSize()

  const invalidRange =
    typeof field1.value === "number" && typeof field2.value === "number"
      ? field1.value + minimumDifference > field2.value
      : true
  const maxHigherThanLower = field1.value > field2.value
  const minimumDistanceError = field2.value - field1.value < minimumDifference

  useEffect(() => {
    if (invalidRange) {
      helpers1.setValue(typeof defaultMin === "number" ? defaultMin : max / 2)
      helpers2.setValue(
        typeof defaultMax === "number"
          ? defaultMax
          : Number(max / 2 + minimumDifference)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useTimedReRender(500)

  useEffect(() => {
    if (invalidRange && !meta1.error) {
      if (maxHigherThanLower) {
        helpers1.setError("Maximum can not be a lower value than the minimum")
      } else if (minimumDistanceError) {
        helpers1.setError(
          `Must be a minimum difference of ${symbol}${minimumDifference}`
        )
      } else {
        helpers1.setError("Invalid input range")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field1, field2])

  useTimedReRender(500)

  if (!sliderContainerRef?.current) {
    return <></>
  }

  const trackWidth = sliderContainerRef.current.offsetWidth

  if (!("value" in field1) || !("value" in field2)) {
    return <InputLoadError />
  }

  if (!("value" in field2)) {
    return <InputLoadError />
  }

  return (
    <Flex flexDirection="column" justifyContent="flex-start" mb={2} mt={2}>
      {label && <Text mb={2}>{label}</Text>}
      <Holder>
        <DoubleKnobSliderTrack
          ref={constraintsRef}
          trackWidth={trackWidth}
          trackHeight={trackHeight}
          knob1Position={getPixelValue(field1.value, max, trackWidth)}
          knob2Position={getPixelValue(field2.value, max, trackWidth)}
        >
          <SliderKnob
            trackHeight={trackHeight}
            knobSize={knobSize}
            drag="x"
            initial={false}
            animate={{
              x: calculateSliderKnobPosition(field1.value, trackWidth, max)
            }}
            transition={{ delay: 0.2 }}
            dragMomentum={false}
            onDragEnd={(event, info) => {
              updateKnob1ValueInterceptor({
                xPoint1: info.point.x,
                xPoint2: calculateSliderKnobPosition(
                  field2.value,
                  trackWidth,
                  max
                ),
                constraintsRef,
                trackWidth,
                helpers1,
                max,
                increment,
                minimumDifference
              })
            }}
            onDrag={(event, info) => {
              updateKnob1ValueInterceptor({
                xPoint1: info.point.x,
                xPoint2: calculateSliderKnobPosition(
                  field2.value,
                  trackWidth,
                  max
                ),
                constraintsRef,
                trackWidth,
                helpers1,
                max,
                increment,
                minimumDifference
              })
            }}
            dragConstraints={{
              left: 0,
              right:
                calculateSliderKnobPosition(field2.value, trackWidth, max) -
                getPixelValue(minimumDifference, max, trackWidth)
            }}
            dragElastic={0}
          />
          <SliderKnob
            trackHeight={trackHeight}
            knobSize={knobSize}
            drag="x"
            initial={false}
            animate={{
              x: calculateSliderKnobPosition(field2.value, trackWidth, max)
            }}
            transition={{ delay: 0.2 }}
            dragMomentum={false}
            onDragEnd={(event, info) => {
              updateKnob2ValueInterceptor({
                xPoint1: calculateSliderKnobPosition(
                  field1.value,
                  trackWidth,
                  max
                ),
                xPoint2: info.point.x,
                constraintsRef,
                trackWidth,
                helpers2,
                max,
                increment,
                minimumDifference
              })
            }}
            onDrag={(event, info) => {
              updateKnob2ValueInterceptor({
                xPoint1: calculateSliderKnobPosition(
                  field1.value,
                  trackWidth,
                  max
                ),
                xPoint2: info.point.x,
                constraintsRef,
                trackWidth,
                helpers2,
                max,
                increment,
                minimumDifference
              })
            }}
            dragConstraints={{
              left:
                calculateSliderKnobPosition(field1.value, trackWidth, max) +
                getPixelValue(minimumDifference, max, trackWidth),
              right: trackWidth
            }}
            dragElastic={0}
          />
        </DoubleKnobSliderTrack>
      </Holder>
      <Flex
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        mt={2}
      >
        {symbol && (
          <Text
            width={10}
            mr="4px"
            fontSize={5}
            fontWeight={700}
            color={
              invalidRange || (meta1.error && meta1.touched)
                ? "error.0"
                : "primary.0"
            }
            height={20}
          >
            {symbol}
          </Text>
        )}
        <SliderNumberField
          width={1 / 1}
          id={field1.name}
          name={field1.name}
          type="number"
          onChange={field1.onChange}
          onBlur={field1.onBlur}
          value={field1.value}
          error={invalidRange || (meta1.error && meta1.touched)}
        />
        <Text
          width={10}
          mr="5px"
          ml="5px"
          fontSize={5}
          fontWeight={700}
          color={invalidRange ? "error.0" : "primary.0"}
          height={20}
        >
          -
        </Text>
        {symbol && (
          <Text
            width={10}
            mr="4px"
            fontSize={5}
            fontWeight={700}
            color={
              invalidRange || (meta2.error && meta2.touched)
                ? "error.0"
                : "primary.0"
            }
            height={20}
          >
            {symbol}
          </Text>
        )}
        <SliderNumberField
          width={1 / 1}
          id={field2.name}
          name={field2.name}
          type="number"
          onChange={field2.onChange}
          onBlur={field2.onBlur}
          value={field2.value}
          error={invalidRange || (meta2.error && meta2.touched)}
        />
      </Flex>
      {meta1.error && (meta1.touched || meta2.touched) && (
        <Text ml={2} mt={2} fontSize={2} color="error.0">
          {meta1.error}
        </Text>
      )}
      {meta2.error && meta2.touched && (
        <Text ml={2} mt={2} fontSize={2} color="error.0">
          {meta2.error}
        </Text>
      )}
    </Flex>
  )
}

const DoubleHandleValueSliderHandler = ({
  label,
  name1,
  name2,
  max,
  increment,
  trackHeight,
  knobSize,
  minimumDifference,
  symbol,
  defaultMin,
  defaultMax,
  ...props
}) => {
  const [field1, meta1, helpers1] = useField({ name: name1, type: "number" })
  const [field2, meta2, helpers2] = useField({ name: name2, type: "number" })
  const sliderContainerRef = useRef()
  return (
    <Flex ref={sliderContainerRef} {...props}>
      <DoubleHandleValueSlider
        field1={field1}
        meta1={meta1}
        helpers1={helpers1}
        field2={field2}
        meta2={meta2}
        helpers2={helpers2}
        label={label}
        max={max}
        increment={increment}
        trackHeight={trackHeight}
        knobSize={knobSize}
        minimumDifference={minimumDifference}
        symbol={symbol}
        defaultMin={defaultMin}
        defaultMax={defaultMax}
        sliderContainerRef={sliderContainerRef}
      />
    </Flex>
  )
}

export default DoubleHandleValueSliderHandler
