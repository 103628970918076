import { Card, Text } from "@engaging-tech/components"
import { useRouter } from "@engaging-tech/routing"
import React from "react"

import PrimaryButton from "../../../ui/components/PrimaryButton"

const CallToAction = ({ content, content: { external = false, newTab = false } }) => {
  const router = useRouter()

  return (
    <Card mb={[3, 4, 4]} p={3} alignItems="center" width={1 / 1}>
      <Text mb={3} fontWeight={700} width="auto" color="primary.0">
        {content.title}
      </Text>
      <Text mb={3} color="dark.2" width="auto">
        {content.description}
      </Text>
      <PrimaryButton
        color="grey.0"
        text={content.action}
        height={38}
        px={3}
        onClick={() => {
          if (external) {
            window.open(content.url())
          } else if (newTab) {
            window.open(content.url(), "_blank", "noreferrer")
          } else {
            router.navigate(content.url())
          }
        }}
      />
    </Card>
  )
}

export default CallToAction
