import React from "react"
import styled from "styled-components"
import { Flex, Text } from "@engaging-tech/components"

const emptyStateMatrixSvg = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/empty-state-matrix.svg`)

const BackgroundImageFlex = styled(Flex)`
  width: 100%;
  height: 100%;
  background-image: url(${emptyStateMatrixSvg});
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
`

const EmptyLineGraphState = ({ stateShow }) => {
  return stateShow === "NO_SURVEYS" ? (
    <BackgroundImageFlex
      width={1 / 1}
      position="relative"
      height={[100, 100]}
      py={3}
    >
      <Text
        color="dark.1"
        fontWeight={500}
        textAlign="center"
        width="auto"
        maxWidth={500}
        p={10}
        px={[30, 10]}
        ml={[4, 0]}
      >
        You have not taken enough surveys to show your trend.
      </Text>
      <Text
        color="dark.2"
        fontWeight={400}
        textAlign="center"
        width="auto"
        maxWidth={450}
        p={10}
        px={[30, 10]}
        ml={[4, 0]}
      >
        You need to have taken at least 3 surveys.
      </Text>
      <Text
        color="dark.2"
        fontWeight={400}
        textAlign="center"
        width="auto"
        maxWidth={450}
        p={10}
        px={[30, 10]}
        ml={[4, 0]}
      >
        By taking surveys regularly, you can track how you are improving here.
      </Text>
    </BackgroundImageFlex>
  ) : (
    <BackgroundImageFlex
      width={1 / 1}
      position="relative"
      height={[100, 400]}
      py={3}
    >
      <Text
        color="dark.1"
        fontWeight={500}
        textAlign="center"
        width="auto"
        maxWidth={500}
        p={10}
        px={[30, 10]}
        ml={[4, 0]}
      >
        You do not have enough data under your selected filters.
      </Text>
      <Text
        color="dark.2"
        fontWeight={400}
        textAlign="center"
        width="auto"
        maxWidth={[400, 450]}
        p={10}
        px={[30, 10]}
        ml={[4, 0]}
        mb={[4, 0]}
      >
        Try changing your selected filters to a different range.
      </Text>
    </BackgroundImageFlex>
  )
}

export default EmptyLineGraphState
