import { connect } from "react-redux"

import { cvLibrarySelectors } from ".."
import { yourProfileSelectors } from "../../yourProfile"
import CVLibraryComponent from "../components/CVLibrary"

const mapState = state => ({
  isPremium: yourProfileSelectors.getIsPremium(state),
  cvList: cvLibrarySelectors.getCVs(state)
})
const CVLibrary = connect(mapState, null)(CVLibraryComponent)

export default CVLibrary
