import { types } from "./surveyResults.actions"

export const initialState = {
  isLoading: false,
  hasError: false,
  hasLoaded: false,
  response: null
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD:
    case types.RETRY_LOAD:
      return { ...state, isLoading: true }

    case types.LOAD_SUCCESS:
      return {
        ...state,
        response: action.payload,
        hasLoaded: true,
        isLoading: false
      }

    case types.LOAD_FAIL:
      return { ...state, hasError: true, hasLoaded: true, isLoading: false }

    case types.RESET:
      return { ...initialState }

    default:
      return state
  }
}
