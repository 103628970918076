import { Flex, Toggle, Text, Box, Icon } from "@engaging-tech/components"
import { useRouter, usePaths } from "@engaging-tech/routing"
import React from "react"
import styled from "styled-components"

const BottomBorderFlex = styled(Flex)`
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.colors.dark[4]};
  border-width: 1px;
`

const AllTogglesWithHeaders = ({ services, handleToggle, isPremium }) => {
  const toogleLabelUI = (
    <Text fontSize="12px" color={isPremium ? "dark.2" : "yellow.0"}>
      Match me with jobs
    </Text>
  )

  return (
    <Flex bg="light.0" width={1 / 1} flexDirection="column">
      <BottomBorderFlex
        width={1 / 1}
        pt={3}
        pb={3}
        pl={3}
        justifyContent="flex-start"
        flexDirection="column"
        bg={isPremium ? "light.0" : "primary.0"}
      >
        <Flex
          justifyContent="flex-start"
          alignContent="center"
          alignItems="center"
          mb={12}
        >
          <Text
            fontSize={2}
            fontWeight={700}
            width={80}
            color={isPremium ? "dark.2" : "yellow.0"}
          >
            Job Matcher
          </Text>
          {isPremium ? <></> : <Icon name="stars" color="yellow.0" />}
        </Flex>

        <Toggle
          id="job-matcher-feature-toggle"
          onChange={value => {
            handleToggle({
              service: "jobMatcher",
              value,
              premiumService: true
            })
          }}
          label={toogleLabelUI}
          initialState={services?.jobMatcher === "ON"}
          onColour="secondary.0"
          m={2}
          size={25}
          offColour={isPremium ? "light.0" : "primary.0"}
          borderColor={isPremium ? "primary.0" : "yellow.0"}
        />
      </BottomBorderFlex>
      <Flex
        pt={3}
        pb={3}
        pl={3}
        justifyContent="flex-start"
        flexDirection="column"
      >
        <Text fontSize={2} mb={3} fontWeight={700}>
          Mentor Matcher
        </Text>
        <Flex justifyContent="space-around" flexDirection="column">
          <Box mb={3}>
            <Toggle
              id="toggle-mentor"
              onChange={value => {
                handleToggle({
                  service: "mentorMatcher",
                  value,
                  premiumService: true
                })
              }}
              label="Find me a mentor"
              initialState={services?.mentorMatcher === "ON"}
              onColour="secondary.0"
              size={25}
              offColour="light.0"
              borderColor="primary.0"
              disabled={!isPremium}
            />
          </Box>
          <Box>
            <Toggle
              id="toggle-mentee"
              onChange={value => {
                handleToggle({
                  service: "menteeMatcher",
                  value
                })
              }}
              label="Find me a mentee"
              initialState={services?.menteeMatcher === "ON"}
              onColour="secondary.0"
              size={25}
              offColour="light.0"
              borderColor="primary.0"
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

const MentorToggle = ({ services, handleToggle, isPremium }) => (
  <Box>
    <Toggle
      id="toggle-mentor"
      onChange={value => {
        handleToggle({
          service: "mentorMatcher",
          value,
          premiumService: true
        })
      }}
      label="Find me a mentor"
      initialState={services?.mentorMatcher === "ON"}
      onColour="secondary.0"
      size={25}
      offColour="light.0"
      borderColor="primary.0"
      disabled={!isPremium}
    />
  </Box>
)

const MenteeToggle = ({ services, handleToggle }) => (
  <Box>
    <Toggle
      id="toggle-mentee"
      onChange={value => {
        handleToggle({
          service: "menteeMatcher",
          value
        })
      }}
      label="Find me a mentee"
      initialState={services?.menteeMatcher === "ON"}
      onColour="secondary.0"
      size={25}
      offColour="light.0"
      borderColor="primary.0"
    />
  </Box>
)

const JobToggle = ({ services, handleToggle, isPremium }) => {
  const toggleLabelUI = (
    <Text fontSize="12px" color={isPremium ? "dark.2" : "yellow.0"}>
      Match me with jobs
    </Text>
  )

  return (
    <Toggle
      id="toggle-job-matcher-feature"
      onChange={value => {
        handleToggle({
          service: "jobMatcher",
          value,
          premiumService: true
        })
      }}
      label={toggleLabelUI}
      initialState={services?.jobMatcher === "ON"}
      onColour="secondary.0"
      m={2}
      size={25}
      offColour={isPremium ? "light.0" : "primary.0"}
      borderColor={isPremium ? "primary.0" : "yellow.0"}
    />
  )
}

const ServicesToggle = ({
  isPremium,
  services,
  updateServices,
  jobMatcherProfileComplete,
  mentor,
  mentee,
  job
}) => {
  const router = useRouter()
  const paths = usePaths()

  const handleToggle = async ({ service, value, premiumService }) => {
    if (isPremium && service === "jobMatcher" && !jobMatcherProfileComplete) {
      return router.redirect(paths.careerDeveloper.jobMatcher.setCriteriaForm)
    }

    if (premiumService && !isPremium) {
      return router.redirect(paths.getPremium.index)
    }

    return updateServices({ [service]: value ? "ON" : "OFF" })
  }

  return (
    <>
      {!mentor && !mentee && !job && (
        <AllTogglesWithHeaders
          services={services}
          handleToggle={handleToggle}
          isPremium={isPremium}
        />
      )}

      {mentor && (
        <MentorToggle
          services={services}
          handleToggle={handleToggle}
          isPremium={isPremium}
        />
      )}

      {mentee && (
        <MenteeToggle
          services={services}
          handleToggle={handleToggle}
          isPremium={isPremium}
        />
      )}

      {job && (
        <JobToggle
          services={services}
          handleToggle={handleToggle}
          isPremium={isPremium}
        />
      )}
    </>
  )
}

export default ServicesToggle
