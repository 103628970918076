import { Box, Card, Flex, Text } from "@engaging-tech/components"
import React from "react"
import styled, { css } from "styled-components"

import PrimaryButton from "../../../ui/components/PrimaryButton"
import Separator from "../../../ui/components/Separator"

const BorderedCard = styled(Card)`
  box-shadow: 0px 0px 6px ${({ theme }) => theme.colors.dark[5]};
  border-radius: 10px;

  ${({ disabled }) =>
    disabled &&
    css`
      filter: opacity(40%);
      background-color: ${({ theme }) => theme.colors.light[1]};
    `};

  ${({ type }) =>
    type === "premium" &&
    css`
      border: 1px solid ${({ theme }) => `${theme.colors.primary[0]}`};
    `};
`

const TextCenteredBox = styled(Box)`
  text-align: center;
`

const ComparisonBox = ({ freeAccountAction, premiumAccountAction, product }) => {
  const type = product.product.title.toLowerCase()
  const productPriceText = `Only ${product?.pricing?.currency === "GBP" ? "£" : ""}${
    product?.pricing?.amount
  } ${product?.pricing?.billingPeriod === "monthly" ? "a month" : ""} `
  const productTrialText =
    type === "premium" ? `${product?.trial?.amount} ${product?.trial?.period} trial` : ""

  const handleClick = e => {
    e.stopPropagation()

    if (type === "premium") {
      premiumAccountAction()
    } else {
      freeAccountAction()
    }
    // if (type === "premium") {
    //   handleStripeCheckout(product.provider.priceId)
    // } else {
    // freeAccountAction()
    // }
    // freeAccountAction()
  }

  return (
    <BorderedCard type={type} width={["100%", "49%", "49%"]} mb={[2]} id="bordered-card">
      <Flex flexDirection="column" height="100%" id="flex-1" width={1 / 1}>
        <Text
          color="yellow.0"
          fontWeight={700}
          fontSize={5}
          height="40px"
          textAlign="center"
          bg={type === "premium" ? "primary.0" : "rgba(234, 241, 253, 1)"}
          pt={2}
          borderRadius="8px 8px 0 0"
        >
          {type === "premium" && "Recommended"}
        </Text>

        <Box p={3} flexDirection="column" justifyContent="space-between" height="100%" id="this-is-my-box">
          <Flex alignItems="center" mb={3} justifyContent="space-between">
            <Text fontSize="28px" fontWeight={700} color="primary.0" width="auto">
              {product.product.title}
            </Text>
            <Text fontWeight={700} color="primary.0" fontSize={[3, "18px"]} width="auto">
              {productTrialText}
            </Text>
          </Flex>

          <Text color="primary.0" fontSize="18px">
            {product.product.description}
          </Text>

          <Separator width={1 / 1} lineColor="dark.5" />

          <Flex my={4} flexDirection="column">
            {product.features?.map(item => (
              <Text color="primary.0" key={item.type} fontSize="18px" my={2}>
                • {item.title}
              </Text>
            ))}
          </Flex>

          <Flex justifyContent="space-between" alignItems="flex-end">
            <TextCenteredBox width="168px" mt={3} textAlign="center">
              <PrimaryButton width="168px" onClick={e => handleClick(e)} mb={2} elevation={0}>
                {type === "premium" ? "Join Premium" : "Sign Up"}
              </PrimaryButton>
              <Text fontWeight={700} color="primary.0" fontSize={3}>
                {product.pricing.amount > 0 ? productPriceText : ""}
              </Text>
            </TextCenteredBox>

            {type === "premium" && (
              <Text color="primary.0" fontSize="15px" width="auto">
                You can cancel anytime
              </Text>
            )}
          </Flex>
        </Box>
      </Flex>
    </BorderedCard>
  )
}

export default ComparisonBox
