import { connect } from "react-redux"
import ConnectionTypeManager from "../components/ConnectionTypeManager"

import { mentorMatcherSelectors } from ".."
import {
  getMenteeMatcherStatus,
  getServices
} from "../../yourProfile/store/yourProfile.selectors"

const mapState = state => ({
  matches: mentorMatcherSelectors.getMentees(state),
  serviceStatus: getMenteeMatcherStatus(state),
  services: getServices(state)
})

const Mentee = connect(mapState)(ConnectionTypeManager)

export default Mentee
