/* eslint-disable react/jsx-fragments */
import { Button, Checkbox, CustomHooks, Flex, Modal, Text } from "@engaging-tech/components"
import { getCountries } from "@engaging-tech/frontend-utils"
import React, { useState } from "react"
import { createPortal } from "react-dom"

import DateInput from "../../features/formFlowWizard/components/formElements/DateInput"
import FieldAreaInput from "../../features/formFlowWizard/components/formElements/FieldAreaInput"
import FieldInput from "../../features/formFlowWizard/components/formElements/FieldInput"
import LocationInput from "../../features/formFlowWizard/components/formElements/locationInput"

const appRoot = () => typeof document !== "undefined" && document.getElementById("root")

const EndDate = ({ isCurrentJob, setIsCurrentJob, setFieldValue, setFieldTouched }) => {
  const handleChange = () => {
    if (!isCurrentJob) setFieldValue("positionToAdd.endDate", "")
    setIsCurrentJob(!isCurrentJob)
  }

  return (
    <Flex flexDirection="column" ml={2}>
      <Text mb={3}>End Date </Text>
      {!isCurrentJob && (
        <DateInput
          name="positionToAdd.endDate"
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        />
      )}
      <Checkbox
        id="current-job"
        label={() => <Text>I currently work here</Text>}
        checked={!!isCurrentJob}
        onChange={handleChange}
      />
    </Flex>
  )
}

export default function CvFormModal({ formik, handleTouchAllInputs, setIsOpen }) {
  const { values, errors, setFieldValue, setFieldTouched } = formik
  const { positionsHeld, positionToAdd } = values
  const [isCurrentJob, setIsCurrentJob] = useState("")

  CustomHooks.useFreshChatDeactivate()

  const handleAddress = loc => {
    const countryFound = getCountries().filter(
      country =>
        country.code ===
        loc.address_components.filter(component => component.types.includes("country"))[0].short_name
    )[0]

    const countryInfo = loc.address_components.filter(country =>
      country.types.includes("country", "political")
    )[0]

    formik.setValues({
      ...formik.values,
      positionToAdd: {
        ...positionToAdd,
        location: {
          name: loc.formatted_address,
          countryId: countryFound.id,
          point: {
            coordinates: [loc.geometry.location.lat(), loc.geometry.location.lng()]
          },
          country: {
            shortName: countryInfo.short_name,
            name: countryInfo.long_name
          },
          vicinity: loc.vicinity
        }
      }
    })
  }

  const handleSave = async () => {
    // if editing (if it has an id already)
    if (positionToAdd?.id) {
      const posToEdit = positionsHeld.map(pos => {
        if (pos.id === positionToAdd.id) return positionToAdd
        return pos
      })
      await setFieldValue("positionsHeld", posToEdit)
    }
    // if saving from new
    else {
      positionToAdd.id = new Date().getTime()
      if (positionsHeld) await setFieldValue("positionsHeld", [...positionsHeld, positionToAdd])
      if (!positionsHeld) await setFieldValue("positionsHeld", [positionToAdd])
    }
    setFieldValue("positionToAdd", null)
    setFieldTouched("positionToAdd", false)
    setIsOpen(false)
  }

  return createPortal(
    <Modal
      bg="light.0"
      flexDirection="column"
      animated
      disableScrollbar
      maxHeight="80vh"
      mb={100}
      onToggle={() => setIsOpen(false)}
      p={[2, 3, 3]}
      zIndex={950} // google maps api autocomplete has z-index of 1000
    >
      <FieldInput name="positionToAdd.jobTitle" inputLabel="Job Title" label="Job Title" />
      <FieldInput name="positionToAdd.company" inputLabel="Company Name" label="Company Name" />
      <Flex justifyContent="center" alignItems="stretch" flexDirection="column" width={1 / 1}>
        <Text mb={16}>Location</Text>
        <LocationInput
          types={["(cities)"]}
          name="positionToAdd.location"
          label="City"
          width={1 / 1}
          selectedLocationCallback={loc => handleAddress(loc)}
        />
      </Flex>
      <Flex flexDirection="row" width={1 / 1} mb={18}>
        <DateInput
          name="positionToAdd.startDate"
          label="Start Date"
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
        />
        <EndDate
          isCurrentJob={isCurrentJob}
          setIsCurrentJob={setIsCurrentJob}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        />
      </Flex>
      <FieldAreaInput
        name="positionToAdd.description"
        label="Describe your achievement and skills"
        inputLabel="Tell us a little something about what you accomplished"
        type="text"
        height={[96, 128]}
        helperText={
          positionToAdd?.description
            ? `${positionToAdd?.description.length}/600 characters`
            : "0/600 characters"
        }
      />

      <Flex justifyContent="space-between" my={4}>
        <Button
          elevation={0}
          px={3}
          variant="outline.primary.0"
          type="button"
          onClick={() => setIsOpen(false)}
        >
          <Text fontWeight={700}>CANCEL</Text>
        </Button>
        <Button
          elevation={0}
          px={5}
          type="button"
          bg={errors.positionToAdd || !positionToAdd ? "dark.5" : "secondary.0"}
          onClick={() => {
            if (errors.positionToAdd || !positionToAdd) handleTouchAllInputs({ formik })
            else handleSave()
          }}
        >
          <Text
            fontWeight={700}
            color={errors.positionToAdd || !positionToAdd ? "dark.2" : "primary.0"}
            fontSize={3}
          >
            SAVE
          </Text>
        </Button>
      </Flex>
    </Modal>,
    appRoot()
  )
}
