import { Button, Checkbox, Flex, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React, { useState } from "react"
import * as Yup from "yup"

import DateInput from "../../../formFlowWizard/components/formElements/DateInput"
import FieldInput from "../../../formFlowWizard/components/formElements/FieldInput"
import Separator from "../../../ui/components/Separator"
import MarkPriceQuote from "./MarkPriceQuote"
import QualificationsAdded from "./QualificationsAdded"
import Skills from "./Skills"

const icon = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/create.png`)

const info = {
  icon,
  title: "Create a CV",
  submitData: false
}

const validationSchema = Yup.object().shape({
  qualToAdd: Yup.object()
    .nullable()
    .shape({
      institution: Yup.string().required("This field cannot be left blank"),
      courseTitle: Yup.string().required("This field cannot be left blank"),
      end: Yup.date().nullable(),
      start: Yup.date()
        .required("This field must be a date")
        .when("end", (end, schema) => end && schema.max(end, "Start Date must be before End Date"))
    }),
  skillToAdd: Yup.string().nullable()
})

const FooterOverride = ({ cancelPath, handleBack, handleNext, formik, handleTouchAllInputs }) => {
  return (
    <Flex width={1 / 1} justifyContent="space-between" alignItems="center" alignContent="center" mt={4}>
      <Link to={cancelPath}>
        <Button elevation={0} bg="transparent">
          <Text fontWeight={700} color="primary.0" fontSize={3}>
            Cancel
          </Text>
        </Button>
      </Link>
      <Flex justifyContent="flex-end" alignItems="center" alignContent="center">
        <Button
          elevation={0}
          width={100}
          bg="transparent"
          onClick={() => handleBack({ values: formik.values })}
        >
          <Text fontWeight={700} color="primary.0" fontSize={3}>
            Back
          </Text>
        </Button>
        <Button
          elevation={0}
          width={100}
          bg={formik.errors.skills ? "dark.5" : "secondary.0"}
          onClick={
            formik.errors.skills
              ? () =>
                  handleTouchAllInputs({
                    formik,
                    containerElement: document.querySelector("#add-skills-container")
                  })
              : () => handleNext({ values: formik.values })
          }
        >
          <Text fontWeight={700} color={formik.errors.skills ? "dark.2" : "primary.0"} fontSize={3}>
            Next
          </Text>
        </Button>
      </Flex>
    </Flex>
  )
}

const EndDate = ({ isCurrentQual, setIsCurrentQual, setFieldValue, setFieldTouched }) => {
  const handleChange = () => {
    if (!isCurrentQual) setFieldValue("qualToAdd.end", "")
    setIsCurrentQual(!isCurrentQual)
  }
  return (
    <Flex flexDirection="column" ml={2}>
      <Text mb={3}>End Date </Text>
      {!isCurrentQual && (
        <DateInput name="qualToAdd.end" setFieldTouched={setFieldTouched} setFieldValue={setFieldValue} />
      )}
      <Checkbox
        id="qualifications-currently"
        onChange={handleChange}
        label={() => <Text>I currently study here</Text>}
        checked={isCurrentQual}
      />
    </Flex>
  )
}

const FormView = ({ setShowFormView, formik, handleTouchAllInputs }) => {
  const { values, errors, setFieldValue, setFieldTouched } = formik
  const { qualifications, qualToAdd } = values
  const [isCurrentQual, setIsCurrentQual] = useState("")

  const handleSave = async () => {
    // if editing (if it has an id already)
    if (qualToAdd?.id) {
      const qualToEdit = qualifications.map(qual => {
        if (qual.id === qualToAdd.id) return qualToAdd
        return qual
      })
      await setFieldValue("qualifications", qualToEdit)
    }
    // if saving from new
    else {
      qualToAdd.id = new Date().getTime()
      if (qualifications) await setFieldValue("qualifications", [...qualifications, qualToAdd])
      if (!qualifications) await setFieldValue("qualifications", [qualToAdd])
    }
    setFieldValue("qualToAdd", null)
    setFieldTouched("qualToAdd", false)
    setShowFormView(false)
  }

  return (
    <Flex flexDirection="column" id="qualification-add-form">
      <FieldInput name="qualToAdd.institution" inputLabel="Institution" label="Institution" />
      <FieldInput
        name="qualToAdd.courseTitle"
        inputLabel="Course Title"
        label="Name of course or certification"
      />
      <Flex flexDirection="row">
        <DateInput
          name="qualToAdd.start"
          label="Start Date"
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
        />
        <EndDate
          isCurrentQual={isCurrentQual}
          setIsCurrentQual={setIsCurrentQual}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        />
      </Flex>
      <Flex justifyContent="flex-end" my={4}>
        <Button
          elevation={0}
          px={5}
          type="button"
          bg={errors.qualToAdd || !qualToAdd ? "dark.5" : "secondary.0"}
          onClick={
            errors.qualToAdd || !qualToAdd
              ? () =>
                  handleTouchAllInputs({
                    formik,
                    containerElement: document.querySelector("#qualification-add-form")
                  })
              : handleSave
          }
        >
          <Text fontWeight={700} color={errors.qualToAdd || !qualToAdd ? "dark.2" : "primary.0"} fontSize={3}>
            SAVE
          </Text>
        </Button>
      </Flex>
      <Separator width={1 / 1} color="primary.0" lineHeight="2px" />
    </Flex>
  )
}

const AddQualButton = ({ setShowFormView }) => (
  <Button
    variant="outline.primary.0"
    my={3}
    leadingIcon="add"
    elevation={0}
    type="button"
    onClick={() => setShowFormView(true)}
  >
    <Text fontWeight={700}>Add Qualification</Text>
  </Button>
)

const Qualifications = ({ formik, handleTouchAllInputs }) => {
  const { values } = formik
  const { qualifications } = values
  const [showFormView, setShowFormView] = useState(true)

  return (
    <Flex width={1 / 1} flexDirection="column">
      <MarkPriceQuote quote="Again, a good opportunity to demonstrate you have built up the right skills and experiences to make you a strong candidate." />
      <Text my={3} fontWeight={700}>
        Qualifications
      </Text>
      {qualifications && <QualificationsAdded formik={formik} setShowFormView={setShowFormView} />}
      {showFormView ? (
        <FormView
          setShowFormView={setShowFormView}
          formik={formik}
          handleTouchAllInputs={handleTouchAllInputs}
        />
      ) : (
        <AddQualButton setShowFormView={setShowFormView} />
      )}
      <Flex id="add-skills-container" flexDirection="column">
        <Skills formik={formik} handleTouchAllInputs={handleTouchAllInputs} />
      </Flex>
    </Flex>
  )
}

export default {
  component: Qualifications,
  validationSchema,
  info,
  FooterOverride
}
