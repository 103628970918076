import { usePaths, useRouter } from "@engaging-tech/routing"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import surveyContainer from "../containers/surveyWrapper.container"
import { useSectionContext } from "../contexts/sectionContext"
import { getRequiresCode } from "../store/survey.selectors"
import SurveySection from "./surveySection"

const SurveyWrapper = ({ data }) => {
  const history = useHistory()
  const paths = usePaths()
  const router = useRouter()

  const { currentSection, sectionsList, onUpdateSectionsList } = useSectionContext()

  const isCodeRequired = useSelector(getRequiresCode)
  const isInAppView = history.location.pathname.includes("/in-app-view")

  const onMoveToPreviousStep = () => {
    if (isInAppView) {
      router.navigate(paths.inAppViewOrganisationSurvey.index.replace(":id", data.surveyId))
    } else {
      router.navigate(paths.survey.index.replace(":id", data.surveyId))
    }
  }

  useEffect(() => {
    let newSectionList = []
    newSectionList.push({
      __typename: "SurveyIntro",
      title: data.title,
      description: data.introduction
    })

    if (isCodeRequired) {
      newSectionList.push({
        __typename: "SurveyCodeCheck"
      })
    }

    newSectionList = [...newSectionList, ...data.template.sections]

    onUpdateSectionsList(newSectionList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.template.sections])

  return <>{sectionsList[currentSection] && <SurveySection onMoveToPreviousStep={onMoveToPreviousStep} />}</>
}

export default surveyContainer(SurveyWrapper)
