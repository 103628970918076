import { types } from "./yourTests.actions"

export const initialState = {
  isLoading: false,
  isUpdating: false,
  tests: [],
  updatingError: false
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE:
      return {
        ...state,
        isUpdating: true
      }
    case types.LOAD:
      return {
        ...state,
        isLoading: true
      }

    case types.UPDATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        tests: action.payload
      }
    case types.LOAD_SUCCESS:
      return {
        ...state,
        tests: action.payload,
        isLoading: false
      }

    case types.UPDATE_FAIL:
      return {
        ...state,
        isUpdating: false,
        updatingError: true
      }
    case types.LOAD_FAIL:
      return {
        ...state,
        isLoading: false
      }

    default:
      return state
  }
}
