import { Box, TextField } from "@engaging-tech/components"
import styled from "styled-components"

export const Wrapper = styled(Box)`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  @media (max-width: 580px) {
    width: 278px;
  }
`

export const Container = styled(Box)`
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 580px) {
    width: 278px;
  }
`

export const Input = styled(TextField)`
  background-color: #dbdada;
`
