import { createSelector } from "reselect"
import {
  mappedHappinessSixSteps,
  pipePassedInProp
} from "../../HappinessSixSteps/store/selectors"

export const getStep = createSelector(
  mappedHappinessSixSteps,
  pipePassedInProp,
  (sixSteps, currentStep) =>
    sixSteps && sixSteps.find(step => step.name === currentStep)
)

export const getQuestions = createSelector(
  getStep,
  step => step && step.questions
)

export const getQuestionsLength = createSelector(
  getQuestions,
  questions => questions && questions.length
)
