import React from "react"
import { Text, Flex, Box, Button } from "@engaging-tech/components"
import styled from "styled-components"
import PrimaryButton from "../../ui/components/PrimaryButton"
import TextButton from "../../ui/components/TextButton"

const title = "Welcome To Career Developer"

const DesktopTitle = styled(Text)`
  text-align: center;
  background-color: ${({ theme }) => `${theme.colors.tertiary[0]}`};
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  font-size: 20px;
  color: ${({ theme }) => `${theme.colors.primary[0]}`};
  @media (max-width: 768px) {
    display: none;
  }
`

const StyledBox = styled(Box)`
  border: 1px solid ${({ theme }) => `${theme.colors.dark[4]}`};
  border-radius: 7px;
  background-color: ${({ theme }) => `${theme.colors.dark[7]}`};
  width: auto;
`

const EmptyBox = styled(Box)`
  border: 1px solid ${({ theme }) => `${theme.colors.primary[0]}`};
  border-radius: 7px;
  height: 110px;
  width: 170px;
  background-color: ${({ theme }) => `${theme.colors.dark[6]}`};
`

const GoalBox = styled(Box)`
  border-radius: 7px;
  height: 110px;
  width: 170px;
  background-color: rgba(204, 251, 226, 1);
  padding-top: 3px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
`

const GoalText = styled(Text)`
  font-size: 15px;
  color: ${({ theme }) => `${theme.colors.primary[0]}`};
  @media (max-width: 414px) {
    font-size: 11.5px;
    line-height: -5px;
  }
`

const DesktopGoals = ({
  setChosenGoals,
  chosenGoals,
  goalsLeftToChoose,
  allGoals,
  myGoals,
  setGoalsLeftToChoose,
  seenBefore,
  toggle,
  updateSeenBefore,
  updateMyGoals,
  chosenGoalsIdsArray,
  setShowSecondOnboarding
}) => {
  return (
    <Flex width={1 / 1} flexDirection="column">
      <DesktopTitle fontWeight={700} fontFamily="serif">
        {title}
      </DesktopTitle>
      <Flex
        flexDirection="column"
        p={3}
        pb={[4, 0]}
        width={1 / 1}
        alignItems="center"
      >
        <Flex flexDirection="column" width={1 / 1} alignItems="center">
          <Text width={1 / 1} textAlign="center" fontSize={5} mb={2} mt={1}>
            Get started by selecting your career goals!
          </Text>
          <Text width={1 / 1} textAlign="center" fontSize={2} mb={2}>
            *Select a minimum of 3 to get started
          </Text>
          <StyledBox p={2} mb={4}>
            <Flex flexDirection="row">
              {chosenGoals[0] ? (
                <GoalBox
                  key={chosenGoals[0].id}
                  id="goalbox"
                  mr={3}
                  onClick={() => {
                    setGoalsLeftToChoose([...goalsLeftToChoose, chosenGoals[0]])
                    setChosenGoals(
                      chosenGoals.filter(el => el.id !== chosenGoals[0].id)
                    )
                  }}
                >
                  <GoalText>{chosenGoals[0].text}</GoalText>
                </GoalBox>
              ) : (
                <EmptyBox mr={3} />
              )}
              {chosenGoals[1] ? (
                <GoalBox
                  key={chosenGoals[1].id}
                  mr={3}
                  onClick={() => {
                    setGoalsLeftToChoose([...goalsLeftToChoose, chosenGoals[1]])
                    setChosenGoals(
                      chosenGoals.filter(el => el.id !== chosenGoals[1].id)
                    )
                  }}
                >
                  <GoalText>{chosenGoals[1].text}</GoalText>
                </GoalBox>
              ) : (
                <EmptyBox mr={3} />
              )}
              {chosenGoals[2] ? (
                <GoalBox
                  key={chosenGoals[2].id}
                  onClick={() => {
                    setGoalsLeftToChoose([...goalsLeftToChoose, chosenGoals[2]])
                    setChosenGoals(
                      chosenGoals.filter(el => el.id !== chosenGoals[2].id)
                    )
                  }}
                >
                  <GoalText>{chosenGoals[2].text}</GoalText>
                </GoalBox>
              ) : (
                <EmptyBox />
              )}
            </Flex>
            <Flex
              flexDirection="row"
              justifyContent="center"
              mt={3}
              width={1 / 1}
            >
              <>
                {chosenGoals[3] ? (
                  <GoalBox
                    mr={3}
                    key={chosenGoals[3].id}
                    onClick={() => {
                      setGoalsLeftToChoose([
                        ...goalsLeftToChoose,
                        chosenGoals[3]
                      ])
                      setChosenGoals(
                        chosenGoals.filter(el => el.id !== chosenGoals[3].id)
                      )
                    }}
                  >
                    <GoalText>{chosenGoals[3].text}</GoalText>
                  </GoalBox>
                ) : (
                  <EmptyBox mr={3} />
                )}
                {chosenGoals[4] ? (
                  <GoalBox
                    key={chosenGoals[4].id}
                    onClick={() => {
                      setGoalsLeftToChoose([
                        ...goalsLeftToChoose,
                        chosenGoals[4]
                      ])
                      setChosenGoals(
                        chosenGoals.filter(el => el.id !== chosenGoals[4].id)
                      )
                    }}
                  >
                    <GoalText>{chosenGoals[4].text}</GoalText>
                  </GoalBox>
                ) : (
                  <EmptyBox />
                )}
              </>
            </Flex>
          </StyledBox>
          <Flex flexDirection="row">
            {myGoals &&
              allGoals.data.length > 0 &&
              goalsLeftToChoose.slice(0, 4).map((goal, index) => (
                <GoalBox
                  key={goal.id}
                  mr={index === 3 ? 0 : 2}
                  mb={2}
                  onClick={
                    chosenGoals.length === 5
                      ? null
                      : () => {
                          setGoalsLeftToChoose(
                            goalsLeftToChoose.filter(el => el.id !== goal.id)
                          )
                          setChosenGoals([...chosenGoals, goal])
                        }
                  }
                >
                  <GoalText>{goal.text}</GoalText>
                </GoalBox>
              ))}
          </Flex>
          <Flex flexDirection="row">
            {myGoals &&
              allGoals.data.length > 0 &&
              goalsLeftToChoose.slice(4, 8).map((goal, index) => (
                <GoalBox
                  mr={index === 3 ? 0 : 2}
                  mb={2}
                  key={goal.id}
                  onClick={
                    chosenGoals.length === 5
                      ? null
                      : () => {
                          setGoalsLeftToChoose(
                            goalsLeftToChoose.filter(el => el.id !== goal.id)
                          )
                          setChosenGoals([...chosenGoals, goal])
                        }
                  }
                >
                  <GoalText>{goal.text}</GoalText>
                </GoalBox>
              ))}
          </Flex>
          <Flex my={3} width={1 / 1} justifyContent="space-between">
            <Button
              variant="text.dark.3"
              elevation={0}
              onClick={
                seenBefore ? () => toggle() : () => updateSeenBefore(true)
              }
            >
              {!seenBefore ? "SKIP" : "CANCEL"}
            </Button>
            <Flex justifyContent="flex-end">
              {chosenGoals.length > 0 && (
                <TextButton
                  mr={4}
                  onClick={() => {
                    setChosenGoals([])
                    setGoalsLeftToChoose([...allGoals.data])
                  }}
                >
                  RESET SELECTION
                </TextButton>
              )}
              <PrimaryButton
                width="120px"
                disabled={chosenGoals.length < 3}
                onClick={
                  seenBefore
                    ? () => {
                        updateMyGoals(chosenGoalsIdsArray)
                        toggle()
                      }
                    : () => setShowSecondOnboarding(true)
                }
              >
                CONFIRM
              </PrimaryButton>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default DesktopGoals
