import { createSelector } from "reselect"

import * as yourProfileSelectors from "../../yourProfile/store/yourProfile.selectors"

const getProfilePercentage = profileInfo => {
  const {
    age,
    gender,
    qualifications,
    positionsHeld,
    hobbies,
    industries,
    languages,
    personalStatement,
    skills
  } = profileInfo
  const agePercent = age ? 100 / 9 : 0
  const genderPercent = gender ? 100 / 9 : 0
  const qualificationsPercent = qualifications && qualifications.length > 0 ? 100 / 9 : 0
  const positionPercent = positionsHeld && positionsHeld.length > 0 ? 100 / 9 : 0
  const hobbiesPercent = hobbies && hobbies.length > 0 ? 100 / 9 : 0
  const industriesPercent = industries && industries.length > 0 ? 100 / 9 : 0
  const languagesPercent = languages && languages.length > 0 ? 100 / 9 : 0
  const statementPercent = personalStatement ? 100 / 9 : 0
  const skillsPercent = skills && skills.length > 0 ? 100 / 9 : 0

  return Math.round(
    agePercent +
      genderPercent +
      qualificationsPercent +
      positionPercent +
      hobbiesPercent +
      industriesPercent +
      languagesPercent +
      statementPercent +
      skillsPercent
  )
}

const developCareer = state => state.developCareer
export const getLayouts = createSelector(developCareer, state => state?.layouts)

export const getSurveys = createSelector(developCareer, state => {
  return {
    data: [...state?.surveys.data].sort(
      // @ts-ignore
      (a, b) => new Date(b.completedAt) - new Date(a.completedAt)
    ),
    loading: state?.surveys.loading,
    error: state?.surveys.error
  }
})

export const getIndividualSurveySubmissions = createSelector(getSurveys, surveys => {
  const surveyTypes = ["individualWorkFromHome", "happiness"]

  return surveys.data?.filter(survey => surveyTypes.includes(survey.surveyType))
})

export const getLatestIndividualSurveySubmission = createSelector(getIndividualSurveySubmissions, surveys => {
  const sortedIndividualSurvey = surveys.sort(
    (surveyA, surveyB) => new Date(surveyB.completedAt) - new Date(surveyA.completedAt)
  )

  return sortedIndividualSurvey[0]
})

export const getAllWidgets = createSelector(developCareer, state => state?.allWidgets)

export const getWidgetLayout = createSelector(developCareer, state => state?.widgetLayout)

export const getGridCustomisationFlag = createSelector(developCareer, state => state?.gridCustomisationFlag)

export const getLocalWidgetLayout = createSelector(developCareer, state => state?.localWidgetLayout)

export const getContentForYou = createSelector(developCareer, state => state?.contentForYou)

export const getAllGoals = createSelector(developCareer, state => state?.allGoals)

export const closeOptionBox = createSelector(developCareer, state => state?.optionBoxesClose)

export const getProfileCompletionPercentage = createSelector(
  state => state,
  state => {
    const profileData = {
      age: yourProfileSelectors.getAge(state),
      gender: yourProfileSelectors.getGender(state),
      qualifications: yourProfileSelectors.getQualifications(state),
      positionsHeld: yourProfileSelectors.getPositionsHeld(state),
      hobbies: yourProfileSelectors.getHobbies(state),
      industries: yourProfileSelectors.getIndustries(state),
      languages: yourProfileSelectors.getLanguages(state),
      personalStatement: yourProfileSelectors.getPersonalStatement(state),
      skills: yourProfileSelectors.getSkills(state)
    }

    return getProfilePercentage(profileData) || 0
  }
)
