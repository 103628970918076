import React, { useState } from "react"
import { Chip, Flex, Select, Button, Text } from "@engaging-tech/components"
import styled from "styled-components"
import ProfileSection from "./ProfileSection"

const StyledChip = styled(Chip)`
  &:hover {
    cursor: auto;
  }
`

const AddLink = styled(Text)`
  cursor: ${({ disabled }) => !disabled && "pointer"};
  color: ${({ theme }) => theme.colors.primary[0]};
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
`

const FormShowing = ({ toggle, hobbies, onUpdate, allHobbies }) => {
  const [hobby, setHobby] = useState("")
  const [hobbys, setHobbys] = useState(hobbies)
  const handleSelect = e =>
    setHobby({
      id: e.target.value,
      name: allHobbies.filter(hob => hob.id === e.target.value)[0].name
    })
  return (
    <Flex flexDirection="column" mt={3}>
      <Select
        label="Add Hobby"
        defaultValue=""
        value={hobby.id}
        onChange={handleSelect}
      >
        <option disabled value="" selected>
          {" "}
        </option>
        {allHobbies.map(hob => (
          <option key={hob.id} value={hob.id}>
            {hob.name}
          </option>
        ))}
      </Select>
      <AddLink
        disabled={hobby === ""}
        mt={3}
        onClick={() => {
          if (!hobbys.find(h => h.id === hobby.id) && !!hobby) {
            setHobbys([...hobbys, hobby])
            setHobby("")
          }
        }}
      >
        + ADD INTEREST
      </AddLink>
      {hobbys && (
        <Flex flexWrap="wrap">
          {hobbys.length > 0 &&
            hobbys.map(point => (
              <Chip
                key={point.id}
                mr={2}
                mt={2}
                trailingIcon="close"
                color="primary.0"
                onClick={() => {
                  setHobbys([...hobbys.filter(el => el.id !== point.id)])
                }}
              >
                {point.name}
              </Chip>
            ))}
        </Flex>
      )}
      <Button
        mt={3}
        elevation={0}
        variant="outline.primary.0"
        width="120px"
        onClick={() => {
          onUpdate({ hobbies: hobbys.map(hob => hob.id) })
          toggle()
        }}
      >
        SAVE
      </Button>
    </Flex>
  )
}

const DataShowing = ({ hobbies }) =>
  hobbies ? (
    <>
      <Text mt={2}>Your selected hobbies and interests:</Text>
      <Flex flexWrap="wrap">
        {hobbies.length > 0 &&
          hobbies.map(point => (
            <StyledChip key={point.id} mr={2} mt={2} cursor="none">
              {point.name}
            </StyledChip>
          ))}
      </Flex>
    </>
  ) : (
    <Text mt={2}>You have not selected any hobbies or interests yet</Text>
  )

const Hobbies = ({
  hobbies,
  onUpdate,
  allHobbies,
  toggle,
  hideCancelButton = false
}) => {
  const [dataShowing, setDataShowing] = useState(true)
  return (
    <ProfileSection
      title="Hobbies and interests"
      icon="favorite"
      onClick={() => setDataShowing(!dataShowing)}
      toggle={toggle}
      dataShowing={dataShowing}
      hideCancelButton={hideCancelButton}
    >
      {dataShowing ? (
        <DataShowing hobbies={hobbies} />
      ) : (
        <FormShowing
          toggle={toggle || (() => setDataShowing(true))}
          hobbies={hobbies}
          onUpdate={onUpdate}
          allHobbies={allHobbies}
        />
      )}
    </ProfileSection>
  )
}

export default Hobbies
