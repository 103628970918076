import React, { useEffect } from "react"

import JobsFeedView from "../../features/jobFinder/views/jobsFeed"

const JobsFeed = () => {
  useEffect(() => {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "FIND_A_JOBS_VIEW", {
        event_category: "page_view",
        event_label: "Find a Job Page"
      })
    }
  }, [])

  return <JobsFeedView />
}

export default JobsFeed
