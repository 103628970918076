import { connect } from "react-redux"
import { Redirect } from "@engaging-tech/routing"
import { UiSelectors } from "../../ui"

const mapState = state => ({
  to: UiSelectors.getPreviousRoute(state)
})

const PreviousRouteRedirect = connect(mapState, null)(Redirect)

export default PreviousRouteRedirect
