import { types } from "./misc.actions"

export const initialState = {
  loadingCountries: false,
  loadingIndustries: false,
  loadingJobRoles: false,
  loadingSurveyJobRoles: false,
  hasLoadedCountries: false,
  hasLoadedIndustries: false,
  hasLoadedJobRoles: false,
  hasLoadedSurveyJobRoles: false,
  hasErrorCountries: false,
  hasErrorIndustries: false,
  hasErrorJobRoles: false,
  hasErrorSurveyJobRoles: false,
  allCountries: [],
  allIndustries: [],
  surveyJobRoles: [],
  allJobRoles: [],
  allHobbies: [],
  allLanguages: [],
  allSkills: [],
  allRegions: [],
  loadingSkills: false,
  hasErrorSkills: false,
  hasLoadedSkills: false,
  loadingRegions: false,
  hasErrorRegions: false,
  hasLoadedRegions: false,
  loadingLanguages: false,
  hasErrorLanguages: false,
  hasLoadedLanguages: false,
  loadingHobbies: false,
  hasErrorHobbies: false,
  hasLoadedHobbies: false
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_ALL_COUNTRIES:
      return {
        ...state,
        loadingCountries: true,
        hasErrorCountries: false,
        hasLoadedCountries: false
      }
    case types.LOAD_ALL_INDUSTRIES:
      return {
        ...state,
        loadingIndustries: true,
        hasErrorIndustries: false,
        hasLoadedIndustries: false
      }
    case types.LOAD_ALL_JOB_ROLES:
      return {
        ...state,
        loadingJobRoles: true,
        hasErrorJobRoles: false,
        hasLoadedJobRoles: false
      }
    case types.LOAD_SURVEY_JOB_ROLES:
      return {
        ...state,
        loadingSurveyJobRoles: true,
        hasErrorSurveyJobRoles: false,
        hasLoadedSurveyJobRoles: false
      }
    case types.LOAD_SKILLS:
      return {
        ...state,
        loadingSkills: true,
        hasErrorSkills: false,
        hasLoadedSkills: false
      }
    case types.LOAD_LANGUAGES:
      return {
        ...state,
        loadingLanguages: true,
        hasErrorLanguages: false,
        hasLoadedLanguages: false
      }
    case types.LOAD_HOBBIES:
      return {
        ...state,
        loadingHobbies: true,
        hasErrorHobbies: false,
        hasLoadedHobbies: false
      }
    case types.LOAD_REGIONS:
      return {
        ...state,
        loadingRegions: true,
        hasErrorRegions: false,
        hasLoadedRegions: false
      }
    case types.LOAD_REGIONS_SUCCESS:
      return {
        ...state,
        loadingRegions: false,
        hasLoadedRegions: true,
        allRegions: action.payload
      }
    case types.LOAD_ALL_COUNTRIES_SUCCESS:
      return {
        ...state,
        loadingCountries: false,
        hasLoadedCountries: true,
        allCountries: action.payload
      }
    case types.LOAD_SKILLS_SUCCESS:
      return {
        ...state,
        loadingSkills: false,
        hasLoadedSkills: true,
        allSkills: action.payload
      }
    case types.LOAD_LANGUAGES_SUCCESS:
      return {
        ...state,
        loadingLanguages: false,
        hasLoadedLanguages: true,
        allLanguages: action.payload
      }
    case types.LOAD_HOBBIES_SUCCESS:
      return {
        ...state,
        loadingHobbies: false,
        hasLoadedHobbies: true,
        allHobbies: action.payload
      }
    case types.LOAD_ALL_INDUSTRIES_SUCCESS:
      return {
        ...state,
        loadingIndustries: false,
        hasLoadedIndustries: true,
        allIndustries: action.payload
      }
    case types.LOAD_ALL_JOB_ROLES_SUCCESS:
      return {
        ...state,
        loadingJobRoles: false,
        hasLoadedJobRoles: true,
        allJobRoles: action.payload
      }

    case types.LOAD_SURVEY_JOB_ROLES_SUCCESS:
      return {
        ...state,
        loadingSurveyJobRoles: false,
        hasLoadedSurveyJobRoles: true,
        surveyJobRoles: action.payload
      }

    case types.LOAD_FAIL_COUNTRIES:
      return {
        ...state,
        loadingCountries: false,
        hasErrorCountries: true,
        hasLoadedCountries: true
      }
    case types.LOAD_REGIONS_FAIL:
      return {
        ...state,
        loadingRegions: false,
        hasErrorRegions: true,
        hasLoadedRegions: true
      }
    case types.LOAD_SKILLS_FAIL:
      return {
        ...state,
        loadingSkills: false,
        hasErrorSkills: true,
        hasLoadedSkills: true
      }
    case types.LOAD_HOBBIES_FAIL:
      return {
        ...state,
        loadingHobbies: false,
        hasErrorHobbies: true,
        hasLoadedHobbies: true
      }
    case types.LOAD_LANGUAGES_FAIL:
      return {
        ...state,
        loadingLanguages: false,
        hasErrorLanguages: true,
        hasLoadedLanguages: true
      }
    case types.LOAD_FAIL_INDUSTRIES:
      return {
        ...state,
        loadingIndustries: false,
        hasErrorIndustries: true,
        hasLoadedIndustries: true
      }
    case types.LOAD_FAIL_JOB_ROLES:
      return {
        ...state,
        loadingJobRoles: false,
        hasErrorJobRoles: true,
        hasLoadedJobRoles: true
      }
    case types.LOAD_FAIL_SURVEY_JOB_ROLES:
      return {
        ...state,
        loadingSurveyJobRoles: false,
        hasErrorSurveyJobRoles: true,
        hasLoadedSurveyJobRoles: true
      }

    default:
      return state
  }
}
