import React, { useState, useEffect } from "react"
import {
  Box,
  Text,
  Select,
  Button,
  TextField,
  Flex
} from "@engaging-tech/components"

const MINIMUM_SERIES_CHAR_LENGTH = 3

const CreateSeries = ({
  name,
  series,
  hasSeries,
  hasCreated,
  isLoading,
  token,
  onLoad,
  onCreate,
  onChange
}) => {
  const [selectedSeries, setSelectedSeries] = useState("")
  const [newSeries, setNewSeries] = useState("")
  const [isCreating, setIsCreating] = useState(false)

  const handleSeriesChange = e => setSelectedSeries(e.target.value)

  const handleInputChange = e => setNewSeries(e.target.value)

  const handleSubmission = () => {
    const submission = {
      title: newSeries,
      summary: "",
      categories: [],
      coverImage: {
        source: "",
        caption: ""
      }
    }

    onCreate(submission)
  }

  useEffect(() => {
    onLoad(token.sub)
  }, [onLoad, token])

  useEffect(() => {
    if (hasCreated) {
      setIsCreating(false)
      setSelectedSeries(series.find(item => item.title === newSeries).id)
    }
  }, [hasCreated])

  useEffect(() => {
    // Formik expects an object with target, name and value. It won't
    // work otherwise.
    const customEventForFormik = {
      target: {
        name,
        value: selectedSeries
      }
    }

    onChange(customEventForFormik)
  }, [selectedSeries])

  return (
    <Box mb={3}>
      <Text fontSize={3} display="block" mt={0} mb={2} fontWeight="400" as="h4">
        Select series (optional)
      </Text>
      {hasSeries && !isCreating && (
        <Select
          id="select-series"
          label="Series title"
          defaultValue=""
          onChange={handleSeriesChange}
          value={selectedSeries}
        >
          <option value="" />
          {series.map(seriesItem => (
            <option key={seriesItem.id} value={seriesItem.id}>
              {seriesItem.title}
            </option>
          ))}
        </Select>
      )}
      {isCreating ? (
        <>
          <TextField
            autoFocus
            noLabel
            width={1 / 1}
            id="create-series"
            label="Series Title"
            value={newSeries}
            onChange={handleInputChange}
          />
          <Flex
            mt={2}
            flexDirection="row-reverse"
            width="auto"
            justifyContent="flex-end"
          >
            <Button
              ml={2}
              variant="text.primary.0"
              elevation={0}
              width="auto"
              type="button"
              onClick={handleSubmission}
              disabled={
                isLoading || newSeries.length <= MINIMUM_SERIES_CHAR_LENGTH
              }
            >
              Save
            </Button>
            <Button
              variant="text.dark.2"
              elevation={0}
              width="auto"
              onClick={() => setIsCreating(false)}
              disabled={isLoading}
            >
              Cancel
            </Button>
          </Flex>
        </>
      ) : (
        <Button
          mt={2}
          variant="text.dark.2"
          elevation={0}
          width="auto"
          leadingIcon="add-circle"
          onClick={() => setIsCreating(true)}
          px={0}
        >
          <Text fontSize={2} color="dark.2" mr={2}>
            Add new series title (optional)
          </Text>
        </Button>
      )}
    </Box>
  )
}

export default CreateSeries
