import React from "react"
import { Box, Text } from "@engaging-tech/components"

const Disclaimer = () => (
  <Box mb={3}>
    <Text fontSize={3} color="dark.2">
      Your post will be sent to all the experts in the industries you’ve
      selected. Your request will be shared on the Global Hub so other experts
      can comment and reach out to you.
    </Text>
  </Box>
)

Disclaimer.defaultProps = {
  postType: "content"
}

export default Disclaimer
