import React, { useState, useEffect, useContext } from "react"
import styled, { ThemeContext } from "styled-components"
import { Flex, MotionFlex } from "@engaging-tech/components"

const Container = styled(Flex)`
  border-bottom: 2px solid ${({ theme }) => theme.colors.quaternary[3]};
`
const MenuGroup = styled(Flex)`
  align-items: center;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: -2px;
`

const TabButton = styled(MotionFlex)`
  display: block;
  color: ${({ theme, selected }) =>
    selected ? theme.colors.quaternary[0] : theme.colors.quaternary[1]};
  height: 48px;
  line-height: 48px;
  padding: 0 16px;
  width: auto;
  font-size: 12px;
  font-weight: 700;
  flex-shrink: 0;
  background: transparent;
  box-shadow: none;
  border-radius: 0px;
  text-transform: none;
  outline: none;

  &:hover {
    cursor: pointer;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[0]}px) {
    font-size: 14px;
  }
`

const TabBar = ({ routes, onChange, ...props }) => {
  const [tabState, setTabState] = useState(routes[0].route)

  const themeContext = useContext(ThemeContext)

  useEffect(() => {
    onChange(routes[0].route)
  }, [])

  const handleChange = route => {
    setTabState(route.route)
    onChange(route.route)
  }
  const buttonVariants = {
    unSelected: {
      borderBottom: `2px solid ${themeContext.colors.quaternary[3]}`,
      transition: {
        duration: 0.5
      }
    },
    selected: {
      borderBottom: `2px solid ${themeContext.colors.primary[0]}`,
      transition: {
        duration: 0.5
      }
    }
  }

  return (
    <Container>
      <MenuGroup pl={[0, 0, 2]} pt={[0, 0, 2]} {...props}>
        {routes &&
          routes.map(route => {
            return (
              <TabButton
                key={route.route}
                selected={tabState === route.route}
                onClick={() => handleChange(route)}
                initial="unSelected"
                animate={tabState === route.route ? "selected" : "unSelected"}
                variants={buttonVariants}
                {...props}
              >
                {route.text}
              </TabButton>
            )
          })}
      </MenuGroup>
    </Container>
  )
}

export default TabBar
