import { Button, Flex, Icon, Spinner, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React from "react"

const FormInfomationCover = ({ hasError, redirectTo, isLoading, isSubmitting }) => {
  const hasErrorUI = (
    <Flex
      width={1 / 1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      alignContent="center"
      mt={5}
    >
      <Icon name="warning" color="dark.2" size={80} />
      <Text textAlign="center" fontSize={6} fontWeight={700}>
        Error
      </Text>
      <Text textAlign="center" mt={2} fontSize={5}>
        {hasError}
      </Text>
    </Flex>
  )

  const isLoadingUI = (
    <Flex
      width={1 / 1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      alignContent="center"
      mt={5}
    >
      <Spinner color="primary.0" />
    </Flex>
  )

  const isSubmittingUI = (
    <Flex
      width={1 / 1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      alignContent="center"
      mt={5}
    >
      <Text textAlign="center" fontSize={6} fontWeight={700}>
        Updating Infomation
      </Text>
      <Spinner color="primary.0" />
    </Flex>
  )

  const isNotSubmittingUI = (
    <Link to={redirectTo}>
      <Button elevation={0} width={100} bg="secondary.0" mt={6} mb={5}>
        <Text fontWeight={700} color="primary.0" fontSize={3}>
          Back
        </Text>
      </Button>
    </Link>
  )

  return (
    <Flex
      bg="light.0"
      width={1 / 1}
      borderRadius={5}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      alignContent="center"
    >
      {hasError && hasErrorUI}

      {isLoading && isLoadingUI}

      {isSubmitting && isSubmittingUI}

      {!isSubmitting && isNotSubmittingUI}
    </Flex>
  )
}

export default FormInfomationCover
