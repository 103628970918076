import { useParams, usePaths } from "@engaging-tech/routing"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import { cvLibraryActions } from "../../../features/cvLibrary"
import CreateCVView from "../../../features/cvLibrary/views/CreateCV"
import TokenAuth from "../../../features/misc/components/TokenAuth"
import AppViewBaseLayout from "../../../layouts/AppViewBaseLayout"

export default function CreateCV() {
  const { id } = useParams()
  const paths = usePaths()

  const redirectTo = paths.inAppViewCVLibrary

  const dispatch = useDispatch()

  useEffect(() => {
    if (id) dispatch(cvLibraryActions.loadSelectedCV({ id, place: "in-app-view" }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <AppViewBaseLayout mx={3} mt={4}>
      <TokenAuth />
      <CreateCVView redirectTo={redirectTo} />
    </AppViewBaseLayout>
  )
}
