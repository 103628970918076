import styled from "styled-components"

import { ReactComponent as ArrowDownBaseIcon } from "./arrow-down-icon.svg"
import { ReactComponent as ArrowRightMaterialIcon } from "./arrow-right-material-icon.svg"
import { ReactComponent as BriefcaseBaseIcon } from "./briefcase-icon.svg"
import { ReactComponent as BuildIcon } from "./build-icon.svg"
import { ReactComponent as ChevronLeftBaseIcon } from "./chevron-left-icon.svg"
import { ReactComponent as ChevronRightBaseIcon } from "./chevron-right-icon.svg"
import { ReactComponent as CompanyMaterialIcon } from "./company-material-icon.svg"
import { ReactComponent as CopyContentMaterialIcon } from "./copy-content-material-icon.svg"
import { ReactComponent as DownloadMaterialIcon } from "./download-material-icon.svg"
import { ReactComponent as FilterDropdownMaterialIcon } from "./filter-dropdown-material-icon.svg"
import { ReactComponent as FilterBaseIcon } from "./filter-icon.svg"
import { ReactComponent as EducationBaseIcon } from "./graduation-hat-icon.svg"
import { ReactComponent as HeartIcon } from "./heart-icon.svg"
import { ReactComponent as IndustryBaseIcon } from "./industry-icon.svg"
import { ReactComponent as LinkBaseIcon } from "./link.svg"
import { ReactComponent as FacebookLogoIcon } from "./logos/facebook-logo-icon.svg"
import { ReactComponent as InstagramLogoIcon } from "./logos/instagram-logo.icon.svg"
import { ReactComponent as LinkedInLogoIcon } from "./logos/linkedin-logo-icon.svg"
import { ReactComponent as TwitterLogoIcon } from "./logos/twitter-logo-icon.svg"
import { ReactComponent as WhatsAppLogoIcon } from "./logos/whatsapp-logo-icon.svg"
import { ReactComponent as PersonBaseIcon } from "./person-icon.svg"
import { ReactComponent as ReportMaterialIcon } from "./report-material-icon.svg"
import { ReactComponent as SearchBaseIcon } from "./search-icon.svg"
import { ReactComponent as ShareMaterialIcon } from "./share-material-icon.svg"
import { ReactComponent as SocialShareBaseIcon } from "./social-share-icon.svg"
import { ReactComponent as StarMaterialIcon } from "./star-material-icon.svg"
import { ReactComponent as SubjectBaseIcon } from "./subject-icon.svg"
import { ReactComponent as TranslateBaseIcon } from "./translate-icon.svg"
import { ReactComponent as WorkLAwardGoldBaseIcon } from "./workl-award-gold.svg"
import { ReactComponent as WorkLAwardGreenBaseIcon } from "./workl-award-green.svg"
import { ReactComponent as XBaseIcon } from "./x-icon.svg"

// icons
export const ChevronRightIcon = styled(ChevronRightBaseIcon)`
  width: 16px;
  height: 16px;
`

export const ChevronLeftIcon = styled(ChevronLeftBaseIcon)`
  width: 16px;
  height: 16px;
`

export const WorkLAwardGoldIcon = styled(WorkLAwardGoldBaseIcon)`
  width: 44px;
  height: 54px;
`

export const WorkLAwardGreenIcon = styled(WorkLAwardGreenBaseIcon)`
  width: 44px;
  height: 54px;
`

export const XIcon = styled(XBaseIcon)`
  width: 12px;
  height: 12px;
`

export const FilterIcon = styled(FilterBaseIcon)`
  width: 24px;
  height: 24px;
`

export const ArrowDownIcon = styled(ArrowDownBaseIcon)`
  width: 24px;
  height: 24px;
`

export const SearchIcon = styled(SearchBaseIcon)`
  width: 40px;
  height: 40px;
`

export const ArrowRightIcon = styled(ArrowRightMaterialIcon)`
  width: 16px;
  height: 16px;
`

export const CompanyIcon = styled(CompanyMaterialIcon)`
  width: 17px;
  height: 16px;
`

export const CopyContentIcon = styled(CopyContentMaterialIcon)`
  width: 20px;
  height: 20px;
`

export const ShareIcon = styled(ShareMaterialIcon)`
  width: 24px;
  height: 24px;
`

export const SocialShareIcon = styled(SocialShareBaseIcon)`
  width: 24px;
  height: 24px;
`

export const ReportIcon = styled(ReportMaterialIcon)`
  height: 14px;
  width: 14px;
`

export const DownloadIcon = styled(DownloadMaterialIcon)`
  height: 20px;
  width: 20px;
`

export const FilterDropdownIcon = styled(FilterDropdownMaterialIcon)`
  width: 13px;
  height: 9px;
`

export const StarIcon = styled(StarMaterialIcon)`
  height: 18px;
  width: 18px;
`

export const PersonIcon = styled(PersonBaseIcon)`
  height: 24px;
  width: 24px;

  & path {
    fill: ${({ color }) => color};
  }
`

export const IndustryIcon = styled(IndustryBaseIcon)`
  height: 24px;
  width: 24px;

  & path {
    fill: ${({ color }) => color};
  }
`

export const LinkIcon = styled(LinkBaseIcon)`
  height: 24px;
  width: 24px;
`

export const BriefcaseIcon = styled(BriefcaseBaseIcon)`
  height: 24px;
  width: 24px;

  & path {
    fill: ${({ color }) => color};
  }
`

export const EducationIcon = styled(EducationBaseIcon)`
  height: 24px;
  width: 24px;

  & path {
    fill: ${({ color }) => color};
  }
`

export const SubjectIcon = styled(SubjectBaseIcon)`
  height: 24px;
  width: 24px;

  & path {
    fill: ${({ color }) => color};
  }
`

export const SkillsIcon = styled(BuildIcon)`
  height: 24px;
  width: 24px;

  & path {
    fill: ${({ color }) => color};
  }
`

export const LikeIcon = styled(HeartIcon)`
  height: 24px;
  width: 24px;

  & path {
    fill: ${({ color }) => color};
  }
`

export const TranslateIcon = styled(TranslateBaseIcon)`
  height: 24px;
  width: 24px;

  & path {
    fill: ${({ color }) => color};
  }
`

// logos

export const FacebookIcon = styled(FacebookLogoIcon)`
  height: 20px;
  width: 20px;
`

export const InstagramIcon = styled(InstagramLogoIcon)`
  height: 20px;
  width: 20px;
`

export const LinkedInIcon = styled(LinkedInLogoIcon)`
  height: 20px;
  width: 20px;
`

export const TwitterIcon = styled(TwitterLogoIcon)`
  height: 20px;
  width: 20px;
`

export const WhatsAppIcon = styled(WhatsAppLogoIcon)`
  height: 20px;
  width: 20px;

  background-color: #25d366;
`
