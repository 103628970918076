import React from "react"
import styled from "styled-components"
import { Text, Flex } from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"

const Thumbnail = styled.img`
  max-height: 125px;
  max-width: 86px;
  width: auto;
`

const ItemBox = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[4]};
`

const OrderItem = ({ book }) => {
  const paths = usePaths()

  return (
    book && (
      <ItemBox
        width={1 / 1}
        p={3}
        bg="light.0"
        flexDirection={["column", "row"]}
      >
        <Flex>
          {book.image && (
            <Flex
              width={[1 / 1, "100px"]}
              mr={[0, 3]}
              mb={[3, 0]}
              justifyContent={["center", "flex-start"]}
            >
              <Thumbnail src={book.image} />
            </Flex>
          )}
          <Flex flexDirection="column" width={[1 / 1, 2 / 3]}>
            <Text mb={1} color="primary.0" fontSize={4} fontWeight="500">
              {book.title}
            </Text>
            {book.authors &&
              book.authors.map((author, aIndex) => (
                <Text
                  key={author.name + aIndex.toString()}
                  mb={1}
                  mr={2}
                  fontSize={2}
                  fontWeight="500"
                >
                  {author.name}
                </Text>
              ))}
            <Text mb={3} fontSize={3} color="dark.2">
              {book.format}
            </Text>
            {book.format !== "HardCover" && (
              <Text mb={3} fontSize={3} color="dark.2" width="auto">
                Access eBooks and audiobooks in{" "}
                <Link
                  color="primary.0"
                  fontWeight="500"
                  to={paths.resourceCentre.myLibrary.index}
                >
                  My Library
                </Link>
              </Text>
            )}
          </Flex>
        </Flex>
        <Flex
          width={1 / 1}
          height={[20, 100]}
          flexDirection={["row", "column", "column"]}
          alignItems="flex-end"
          justifyContent="space-around"
        >
          <Text fontSize={3} fontWeight="500" color="dark.2" width="auto">
            Quantity: {book.quantity}
          </Text>
          <Text fontSize={4} fontWeight="500" width="auto">
            Price: {`£${book.price.toFixed(2)}`}
          </Text>
        </Flex>
      </ItemBox>
    )
  )
}

export default OrderItem
