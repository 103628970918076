import { getConfig } from "@engaging-tech/ssr-config"

export const destroyFreshchatWidget = () => {
  if (typeof window !== "undefined") {
    window.fcWidget.destroy()
  }
}

export const showFreshchatWidget = (widgetUuid, tags = ["workl"], userId, firstName, lastName, email) => {
  destroyFreshchatWidget()

  const config = getConfig()
  if (typeof window !== "undefined") {
    // destroy the existing widget instance
    if (window.fcWidget.isLoaded()) {
      return
    }

    let widgetConfig = {
      token: config.fsKey,
      host: config.fsDomain
    }
    if (userId || firstName || lastName || widgetUuid) {
      widgetConfig = {
        ...widgetConfig,
        externalId: userId,
        firstName: firstName || "Guest",
        lastName,
        email,
        widgetUuid,
        tags
      }
    }
    window.fcWidget.init(widgetConfig)
  }
}
