import { getConfig } from "@engaging-tech/ssr-config"
import { useEffect } from "react"
import { useSelector } from "react-redux"

import { getProfile } from "../features/yourProfile/store/yourProfile.selectors"
import { showFreshchatWidget } from "../utils/freschat"

const useFreschatWidget = () => {
  const { fsNormalWidgetId, fsMentorWidgetId } = getConfig()

  const user = useSelector(getProfile)
  useEffect(() => {
    let widgetId = null
    let tags = ["workl"]

    if (user?.premium?.active) {
      widgetId = fsMentorWidgetId
      tags = ["workl", "workl-premium"]
    } else if (user) {
      widgetId = fsNormalWidgetId
      // show Loggedin non-premium user widget
    }

    if (!widgetId) {
      showFreshchatWidget()
    } else {
      showFreshchatWidget(widgetId, tags, user.id, user.firstName, user.lastName, user.email)
    }
  }, [user])

  return null
}

export default useFreschatWidget
