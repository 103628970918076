import React from "react"
import styled from "styled-components"
import { Flex, Card, H3, Text } from "@engaging-tech/components"
import EmptyBasket from "./EmptyBasket"

import Book from "./Book"

const CardTitle = styled(H3)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[4]};
`
const BasketBookList = ({
  hasProducts,
  basket,
  deleteProduct,
  updateQuantity
}) => {
  return (
    <Flex width={1 / 1} MaxWidth={700} ml="auto">
      {!hasProducts ? (
        <EmptyBasket />
      ) : (
        <Card
          bg="light.0"
          flexDirection="column"
          p={0}
          width={1 / 1}
        >
          <CardTitle p={3} width={1 / 1} fontSize={18} fontWeight={700}>
            My Basket
          </CardTitle>
          <Flex flexDirection="column" px={3} pt={3}>
            {basket.map(book => (
              <Book
                key={book.id}
                book={book}
                updateQuantity={updateQuantity}
                deleteProduct={deleteProduct}
              />
            ))}
          </Flex>
        </Card>
      )}
    </Flex>
  )
}

export default BasketBookList
