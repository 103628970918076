import { Box, Text, ProgressBar as Bar } from "@engaging-tech/components"
import React from "react"
import progressBarContainer from "../containers/progressBar.container"

const makeCurrentIndex = current =>
  String(current).length === 1 ? `0${current}` : current

const ProgressBar = ({ current, total }) => (
  <Box mb={3} width={1 / 1}>
    <Text fontWeight="500" color="primary.0" fontSize={6}>
      {makeCurrentIndex(current)}
    </Text>
    <Text fontWeight="500" color="dark.2" fontSize={2}>
      /{total.toString()}
    </Text>
    <Bar
      color="secondary.0"
      bg="secondary.4"
      progress={(current / total) * 100}
      height="4px"
      borderRadius={99}
    />
  </Box>
)

export default progressBarContainer(ProgressBar)
