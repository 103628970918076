import POSTS_TO_LOAD from "../lib/postsToLoad"
import { types } from "./globalHub.actions"

const prependComment = (posts, selectedPost, newComment) => {
  const addComment = post => ({
    ...post,
    comments: [
      {
        id: newComment.id,
        content: newComment.content,
        user: newComment.user,
        anonymous: newComment.anonymous,
        childs: newComment.childs
      },
      ...post.comments
    ]
  })

  return {
    posts: posts.map(post => (post.id === newComment.postId ? addComment(post, newComment) : post)),
    selectedPost: selectedPost && addComment(selectedPost, newComment)
  }
}

const insertReply = (comments, newReply) =>
  comments.map(comment =>
    comment.id === newReply.parentId
      ? {
          ...comment,
          childs: [
            {
              id: newReply.id,
              content: newReply.content,
              user: newReply.user,
              anonymous: newReply.anonymous
            },
            ...comment.childs
          ]
        }
      : comment
  )

const prependReply = (posts, selectedPost, newReply) => ({
  posts: posts.map(post =>
    post.id === newReply.postId
      ? {
          ...post,
          comments: insertReply(post.comments, newReply)
        }
      : post
  ),
  selectedPost: selectedPost && {
    ...selectedPost,
    comments: insertReply(selectedPost.comments, newReply)
  }
})

const attachEndorsement = (posts, selectedPost, endorsement) => {
  return {
    posts: posts.map(post =>
      post.id === endorsement.id
        ? {
            ...post,
            likes: [...post.likes, endorsement.user]
          }
        : post
    ),
    selectedPost: selectedPost && {
      ...selectedPost,
      likes: [...selectedPost.likes, endorsement.user]
    }
  }
}

const removeEndorsement = (posts, selectedPost, unendorsement) => {
  return {
    posts: posts.map(post =>
      post.id === unendorsement.id
        ? {
            ...post,
            likes: post.likes.filter(user => user.id !== unendorsement.user.id)
          }
        : post
    ),
    selectedPost: selectedPost && {
      ...selectedPost,
      likes: selectedPost.likes.filter(user => user.id !== unendorsement.user.id)
    }
  }
}

const generalMessage = "Something went wrong"

export const initialState = {
  isLoading: false,
  isLoadingMore: false,
  isPosting: false,
  isEndorsing: false,
  isCommenting: false,
  postCount: 0,
  hasLoadedAll: false,
  loadFrom: 0,
  selected: null,
  selectedPost: null,
  posts: [],
  usersAdvicePosts: [],
  loadingAdvicePosts: false,
  error: { generalMessage: null }
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD:
      return {
        ...state,
        selected: action.payload?.type,
        hasLoadedAll: false,
        isLoading: true,
        selectedPost: null
      }
    case types.LOAD_USERS_ADVICE_POSTS:
      return {
        ...state,
        loadingAdvicePosts: true
      }
    case types.LOAD_USERS_ADVICE_POSTS_SUCCESS:
      return {
        ...state,
        usersAdvicePosts: action.payload,
        loadingAdvicePosts: false
      }
    case types.LOAD_USERS_ADVICE_POSTS_FAIL:
      return {
        ...state,
        loadingAdvicePosts: false
      }

    case types.LOAD_MORE:
      return {
        ...state,
        isLoadingMore: true
      }

    case types.LOAD_SUCCESS:
      return {
        ...state,
        posts: action.payload,
        loadFrom: action.payload.length,
        isLoading: false
      }

    case types.LOAD_MORE_SUCCESS:
      return {
        ...state,
        posts: [...state.posts, ...action.payload],
        loadFrom: state.loadFrom + action.payload.length,
        isLoadingMore: false,
        hasLoadedAll: action.payload.length < POSTS_TO_LOAD
      }

    case types.LOAD_FAIL:
    case types.SELECT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: { ...action.payload, generalMessage }
      }

    case types.LOAD_MORE_FAIL:
      return {
        ...state,
        isLoadingMore: false,
        error: { ...action.payload, generalMessage }
      }

    case types.POST:
      return { ...state, isPosting: true }

    case types.POST_SUCCESS:
      return {
        ...state,
        selected: initialState.selected,
        postCount: state.postCount + 1,
        isPosting: false
      }

    case types.POST_FAIL:
      return { ...state, isPosting: false }

    case types.ENDORSE:
      return { ...state, isEndorsing: action.payload.id }

    case types.ENDORSE_SUCCESS:
      return {
        ...state,
        ...attachEndorsement(state.posts, state.selectedPost, action.payload),
        isEndorsing: false
      }

    case types.UNENDORSE_SUCCESS:
      return {
        ...state,
        ...removeEndorsement(state.posts, state.selectedPost, action.payload),
        isEndorsing: false
      }

    case types.ENDORSE_FAIL:
      return { ...state, isEndorsing: false, error: action.payload }

    case types.COMMENT:
    case types.REPLY:
      return { ...state, isCommenting: true }

    case types.COMMENT_SUCCESS:
      return {
        ...state,
        ...prependComment(state.posts, state.selectedPost, action.payload),
        isCommenting: false
      }

    case types.COMMENT_FAIL:
      return { ...state, isCommenting: false }

    case types.REPLY_SUCCESS:
      return {
        ...state,
        ...prependReply(state.posts, state.selectedPost, action.payload),
        isCommenting: false
      }

    case types.REPLY_FAIL:
      return { ...state, isCommenting: false }

    case types.SELECT:
      return { ...state, isLoading: true }

    case types.SELECT_SUCCESS:
      return { ...state, selectedPost: action.payload, isLoading: false }

    default:
      return state
  }
}
