import { getFileType } from "@engaging-tech/frontend-utils"
import { getConfig } from "@engaging-tech/ssr-config"
import axios from "axios"

import { getIdToken } from "../../account/services/account.service"
import uploadVideo from "./videoUpload.service"

const UPLOAD_API = () => `${getConfig().api.url}${getConfig().api.endpoints.upload}`
const MEDIA_UPLOAD_API = () => `${getConfig().api.url}${getConfig().api.endpoints.mediaUpload}`

const determineAttachmentType = attachment => {
  switch (getFileType(attachment).toLowerCase()) {
    case "pdf":
      return "document"

    case "jpg":
    case "jpeg":
    case "png":
      return "image"

    case "mp4":
      return "video"

    default:
      return "document"
  }
}

const upload = async (file, url = UPLOAD_API(), section = null, api = axios) => {
  const formData = new FormData()

  formData.append("file", file)
  const tokenString = await getIdToken()
  if (section) {
    url = `${url}?section=${section}`
  }

  return api({
    method: "post",
    url,
    data: formData,
    headers: { Authorization: `JWT ${tokenString}` }
  })
}

export const parseAttachments = attachments => ({
  audio: attachments.find(attachment => attachment.type.includes("audio")),
  image: attachments.find(attachment => attachment.type.includes("image")),
  document: attachments.find(attachment => attachment.type.includes("pdf")),
  video: attachments.find(attachment => attachment.type.includes("video"))
})

export const uploadArticle = async articleAttachments => {
  const { image } = parseAttachments(articleAttachments)

  if (!image) {
    return {
      coverImageUrl: ""
    }
  }

  const uploadedCoverImage = await upload(image, MEDIA_UPLOAD_API(), "material")

  return {
    coverImageUrl: uploadedCoverImage.data.result
  }
}

export const uploadPodcast = async podcastAttachments => {
  const { audio, image } = parseAttachments(podcastAttachments)
  const [uploadedAudio, uploadedCoverImage] = await Promise.all([
    upload(audio, MEDIA_UPLOAD_API(), "material"),
    upload(image, MEDIA_UPLOAD_API(), "material")
  ])

  return {
    podcastUrl: uploadedAudio.data.result,
    coverImageUrl: uploadedCoverImage.data.result
  }
}

export const uploadResearch = async researchAttachments => {
  const { document, image } = parseAttachments(researchAttachments)

  const [uploadedDocument, uploadedCoverImage] = await Promise.all([
    upload(document, MEDIA_UPLOAD_API(), "material"),
    upload(image, MEDIA_UPLOAD_API(), "material")
  ])

  return {
    documentUrl: uploadedDocument.data.result,
    coverImageUrl: uploadedCoverImage.data.result
  }
}

export const uploadLecture = async lectureAttachments => {
  const { video, image } = parseAttachments(lectureAttachments)

  const [uploadedVideo, uploadedCoverImage] = await Promise.all([
    upload(video, MEDIA_UPLOAD_API(), "material"),
    upload(image, MEDIA_UPLOAD_API(), "material")
  ])

  return {
    lectureUrl: uploadedVideo?.data?.result,
    coverImageUrl: uploadedCoverImage?.data?.result
  }
}

export const uploadHubAttachments = async attachments => {
  const uploaded = await Promise.all(
    attachments.map(file =>
      file.type.includes("video")
        ? upload(file, MEDIA_UPLOAD_API(), "hub")
        : upload(file, MEDIA_UPLOAD_API(), "hub")
    )
  )

  return uploaded.map((res, index) =>
    res.data
      ? {
          source: res.data ? res.data.result : res,
          type: determineAttachmentType(res.data.result),
          caption: attachments[index].name
        }
      : {
          source: res,
          type: "video",
          caption: attachments[index].name
        }
  )
}

export default upload
