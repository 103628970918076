import { createSelector } from "reselect"

const businessLibrary = state => state.businessLibrary
const libraryBooks = state => state.libraryBooks

export const getFilter = state => state.businessLibrary.filter
export const getSearchTerm = state => state.businessLibrary.searchTerm
export const getFeaturedState = state => state.businessLibrary.featured

const byPublishDate = (a, b) =>
  new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime()

export const getIsLoading = createSelector(
  businessLibrary,
  state => state.isLoading
)

export const getBookSearchTerm = createSelector(
  libraryBooks,
  state => state.searchTerm
)

export const getIsLoadingMore = createSelector(
  businessLibrary,
  state => state.isLoadingMore
)

export const getHasLoadedAll = createSelector(
  businessLibrary,
  state => state.hasLoadedAll
)

export const getLoadFrom = createSelector(
  businessLibrary,
  state => state.loadFrom
)

export const getContent = createSelector(
  businessLibrary,
  state => state.content
)

export const getSelected = createSelector(businessLibrary, state =>
  state.selected ? state.selected : null
)

export const getAll = createSelector(getContent, content =>
  content.sort(byPublishDate)
)

export const getHasFiltered = createSelector(
  getFilter,
  activeFilter => !!activeFilter
)

export const getHasSearched = createSelector(
  getSearchTerm,
  searchTerm => !!searchTerm
)

export const getHasResults = createSelector(
  getContent,
  content => content?.length > 0
)

export const getFeatured = createSelector(
  [getFeaturedState, (state, props) => props.type],
  (featuredContent, type) =>
    featuredContent.filter(featured => featured.type === type)
)

export const getPostType = createSelector(
  businessLibrary,
  state => state?.postType
)
