import { Box, TextArea, TextField } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

export const Field = ({ onSelect, ...props }) => (
  <Box mb={3}>
    <TextField onClick={onSelect} onFocus={onSelect} width={1 / 1} {...props} />
  </Box>
)

export const TextAreaCustom = styled(TextArea)`
  > label {
    margin-left: 10px;
    padding-bottom: 10px;
  }
`

export const Area = ({ onSelect, ...props }) => (
  <Box mb={[4, 3]}>
    <TextAreaCustom onClick={onSelect} onFocus={onSelect} width={1 / 1} {...props} />
  </Box>
)
