import { useEffect } from "react"
import { connect } from "react-redux"

import { load } from "../store/yourTests.actions"

const LoaderComponent = ({ onLoad }) => {
  useEffect(() => {
    onLoad()
  }, [onLoad])

  return null
}

const mapDispatch = dispatch => ({
  onLoad: () => dispatch(load())
})

const Loader = connect(null, mapDispatch)(LoaderComponent)

export default Loader
