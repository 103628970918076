import { Box, Flex, Icon, Text } from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"
import React from "react"
import styled from "styled-components"

const Border2 = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary[0]};
  margin-bottom: 20px;
`

const PremiumFeaturePromoCard = ({ iconName, title, SubTitle, premiumFeatures }) => {
  const paths = usePaths()

  return (
    <Flex
      width={1 / 1}
      bg="primary.0"
      ml={[0, 4]}
      borderRadius={6}
      flexDirection="column"
      alignItems="center"
      px={[1, 3]}
      py={[1, 3]}
    >
      <Icon name={iconName} color="yellow.0" size={70} />
      <Text
        fontSize={5}
        fontWeight={600}
        fontFamily="Tiempos"
        textAlign="center"
        color="yellow.0"
        mb={4}
        mt={2}
      >
        {title}
      </Text>
      {typeof SubTitle === "string" ? (
        <Text fontSize={3} color="light.0" textAlign="center" px={[2, 5]} mb={4} width={2 / 3}>
          {SubTitle}
        </Text>
      ) : (
        <SubTitle />
      )}
      <Border2 />
      <Text fontSize={5} fontWeight={600} fontFamily="Tiempos" textAlign="center" color="light.0" mb={3}>
        Get access to these exclusive features with Premium
      </Text>
      <Text fontSize={3} color="light.0" fontWeight={700} textAlign="center" px={[3, 5]} mb={2}>
        Enjoy a free 14 day trial then use our full range of{" "}
        <Link to={paths.getPremium.subscribeToPremium}>
          <Text fontSize={4} color="yellow.0" fontWeight={700}>
            Premium services
          </Text>
        </Link>{" "}
        for £4.99 a month
      </Text>
      <Text fontSize={2} color="light.0" textAlign="center" px={[2, 5]} mb={3}>
        This price includes VAT
      </Text>
      <Flex flexDirection="row" flexWrap="wrap" width="100%" mb={1}>
        {premiumFeatures.map(data => (
          <Flex flexDirection="column" width={["100%", "50%"]} p={[2, 1]} px={[3, 1]} mb={2} key={data.title}>
            <Flex width="100%">
              <Icon name="done_outline" color="light.0" size={20} />
              <Text fontSize={3} color="light.0" ml={2} fontWeight={500}>
                {data.title}
              </Text>
            </Flex>
            <Text fontSize={2} color="light.0" width="100%">
              {`${data.text}`}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}

export default PremiumFeaturePromoCard
