import { getConfig } from "@engaging-tech/ssr-config"
import axios from "axios"

import { apollo } from "../../../lib/Apollo"
import { getIdToken } from "../../account/services/account.service"
import PAYBOOK from "./libraryBooks.queries"

const BOOKS_API_URL = () => `${getConfig().api.url}${getConfig().api.endpoints.books}`

const GRAPHQL_API_URL = () => `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

const axiosGetWithHeader = async url => {
  const tokenString = await getIdToken()
  return axios({
    method: "get",
    url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${tokenString}`
    }
  })
}

export const createOrderIntent = orderId =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: PAYBOOK,
    variables: { orderId }
  })

export const getBooks = async ({ from, filter, searchTerm, limit = 20 }) => {
  const baseUrl = `${BOOKS_API_URL()}/product/searchByCategory`
  let newUrl = null

  if (filter || searchTerm) {
    newUrl = `${baseUrl}/text/?format=json&skip=${from || 0}`
  } else {
    newUrl = `${baseUrl}/or/0/?format=json&skip=${from || 0}`
  }

  if (searchTerm) {
    newUrl += `&searchTerm=${searchTerm}`
  }
  if (filter?.id) {
    newUrl += `&gardnerBicCode=${filter.id}`
  }

  if (limit) {
    newUrl += `&take=${limit}`
  }
  const orderRes = await axiosGetWithHeader(newUrl)
  return orderRes
}

// for single page view
export const getBookDetails = async id => {
  const url = `${BOOKS_API_URL()}/product/search.json?idIn=${id}`
  const orderRes = await axiosGetWithHeader(url)
  return orderRes
}

export const getEbookDetails = async ({ id, ean }) => {
  let url = `${BOOKS_API_URL()}/product/ebook/search.json?`
  if (id) url += `idIn=${id}&`
  if (ean) url += `eanIn=${ean}&`
  const orderRes = await axiosGetWithHeader(url)
  return orderRes
}

export const getBookDetailsByEan = async ean => {
  const url = `${BOOKS_API_URL()}/product/search.json?EanNumberIn=${ean}`
  const orderRes = await axiosGetWithHeader(url)
  return orderRes
}

export const getEbookDetailsByEan = async ean => {
  const url = `${BOOKS_API_URL()}/product/ebook/search.json?EANIn=${ean}`
  const orderRes = await axiosGetWithHeader(url)
  return orderRes
}

// Create order
export const createOrder = async (params, url = BOOKS_API_URL(), api = axios) => {
  const { isProd } = getConfig()
  const physicalBooks = params.books
    .filter(book => book.format === "hardCover")
    .map(book => ({
      Ean: book.ean,
      Quantity: book.quantity
    }))
  const Ebooks = params.books
    .filter(book => book.format !== "hardCover")
    .map(book => ({
      Ean: book.ean,
      EBookType: book.type
    }))
  const tokenString = await getIdToken()

  return api({
    method: "POST",
    url: `${url}/order`,
    data: {
      Ewp: !!params.isPremium,
      Order: {
        Testing: !isProd,
        PhysicalBooks: physicalBooks,
        EBooks: Ebooks
      }
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${tokenString}`
    }
  })
}

export const updateOrder = async (order, url = BOOKS_API_URL(), api = axios) => {
  const tokenString = await getIdToken()
  return api({
    method: "PUT",
    url: `${url}/order/${order.orderId}`,
    data: order.orderInfo,
    headers: { Authorization: `JWT ${tokenString}` }
  })
}

export const getOrderDetails = async ({ id, userId }) => {
  let url = `${BOOKS_API_URL()}/order/search.json?`
  url = userId ? `${url}&userId=${userId}` : url
  url = id ? `${url}&id=${id}` : url
  const orderRes = await axiosGetWithHeader(url)
  return orderRes
}

export const getOrders = async ({ userId }) => {
  let url = `${BOOKS_API_URL()}/order/search.json?OrderStatus!=PendingPayment&OrderStatus!=Error`
  url = userId ? `${url}&userId=${userId}` : url
  const orderRes = await axiosGetWithHeader(url)
  return orderRes
}
