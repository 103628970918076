/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, usePaths, useRouter } from "@engaging-tech/routing"
import { useCallback } from "react"
import { useSelector } from "react-redux"

import { createUrl } from "../../../lib/utils"
import { getFilters } from "../../jobFinder/store/jobFinder.selectors"
import { yourProfileSelectors } from "../../yourProfile"

export default function useJobMatcherRedirect() {
  const location = useLocation()
  const paths = usePaths()
  const router = useRouter()

  const jobMatcherProfile = useSelector(yourProfileSelectors.getJobMatcherProfile)
  const jobMatcherDesiredLocation = useSelector(yourProfileSelectors.getJobMatcherDesiredLocation)
  const filters = useSelector(getFilters)

  const optionSearchParams = new URLSearchParams(location.search)

  const onGoToJobFinder = useCallback(() => {
    const salaryList = filters.find(item => item.id === "salary")
    const salaryListItems = salaryList?.items.map(item => ({
      id: item,
      value: parseInt(item.replace("£", "").replace("+", ""), 10)
    }))
    let selectedSalary

    for (let i = 0; i < salaryListItems.length; i++) {
      if (
        i === salaryListItems.length - 1 ||
        jobMatcherProfile.desiredJobSalary.min <= salaryListItems[i + 1].value
      ) {
        selectedSalary = salaryListItems[i].id
        break
      }
    }

    if (jobMatcherProfile?.desiredJobType) {
      optionSearchParams.set("job_type", jobMatcherProfile.desiredJobType)
    }
    if (jobMatcherProfile?.desiredJobSalary?.max && jobMatcherProfile?.desiredJobSalary?.min) {
      optionSearchParams.set("salary", selectedSalary)
    }
    if (jobMatcherDesiredLocation?.name && jobMatcherDesiredLocation?.country) {
      optionSearchParams.set("location", jobMatcherDesiredLocation.name.split(",")[0])
      optionSearchParams.set("city", jobMatcherDesiredLocation.vicinity)
      optionSearchParams.set("country_code", jobMatcherDesiredLocation?.country?.shortName)
    }
    if (jobMatcherProfile?.desiredJobRoles && jobMatcherProfile?.desiredJobRoles?.length) {
      optionSearchParams.set("search_term", jobMatcherProfile.desiredJobRoles[0])
    }

    const optionUrl = createUrl(paths.findAJob.jobFinder.index, optionSearchParams)

    router.navigate(optionUrl)
  }, [
    jobMatcherDesiredLocation?.country,
    jobMatcherDesiredLocation?.name,
    jobMatcherDesiredLocation?.vicinity
  ])

  return { onGoToJobFinder }
}
