import { Box, Flex, Themes } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"
import { useSelector } from "react-redux"
import styled, { ThemeProvider } from "styled-components"
// eslint-disable-next-line import/no-extraneous-dependencies
import * as system from "styled-system"

import PoweredBy from "../../public/img/powered-by.png"
import TestingBar from "../features/survey/components/testingBar"
import * as SurveySelectors from "../features/survey/store/survey.selectors"
import { getIsIsolated, getIsIsolatedLoading } from "../features/survey/store/survey.selectors"
import { getIsIsolatedResultsLoading } from "../features/surveyResults/store/surveyResults.selectors"
import SiteBanner from "../features/ui/components/SiteBanner"
import TestingSiteBanner from "../features/ui/components/TestingSiteBanner"
import MinifiedNav from "../features/ui/containers/MinifiedNav"

const BrandImage = styled.img.attrs(props => ({
  height: [56, 136],
  padding: props.padded ? ["8px", "20px"] : 0
}))`
  object-fit: contain;
  ${system.padding}
  ${system.height}
`

const OrganisationSurveyDefaultLayout = ({ children }) => {
  const { websiteInfoBanner } = getConfig()

  return (
    <Flex minHeight="100vh" flexDirection="column">
      <TestingSiteBanner />

      {websiteInfoBanner && <SiteBanner text={websiteInfoBanner} disableClose icon="warning" />}

      <MinifiedNav />
      <TestingBar />
      <Flex as="main" flex="1" alignItems="stretch">
        {children}
      </Flex>
    </Flex>
  )
}

const OrganisationSurveyIsolatedLayout = ({ children }) => {
  const brand = useSelector(SurveySelectors.getBranding)

  return (
    <ThemeProvider theme={brand.theme}>
      <TestingBar />
      <Box
        backgroundColor={brand?.colour ? "primary.4" : "primary.1"}
        style={
          !brand?.colour
            ? {
                background: "linear-gradient(to bottom right, #6b8992, #023e4d)"
              }
            : undefined
        }
      >
        <Flex minHeight="100vh" flexDirection="column">
          <Flex
            justifyContent="center"
            alignItems="center"
            height={[56, 136]}
            backgroundColor={brand?.colour || "white"}
          >
            {Boolean(brand.logo.url) && (
              <BrandImage src={brand.logo.url} alt="Logo Placeholder" padded={brand.logo.isFallback} />
            )}
          </Flex>
          <Flex as="main" flex="1" alignItems="stretch">
            <ThemeProvider theme={Themes.workL}>{children}</ThemeProvider>
          </Flex>
          <Flex
            backgroundColor="light.0"
            height={60}
            justifyContent="flex-end"
            alignItems="center"
            paddingRight={28}
          >
            <img width={148} height={24} src={PoweredBy} alt="Logo Placeholder" />
          </Flex>
        </Flex>
      </Box>
    </ThemeProvider>
  )
}

const OrganisationSurveyLayout = ({ children }) => {
  const isIsolatedLoading = useSelector(getIsIsolatedLoading)
  const isIsolated = useSelector(getIsIsolated)

  const shouldShowIsolatedContent = isIsolatedLoading || isIsolated

  if (shouldShowIsolatedContent) {
    return <OrganisationSurveyIsolatedLayout>{children}</OrganisationSurveyIsolatedLayout>
  }

  return <OrganisationSurveyDefaultLayout>{children}</OrganisationSurveyDefaultLayout>
}

export default OrganisationSurveyLayout
