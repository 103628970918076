import { Button, Flex } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"
import businessFeatures from "../../lib/businessFeatures"
import ContentBlock from "../ContentBlock"
import CategoryColumn from "./CategoryColumn"

const EngagingBusiness = () => {


  return (
    <ContentBlock
      title="WorkL For Business"
      subtitle="Helping you keep and recruit the best people for your business"
      noGutter
    >
      <Flex
        flexDirection={["column", "row"]}
        flexWrap="wrap"
        justifyContent="center"
      >
        {businessFeatures.map(feature => (
          <CategoryColumn
            key={feature.title}
            icon={feature.icon}
            title={feature.title}
            points={feature.points}
          />
        ))}
      </Flex>
      <Flex px={3} justifyContent="center" mt={[3, 5, 5]}>
        <Link to={getConfig().worklForBusiness.public.index}>
          <Button variant="primary.0" width={[1 / 1, "auto"]}>
            Learn more
          </Button>
        </Link>
      </Flex>
    </ContentBlock>
  )
}

export default EngagingBusiness
