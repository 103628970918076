import { getCountries } from "@engaging-tech/frontend-utils"
import React, { useEffect, useState } from "react"
import * as Yup from "yup"

import LocationInput from "../../../formFlowWizard/components/formElements/locationInput"

const icon = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/location.png`)

const info = {
  icon,
  title: "Where would you like to be based?",
  submitData: true
}

const validationSchema = Yup.object({
  desiredJobLocation: Yup.object()
    .shape({
      name: Yup.string()
    })
    .nullable()
})

const DesiredLocationTemplate = ({ formik }) => {
  const [countries, setCountries] = useState([])

  const formatLocation = location => {
    const desiredJobLocation = {
      country: { shortName: "", name: "" },
      point: { coordinates: [] }
    }
    desiredJobLocation.countryId = countries.filter(
      country =>
        country.code ===
        location.address_components.filter(component => component.types.includes("country"))[0].short_name
    )[0].id
    desiredJobLocation.name = location.formatted_address
    desiredJobLocation.country.shortName = location.address_components.filter(country =>
      country.types.includes("country", "political")
    )[0].short_name
    desiredJobLocation.country.name = location.address_components.filter(country =>
      country.types.includes("country", "political")
    )[0].long_name
    desiredJobLocation.point.coordinates = [
      location.geometry.location.lat(),
      location.geometry.location.lng()
    ]
    desiredJobLocation.vicinity = location.vicinity

    return desiredJobLocation
  }

  useEffect(() => {
    setCountries(getCountries())
  }, [])

  const locationMalipulator = location => {
    if (location) {
      formik.setValues({ ...formik.values, desiredJobLocation: formatLocation(location) }, true)
    } else {
      formik.setValues(
        {
          ...formik.values,
          desiredJobLocation: {
            name: "",
            countryId: "",
            country: {
              shortName: null,
              name: null
            },
            point: {
              coordinates: [null]
            }
          }
        },
        true
      )
    }
  }

  return <LocationInput name="desiredJobLocation.name" selectedLocationCallback={locationMalipulator} />
}

export default { component: DesiredLocationTemplate, validationSchema, info }
