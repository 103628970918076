import { takeLatest, put, call } from "redux-saga/effects"

import {
  loadAll,
  loadById,
  loadFeatured,
  loadMore
} from "../services/businessLibrary.service"

import * as Actions from "./businessLibrary.actions"

export function* loadAllSaga(action) {
  try {
    const res = yield call(
      loadAll,
      action.payload.postType,
      action.payload.searchTerm,
      action.payload?.category?.id || null
    )
    yield put(Actions.loadAllSuccess(res.data.AllMaterials))
  } catch {
    yield put(Actions.loadAllFail())
  }
}

export function* loadMoreSaga(action) {
  try {
    const res = yield call(
      loadMore,
      action.payload.postType,
      action.payload.from,
      action.payload.searchTerm,
      action.payload.categoryId
    )
    yield put(Actions.loadMoreSuccess(res.data.AllMaterials))
  } catch {
    yield put(Actions.loadMoreFail())
  }
}

export function* loadSelectedSaga(action) {
  try {
    const res = yield call(loadById, action.payload)
    yield put(Actions.loadSelectedSuccess(res.data.AllMaterials[0]))
  } catch {
    yield put(Actions.loadSelectedFail())
  }
}

export function* loadFeaturedSaga() {
  try {
    const res = yield call(loadFeatured)
    yield put(Actions.loadFeaturedSuccess(res.data.AllMaterials))
  } catch {
    yield put(Actions.loadFeaturedFail())
  }
}

export function* saga() {
  yield takeLatest(Actions.types.LOAD_ALL, loadAllSaga)
  yield takeLatest(Actions.types.LOAD_SELECTED, loadSelectedSaga)
  yield takeLatest(Actions.types.LOAD_FEATURED, loadFeaturedSaga)
  yield takeLatest(Actions.types.LOAD_MORE, loadMoreSaga)
}
