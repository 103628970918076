import React from "react"
import styled from "styled-components"
import { Flex } from "@engaging-tech/components"

const Wrapper = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.dark[5]};
`

const CommentWrapper = ({ children }) =>
  children ? (
    <Wrapper px={3} pt={3} alignItems="center">
      {children}
    </Wrapper>
  ) : null

export default CommentWrapper
