import { gql } from "@apollo/client"

export const JOBS_FEED = gql`
  query getAllFeedByFilters(
    $search_term: [String]
    $experience: [String]
    $location: [String]
    $position: [String]
    $salary: Int
    $job_type: [String]
    $industry: [String]
    $company_name: String
    $company_name_key: String
    $date_posted: Int
    $nextToken: String
    $countryCode: String
    $demographicFilters: [String]
  ) {
    getAllFeedByFilters(
      search_term: $search_term
      experience: $experience
      location: $location
      position: $position
      salary: $salary
      job_type: $job_type
      industry: $industry
      company_name: $company_name
      company_name_key: $company_name_key
      date_posted: $date_posted
      nextToken: $nextToken
      countryCode: $countryCode
      demographicFilters: $demographicFilters
    ) {
      items {
        company_name_key
        age
        business_information
        company
        country_code
        created_at
        happiest_company_score
        happiest_industry_ranking
        happy_work_test
        job_id
        job_type
        location
        logo
        max_salary
        min_salary
        postcode
        salary
        snippet
        title
        url
      }
      nextToken
    }
  }
`

export const JOBS_FEED_FILTERS = gql`
  query getJobStaticFilters {
    getJobStaticFilters {
      id
      input
      items
      label
      type
    }
  }
`

export const JOB_BY_ID = gql`
  query getJobById($job_id: String) {
    getJobById(job_id: $job_id) {
      age
      business_information
      company
      company_name_key
      country_code
      created_at
      happiest_company_score
      happiest_industry_ranking
      happy_work_test
      job_id
      job_type
      location
      logo
      max_salary
      min_salary
      postcode
      salary
      snippet
      title
      url
    }
  }
`

export const JOB_COMPANY_RANKING = gql`
  query getCompanyRanking($companyNameKey: String) {
    getCompanyRanking(companyNameKey: $companyNameKey) {
      company
      companyNameKey
      countryId
      industryId
      happinessScores {
        global
        industry
        score
      }
    }
  }
`
