import { List, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import WidgetCard from "../../components/WidgetCard"
import DynamicState from "../../containers/DynamicState"

const EllipsisListItem = styled(List.Item)`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 20px !important;
`

const MostRecentAdvice = ({ myLatestAdvicePost, id }) => {
  return (
    <DynamicState
      id={id}
      removable
      outlineBtn
      title="Get Expert Advice"
      btnText="SEE POST"
      linkTo={`/global-hub/${myLatestAdvicePost.id}?returnTo=/develop-career`}
      btnText2="post again"
      linkTo2="/global-hub/publish/?type=advice&returnTo=/develop-career"
    >
      <Text fontSize={3} fontWeight={600} my={3}>
        Your recent Ask An Expert posts
      </Text>
      <Text fontSize={4} mb={3}>
        {myLatestAdvicePost.adviceRef.expertType.name}
      </Text>
      <Text fontSize={3} mb={6}>
        {myLatestAdvicePost.adviceRef.question}
      </Text>
    </DynamicState>
  )
}

const questionArray = [
  "How did you get into your role/industry?",
  "What is your favourite/least favourite thing about your job?",
  "What advice would you give to someone starting out in your role?",
  "What books/resources would you suggest to someone starting out?"
]
const NoAdviceQueriesMade = ({ id }) => {
  return (
    <DynamicState
      id={id}
      removable
      title="Get Expert Advice"
      subTitle="Get great advice from a specific expert in your industry. You might want to start by asking:"
      btnText="Create a post"
      linkTo="/global-hub/publish?type=advice&returnTo=/develop-career"
    >
      <List.Wrapper my={0}>
        {questionArray.map(question => (
          <EllipsisListItem key={question}>{question}</EllipsisListItem>
        ))}
      </List.Wrapper>
    </DynamicState>
  )
}

const AdviceWidget = ({ myLatestAdvicePost, id }) => {
  return (
    <WidgetCard width="100%" bg="light.0" height="100%">
      {myLatestAdvicePost ? (
        <MostRecentAdvice myLatestAdvicePost={myLatestAdvicePost} id={id} />
      ) : (
        <NoAdviceQueriesMade id={id} />
      )}
    </WidgetCard>
  )
}

export default AdviceWidget
