import { connect } from "react-redux"

import { happinessSurveySelectors } from "../../happinessSurvey"
import ResultsPageHeaderComponent from "../components/ResultsPageHeaders"
import { loadSubmission } from "../store/surveyResults.actions"
import * as Selectors from "../store/surveyResults.selectors"

const mapState = state => {
  return {
    type: Selectors.getSurveyType(state),
    surveyTitle: Selectors.getSurveyTitle(state),
    savantaId: happinessSurveySelectors.getSavantaId(state),
    languages: Selectors.getSurveyLanguages(state)
  }
}

const mapDispatch = dispatch => {
  return {
    load: surveyId => dispatch(loadSubmission(surveyId))
  }
}

const ResultsPageHeader = connect(mapState, mapDispatch)(ResultsPageHeaderComponent)

export default ResultsPageHeader
