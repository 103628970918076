import React from "react"

import SelectedPostContainer from "../../features/globalHub/containers/SelectedPost"
import DetailsLayout from "../../layouts/DetailsLayout"

const SelectedPost = () => (
  <DetailsLayout px={1} maxWidth={560}>
    <SelectedPostContainer />
  </DetailsLayout>
)

export default SelectedPost
