import React from "react"
import { useTranslation } from "react-i18next"
import { Box, Paragraph, List } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import { translationMap } from "../../lib/utils"

const determineView = (
  t,
  stageIndex,
  modalInfoIndex,
  suggestedActionIndex,
  index
) => {
  const placeholder = t(
    `action_plan.stage.${stageIndex}.modalInfo.${modalInfoIndex}.suggestedActions.${suggestedActionIndex}.actions.${index}.placeholder`,
    { returnObjects: true }
  )
  const text = t(
    `action_plan.stage.${stageIndex}.modalInfo.${modalInfoIndex}.suggestedActions.${suggestedActionIndex}.actions.${index}.text`,
    ""
  )

  const { key } = placeholder

  if (placeholder && text.includes(key)) {
    return text.split(key).map((textFragment, ind) =>
      ind === 1 ? (
        <Link
          to={t(
            `action_plan.stage.${stageIndex}.modalInfo.${modalInfoIndex}.suggestedActions.${suggestedActionIndex}.actions.${index}.placeholder.url`,
            ""
          )}
          external
          newTab
        >
          {textFragment}
        </Link>
      ) : (
        textFragment
      )
    )
  }
  return text
}

const ActionPlansPoint = ({
  stageIndex,
  modalInfoIndex,
  suggestedActionIndex
}) => {
  const { t } = useTranslation(["results"])

  return (
    <Paragraph
      color="dark.2"
      fontSize={3}
      fontWeight={500}
      textAlign="left"
      bg="dark.7"
      borderRadius={4}
      my={3}
      p={3}
    >
      {t(
        `action_plan.stage.${stageIndex}.modalInfo.${modalInfoIndex}.suggestedActions.${suggestedActionIndex}.heading`,
        ""
      )}
      {translationMap(
        t,
        `action_plan.stage.${stageIndex}.modalInfo.${modalInfoIndex}.suggestedActions.${suggestedActionIndex}.actions`
      ).map((_, index) => (
        <List.Wrapper key={`action_plan.stage${modalInfoIndex}.${index}`}>
          <List.Item>
            <Box color="dark.2" fontSize={3} fontWeight={400}>
              {determineView(
                t,
                stageIndex,
                modalInfoIndex,
                suggestedActionIndex,
                index
              )}
            </Box>
          </List.Item>
          {t(
            `action_plan.stage.${stageIndex}.modalInfo.${modalInfoIndex}.suggestedActions.${suggestedActionIndex}.actions.${index}.children`,
            { returnObjects: true }
          ) && (
            <List.Wrapper px={3}>
              {translationMap(
                t,
                `action_plan.stage.${stageIndex}.modalInfo.${modalInfoIndex}.suggestedActions.${suggestedActionIndex}.actions.${index}.children`
              ).map(child => (
                <List.Item
                  fontSize={3}
                  color="dark.2"
                  fontWeight={400}
                  key={`action_plan.stage${modalInfoIndex}.children`}
                >
                  {child}
                </List.Item>
              ))}
            </List.Wrapper>
          )}
        </List.Wrapper>
      ))}
    </Paragraph>
  )
}

export default ActionPlansPoint
