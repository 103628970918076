import styled from "styled-components"
import { IconButton } from "@engaging-tech/components"

const SearchButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.secondary[0]};
  margin-left: ${({ theme }) => theme.space[2]}px;
  flex-shrink: 0;
  &:disabled {
    cursor: not-allowed;
  }
  :hover {
    background-color: ${({ theme }) => theme.colors.secondary[0]};
  }

  &:focus {
    outline: 0;
  }
`

export default SearchButton
