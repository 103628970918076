/* eslint-disable import/prefer-default-export */
import { gql } from "../../../lib/Apollo"

export const LOAD = gql`
  query GetAllProducts {
    getWorkLProducts {
      id
      product {
        title
        description
        image
      }
      pricing {
        amount
        currency
        type
        billingPeriod
      }
      platform
      category
      provider {
        title
        productId
        priceId
      }
      features {
        id
        title
        type
        paragraphs
      }
      trial {
        period
        amount
      }
    }
  }
`

export const HANDLE_STRIPE_CHECKOUT = gql`
  mutation($price: String!) {
    createCheckoutSession(lineItem: { price: $price, quantity: 1 })
  }
`
