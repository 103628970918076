import React from "react"
import styled from "styled-components"
import { Link } from "@engaging-tech/routing"
import { Dialog, Text, Flex, Box } from "@engaging-tech/components"

const DialogTextBox = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.colors.dark[4]};
`

const PurchasedDialog = ({ handlePurchasedPopup }) => {
  return (
    <Dialog
      onToggle={handlePurchasedPopup}
      header="Research Purchased"
      footer={[
        {
          label: "DONE",
          action: () => [handlePurchasedPopup()]
        }
      ]}
    >
      <DialogTextBox mt={3}>
        <Flex p={3} flexDirection="column" alignItems="center">
          <Box mb={4}>
            <Text fontSize={5} fontWeight={500}>
              Thank you, your purchase can be found in
            </Text>
            <Link to="/business-library/my-library">
              <Text fontSize={5} fontWeight={500} color="primary.0">
                {" "}
                My Library
              </Text>
            </Link>
          </Box>
          <Text fontSize={3} color="dark.2">
            Your will receive an invoice by email and your research will be
            available shortly while your payment is processed by us.
          </Text>
        </Flex>
      </DialogTextBox>
    </Dialog>
  )
}

export default PurchasedDialog
