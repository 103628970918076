import React from "react"
import styled from "styled-components"
import { Button, Text } from "@engaging-tech/components"

const ButtonText = styled(Text)`
  font-size: 14px;
  font-weight: 700;
`

const TextButton = ({ text = null, children, color = "primary.0", ...props }) => (
  <Button alignItems="center" width="auto" {...props} elevation={0} bg="transparent">
    <ButtonText color={color}>{children || text}</ButtonText>
  </Button>
)

export default TextButton
