import { titleCaps } from "@engaging-tech/frontend-utils"
import { createSelector } from "reselect"

const jobMatcherState = state => state.jobMatcher

const generateCityString = ({ city, region, country }) => {
  if (city === region?.name) {
    return (region?.name ? `${region.name}, ` : "") + (country ? country.name : "")
  }

  return (
    (city ? `${titleCaps(city)}, ` : " ") +
    (region?.name ? `${region.name}, ` : "") +
    (country ? country.name : "")
  )
}

const mapJobMatches = jobMatches => {
  return jobMatches?.map(({ job, createdAt, score, ...rest }) => ({
    job: {
      ...job,
      postedDate: createdAt,
      companyName: job.organisation?.name,
      city: generateCityString({
        city: job.location?.city,
        region: job.location?.region,
        country: job.location?.country
      }),
      jobDescription: job.description,
      match: Math.round(score * 100),
      industryRanking: job.industryRanking
    },
    ...rest
  }))
}

export const getIsLoading = createSelector(jobMatcherState, state => state.isLoading)

export const getJobMatches = createSelector(jobMatcherState, state => mapJobMatches(state.jobMatches))
export const getIsJobMatchesLoading = createSelector(jobMatcherState, state => state.isJobMatchesLoading)

export const getIsSubmitting = createSelector(jobMatcherState, state => state.isSubmitting)

export const getError = createSelector(jobMatcherState, state => state.hasError)

export const getCountries = createSelector(jobMatcherState, state => state.countries)

export const getRegions = createSelector(jobMatcherState, state => state.regions)
export const getIsRegionsLoading = createSelector(jobMatcherState, state => state.isRegionsLoading)
