import { Flex, MotionFlex, Paragraph, Text } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React from "react"

import PrimaryButton from "../../ui/components/PrimaryButton"
import subscriptionDetails from "../lib/subscriptionDetails"

const premiumSuccessImg = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/premium-success.svg`)

const getDate = discountInput => {
  if (discountInput !== "") return new Date(Date.now() + 6.048e8 * 14).toLocaleDateString()
  return new Date(Date.now() + 6.048e8 * 2).toLocaleDateString()
}

const Success = ({ alreadyGotPremium, discountInput }) => {
  const paths = usePaths()
  const router = useRouter()
  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      width="100%"
      height="100%"
      alignItems="center"
    >
      <Flex flexDirection="column" mt={3} width="100%" alignItems="center">
        <MotionFlex
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          transition={{ delay: 0.6, duration: 0.2 }}
        >
          <Text fontSize={7} fontWeight={700} color="primary.0" width="auto" textAlign="center">
            {alreadyGotPremium ? "Premium Subscription" : "Welcome to WorkL Premium!"}
          </Text>
        </MotionFlex>

        <MotionFlex
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          justifyContent="center"
          alignItems="center"
          transition={{ delay: 0.7 }}
        >
          <Paragraph color="primary.0" fontSize={6} textAlign="center" width="90%" mt={3}>
            {alreadyGotPremium
              ? "You already have an active subscription to WorkL Premium"
              : `You have successfully signed up to WorkL Premium and created an account! Your free trial ends in ${subscriptionDetails.defaultTrialPeriodDays} day, then you will be charged £${subscriptionDetails.premiumPrice} a month. Get started by filling out your Career Developer profile now!`}
          </Paragraph>
        </MotionFlex>
      </Flex>
      <MotionFlex
        animate={{ opacity: 1, scale: 1 }}
        initial={{ opacity: 0, scale: 0 }}
        justifyContent="center"
        alignItems="center"
        transition={{ delay: 0.8 }}
      >
        <img
          src={premiumSuccessImg}
          width="80%"
          style={{ maxWidth: 400 }}
          alt="successfully subscribed to premium celebration"
        />
      </MotionFlex>
      <MotionFlex
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        justifyContent="center"
        alignItems="center"
        transition={{ delay: 1 }}
      >
        <PrimaryButton
          width={290}
          height={50}
          onClick={() => router.navigate(paths.careerDeveloper.index)}
          fontSize="20px"
          mx="auto"
        >
          GO TO CAREER DEVELOPER
        </PrimaryButton>
      </MotionFlex>
    </Flex>
  )
}

export default Success
