import { types } from "./account.actions"

export const initialState = {
  isLoading: true,
  isAuthenticated: false,
  hasResetPassword: false,
  magicLinkSent: false,
  token: "",
  error: ""
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOKEN_SIGN_IN:
      return { ...state, isLoading: true }

    case types.SIGN_IN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        token: action.payload
      }

    case types.SEND_MAGIC_LINK_FAIL:
    case types.SIGN_IN_FAIL:
    case types.TOKEN_SIGN_IN_FAIL:
      return { ...state, isLoading: false, magicLinkSent: false }

    case types.SEND_MAGIC_LINK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        magicLinkSent: true
      }

    case types.RESET_SEND_MAGIC_LINK:
      return {
        ...state,
        isLoading: false,
        magicLinkSent: false
      }

    default:
      return state
  }
}
