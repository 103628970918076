import React from "react"
import styled from "styled-components"
import { Link } from "@engaging-tech/routing"
import { Card, Text, Flex, Icon } from "@engaging-tech/components"

const GridCard = styled(Card)`
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary[5]};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.secondary[4]};
  }
`

const CategoryCard = ({ description, path, icon }) => (
  <GridCard mb={3}>
    <Link width={1 / 1} to={path}>
      <Flex flexDirection="row" p={3} alignItems="center">
        <Icon name={icon} m={0} width="auto" />
        {description && (
          <Text fontSize={3} ml={3} color="dark.1">
            {description}
          </Text>
        )}
      </Flex>
    </Link>
  </GridCard>
)

export default CategoryCard
