import { connect } from "react-redux"
import ProfileSettingsModalComponent from "../../components/ProfileSettingsModal"
import { setShowModal } from "../../../../yourProfile/store/yourProfile.actions"
import { getIsPremium } from "../../../../yourProfile/store/yourProfile.selectors"

const mapState = state => ({
  isPremium: getIsPremium(state)
})

const mapDispatch = dispatch => ({
  setVisibility: type => dispatch(setShowModal(type))
})

const ProfileSettingsModal = connect(
  mapState,
  mapDispatch
)(ProfileSettingsModalComponent)

export default ProfileSettingsModal
