/* eslint-disable import/prefer-default-export */
import { gql } from "../../../lib/Apollo"

export const LOAD_JOB_MATCHES = gql`
  query {
    getMatchedJobs {
      id
      status
      user {
        salary {
          min
          max
          private
        }
        skills {
          id
          name
        }
      }
      job {
        id
        role
        organisation {
          name
        }
        location {
          name
          countryId
          vicinity
          point {
            coordinates
          }
          country {
            shortName
            name
          }
        }
        industryRanking
        jobType
        salary {
          min
          max
          private
        }
        skills
        hobbies
        description
        responsibilities
        requirements
      }
      score
      createdAt
    }
  }
`

export const DELETE_JOB_MATCH = gql`
  mutation ($jobMatchedId: ID!) {
    deleteMatch(jobMatchedId: $jobMatchedId)
  }
`

export const UPDATE_JOB_MATCHER_PROFILE = gql`
  mutation (
    $currentJobRole: String
    $currentJobSalary: Float
    $desiredJobRoles: [String]
    $desiredJobType: String
    $desiredJobSalary: DesiredJobSalaryInput
    $desiredJobLocation: ProfileCreationLocationInput
  ) {
    updateJobMatcher(
      currentJobRole: $currentJobRole
      currentJobSalary: $currentJobSalary
      desiredJobRoles: $desiredJobRoles
      desiredJobType: $desiredJobType
      desiredJobSalary: $desiredJobSalary
      desiredJobLocation: $desiredJobLocation
    ) {
      currentJobRole
      currentJobSalary
      desiredJobRoles
      desiredJobType
      desiredJobSalary {
        min
        max
      }
      desiredJobLocation {
        name
        vicinity
        country {
          shortName
          name
        }
        countryId
        point {
          coordinates
        }
      }
    }
  }
`
