import React, { createContext, useContext } from "react"

const QuestionContext = createContext({
  onNextSection: () => null,
  onPreviousSection: () => null
})

export const QuestionProvider = ({
  onNextSection,
  onPreviousSection,
  currentSection,
  sectionsList,
  children
}) => {
  const isLastSection = currentSection === sectionsList?.length - 1

  return (
    <QuestionContext.Provider
      value={{
        onNextSection,
        onPreviousSection,
        isLastSection
      }}
    >
      {children}
    </QuestionContext.Provider>
  )
}

export const useQuestionContext = () => {
  const context = useContext(QuestionContext)
  return context
}
