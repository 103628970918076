export const types = {
  LOAD_ALL_BOOKS: "[Library Books] Load All Books",
  LOAD_ALL_BOOKS_SUCCESS: "[Library Books] Load All Success",
  LOAD_ALL_BOOKS_FAIL: "[Library Books] Load All Fail",
  LOAD_MORE: "[Library Books] Load More",
  LOAD_MORE_SUCCESS: "[Library Books] Load More Success",
  LOAD_MORE_FAIL: "[Library Books] Load More Fail",
  LOAD_BOOK: "[Library Books] Load Book",
  LOAD_BOOK_SUCCESS: "[Library Books] Load Book Success",
  LOAD_BOOK_FAIL: "[Library Books] Load Book Fail",
  SELECT_FORMAT: "[Library Books] Select book format",
  FILTER: "[Library Books] Filter",
  RESET_FILTER: "[Library Books] Reset Filters",
  SEARCH: "[Library Books] Search",
  RESET_SEARCH: "[Library Books] Reset Search",
  ADD_TO_BASKET: "[Library Books] Add to basket",
  UPDATE_QUANTITY: "[Library Books] Update quantity of product",
  DELETE_PRODUCT: "[Library Books] Delete product from basket",
  LOAD_ORDER: "[Library Books] Load Order",
  LOAD_ORDER_SUCCESS: "[Library Books] Load Order Success",
  LOAD_ORDER_FAIL: "[Library Books] Load Order Fail",
  CREATE_ORDER: "[Library Books] Create Order",
  CREATE_ORDER_SUCCESS: "[Library Books] Create Order Success",
  CREATE_ORDER_FAIL: "[Library Books] Create Order Fail",
  RESET_ORDER: "[Library Books] Reset Order",
  RESET_BASKET: "[Library Books] Reset Basket",
  UPDATE_ORDER: "[Library Books] Update Order",
  UPDATE_ORDER_SUCCESS: "[Library Books] Update Order Success",
  UPDATE_ORDER_FAIL: "[Library Books] Update Order Fail",
  CREATE_ORDER_INTENT: "[Library Books] Create Order Intent",
  CREATE_ORDER_INTENT_SUCCESS: "[Library Books] Create Order Intent Success",
  CREATE_ORDER_INTENT_FAIL: "[Library Books] Create Order Intent Fail",
  PAY_ORDER_SUCCESS: "[Library Books] Pay Order Success",
  PAY_ORDER_FAIL: "[Library Books] Pay Order Fail",
  SET_DELIVERY_TYPE: "[Library Books] Set Order Delivery Type",
  LOAD_DIGITAL_PURCHASES: "[Library Books] Load Digital Purchase",
  LOAD_DIGITAL_PURCHASES_SUCCESS:
    "[Library Books] Load Digital Purchase Success",
  LOAD_DIGITAL_PURCHASES_FAIL: "[Library Books] Load Digital Purchase Fail",
  LOAD_DISCOVERY_BOOKS: "[Library Books] Load Discovery Books",
  LOAD_DISCOVERY_BOOKS_SUCCESS: "[Library Books] Load Discovery Books Success",
  LOAD_DISCOVERY_BOOKS_FAIL: "[Library Books] Load Discovery Books Fail",
  LOAD_ORDERS: "[Library Books] Load Orders",
  LOAD_ORDERS_SUCCESS: "[Library Books] Load Orders Success",
  LOAD_ORDERS_FAIL: "[Library Books] Load Orders Fail"
}

export const loadAllBooks = (payload) => ({
  type: types.LOAD_ALL_BOOKS,
  payload
})

export const loadAllBooksSuccess = books => ({
  type: types.LOAD_ALL_BOOKS_SUCCESS,
  payload: books
})

export const loadAllBooksFail = () => ({
  type: types.LOAD_ALL_BOOKS_FAIL
})

export const loadMore = ({ from }) => ({
  type: types.LOAD_MORE,
  payload: { from }
})

export const loadMoreSuccess = books => ({
  type: types.LOAD_MORE_SUCCESS,
  payload: books
})

export const loadMoreFail = () => ({
  type: types.LOAD_MORE_FAIL
})

export const loadBook = bookId => ({
  type: types.LOAD_BOOK,
  payload: bookId
})

export const loadBookSuccess = book => ({
  type: types.LOAD_BOOK_SUCCESS,
  payload: book
})

export const addToBasket = ({ format, quantity }) => ({
  type: types.ADD_TO_BASKET,
  payload: { format, quantity }
})

export const updateQuantity = ({ id, quantity }) => ({
  type: types.UPDATE_QUANTITY,
  payload: { id, quantity }
})

export const deleteProduct = bookId => ({
  type: types.DELETE_PRODUCT,
  payload: bookId
})

export const loadBookFail = () => ({
  type: types.LOAD_BOOK_FAIL
})

export const selectFormat = selectedFormat => ({
  type: types.SELECT_FORMAT,
  payload: selectedFormat
})

export const filter = category => ({
  type: types.FILTER,
  payload: category
})

export const resetFilters = () => ({
  type: types.RESET_FILTER
})

export const search = searchTerm => ({
  type: types.SEARCH,
  payload: searchTerm
})

export const resetSearch = () => ({
  type: types.RESET_SEARCH
})

export const resetOrder = () => ({
  type: types.RESET_ORDER
})

export const resetBasket = () => ({
  type: types.RESET_BASKET
})

export const loadOrder = ({ id, userId }) => ({
  type: types.LOAD_ORDER,
  payload: { id, userId }
})

export const loadOrderSuccess = order => ({
  type: types.LOAD_ORDER_SUCCESS,
  payload: order
})

export const loadOrderFail = () => ({
  type: types.LOAD_ORDER_FAIL
})

export const createOrder = books => ({
  type: types.CREATE_ORDER,
  payload: books
})

export const createOrderSuccess = orderId => ({
  type: types.CREATE_ORDER_SUCCESS,
  payload: orderId
})

export const createOrderFail = message => ({
  type: types.CREATE_ORDER_FAIL,
  payload: message
})

export const updateOrder = ({ orderId, orderInfo }) => ({
  type: types.UPDATE_ORDER,
  payload: { orderId, orderInfo }
})

export const updateOrderSuccess = orderId => ({
  type: types.UPDATE_ORDER_SUCCESS,
  payload: orderId
})

export const updateOrderFail = () => ({
  type: types.UPDATE_ORDER_FAIL
})

export const createOrderIntent = orderId => ({
  type: types.CREATE_ORDER_INTENT,
  payload: orderId
})

export const createOrderIntentSuccess = intentSecret => ({
  type: types.CREATE_ORDER_INTENT_SUCCESS,
  payload: intentSecret
})

export const createOrderIntentFail = () => ({
  type: types.CREATE_ORDER_INTENT_FAIL
})

export const payOrderSuccess = () => ({
  type: types.PAY_ORDER_SUCCESS
})

export const payOrderFail = () => ({
  type: types.PAY_ORDER_FAIL
})

export const setDeliveryType = code => ({
  type: types.SET_DELIVERY_TYPE,
  payload: code
})

export const loadDigitalPurchases = userId => ({
  type: types.LOAD_DIGITAL_PURCHASES,
  payload: { userId }
})

export const loadDigitalPurchasesSuccess = purchases => ({
  type: types.LOAD_DIGITAL_PURCHASES_SUCCESS,
  payload: purchases
})

export const loadDigitalPurchasesFail = () => ({
  type: types.LOAD_DIGITAL_PURCHASES_FAIL
})

export const loadOrders = userId => ({
  type: types.LOAD_ORDERS,
  payload: { userId }
})

export const loadOrdersSuccess = orders => ({
  type: types.LOAD_ORDERS_SUCCESS,
  payload: orders
})

export const loadOrdersFail = () => ({
  type: types.LOAD_ORDERS_FAIL
})

export const loadDiscoveryBooks = eansPerCategory => ({
  type: types.LOAD_DISCOVERY_BOOKS,
  payload: eansPerCategory
})

export const loadDiscoveryBooksSuccess = booksPerCateories => ({
  type: types.LOAD_DISCOVERY_BOOKS_SUCCESS,
  payload: booksPerCateories
})

export const loadDiscoveryBooksFail = () => ({
  type: types.LOAD_DISCOVERY_BOOKS_FAIL
})
