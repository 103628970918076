import { getConfig } from "@engaging-tech/ssr-config"
import { apollo } from "../../../lib/Apollo"
import { LOAD_MY_MENTOR_MATCHES, DELETE_MATCH } from "./mentorMatcher.queries"

const GRAPHQL_API_URL = () =>
  `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

export const loadMyMentorMatches = () =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD_MY_MENTOR_MATCHES
  })

export const deleteMentorMatch = id =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: DELETE_MATCH,
    variables: { id }
  })
