import { Box, Button, Flex, Text } from "@engaging-tech/components"
import styled from "styled-components"

import { ArrowRightIcon } from "../../../../../../assets/icons"

export const Header = styled(Box)`
  width: 100%;
  height: 100%;

  padding: 20px;

  box-sizing: border-box;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  border-bottom: solid 2px rgba(0, 0, 0, 0.08);

  @media screen and (max-width: 1080px) {
    width: 100%;

    padding: 16px;

    box-shadow: unset;
    border-bottom: unset;
  }
`

export const JobTitleWrapper = styled(Flex)`
  width: 100%;

  flex-direction: column;
`

export const JobTitleContainer = styled(Flex)`
  position: relative;

  justify-content: space-between;
`

export const JobTitle = styled(Text)`
  width: auto;

  margin-bottom: 6px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;

  color: #003e4c;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const JobLocationAndSalary = styled(Text)`
  width: auto;

  margin-bottom: 6px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #003e4c;
`

export const JobTypeAndPostDate = styled(Text)`
  width: auto;

  margin-bottom: 6px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #003e4c;
`

export const ApplyButton = styled(Button)`
  margin-top: 14px;

  background: #003e4c;
  border-radius: 5px;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`

export const ApplyIcon = styled(ArrowRightIcon)`
  margin-left: 5px;
`
