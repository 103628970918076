import { Flex, Text } from "@engaging-tech/components"
import React from "react"
import * as Yup from "yup"

import FieldAreaInput from "../../../formFlowWizard/components/formElements/FieldAreaInput"
import MarkPriceQuote from "./MarkPriceQuote"

const icon = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/create.png`)

const info = {
  icon,
  title: "Create a CV",
  submitData: false
}

const validationSchema = Yup.object({
  personalStatement: Yup.string()
    .required("This field cannot be left blank")
    .max(600, "Must be less than or equal to 600 characters")
})

const PersonalStatement = ({ formik }) => {
  const { values } = formik

  return (
    <Flex width={1 / 1} flexDirection="column">
      <MarkPriceQuote quote="Convey your character, your values and why you would be a great fit for the job." />
      <Text my={3} fontWeight={700}>
        Personal Statement
      </Text>
      <FieldAreaInput
        name="personalStatement"
        inputLabel="Tell us a little something about yourself"
        height={[96, 128]}
        helperText={
          values.personalStatement ? `${values.personalStatement.length}/600 characters` : "0/600 characters"
        }
        label="Start with the reasons why you want to apply for this specific job and
        company. Next, add your qualities or experience that make you a great
        fit."
      />
    </Flex>
  )
}

export default { component: PersonalStatement, validationSchema, info }
