import { usePaths } from "@engaging-tech/routing"
import React from "react"

import FormView from "../../features/jobMatcher/views/form"
import MinifiedNavLayout from "../../layouts/MinifiedNavLayout"

const JobMatcherForm = () => {
  const paths = usePaths()

  return (
    <MinifiedNavLayout bg="primary.0">
      <FormView redirectTo={paths.careerDeveloper.jobMatcher.index} />
    </MinifiedNavLayout>
  )
}

export default JobMatcherForm
