import React from "react"
import { Text, Flex, Box } from "@engaging-tech/components"
import { useTranslation } from "react-i18next"

import ReportItem from "../../components/ReportItem"
import ResultCard from "../../components/ResultCard"
import StepResult from "../containers/StepResult"
import SixStepsLabel from "./SixStepsLabel"

const WellbeingSixSteps = () => {
  const { t } = useTranslation(["wellbeing_results"])
  return (
    <ResultCard>
      <ReportItem
        heading={t("six_steps.heading", "")}
        subHeading={t("six_steps.sub_heading", "")}
      >
        <SixStepsLabel />
        <Flex flexWrap="wrap" justifyContent="space-evenly" py={5}>
          {Object.entries(t("six_steps.steps", { returnObjects: true })).map(
            step => (
              <StepResult key={step[0]} heading={step[1]} section={step[0]} />
            )
          )}
        </Flex>

        <Box bg="primary.5" p={4}>
          <Text
            fontSize={3}
            display="block"
            mb={1}
            fontWeight={400}
            color="dark.2"
          >
            {t("six_steps.below_60.warning1", "")}
          </Text>
          <Text
            fontSize={3}
            display="block"
            mb={0}
            fontWeight={400}
            color="dark.2"
          >
            {t("six_steps.below_60.warning2", "")}
          </Text>
        </Box>
      </ReportItem>
    </ResultCard>
  )
}

export default WellbeingSixSteps
