import { connect } from "react-redux"

import { cvLibrarySelectors } from ".."
import { getFirstName, getLastName } from "../../yourProfile/store/yourProfile.selectors"
import SelectedCVComponent from "../components/SelectedCV"

const mapState = state => ({
  firstName: getFirstName(state),
  lastName: getLastName(state),
  isLoading: cvLibrarySelectors.getIsLoading(state),
  cv: cvLibrarySelectors.getSelectedCV(state)
})

const SelectedCV = connect(mapState, null)(SelectedCVComponent)

export default SelectedCV
