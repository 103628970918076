import { Flex, Select, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

// Custom select component to remove border and add rounded corners
const CustomSelect = styled(Select)`
  & > select {
    border-radius: 4px;
    padding-top: ${({ theme }) => `${theme.space[3]}px`};
    padding-bottom: ${({ theme }) => `${theme.space[3]}px`};
    padding-left: ${({ theme }) => `${theme.space[3]}px`};
    padding-right: ${({ theme }) => `${theme.space[4]}px`};
    line-height: 1;
    border-bottom: none;
    color: #00000089;
    text-transform: uppercase;
  }
  & > select:focus,
  & > select:hover {
    border-bottom: none;
  }
  & > select > option {
    text-transform: uppercase;
  }
  & > div > span {
    color: ${({ theme }) => theme.colors.error[0]};
  }
  &:before {
    top: 8px;
  }
`

const calculateTrendValue = surveyList => {
  const firstSurvey = surveyList[0]
  const lastSurvey = surveyList[surveyList.length - 1]
  if (surveyList.length < 3) {
    return {
      text: "-",
      color: "#00000089"
    }
  }

  const percentageDifference = lastSurvey.score - firstSurvey.score
  if (percentageDifference === 0) {
    return {
      text: "-",
      color: "#00000089"
    }
  }
  const trendDirection = percentageDifference > 0 ? "+" : ""
  return {
    text: `${trendDirection}${Math.round(percentageDifference)}%`,
    color: trendDirection === "+" ? "#00A378" : "#BF4630"
  }
}

/**
 * Graph to show happiness trend of a users surveys
 *
 * @param {Object} params - params passed into component
 * @param {Object} params.originalSurveyList - All Survey objects to be included within dropdown options
 * @param {Object[]} params.filteredSurveys - Filtered Survey objects
 * @param {Number} params.selectedYear - Selected survey year from filter
 * @param {Object} params.selectedType - Selected survey type object from filter
 * @param {Function} params.setSelectedYear - Setter function for year state
 * @param {Function} params.setSelectedType - Setter function for type state
 * @param {Object} params.surveyTypes - Survey objects has been loaded
 *
 * @returns react component
 */
const TrendGraphFilterBar = ({
  originalSurveyList,
  filteredSurveys,
  selectedYear,
  selectedType,
  setSelectedYear,
  setSelectedType,
  surveyTypes
}) => {
  const generateYearOptions = () => {
    const yearArray = filteredSurveys.map(survey => new Date(survey.completedAt).getFullYear())
    const yearSet = new Set(yearArray)

    return Array.from(yearSet).map(y => (
      <option key={y} value={y}>
        {y}
      </option>
    ))
  }

  const generateSurveyTypeOptions = () =>
    Object.values(surveyTypes).map(sT => (
      <option key={sT.value} value={sT.value}>
        {sT.text}
      </option>
    ))

  const trendValue = calculateTrendValue(filteredSurveys)
  console.log({ filteredSurveys, trendValue })

  return (
    <Flex
      flexDirection={["column", "row"]}
      height={["auto", 70]}
      justifyContent="space-between"
      alignItems={["flex-start", "center"]}
      width={1 / 1}
      padding={[0, 30]}
      mb={[3, 0]}
    >
      <Flex
        width={[1 / 1, 4 / 10]}
        flexDirection={["column", "row"]}
        justifyContent={["flex-start", "center"]}
        alignItems="center"
        ml={[0, 2]}
        mt={[2, 0]}
      >
        <Text width={250} textAlign={["left", "right"]} mr={20} mb={[2, 0]} color="dark.2">
          Filter By Survey Type
        </Text>
        <CustomSelect
          id="surveyType"
          disabled={originalSurveyList.length === 0}
          onChange={event => setSelectedType(surveyTypes[event.target.value])}
          value={selectedType.value}
          width={[1 / 1, 350]}
          color="dark.2"
        >
          {generateSurveyTypeOptions()}
        </CustomSelect>
      </Flex>
      <Flex
        width={[1 / 1, 4 / 10]}
        flexDirection={["column", "row"]}
        justifyContent={["flex-start", "center"]}
        alignItems="center"
        ml={[0, 2]}
        mt={[2, 0]}
      >
        <Text width={250} textAlign={["left", "right"]} mr={20} mb={[2, 0]} color="dark.2">
          Filter By Year
        </Text>
        <CustomSelect
          id="surveyYear"
          disabled={originalSurveyList.length === 0}
          onChange={event => setSelectedYear(Number(event.target.value))}
          value={selectedYear}
          color="dark.2"
          width={[1 / 1, 350]}
        >
          <option key="All" value={0}>
            All
          </option>
          {generateYearOptions()}
        </CustomSelect>
      </Flex>
      <Flex
        width={150}
        justifyContent={["flex-end", "center"]}
        alignItems="center"
        flexDirection="row"
        ml={[0, 2]}
        bg="rgba(0,0,0,0.04)"
        height={48}
        borderRadius={4}
        p={14}
        mt={["16px", 0]}
      >
        <Text color="dark.2">Trend:</Text>
        <Text fontWeight={600} fontSize={6} color={trendValue.color}>
          {trendValue.text}
        </Text>
      </Flex>
    </Flex>
  )
}

export default TrendGraphFilterBar
