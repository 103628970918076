import { Flex, Text } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"
import styled from "styled-components"

const TestingText = styled(Text)`
  text-transform: uppercase;
`

const TestingSiteBanner = () => {
  const { isProd } = getConfig()
  return isProd ? null : (
    <Flex bg="#eb7a1e" p={[2, 3]} width={1 / 1} justifyContent="center">
      <TestingText
        width="auto"
        color="light.0"
        fontFamily="serif"
        fontSize={[4, 6]}
        fontWeight="700"
      >
        This is the test site for WorkL
      </TestingText>
    </Flex>
  )
}

export default TestingSiteBanner
