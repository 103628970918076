/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */

import React from "react"
import styled from "styled-components"
import { Button, Text, Flex, Box, H4, List } from "@engaging-tech/components"
import { useRouter } from "@engaging-tech/routing"

const Image = styled.img`
  width: 100%;
`

const getImage = name =>
  require(`${process.env.RAZZLE_PUBLIC_DIR}/img/${name}.png`)

const Description = ({ feature }) => {
  const { title, points, action } = feature
  const router = useRouter()

  return (
    <Flex flexDirection={["column", "row"]}>
      <Flex
        width={[1 / 1, 1 / 2]}
        mr={[0, 5]}
        mb={[5, 0]}
        flexDirection="column"
      >
        <H4 color="dark.2" fontWeight="600" fontSize={5} mb={5}>
          {title}
        </H4>
        {points.map(point => (
          <List.Wrapper key={point.heading}>
            <List.Item>
              <Box>
                <H4 fontSize={4} fontWeight="600" color="dark.2" mb={1}>
                  {point.heading}
                </H4>
                <Text color="dark.2">{point.description}</Text>
              </Box>
            </List.Item>
          </List.Wrapper>
        ))}
        <Box mt={4}>
          <Button
            variant="primary.0"
            width={[1 / 1, "121px"]}
            onClick={() => router.navigate(action.url)}
          >
            Get Started
          </Button>
        </Box>
      </Flex>
      <Flex
        width={[1 / 1, 1 / 2]}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="inherit"
      >
        <Image src={getImage(feature.image)} />
      </Flex>
    </Flex>
  )
}

export default Description
