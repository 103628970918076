import { createSelector } from "reselect"
// import { AccountSelectors } from "features/account"

import * as DeliveryTypes from "../lib/deliveryTypes"

const libraryBooksState = state => state.libraryBooks

export const getFilter = state => state.libraryBooks.filter
export const getSearchTerm = state => state.libraryBooks.searchTerm

export const getBooksResults = createSelector(
  libraryBooksState,
  state => state.books
)

export const getIsCheckoutLoading = createSelector(
  libraryBooksState,
  state => state.isCheckoutLoading
)

export const getEbookResult = createSelector(libraryBooksState, state => {
  const ebooks = (state.book && state.book.eBooks) || []
  const ebook = ebooks.find(
    ebookData =>
      ebookData.formats && ebookData.formats.find(format => format.id === 6)
  )
  if (ebook) {
    ebook.price =
      ebook.format_data &&
      ebook.format_data.find(data => data.gardnerEBookFormatId === 6).price
  }
  return ebook
})

export const getAudioBookResult = createSelector(libraryBooksState, state => {
  const ebooks = (state.book && state.book.eBooks) || []
  const ebook = ebooks.find(
    ebookData =>
      ebookData.formats && ebookData.formats.find(format => format.id === 5)
  )
  if (ebook) {
    ebook.price =
      ebook.format_data &&
      ebook.format_data.find(data => data.gardnerEBookFormatId === 5).price
  }
  return ebook
})

export const getFiltered = createSelector(
  [getBooksResults, getFilter],
  (content, categoryId) => {
    const results = content.filter(book =>
      book.bicCliassifications.find(category => category.code === categoryId)
    )
    return results.length > 0 ? results : content
  }
)

export const getIsLoading = createSelector(
  libraryBooksState,
  state => state.isLoading
)

export const getIsLoadingMore = createSelector(
  libraryBooksState,
  state => state.isLoadingMore
)

export const getHasLoadedAll = createSelector(
  libraryBooksState,
  state => state.hasLoadedAll
)

export const getLoadCounter = createSelector(
  libraryBooksState,
  state => state.loadFrom
)

export const getHasResults = createSelector(
  libraryBooksState,
  state => state.books && state.books.length
)

export const getBookResult = createSelector(libraryBooksState, state =>
  state.book ? state.book : null
)

export const getSelectedFormat = createSelector(
  libraryBooksState,
  state => state.selectedFormat
)

export const getSelectedProduct = createSelector(libraryBooksState, state => {
  let product = {}
  const ebooks = (state.book && state.book.eBooks) || []
  switch (state.selectedFormat) {
    case "ebook":
      product = ebooks.find(ebookData =>
        ebookData.formats.find(format => format.id === 6)
      )
      break
    case "audiobook":
      product = ebooks.find(ebookData =>
        ebookData.formats.find(format => format.id === 5)
      )
      break
    case "hardCover":
      product = state.book
      break
    default:
      product = {}
  }
  return product
})

export const getHasFiltered = createSelector(
  getFilter,
  activeFilter => activeFilter !== "0"
)

export const getBasket = createSelector(libraryBooksState, state =>
  state.basket ? state.basket : []
)

export const getOrderId = createSelector(libraryBooksState, state =>
  state.orderId ? state.orderId : null
)

export const getSubTotalPrice = createSelector(libraryBooksState, state => {
  let totalPrice = 0
  state.basket.forEach(book => {
    totalPrice += book.price * book.quantity
  })
  return totalPrice
})

export const getTotalPrice = createSelector(
  // Disable checking if a user is premium or not.
  // This is a tightly-coupled dependency, and will
  // need to be refactored.

  // [libraryBooksState, AccountSelectors.getIsPremium],
  [libraryBooksState, () => false],
  (state, isPremium) => {
    let totalPrice = 0
    state.basket.forEach(book => {
      totalPrice += book.price * book.quantity
    })
    totalPrice = isPremium ? totalPrice * 0.8 : totalPrice
    return totalPrice
  }
)

export const getHasShippingCosts = createSelector(
  libraryBooksState,
  state => !!state.basket.filter(book => book.format === "hardCover").length
)

export const getDeliveryPrice = createSelector(libraryBooksState, state => {
  const delivery = DeliveryTypes.Types.find(
    type => type.code === state.deliveryType
  )
  return (delivery && delivery.price) || 0
})

export const getIntentSecret = createSelector(libraryBooksState, state =>
  state.intentSecret ? state.intentSecret : null
)

export const getHasPaid = createSelector(libraryBooksState, state =>
  state.hasPaid ? state.hasPaid : false
)

export const getOrder = createSelector(libraryBooksState, state =>
  state.order ? state.order : null
)

export const getOrders = createSelector(libraryBooksState, state =>
  state.orders ? state.orders : null
)

export const getEBooks = createSelector(libraryBooksState, state =>
  state.digitalBooks.filter(book => book.type === "ebook")
)

export const getAudioBooks = createSelector(libraryBooksState, state =>
  state.digitalBooks.filter(book => book.type === "audiobook")
)

export const getDiscoveryBooksTop5 = createSelector(
  libraryBooksState,
  state => state.discoveryBooks?.businessBooks || []
)

export const getDiscoveryBooksNewRelease = createSelector(
  libraryBooksState,
  state => state.discoveryBooks?.popularReleases || []
)

export const getDiscoveryBooksRecommendation = createSelector(
  libraryBooksState,
  state => state.discoveryBooks?.recommendedBooks || []
)
