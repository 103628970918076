import { connect } from "react-redux"

import * as yourProfileSelectors from "../../../yourProfile/store/yourProfile.selectors"
import { getProfileCompletionPercentage } from "../../store/developCareer.selectors"
import AboutYourselfComponent from "../components/AboutYourself"

const mapState = state => ({
  isLoading: yourProfileSelectors.getIsLoading(state),
  age: yourProfileSelectors.getAge(state),
  gender: yourProfileSelectors.getGender(state),
  qualifications: yourProfileSelectors.getQualifications(state),
  positionsHeld: yourProfileSelectors.getPositionsHeld(state),
  hobbies: yourProfileSelectors.getHobbies(state),
  industries: yourProfileSelectors.getIndustries(state),
  languages: yourProfileSelectors.getLanguages(state),
  personalStatement: yourProfileSelectors.getPersonalStatement(state),
  skills: yourProfileSelectors.getSkills(state),
  profileCompletionPercentage: getProfileCompletionPercentage(state)
})

const AboutYourself = connect(mapState, null)(AboutYourselfComponent)

export default AboutYourself
