import { connect } from "react-redux"
import { getBranding } from "../store/survey.selectors"

const mapState = state => ({
  brand: getBranding(state)
})

const surveyLogoContainer = connect(mapState)

export default surveyLogoContainer
