import {
  Paragraph,
  Text,
  Flex,
  Button,
  Spinner
} from "@engaging-tech/components"
import React from "react"
import { Link, usePaths } from "@engaging-tech/routing"

const UnverifiedStep = ({
  sendVerification,
  isVerificationEmailSending,
  isVerificationEmailSent
}) => {
  const paths = usePaths()

  const getSendButtonState = () => {
    if (isVerificationEmailSent) {
      return "Sent"
    }
    if (isVerificationEmailSending) {
      return <Spinner color="light.0" size={20} mx={3} />
    }
    return "Send verification email"
  }

  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      width="100%"
      height="100%"
      alignItems="center"
    >
      <Flex
        flexDirection="column"
        justifyContent="flex-start"
        width="100%"
        alignItems="center"
      >
        <Text
          fontSize={7}
          fontWeight={700}
          color="primary.0"
          width="auto"
          textAlign="center"
        >
          Unverified Account
        </Text>
        <Paragraph
          color="primary.0"
          fontSize={5}
          textAlign="center"
          width="90%"
          mt={4}
        >
          To enable premium on your account, you need to verify your account
          first.
        </Paragraph>
      </Flex>

      <Flex width={1 / 1} justifyContent="space-between">
        <Link to={paths.home}>
          <Button variant="text.noBg.primary.0" elevation={0}>
            Cancel
          </Button>
        </Link>
        <Button
          variant="primary.0"
          height={32}
          onClick={sendVerification}
          minWidth={80}
        >
          {getSendButtonState()}
        </Button>
      </Flex>
    </Flex>
  )
}

export default UnverifiedStep
