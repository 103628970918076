import React from "react"
import styled from "styled-components"
import { Box, Flex } from "@engaging-tech/components"
import BreakdownRow from "./BreakdownRow"

const QuestionsContainer = styled(Flex)`
  div {
    border-top: 2px solid ${({ theme }) => theme.colors.dark[6]};
  }
  div:first-child {
    border: none;
  }
`

const Breakdown = ({ questions }) =>
  questions && (
    <Box
      bg="dark.7"
      px={[2, 5]}
      py={[0, 3]}
      borderRadius={4}
      mt={3}
      mb={[3, 5]}
    >
      <QuestionsContainer width={1 / 1} flexDirection="column">
        {questions.map((questionInfo, index) => (
          <BreakdownRow
            key={questionInfo.question}
            rowNum={index + 1}
            title={questionInfo.question}
            percentage={questionInfo.values.score}
          />
        ))}
      </QuestionsContainer>
    </Box>
  )

export default Breakdown
