import { userInterfaceNotificationsStore } from "@engaging-tech/components"
import { call, put, select, takeLatest } from "redux-saga/effects"

import getGraphQLErrCode from "../../../helpers/getGraphqlErrorCode"
import { loadWidgetLayout } from "../../developCareer/store/developCareer.actions"
import {
  applyDiscount,
  cancel,
  confirmEmail,
  create,
  load,
  requestConfirmEmail,
  subscribe,
  toggleServices,
  update,
  updateMyGoals,
  upload
} from "../services/yourProfile.service"
import * as Actions from "./yourProfile.actions"
import * as Selectors from "./yourProfile.selectors"

const subscriptionSteps = {
  pending: "incomplete",
  done: "completed"
}

export function* loadSaga(action) {
  try {
    const res = yield call(load, action.payload.userId, action.payload.place)
    yield put(Actions.loadSuccess(res.data.getUserProfile))
  } catch (error) {
    const code = getGraphQLErrCode(error)

    if (code && code === "ERR007") {
      yield put(
        userInterfaceNotificationsStore.actions.addNotification({
          message: "User not verified!"
        })
      )
    }

    yield put(Actions.loadFail({ code: "UM005" }))
  }
}

export function* uploadImageSaga(action) {
  try {
    if (action.payload) {
      const res = yield call(upload, action.payload)

      yield put(Actions.uploadImageSuccess(res.data.result))
    } else {
      yield put(Actions.uploadImageSuccess({ small: "", large: "" }))
    }
  } catch (error) {
    yield put(Actions.uploadImageFail("image upload"))
  }
}

export function* createProfileSaga(action) {
  try {
    const res = yield call(create, action.payload)

    if (res.data.createProfile) {
      yield put(Actions.createProfileSuccess(res.data.createProfile))
    }
  } catch (error) {
    yield put(Actions.setHasError("profile information"))
  }
}

export function* updateYourProfileSaga(action) {
  try {
    const res = yield call(update, action.payload)
    if (res.data.updateMyProfile) {
      yield put(Actions.updateYourProfileSuccess(res.data.updateMyProfile))
    }
  } catch (error) {
    yield put(Actions.setHasError("profile information"))
  }
}

export function* toggleServicesSaga(action) {
  try {
    const res = yield call(toggleServices, action.payload)
    if (res.data.toggleServices) {
      yield put(Actions.toggleServicesSuccess(res.data.toggleServices))
    }
  } catch (error) {
    yield put(Actions.setHasError(""))
  }
}

export function* confirmEmailSaga(action) {
  try {
    const res = yield call(confirmEmail, action.payload)
    yield res ? put(Actions.confirmEmailSuccess()) : put(Actions.confirmEmailFail())
  } catch {
    yield put(Actions.confirmEmailFail())
  }
}

export function* requestConfirmationSaga() {
  try {
    yield call(requestConfirmEmail)
    yield put(Actions.requestConfirmationSuccess({ code: "EMAIL_SENT" }))
  } catch (err) {
    yield put(Actions.requestConfirmationFail())
  }
}
export function* setToCancelledSaga() {
  yield put(Actions.setShowModal("cancelled"))
}

export function* updateMyGoalsSaga(action) {
  try {
    const res = yield call(updateMyGoals, action.payload)

    yield put(Actions.loadSuccess({ goals: res.data.updateGoals }))
    yield put(loadWidgetLayout())
  } catch (error) {
    yield put(Actions.setHasError("Failed to update 'My Goals' on backend"))
  }
}

// PREMIUM //
export function* subscribeSaga(action) {
  try {
    const source = action.payload.cardDetails
    const promoId = yield select(Selectors.getPromoId)
    const {
      data: { result }
    } = yield call(subscribe, {
      source,
      promoId
    })

    const { status } = result
    switch (status) {
      case subscriptionSteps.done:
        yield put(Actions.subscribeSuccess())
        break
      case subscriptionSteps.pending:
        yield put(Actions.subscribePending(result))
        break
      default:
        yield put(Actions.subscribeFail())
        break
    }
  } catch {
    yield put(Actions.subscribeFail())
  }
}

export function* setAttributesSaga(action) {
  const premium = action.payload?.premium || {}
  yield put(
    Actions.setPremiumAttributes({
      ...premium,
      premiumExpiration: action.payload?.premium?.expireDate,
      premiumCancelled: action.payload?.premium?.cancelled
    })
  )
}

export function* cancelPremiumSaga(action) {
  try {
    const res = yield call(cancel, action.payload)
    yield put(Actions.cancelPremiumSubscriptionSuccess(res.data.cancelPremiumSubscription))
  } catch (error) {
    yield put(Actions.cancelPremiumSubscriptionFail())
  }
}

export function* applyDiscountSaga(action) {
  try {
    let promotionCodeId
    if (action.payload.discountCode) {
      const res = yield call(applyDiscount, action.payload)
      promotionCodeId = res.data.result.promotionCodeId
      yield put(Actions.applyDiscountSuccess(promotionCodeId))
    } else {
      yield put(Actions.applyDiscountFail())
    }
  } catch (err) {
    yield put(Actions.applyDiscountFail())
  }
}

export function* saga() {
  yield takeLatest(Actions.types.LOAD, loadSaga)
  yield takeLatest(Actions.types.UPLOAD_IMAGE, uploadImageSaga)
  yield takeLatest(Actions.types.UPDATE_YOUR_PROFILE, updateYourProfileSaga)
  yield takeLatest(Actions.types.CREATE_PROFILE, createProfileSaga)
  yield takeLatest(Actions.types.TOGGLE_SERVICES, toggleServicesSaga)
  yield takeLatest(Actions.types.CONFIRM_EMAIL, confirmEmailSaga)
  yield takeLatest(Actions.types.REQUEST_CONFIRMATION, requestConfirmationSaga)
  yield takeLatest(Actions.types.CANCEL_PREMIUM_SUBSCRIPTION_SUCCESS, setToCancelledSaga)
  yield takeLatest(Actions.types.UPDATE_MY_GOALS, updateMyGoalsSaga)
  // PREMIUM //
  yield takeLatest(Actions.types.SUBSCRIBE, subscribeSaga)
  yield takeLatest(Actions.types.APPLY_DISCOUNT, applyDiscountSaga)
  yield takeLatest(Actions.types.CANCEL_PREMIUM_SUBSCRIPTION, cancelPremiumSaga)
  yield takeLatest(Actions.types.LOAD_SUCCESS, setAttributesSaga)
}
