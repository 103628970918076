import { connect } from "react-redux"
import { developCareerSelectors } from ".."
import TrendGraphsComponent from "../components/TrendGraph"

const mapState = state => ({
  surveys: developCareerSelectors.getIndividualSurveySubmissions(state),
  hasLoaded: !developCareerSelectors.getSurveys(state)?.loading
})

const TrendGraph = connect(mapState)(TrendGraphsComponent)

export default TrendGraph
