import React from "react"

import SolidLayout from "../../layouts/SolidLayout"
import AuthGuard from "../../features/ui/containers/AuthGuard"
import Subscription from "../../features/premium/views/Subscription"

const Subscribe = () => (
  <AuthGuard>
    <SolidLayout>
      <Subscription />
    </SolidLayout>
  </AuthGuard>
)

export default Subscribe
