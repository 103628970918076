import { Utils } from "@engaging-tech/components"
import { createSelector } from "reselect"

import surveyLifecycle from "../lib/surveyLifecycle"

const castAnswersToString = answers =>
  answers.map(answer => ({
    questionId: answer.questionId,
    values: answer.value.map(val => `${val}`)
  }))

export const surveyState = state => state.happinessSurvey
const surveyMetaState = state => state.happinessSurvey.meta
const surveyTemplateState = state => state.happinessSurvey.template

const getAnswer = (state, id) => {
  const {
    happinessSurvey: { answers }
  } = state
  return answers.find(answer => answer.questionId === id)
}

const generateBrandTheme = colour =>
  Utils.createTheme({
    colours: Utils.createColourPalette(colour)
  })

export const getIsLoading = createSelector(surveyState, state => state.isLoading)

export const getSurveyId = createSelector(surveyState, state => state?.surveyId)

export const getSavantaId = createSelector(surveyState, state => state.savantaId)
export const getIsSubmitting = createSelector(surveyState, state => state.isSubmitting)

export const getQuestionById = createSelector(
  (state, questionId) => {
    const { questions } = surveyState(state)
    return questions[questionId]
  },
  question => question || {}
)

export const getHasError = createSelector(surveyState, state => state.hasError)

export const getHasLoaded = createSelector(surveyState, state => state.hasLoaded)

export const getHasSubmitted = createSelector(surveyState, state => state.hasSubmitted || state.isLoading)

export const getBranding = createSelector(surveyMetaState, meta =>
  meta && meta.brand.colour
    ? {
        theme: generateBrandTheme(meta.brand.colour),
        logo: meta.brand.logo
      }
    : {
        theme: null,
        logo: null
      }
)

export const getIntroduction = createSelector(surveyState, state => state?.introduction || [])

export const getTitle = createSelector(surveyState, state => state && state.title)

export const getHasCustomIntro = createSelector(getIntroduction, intro => intro[0] && intro[0].content)

export const getStatus = createSelector(surveyState, state => state && state.status)

export const getIsTestSurvey = createSelector(getStatus, status => status !== surveyLifecycle.live)

export const getAllSections = createSelector(surveyTemplateState, template => template && template.sections)

export const getSavantaTerritory = createSelector(surveyState, state => state && state.savantaTerritory)

export const getAllAnswers = createSelector(surveyState, state => state.answers)

export const getAnswerById = createSelector(getAnswer, answer =>
  answer
    ? {
        id: answer.questionId,
        value: answer.value
      }
    : null
)

export const getAllQuestions = createSelector(surveyTemplateState, template =>
  template.sections.reduce((acc, cur) => [...acc, ...cur.questions], [])
)

export const getQuestionTotal = createSelector(getAllQuestions, questionList => questionList.length)

export const getQuestionIndexById = (state, id) => {
  const questionList = getAllQuestions(state)
  return questionList.indexOf(id) + 1
}

export const getFormattedSubmission = createSelector(
  [getSurveyId, surveyTemplateState, getAllAnswers],
  (id, template, answers) => ({
    id,
    templateId: template.id,
    answers: castAnswersToString(answers)
  })
)

export const getSubmissionId = createSelector(surveyState, state => state?.submissionId)

export const getSurveyLanguages = createSelector(surveyState, state => state?.languages)

export const getHasAttachedUser = createSelector(surveyState, state => state?.hasAttachedUser)

export const getCompanyName = createSelector(surveyState, state => state.companyName)
export const getCompanyId = createSelector(surveyState, state => state.companyId)
