export const types = {
  SET_HAS_ERROR: "[Your Profile] Set Has Error",
  LOAD_CVS: "[Your Profile] Load CVs",
  LOAD_CVS_SUCCESS: "[Your Profile] Load CVs Success",
  LOAD_CVS_FAIL: "[Your Profile] Load CVs Fail",
  LOAD_SELECTED_CV: "[Your Profile] Load Selected CV",
  LOAD_SELECTED_CV_SUCCESS: "[Your Profile] Load Selected CV Success",
  LOAD_SELECTED_CV_FAIL: "[Your Profile] Load Selected CV Fail",
  DELETE_CV: "[Your Profile] Delete CV",
  SET_DEFAULT_CV: "[Your Profile] Set Default CV",
  CREATE_CV: "[Your Profile] Create CV",
  CREATE_CV_SUCCESS: "[Your Profile] Create CV Success",
  CREATE_CV_FAIL: "[Your Profile] Create CV Fail"
}

export const setHasError = payload => ({
  type: types.SET_HAS_ERROR,
  payload
})

export const loadCVs = payload => ({
  type: types.LOAD_CVS,
  payload
})

export const loadCVsSuccess = payload => ({
  type: types.LOAD_CVS_SUCCESS,
  payload
})

export const loadCVsFail = () => ({
  type: types.LOAD_CVS_FAIL
})

export const loadSelectedCV = payload => ({
  type: types.LOAD_SELECTED_CV,
  payload
})

export const loadSelectedCVSuccess = payload => ({
  type: types.LOAD_SELECTED_CV_SUCCESS,
  payload
})

export const loadSelectedCVFail = () => ({
  type: types.LOAD_SELECTED_CV_FAIL
})

export const deleteCV = payload => ({
  type: types.DELETE_CV,
  payload
})

export const setDefaultCV = payload => ({
  type: types.SET_DEFAULT_CV,
  payload
})

export const createCV = payload => ({
  type: types.CREATE_CV,
  payload
})

export const createCVSuccess = payload => ({
  type: types.CREATE_CV_SUCCESS,
  payload
})

export const createCVFail = payload => ({
  type: types.CREATE_CV_FAIL,
  payload
})
