import { Avatar, Button, Flex } from "@engaging-tech/components"
import React from "react"

import { TextAreaCustom } from "../shared/styled"
import { FullWidthForm } from "./form.styled"

const Form = ({ isLoading, handleSubmit, user, values, isValid, handleChange, handleBlur, onClose }) => (
  <FullWidthForm onSubmit={handleSubmit}>
    <Flex>
      {!values.anonymous && user.picture.small && (
        <Flex mr={2} width={30} height={30}>
          <Avatar size={30} picture={user.picture.small} />
        </Flex>
      )}
      <Flex flexDirection="column">
        <TextAreaCustom
          height={56}
          name="content"
          value={values.content}
          onChange={handleChange}
          onBlur={handleBlur}
          width={1 / 1}
          label="Write a reply"
          autoFocus
          noLabel
        />
        <Flex flexDirection="row-reverse" flexWrap="wrap" mt={3}>
          <Button
            alignSelf="flex-end"
            width="auto"
            variant="primary.0"
            type="submit"
            ml={[3, 3, 2]}
            disabled={isLoading || !isValid}
          >
            Reply
          </Button>
          <Button width="auto" type="button" variant="text.dark.2" elevation={0} onClick={onClose}>
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Flex>
  </FullWidthForm>
)

export default Form
