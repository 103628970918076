import { connect } from "react-redux"
import { getSelectedCategory } from "../store/globalHub.selectors"
import TopBarComponent from "../components/TopBar/TopBar"

const mapState = state => ({
  selectedCategory: getSelectedCategory(state)
})

const TopBar = connect(mapState)(TopBarComponent)

export default TopBar
