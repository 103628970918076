import { createSelector } from "reselect"

const notificationsState = state => state?.notifications

export const getIsLoading = createSelector(notificationsState, state => state.isLoading)

export const getHasError = createSelector(notificationsState, state => state?.hasError)

export const getModalType = createSelector(notificationsState, state => state.modalType)

export const getAlerts = createSelector(notificationsState, state => state?.alerts)

export const getAlertSuccess = createSelector(notificationsState, state => state?.alertSuccess)

export const getLatestAlert = createSelector(notificationsState, state => state?.latestAlert)

export const getJobAlerts = createSelector(getAlerts, alerts => alerts?.jobs)
export const getEventAlerts = createSelector(getAlerts, alerts => alerts?.events)
export const getMaterialsAlerts = createSelector(getAlerts, alerts => alerts?.materials)

export const getActiveAlert = createSelector(notificationsState, state => state?.activeAlert)
