import { connect } from "react-redux"
import { getData } from "../store/survey.selectors"

const mapState = state => ({
  data: getData(state)
})

const surveyContainer = connect(mapState)

export default surveyContainer
