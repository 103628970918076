import React from "react"
import styled from "styled-components"
import { Link } from "@engaging-tech/routing"
import { Flex, Box, Paragraph, Text, H4, Card } from "@engaging-tech/components"

import CheckList from "../CheckList"
import Summary from "../Summary"
import Interactions from "../../Interactions/Interactions"
import CommentWrapper from "../../CommentWrapper/CommentWrapper"

const HeaderText = styled(Text)`
  text-transform: capitalize;
`

const HeaderWrapper = styled(Box)`
  border-radius: 4px 4px 0 0;
`

const createChecklist = items =>
  items.map((item, index) => <CheckList text={item} key={index.toString()} />)

const Header = () => (
  <HeaderWrapper bg="#BC96E8" py={2} px={3}>
    <HeaderText fontWeight="500">Job</HeaderText>
  </HeaderWrapper>
)

const CardContent = ({ title, text }) => (
  <>
    <H4 fontSize={3} color="dark.2" fontWeight="400" mb={2}>
      {title}
    </H4>
    <Paragraph
      textAlign="left"
      fontSize={3}
      color="dark.1"
      fontWeight="400"
      mb={3}
    >
      {text}
    </Paragraph>
  </>
)
const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const Details = ({
  job,
  user,
  publishedAt,
  interactions: RenderShare,
  userDetails: UserDetails,
  postInteractions: RenderInteractions,
  postComments: RenderComments,
  commentForm: RenderCommentForm
}) =>
  job && (
    <Card mb={[3, 5, 5]} bg="light.0" position="relative" width={1 / 1}>
      <Header />
      <Flex flexDirection="column" p={3}>
        {UserDetails && (
          <UserDetails
            userHoldingImage={profilePlaceholder}
            interactions={RenderShare}
            publishedAt={publishedAt}
            user={user}
          />
        )}
        <Box pb={3}>
          <Box mb={3}>
            <Paragraph fontSize={3} color="dark.2" fontWeight="400" mb={2}>
              To apply via email:{" "}
              <Link to={`mailto:${job.email}`} external newTab>
                <Text color="primary.0">{job.email}</Text>
              </Link>
            </Paragraph>
            {job.link && (
              <Paragraph fontSize={3} color="dark.2" fontWeight="400" mb={0}>
                To apply via website:{" "}
                <Link to={job.link} external newTab>
                  <Text color="primary.0">{job.link}</Text>
                </Link>
              </Paragraph>
            )}
          </Box>
          <Summary job={job} />
        </Box>
        <Box>
          <CardContent title="Job Description" text={job.jobDescription} />
          <CardContent title="Responsibilities" text={job.responsibilities} />
          <CardContent title="Requirements" text={job.requirements} />
          {!!job.skills.length && (
            <Paragraph fontSize={3} color="dark.2" fontWeight="400" mb={2}>
              Skills
            </Paragraph>
          )}
          {createChecklist(job.skills)}
          {!!job.hobbies.length && (
            <>
              <Paragraph
                fontSize={3}
                color="dark.2"
                fontWeight="400"
                mt={3}
                mb={2}
              >
                Hobbies
              </Paragraph>
              <Paragraph textAlign="left" fontSize={3} color="dark.1" mb={3}>
                It is a bonus if you have the following hobbies
              </Paragraph>
            </>
          )}
          {createChecklist(job.hobbies)}
        </Box>
      </Flex>
      <Interactions>
        {RenderInteractions && <RenderInteractions />}
      </Interactions>
      <CommentWrapper>{RenderComments && <RenderComments />}</CommentWrapper>
      <RenderCommentForm />
    </Card>
  )

export default Details
