import { connect } from "react-redux"

import PersonalStatementComponent from "../components/PersonalStatement"
import { updateYourProfile } from "../store/yourProfile.actions"
import { getPersonalStatement } from "../store/yourProfile.selectors"

const mapState = state => ({
  personalStatement: getPersonalStatement(state)
})

const mapDispatch = dispatch => ({
  onUpdate: params => dispatch(updateYourProfile(params))
})

const PersonalStatement = connect(mapState, mapDispatch)(PersonalStatementComponent)

export default PersonalStatement
