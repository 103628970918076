import { connect } from "react-redux"
import ReportUserComponent from "../components/ReportUserComponent"
import { reportUser } from "../store/connect.actions"

const mapDispatch = dispatch => ({
  onReportUser: ({ id, reason }) => dispatch(reportUser(id, reason))
})

const mapState = (state, OwnProps) => OwnProps

const ReportUser = connect(mapState, mapDispatch)(ReportUserComponent)

export default ReportUser
