import { connect } from "react-redux"
import { submit } from "../store/survey.actions"

const mapDispatch = dispatch => ({
  onRetry: () => dispatch(submit())
})

const failureDialogContainer = connect(null, mapDispatch)

export default failureDialogContainer
