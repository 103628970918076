import { types } from "./advice.actions"

export const initialState = {
  isLoading: false,
  isPosting: false,
  hasCreated: false,
  regions: [],
  jobRoles: [],
  textSearch: ""
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE:
      return { ...state, isPosting: true }

    case types.CREATE_SUCCESS:
      return { ...state, isPosting: false, hasCreated: true }

    case types.CREATE_FAIL:
      return {
        ...state,
        isPosting: false
      }

    case types.RESET_CREATE:
      return {
        ...state,
        hasCreated: false,
        textSearch: "",
        regions: [],
        jobRoles: []
      }

    case types.LOAD_JOB_ROLES:
    case types.LOAD_REGIONS:
    case types.LOAD_JOB_ROLES_FAIL:
      return { ...state, isLoading: true }

    case types.LOAD_REGIONS_SUCCESS:
      return { ...state, regions: action.payload, isLoading: false }

    case types.LOAD_REGIONS_FAIL:
      return { ...state, regions: [], isLoading: false }

    case types.LOAD_JOB_ROLES_SUCCESS:
      return { ...state, jobRoles: action.payload, isLoading: false }

    case types.SET_JOB_ROLE_TEXT_SEARCH:
      return { ...state, textSearch: action.payload, jobRoles: [] }

    default:
      return state
  }
}
