import React from "react"
import styled from "styled-components"
import { Flex, Text, Box, TextField } from "@engaging-tech/components"

const HiddenRadioInput = styled.input`
  display: none;
`

const ClickableLabel = styled.label`
  cursor: pointer;
`

const DropdownWrapper = styled(Flex)`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  & > :last-child {
    padding-bottom: ${({ theme }) => theme.space[5]}px;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[0]}px) {
    & > :last-child {
      padding-bottom: ${({ theme }) => theme.space[3]}px;
    }
  }
`

const TextSearch = ({
  label,
  helperText,
  sourceList,
  searchText,
  setSearchText,
  setSelectedOption,
  handleSelect,
  onFinishWriting,
  showDropDownList
}) => {
  let timer = 0

  const checkOptions = e => {
    clearTimeout(timer)
    const inputText = e.target.value
    setSearchText(inputText)
  }

  const selectOption = e => {
    const option = JSON.parse(e.target.value)
    setSelectedOption(option)
  }

  const handleKeyUp = () => {
    timer = setTimeout(() => onFinishWriting(), 800)
  }

  return (
    <Box mb={3} position="relative">
      <TextField
        name="expertTypeId"
        id="expertTypeId"
        bg="dark.7"
        height={55}
        label={label}
        alignItems="center"
        justifyContent="space-between"
        px={3}
        isActive={showDropDownList}
        tabIndex={0}
        onChange={checkOptions}
        onKeyUp={handleKeyUp}
        width={1 / 1}
        position="relative"
        value={searchText}
        onFocus={handleSelect}
        noLabel
      />
      {helperText && (
        <Text mx={3} color="dark.2" fontSize={2}>
          {helperText}
        </Text>
      )}
      {showDropDownList && (
        <DropdownWrapper
          flexDirection="column"
          width={1 / 1}
          p={3}
          position="absolute"
          bg="light.0"
          zIndex="2000"
          elevation={2}
          borderRadius={4}
          maxHeight={200}
        >
          {sourceList.map(source => (
            <Box key={source.id} py={1}>
              <ClickableLabel>
                <HiddenRadioInput
                  type="radio"
                  name={source.name}
                  value={JSON.stringify(source)}
                  onChange={selectOption}
                />
                {source.name}
              </ClickableLabel>
            </Box>
          ))}
        </DropdownWrapper>
      )}
    </Box>
  )
}

export default TextSearch
