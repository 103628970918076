import { Button, Flex } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React from "react"
import styled from "styled-components"
import { getConfig } from "@engaging-tech/ssr-config"

const BorderedFlex = styled(Flex)`
  border-top: 2px solid ${({ theme }) => theme.colors.dark[5]};
`

const ComparisonFooter = () => {
  return (
    <BorderedFlex
      flexDirection={["column", "row", "row"]}
      width={1 / 1}
      mt={6}
      justifyContent={["center", "space-between", "space-between"]}
      alignItems={["center", "space-between", "space-between"]}
    >
      <Link to={getConfig().workL.public.index} external newTab>
        <Button width="168px" variant="outline.primary.0" elevation={0} mt={6}>
          Back to WorkL
        </Button>
      </Link>
    </BorderedFlex>
  )
}

export default ComparisonFooter
