import { connect } from "react-redux"
import { getCountries } from "@engaging-tech/frontend-utils"
import EventsAlertsOptionsComponent from "../components/EventsAlertsOptions"
import categories from "../../misc/lib/AllCategories"

const mapState = () => ({
  countries: getCountries(),
  categories
})

const EventsAlertsOptions = connect(mapState)(EventsAlertsOptionsComponent)

export default EventsAlertsOptions
