import React from "react"
import { H1,  Flex, Box } from "@engaging-tech/components"

const PremiumDialog = ({
  logo: Logo,
  title,
  children,
  headerBg = "transparent"
}) => (
  <Flex
    bg="light.0"
    borderRadius={4}
    elevation={12}
    mt={[6, 6, 1]}
    mb={[7, 6, 1]}
    width={[9 / 10, 1 / 2, 1]}
    maxWidth={560}
  >
    <Flex flexDirection="column" alignItems="center">
      {Logo && (
        <Flex width="auto" mt="48px" mb={2}>
          <Logo />
        </Flex>
      )}
      <H1
        fontSize={5}
        color="dark.1"
        fontWeight="700"
        mb={4}
        fontFamily="Tiempos"
        bg={headerBg}
        textAlign="center"
        py={3}
      >
        {title}
      </H1>
      <Box px={[4, 5]} pb={3}>
        {children}
      </Box>
    </Flex>
  </Flex>
)

export default PremiumDialog
