import React from "react"

import BaseLayout from "../../../../layouts/BaseLayout"
import Filters from "../../components/filters"
import { Content } from "./sideColumnLayout.styled"

const SideColumnLayout = ({ children, ...props }) => (
  <BaseLayout {...props}>
    <Filters />

    <Content width={1 / 1}>{children}</Content>
  </BaseLayout>
)

export default SideColumnLayout
