import { useLocation, useRouter } from "@engaging-tech/routing"
import React, { createContext, useContext, useReducer, useState } from "react"
import { useSelector } from "react-redux"

import { getStatus } from "../../store/survey.selectors"
import { toSubmission } from "../../utils/surveyRedirects"
import initialState from "./ini"
import reducer from "./reducer"

const SectionContext = createContext({
  ...initialState,
  onUpdateSectionsList: newList => ({ newList }),
  onUpdateCurrentSection: value => ({ value }),
  onClearQuestionHistory: () => ({}),
  onUpdateQuestionHistory: value => ({ value }),
  onRemoveLastItemQuestionHistory: () => ({}),
  onUpdateCurrentQuestion: value => ({ value }),
  onNextQuestion: skipTo => ({ skipTo }),
  onPreviousQuestion: () => ({})
})

export const SectionContextProvider = ({ children, data }) => {
  const router = useRouter()
  const { pathname } = useLocation()

  const [state, dispatch] = useReducer(reducer, initialState)

  const [questionJump, setQuestionJump] = useState(null)
  const [sectionJump, setSectionJump] = useState(null)

  const status = useSelector(getStatus)

  const onUpdateSectionsList = newList => {
    let sectionsList = []
    if (newList && newList.length > 0) {
      sectionsList = newList?.map(item => {
        if (item.name) {
          if (item.description && item.description.length > 0) {
            return {
              ...item,
              questions: item?.questions ? ["-1", ...item.questions] : ["-1"]
            }
          }

          return {
            ...item,
            questions: item?.questions ? [...item.questions] : []
          }
        }
        return item
      })
    }

    dispatch({
      type: "UPDATE_SECTIONS_LIST",
      payload: [...state.sectionsList, ...sectionsList]
    })
  }

  const onUpdateCurrentQuestion = questionIndex => {
    dispatch({ type: "QUESTION_CURRENT_UPDATE", payload: questionIndex })
  }

  const onUpdateCurrentSection = value => {
    if (
      (state.currentSection === state.sectionsList.length - 1 && value > state.currentSection) ||
      value === "end"
    ) {
      router.navigate(toSubmission(pathname))
    } else {
      const sectionData = state.sectionsList[value]

      if (
        // eslint-disable-next-line no-underscore-dangle
        sectionData?.__typename === "SurveyCodeCheck" &&
        (status === "draft" || status === "scheduled")
      ) {
        const nextSection = value < state.currentSection ? value - 1 : value + 1

        dispatch({
          type: "UPDATE_CURRENT_SECTION",
          payload: nextSection
        })
      } else {
        dispatch({ type: "UPDATE_CURRENT_SECTION", payload: value })
      }
    }
  }

  const onClearQuestionHistory = () => {
    dispatch({ type: "QUESTION_HISTORY_UPDATE", payload: [] })
  }

  const onUpdateQuestionHistory = value => {
    dispatch({
      type: "QUESTION_HISTORY_UPDATE",
      payload: [...state.questionsHistory, value]
    })
  }

  const onRemoveLastItemQuestionHistory = () => {
    const currentHistory = state.questionsHistory
    const newHistory = currentHistory.slice(0, -1)

    dispatch({ type: "QUESTION_HISTORY_UPDATE", payload: newHistory })
  }

  const onNextQuestion = branchingLogic => {
    /**
     * branchingLogic
     *
     * 1. check if the section didn't end
     * 2. check if there is any branching
     * 3. check if it is SECTION jump or QUESTIONS jump
     * 3.1. if it IS SECTION jump
     * 3.1.1. check if the SECTION index exists in the sections list
     * 3.1.2. check if there is any required questions between the actual QUESTION and the target SECTION
     * 3.1.3. if it IS required, store the jump
     * 3.1.4. if it ISN'T required, do the jump
     * 3.2. if it IS QUESTION jump
     * 3.2.1. check if the QUESTION index exists in the questions list
     * 3.2.2. check if there is any required questions between the actual QUESTION and the target QUESTION
     * 3.2.3. if it IS required, store the jump
     * 3.2.4. if it ISN'T required, do the jump
     *
     */

    const sectionData = state.sectionsList[state.currentSection]

    // 1. check if the section didn't end
    if (state.currentQuestion < sectionData.questions.length - 1) {
      onUpdateQuestionHistory(state.currentQuestion)

      // 2. check if there is any branching
      if (questionJump || sectionJump || branchingLogic) {
        let questionIndex = -1
        let sectionIndex = -1

        if (sectionJump) {
          sectionIndex =
            (typeof sectionJump.targetSection === "string"
              ? parseInt(sectionJump.targetSection, 10)
              : sectionJump.targetSection) + 1
        } else if (questionJump) {
          questionIndex = sectionData.questions.indexOf(questionJump.targetQuestion)
        } else if (branchingLogic.targetQuestion) {
          questionIndex = sectionData.questions.indexOf(branchingLogic.targetQuestion)
        } else if (branchingLogic.targetSection) {
          if (
            branchingLogic.targetSection === "end" &&
            state.currentSection === state.sectionsList.length - 1
          ) {
            onClearQuestionHistory()
            onUpdateCurrentSection("end")
          } else {
            sectionIndex =
              (typeof branchingLogic.targetSection === "string"
                ? parseInt(branchingLogic.targetSection, 10)
                : branchingLogic.targetSection) + 1
          }
        }

        // 3. check if the question index exists
        if (questionIndex && questionIndex > -1 && questionIndex !== state.currentQuestion) {
          let nextRequiredQuestionIndex = -1

          // 4. check if there is any required questions between the actual question and the target question
          sectionData.questions.forEach((item, idx) => {
            if (
              idx > state.currentQuestion &&
              idx < questionIndex &&
              item.required &&
              nextRequiredQuestionIndex === -1
            )
              nextRequiredQuestionIndex = idx
          })

          // 4.1 if it IS required, store the jump
          if (nextRequiredQuestionIndex && nextRequiredQuestionIndex !== -1) {
            if (!questionJump) setQuestionJump(branchingLogic)

            onUpdateCurrentQuestion(nextRequiredQuestionIndex)

            // 4.2 if it ISN'T required, do the jump
          } else {
            setQuestionJump(null)

            dispatch({
              type: "BRANCHING_LOGICS_UPDATE",
              payload: [...state.branchingLogics, questionJump || branchingLogic]
            })

            onUpdateCurrentQuestion(questionIndex)
          }
        } else if (sectionIndex && sectionIndex > -1 && sectionIndex !== state.currentSection) {
          let nextRequiredQuestionIndex = -1

          const sectionQuestions = data.template.questions.filter(i => sectionData.questions.includes(i.id))

          // 4. check if there is any required questions between the actual question and the target question
          sectionQuestions.forEach((item, idx) => {
            if (idx > state.currentQuestion && idx < questionIndex) {
              if (item.required && nextRequiredQuestionIndex === -1) {
                nextRequiredQuestionIndex = idx
              }
            }
          })

          // 4.1 if it IS required, store the jump
          if (nextRequiredQuestionIndex && nextRequiredQuestionIndex !== -1) {
            if (!sectionJump) {
              setSectionJump(branchingLogic)
            }

            onUpdateCurrentQuestion(nextRequiredQuestionIndex)

            // 4.2 if it ISN'T required, do the jump
          } else {
            setSectionJump(null)

            dispatch({
              type: "BRANCHING_LOGICS_UPDATE",
              payload: [...state.branchingLogics, questionJump || branchingLogic]
            })

            onUpdateCurrentSection(state.currentSection + 1)
          }
        } else {
          setQuestionJump(null)
          onUpdateCurrentQuestion(state.currentQuestion + 1)
        }
      } else {
        onUpdateCurrentQuestion(state.currentQuestion + 1)
      }
    } else {
      onClearQuestionHistory()
      onUpdateCurrentSection(state.currentSection + 1)
    }
  }

  const onPreviousQuestion = currentQuestion => {
    if (questionJump && questionJump.currentQuestion === currentQuestion) {
      setQuestionJump(null)
    }

    if (currentQuestion) {
      const newBranchingHistory = state.branchingLogics

      state.branchingLogics.forEach((i, index) => {
        if (i.targetQuestion === currentQuestion) newBranchingHistory.splice(index)
      })

      dispatch({
        type: "BRANCHING_LOGICS_UPDATE",
        payload: newBranchingHistory
      })
    }

    if (state.currentQuestion === 0) {
      onUpdateCurrentSection(state.currentSection - 1)
    } else {
      const lastHistoryItem = state.questionsHistory[state.questionsHistory.length - 1]
      onRemoveLastItemQuestionHistory()
      onUpdateCurrentQuestion(lastHistoryItem)
    }
  }

  return (
    <SectionContext.Provider
      value={{
        ...state,
        onUpdateSectionsList,
        onUpdateCurrentSection,
        onClearQuestionHistory,
        onUpdateQuestionHistory,
        onRemoveLastItemQuestionHistory,
        onUpdateCurrentQuestion,
        onNextQuestion,
        onPreviousQuestion
      }}
    >
      {children}
    </SectionContext.Provider>
  )
}

export const useSectionContext = () => useContext(SectionContext)
