import { connect } from "react-redux"
import { yourProfileSelectors } from "../../yourProfile"
import { LibraryBooksActions, LibraryBooksSelectors } from "../../gardners"

import { getIsLoading } from "../store/myLibrary.selectors"
import { downloadEBook } from "../store/myLibrary.actions"

import EBookList from "../components/Ebooks/EbookList"

const mapState = state => ({
  isLoading: getIsLoading(state),
  eBooks: LibraryBooksSelectors.getEBooks(state),
  userDetails: yourProfileSelectors.getProfile(state)
})

const mapDispatch = dispatch => ({
  onDownload: () => dispatch(downloadEBook()),
  onLoad: userId => dispatch(LibraryBooksActions.loadDigitalPurchases(userId))
})

const EBooks = connect(mapState, mapDispatch)(EBookList)

export default EBooks
