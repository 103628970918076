import {
  Box,
  Button,
  Flex,
  Paragraph,
  Text
} from "@engaging-tech/components"
import { usePaths, Link } from "@engaging-tech/routing"
import React from "react"
import styled from "styled-components"
import CircleIcon from "../../ui/components/CircleIcon"

const StyledText = styled(Text)`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.dark[1]};
  font-weight: 500px;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.secondary[3]};
  padding: 5px;
  border-radius: 10px 10px 0 0;
`

const Grid = ({ icon, title, paragraph }) => (
  <Box bg="secondary.4" width={[1 / 1, "244px"]} m={2} borderRadius={10}>
    <Flex flexDirection="column" alignItems="center">
      <StyledText>{title}</StyledText>
      <Flex flexDirection="row" alignItems="center" p={3}>
        <CircleIcon name={icon} mr={2} color="dark.2" />
        <Text fontSize={3} color="dark.2" fontWeight={300}>
          {paragraph}
        </Text>
      </Flex>
    </Flex>
  </Box>
)

const gridInfo = [
  {
    title: "Find Jobs",
    icon: "work",
    paragraph: "Find great job vacancies from happy companies"
  },
  {
    title: "Create CVs",
    icon: "research",
    paragraph: "Create CVs tailored towards specific job roles"
  },
  {
    title: "Happy Companies",
    icon: "sunny",
    paragraph: "Discover the happiest places to work, rated by employees"
  },
  {
    title: "Happy Industries",
    icon: "industry",
    paragraph: "Discover what the happiest industries are around the world"
  }
]

const Thankyou = ({ redirectTo, isFromPremium }) => {
  const paths = usePaths()

  return (
    <Flex p={3} justifyContent="center" alignItems="center" mb={[6, 0]}>
      <Flex
        maxWidth={560}
        elevation={12}
        p={3}
        bg="light.0"
        borderRadius={3}
        flexDirection="column"
        alignItems="center"
      >
        <Flex flexDirection="column" alignItems="center">
          <Text
            color="dark.2"
            fontSize={5}
            fontWeight={600}
            textAlign="center"
            py={3}
          >
            Thank you for signing up to WorkL!
          </Text>
          <Paragraph textAlign="center" fontSize={[4, 3, 3]} color="dark.2">
            Here are some ways you could get started today:
          </Paragraph>
        </Flex>
        <Flex flexWrap="wrap" py={3} justifyContent="center" width={1 / 1}>
          {gridInfo.map(point => (
            <Grid
              key={point.title}
              title={point.title}
              icon={point.icon}
              paragraph={point.paragraph}
            />
          ))}
        </Flex>
        {isFromPremium ? (
          <Link to={paths.getPremium.subscribeToPremium}>
            <Button variant="primary.0" px={5}>
              Continue to Get Premium
            </Button>
          </Link>
        ) : (
          <Link to={redirectTo || paths.home}>
            <Button variant="secondary.0" px={5}>
              <Text color="dark.2">Get Started</Text>
            </Button>
          </Link>
        )}
      </Flex>
    </Flex>
  )
}

export default Thankyou
