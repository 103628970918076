import { call, put, takeLatest } from "redux-saga/effects"

import { parseParagraphs } from "../../userGeneratedContent/services/parse.service"
import { uploadHubAttachments } from "../../userGeneratedContent/services/upload.service"
import {
  comment,
  endorse,
  load,
  loadSelected,
  loadUsersAdvice,
  post,
  reply,
  reportPost
} from "../services/globalHub.service"
import * as Actions from "./globalHub.actions"

export function* loadSaga(action) {
  try {
    let res

    if (action.payload?.type) {
      res = yield call(load, { place: action.payload?.place, type: action.payload.type })
    } else {
      res = yield call(load, { place: action.payload?.place, type: "" })
    }

    yield put(Actions.loadSuccess(res.data.allHubPosts))
  } catch (error) {
    yield put(Actions.loadFail(error))
  }
}

export function* loadMoreSaga(action) {
  try {
    const res = yield action.payload.postType
      ? call(load, { type: action.payload.postType, from: action.payload.from, place: action.payload?.place })
      : call(load, { from: action.payload.from, place: action.payload?.place })

    yield put(Actions.loadMoreSuccess(res.data.allHubPosts))
  } catch (error) {
    yield put(Actions.loadMoreFail(error))
  }
}

export function* postSaga(action) {
  try {
    const { data, place } = action.payload

    const attachments = yield data.attachments.length > 0
      ? call(uploadHubAttachments, data.attachments)
      : data.attachments

    const sanitisedPost = yield {
      ...data,
      content: parseParagraphs(data.content),
      attachments
    }

    const res = yield call(post, { newPost: sanitisedPost, place })

    yield put(Actions.postSuccess(res.data.createPost))
    yield put(Actions.load({ place }))
  } catch {
    yield put(Actions.postFail())
  }
}

export function* endorseSaga(action) {
  try {
    const res = yield call(endorse, action.payload)

    const completedEndorsement = {
      id: res.data.endorsePost.id,
      user: action.payload.user
    }

    yield action.payload.isEndorsing
      ? put(Actions.endorseSuccess(completedEndorsement))
      : put(Actions.unendorseSuccess(completedEndorsement))
  } catch {
    yield put(Actions.endorseFail())
  }
}

export function* commentSaga(action) {
  try {
    const sanitised = yield call(parseParagraphs, action.payload.content)

    const res = yield call(comment, {
      ...action.payload,
      content: sanitised
    })

    yield put(
      Actions.commentSuccess({
        ...res.data.createComment,
        postId: action.payload.postId
      })
    )
  } catch {
    yield put(Actions.commentFail())
  }
}

export function* replySaga(action) {
  try {
    const sanitised = yield call(parseParagraphs, action.payload.content)

    const res = yield call(reply, {
      ...action.payload,
      content: sanitised
    })

    yield put(
      Actions.replySuccess({
        ...res.data.createComment,
        postId: action.payload.postId
      })
    )
  } catch {
    yield put(Actions.replyFail())
  }
}

export function* selectSaga(action) {
  try {
    const res = yield call(loadSelected, action.payload)

    yield put(Actions.selectSuccess(res.data.allHubPosts[0]))
  } catch (error) {
    yield put(Actions.selectFail({ ...error, generalMessage: "Something went wrong" }))
  }
}

export function* loadUsersAdviceSaga(action) {
  try {
    const res = yield call(loadUsersAdvice, action.payload)
    yield put(Actions.loadUsersAdvicePostsSuccess(res.data.allHubPosts))
  } catch {
    yield put(Actions.loadUsersAdvicePostsFail())
  }
}

export function* reportPostSaga(action) {
  try {
    const reportedPost = yield call(reportPost, {
      id: action.payload.id,
      reason: action.payload.reason
    })
    yield put(Actions.reportPostSuccess(reportedPost))
  } catch {
    yield put(Actions.reportPostFail())
  }
}

export function* saga() {
  yield takeLatest(Actions.types.LOAD, loadSaga)
  yield takeLatest(Actions.types.LOAD_MORE, loadMoreSaga)
  yield takeLatest(Actions.types.POST, postSaga)
  yield takeLatest(Actions.types.ENDORSE, endorseSaga)
  yield takeLatest(Actions.types.COMMENT, commentSaga)
  yield takeLatest(Actions.types.REPLY, replySaga)
  yield takeLatest(Actions.types.SELECT, selectSaga)
  yield takeLatest(Actions.types.LOAD_USERS_ADVICE_POSTS, loadUsersAdviceSaga)
  yield takeLatest(Actions.types.REPORT_POST, reportPostSaga)
}
