import React from "react"
import { Box, Paragraph, Text } from "@engaging-tech/components"

const Application = ({ email, link }) => (
  <Box p={3}>
    {email && (
      <Paragraph fontSize={3} color="dark.2" fontWeight="400">
        To apply via email:{" "}
        <a href={`mailto:${email}`} target="_blank noopener noreferrer">
          <Text fontSize={3} color="primary.0">
            {email}
          </Text>
        </a>
      </Paragraph>
    )}
    {link && (
      <Paragraph fontSize={3} color="dark.2" fontWeight="400" mb={0}>
        To apply via website:{" "}
        <a href={link} target="_blank noopener noreferrer">
          <Text fontSize={3} color="primary.0">
            {link}
          </Text>
        </a>
      </Paragraph>
    )}
  </Box>
)

export default Application
