import React from "react"

import ResetPasswordForm from "../forms/resetPassword"

/**
 * The "Reset Password" dialog box.
 * @param {React.Component} siteLogo The brand logo to show within the dialog.
 * @param {string} redirectTo The location to redirect a user to after they submit the reset password form.
 * @param {string} cancelRoute The route to redirect a user to if they click "Cancel".
 */
export default function ResetPassword({ siteLogo, cancelRoute, redirectTo }) {
  return <ResetPasswordForm siteLogo={siteLogo} cancelRoute={cancelRoute} redirectTo={redirectTo} />
}
