import { pipe } from "@engaging-tech/frontend-utils"

/**
 * Regular expression to match text within the input to be removed. This
 * includes HTML break tags (<br>) and excessive new line characters.
 */
const SANITISER = /[\n]+|<br\s*\/?>/g

/**
 * Removes excess line breaks and <br> tags from the text input.
 * @param {string} content The text input to be converted.
 */
const sanitiseContent = content => content.replace(SANITISER, "\n")

/**
 * Splits text input by new line characters. After sanitisation, there
 * *should* be a single occurance of a new line character to act as a
 * paragraph delimiter.
 * @param {string} content The text input to be split.
 */
const splitContent = content => content.split("\n")

/**
 * Maps the text input into paragraph objects.
 * @param {string[]} content The text input, split by new lines.
 */
const toParagraphs = content =>
  content.map(text => ({
    type: "paragraph",
    body: text
  }))

/**
 * Converts text input (normally submitted through a TextArea component) into
 * structured paragraph templates. The templates are stored on the back-end, and used
 * across web and mobile apps.
 * @param {string} content The text input to be converted.
 */
export const parseParagraphs = content =>
  pipe(sanitiseContent, splitContent, toParagraphs)(content)

/**
 * Converts form input from any of the "Publish..." forms in the Business Library into
 * a structured content template. The template returned by this function is *incomplete*
 * and needs to be fed into a "to..." function (e.g. toPodcast, toResearch) to be completed.
 * @param {*} param0 The submitted "Publish ..." form.
 */
const parseFormInput = ({
  title,
  summary,
  seriesId,
  type,
  categories,
  content,
  ...attachments
}) => ({
  title,
  summary,
  type,
  content,
  seriesId: seriesId || null,
  categories,
  coverImage: {
    source: attachments.coverImageUrl || "",
    caption: ""
  },
  ...attachments
})

/**
 *
 * @param {*} param0 The content template generated from the "Publish Article" form.
 */
const toArticle = ({ coverImageUrl, attachments, content, ...formInput }) => ({
  ...formInput,
  content: parseParagraphs(content)
})

/**
 * Appends the URL of the uploaded podcast to the content template.
 * @param {*} param0 The content template generated from the "Publish Podcst" form.
 */
const toPodcast = ({
  podcastUrl,
  coverImageUrl,
  attachments,
  ...formInput
}) => ({
  ...formInput,
  content: [
    {
      type: "audio",
      source: podcastUrl
    }
  ]
})

/**
 * Appends the URL of the uploaded lecture to the content template.
 * @param {*} param0 The content template generated from the "Publish Podcst" form.
 */
const toLecture = ({
  lectureUrl,
  coverImageUrl,
  attachments,
  ...formInput
}) => ({
  ...formInput,
  content: [
    {
      type: "video",
      source: lectureUrl
    }
  ]
})

/**
 * Appends the URL of the uploaded research document to the content template.
 * @param {*} param0 The content template generated from the "Publish Research" form.
 */
const toResearch = ({
  documentUrl,
  coverImageUrl,
  attachments,
  ...formInput
}) => ({
  ...formInput,
  content: [
    {
      type: "document",
      source: documentUrl
    }
  ]
})

export const parseArticle = articleFormInput =>
  pipe(parseFormInput, toArticle)(articleFormInput)

/**
 * Converts the form input from the "Publish Podcast" form into a structured content template.
 * @param {*} param0 The completed "Publish Podcast" form, with the URLs for the cover
 * image and podcast audio appended.
 */
export const parsePodcast = podcastFormInput =>
  pipe(parseFormInput, toPodcast)(podcastFormInput)

/**
 * Converts the form input from the "Publish Lecture" form into a structured content template.
 * @param {*} param0 The completed "Publish Lecture" form, with the URLs for the cover
 * image and lecture video appended.
 */
export const parseLecture = lectureFormInput =>
  pipe(parseFormInput, toLecture)(lectureFormInput)

/**
 * Converts the input from the "Publish Research" form into a structured content template.
 * @param {*} param0 The completed "Publish Research" form, with the URLS for the cover
 * image and research document appended.
 */
export const parseResearch = researchFormInput =>
  pipe(parseFormInput, toResearch)(researchFormInput)

export default parseParagraphs
