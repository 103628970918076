import { connect } from "react-redux"
import ManageProfileComponent from "../../components/ProfileSettingsModal/ManageProfile"
import {
  updateYourProfile,
  setShowModal
} from "../../../../yourProfile/store/yourProfile.actions"
import { getProfile } from "../../../../yourProfile/store/yourProfile.selectors"

const mapState = state => ({
  profile: getProfile(state)
})

const mapDispatch = dispatch => ({
  submitProfile: data => dispatch(updateYourProfile(data)),
  setVisibility: type => dispatch(setShowModal(type))
})

const ManageProfile = connect(mapState, mapDispatch)(ManageProfileComponent)

export default ManageProfile
