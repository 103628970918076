import { CustomHooks, Flex, Icon, MotionFlex, Text } from "@engaging-tech/components"
import React, { useRef } from "react"
import { SizeMe } from "react-sizeme"

import usePercentageGraphicAnimation from "../../../../../../hooks/usePercentageGraphicAnimation"

const arrowSize = 40

const BarShape = ({ containerDimensions }) => {
  return (
    <Flex bg="#EF464A" width="100%" height={26} borderRadius={13} justifyContent="center">
      <Flex bg="#FFB547" height="100%" width={(containerDimensions.width / 20) * 10} justifyContent="center">
        <Flex
          bg="#0DC187"
          height="100%"
          width={(containerDimensions.width / 20) * 4}
          justifyContent="center"
        />
      </Flex>
    </Flex>
  )
}
const lineWidth = 2
const lineHeight = 8
const BarScaleLabel = ({ label }) => {
  return (
    <Flex
      bg="dark.3"
      height={lineHeight}
      width={`${lineWidth}px`}
      position="relative"
      style={{ transform: `translate(0px, -${lineHeight}px)` }}
    >
      <Text
        position="absolute"
        width={40}
        height={20}
        color="dark.3"
        noSelect
        fontSize="14px"
        style={{ transform: "translate(-20px, 10px)", textAlign: "center" }}
      >
        {label}
      </Text>
    </Flex>
  )
}

const BarScale = ({ containerDimensions }) => {
  return (
    <Flex height={lineHeight} width="100%">
      <Flex height={`${lineWidth}px`} width="100%" bg="dark.3" mt="11px" justifyContent="space-between">
        <BarScaleLabel label="-100" />
        <Flex
          height="100%"
          width={(containerDimensions.width / 20) * 10 + lineWidth}
          justifyContent="space-between"
        >
          <BarScaleLabel label="-50" />
          <Flex
            height="100%"
            width={(containerDimensions.width / 20) * 4 + lineWidth}
            justifyContent="space-between"
          >
            <BarScaleLabel label="-20" />
            <BarScaleLabel label="20" />
          </Flex>
          <BarScaleLabel label="50" />
        </Flex>
        <BarScaleLabel label="100" />
      </Flex>
    </Flex>
  )
}

const getColour = score => {
  if (score > 50) {
    return "#EF464A"
  }
  if (score > 20) {
    return "#FFB547"
  }

  return "#0DC187"
}

const BarArrow = ({
  indicatorVisible,
  hideArrowLabels,
  score,
  label,
  disableAnimation,
  containerDimensions,
  index
}) => {
  const middlePoint = containerDimensions.width / 2 - arrowSize / 2
  const calculateArrowPosition = () => {
    return middlePoint + (containerDimensions.width / 200) * score
  }

  return (
    <Flex style={{ position: "relative" }} mt={index * 1 + 16}>
      <MotionFlex
        style={{ position: "absolute" }}
        initial={{
          left: disableAnimation || indicatorVisible ? calculateArrowPosition() : middlePoint
        }}
        animate={{
          left: disableAnimation || indicatorVisible ? calculateArrowPosition() : middlePoint
        }}
        transition={{ duration: disableAnimation ? 0 : 0.7 }}
        width={arrowSize}
        height={arrowSize}
        alignItems="center"
      >
        <Icon
          name="keyboard_arrow_up"
          size={arrowSize}
          color={(() => {
            if (label === "global") return "#A9C7F0"
            if (label === "industry") return "#BFA9F0"
            return getColour(Math.abs(score))
          })()}
        />
        {!hideArrowLabels && (
          <SizeMe>
            {({ size }) => (
              <MotionFlex position="absolute" left={score > 0 ? -size.width : arrowSize} width="auto">
                <Text
                  width="auto"
                  style={{
                    textTransform: "capitalize",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                  }}
                  color="dark.2"
                  maxWidth={130}
                  textSize={14}
                >
                  {label}
                </Text>
              </MotionFlex>
            )}
          </SizeMe>
        )}
      </MotionFlex>
    </Flex>
  )
}

const BarIndicatorInner = ({
  containerDimensions,
  dataSet,
  containerRef,
  disableAnimation,
  hideArrowLabels,
  noData
}) => {
  const mainScore = dataSet ? dataSet[Object.keys(dataSet)[0]] : 0

  const animatedValue = usePercentageGraphicAnimation({
    scoreToAnimateTo: mainScore,
    delay: 100,
    containerRef
  })
  const indicatorVisible = CustomHooks.useElementVisibleTrigger({
    componentRef: containerRef,
    delay: 100
  })

  return (
    <>
      {noData ? (
        <Text color="dark.1" fontSize={6} width="100%" mb={3} style={{ textAlign: "center" }}>
          No Data
        </Text>
      ) : (
        <Text
          fontSize={55}
          width="100%"
          style={{ textAlign: "center" }}
          color={getColour(Math.abs(disableAnimation ? mainScore : animatedValue))}
        >
          {disableAnimation ? Math.round(mainScore) : Math.round(animatedValue)}
        </Text>
      )}
      <Flex width="100%" justifyContent="space-between" mb={1}>
        <Text width="auto" fontSize="12px" color="dark.2">
          Majority
        </Text>
        <Text width="auto" fontSize="12px" color="dark.2">
          Minority
        </Text>
      </Flex>
      <BarShape containerDimensions={containerDimensions} />
      <BarScale containerDimensions={containerDimensions} />
      {!noData &&
        Object.keys(dataSet).map((key, index) => (
          <BarArrow
            score={dataSet[key]}
            label={key}
            key={key}
            index={index}
            hideArrowLabels={hideArrowLabels}
            containerDimensions={containerDimensions}
            indicatorVisible={indicatorVisible}
          />
        ))}
    </>
  )
}

const BarIndicator = ({ dataSet, disableAnimation, hideArrowLabels }) => {
  const containerRef = useRef()

  return (
    <SizeMe>
      {({ size }) => (
        <Flex
          width="calc(100% - 40px)"
          flexDirection="column"
          ref={containerRef}
          mb={`${dataSet && Object.keys(dataSet).length * 5 + 25}px`}
        >
          <BarIndicatorInner
            hideArrowLabels={hideArrowLabels}
            containerDimensions={size}
            dataSet={dataSet}
            noData={!dataSet}
            disableAnimation={disableAnimation}
            containerRef={containerRef}
          />
        </Flex>
      )}
    </SizeMe>
  )
}

export default BarIndicator
