import { useLocation } from "@engaging-tech/routing"
import { useEffect } from "react"
import { connect } from "react-redux"

import { cvLibraryActions } from ".."

const LoaderComponent = ({ onLoad }) => {
  const location = useLocation()

  const isInAppView = location.pathname.includes("in-app-view")

  useEffect(() => {
    onLoad({ place: isInAppView ? "in-app-view" : "" })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onLoad])
  return null
}

const mapDispatch = dispatch => ({
  onLoad: () => dispatch(cvLibraryActions.loadCVs())
})

const Loader = connect(null, mapDispatch)(LoaderComponent)

export default Loader
