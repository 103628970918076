import React from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
import { usePaths, useRouter } from "@engaging-tech/routing"
import { Box } from "@engaging-tech/components"
import PublishPromotions from "./PublishPromotions"
import { getIsPremium } from "../../yourProfile/store/yourProfile.selectors"

import PromotionCard from "../../ui/components/PromotionCard"

const Wrapper = styled(Box)`
  & > div {
    margin-bottom: ${({ theme }) => theme.space[3]}px;
  }
`

const PremiumPromotion = ({ content }) => {
  const paths = usePaths()

  const isPremium = useSelector(getIsPremium)

  if (isPremium) {
    return null
  }
  return (
    <PromotionCard
      title="Get 20% off with Premium"
      premiumBanner
      content={content}
      action={{
        description: "Sign up to premium",
        linkTo: paths.getPremium.index

      }}
    />
  )
}

const SideBar = ({ materialType, children }) => {
  const determineSecondBox = {
    article: <PublishPromotions.Article />,
    lecture: <PublishPromotions.Lecture />,
    podcast: <PublishPromotions.Podcast />,
    research: <PublishPromotions.Research />,
    book: (
      <PremiumPromotion
        content={[
          "20% off books, ebooks and audiobooks with premium",
          "Get matched with the perfect job for you",
          "Coming soon - get notifications about events"
        ]}
      />
    )
  }
  return (
    <Wrapper width={1 / 1} id="business-library-sidebar">
      {determineSecondBox[materialType]}
      {children}
    </Wrapper>
  )
}

export default SideBar
