import { gql } from "../../../lib/Apollo"

export const LOAD = gql`
  query {
    getMyAlerts {
      events {
        id
        frequency
        location {
          name
          countryId
          country {
            shortName
            name
          }
          point {
            coordinates
          }
        }
        category {
          id
        }
        radius {
          measurement
          value
        }
      }
      jobs {
        id
        frequency
        industry {
          id
        }
        location {
          name
          countryId
          country {
            name
            shortName
          }
          point {
            coordinates
          }
        }
        jobRole
        jobType
        radius {
          measurement
          value
        }
      }
      materials {
        id
        frequency
        materialType
        category {
          id
          name
        }
      }
    }
  }
`

export const CREATE_JOB_ALERT = gql`
  mutation ($params: JobAlertInput!) {
    createJobAlert(jobAlert: $params) {
      id
      frequency
      industry {
        id
      }
      location {
        name
        countryId
        country {
          shortName
          name
        }
        point {
          coordinates
        }
      }
      radius {
        measurement
        value
      }
      salary
      jobRole
      jobType
    }
  }
`

export const UPDATE_JOB_ALERT = gql`
  mutation ($jobAlert: JobAlertInput!) {
    updateJobAlert(jobAlert: $jobAlert) {
      id
      frequency
      industry {
        id
        name
      }
      location {
        name
        countryId
        country {
          shortName
          name
        }
        point {
          coordinates
        }
      }
      radius {
        measurement
        value
      }
      salary
      jobRole
      jobType
    }
  }
`

export const DELETE_ALERT = gql`
  mutation ($alertId: ID!) {
    deleteUserAlert(alertId: $alertId)
  }
`

export const CREATE_EVENT_ALERT = gql`
  mutation ($eventAlert: EventAlertInput!) {
    createEventAlert(eventAlert: $eventAlert) {
      id
      frequency
      location {
        name
        countryId
        country {
          shortName
          name
        }
        point {
          coordinates
        }
      }
      category {
        id
        name
      }
    }
  }
`

export const UPDATE_EVENT_ALERT = gql`
  mutation ($eventAlert: EventAlertInput!) {
    updateEventAlert(eventAlert: $eventAlert) {
      id
      frequency
      location {
        name
        countryId
        country {
          shortName
          name
        }
        point {
          coordinates
        }
      }
    }
  }
`

export const CREATE_MATERIAL_ALERT = gql`
  mutation ($materialAlert: MaterialAlertInput!) {
    createMaterialAlert(materialAlert: $materialAlert) {
      id
      frequency
      materialType
      category {
        id
        name
      }
    }
  }
`

export const UPDATE_MATERIAL_ALERT = gql`
  mutation ($materialAlert: MaterialAlertInput!) {
    updateMaterialAlert(materialAlert: $materialAlert) {
      id
      frequency
      materialType
      category {
        id
        name
      }
    }
  }
`
