import { Button, Flex, Text } from "@engaging-tech/components"
import { AnimatePresence } from "framer-motion"
import React, { useState } from "react"
import { useSelector } from "react-redux"

import { businessProfilePageSelectors } from "../../.."
import { getLabelData } from "../../../../../lib/utils"
import PercentageGraphic from "../../../../ui/components/PercentageGraphic"
import ModalChartViewer from "./common/modal-chart-viewer"
import IndustryAndGlobalScoreBox from "./industry-and-global-score-box"
import WidgetContainer from "./widget-container"

const FlightRiskIndicator = () => {
  const [showModal, setShowModal] = useState(false)

  const dataSet = useSelector(businessProfilePageSelectors.getIndicatorFlightRiskDataSet)
  const chartData = useSelector(state =>
    businessProfilePageSelectors.getIndicatorChartData(state, "flightRisk")
  )

  return (
    <>
      <WidgetContainer mb={4} width={["100%", "48%"]} height={["auto", "500px"]} title="Flight Risk">
        <Flex width={["60%", "70%"]} height="auto" mt={[3, 0]}>
          <PercentageGraphic
            graphicWidth="auto"
            height="auto"
            reverseColor
            score={dataSet?.score}
            noData={typeof (dataSet ? dataSet.score : null) !== "number"}
            noScoreLabel
            animate
          />
        </Flex>

        <Text
          color={getLabelData(100 - (dataSet?.score || 0)).color}
          width="100%"
          textAlign="center"
          mb={3}
          height={42}
        >
          {typeof (dataSet ? dataSet.score : null) === "number"
            ? `${Math.round(dataSet.score)}% of employees have a high chance of leaving in the next 9 months`
            : ""}
        </Text>
        <IndustryAndGlobalScoreBox
          globalScore={dataSet?.global ? Math.round(dataSet.global) : null}
          industryScore={dataSet?.industry ? Math.round(dataSet.industry) : null}
          mb={3}
          mt={[3, 0]}
        />
        <Button variant="primary.0" onClick={() => setShowModal(!showModal)} height={32}>
          Find out more
        </Button>
      </WidgetContainer>
      <AnimatePresence>
        {showModal && (
          <ModalChartViewer
            onToggle={() => setShowModal(!showModal)}
            chartData={chartData}
            graphDescription="The Flight Risk Indicator is the percentage of employees who scored below 60% in an averaged score across 4 questions."
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default FlightRiskIndicator
