import {
  desktopColumns,
  tabletColumns,
  mobileColumns,
  defaultWidgetHeight,
  staticWidgetsDefault,
  staticWidgetsProfileHighlight
} from "../lib/gridSetup"
import widgetDictonary from "../widgets/lib/widgetDictonary"

import { findWidgetWithinDictonaryById } from "../widgets/helpers/widgetHelpers"

// Gets next available row along Y axis of widget grid
const getNextRow = (layoutDevice, widgetLayout) => {
  return widgetLayout[layoutDevice].reduce((nextRowIndex, layoutObject) => {
    if (layoutObject.y + layoutObject.h > nextRowIndex) {
      return layoutObject.y + layoutObject.h
    }
    return nextRowIndex
  }, 0)
}

// The layout manager is used by the develop career reducer to handle adding/removing widgets to the device layouts

const layoutManager = {
  remove: (widgetId, widgetLayout) => {
    // Removes a widget from all device layouts, taking in the widget id (i)
    return {
      mobile: widgetLayout.mobile.filter(
        layoutObject => layoutObject.i !== widgetId
      ),
      tablet: widgetLayout.tablet.filter(
        layoutObject => layoutObject.i !== widgetId
      ),
      desktop: widgetLayout.desktop.filter(
        layoutObject => layoutObject.i !== widgetId
      )
    }
  },
  add: (widget, widgetLayout) => {
    // Check widget ID is available within widgetDictionary, if not, do not add
    if (
      !findWidgetWithinDictonaryById(
        typeof widget === "string" ? widget : widget.desktop.id
      )
    ) {
      console.log(
        `Error: Career Developer widget ID: ${
          widget === "string" ? widget : widget.desktop.id
        } unable to be found`
      )
      return widgetLayout
    }

    // Add a widget to all device layouts. Can take in a widget name (i) string or a layout object
    const columnMapper = device => {
      if (device === "mobile") {
        return mobileColumns
      }
      return device === "desktop" ? desktopColumns : tabletColumns
    }

    const layoutTemplate = device => {
      const widgetParameters = () => {
        const matchingWidget = findWidgetWithinDictonaryById(
          typeof widget === "string" ? widget : widget[device].id
        )
        return matchingWidget && matchingWidget.parameters
          ? matchingWidget.parameters
          : {}
      }

      const widgetDefaults = () => {
        const matchingWidget = findWidgetWithinDictonaryById(
          typeof widget === "string" ? widget : widget[device].id
        )
        return matchingWidget && matchingWidget.defaults
          ? matchingWidget.defaults
          : {}
      }

      // If string passed in, automatically place widget to bottom of grid (full width and default height)
      if (typeof widget === "string") {
        return {
          i: widget,
          x: 0,
          y: getNextRow(device, widgetLayout),
          w: columnMapper(device),
          h: defaultWidgetHeight,
          ...widgetParameters(),
          ...widgetDefaults()
        }
      }

      const { id, x, y, w, h } = widget[device]

      // If widget layout object passed in, check all layout properties are correct, if so inject into grid at desired location
      if (
        typeof id === "string" &&
        typeof x === "number" &&
        typeof y === "number" &&
        typeof w === "number" &&
        typeof h === "number"
      ) {
        return {
          i: id,
          x,
          y,
          w,
          h,
          ...widgetParameters()
        }
      }

      // If not all layout properties ate correct, add widget to bottom of grid (full width and default height)
      return {
        i: id,
        x: 0,
        y: getNextRow(device, widgetLayout),
        w: columnMapper(device),
        h: defaultWidgetHeight,
        ...widgetParameters(),
        ...widgetDefaults()
      }
    }

    //Checks if widget already exists within layout, if so, replaces it
    const duplicateReducer = (layout, widgetToAdd) => {
      let foundDuplicate = false
      const reducedLayout = layout.map(w => {
        if (w.i === widgetToAdd.i) {
          foundDuplicate = true
          return widgetToAdd
        }
        return w
      })
      if (foundDuplicate) {
        return reducedLayout
      }
      return [...reducedLayout, widgetToAdd]
    }

    return {
      mobile: duplicateReducer(widgetLayout.mobile, layoutTemplate("mobile")),
      tablet: duplicateReducer(widgetLayout.tablet, layoutTemplate("tablet")),
      desktop: duplicateReducer(widgetLayout.desktop, layoutTemplate("desktop"))
    }
  },
  mapNewLayout: (newLayout, profileComplete) => {
    // Takes in layout from backend, adds static widgets to all device layouts and maps optional widget parameters to each widget passed in from backend.
    const emptyLayout = {
      tablet: [],
      desktop: [],
      mobile: []
    }
    const staticLayoutToUse = profileComplete
      ? staticWidgetsDefault
      : staticWidgetsProfileHighlight

    const staticWidgetLayout = staticLayoutToUse.reduce(
      (layout, staticWidget) => layoutManager.add(staticWidget, layout),
      emptyLayout
    )

    const findWidget = (widgetId, widgetArray) =>
      widgetArray.find(({ id }) => id === widgetId)

    // Adds widgets from backend to static layout
    const injectedWidgetLayout = newLayout.desktop.reduce(
      (layout, widgetLayoutObject) => {
        // Find layout for each device type, then add them to new layout

        // Remove disabled and not found widgets
        const matchingWidget = findWidgetWithinDictonaryById(
          widgetLayoutObject.id
        )
        if (!matchingWidget || matchingWidget.disabled) {
          return layout
        }

        const widgetLayoutToAdd = {
          mobile: findWidget(widgetLayoutObject.id, newLayout.mobile),
          tablet: findWidget(widgetLayoutObject.id, newLayout.tablet),
          desktop: widgetLayoutObject
        }

        // This checks if a widget within the desktop layout is found in tablet and mobile, if layout is not in sync, do not add new widget
        if (
          !widgetLayoutToAdd.mobile ||
          !widgetLayoutToAdd.tablet ||
          !widgetLayoutToAdd.desktop
        ) {
          console.log(
            "Error: Career Developer grid layout does not align across all devices"
          )
          return layout
        }

        return layoutManager.add(widgetLayoutToAdd, layout)
      },
      staticWidgetLayout
    )

    return injectedWidgetLayout
  },
  prepareLayoutForBackend: layout => {
    // Strips away unnecessary keys from layout object to prepare it for backend and removes static widgets
    const removableWidgetIds = Object.keys(widgetDictonary).reduce(
      (collector, key) => {
        if (!widgetDictonary[key].parameters?.static) {
          return [...collector, widgetDictonary[key].id]
        }
        return collector
      },
      []
    )
    const removeStaticWidgets = deviceLayout => {
      return deviceLayout.filter(widget =>
        removableWidgetIds.includes(widget.i)
      )
    }

    const reduceLayout = deviceLay =>
      deviceLay.map(({ i, x, y, h, w }) => ({ id: i, x, y, h, w }))

    return {
      mobile: reduceLayout(removeStaticWidgets(layout.mobile)),
      tablet: reduceLayout(removeStaticWidgets(layout.tablet)),
      desktop: reduceLayout(removeStaticWidgets(layout.desktop))
    }
  }
}

export default layoutManager
