import { Button } from "@engaging-tech/components"
import React from "react"

const determineUrl = stageIndex => {
  // scottish personalised action plans
  if (stageIndex === 0)
    return "https://media.engaging.works/production/media/business/materials/files/1_+Reward+and+Recognition+_+Scottish+Parliament+_+Individual+Action+Planning.pdf"
  if (stageIndex === 1)
    return "https://media.engaging.works/production/media/business/materials/files/2_+Information+Sharing++_+Scottish+Parliament++_+Individual+Action+Planning.pdf"
  if (stageIndex === 2)
    return "https://media.engaging.works/production/media/business/materials/files/3_+Empowerment++_+Scottish+Parliament++_+Individual+Action+Planning+.pdf"
  if (stageIndex === 3)
    return "https://media.engaging.works/production/media/business/materials/files/4_+Wellbeing+_+Scottish+Parliament++_+Individual+Action+Planning+.pdf"
  if (stageIndex === 4)
    return "https://media.engaging.works/production/media/business/materials/files/5_+Instilling+Pride+_+Scottish+Parliament++_+Individual+Action+Planning+.pdf"
  if (stageIndex === 5)
    return "https://media.engaging.works/production/media/business/materials/files/6_+Job+Satisfaction+_+Scottish+Parliament++_+Individual+Action+Planning+.pdf"
  return null
}

const determineEnglishUrl = stageIndex => {
  // senedd (welsh parliament) personlaised action plans in english
  if (stageIndex === 0)
    return "https://media.engaging.works/production/media/business/materials/files/Senedd-reward-and-recognition-english-action-plan.pdf"
  if (stageIndex === 1)
    return "https://media.engaging.works/production/media/business/materials/files/Senedd-information-sharing-english-action-plan.pdf"
  if (stageIndex === 2)
    return "https://media.engaging.works/production/media/business/materials/files/Senedd-empowerment-english-action-plan.pdf"
  if (stageIndex === 3)
    return "https://media.engaging.works/production/media/business/materials/files/Senedd-wellbeing-english-action-plan.pdf"
  if (stageIndex === 4)
    return "https://media.engaging.works/production/media/business/materials/files/Senedd-instilling-pride-english-action-plan.pdf"
  if (stageIndex === 5)
    return "https://media.engaging.works/production/media/business/materials/files/Senedd-job-satisfaction-english-action-plan.pdf"
  return null
}

const determineWelshUrl = stageIndex => {
  // senedd (welsh parliament) personalised action plans in welsh
  if (stageIndex === 0)
    return "https://media.engaging.works/production/media/business/materials/files/Senedd-reward-and-recognition-welsh-action-plan.pdf"
  if (stageIndex === 1)
    return "https://media.engaging.works/production/media/business/materials/files/Senedd-information-sharing-welsh-action-plan.pdf"
  if (stageIndex === 2)
    return "https://media.engaging.works/production/media/business/materials/files/Senedd-empowerment-welsh-action-plan.pdf"
  if (stageIndex === 3)
    return "https://media.engaging.works/production/media/business/materials/files/Senedd-wellbeing-welsh-action-plan.pdf"
  if (stageIndex === 4)
    return "https://media.engaging.works/production/media/business/materials/files/Senedd-instilling-pride-welsh-action-plan.pdf"
  if (stageIndex === 5)
    return "https://media.engaging.works/production/media/business/materials/files/Senedd-job-satisfaction-welsh-action-plan.pdf"
  return null
}

const ActionPlanButtons = ({ displayLanguage, t, stageIndex, setShowModal, surveyId }) => {
  // if its the scottish parliament survey
  if (surveyId === "5fbd16543b6d475eeb52e49b") {
    return (
      <a href={determineUrl(stageIndex)} target="_blank noopener noreferrer">
        <Button variant="primary.0">Action Plan</Button>
      </a>
    )
  }
  // if its the welsh parliament survey and taken in english
  if (surveyId === "5f996b51b97af4153987eb45" && displayLanguage.includes("en")) {
    return (
      <a href={determineEnglishUrl(stageIndex)} target="_blank noopener noreferrer">
        <Button variant="primary.0">Action Plan</Button>
      </a>
    )
  }
  // if its the welsh parliament survey and taken in welsh
  if (surveyId === "5f996b51b97af4153987eb45" && displayLanguage === "cy") {
    return (
      <a href={determineWelshUrl(stageIndex)} target="_blank noopener noreferrer">
        <Button variant="primary.0">Cynllun Gweithredu</Button>
      </a>
    )
  }
  // if its any other survey!!!
  return (
    <Button variant="primary.0" onClick={() => setShowModal(true)}>
      {t("action_plan.button", "")}
    </Button>
  )
}

export default ActionPlanButtons
