import React from "react"
import { Button, Text } from "@engaging-tech/components"

const CancelButton = ({ onCancel }) => (
  <Button elevation={0} variant="text.primary.0" onClick={onCancel}>
    <Text color="primary.0" fontWeight={700} fontSize={3}>
      Cancel
    </Text>
  </Button>
)

export default CancelButton
