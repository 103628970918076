import { connect } from "react-redux"

import { AccountSelectors } from "../../account"
import { yourProfileSelectors } from "../../yourProfile"
import CreatePostComponent from "../components/Posts/createPost"
import { post } from "../store/globalHub.actions"
import { getIsPostCreationVisible, getIsPosting, getPostCount } from "../store/globalHub.selectors"

const mapState = state => ({
  profile: yourProfileSelectors.getProfile(state),
  isAuthenticated: AccountSelectors.getIsAuthenticated(state),
  postCount: getPostCount(state),
  isPosting: getIsPosting(state),
  isVisible: getIsPostCreationVisible(state)
})

const mapDispatch = dispatch => ({
  onSubmit: newPost => dispatch(post(newPost))
})

const CreatePost = connect(mapState, mapDispatch)(CreatePostComponent)

export default CreatePost
