import React from "react"
import PageLayout from "../layouts/PageLayout"
import ConnectView from "../features/connect/views/InvitationResponse"

const ConnectUsers = () => (
  <PageLayout>
    <ConnectView />
  </PageLayout>
)

export default ConnectUsers
