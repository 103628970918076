import React from "react"
import { useSelector } from "react-redux"
import YourSurveysView from "../../features/developCareer/views/YourSurveys"
import Loader from "../../features/developCareer/containers/Loader"
import { developCareerSelectors } from "../../features/developCareer"
import DetailsLayout from "../../layouts/DetailsLayout"

const YourSurveys = () => {
  const surveys = useSelector(developCareerSelectors.getSurveys)
  return (
    <DetailsLayout
      actionName="CAREER DEVELOPER"
      backTo="/develop-career"
      bg={!surveys.loading && surveys.data.length > 0 ? "light.0" : "dark.7"}
    >
      <Loader />
      <YourSurveysView />
    </DetailsLayout>
  )
}

export default YourSurveys
