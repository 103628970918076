import React from "react"

import GradientLayout from "../layouts/GradientLayout"
import PreviousRouteRedirect from "../features/onboarding/containers/PreviousRouteRedirect"

const Onboarding = () => (
  <GradientLayout>
    <PreviousRouteRedirect />
  </GradientLayout>
)

export default Onboarding
