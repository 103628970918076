import {
  Modal,
  Button,
  Flex,
  Paragraph,
  Radio,
  Text,
  Box,
  TextArea,
  Spinner,
  CustomHooks,
  Icon
} from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import { format } from "date-fns"
import React, { useState, useEffect } from "react"
import { createPortal } from "react-dom"
import styled from "styled-components"
import PrimaryButton from "../../../../ui/components/PrimaryButton"

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const StyledModal = styled(Modal)`
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
`

const BottomBorderedBox = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[5]};
  padding: ${({ theme }) => theme.space[3]}px 0;
`

const LastChild = styled(Box)`
  & > :last-child {
    ${({ styles }) => ({ ...styles })}
  }
`

const StyledTextArea = styled(TextArea)`
  background: none;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  height: 80px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.dark[4]};

  &:hover {
    background: none;
  }
`

const CloseButton = styled(Button)`
  background: none;
  box-shadow: none;
  outline: none;
`

const reasons = [
  "I can’t afford it/it’s too expensive",
  "I’m not using it as much as I hoped to",
  "I’m having technical issues with the service",
  "I’ve now found a job",
  "I’m not happy with the service",
  "Other (please specify below)"
]

const CancellationForm = ({ onSubmit, setVisibility, isLoading }) => {
  const [reason, setReason] = useState()
  const [message, setMessage] = useState("")

  const handleChange = e => {
    setReason(e.target.id)
  }

  const handleSubmit = () => {
    const params = { reason, message: message || null }

    onSubmit(params)
  }
  if (isLoading) return <Spinner color="primary.0" />

  const isValid = () => {
    if (reason === reasons[reasons.length - 1]) {
      return !!message
    }

    return !!reason
  }

  return (
    <>
      <Text
        color="primary.0"
        fontSize={[5, 6]}
        fontWeight={700}
        textAlign="center"
      >
        Manage Your Subscription
      </Text>
      <Paragraph mt={2} color="dark.1" fontSize={[3, 4]} textAlign="center">
        You’re about to cancel your WorkL Premium Membership. We’re sorry to see
        you go, please tell us why you’re choosing to cancel
      </Paragraph>
      <LastChild
        styles={{
          "border-bottom": "none"
        }}
      >
        {reasons.map(currentReason => (
          <BottomBorderedBox key={currentReason}>
            <Radio
              id={currentReason}
              label={() => <Text color="dark.2">{currentReason}</Text>}
              checked={currentReason === reason}
              onChange={handleChange}
              name="reason"
            />
          </BottomBorderedBox>
        ))}
      </LastChild>
      <StyledTextArea
        placeholder="Type your message here…"
        onClick={() => setReason("Other (please specify below)")}
        value={message}
        onChange={e => setMessage(e.target.value)}
      />
      <Flex justifyContent="space-between" mt={[2, 5]}>
        <CloseButton
          onClick={() => {
            setVisibility("settings")
          }}
        >
          Back
        </CloseButton>

        <PrimaryButton width={168} disabled={!isValid()} onClick={handleSubmit}>
          Cancel Premium
        </PrimaryButton>
      </Flex>
    </>
  )
}

const DisplayCancellationInfo = ({ setVisibility, premiumExpiration }) => {
  return (
    <>
      <Text
        color="primary.0"
        fontSize={[5, 6]}
        fontWeight={700}
        textAlign="center"
      >
        Your Premium Membership has been cancelled
      </Text>
      <Paragraph color="dark.1" my={4} fontSize={[4, 5]} textAlign="center">
        {`You will still be able to use our Premium features until ${format(
          new Date(premiumExpiration),
          "MMMM do, yyyy"
        )}.`}
      </Paragraph>

      <Flex justifyContent="space-between" mt={5}>
        <CloseButton
          onClick={() => {
            setVisibility("settings")
          }}
        >
          Back
        </CloseButton>
      </Flex>
    </>
  )
}

const ErrorState = ({ message, setVisibility }) => {
  return (
    <Flex justifyContent="center" alignItems="center" flexDirection="column">
      <Icon name="warning" size={60} color="dark.2" mb={4} />
      <Text width="auto" mb={4}>
        {message}
      </Text>
      <PrimaryButton
        onClick={() => {
          setVisibility("settings")
        }}
      >
        Back
      </PrimaryButton>
    </Flex>
  )
}
const ManageSubscriptionModal = ({
  setVisibility,
  onSubmit,
  isPremium,
  isPremiumCancelled,
  hasErrorPremium,
  premiumExpiration,
  isLoading,
  hasError,
  resetErrors,
  showModal
}) => {
  const paths = usePaths()
  const router = useRouter()
  CustomHooks.useFreshChatDeactivate()
  useEffect(() => {
    resetErrors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!showModal) return <></>

  const getState = () => {
    if (!isPremium) {
      return (
        <ErrorState
          message="You do not have premium"
          setVisibility={setVisibility}
        />
      )
    }

    if (hasError || hasErrorPremium) {
      return (
        <ErrorState
          message="There has been a error unsubscribing from premium"
          setVisibility={setVisibility}
        />
      )
    }

    if (!isPremiumCancelled) {
      return (
        <CancellationForm
          onSubmit={onSubmit}
          setVisibility={setVisibility}
          isLoading={isLoading}
        />
      )
    }
    return (
      <DisplayCancellationInfo
        setVisibility={setVisibility}
        premiumExpiration={premiumExpiration}
      />
    )
  }
  return createPortal(
    <StyledModal
      onToggle={() => {
        setVisibility(false)
        router.navigate(paths.careerDeveloper.index)
      }}
      animated
      disableScrollbar
      maxHeight="80vh"
      mb={100}
    >
      <Flex bg="light.0" width={1} px={[4]} py={5} flexDirection="Column">
        {getState()}
      </Flex>
    </StyledModal>,
    appRoot()
  )
}

export default ManageSubscriptionModal
