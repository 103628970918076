import { call, put, takeLatest } from "redux-saga/effects"

import { AccountActions } from "../../account"
import { load, loadByMaterialType, toggleBookmark } from "../services/bookmark.service"
import * as Actions from "./bookmark.actions"

export function* loadSaga() {
  try {
    const res = yield call(load)

    yield put(Actions.loadSuccess(res.data.myBookmarks))
  } catch (err) {
    yield put(Actions.loadFail())
  }
}

export function* loadByMaterialTypeSaga(action) {
  try {
    const { refSubType, refType } = action
    const res = yield call(loadByMaterialType, { refSubType, refType })

    yield put(Actions.loadByMaterialTypeSuccess(res.data.myBookmarks))
  } catch (err) {
    yield put(Actions.loadByMaterialTypeFail())
  }
}

export function* toggleSaga(action) {
  try {
    const toggleRes = yield call(toggleBookmark, action.payload)
    yield toggleRes?.data?.bookmark
      ? put(Actions.bookmarkSuccess(action.payload))
      : put(Actions.unbookmarkSuccess(action.payload))

    const loadAllRes = !action.payload.selectedTabCategory
      ? yield call(load)
      : yield call(loadByMaterialTypeSaga, action.payload)

    yield put(Actions.loadSuccess(loadAllRes.data.myBookmarks))
  } catch (err) {
    yield put(Actions.toggleFail())
  }
}

export function* saga() {
  yield takeLatest([Actions.types.LOAD, AccountActions.types.SIGN_IN_SUCCESS], loadSaga)
  yield takeLatest(Actions.types.TOGGLE, toggleSaga)
}
