import { gql } from "../../../lib/Apollo"

export const LOAD_ALL_COUNTRIES = gql`
  query {
    allCountries {
      id
      name
      code
    }
  }
`

export const LOAD_ALL_INDUSTRIES = gql`
  query {
    allIndustries {
      id
      name
    }
  }
`

export const LOAD_ALL_JOB_ROLES = gql`
  query {
    allJobRoles {
      id
      name
    }
  }
`

export const LOAD_SURVEY_JOB_ROLES = gql`
  query {
    allSurveyJobRoles {
      id
      name
    }
  }
`

export const LOAD_SKILLS = gql`
  query {
    allSkills {
      id
      name
    }
  }
`

export const LOAD_LANGUAGES = gql`
  query {
    allLanguages {
      code
      name
    }
  }
`

export const LOAD_HOBBIES = gql`
  query {
    allHobbies {
      id
      name
    }
  }
`

export const LOAD_REGIONS = gql`
  query {
    allRegions {
      id
      name
    }
  }
`
