import { Button, Chip, Flex, Text } from "@engaging-tech/components"
import React from "react"
import { useSelector } from "react-redux"
import * as Yup from "yup"

import DropdownInput from "../../../formFlowWizard/components/formElements/DropdownInput"
import { getAllHobbies } from "../../../misc/store/misc.selectors"
import MarkPriceQuote from "./MarkPriceQuote"

const icon = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/create.png`)

const info = {
  icon,
  title: "Create a CV",
  submitData: true
}

const validationSchema = Yup.object({
  hobbyToAdd: Yup.string().nullable()
})

const HobbiesAdded = ({ values, setFieldValue, hobbies, findHobbyById }) => (
  <Flex flexWrap="wrap">
    {values.hobbies.length > 0 &&
      values.hobbies.map(hobbyId => (
        <Chip
          key={hobbyId}
          mr={2}
          mt={2}
          trailingIcon="close"
          color="primary.0"
          onClick={() => {
            setFieldValue(
              "hobbies",
              hobbies.filter(hobby => hobby !== hobbyId)
            )
          }}
        >
          {findHobbyById(hobbyId)}
        </Chip>
      ))}
  </Flex>
)

const Hobbies = ({ formik, handleTouchAllInputs }) => {
  const { values, errors, setFieldValue, setFieldTouched } = formik
  const { hobbies, hobbyToAdd } = values

  const allHobbies = useSelector(getAllHobbies).map(hobby => {
    return {
      id: hobby.id,
      label: hobby.name
    }
  })

  const findHobbyById = id => allHobbies.find(hobby => hobby.id === id)?.label

  const handleHobbiesSave = async () => {
    if (hobbies) await setFieldValue("hobbies", [...hobbies, hobbyToAdd.id])
    if (!hobbies) await setFieldValue("hobbies", [hobbyToAdd.id])
    setFieldValue("hobbyToAdd", null)
    setFieldTouched("hobbyToAdd", false)
  }

  const buttonDisable = errors.hobbyToAdd || values.hobbyToAdd === "" || values.hobbyToAdd === null

  return (
    <Flex width={1 / 1} flexDirection="column">
      <MarkPriceQuote
        quote="This was always a section I took great interest in, 
      as through it you could learn a lot about the candidate and their nature."
      />
      <Text fontWeight={700} mb={3}>
        Hobbies & Interests
      </Text>
      <DropdownInput
        name="hobbyToAdd"
        placeholderLabel="Please Select"
        label="Add a hobby"
        options={allHobbies.filter(item => !hobbies?.includes(item.id))}
      />
      <Flex justifyContent="flex-end" my={4}>
        <Button
          elevation={0}
          px={5}
          type="button"
          disable={buttonDisable}
          bg={buttonDisable ? "dark.5" : "secondary.0"}
          onClick={
            buttonDisable
              ? () =>
                  handleTouchAllInputs({
                    formik,
                    containerElement: document.querySelector("#skill-add-form")
                  })
              : handleHobbiesSave
          }
        >
          <Text fontWeight={700} color={buttonDisable ? "dark.2" : "primary.0"} fontSize={3}>
            SAVE
          </Text>
        </Button>
      </Flex>
      {values.hobbies && (
        <HobbiesAdded
          values={values}
          setFieldValue={setFieldValue}
          hobbies={hobbies}
          findHobbyById={findHobbyById}
        />
      )}
    </Flex>
  )
}

export default { component: Hobbies, validationSchema, info }
