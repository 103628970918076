import { connect } from "react-redux"

import { getIsPremium } from "../../yourProfile/store/yourProfile.selectors"
import DynamicStateComponent from "../components/DynamicState"
import { removeWidget } from "../store/developCareer.actions"
import { getGridCustomisationFlag } from "../store/developCareer.selectors"

const mapState = state => ({
  isPremium: getIsPremium(state),
  gridCustomisationFlag: getGridCustomisationFlag(state)
})

const mapDispatch = dispatch => ({
  removeWidget: widgetName => dispatch(removeWidget(widgetName))
})

const DynamicState = connect(mapState, mapDispatch)(DynamicStateComponent)

export default DynamicState
