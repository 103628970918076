import { Flex, MotionFlex } from "@engaging-tech/components"
import { useLocation } from "@engaging-tech/routing"
import { Form, Formik } from "formik"
import { AnimatePresence } from "framer-motion"
import React, { useEffect, useState } from "react"

import useJobMatcherRedirect from "../../jobMatcher/hooks/useJobMatcherRedirect"
import ProgressBar from "../../ui/components/ProgressBar"
import createSleep from "../helpers/createSleep"
import createTouchFieldObject from "../helpers/createTouchFieldObject"
import FormFooter from "./formFooter"
import FormHeader from "./formHeader"
import FormInfomationCover from "./formInfomationCover"

const FormFlowWizard = ({
  isLoading,
  initialData,
  hasError,
  redirectTo,
  onSubmit,
  isSubmitting,
  formStepTemplates,
  additonalData,
  additonalActions
}) => {
  const location = useLocation()

  const { onGoToJobFinder } = useJobMatcherRedirect()

  const [stepNumber, setStepNumber] = useState(0)
  const [forwardDirection, setForwardDirection] = useState(true)
  const [shakePanel, setShakePanel] = useState(false)
  const [snapshot, setSnapshot] = useState(null)

  useEffect(() => {
    setSnapshot(initialData)
  }, [initialData])

  const formStep = formStepTemplates[stepNumber]
  const totalSteps = formStepTemplates.length

  const handleBack = async ({ goToStep, values }) => {
    if (typeof goToStep === "number") {
      setSnapshot(values)
      setForwardDirection(stepNumber < goToStep)

      await createSleep(10)

      setStepNumber(goToStep)
    } else {
      setSnapshot(values)
      setForwardDirection(false)

      await createSleep(10)

      setStepNumber(Math.min(stepNumber - 1, totalSteps - 1))
    }
  }

  const handleNext = async ({ values }) => {
    setSnapshot(values)
    setForwardDirection(true)

    if (stepNumber !== 0) {
      if (location.pathname.includes("in-app-view")) {
        onSubmit({ data: values, place: "in-app-view" })
      } else {
        onSubmit({ data: values })
      }
    }

    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1))

    if (stepNumber === totalSteps - 1) {
      onGoToJobFinder()
    }
  }

  const handleShake = async () => {
    setShakePanel(true)

    await createSleep(500)

    setShakePanel(false)
  }

  const handleTouchAllInputs = ({ shake = true, formik, containerElement = null }) => {
    if (shake) {
      handleShake()
    }

    formik.setTouched(createTouchFieldObject(containerElement))
  }

  const getProgressionPercentage = errors => {
    const incrementalProgressValue = 1 / Object.keys(formStep.validationSchema.fields).length
    const stepIncrement =
      (Object.keys(formStep.validationSchema.fields).length - Object.keys(errors).length) *
      incrementalProgressValue

    return ((stepNumber + stepIncrement) / totalSteps) * 100
  }

  const showFormInfomationCover = isLoading || hasError || !initialData
  const StepTemplateComponent = formStep.component

  const formPanelVariants = {
    initial: direction => ({
      opacity: 0,
      x: direction ? 50 : -50,
      display: "none"
    }),
    active: {
      opacity: 1,
      x: 0,
      display: "block",
      transition: {
        delay: 0.35,
        duration: 0.3,
        x: {
          delay: 0.3,
          duration: 0.3
        },
        display: {
          delay: 0.3,
          duration: 0
        }
      }
    },
    shake: {
      x: [-3, 3],
      display: "block",
      opacity: 1,
      transition: {
        repeat: Infinity,
        duration: 0.1,
        repeatType: "mirror"
      }
    },
    exit: direction => ({
      opacity: 0,
      x: direction ? -50 : 50,
      transition: {
        duration: 0.3
      },
      transitionEnd: {
        display: "none"
      }
    })
  }

  return (
    <AnimatePresence>
      <MotionFlex
        margin="auto"
        mt="10vh"
        maxWidth={600}
        width={19 / 20}
        bg="light.0"
        borderRadius={5}
        flexDirection="column"
        key={stepNumber}
        custom={forwardDirection}
        variants={formPanelVariants}
        initial="initial"
        animate={shakePanel ? "shake" : "active"}
        exit="exit"
      >
        {showFormInfomationCover && (
          <FormInfomationCover
            hasError={hasError}
            redirectTo={redirectTo}
            isLoading={isLoading}
            isSubmitting={isSubmitting}
          />
        )}
        {!showFormInfomationCover && snapshot?.id === initialData?.id && snapshot && (
          <Formik initialValues={snapshot} validationSchema={formStep.validationSchema} validateOnMount>
            {formik => (
              <>
                <FormHeader title={formStep.info.title} icon={formStep.info.icon} />
                <ProgressBar percentage={getProgressionPercentage(formik.errors)} height="5px" />
                <Flex p={4} flexDirection="column" width={1 / 1}>
                  <Form style={{ width: "100%" }} id="form-flow-wizard">
                    <StepTemplateComponent
                      formik={formik}
                      additonalData={additonalData}
                      additonalActions={additonalActions}
                      onSubmit={onSubmit}
                      handleTouchAllInputs={handleTouchAllInputs}
                    />
                  </Form>
                  <FormFooter
                    cancelPath={redirectTo}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    handleTouchAllInputs={handleTouchAllInputs}
                    formStep={formStep}
                    finalStep={stepNumber === totalSteps - 1}
                    stepNumber={stepNumber}
                    formik={formik}
                    formInvalid={formik.isValidating || !formik.isValid || formik.isSubmitting}
                  />
                </Flex>
              </>
            )}
          </Formik>
        )}
      </MotionFlex>
    </AnimatePresence>
  )
}

export default FormFlowWizard
