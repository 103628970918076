import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Flex, Box, MotionFlex } from "@engaging-tech/components"
import { prominent } from "color.js"

const ThumbnailImg = styled.img`
  object-fit: contain;
  object-position: 50%;
  height: 100%;
  width: 100%;
  background-color: transparent;
  position: absolute;
`

const GradientFlex = styled(MotionFlex)`
  background: linear-gradient(
    21deg,
    rgba(
        ${({ prominentColors }) => prominentColors[0][0]},
        ${({ prominentColors }) => prominentColors[0][1]},
        ${({ prominentColors }) => prominentColors[0][2]},
        1
      )
      0%,
    rgba(
        ${({ prominentColors }) => prominentColors[1][0]},
        ${({ prominentColors }) => prominentColors[1][1]},
        ${({ prominentColors }) => prominentColors[1][2]},
        1
      )
      50%
  );
`

const PlayButton = styled(Flex)`
  border: 4px solid ${({ theme }) => theme.colors.secondary[0]};
  position: absolute;
  & > div {
    width: 16px;
    height: 24px;
    border-style: solid;
    border-width: 24px;
    border-color: transparent transparent transparent
      ${({ theme }) => theme.colors.secondary[0]};
    border-width: 12px 0px 12px 16px;
    margin-left: 8px;
  }
`

const Thumbnail = ({ src, hasPlayButton, ...props }) => {
  const [prominentColors, setProminentColors] = useState([
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0]
  ])

  const [showGradient, setShowGradient] = useState(false)

  useEffect(() => {
    let isCancelled = false
    const runAsync = async () => {
      try {
        const img = document.getElementById(src)

        if (img.complete) {
          const colors = await prominent(img, { amount: 2, sample: 100 })

          if (!isCancelled && colors) {
            setProminentColors(colors)
            setShowGradient(true)
          }
        } else {
          img.addEventListener("load", async function() {
            const colors = await prominent(img, { amount: 2, sample: 100 })

            if (!isCancelled && colors) {
              setProminentColors(colors)
              setShowGradient(true)
            }
          })
        }
      } catch (e) {
        if (!isCancelled) {
          setShowGradient(false)
        }
      }
    }

    runAsync()

    return () => {
      isCancelled = true
    }
  }, [])

  const gradientFlexVariants = {
    hidden: {
      opacity: 0
    },
    visable: {
      opacity: 1
    }
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      position="relative"
      minHeight="186px"
      {...props}
    >
      <GradientFlex
        className="gradient-wrap"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
        position="absolute"
        initial="hidden"
        animate={showGradient ? "visable" : "hidden"}
        variants={gradientFlexVariants}
        prominentColors={prominentColors}
      />

      <ThumbnailImg src={src} crossOrigin="Anonymous" id={src} />
      {hasPlayButton && (
        <PlayButton
          width={48}
          height={48}
          borderRadius={99}
          elevation={2}
          justifyContent="center"
          alignItems="center"
        >
          <Box />
        </PlayButton>
      )}
    </Flex>
  )
}

export default Thumbnail
