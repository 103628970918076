import React from "react"

import BrowseNav from "../features/businessLibrary/components/BrowseNav"
import SearchBar from "../features/businessLibrary/components/Search/SearchBar"
import GlobalHubSideColumnLayout from "./GlobalHubSideColumnLayout"

const DefaultBanner = () => (
  <>
    <SearchBar />
    <BrowseNav />
  </>
)

const BrowseLibraryLayout = ({ banner: Banner, ...props }) => (
  <GlobalHubSideColumnLayout
    {...props}
    banner={Banner || DefaultBanner}
    isSticky
    sideBarWidth={[1 / 1, "30%"]}
  />
)

export default BrowseLibraryLayout
