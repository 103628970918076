import { Button, Flex, Paragraph, Text, TextArea } from "@engaging-tech/components"
import React, { useEffect, useState } from "react"

import ProfileSection from "./ProfileSection"

const CHAR_LIMIT = 200

const FormShowing = ({ toggle, personalStatement, onUpdate }) => {
  const [statement, setStatement] = useState(personalStatement)
  useEffect(() => {
    if (statement && statement.length > CHAR_LIMIT) {
      setStatement(statement.substring(0, CHAR_LIMIT))
    }
  }, [statement])

  // const handleFile = e => {
  //   if (!e.target.files.length) return
  //   const file = e.target.files[0]
  //   const reader = new FileReader()
  //   reader.onloadend = () => {
  //     setStatement(reader.result)
  //   }
  //   reader.readAsText(file)
  // }

  return (
    <Flex flexDirection="column">
      <TextArea
        width={1 / 1}
        color="primary.0"
        mt={2}
        placeholder="How would you describe yourself and your work?"
        maxLength={CHAR_LIMIT}
        value={statement}
        height={150}
        onChange={e => setStatement(e.target.value)}
      />
      <Flex mt={2}>
        {/* <Button
          type="button"
          variant="primary.0"
          width="120px"
          mr={2}
          onClick={() => {
            if (document) {
              document.getElementById("personal-statement-file-upload").click()
            }
          }}
        >
          Upload
        </Button> */}
        <Button
          elevation={0}
          variant="outline.primary.0"
          width="120px"
          onClick={() => {
            onUpdate({ personalStatement: statement })
            toggle()
          }}
        >
          SAVE
        </Button>
      </Flex>
      {/* <input
        type="file"
        id="personal-statement-file-upload"
        style={{ display: "none" }}
        onChange={handleFile}
        accept=".txt,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        hidden
      /> */}
    </Flex>
  )
}

const DataShowing = ({ personalStatement }) =>
  personalStatement ? (
    personalStatement.split("\n").map(paragraph => (
      <Paragraph key={paragraph} fontSize={3} color="dark.2" mt={3} mb={2}>
        {paragraph}
      </Paragraph>
    ))
  ) : (
    <Text mt={2}>You have not entered a personal statement yet</Text>
  )

const PersonalStatement = ({ personalStatement, onUpdate, toggle, hideCancelButton = false }) => {
  const [dataShowing, setDataShowing] = useState(true)

  return (
    <ProfileSection
      title="Personal Statement"
      icon="subject"
      onClick={() => setDataShowing(!dataShowing)}
      toggle={toggle}
      dataShowing={dataShowing}
      hideCancelButton={hideCancelButton}
    >
      {dataShowing ? (
        <DataShowing personalStatement={personalStatement} />
      ) : (
        <FormShowing
          toggle={toggle || (() => setDataShowing(true))}
          personalStatement={personalStatement}
          onUpdate={onUpdate}
        />
      )}
    </ProfileSection>
  )
}

export default PersonalStatement
