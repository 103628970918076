/* eslint-disable no-restricted-syntax */
import { titleCaps } from "@engaging-tech/frontend-utils"

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return item && typeof item === "object" && !Array.isArray(item)
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })
        mergeDeep(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }

  return mergeDeep(target, ...sources)
}

export const getIsSingular = dataset => {
  if (dataset && dataset.scores) {
    return !dataset.scores.find(d => Object.keys(d.values).length > 1)
  }
  return null
}

export function formatLabel(str, maxLineWidth) {
  const sections = []
  const words = str.split(" ")
  let temp = ""

  words.forEach((item, index) => {
    if (temp.length > 0) {
      const concat = `${temp} ${item}`

      if (concat.length > maxLineWidth) {
        sections.push(temp)
        temp = ""
      } else if (index === words.length - 1) {
        sections.push(concat)
        return
      } else {
        temp = concat
        return
      }
    }

    if (index === words.length - 1) {
      sections.push(item)
      return
    }

    if (item.length < maxLineWidth) {
      temp = item
    } else {
      sections.push(item)
    }
  })

  return sections
}

export const colorScale = ["rgba(169, 240, 213, 1)", "rgba(191, 169, 240, 1)", "rgba(169, 199, 240, 1)"]

export const formatMultiData = dataList => {
  const legends = Object.keys(dataList[0].values).filter(l => l !== "__typename")
  return {
    labels: dataList.map(d => formatLabel(d.label, 20)),
    datasets: legends.map((l, i) => ({
      label: titleCaps(l),
      data: dataList.map(d => d.values[l]),
      backgroundColor: colorScale[i]
    }))
  }
}

export const formatSingularData = dataList => {
  return dataList.reduce(
    (acc, score) => {
      const label = formatLabel(titleCaps(score.label), 20)

      acc.labels.push(label)
      acc.datasets[0].data.push(score.values.data)
      return acc
    },
    {
      labels: [],
      datasets: [{ data: [], backgroundColor: colorScale }]
    }
  )
}

export const formatData = dataset => {
  if (getIsSingular(dataset)) {
    return formatSingularData(dataset.scores)
  }
  return formatMultiData(dataset.scores)
}
