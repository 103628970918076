import React from "react"
import { Text, Flex, Paragraph } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"

import * as Yup from "yup"
import MarkPriceQuote from "./MarkPriceQuote"

const icon = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/create.png`)

const info = {
  icon,
  title: "Create a CV",
  submitData: false
}

const validationSchema = Yup.object({})

const CreateCVIntro = () => (
  <Flex width={1 / 1} flexDirection="column">
    <MarkPriceQuote quote="Tailor your resume to make your application shine through." />
    <Paragraph textAlign="center">
      Want to make your CV shine? Read{" "}
      <Text fontWeight={700} color="primary.0">
        <Link to="/business-library/browse/articles/650085b45b7dad0012219c70">
          The Six Steps To Your Best CV.
        </Link>
      </Text>
    </Paragraph>
    <Paragraph textAlign="center">
      Using Mark Price's guidance you can generate a CV that is perfect for the
      role and company that you are applying for
    </Paragraph>
  </Flex>
)

export default { component: CreateCVIntro, validationSchema, info }
