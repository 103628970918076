import { Box, Dialog, Paragraph, ResponsiveSearch } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

const ResponsiveSearchCustom = styled(ResponsiveSearch)`
  input {
    font-size: 18px;
  }
`

const CompanyRankingDialog = ({
  onSubmitCompany,
  savantaId,
  companyName: preSelectedCompany,
  companies,
  getSurveyCompanies,
  isLoading
}) => {
  const [companyName, setCompanyName] = useState(preSelectedCompany || "")
  const [companyId, setCompanyId] = useState("")
  const router = useRouter()
  const paths = usePaths()
  const history = useHistory()

  const isInAppView = history.location.pathname.includes("/in-app-view")

  const toSurveyStart = () => {
    if (isInAppView) {
      router.navigate(paths.inAppViewHappinessSurvey.questions)
    } else {
      router.navigate(paths.happinessSurvey.questions)
    }
  }

  const toHome = () => history.goBack()

  const handleCompanySubmission = () => {
    onSubmitCompany(companyId, companyName)
    setTimeout(() => toSurveyStart(), 100) // allow time for redux dispatch to happen
  }
  const { t } = useTranslation(["happiness_survey"])

  let footerItems = [
    {
      label: t("company_ranking.take_survey", ""),
      action: handleCompanySubmission,
      isDisabled: !companyName.length
    }
  ]
  const optionalItems = isInAppView
    ? []
    : [
        {
          label: t("ui.cancel", ""),
          action: toHome
        }
      ]

  if (!savantaId) {
    footerItems = footerItems.concat(optionalItems)
  }
  const companiesOptions =
    companies &&
    companies.map(company => ({
      name: company.name,
      id: company.name
    }))

  return (
    <Dialog header={t("company_ranking.title", "")} onToggle={() => {}} footer={footerItems}>
      <Box>
        <Paragraph color="dark.2" mt={2} mb={3} fontSize={3}>
          {t("company_ranking.paragraph1", "")}
        </Paragraph>
        <Paragraph color="dark.2" mt={2} mb={3} fontSize={3} fontWeight="500">
          {t("company_ranking.paragraph2", "")}
        </Paragraph>
        <ResponsiveSearchCustom
          id="companyName"
          width={1 / 1}
          type="text"
          label={t("company_ranking.companyLabel", "")}
          selectCallback={e => {
            if (e.id) {
              const companyObject = companies.find(company => company.name === e.id)
              setCompanyId(companyObject.id)
            } else if (companyId) {
              setCompanyId("")
            }
            setCompanyName(e.name)
          }}
          options={companiesOptions}
          onChangeCallback={e => getSurveyCompanies(e)}
          isLoading={isLoading}
          dropdownWidth={["auto", "auto", 530]}
          initialSearchTerm={companyName}
        />
      </Box>
    </Dialog>
  )
}

export default CompanyRankingDialog
