import { Flex } from "@engaging-tech/components"
import { useLocation } from "@engaging-tech/routing"
import React, { useEffect } from "react"
import { connect, useSelector } from "react-redux"

import { surveyResultsActions } from ".."
import { getIsIsolated } from "../../survey/store/survey.selectors"
import AuthGuard from "../../ui/containers/AuthGuard"
import ResultsElement from "../components/ResultsElement"
import ResultsPageHeaders from "../containers/ResultsPageHeaders"
import * as ResultsSelectors from "../store/surveyResults.selectors"

const order = ["happinessDataHighlight", "happinessSixSteps", "actionPlans", "matrices"]

const Main = () => {
  const isIsolated = useSelector(getIsIsolated)
  const summaryLayout = useSelector(ResultsSelectors.getSummaryLayout)
  const sortedComponents = summaryLayout
    ?.filter(component => order.includes(component))
    ?.toSorted((a, b) => order.indexOf(a) - order.indexOf(b))

  const components = isIsolated ? sortedComponents : summaryLayout

  return (
    <Flex
      justifyContent="space-between"
      flexDirection={["column", "column", "row"]}
      flexWrap="wrap"
      alignItems="stretch"
    >
      <ResultsPageHeaders />
      {components?.map(component => (
        <ResultsElement key={component} tag={component} isolated={isIsolated} />
      ))}
    </Flex>
  )
}

const Results = ({ surveyType, clearResults }) => {
  const { pathname } = useLocation()

  const isInAppView = pathname.includes("in-app-view")

  const allowedSurveysType = ["organisation", "workFromHome", "employeeStarter", "employeeExit"]
  const isOrgSurvey = allowedSurveysType.find(type => type === surveyType)

  useEffect(() => {
    return clearResults
  }, [clearResults])

  if (isOrgSurvey || isInAppView) return <Main />

  return (
    <AuthGuard>
      <Main />
    </AuthGuard>
  )
}

const mapState = state => ({
  surveyType: ResultsSelectors.getSurveyType(state)
})
const mapDispatch = dispatch => ({
  clearResults: () => dispatch(surveyResultsActions.resetSurveyResults())
})

export default connect(mapState, mapDispatch)(Results)
