import React from "react"
import styled from "styled-components"
import { Card, Paragraph, Text, Box } from "@engaging-tech/components"

import BodyWrapper from "../../BodyWrapper"
import Interactions from "../../Interactions/Interactions"
import CommentWrapper from "../../CommentWrapper/CommentWrapper"

const HeaderText = styled(Text)`
  text-transform: capitalize;
`

const HeaderWrapper = styled(Box)`
  border-radius: 4px 4px 0 0;
`

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const SummaryHeader = () => (
  <HeaderWrapper bg="#F79F79" py={2} px={3}>
    <HeaderText fontWeight="500">Asking for Expert Advice</HeaderText>
  </HeaderWrapper>
)

const AdviceSummary = ({
  user,
  post,
  publishedAt,
  interactions: RenderShare,
  userDetails: UserDetails,
  postInteractions: RenderInteractions,
  postComments: RenderComments,
  commentForm: RenderCommentForm
}) => {
  return (
    <Card width={1 / 1} bg="light.0" position="relative" mb={[3, 5, 5]}>
      <SummaryHeader />
      <BodyWrapper p={3} mb={3}>
        {UserDetails && (
          <UserDetails
            userHoldingImage={profilePlaceholder}
            interactions={RenderShare}
            publishedAt={publishedAt}
            user={user}
          />
        )}
        <Paragraph
          letterSpacing="0.02rem"
          color="dark.0"
          fontSize={3}
          fontWeight={500}
        >
          {post.expertType.name}
        </Paragraph>
        <Paragraph letterSpacing="0.02rem" color="dark.2" fontSize={3}>
          {post.question}
        </Paragraph>
      </BodyWrapper>
      <Interactions>
        {RenderInteractions && <RenderInteractions />}
      </Interactions>
      <CommentWrapper>{RenderComments && <RenderComments />}</CommentWrapper>
      <RenderCommentForm />
    </Card>
  )
}

export default AdviceSummary
