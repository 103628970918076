import { Flex, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"
import testingBarContainer from "../containers/testingBar.container"
import surveyLifecycle from "../utils/surveyLifecycle"

const TestingText = styled(Text)`
  text-transform: uppercase;
`

const TestingBar = ({ hasLoaded, status }) => {
  if (hasLoaded && status !== surveyLifecycle.live) {
    return (
      <Flex bg="dark.3" p={[2, 3]} width={1 / 1} justifyContent="center">
        {[surveyLifecycle.draft, surveyLifecycle.scheduled].includes(
          status
        ) && (
          <TestingText
            width="auto"
            color="light.0"
            fontFamily="serif"
            fontSize={[4, 6]}
            fontWeight="700"
          >
            This survey is not yet live - Submissions will not be recorded
          </TestingText>
        )}

        {surveyLifecycle.completed === status && (
          <TestingText
            width="auto"
            color="light.0"
            fontFamily="serif"
            fontSize={[4, 6]}
            fontWeight="700"
          >
            This survey has already finished! Your submission will not be
            recorded
          </TestingText>
        )}
      </Flex>
    )
  }

  return null
}

export default testingBarContainer(TestingBar)
