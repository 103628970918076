export const types = {
  LOAD: "[Your Tests] Load",
  LOAD_SUCCESS: "[Your Tests] Load Success",
  LOAD_FAIL: "[Your Tests] Load Fail",
  UPDATE: "[Your Tests] Update",
  UPDATE_SUCCESS: "[Your Tests] Update Success",
  UPDATE_FAIL: "[Your Tests] Update Fail"
}

export const load = payload => ({
  type: types.LOAD,
  payload
})

export const loadSuccess = payload => ({
  type: types.LOAD_SUCCESS,
  payload
})

export const loadFail = () => ({
  type: types.LOAD_FAIL
})

export const update = payload => ({
  type: types.UPDATE,
  payload
})

export const updateSuccess = payload => ({
  type: types.UPDATE_SUCCESS,
  payload
})

export const updateFail = () => ({
  type: types.UPDATE_FAIL
})
