import { Flex, Text, Toggle } from "@engaging-tech/components"
import { useLocation, useParams, usePaths, useRouter } from "@engaging-tech/routing"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"

import SecondaryButton from "../../../ui/components/SecondaryButton"
import { setDefaultCV } from "../../store/cvLibrary.actions"
import { getCVs, getHasCreatedCV, getIsCreatingCV, getSelectedCV } from "../../store/cvLibrary.selectors"

const icon = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/thumb.png`)

const info = {
  icon,
  title: "You're all set!",
  submitData: true
}

const validationSchema = Yup.object({})

const texts = {
  firstCv: "Make this my default CV",
  errorTitle: "Something's gone wrong!",
  errorMessage: "Please try again.",
  doneMessage: "Well done, your CV is now complete!"
}

const FooterOverride = () => {
  const dispatch = useDispatch()
  const paths = usePaths()
  const router = useRouter()
  const { id } = useParams()
  const { pathname } = useLocation()

  const selectedCvId = useSelector(getSelectedCV)?.id
  const allCVs = useSelector(getCVs)

  const cvId = selectedCvId || id
  const [toggle, setToggle] = useState(false)

  const firstCV = allCVs?.length === 0

  const handleSubmit = () => {
    if (toggle || firstCV) dispatch(setDefaultCV({ id: cvId }))

    if (pathname.includes("in-app-view")) {
      router.navigate(paths.inAppViewCVLibrary)
    } else {
      router.navigate(paths.careerDeveloper.cvLibrary.index)
    }
  }

  return (
    <Flex flexDirection="column">
      {!firstCV && (
        <Flex flexDirection="row" justifyContent="center" alignItems="center">
          <Text fontSize={3} fontWeight={700} width="auto" mr={2}>
            {texts.firstCv}
          </Text>
          <Toggle
            id="toggle-first-cv"
            onColour="secondary.0"
            offColour="dark.3"
            size={40}
            onChange={() => setToggle(!toggle)}
            initialState={toggle}
          />
        </Flex>
      )}
      <Flex justifyContent="center" mt={5}>
        <SecondaryButton text="Return to profile" onClick={handleSubmit} />
      </Flex>
    </Flex>
  )
}

const ErrorState = () => (
  <Flex alignItems="center" flexDirection="column">
    <Text textAlign="center" width="auto" my={3}>
      {texts.errorTitle}
    </Text>
    <Text textAlign="center" width="auto">
      {texts.errorMessage}
    </Text>
  </Flex>
)

const AllSet = () => {
  const hasCreatedCV = useSelector(getHasCreatedCV)
  const isCreatingCV = useSelector(getIsCreatingCV)

  if (!hasCreatedCV && !isCreatingCV) return <ErrorState />

  return (
    <Flex width={1 / 1} flexDirection="column">
      <Text textAlign="center" mb={3}>
        {texts.doneMessage}
      </Text>
    </Flex>
  )
}

export default {
  component: AllSet,
  validationSchema,
  info,
  FooterOverride
}
