import { createSelector } from "reselect"
import * as ResultsSelectors from "../../store/surveyResults.selectors"
import matricesInfo from "../lib/matricesInfo"

const pipePassedInProp = (state, prop) => prop

const getMatrices = createSelector(
  ResultsSelectors.getResultsData,
  data => data && data.matrices
)

const formatMatrices = matrix => ({
  ...matrix,
  values: matrix.values.reduce(
    (acc, currentVal) => ({
      ...acc,
      [currentVal.label]: {
        x: parseInt(currentVal.values.x * 100, 10),
        y: parseInt(currentVal.values.y * 100, 10)
      }
    }),
    {}
  )
})

const mappedMatrices = createSelector(
  getMatrices,
  matrices => matrices && matrices.map(formatMatrices)
)

export const getMatrix = createSelector(
  [mappedMatrices, pipePassedInProp],
  (matrices, matrixName) => matrices.find(matrix => matrix.name === matrixName)
)

export const getMatrixInfo = name =>
  matricesInfo.find(info => info.section === name)
