import React from "react"
import {
  Text,
  Flex,
  Button,
  Icon,
  TabletUp,
  MobileOnly,
  CustomHooks
} from "@engaging-tech/components"
import styled from "styled-components"
import PrimaryButton from "../../ui/components/PrimaryButton"
import ProgressBar from "../../ui/components/ProgressBar"
import WidgetCard from "./WidgetCard"

const exampleImg = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/career-developer/widget-remove-example.png`)

const title = "Welcome To Career Developer"

const DesktopTitle = styled(Text)`
  text-align: center;
  background-color: ${({ theme }) => `${theme.colors.tertiary[0]}`};
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  font-size: 20px;
  color: ${({ theme }) => `${theme.colors.primary[0]}`};
`

const ClickFlex = styled(Flex)`
  cursor: pointer;
`

const Image = styled.img`
  width: 80%;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    width: 100%;
  }
`

const MobileCard = styled(WidgetCard)`
  background-color: ${({ theme }) => `${theme.colors.light[0]}`};
  padding: 12px;
  margin: 12px;
  margin-bottom: 100px;
`

const SecondScreenContent = ({
  setShowSecondOnboarding,
  seenBefore,
  toggle,
  updateSeenBefore,
  chosenGoals,
  updateMyGoals,
  chosenGoalsIdsArray
}) => (
  <Flex flexDirection="column" width={1 / 1} p={[3, 5]}>
    <Flex alignItems="center" mb={3}>
      <ClickFlex
        ml={-2}
        alignItems="center"
        width="auto"
        onClick={() => setShowSecondOnboarding(false)}
      >
        <Icon name="keyboard_arrow_left" color="dark.3" />
        <Text color="dark.3" fontSize={3} mr={6}>
          BACK
        </Text>
      </ClickFlex>
      <TabletUp width={1 / 1} alignItems="center">
        <Text textAlign="center" fontSize={5}>
          Your Career Developer Dashboard is almost ready…
        </Text>
      </TabletUp>
    </Flex>
    <MobileOnly width={1 / 1} mt={2}>
      <Text textAlign="center" fontSize={5}>
        Your Career Developer Dashboard is almost ready…
      </Text>
    </MobileOnly>
    <Flex pr={[0, 6]} flexDirection="column" width="100%">
      <Text fontSize={4} my={3}>
        Based on your goals, we have suggested some widgets to help you get
        started.
      </Text>
      <Text fontSize={4} mb={2}>
        If the widget isn’t relevant to your goals, or you’re not interested,
        simply click on the 3 dots at the top right of each widget card, and
        click “Remove Widget” as demonstrated below.
      </Text>
      <Text fontSize={4} mb={2}>
        You can also resize and move your widgets around, by clicking on the
        icon on the bottom right of each widget.
      </Text>
    </Flex>
    <Image src={exampleImg} alt="example image" />
    <Flex mt={3} width={1 / 1} justifyContent="space-between">
      <Button
        variant="text.dark.3"
        elevation={0}
        onClick={seenBefore ? () => toggle() : () => updateSeenBefore(true)}
      >
        CANCEL
      </Button>
      <Flex justifyContent="flex-end">
        <PrimaryButton
          width="120px"
          disabled={chosenGoals.length < 3}
          onClick={() => {
            updateMyGoals(chosenGoalsIdsArray)
            updateSeenBefore(true)
            setShowSecondOnboarding(false)
          }}
        >
          CONFIRM
        </PrimaryButton>
      </Flex>
    </Flex>
  </Flex>
)

const OnboardingSecondScreen = ({
  setShowSecondOnboarding,
  toggle,
  updateSeenBefore,
  seenBefore,
  chosenGoals,
  chosenGoalsIdsArray,
  updateMyGoals
}) => {
  CustomHooks.useFreshChatDeactivate()
  return (
    <Flex flexDirection="column" width="100%" height="100%">
      <MobileOnly width={1 / 1}>
        <Flex bg="tertiary.0" width={1 / 1} p={3} alignItems="center">
          <Icon
            name="close"
            mr={3}
            size={30}
            color="primary.0"
            onClick={seenBefore ? () => toggle() : () => updateSeenBefore(true)}
          />
          <ProgressBar
            height="10px"
            percentage={90}
            backgroundColor="light.0"
            primaryColor="primary.0"
            borderRadius={3}
          />
        </Flex>
        <Flex width={1 / 1} bg="dark.4" justifyContent="center">
          <MobileCard>
            <SecondScreenContent
              setShowSecondOnboarding={setShowSecondOnboarding}
              seenBefore={seenBefore}
              toggle={toggle}
              updateSeenBefore={updateSeenBefore}
              chosenGoals={chosenGoals}
              updateMyGoals={updateMyGoals}
              chosenGoalsIdsArray={chosenGoalsIdsArray}
            />
          </MobileCard>
        </Flex>
      </MobileOnly>
      <TabletUp>
        <Flex width="100%" flexDirection="column">
          <DesktopTitle fontWeight={700} fontFamily="serif">
            {title}
          </DesktopTitle>
          <SecondScreenContent
            setShowSecondOnboarding={setShowSecondOnboarding}
            seenBefore={seenBefore}
            toggle={toggle}
            updateSeenBefore={updateSeenBefore}
            chosenGoals={chosenGoals}
            updateMyGoals={updateMyGoals}
            chosenGoalsIdsArray={chosenGoalsIdsArray}
          />
        </Flex>
      </TabletUp>
    </Flex>
  )
}

export default OnboardingSecondScreen
