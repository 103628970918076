import React from "react"
import { Flex, Text } from "@engaging-tech/components"
import { getLabelData } from "../../../../lib/utils"

const BreakdownRow = ({ rowNum, title, percentage }) => {
  return (
    <Flex
      width={1 / 1}
      height={3}
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex width={9 / 10} pr={[2, 0]}>
        <Text width="auto" fontSize={3} color="dark.2" mr={3} fontWeight={500}>
          {`${rowNum}`}
        </Text>
        <Text width="auto" fontSize={3} color="dark.2">
          {title}
        </Text>
      </Flex>
      <Text
        width="auto"
        fontSize={3}
        color={getLabelData(percentage).color}
        fontWeight={500}
      >
        {`${percentage}`}%
      </Text>
    </Flex>
  )
}

export default BreakdownRow
