import React from "react"
import { useRouter, usePaths } from "@engaging-tech/routing"
import { matchPath } from "react-router"

import ChipBar from "../../ui/components/ChipBar"

const BrowseNav = () => {
  const paths = usePaths()
  const router = useRouter()

  const browsePaths = paths.resourceCentre.browse

  const chips = Object.keys(browsePaths)
    .map(category => ({
      name: category,
      linkTo: browsePaths[category].index,
      isActive:
        router.isMatch(browsePaths[category].index) ||
        (matchPath(window?.location?.pathname, {
          path: paths.resourceCentre.browse.index,
          exact: true,
          strict: false
        }) &&
          category === "books")
    }))
    // Remove the index route (/business-library/browse) from the list of chips
    // since it's a redirect to /browse/podcasts.
    .filter((chip, index) => index !== 0)

  return (
    <ChipBar
      chips={chips}
      width={1 / 1}
      bg="light.0"
      chipBgActive="secondary.0"
      chipBgNotActive="rgba(219, 218, 218, 1)"
      textWeight={400}
      overflow={false}
      textColor="dark.2"
      chipProps={{ m: 1 }}
      pt={25}
    />
  )
}

export default BrowseNav
