import { takeLatest, put, call } from "redux-saga/effects"

import {
  connectToUser,
  loadInvites,
  reportUser
} from "../services/connect.service"

import * as Actions from "./connect.actions"

export function* sendConnectInviteSaga(action) {
  try {
    const res = yield call(connectToUser, action.payload)

    yield put(
      Actions.connectUserSuccess({
        id: action.payload,
        response: res.data.connectToUser
      })
    )
  } catch {
    yield put(Actions.connectUserFail(action.payload))
  }
}

export function* loadInvitesSaga() {
  try {
    const res = yield call(loadInvites)
    yield put(Actions.loadInvitesSuccess(res.data.getConnectInvitations))
  } catch (e) {
    yield put(Actions.loadInvitesFail())
  }
}

export function* reportUserSaga(action) {
  try {
    const reportedUser = yield call(reportUser, {
      reason: action.payload.reason,
      reportedUserId: action.payload.reportedUserId
    })
    yield put(Actions.reportUserSuccess(reportedUser))
  } catch {
    yield put(Actions.reportUserFail())
  }
}

export function* saga() {
  yield takeLatest(Actions.types.CONNECT_USER, sendConnectInviteSaga)
  yield takeLatest(Actions.types.LOAD_INVITES, loadInvitesSaga)
  yield takeLatest(Actions.types.REPORT_USER, reportUserSaga)
}
