/* eslint-disable react/no-array-index-key */
import { Flex, Paragraph, Text } from "@engaging-tech/components"
import { format } from "date-fns"
import React from "react"

import PrimaryButton from "../../../ui/components/PrimaryButton"
import Separator from "../../../ui/components/Separator"

const QualField = ({ heading, value }) => (
  <Flex flexDirection="column" mb={1}>
    <Text my={1}>{heading}</Text>
    <Paragraph fontWeight={700}>{value}</Paragraph>
  </Flex>
)

const formattedDate = date => format(new Date(date), "MMM yyyy")

const QualsAdded = ({ institution, courseTitle, start, end, id, handleEdit, qualToAdd }) => {
  return (
    <Flex flexDirection="column" key={id}>
      <QualField heading="Institution" value={institution} />
      <QualField heading="Name of course or certification" value={courseTitle} />
      <Flex flexDirection="row">
        <QualField heading="Start Date" value={start && formattedDate(start)} />
        <QualField heading="End Date" value={end ? formattedDate(end) : "I currently study here"} />
      </Flex>

      <Flex justifyContent="flex-end" my={4}>
        <PrimaryButton
          text="EDIT"
          px={5}
          onClick={() => handleEdit(id)}
          type="button"
          disabled={id === qualToAdd?.id}
        />
      </Flex>
      <Separator width={1 / 1} color="primary.0" lineHeight="2px" />
    </Flex>
  )
}

const QualificationsAdded = ({ formik, setShowFormView }) => {
  const { values, setFieldValue } = formik
  const { qualifications, qualToAdd } = values

  const handleEdit = async id => {
    setShowFormView(true)
    const qualToEdit = await qualifications.find(qual => qual.id === id)
    if (qualToEdit.end === null) delete qualToEdit.end
    setFieldValue("qualToAdd", qualToEdit)
  }

  return (
    <>
      <Separator width={1 / 1} color="primary.0" lineHeight="2px" />
      {qualifications.map((qual, index) => (
        <QualsAdded key={index} {...qual} handleEdit={handleEdit} qualToAdd={qualToAdd} />
      ))}
    </>
  )
}

export default QualificationsAdded
