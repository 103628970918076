import { connect } from "react-redux"
import { setCode } from "../store/survey.actions"

const mapDispatch = dispatch => ({
  resetCode: () => dispatch(setCode(null))
})

const codeAlreadyUsedContainer = connect(null, mapDispatch)

export default codeAlreadyUsedContainer
