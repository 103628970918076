import { connect } from "react-redux"

import {
  getLoadCounter,
  getIsLoadingMore,
  getHasLoadedAll
} from "../store/libraryBooks.selectors"

import { loadMore } from "../store/libraryBooks.actions"

import LoadMoreButton from "../components/Browse/LoadMore"

const mapState = state => ({
  from: getLoadCounter(state),
  isLoadingMore: getIsLoadingMore(state),
  hasLoadedAll: getHasLoadedAll(state)
})

const mapDispatch = dispatch => ({
  onLoad: from =>
    dispatch(
      loadMore({
        from
      })
    )
})

const LoadMore = connect(mapState, mapDispatch)(LoadMoreButton)

export default LoadMore
