import { Button, Card, Text } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React from "react"

const NotAuthenticated = () => {
  const paths = usePaths()
  const router = useRouter()

  const toSignIn = () => router.navigate(paths.account.signIn)

  return (
    <Card
      width={1 / 1}
      p={3}
      mb={[3, 4, 4]}
      justifyContent="space-between"
      flexDirection="row"
      alignItems="center"
    >
      <Text color="dark.2" mr={3} width="auto">
        Want to start sharing your own ideas?
      </Text>
      <Button variant="text.primary.0" elevation={0} onClick={toSignIn} flexShrink="0">
        Get Started
      </Button>
    </Card>
  )
}

export default NotAuthenticated
