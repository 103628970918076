import { connect } from "react-redux"
import {
  notificationsSelectors,
  notificationsActions
} from "../../../Notifications"
import AlertsListComponent from "../components/AlertsList"

const mapState = state => ({
  type: "Job",
  alerts: notificationsSelectors.getJobAlerts(state)
})

const mapDispatch = dispatch => ({
  setActiveAlert: alert => dispatch(notificationsActions.setActiveAlert(alert))
})

const JobAlerts = connect(mapState, mapDispatch)(AlertsListComponent)

export default JobAlerts
