import { connect } from "react-redux"

import {
  getBookResult,
  getEbookResult,
  getAudioBookResult,
  getSelectedFormat,
  getIsLoading
} from "../store/libraryBooks.selectors"

import { loadBook, selectFormat } from "../store/libraryBooks.actions"

import SelectedBookComponent from "../components/SelectedBook/SelectedBook"

const mapState = state => ({
  isLoading: getIsLoading(state),
  book: getBookResult(state),
  ebook: getEbookResult(state),
  audioBook: getAudioBookResult(state),
  selectedFormat: getSelectedFormat(state)
})

const mapDispatch = dispatch => ({
  onLoad: bookId => dispatch(loadBook(bookId)),
  onSelectFormat: format => dispatch(selectFormat(format))
})

const SelectedBook = connect(mapState, mapDispatch)(SelectedBookComponent)

export default SelectedBook
