import React from "react"

import { libraryPostTypes } from "../../../features/userGeneratedContent/lib/postTypes"

import AllPosts from "../../../features/businessLibrary/containers/AllPosts"
import SideBar from "../../../features/businessLibrary/containers/SideBar"
import BrowseLibraryLayout from "../../../layouts/BrowseLibraryLayout"
import TopBar from "../../../features/businessLibrary/containers/TopBar"

const AllResearch = AllPosts(libraryPostTypes.research)

const Research = () => (
  <BrowseLibraryLayout
    sideColumn={SideBar(libraryPostTypes.research)}
    topRow={TopBar(libraryPostTypes.research)}
  >
    <AllResearch />
  </BrowseLibraryLayout>
)

export default Research
