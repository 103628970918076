import { connect } from "react-redux"

import {
  getQuestionIndexById,
  getQuestionTotal
} from "../store/happinessSurvey.selectors"

import ProgressBarComponent from "../components/ProgressBar"

const mapState = (state, { id }) => ({
  current: getQuestionIndexById(state, id),
  total: getQuestionTotal(state)
})

const ProgressBar = connect(mapState)(ProgressBarComponent)

export default ProgressBar
