import { userInterfaceNotificationsStore } from "@engaging-tech/components"
import { happinessReducer, happinessSaga } from "@engaging-tech/happiness"
import {
  analyticsSaga,
  marketingSaga,
  thirdPartyReducer,
  thirdPartySaga
} from "@engaging-tech/third-party-cookies"
import { applyMiddleware, combineReducers, compose, createStore } from "redux"
import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage/session"
import createSagaMiddleware from "redux-saga"
import { spawn } from "redux-saga/effects"

import { demographicFilterReducer, demographicFilterSaga } from "./components/shared/demographicFilter/store"
import { notificationsReducer, notificationsSaga } from "./features/Notifications"
import { accountReducer, accountSaga } from "./features/account"
import { samlReducer, samlSaga } from "./features/account/saml"
import { bookmarkReducer, bookmarkSaga } from "./features/bookmarks"
import { businessProfilePageReducer, businessProfilePageSaga } from "./features/business-profile"
import { businessLibraryReducer, businessLibrarySaga } from "./features/businessLibrary"
import { cmsReducer, cmsSaga } from "./features/cms"
import { connectReducer, connectSaga } from "./features/connect"
import { cvLibraryReducer, cvLibrarySaga } from "./features/cvLibrary"
import { developCareerReducer, developCareerSaga } from "./features/developCareer"
import { expertAdviceReducer, expertAdviceSaga } from "./features/expertAdvice"
import { libraryBooksReducer, libraryBooksSaga } from "./features/gardners"
import { globalHubReducer, globalHubSaga } from "./features/globalHub"
import { happinessSurveyReducer, happinessSurveySaga } from "./features/happinessSurvey"
import { jobFinderReducer, jobFinderSaga } from "./features/jobFinder"
import { jobMatcherReducer, jobMatcherSaga } from "./features/jobMatcher"
import { mentorMatcherReducer, mentorMatcherSaga } from "./features/mentorMatcher"
import { MiscReducer, MiscSaga } from "./features/misc"
import { myLibraryReducer, myLibrarySaga } from "./features/myLibrary"
import { premiumReducer, premiumSagas } from "./features/premium"
import { rankingsReducer, rankingsSaga } from "./features/rankings"
import { surveyReducer, surveySaga } from "./features/survey"
import { surveyResultsReducer, surveyResultsSaga } from "./features/surveyResults"
import { uiReducer, uiSaga } from "./features/ui"
import { userContentReducer, userContentSaga } from "./features/userGeneratedContent"
import { reducer as yourProfileReducer } from "./features/yourProfile/store/yourProfile.reducer"
import { saga as yourProfileSaga } from "./features/yourProfile/store/yourProfile.sagas"
import { yourTestsReducer, yourTestsSaga } from "./features/yourTests"

const sagaMiddleware = createSagaMiddleware()

const reduxDevToolParamaters = {
  trace: true,
  traceLimit: 20
}

const withDevTools =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(reduxDevToolParamaters)) ||
  compose

const rootReducerPersistConfig = {
  whitelist: ["jobFinder", "cms"],
  key: "root",
  storage
}

const appReducer = combineReducers({
  account: accountReducer,
  saml: samlReducer,
  bipProfile: businessProfilePageReducer,
  bookmarks: bookmarkReducer,
  businessLibrary: businessLibraryReducer,
  connect: connectReducer,
  cvLibrary: cvLibraryReducer,
  developCareer: developCareerReducer,
  expertAdvice: expertAdviceReducer,
  globalHub: globalHubReducer,
  happiness: happinessReducer,
  happinessSurvey: happinessSurveyReducer,
  jobFinder: jobFinderReducer,
  jobMatcher: jobMatcherReducer,
  libraryBooks: libraryBooksReducer,
  mentorMatcher: mentorMatcherReducer,
  misc: MiscReducer,
  myLibrary: myLibraryReducer,
  notifications: notificationsReducer,
  organisationSurveys: surveyReducer,
  rankingsData: rankingsReducer,
  surveyResults: surveyResultsReducer,
  thirdParty: thirdPartyReducer,
  ui: uiReducer,
  userContent: userContentReducer,
  yourProfile: yourProfileReducer,
  demographicFilter: demographicFilterReducer,
  yourTests: yourTestsReducer,
  userInterfaceNotifications: userInterfaceNotificationsStore.reducer,
  cms: cmsReducer,
  products: premiumReducer
})

const rootReducer = (state, action) => {
  if (action.type === "[Account] Sign Out Success") {
    // eslint-disable-next-line no-param-reassign
    state = undefined
  }
  return appReducer(state, action)
}
const persistedReducer = persistReducer(rootReducerPersistConfig, rootReducer)

function* rootSaga() {
  // yield spawn(premiumSaga)
  yield spawn(jobFinderSaga)
  yield spawn(businessLibrarySaga)
  yield spawn(myLibrarySaga)
  yield spawn(globalHubSaga)
  yield spawn(expertAdviceSaga)
  yield spawn(userContentSaga)
  yield spawn(bookmarkSaga)
  yield spawn(accountSaga)
  yield spawn(samlSaga)
  yield spawn(userInterfaceNotificationsStore.saga)
  yield spawn(thirdPartySaga)
  yield spawn(analyticsSaga)
  yield spawn(marketingSaga)
  yield spawn(libraryBooksSaga)
  yield spawn(surveySaga)
  yield spawn(happinessSurveySaga)
  yield spawn(surveyResultsSaga)
  yield spawn(uiSaga)
  yield spawn(rankingsSaga)
  yield spawn(businessProfilePageSaga)
  yield spawn(happinessSaga)
  yield spawn(MiscSaga)
  yield spawn(developCareerSaga)
  yield spawn(notificationsSaga)
  yield spawn(connectSaga)
  yield spawn(yourProfileSaga)
  yield spawn(jobMatcherSaga)
  yield spawn(cvLibrarySaga)
  yield spawn(mentorMatcherSaga)
  yield spawn(yourTestsSaga)
  yield spawn(cmsSaga)
  yield spawn(premiumSagas)
  yield spawn(demographicFilterSaga)
}

const initialize = () => {
  const store = createStore(persistedReducer, withDevTools(applyMiddleware(sagaMiddleware)))

  sagaMiddleware.run(rootSaga)

  const persistor = persistStore(store)
  return { store, persistor }
}

export default initialize
