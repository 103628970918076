import React from "react"
import styled from "styled-components"
import { Card, Flex } from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"

const FullWidthLink = styled(Link)`
  cursor: "pointer";
`

const Thumbnail = styled.img`
  height: 180px;
  max-width: 132px;
  width: auto;
`

const Preview = ({ book }) => {
  const paths = usePaths()

  return (
    <FullWidthLink to={`${paths.resourceCentre.browse.books.index}/${book.id}`}>
      <Card my={3} mr={4} p={3} bg="light.0" flexDirection={["column", "row"]}>
        {book.image && (
          <Flex width={1 / 1} justifyContent={["center"]}>
            <Thumbnail
              src={`https://gardnersmedia.engaging.works${book.image}`}
            />
          </Flex>
        )}
      </Card>
    </FullWidthLink>
  )
}

export default Preview
