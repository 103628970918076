// eslint-disable-next-line import/prefer-default-export
export const formatTitle = title => {
  const spaced = title
    .split("-")
    .map(word =>
      word.includes("%")
        ? word.substring(1)
        : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join(" ")
  if (spaced.includes("~")) return spaced.split("~").join("-")
  return spaced
}
