import { Box, TextField } from "@engaging-tech/components"
import React, { useState, useEffect } from "react"

const ShortText = ({ fields, onAnswer, initialValue }) => {
  const setFields = () => {
    let idx = fields.length
    const inputs = []
    while (idx > 0) {
      idx -= 1
      if (initialValue?.value?.["0"]) {
        inputs[idx] = initialValue.value[0][idx]
      } else {
        inputs[idx] = null
      }
    }

    return inputs
  }

  const [values, setValues] = useState(setFields())

  const handleUpdateInputValue = (value, index) => {
    const current = [...values]
    current[index] = value

    setValues(current)
  }
  useEffect(() => {
    onAnswer(values.map(val => (!val ? "" : val)))
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [values])

  return (
    <Box mt={[3, 5]}>
      {fields.map((field, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={index} mb={3}>
          <TextField
            width={1 / 1}
            label={field.label}
            value={values[index] || ""}
            maxLength={field.limit}
            onChange={e => handleUpdateInputValue(e.target.value, index)}
            onBlur={e => handleUpdateInputValue(e.target.value, index)}
          />
        </Box>
      ))}
    </Box>
  )
}

export default ShortText
