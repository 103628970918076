import { Flex } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"

import { happinessSurveySelectors } from "../../features/happinessSurvey"
import SubmitSurvey from "../../features/happinessSurvey/views/Submit"
import gradientCss from "../../helpers/gradientCss"
import AppViewBaseLayout from "../../layouts/AppViewBaseLayout"

const GradientBox = styled(Flex)`
  ${({ theme }) => gradientCss(theme)}
`

export default function Submit() {
  const paths = usePaths()
  const router = useRouter()

  const submissionId = useSelector(happinessSurveySelectors.getSubmissionId)

  const toSignIn = () => router.navigate(paths.account.signIn)
  const toSignUp = () => router.navigate(paths.account.signUp)
  const toHome = () => router.navigate(paths.home)
  const toResults = () => router.navigate(paths.happinessSurvey.results.replace(":id", submissionId))

  return (
    <AppViewBaseLayout mx={3}>
      <GradientBox as="main" flexDirection="column" minHeight="100vh">
        <Flex width={1 / 1} flex="1" alignItems="center" justifyContent="center" px={[3, 3, 4]} mb={[60]}>
          <Flex justifyContent="center">
            <SubmitSurvey
              onSignUp={toSignUp}
              onSignIn={toSignIn}
              onFinish={toHome}
              onSeeResults={toResults}
            />
          </Flex>
        </Flex>
      </GradientBox>
    </AppViewBaseLayout>
  )
}
