/* eslint-disable import/prefer-default-export */
import { gql } from "../../../lib/Apollo"

export const LOAD = gql`
  query($id: ID!) {
    getSurveyResults(surveySubmissionId: $id) {
      status
      type
      title
      surveyId  
      surveyTemplateId
      languages {
        code
        name
        nativeName
      }

      results {
        completedAt
        layout {
          name
          children {
            name
          }
        }
        data {
          ... on OrganisationResultsData {
            happinessDataHighlight {
              score
              industry
              partial
              global
              trend
            }
            happinessSixSteps {
              name
              score
              partial
              global
              industry
              questions {
                id
                question
                values {
                  score
                  industry
                  global
                }
              }
            }
            mentalHealthWellBeing {
              score
            }
            matrices {
              name
              title
              description
              labels {
                x {
                  heading
                  questions
                }
                y {
                  heading
                  questions
                }
              }
              bands {
                topLeft {
                  title
                  description
                }
                topRight {
                  title
                  description
                }
                bottomLeft {
                  title
                  description
                }
                bottomRight {
                  title
                  description
                }
              }
              values {
                label
                values {
                  x
                  y
                }
              }
            }
          }

          ... on HappinessResultsData {
            happinessDataHighlight {
              score
              industry
              partial
              global
              trend
            }
            happinessSixSteps {
              name
              score
              partial
              global
              industry
              questions {
                id
                question
                values {
                  score
                  industry
                  global
                }
              }
            }
            mentalHealthWellBeing {
              score
            }
            matrices {
              name
              title
              description
              labels {
                x {
                  heading
                  questions
                }
                y {
                  heading
                  questions
                }
              }
              bands {
                topLeft {
                  title
                  description
                }
                topRight {
                  title
                  description
                }
                bottomLeft {
                  title
                  description
                }
                bottomRight {
                  title
                  description
                }
              }
              values {
                label
                values {
                  x
                  y
                }
              }
            }
          }

          ... on EmployeeStarterResultsData {
            happinessDataHighlight {
              score
              industry
              global
              partial
            }
          }

          ... on EmployeeExitResultsData {
            happinessDataHighlight {
              score
              industry
              global
              partial
            }
          }
        }
      }
    }
  }
`
