/* eslint-disable no-async-promise-executor */
import { getConfig } from "@engaging-tech/ssr-config"
import { API, Amplify, Auth } from "aws-amplify"

import { SEND_MAGIC_LINK_EMAIL } from "./account.queries"

export const signIn = ({ email, password }) => {
  return Auth.signIn({ username: email, password })
}

export const getCurrentUser = async () => {
  const user = await Auth.currentSession()
  return user.accessToken.payload
}

export const getSessionToken = async () => {
  const user = await Auth.currentSession()
  return user.getAccessToken().getJwtToken()
}

export const getIdToken = async () => {
  const session = await Auth.currentSession()
  return session.getIdToken().getJwtToken()
}

export const loginWithMagicLink = ({ email, code }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const currentUser = await Auth.signIn(email)
      await Auth.sendCustomChallengeAnswer(currentUser, code)
      const currentSession = await Auth.currentSession()
      resolve(currentSession)
    } catch (error) {
      reject(error)
    }
  })
}

export const sendMagicLinkService = ({ email }) => {
  const config = getConfig()
  Amplify.configure({
    aws_appsync_graphqlEndpoint: config.appSync.publicGatewayURL,
    aws_appsync_authenticationType: config.appSync.publicGatewayAuthType,
    aws_appsync_apiKey: config.appSync.publicGatewayApiKey,
    aws_appsync_region: config.appSync.publicGatewayRegion
  })

  return API.graphql({
    query: SEND_MAGIC_LINK_EMAIL,
    variables: { email }
  })
}
