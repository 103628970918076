const getGraphQLErrCode = err => {
  const extensions = err?.graphQLErrors?.[0] && err.graphQLErrors?.[0].extensions
  if (extensions?.code) {
    return extensions.code
  }

  return extensions?.downstreamErrors?.[0] && extensions?.downstreamErrors?.[0]?.extensions?.code
}

export default getGraphQLErrCode
