import { connect } from "react-redux"

import { getBasket } from "../store/libraryBooks.selectors"
import { updateQuantity, deleteProduct } from "../store/libraryBooks.actions"

import List from "../components/Basket/List"

const mapState = state => ({
  basket: getBasket(state),
  hasProducts: !!getBasket(state).length
})

const mapDispatch = dispatch => ({
  updateQuantity: ({ id, quantity }) =>
    dispatch(updateQuantity({ id, quantity })),
  deleteProduct: id => dispatch(deleteProduct(id))
})

const BasketBooks = connect(mapState, mapDispatch)(List)

export default BasketBooks
