import { Flex } from "@engaging-tech/components"
import React from "react"

import SurveyWelcomeView from "../../../features/survey/views/surveyWelcome"

const SurveyWelcome = () => (
  <Flex minHeight="100vh" flexDirection="column">
    <Flex as="main" flex="1" alignItems="stretch">
      <SurveyWelcomeView />
    </Flex>
  </Flex>
)

export default SurveyWelcome
