import React from "react"
import { Flex, Icon, Text } from "@engaging-tech/components"

const Benefit = ({ iconColour = "secondary.0", ...props }) => {
  return (
    <Flex alignItems="center" mb={4} mr={1}>
      {Object.keys(props) && (
        <Icon
          name={Object.keys(props)}
          bg={iconColour}
          p={20}
          borderRadius={99}
          mr={3}
          color="dark.2"
        />
      )}
      <Text color="dark.2" fontWeight={500}>
        {Object.values(props)}
      </Text>
    </Flex>
  )
}

export default Benefit
