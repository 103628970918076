export const types = {
  ADD_MATERIAL: "[User Content] Add Material",
  ADD_MATERIAL_SUCCESS: "[User Content] Add Material Success",
  ADD_MATERIAL_FAIL: "[User Content] Add Material Fail",
  LOAD_SERIES: "[User Content] Load Series",
  LOAD_SERIES_SUCCESS: "[User Content] Load Series Success",
  LOAD_SERIES_FAIL: "[User Content] Load Series Fail",
  CREATE_SERIES: "[User Content] Create Series",
  CREATE_SERIES_SUCCESS: "[User Content] Create Series Success",
  CREATE_SERIES_FAIL: "[User Content] Create Series Fail",
  RESET_CREATE: "[User Content] Reset Create",
  CREATE_PURCHASE_MATERIAL_INTENT:
    "[User Content] Create Purchase Material Intent",
  CREATE_PURCHASE_MATERIAL_INTENT_SUCCESS:
    "[User Content] Create Purchase Material Intent Success",
  CREATE_PURCHASE_MATERIAL_INTENT_FAIL:
    "[User Content] Create Purchase Material Intent Fail",
  PURCHASE_MATERIAL_SUCCESS: "[User Content] Purchase Material Success",
  PURCHASE_MATERIAL_FAIL: "[User Content] Purchase Material Fail"
}

export const addMaterial = (materialType, newMaterial) => ({
  type: types.ADD_MATERIAL,
  payload: {
    type: materialType,
    ...newMaterial
  }
})

export const addMaterialSuccess = post => ({
  type: types.ADD_MATERIAL_SUCCESS,
  payload: `Your ${post.type} was successfully published and a preview has been shared on the Global Hub`
})

export const addMaterialFail = () => ({
  type: types.ADD_MATERIAL_FAIL
})

export const loadSeries = userId => ({
  type: types.LOAD_SERIES,
  payload: userId
})

export const loadSeriesSuccess = seriesList => ({
  type: types.LOAD_SERIES_SUCCESS,
  payload: seriesList
})

export const loadSeriesFail = () => ({
  type: types.LOAD_SERIES_FAIL
})

export const createSeries = newSeries => ({
  type: types.CREATE_SERIES,
  payload: newSeries
})

export const createSeriesSuccess = series => ({
  type: types.CREATE_SERIES_SUCCESS,
  payload: series
})

export const createSeriesFail = () => ({
  type: types.CREATE_SERIES_FAIL
})

export const resetCreate = () => ({
  type: types.RESET_CREATE
})

export const createPurchaseMaterialIntent = materialId => ({
  type: types.CREATE_PURCHASE_MATERIAL_INTENT,
  payload: materialId
})

export const createPurchaseMaterialIntentSuccess = intentSecret => ({
  type: types.CREATE_PURCHASE_MATERIAL_INTENT_SUCCESS,
  payload: intentSecret
})

export const createPurchaseMaterialIntentFail = () => ({
  type: types.CREATE_PURCHASE_MATERIAL_INTENT_FAIL
})

export const purchaseMaterialSuccess = () => ({
  type: types.PURCHASE_MATERIAL_SUCCESS
})

export const purchaseMaterialFail = () => ({
  type: types.PURCHASE_MATERIAL_FAIL
})
