import { MotionSelect } from "@engaging-tech/components"
import React from "react"

export const FREQUENCIES = ["Daily", "Weekly", "Monthly"]

export default function Frequencies({ setSelectedFrequency }) {
  return (
    <MotionSelect
      id="frequency"
      name="frequency"
      borderRadius={20}
      height={40}
      bg="light.0"
      buttonTextStyling={{
        fontSize: 14,
        ml: 3,
        mr: 0,
        fontWeight: 400,
        color: "primary.0"
      }}
      mx={2}
      iconColor="primary.0"
      dropdownBg="grey.0"
      defaultOptionId={FREQUENCIES[0]}
      disableClearButton
      options={FREQUENCIES.map(frequency => ({
        id: frequency,
        value: frequency
      }))}
      border={{
        style: "solid",
        width: "1px",
        color: "rgba(1,62,76,1)"
      }}
      onChange={e => setSelectedFrequency(e)}
    />
  )
}
