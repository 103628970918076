import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { decode } from "@engaging-tech/jwt"

class ApolloInAppView {
  getLanguage = () => window && window.localStorage && window.localStorage.getItem("i18nextLng")

  saveToken = async token => {
    if (token) {
      localStorage.setItem("custom-token", token)
    }
  }

  createClientWithToken = async uri => {
    const token = localStorage.getItem("custom-token")
    const cache = new InMemoryCache()

    const httpLink = createHttpLink({ uri })

    const headerLink = setContext((_, { headers }) => {
      const language = this.getLanguage()
      const headerObj = { ...headers }
      const tokenDecoded = decode(token)

      if (token) headerObj.Authorization = `JWT ${token}`
      if (tokenDecoded) headerObj["user-token"] = JSON.stringify(tokenDecoded)

      if (language) headerObj["Content-Language"] = language

      return {
        headers: headerObj
      }
    })

    return new ApolloClient({
      uri,
      link: headerLink.concat(httpLink),
      cache
    })
  }

  query = async ({ uri, ...queryParams } = {}) => {
    const client = await this.createClientWithToken(uri)
    return client.query(queryParams)
  }

  mutate = async ({ uri, ...mutationParams } = {}) => {
    const client = await this.createClientWithToken(uri)
    return client.mutate(mutationParams)
  }
}

const apolloInAppView = new ApolloInAppView()

export { apolloInAppView }

export default ApolloInAppView
