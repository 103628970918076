import React from "react"
import { useSelector } from "react-redux"

import { businessProfilePageSelectors } from "../../features/business-profile"
import BusinessProfileView from "../../features/business-profile/views/business-profile-view"
import PageLayout from "../../layouts/PageLayout"

export default function BusinessInformation() {
  const bipInfo = useSelector(businessProfilePageSelectors.getBipInfo)

  return (
    <PageLayout
      bg={bipInfo?.profile?.status === "PUBLISHED" ? "#006666" : "#F5F5F5"}
      alignItems="center"
      flexDirection="column"
      isPublic
    >
      <BusinessProfileView />
    </PageLayout>
  )
}
