import React from "react"
import { string, object, array } from "yup"

import { Field, Area } from "../../PublishForm/PublishInput"

import CategorySelect from "../../PublishForm/CategorySelect"
import PublishForm from "../../PublishForm/PublishForm"
import UploadContent from "../../PublishForm/UploadContent"

const initialValues = {
  title: "",
  summary: "",
  seriesId: "",
  content: "",
  categories: [],
  attachments: []
}

const validation = object().shape({
  title: string().required("A title for your article is required"),
  seriesId: string(),
  summary: string().required("A short summary of your article is required"),
  content: string().required("Article content is required"),
  categories: array().of(string())
})

const CreateForm = ({
  onSubmit,
  isLoading,
  hasCreated,
  renderSeriesCreator: CreateSeries
}) => (
  <PublishForm
    postType="article"
    initialValues={initialValues}
    validation={validation}
    onSubmit={onSubmit}
    isLoading={isLoading}
    hasCreated={hasCreated}
    render={({
      handleChange,
      handleBlur,
      handleSelect,
      handleCloseDropdown,
      values,
      touched,
      setTouched,
      setValues,
      errors
    }) => {
      const handleAttachment = attachments => {
        setValues({ ...values, attachments })
        if (attachments.length > 0) {
          setTouched({ ...touched, attachments: true })
        }
      }

      return (
        <>
          <Field
            onSelect={handleSelect}
            id="article-title"
            name="title"
            type="text"
            label="Title"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
            error={touched.title && errors.title}
          />
          <CreateSeries name="seriesId" onChange={handleChange} />
          <CategorySelect
            label="Categories"
            name="categories"
            helperText="Select categories for your article (max 3)"
            onChange={handleChange}
            closeDropdownTrigger={handleCloseDropdown}
          />
          <Area
            onSelect={handleSelect}
            height={[248, 124]}
            id="article-summary"
            name="summary"
            type="text"
            label="Summary"
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={250}
            helperText="Summary and key points covered in your article"
            value={values.summary}
            error={touched.summary && errors.summary}
          />
          <Area
            onSelect={handleSelect}
            height={[372, 558]}
            id="article-content"
            name="content"
            type="text"
            label="Content"
            onChange={handleChange}
            onBlur={handleBlur}
            helperText="Add the content for your article. Include paragraphs"
            value={values.content}
            error={touched.content && errors.content}
          />
          <UploadContent
            helperTexts={[
              "Attach an image to make your article more eye catching.",
              "Please compress your image file to below 80MB before uploading."
            ]}
            onSelect={handleAttachment}
            hideHeader
          />
        </>
      )
    }}
  />
)

export default CreateForm
