import { CustomHooks, Flex, MotionFlex, Spinner } from "@engaging-tech/components"
import { useRouter } from "@engaging-tech/routing"
import add from "date-fns/add"
import { AnimatePresence } from "framer-motion"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import "url-search-params-polyfill"

import { UiSelectors } from "../../ui"
import SiteLogo from "../../ui/components/SiteLogo"
import { applyDiscount } from "../../yourProfile/store/yourProfile.actions"
import {
  getIsConfirmed,
  getIsLoading,
  getIsPremium,
  getPromoId,
  getShowSuccessPage
} from "../../yourProfile/store/yourProfile.selectors"
import SuccessStep from "../components/Success"
import PaymentMethodForm from "../containers/PaymentMethodForm"
import SubscriptionForm from "../containers/SubscriptionForm"
import UnverifiedStep from "../containers/UnverifiedStep"
import discountCodes from "../lib/discountCodes"
import subscriptionDetails from "../lib/subscriptionDetails"

const Subscription = () => {
  const dispatch = useDispatch()
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [discountInput, setDiscountInput] = useState("")
  const previousRoute = useSelector(UiSelectors.getPreviousRoute)
  const isPremium = useSelector(getIsPremium)
  const isLoading = useSelector(getIsLoading)
  const isConfirmed = useSelector(getIsConfirmed)

  const promoId = useSelector(getPromoId)
  const device = CustomHooks.useClientDevice()
  const showSuccessPage = useSelector(getShowSuccessPage)
  // const showSuccessPage = true
  const router = useRouter()
  const [isStripeLoading, setIsStripeLoading] = useState(false)
  let windowWidth = 0
  useEffect(() => {
    // Resets premium store
    dispatch(applyDiscount({ discountCode: "" }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const cancel = () => {
    router.navigate(previousRoute)
    dispatch(applyDiscount({ discountCode: "" }))
  }
  const onStripeLoadingUpdate = loading => {
    setIsStripeLoading(loading)
  }

  if (typeof window !== "undefined") {
    windowWidth = window.innerWidth
  }

  const getDiscountInfo = () => {
    if (promoId) {
      return discountCodes.find(obj => obj.ids.includes(promoId))
    }
    return {}
  }
  const discountInfo = getDiscountInfo()

  const getStartingDate = () => {
    let returnDate
    switch (discountInfo.discountType) {
      case "TRIAL_PERIOD":
        returnDate = add(new Date(), {
          months: discountInfo.value,
          days: subscriptionDetails.defaultTrialPeriodDays
        }).toLocaleDateString()
        break
      default:
        returnDate = add(new Date(), {
          days: subscriptionDetails.defaultTrialPeriodDays
        }).toLocaleDateString()
        break
    }
    return returnDate
  }

  const getTotal = () => {
    let returnTotal
    switch (discountInfo.discountType) {
      case "TRIAL_PERIOD":
        returnTotal = "0.00"
        break
      default:
        returnTotal = "0.00"
        break
    }
    return returnTotal
  }

  const variants = {
    paymentMethod: {
      height: 670,
      width: (windowWidth / 100) * 90 > 560 ? 560 : (windowWidth / 100) * 90,
      transition: { duration: 1 }
    },
    loading: {
      height: 300,
      width: (windowWidth / 100) * 90 > 560 ? 560 : (windowWidth / 100) * 90,
      transition: { duration: 1 }
    },
    subscription: stripeLoading => ({
      height: (() => {
        if (stripeLoading) {
          return 300
        }
        if (device === "MOBILE") {
          return 1130
        }
        return 1000
      })()
    }),
    success: {
      height: 700,
      width: (windowWidth / 100) * 90 > 1000 ? 1000 : (windowWidth / 100) * 90,
      transition: { duration: 1 }
    },
    unverified: {
      height: 350,
      width: (windowWidth / 100) * 90 > 560 ? 560 : (windowWidth / 100) * 90,
      transition: { duration: 1 }
    }
  }

  const getStep = () => {
    if (isLoading) {
      return {
        step: "loading",
        component: (
          <Flex width="100%" justifyContent="center" height="100%" alignItems="center">
            <Spinner color="primary.0" justifyContent="center" />
          </Flex>
        )
      }
    }
    if (!isConfirmed) {
      return {
        step: "unverified",
        component: (
          <UnverifiedStep
            startingDate={getStartingDate()}
            alreadyGotPremium={isPremium && !showSuccessPage}
          />
        )
      }
    }
    if (showSuccessPage || isPremium) {
      return {
        step: "success",
        component: (
          <SuccessStep discountInput={discountInput} alreadyGotPremium={isPremium && !showSuccessPage} />
        )
      }
    }
    if (!paymentMethod) {
      return {
        step: "paymentMethod",
        component: (
          <PaymentMethodForm
            cancel={cancel}
            startingDate={getStartingDate()}
            total={getTotal()}
            discountInfo={getDiscountInfo()}
            setPaymentMethod={setPaymentMethod}
            promoId={promoId}
            discountInput={discountInput}
            setDiscountInput={setDiscountInput}
          />
        )
      }
    }
    return {
      step: "subscription",
      component: (
        <SubscriptionForm
          cancel={cancel}
          onStripeLoadingUpdate={onStripeLoadingUpdate}
          startingDate={getStartingDate()}
          total={getTotal()}
        />
      )
    }
  }

  return (
    <MotionFlex
      variants={variants}
      custom={isStripeLoading}
      initial={getStep().step}
      animate={getStep().step}
      margin="0 auto"
      bg="light.0"
      borderRadius={4}
      flexDirection="column"
      p={4}
      mb={100}
      mt={50}
      elevation={12}
    >
      <Flex width="100%" mt={3} mb={4} justifyContent="center">
        <SiteLogo />
      </Flex>
      <AnimatePresence>{getStep().component}</AnimatePresence>
    </MotionFlex>
  )
}

export default Subscription
