import { connect } from "react-redux"
import { getQuestions } from "../store/selectors"

import BreakdownComponent from "../components/Breakdown"

const mapState = (state, { section }) => ({
  questions: getQuestions(state, section)
})

const Breakdown = connect(mapState)(BreakdownComponent)

export default Breakdown
