/* eslint-disable no-nested-ternary */
import { CircularProgress } from "@engaging-tech/charts"
import {
  Box,
  Button,
  CustomHooks,
  Flex,
  Icon,
  Modal,
  Table,
  Text,
  ToggleLogic
} from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"
import { createPortal } from "react-dom"
import styled from "styled-components"

import { getLabelData } from "../../../lib/utils"

const Image = styled("img")`
  width: 40px;
`

const MobileImage = styled("img")`
  width: 24px;
`

const ClickableIcon = styled(Icon)`
  cursor: pointer;
`

const appRoot = () => typeof document !== "undefined" && document.getElementById("root")

const LessThan60Modal = ({ toggle }) => {
  return createPortal(
    <Modal bg="light.0" width="560px">
      <Flex flexDirection="column" alignItems="center" width={1 / 1}>
        <Text
          fontWeight={600}
          fontSize={5}
          color="dark.2"
          textAlign="center"
          pt={3}
          pb={3}
          mb={3}
          bg="dark.7"
        >
          Is This Your Company?
        </Text>
        <Text fontWeight={600} fontSize={5} color="dark.2" textAlign="center" mt={2} width="80%">
          If a workplace has a score of less than 60% their score won't appear on the Happiest Workplaces
          List.
        </Text>
        <Text fontSize={4} color="dark.3" textAlign="center" mt={3} width="80%">
          If this is your workplace, or you would like to speak to our team about the Happiest Workplaces List
          please get in touch.
        </Text>
        <Text fontSize={4} color="dark.3" textAlign="center" mt={3} width="80%">
          Get in touch links through to WFB contact us.
        </Text>
        <Flex justifyContent="space-between" my={2}>
          <Button elevation={0} variant="text.dark.2" onClick={toggle} ml={2}>
            CANCEL
          </Button>
          <Link to={`${getConfig().workL.public.index}/contact`}>
            <Button variant="primary.0" mr={2}>
              get in touch
            </Button>
          </Link>
        </Flex>
      </Flex>
    </Modal>,
    appRoot()
  )
}

const scoreWidget = score => (
  <Flex width={1 / 1} justifyContent="center">
    {score > 60 ? (
      <CircularProgress
        percentage={score}
        width={50}
        height={50}
        strokeColor={getLabelData(score).color}
        thickness={0.45}
        fontSizeNoLabel={120}
      />
    ) : (
      <ToggleLogic>
        {({ on, toggle }) => (
          <>
            <ClickableIcon name="error" color="#FF9800" onClick={toggle} />
            {on && <LessThan60Modal toggle={toggle} />}
          </>
        )}
      </ToggleLogic>
    )}
  </Flex>
)

const LinkToBIP = ({ company, companyNameKey, year }) => {
  const paths = usePaths()
  const src = year
    ? `${paths.companyBusinessProfile.index}/${companyNameKey}/?award=${year}`
    : `${paths.companyBusinessProfile.index}/${companyNameKey}`
  return (
    <Link to={src}>
      <Flex justifyContent="center">
        <Text width="auto" color="primary.0">
          {company}
        </Text>
      </Flex>
    </Link>
  )
}

const DisplayTable = ({
  rows,
  config,
  headings,
  mobileConfig,
  mobileHeadings,
  isLoading,
  isDemographicFiltersSelected,
  year = false,
  type = "industries"
}) => {
  const clientDevice = CustomHooks.useClientDevice()

  const formattedRows = rows.map(row => {
    if (mobileConfig && clientDevice === "MOBILE") {
      return {
        ...row,
        "ranking/company": (
          <Box>
            {row.ranking}
            <LinkToBIP
              active={row.profile?.active}
              company={row.company}
              companyNameKey={row.companyNameKey}
              year={year}
            />
          </Box>
        ),
        "industry/country":
          type === "companies" ? (
            <Box>
              {row.industryId} <br />
              {row.countryId}
            </Box>
          ) : (
            <Box>
              {row.industry} <br />
              {row.country}
            </Box>
          ),
        awards: (
          <Flex justifyContent="center" width={1 / 1}>
            {row.category_wins.map(win => (
              <MobileImage src={win} />
            ))}
          </Flex>
        ),
        happinessScore:
          type === "companies"
            ? isDemographicFiltersSelected
              ? scoreWidget(row.filteredHappinessScores)
              : scoreWidget(row.happinessScores)
            : scoreWidget(row.happinessScore)
      }
    }
    return {
      ...row,
      ranking: row.ranking,
      company: (
        <LinkToBIP active={row.profile?.active} company={row.company} companyNameKey={row.companyNameKey} />
      ),
      industry: type === "companies" ? row.industryId : row.industry,
      country: type === "companies" ? row.countryId : row.country,
      awards: (
        <Flex justifyContent="center" width={1 / 1}>
          {row.category_wins.map(win => (
            <Image src={win} />
          ))}
        </Flex>
      ),
      happinessScore:
        type === "companies"
          ? isDemographicFiltersSelected
            ? scoreWidget(row.filteredHappinessScores)
            : scoreWidget(row.happinessScores)
          : scoreWidget(row.happinessScore)
    }
  })

  return (
    <Flex justifyContent="center">
      <Table
        config={mobileConfig && clientDevice === "MOBILE" ? mobileConfig : config}
        headings={mobileConfig && clientDevice === "MOBILE" ? mobileHeadings : headings}
        rows={formattedRows}
        width={1 / 1}
        pr={0}
        mt={3}
        isLoading={isLoading}
      />
    </Flex>
  )
}

export default DisplayTable
