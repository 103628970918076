const discountCodes = [
  {
    ids: [
      "promo_1INgiNJ56d8MdsEKKCsnlShz",
      "promo_1INgojJ56d8MdsEKOHUJpBip",
      "promo_1IOLjlJ56d8MdsEKQEqv690P",
      "promo_1IOOBhJ56d8MdsEKgjW1wmh0",
      "promo_1IXlSlJ56d8MdsEKc7aBFWVE",
      "promo_1IXn4CJ56d8MdsEKunNJuWKJ"
    ],
    discountAppliedText: "3 Months Free",
    discountType: "TRIAL_PERIOD",
    value: 3
  }
]

export default discountCodes
