import { useLocation, usePaths, useRouter } from "@engaging-tech/routing"
import React from "react"
import { useSelector } from "react-redux"

import { happinessSurveySelectors } from "../../features/happinessSurvey"
import SubmitSurvey from "../../features/happinessSurvey/views/Submit"
import SurveyLayout from "../../layouts/SurveyLayout"

const Submit = () => {
  const paths = usePaths()
  const router = useRouter()
  const { pathname } = useLocation()

  const inAppView = pathname.includes("in-app-view")

  const submissionId = useSelector(happinessSurveySelectors.getSubmissionId)

  const toSignIn = () => router.navigate(paths.account.signIn)
  const toSignUp = () => router.navigate(paths.account.signUp)
  const toHome = () => router.navigate(paths.home)
  const toResults = () => {
    if (inAppView) {
      router.navigate(`/in-app-view/happiness-survey/results/${submissionId}`)
    } else {
      router.navigate(`/happiness-survey/results/${submissionId}`)
    }
  }

  return (
    <SurveyLayout>
      <SubmitSurvey onSignUp={toSignUp} onSignIn={toSignIn} onFinish={toHome} onSeeResults={toResults} />
    </SurveyLayout>
  )
}

export default Submit
