import { Redirect, usePaths } from "@engaging-tech/routing"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import FormFlowWizard from "../../formFlowWizard/components/formFlowWizard"
import { yourProfileSelectors } from "../../yourProfile"
import {
  currentRoleTemplate,
  desiredLocationTemplate,
  desiredRoleTemplate,
  desiredSalaryTemplate,
  featureToggleTemplate
} from "../components/formStepTemplates"
import { submit } from "../store/jobMatcher.actions"
import { getError, getIsLoading, getIsSubmitting } from "../store/jobMatcher.selectors"

const initialDataStructure = {
  currentJobRole: "",
  currentJobSalary: 0,
  desiredJobRoles: [],
  desiredJobType: "",
  desiredJobSalary: {
    min: 20000,
    max: 30000
  },
  desiredJobLocation: {
    name: "",
    country: {
      id: null,
      name: ""
    },
    coordinates: {
      lat: null,
      lng: null
    }
  }
}

const Form = ({ redirectTo }) => {
  const paths = usePaths()

  const userProfile = useSelector(yourProfileSelectors.getProfile)
  const jobMatcherProfile = useSelector(yourProfileSelectors.getJobMatcherProfile)
  const jobMatcherDesiredLocation = useSelector(yourProfileSelectors.getJobMatcherDesiredLocation)
  const isLoading = useSelector(getIsLoading)
  const hasError = useSelector(getError)
  const isSubmitting = useSelector(getIsSubmitting)

  const initialData = {
    ...initialDataStructure,
    ...jobMatcherProfile,
    desiredJobLocation: jobMatcherDesiredLocation
  }

  const dispatch = useDispatch()

  const onSubmit = formDetails => dispatch(submit(formDetails))

  if (userProfile.premium) {
    return (
      <FormFlowWizard
        redirectTo={redirectTo}
        isLoading={isLoading}
        hasError={hasError}
        isSubmitting={isSubmitting}
        initialData={initialData}
        formStepTemplates={[
          currentRoleTemplate,
          desiredRoleTemplate,
          desiredSalaryTemplate,
          desiredLocationTemplate,
          featureToggleTemplate
        ]}
        onSubmit={onSubmit}
      />
    )
  }

  return <Redirect to={paths.index} />
}

export default Form
