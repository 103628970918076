import { createSelector } from "reselect"

import { getAccountEmail } from "../../account/store/account.selectors"
import { getProfile } from "../../yourProfile/store/yourProfile.selectors"

const cvLibraryState = state => state && state.cvLibrary

export const getFirstName = createSelector(getProfile, profile => profile?.firstName)
export const getLastName = createSelector(getProfile, profile => profile?.lastName)

export const getCVs = createSelector(cvLibraryState, library => library?.cvs)

export const getIsLoading = createSelector(cvLibraryState, library => library?.isLoading)
export const getIsLoadingCVs = createSelector(cvLibraryState, library => library?.isLoadingCVs)

export const getSelectedCV = createSelector(cvLibraryState, library => library?.selectedCV)

export const getInitialData = createSelector([getSelectedCV, getAccountEmail], (cv, email) => {
  const initialCV = {
    ...cv,
    email,
    positionToAdd: null,
    qualToAdd: null,
    references: cv?.references ? { id: "1" } : { id: "2" }
  }
  if (cv?.skills) initialCV.skills = [...cv?.skills?.map(skill => skill?.id)]
  if (cv?.hobbies) initialCV.hobbies = [...cv?.hobbies?.map(hobby => hobby?.id)]
  return initialCV
})
export const getHasCreatedCV = createSelector(cvLibraryState, library => library?.hasCreatedCV)

export const getIsCreatingCV = createSelector(cvLibraryState, library => library?.isCreatingCV)
