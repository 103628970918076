import React from "react"
import { Text, Flex, Button } from "@engaging-tech/components"
import { usePaths, Link } from "@engaging-tech/routing"

import SiteBanner from "../../ui/components/SiteBanner"

const PremiumBanner = () => {
  const paths = usePaths()
  return (
    <SiteBanner>
      <Flex
        flexDirection="row"
        mb={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Text width="auto">
          Sign up to see the latest jobs marketplace updates and make use of the
          job matcher service.
        </Text>
        <Link to={paths.getPremium.index}>
          <Button variant="text.dark.2" elevation="0" width="auto">
            Sign Up
          </Button>
        </Link>
      </Flex>
    </SiteBanner>
  )
}

export default PremiumBanner
