import React from "react"
import { getConfig } from "@engaging-tech/ssr-config"
import PromotionCard from "../../../ui/components/PromotionCard"

const BusinessServices = () => {
  return (
    <PromotionCard
      title="Business services"
      content={[
        "- Post events for your organisation",
        "- Advertise job postings",
        "- Receive leads from users"
      ]}
      action={{
        description: "WorkL For Business Services",
        linkTo:
          getConfig().worklForBusiness.public.index +
          getConfig().worklForBusiness.public.paths.worklBusinessNetwork,
        external: true,
        newTab: true
      }}
    />
  )
}

export default BusinessServices
