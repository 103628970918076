import { connect } from "react-redux"
import {
  getHasLoaded,
  getIsLoading,
  getConnectResponse,
  getHasError,
  getInvite
} from "../store/connect.selectors"
import { connectUser } from "../store/connect.actions"

import ConnectModalComponent from "../components/ConnectModal"

const mapState = (state, { id }) => ({
  isLoading: getIsLoading(state, id),
  hasLoaded: getHasLoaded(state, id),
  connectResponse: getConnectResponse(state, id),
  hasError: getHasError(state, id),
  currentInvite: getInvite(state, id)
})

const mapDispatch = dispatch => ({
  connectUser: userId => dispatch(connectUser(userId))
})

const ConnectModal = connect(mapState, mapDispatch)(ConnectModalComponent)

export default ConnectModal
