import { Card, Flex, Modal, Text, Utils } from "@engaging-tech/components"
import React from "react"
import { createPortal } from "react-dom"
import { useTranslation } from "react-i18next"

import PercentageGraphic from "../../../ui/components/PercentageGraphic"
import Breakdown from "../containers/Breakdown"
import ExplanationTopBar from "./ExplanationTopBar"

const appRoot = () => typeof document !== "undefined" && document.getElementById("root")

const Explanation = ({ setVisibility, heading, score, section, numQuestions }) => {
  const { t } = useTranslation(["results"])

  return createPortal(
    <Modal bg="light.0" onToggle={() => setVisibility(false)} width={1 / 6} my={[5]}>
      <Utils.DisableScroll />
      <Flex flexDirection="column" width={1 / 1} alignItems="center">
        <ExplanationTopBar setVisibility={setVisibility} heading={heading} />
        <Card width={1 / 1} alignItems="center" px={[3, 4]}>
          <PercentageGraphic score={score} />

          {numQuestions > 1 ? (
            <Text width={3 / 4} textAlign="center" fontWeight={500} fontSize={3}>
              {t("six_steps.explanation_points.point1", "")} {t(`six_steps.numbers.${[numQuestions]}`)}{" "}
              {t("six_steps.explanation_points.point2", "")} {heading}:
            </Text>
          ) : (
            <Text width={3 / 4} textAlign="center" fontWeight={500} fontSize={3}>
              {t("six_steps.explanation_points.point3", "")} {heading}:
            </Text>
          )}

          <Breakdown heading={heading} section={section} />

          <Text textAlign="center" fontSize={3} fontWeight={500} color="dark.2" mb={3}>
            {t("six_steps.explanation_points.point4", "")} {heading}{" "}
            {t("six_steps.explanation_points.point5", "")}
          </Text>
        </Card>
      </Flex>
    </Modal>,
    appRoot()
  )
}

export default Explanation
