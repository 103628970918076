import { userInterfaceNotificationsStore } from "@engaging-tech/components"
import { Auth } from "aws-amplify"
import { call, put, takeLatest } from "redux-saga/effects"

import { load, types as yourProfileActionsTypes } from "../../yourProfile/store/yourProfile.actions"
import * as Actions from "./account.actions"

export const EMAIL_SENT_ERROR_CODE = "EMAIL_SENT"
export const PASSWORD_RESET = "RESET_PASSWORD"

export function* getTokenSaga() {
  try {
    const userSession = yield call(Auth.currentSession())
    const token = userSession.getAccessToken()
    const tokenDecoded = token.payload

    const expireDate = tokenDecoded && new Date(tokenDecoded.exp * 1000)
    const isValid = expireDate > new Date()

    yield isValid ? put(Actions.signInSuccess(token)) : put(Actions.tokenSignInFail())
  } catch (error) {
    yield put(Actions.tokenSignInFail(error))
  }
}

export function* showNotificationSaga(action) {
  if (action?.payload?.message) {
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message:
          typeof action?.payload?.message === "string" ? action.payload.message : "There has been a error"
      })
    )
  }
}

export function* authSuccessSaga(action) {
  yield put(load({ userId: action.payload }))
}

export function* sendMagicLinkSuccessSaga() {
  yield put(Actions.sendMagicLinkSuccess()) // todo get userId
}

export function* saga() {
  yield takeLatest(Actions.types.TOKEN_SIGN_IN, getTokenSaga)
  yield takeLatest([Actions.types.SIGN_IN_MAGIC_LINK_SUCCESS, Actions.types.SIGN_IN_SUCCESS], authSuccessSaga)
  yield takeLatest(
    [Actions.types.SIGN_IN_FAIL, yourProfileActionsTypes.LOAD_FAIL, Actions.types.SEND_MAGIC_LINK_FAIL],
    showNotificationSaga
  )
}
