import React from "react"

import {
  DemographicFilterButton as DemographicFilterButtonButton,
  DemographicFilterWrapper,
  FilterDropdownIcon
} from "./demographicFilterButton.styled"

export default function DemographicFilterButton({ isSelected, onOpen }) {
  return (
    <DemographicFilterWrapper py={[1, 0]}>
      <DemographicFilterButtonButton selected={isSelected} onClick={onOpen}>
        <FilterDropdownIcon />
        HAPPIEST WORKPLACES FOR YOU
      </DemographicFilterButtonButton>
    </DemographicFilterWrapper>
  )
}
