import React from "react"
import styled from "styled-components"
import { Flex, Box, List, Button, Paragraph } from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"

import ContentBlock from "./ContentBlock"

/* eslint-disable-next-line import/no-dynamic-require */
const image = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/survey-sample.png`)

const Image = styled.img`
  width: 100%;
  height: 100%;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
    0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12);
`

const WorkplaceHappinessSurvey = () => {
  const paths = usePaths()

  return (
    <ContentBlock title="Workplace Happiness Survey" bg="dark.7">
      <Flex
        flexDirection={["column", "row"]}
        alignItems={["center", "flex-start"]}
      >
        <Box width={[1 / 1, 1 / 2]} mr={[0, 5]} mb={[5, 0]}>
          <Link to={paths.happinessSurvey.index}>
            <Image src={image} />
          </Link>
        </Box>
        <Box width={[1 / 1, 1 / 2]}>
          <Paragraph color="dark.2" width="auto">
            Start with our workplace happiness survey to see how your work life
            can be improved
          </Paragraph>
          <List.Wrapper>
            <List.Item fontSize={3} color="dark.2">
              Our quick and easy survey measures your workplace happiness and
              how it compares to others like you.
            </List.Item>
            <List.Item fontSize={3} color="dark.2">
              The survey identifies areas for development.
            </List.Item>
            <List.Item fontSize={3} color="dark.2">
              We then recommend how you can have a more fulfilling time at work.
            </List.Item>
          </List.Wrapper>
          <Link to={paths.happinessSurvey.index}>
            <Button width={[1 / 1, "165px"]} variant="secondary.0">
              Happy at Work Test
            </Button>
          </Link>
        </Box>
      </Flex>
    </ContentBlock>
  )
}

export default WorkplaceHappinessSurvey
