import { connect } from "react-redux"

import {
  UserContentActions,
  UserContentSelectors
} from "../../userGeneratedContent"

import { getSelected, getIsLoading } from "../store/myLibrary.selectors"
import { select } from "../store/myLibrary.actions"

import BookmarkedMaterialComponent from "../components/BookmarkedMaterial"

const mapState = state => ({
  isLoading: getIsLoading(state),
  post: getSelected(state),
  intentSecret: UserContentSelectors.getIntentSecret(state)
})

const mapDispatch = dispatch => ({
  onLoad: postId => dispatch(select(postId)),
  onSubmit: postId =>
    dispatch(UserContentActions.createPurchaseMaterialIntent(postId)),
  onPaymentActionSuccess: () =>
    dispatch(UserContentActions.purchaseMaterialSuccess()),
  onPaymentActionFail: () => dispatch(UserContentActions.purchaseMaterialFail())
})

const PostDetailsContainer = connect(
  mapState,
  mapDispatch
)(BookmarkedMaterialComponent)

export default PostDetailsContainer
