import { Text } from "@engaging-tech/components"
import React from "react"

const EmailLink = ({ url }) => (
  <Text color="primary.0">
    <a href={`mailto:${url}`} rel="noopener noreferrer" target="_blank">
      {url}
    </a>
  </Text>
)

export default EmailLink
