import React from "react"
import { useTranslation } from "react-i18next"
import { Text, Icon, Flex } from "@engaging-tech/components"
import { getLabelData } from "../../../lib/utils"

const ScoreLabel = ({
  score,
  noIcon,
  width = "auto",
  fontSize = 3,
  noData,
  ...props
}) => {
  const labelData = getLabelData(score)
  const { t } = useTranslation(["results"])
  return (
    <Flex justifyContent="center" alignItems="center" width={width} {...props}>
      {!noIcon && !noData && (
        <Icon
          name={labelData.icon}
          color={labelData.color}
          mr={0}
          width="auto"
        />
      )}
      <Text
        color={score ? labelData.color : "dark.3"}
        pl={1}
        fontWeight={500}
        width="auto"
        fontSize={fontSize}
        textAlign="center"
      >
        {noData ? <strong>No Score</strong> : t(...labelData.text)}
      </Text>
    </Flex>
  )
}

export default ScoreLabel
