import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Spinner } from "@engaging-tech/components"

import { load } from "../store/happinessSurvey.actions"
import { getHasLoaded, getIsLoading } from "../store/happinessSurvey.selectors"

const LoaderComponent = ({ surveyId: id, hasLoaded, isLoading, onLoad }) => {
  useEffect(() => {
    if (!hasLoaded) {
      onLoad(id)
    }
  }, [id, hasLoaded, onLoad])

  return isLoading && <Spinner color="light.0" />
}

const mapState = state => ({
  hasLoaded: getHasLoaded(state),
  isLoading: getIsLoading(state)
})

const mapDispatch = dispatch => ({
  onLoad: id => dispatch(load(id))
})

const Loader = connect(mapState, mapDispatch)(LoaderComponent)

export default Loader
