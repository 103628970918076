/* eslint-disable import/prefer-default-export */
import { Text } from "@engaging-tech/components"
import styled from "styled-components"

export const EllipisText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
`
