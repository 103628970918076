import { call, put, takeLatest } from "redux-saga/effects"

import { showNotification } from "../../ui/store/ui.actions"
import { updateYourProfile } from "../../yourProfile/store/yourProfile.actions"
import { deleteJobMatch, loadJobMatches, updateJobMatcherProfile } from "../services/jobMatcher.service"
import * as Actions from "./jobMatcher.actions"

export function* loadJobMatchesSaga() {
  try {
    const jobMatchesData = yield call(loadJobMatches)

    if (jobMatchesData?.data?.getMatchedJobs) {
      yield put(Actions.loadJobMatchesSuccess(jobMatchesData.data.getMatchedJobs))
    } else {
      yield put(Actions.setHasError("Failed To Load Job Matches"))
    }
  } catch {
    yield put(Actions.setHasError("Failed To Load Job Matches"))
  }
}

export function* deleteJobMatchSaga(action) {
  try {
    const deletedJobMatchSuccess = yield call(() => deleteJobMatch({ jobMatchId: action.payload }))

    if (deletedJobMatchSuccess) {
      yield put(Actions.loadJobMatches())
      yield put(showNotification("Deleted Job Match"))
    } else {
      yield put(showNotification("Failed To Delete Job Match"))
    }
  } catch {
    yield put(showNotification("Failed To Delete Job Match"))
  }
}

export function* updateJobMatcherProfileSaga(action) {
  try {
    const res = yield call(updateJobMatcherProfile, action.payload)

    if (res.data.updateJobMatcher) {
      yield put(updateYourProfile({ jobMatcher: res.data.updateJobMatcher }))
      yield put(Actions.submitSuccess())
    }
  } catch {
    yield put(Actions.setHasError("Failed To Update Job Matcher Profile"))
  }
}

export function* saga() {
  yield takeLatest(Actions.types.LOAD_JOB_MATCHES, loadJobMatchesSaga)
  yield takeLatest(Actions.types.DELETE_JOB_MATCH, deleteJobMatchSaga)
  yield takeLatest(Actions.types.SUBMIT, updateJobMatcherProfileSaga)
}
