import React from "react"

import BaseLayout from "../BaseLayout"
import { Content } from "./sideColumnLayout.styled"

const SideColumnLayout = ({ children, ...props }) => (
  <BaseLayout {...props}>
    <Content>{children}</Content>
  </BaseLayout>
)

export default SideColumnLayout
