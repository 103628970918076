import { Flex } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React from "react"
import styled from "styled-components"

import SurveyQuestions from "../../features/happinessSurvey/views/Questions"
import gradientCss from "../../helpers/gradientCss"
import AppViewBaseLayout from "../../layouts/AppViewBaseLayout"

const GradientBox = styled(Flex)`
  ${({ theme }) => gradientCss(theme)}
`

export default function Questions() {
  const paths = usePaths()
  const router = useRouter()

  const toSurveyStart = () => {
    router.navigate(paths.inAppViewHappinessSurvey.language)
  }
  const toSubmit = () => router.navigate(paths.inAppViewHappinessSurvey.submit)

  return (
    <AppViewBaseLayout mx={3}>
      <GradientBox as="main" flexDirection="column" minHeight="100vh">
        <Flex width={1 / 1} flex="1" alignItems="center" justifyContent="center" px={[3, 3, 4]} mb={[60]}>
          <Flex justifyContent="center">
            <SurveyQuestions onComplete={toSubmit} onCancel={toSurveyStart} />
          </Flex>
        </Flex>
      </GradientBox>
    </AppViewBaseLayout>
  )
}
