import React from "react"
import { useSelector } from "react-redux"

import FailureDialog from "../components/failureDialog"
import SuccessDialog from "../components/successDialog"
import TestDialog from "../components/testDialog"
import BrandingWrapper from "../layouts/brandingWrapper"
import IsolatedWrapper from "../layouts/isolatedWrapper"
import AttachUserLoader from "../loaders/attachUserLoader"
import SubmitterLoader from "../loaders/submitterLoader"
import {
  getData,
  getHasError,
  getHasSubmitted,
  getIsIsolated,
  getIsIsolatedLoading,
  getIsPrivate,
  getIsTestSurvey,
  getSavantaId
} from "../store/survey.selectors"

const SurveySubmit = () => {
  const data = useSelector(getData)
  const hasSubmitted = useSelector(getHasSubmitted)
  const hasError = useSelector(getHasError)
  const isTest = useSelector(getIsTestSurvey)
  const isPrivate = useSelector(getIsPrivate)
  const savantaId = useSelector(getSavantaId)

  const isIsolated = useSelector(getIsIsolated)
  const isIsolatedLoading = useSelector(getIsIsolatedLoading)

  const shouldShowIsolatedContent = isIsolated || isIsolatedLoading

  const Wrapper = shouldShowIsolatedContent ? IsolatedWrapper : BrandingWrapper

  return (
    <Wrapper>
      <SubmitterLoader />
      <AttachUserLoader />
      {hasSubmitted && isTest && <TestDialog />}
      {hasSubmitted && !isTest && (
        <SuccessDialog title={data.title} isPrivate={isPrivate} savantaId={savantaId} />
      )}
      {hasError && <FailureDialog />}
    </Wrapper>
  )
}

export default SurveySubmit
