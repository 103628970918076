import { connect } from "react-redux"
import {
  notificationsSelectors,
  notificationsActions
} from "../../../Notifications"
import DeleteAlertComponent from "../components/DeleteAlert"

const mapState = state => ({
  activeAlert: notificationsSelectors.getActiveAlert(state)
})

const mapDispatch = dispatch => ({
  deleteAlert: (id, type) =>
    dispatch(notificationsActions.deleteAlert({ id, type }))
})

const DeleteAlert = connect(mapState, mapDispatch)(DeleteAlertComponent)

export default DeleteAlert
