import React from "react"

import SurveyWelcomeView from "../../features/survey/views/surveyWelcome"
import OrganisationSurveyLayout from "../../layouts/organisationSurveyLayout"

const SurveyWelcome = () => (
  <OrganisationSurveyLayout>
    <SurveyWelcomeView />
  </OrganisationSurveyLayout>
)

export default SurveyWelcome
