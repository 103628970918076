import { call, put, takeLatest } from "redux-saga/effects"

import { loadAll, loadById } from "../services/myLibrary.service"
import * as Actions from "./myLibrary.actions"

export function* loadSaga(action) {
  try {
    const res = yield call(loadAll, action.payload)
    const loadedContent = res.data.myBookmarks.map(({ refTarget, createdAt }) => ({
      ...refTarget,
      bookmarkedAt: createdAt
    }))

    yield put(Actions.loadSuccess(loadedContent))
  } catch {
    yield put(Actions.loadFail())
  }
}

export function* selectSaga(action) {
  try {
    const res = yield call(loadById, action.payload)
    yield put(Actions.selectSuccess(res.data.AllMaterials[0]))
  } catch {
    yield put(Actions.selectFail())
  }
}

export function* saga() {
  yield takeLatest(Actions.types.LOAD, loadSaga)
  yield takeLatest(Actions.types.SELECT, selectSaga)
}
