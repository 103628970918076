/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable import/no-dynamic-require */
import { Flex, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"
import styled from "styled-components"

const googlePlay = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/google-play.png`)
const itunes = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/app-store.svg`)

const Badge = styled.img`
  max-height: 40px;
  margin-right: ${({ theme }) => theme.space[2]}px;
`

const AppBadges = ({ alignment, ...props }) => (
  <Flex {...props}>
    <Flex width="auto" flexDirection="column" alignItems={alignment}>
      <Text color="dark.2" mb={3}>
        Get our Workl App
      </Text>
      <Flex justifyContent="flex-end" width="auto">
        <Link newTab to={getConfig().appLinks.android}>
          <Badge src={googlePlay} alt="Google play store" />
        </Link>
        <Link newTab to={getConfig().appLinks.ios}>
          <Badge src={itunes} alt="iTunes App Store" />
        </Link>
      </Flex>
    </Flex>
  </Flex>
)

AppBadges.defaultProps = {
  flexDirection: ["column", "row"],
  justifyContent: ["center", "flex-end", "flex-end"],
  alignItems: "center",
  width: [1 / 1, 1 / 2, 1 / 2],
  px: 3,
  alignment: ["center", "flex-start"]
}

export default AppBadges
