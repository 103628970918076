import styled from "styled-components"

// @ts-ignore
const SliderNumberField = styled.input`
  caret-color: ${({ theme, error }) =>
    error ? theme.colors.error[0] : theme.colors.primary[0]};
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: ${({ theme }) => theme.fontSizes[5]}px;
  font-weight: 400;
  color: ${({ theme, error }) =>
    error ? theme.colors.error[0] : theme.colors.primary[0]};
  line-height: 1.75;
  width: ${({ value }) => {
    if (String(value).length === 0) {
      return 20
    }
    return String(value).length * 13 + 7
  }}px;
  letter-spacing: 0.009375em;
  border: none;
  transform: translateY(4px);
  background-color: transparent;
  outline: 0;
  font-weight: 700;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export default SliderNumberField
