import React from "react"
import { Link } from "@engaging-tech/routing"
import {
  H3,
  Card,
  Paragraph,
  Text,
  Flex,
  Icon
} from "@engaging-tech/components"
import styled from "styled-components"

import PrimaryButton from "./PrimaryButton"

const PremiumBanner = styled(Flex)`
  border-radius: 4px 4px 0px 0px;
  height: 38px;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
`

const PromotionCard = ({
  title,
  subtitle,
  content,
  action,
  premiumBanner,
  ...props
}) => (
  <Card justifyContent="center" alignItems="center" width={1 / 1} {...props}>
    {premiumBanner && (
      <PremiumBanner bg="primary.0">
        <Icon name="stars" color="yellow.0" />
        <Text
          fontSize={14}
          color="yellow.0"
          ml={2}
          fontWeight={700}
          width="auto"
        >
          PREMIUM SERVICE
        </Text>
      </PremiumBanner>
    )}
    <Flex p={3} flexDirection="column">
      {title && (
        <H3 fontWeight={700} mb={3} fontSize={4}>
          {title}
        </H3>
      )}
      {subtitle && (
        <Text fontSize={3} fontWeight="500" color="dark.2" mb={3}>
          {subtitle}
        </Text>
      )}
      {content &&
        content.map(item => (
          <Text fontSize={3} color="dark.2" mb={3} key={item}>
            {item}
          </Text>
        ))}
      {action && (
        <>
          {action.label && (
            <Paragraph fontSize={2} color="dark.2" mb={2}>
              {action.label}
            </Paragraph>
          )}
          <Link
            to={action.linkTo}
            onClick={action.onClick}
            external={action.external}
            newTab={action.newTab}
          >
            <PrimaryButton
              width={1 / 1}
              elevation={4}
              height={34}
              color="grey.0"
              text={action.description}
            />
          </Link>
        </>
      )}
    </Flex>
  </Card>
)

export default PromotionCard
