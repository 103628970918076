import React from "react"
import { Flex } from "@engaging-tech/components"

import SearchBooks from "../../containers/SearchBooks"
import CategoryFilters from "../../containers/CategoryFilters"
import bookCategories from "../../lib/categories"

const Search = () => {
  const formatedBookCategories = bookCategories.map(category => ({
    name: category.description,
    id: category.code
  }))

  return (
    <Flex
      px={[3, 3, 4]}
      pt={[4, 2]}
      height="auto"
      flexDirection={["column", "column", "row"]}
      justifyContent={["flex-start", "flex-start", "space-between"]}
      alignItems={["flex-start", "flex-start", "center"]}
      alignContent={["flex-start", "flex-start", "center"]}
    >
      <SearchBooks />
      <CategoryFilters
        categories={formatedBookCategories}
        mt={[3, 3, 0]}
        width={200}
      />
    </Flex>
  )
}

export default Search
