import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Spinner } from "@engaging-tech/components"

import { getIsLoading } from "../store/businessLibrary.selectors"
import { loadFeatured } from "../store/businessLibrary.actions"

const Loader = ({ children, onLoad, isLoading }) => {
  useEffect(() => {
    onLoad()
  }, [onLoad])

  if (isLoading) {
    return <Spinner color="primary.0" py={3} />
  }

  return children
}

const mapState = state => ({
  isLoading: getIsLoading(state)
})

const mapDispatch = dispatch => ({
  onLoad: () => dispatch(loadFeatured())
})

const DiscoverLoader = connect(mapState, mapDispatch)(Loader)

export default DiscoverLoader
