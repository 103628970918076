import { Flex, Text } from "@engaging-tech/components"
import styled from "styled-components"

export const JobContainer = styled(Flex)`
  min-width: 328px;
  max-width: 387px;
  width: 100%;

  margin: ${({ first }) => (first ? 0 : "20px 0 0")};

  background-color: ${({ selected }) => (selected ? "#E6F5F5" : "#f5f5f5")};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  flex-direction: column;

  cursor: pointer;

  &:hover {
    background-color: #e6f5f5;
  }

  @media (max-width: 1080px) {
    background-color: #f5f5f5 !important;
  }

  @media (max-width: 480px) {
    min-height: 176px;
    height: 100%;

    min-width: unset;
    max-width: unset;
    width: 328px;

    margin: ${({ first }) => (first ? "0 16px" : "20px 16px 0")};
  }
`

export const JobTitleContainer = styled(Flex)`
  padding: 10px 20px 0;

  flex-direction: column;
`

export const JobTitle = styled(Text)`
  width: auto;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;

  margin: 0;

  color: #003e4c;

  &:hover {
    text-decoration: underline;
  }
`

export const JobCompanyName = styled(Text)`
  width: auto;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;

  margin: 6px 0 0;

  color: #003e4c;
`

export const JobLocation = styled(Text)`
  width: auto;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  margin: 6px 0 0;

  color: #003e4c;
`

export const JobType = styled(Text)`
  width: auto;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  margin: 6px 0 0;

  color: #003e4c;
`

export const LoadMoreWrapper = styled(Flex)`
  width: 100%;
  height: 60px;

  margin-bottom: 20px;

  justify-content: center;
  align-items: center;
`
