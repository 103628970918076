import { connect } from "react-redux"

import {
  getIsLoading,
  getDiscoveryBooksTop5,
  getDiscoveryBooksRecommendation,
  getDiscoveryBooksNewRelease
} from "../store/libraryBooks.selectors"

import { loadDiscoveryBooks } from "../store/libraryBooks.actions"

import Featured from "../components/FeaturedBooks/FeaturedBooks"

const DiscoverBooks = () => {
  const mapState = state => ({
    isLoading: getIsLoading(state),
    top5Books: getDiscoveryBooksTop5(state),
    recommendedBooks: getDiscoveryBooksRecommendation(state),
    newBooks: getDiscoveryBooksNewRelease(state)
  })

  const mapDispatch = dispatch => ({
    onLoad: () => dispatch(loadDiscoveryBooks())
  })

  return connect(mapState, mapDispatch)(Featured)
}

export default DiscoverBooks
