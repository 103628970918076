import { TermsAndConditions } from "@engaging-tech/legal"
import React from "react"
import PageLayout from "../../layouts/PageLayout"
import { header, terms } from "../../lib/terms"

const Terms = () => (
  <PageLayout isPublic flexDirection="column">
    <TermsAndConditions header={header} terms={terms} />
  </PageLayout>
)

export default Terms
