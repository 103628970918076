import { connect } from "react-redux"
import { HappinessSelectors } from "@engaging-tech/happiness"

import HappinessWidgetComponent from "../components/HappinessWidget"

const mapState = state => ({
  worldwide: HappinessSelectors.getWorldWide(state),
  industries: HappinessSelectors.getIndustries(state),
  countries: HappinessSelectors.getCountries(state)
})

const HappinessWidget = connect(mapState)(HappinessWidgetComponent)

export default HappinessWidget
