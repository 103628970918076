import { Dialog } from "@engaging-tech/components"
import React, { useState } from "react"
import { createPortal } from "react-dom"
import { reportPostMetadata } from "../../../../constants/report"
import ReportComponent from "../../../reportComponent/Report"

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const ReportPost = ({ id, onParentClose, onReportPost }) => {
  const [reportReason, setReportReason] = useState(null)

  const handleReportPostChange = reason => {
    setReportReason(reason)
  }

  const submitReportReason = () => {
    if (id && reportReason) onReportPost({ id, reason: reportReason })

    onParentClose()
  }

  return createPortal(
    <Dialog
      onToggle={onParentClose}
      footer={[
        {
          label: "confirm",
          action: submitReportReason
        },
        {
          label: "close",
          action: onParentClose
        }
      ]}
    >
      <ReportComponent
        onReportChange={handleReportPostChange}
        reportReason={reportReason}
        reportMetadata={reportPostMetadata}
      />
    </Dialog>,
    appRoot()
  )
}

export default ReportPost
