import { createSelector } from "reselect"

const globalHubState = state => state.globalHub

export const getIsLoading = createSelector(globalHubState, state => state.isLoading)

export const getIsLoadingMore = createSelector(globalHubState, state => state.isLoadingMore)

export const getPosts = createSelector(globalHubState, state => state.posts)

export const getLoadCounter = createSelector(globalHubState, state => state.loadFrom)

export const getSelectedCategory = createSelector(globalHubState, state => state.selected)

export const getIsPostCreationVisible = createSelector(globalHubState, state => state.selected === "post")

export const getHasLoadedAll = createSelector(globalHubState, state => state.hasLoadedAll)

export const getIsPosting = createSelector(globalHubState, state => state.isPosting)

export const getPostCount = createSelector(globalHubState, state => state.postCount)

export const getIsEndorsing = createSelector(globalHubState, state => state.isEndorsing)

export const getIsCommenting = createSelector(globalHubState, state => state.isCommenting)

export const getSelectedPost = createSelector(globalHubState, state => state.selectedPost)

export const getMyLatestAdvicePost = createSelector(
  globalHubState,
  state =>
    [...state?.usersAdvicePosts].sort(
      (postA, postB) =>
        // @ts-ignore
        new Date(postB.publishedAt) - new Date(postA.publishedAt)
    )[0]
)

export const getError = createSelector(globalHubState, state => state?.error?.generalMessage)
