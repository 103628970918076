import { connect } from "react-redux"

import { yourProfileSelectors } from "../../../yourProfile"
import MorrisonsSamlComponent from "../components/Morrisons"
import { fetchSurveyForSamlUser, samlSignIn } from "../store/saml.actions"
import {
  getHasLoaded,
  getHasLoadedSurveys,
  getIsLoading,
  getIsLoadingSurveys,
  getSurveys
} from "../store/saml.selectors"

const mapState = state => ({
  profile: yourProfileSelectors.getProfile(state),
  isSigningIn: getIsLoading(state),
  hasSignedIn: getHasLoaded(state),
  isLoadingSurveys: getIsLoadingSurveys(state),
  hasLoadedSurveys: getHasLoadedSurveys(state),
  surveys: getSurveys(state)
})

const mapDispatch = dispatch => ({
  onLoad: ({ idToken, accessToken, refreshToken }) =>
    dispatch(samlSignIn({ idToken, accessToken, refreshToken })),
  onFetchSurvey: () => dispatch(fetchSurveyForSamlUser())
})

const MorrisonsSaml = connect(mapState, mapDispatch)(MorrisonsSamlComponent)

export default MorrisonsSaml
