import {
  Flex,
  MotionFlex,
  Spinner,
  Text,
  TextField
} from "@engaging-tech/components"
import { AnimatePresence } from "framer-motion"
import React, { useEffect, useState } from "react"
import PrimaryButton from "../../ui/components/PrimaryButton"

const DiscountInfomation = ({ discountInfo }) => {
  const containerVariants = {
    hidden: {
      height: 0,
      display: "none",
      opacity: 0,
      transition: {
        display: { delay: 1 },
        opacity: { duration: 0.5 },
        duration: 1
      }
    },
    active: {
      height: 45,
      opacity: 1,
      display: "flex",
      transition: {
        display: { delay: 0 },
        duration: 1
      }
    }
  }

  return (
    <AnimatePresence>
      {discountInfo.discountType && (
        <MotionFlex
          initial="hidden"
          animate="active"
          exit="hidden"
          flexDirection="column"
          alignItems="flex-end"
          variants={containerVariants}
        >
          <Flex justifyContent="space-between" my={2}>
            <Text width="auto">Discount Code Applied:</Text>
            <Text textAlign="right" width="auto" fontWeight={700}>
              {discountInfo.discountAppliedText}
            </Text>
          </Flex>
        </MotionFlex>
      )}
    </AnimatePresence>
  )
}

const ApplyDiscount = ({
  onSubmit,
  isDiscountCodeLoading,
  promoId,
  discountCodeError,
  discountInfo,
  setDiscountInput,
  discountInput
}) => {
  const [showErrorState, setShowErrorState] = useState(discountCodeError)

  useEffect(() => {
    setShowErrorState(discountCodeError)
  }, [discountCodeError])

  const getButtonState = () => {
    if (isDiscountCodeLoading) {
      return (
        <PrimaryButton
          ml={4}
          mb={3}
          width="100px"
          style={{ cursor: "progress" }}
        >
          <Spinner
            color="light.0"
            justifyContent="center"
            width="100"
            height="100%"
            size={20}
          />
        </PrimaryButton>
      )
    }
    if (promoId) {
      return (
        <PrimaryButton
          ml={4}
          mb={3}
          width="100px"
          onClick={() => {
            setDiscountInput("")
            onSubmit("")
          }}
        >
          Remove
        </PrimaryButton>
      )
    }
    return (
      <PrimaryButton
        ml={4}
        mb={3}
        width="100px"
        disabled={!discountInput || showErrorState}
        onClick={() => onSubmit(discountInput)}
        bg={!discountInput || showErrorState ? "primary.2" : "primary.0"}
      >
        Apply
      </PrimaryButton>
    )
  }

  return (
    <>
      <Text width="auto" fontSize={3} mb={2}>
        Add a discount code
      </Text>
      <Flex
        justifyContent="center"
        width="100%"
        mb={3}
        height={70}
        alignItems="center"
      >
        <TextField
          label="Discount Code"
          name="discountCode"
          borderRadius="4px 4px 0px 0px"
          success={promoId}
          disabled={promoId}
          width={1}
          trailingIconName={promoId ? "check" : ""}
          value={discountInput}
          error={discountInput && showErrorState ? "Invalid discount code" : ""}
          onChange={e => {
            setShowErrorState(false)
            setDiscountInput(e.target.value)
          }}
          iconStyle={{ color: "rgba(19, 186, 0, 1)" }}
        />
        {getButtonState()}
      </Flex>
      <DiscountInfomation discountInfo={discountInfo} />
    </>
  )
}

export default ApplyDiscount
