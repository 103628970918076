import React from "react"
import Loader from "../../features/yourProfile/containers/Loader"
import DetailsLayout from "../../layouts/DetailsLayout"
import YourTests from "../../features/yourTests/views/YourTests"

const YourProfile = () => {
  return (
    <DetailsLayout
      actionName="CAREER DEVELOPER"
      backTo="/develop-career"
      bg="light.0"
    >
      <YourTests />
      <Loader />
    </DetailsLayout>
  )
}

export default YourProfile
