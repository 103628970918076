import React, { useState } from "react"
import styled from "styled-components"
import Player from "react-player"
import { Flex, Text, Icon, MotionFlex } from "@engaging-tech/components"

const worklLogo = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/WorkL_Logo_TEAL.png`)

const Wrapper = styled(Flex)`
  /* We have to use !important to overwrite the third party styles
  in react-player by default. */
  background-color: ${({ theme }) => theme.colors.dark[1]};
  border-radius: 10px;

  .react-player__shadow {
    background: none !important;
    background-color: ${({ theme }) => theme.colors.secondary[0]} !important;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  .react-player__preview {
    border-radius: 10px !important;
  }
`

const WorklLogo = styled.img`
  object-fit: cover;
  width: 160px;
`

const Thumbnail = ({ videoInfo }) => {
  const [hovered, setHovered] = useState(false)
  return (
    <MotionFlex
      bg="dark.6"
      width="100%"
      height="100%"
      borderRadius="10px"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      onHoverStart={() => setHovered(true)}
      onHoverEnd={() => setHovered(false)}
    >
      <MotionFlex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        mb={3}
        animate={{ scale: hovered ? 1.05 : 1 }}
      >
        <WorklLogo src={worklLogo} />
        <Text
          mt={2}
          color="primary.0"
          fontWeight={700}
          width="auto"
          fontSize={5}
        >
          ANSWERS
        </Text>
      </MotionFlex>

      <Flex
        bg="primary.0"
        justifyContent="center"
        height={100}
        flexDirection="column"
        width="100%"
        alignItems="center"
      >
        <Text
          width="80%"
          color="light.0"
          fontWeight={700}
          fontSize="16px"
          style={{ textTransform: "uppercase", textAlign: "center" }}
        >
          {videoInfo.title}
        </Text>
        <Flex alignItems="center" justifyContent="center" mt={3}>
          <Icon name="timer" color="light.0" mr={2} />
          <Text width="auto" color="light.0" fontWeight={700} fontSize="16px">
            {`${videoInfo.runTimeInMinutes}mins`}
          </Text>
        </Flex>
      </Flex>
      <Text
        color="primary.0"
        fontWeight={700}
        fontSize="14px"
        mt={3}
        style={{ textAlign: "center" }}
        width="80%"
      >
        {videoInfo.description}
      </Text>
    </MotionFlex>
  )
}

const TrainingVideo = ({ videoInfo, url, ...props }) => {
  const [isPlaying, setIsPlaying] = useState(false)

  return (
    <Wrapper
      m={2}
      width={["97%", 370]}
      height={[260, 280]}
      elevation={3}
      onClick={() => setIsPlaying(true)}
      {...props}
    >
      <Player
        height="100%"
        url={videoInfo.url}
        playing
        light="null"
        playIcon={<Thumbnail videoInfo={videoInfo} />}
        style={{
          paddingTop: isPlaying ? "10px" : "0px",
          paddingBottom: isPlaying ? "10px" : "0px",
          backgroundColor: isPlaying ? "black" : "rgba(255,255,255,1)",
          borderRadius: "10px"
        }}
      />
    </Wrapper>
  )
}

export default TrainingVideo
