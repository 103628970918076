import { getConfig } from "@engaging-tech/ssr-config"
import { API, Amplify } from "aws-amplify"

import { apollo } from "../../../lib/Apollo"
import { LOAD_COMPANIES, LOAD_COMPANY_RANKINGS, LOAD_SURVEY_COMPANIES } from "./rankings.queries"

const GRAPHQL_API_URL = () => `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

export const loadCompanyRankings = ({
  from = 0,
  term = null,
  industryId = null,
  countryId = null,
  type = "happinessScore"
}) =>
  apollo.query({
    uri: GRAPHQL_API_URL,
    query: LOAD_COMPANY_RANKINGS,
    variables: { from, term, industryId, countryId, type }
  })

export const loadSurveyCompanies = ({ searchTerm }) =>
  apollo.query({
    uri: GRAPHQL_API_URL,
    query: LOAD_SURVEY_COMPANIES,
    variables: { searchTerm }
  })

export const loadCompanies = filters => {
  const config = getConfig()
  Amplify.configure({
    aws_appsync_graphqlEndpoint: config.appSync.publicGatewayURL,
    aws_appsync_authenticationType: config.appSync.publicGatewayAuthType,
    aws_appsync_apiKey: config.appSync.publicGatewayApiKey,
    aws_appsync_region: config.appSync.publicGatewayRegion
  })

  return API.graphql({
    query: LOAD_COMPANIES,
    variables: { ...filters }
  })
}
