import { Flex } from "@engaging-tech/components"
import { useLocation, useRouter } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React, { useEffect, useState } from "react"

import { createUrl } from "../../../../../lib/utils"
import { LocationAutocompleteInput } from "./locationSearchFilter.styled"

const LocationSearchFilter = () => {
  const location = useLocation()
  const router = useRouter()

  const optionSearchParams = new URLSearchParams(location.search)
  const locationFilterValue = optionSearchParams.get("location")
  const locationTypeFilterValue = optionSearchParams.get("location_type")
  const countryCodeFilterValue = optionSearchParams.get("country_code")

  const [locationInputValue, setLocationInputValue] = useState(locationFilterValue ?? "")

  const onChangeLocationDetails = locationDetails => {
    if (locationDetails?.formatted_address) {
      if (locationDetails?.types && !locationDetails.types.includes("country")) {
        optionSearchParams.set(
          "location",
          locationDetails?.vicinity ?? locationDetails.address_components[0].long_name
        )
      }
      optionSearchParams.set("location_type", locationDetails.types[0])
      optionSearchParams.set(
        "country_code",
        locationDetails.address_components[locationDetails.address_components.length - 1].short_name
      )
    } else {
      optionSearchParams.delete("location")
      optionSearchParams.delete("location_type")
      optionSearchParams.delete("country_code")
    }
    const optionUrl = createUrl(location.pathname, optionSearchParams)
    router.navigate(optionUrl)
  }

  useEffect(() => {
    if (locationInputValue && locationFilterValue === null) {
      setLocationInputValue("")
    } else if (
      !locationInputValue &&
      locationFilterValue &&
      locationTypeFilterValue &&
      countryCodeFilterValue
    ) {
      setLocationInputValue(locationFilterValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationFilterValue])

  return (
    <Flex justifyContent="flex-start" alignItems="center" alignContent="center" ml={[0, 3]}>
      <LocationAutocompleteInput
        googleApiKey={getConfig().keys.googleApiKey}
        libraries={["places"]}
        noHighlight
        iconName="room"
        initialValue={locationInputValue}
        initialLocation={locationInputValue}
        value={locationInputValue}
        selectedLocationCallback={onChangeLocationDetails}
        onChange={setLocationInputValue}
      />
    </Flex>
  )
}

export default LocationSearchFilter
