import { connect } from "react-redux"

import { yourTestsActions, yourTestsSelectors } from "../../../yourTests"
import { getLatestIndividualSurveySubmission } from "../../store/developCareer.selectors"
import TestsWidgetComponent from "../components/TestsWidget"

const mapState = state => ({
  isLoading: yourTestsSelectors.getIsLoading(state),
  testStatus: yourTestsSelectors.getYourTests(state),
  latestIndividualSurveySubmission: getLatestIndividualSurveySubmission(state)
})

const mapDispatch = dispatch => ({
  updateTestStatus: status => dispatch(yourTestsActions.update(status))
})

const TestsWidget = connect(mapState, mapDispatch)(TestsWidgetComponent)

export default TestsWidget
