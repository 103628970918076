import { Box, Flex } from "@engaging-tech/components"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { loadAllCountries } from "../../../misc/store/misc.actions"
import DiscoverHappiestIndustries from "../../components/discoverHappiestIndustries"
import SideColumnLayout from "../../layouts/sideColumnLayout"
import { getJobSelected } from "../../store/jobFinder.selectors"
import JobAlerts from "./jobAlerts"
import JobDetail from "./jobDetail"
import JobsList from "./jobsList"

const JobsFeed = () => {
  const jobSelected = useSelector(getJobSelected)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadAllCountries())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SideColumnLayout>
      <Flex flexDirection="column" mt={25}>
        <JobAlerts />
        <DiscoverHappiestIndustries />
        <Flex justifyContent="start">
          <Box w="auto">
            <JobsList />
          </Box>

          <JobDetail job={jobSelected} />
        </Flex>
      </Flex>
    </SideColumnLayout>
  )
}

export default JobsFeed
