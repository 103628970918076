import { useLocation } from "@engaging-tech/routing"
import React from "react"

import PublishForm from "../../features/businessLibrary/containers/Publish"
import PublishAdvice from "../../features/expertAdvice/containers/PublishAdvice"
import AuthGuard from "../../features/ui/containers/AuthGuard"
import { hubPostTypes, libraryPostTypes } from "../../features/userGeneratedContent/lib/postTypes"
import DetailsLayout from "../../layouts/DetailsLayout"

const PublishArticleForm = PublishForm(libraryPostTypes.articles)
const PublishPodcastForm = PublishForm(libraryPostTypes.podcasts)
const PublishLectureForm = PublishForm(libraryPostTypes.lectures)
const PublishResearchForm = PublishForm(libraryPostTypes.research)

const Publish = () => {
  const { search } = useLocation()
  const [, contentType] = search.split("=")

  const renderForm = () => {
    switch (contentType) {
      case libraryPostTypes.articles:
        return <PublishArticleForm />

      case libraryPostTypes.podcasts:
        return <PublishPodcastForm />

      case libraryPostTypes.lectures:
        return <PublishLectureForm />

      case libraryPostTypes.research:
        return <PublishResearchForm />

      case hubPostTypes.advice:
        return <PublishAdvice />

      default:
        return <PublishArticleForm />
    }
  }

  return (
    <AuthGuard>
      <DetailsLayout>{renderForm()}</DetailsLayout>
    </AuthGuard>
  )
}

export default Publish
