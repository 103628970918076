/* eslint-disable react/jsx-curly-newline */
import { Box, Flex, Text } from "@engaging-tech/components"
import { useLocation, usePaths, useRouter } from "@engaging-tech/routing"
import React from "react"
import styled from "styled-components"

import WidgetCard from "../../developCareer/components/WidgetCard"
import CircleIcon from "../../ui/components/CircleIcon"
import PrimaryButton from "../../ui/components/PrimaryButton"
import CVList from "../containers/CVList"

const Border = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[4]};
  margin-bottom: 8px;
`

const StyledFlex = styled(Flex)`
  width: auto;
  #explainer {
    display: none;
  }
  :hover {
    width: 550px;
    @media (max-width: 767px) {
      width: auto;
    }
    #explainer {
      display: block;
    }
  }
`

const CVLibrary = ({ cvList, isPremium }) => {
  const location = useLocation()
  const paths = usePaths()

  const token = new URLSearchParams(location.search).get("token")

  const router = useRouter()

  return (
    <WidgetCard width={1 / 1} p={[2, 0]} mb={3} m={1}>
      <Flex flexDirection="column" px={[1, 3]} py={[1, 3]}>
        <Flex>
          <CircleIcon name="research" bg="primary.0" color="secondary.0" />
          <Text fontWeight={700} mt={2} ml={3}>
            Create Your Perfect CV
          </Text>
        </Flex>
        <Text fontWeight={600} mt={3}>
          Create multiple CVs in order to target yourself towards specific job applications. Our step-by-step
          process lets you add your information tailored towards your ideal company and/or role.
        </Text>
        <Text mt={3}>
          Your CV selected as default will be the information used to match you with a mentor via our Mentor
          Matcher service.
        </Text>
        <StyledFlex
          justifyContent="space-between"
          alignItems={["flex-start", "center"]}
          flexDirection={["column", "row"]}
        >
          <PrimaryButton
            onClick={() => {
              if (location.pathname.includes("in-app-view")) {
                router.navigate(`${paths.inAppViewCreateCV}?token=${token}`)
              } else {
                router.navigate(paths.careerDeveloper.cvLibrary.createCV)
              }
            }}
            px={[4, 6]}
            my={[4, 2]}
            text="Create CV"
            disabled={!isPremium && cvList && cvList.length >= 5}
            bg={!isPremium && cvList && cvList.length >= 5 ? "dark.5" : "primary.0"}
            elevation={!isPremium && cvList && cvList.length >= 5 ? 0 : 2}
          />
          {!isPremium && cvList && cvList.length >= 5 && (
            <styledText id="explainer">Sign up to premium to build unlimited CVs</styledText>
          )}
        </StyledFlex>
      </Flex>
      <Border />
      <Flex flexDirection="column" px={[1, 3]} py={[1, 3]}>
        <CVList Border={Border} cvList={cvList} />
      </Flex>
    </WidgetCard>
  )
}

export default CVLibrary
