import { initialState } from "./ini"
import { types } from "./jobFinder.actions"

const jobFinderReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD: {
      return {
        ...state,
        isLoading: true,
        progressState: "loading"
      }
    }
    case types.LOAD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        progressState: "loaded",
        jobs: action.payload.items,
        nextToken: action.payload.nextToken
      }
    }
    case types.LOAD_FAIL: {
      return { ...state, isLoading: false }
    }
    case types.LOAD_MORE: {
      return {
        ...state,
        isLoadingMore: true,
        progressState: "loading"
      }
    }
    case types.LOAD_MORE_SUCCESS: {
      return {
        ...state,
        isLoadingMore: false,
        progressState: "loaded",
        jobs: state.jobs.concat(action.payload?.items || []),
        nextToken: action.payload.nextToken
      }
    }
    case types.LOAD_MORE_FAIL: {
      return { ...state, isLoadingMore: false }
    }
    case types.JOB_SELECT: {
      return {
        ...state,
        jobSelected: action.payload
      }
    }

    case types.LOAD_FILTERS: {
      return { ...state, filtersIsLoading: true }
    }
    case types.LOAD_FILTERS_SUCCESS: {
      return {
        ...state,
        filtersIsLoading: false,
        filters: action.payload
      }
    }
    case types.LOAD_FILTERS_FAIL: {
      return { ...state, filtersIsLoading: false }
    }

    case types.FILTER: {
      return {
        ...state,
        filtersValues: {
          ...state.filtersValues,
          ...action.payload.reduce((newFilters, update) => {
            return {
              ...newFilters,
              [update.name]: update.value
            }
          }, {})
        }
      }
    }

    case types.CLEAR_FILTERS: {
      const clearedFiltersValues = state.filtersValues

      if (clearedFiltersValues) {
        Object.keys(clearedFiltersValues).forEach(item => {
          clearedFiltersValues[item] = undefined
        })
      }

      return {
        ...state,
        filtersValues: {
          ...clearedFiltersValues
        }
      }
    }

    case types.UPDATE_PROGRESS_STATE:
      return { ...state, progressState: action.payload }

    case types.LOAD_JOB: {
      return { ...state, isLoadingJobDetails: true }
    }
    case types.LOAD_JOB_SUCCESS: {
      return { ...state, isLoadingJobDetails: false, jobDetails: action.payload }
    }
    case types.LOAD_JOB_FAIL: {
      return { ...state, isLoadingJobDetails: false }
    }

    case types.LOAD_JOBS_RANKINGS: {
      return { ...state, isLoadingJobRanking: true }
    }
    case types.LOAD_JOBS_RANKINGS_SUCCESS: {
      return {
        ...state,
        isLoadingJobRanking: false,
        jobsRanking: action.payload
      }
    }
    case types.LOAD_JOBS_RANKINGS_FAIL: {
      return { ...state, isLoadingJobRanking: false }
    }

    case types.LOAD_JOBS_RANKINGS_MORE: {
      return { ...state, isLoadingJobRankingMore: true }
    }
    case types.LOAD_JOBS_RANKINGS_MORE_SUCCESS: {
      return {
        ...state,
        isLoadingJobRankingMore: false,
        jobsRanking: state.jobsRanking.concat(action.payload || [])
      }
    }
    case types.LOAD_JOBS_RANKINGS_MORE_FAIL: {
      return { ...state, isLoadingJobRankingMore: false }
    }

    default: {
      return state
    }
  }
}

export default jobFinderReducer
