import { connect } from "react-redux"

import MentorMatcherWidgetComponent from "../components/MentorMatcherWidget"
import { mentorMatcherSelectors } from "../../../mentorMatcher"
import {
  getServices,
  getIsPremium
} from "../../../yourProfile/store/yourProfile.selectors"
import { toggleServices } from "../../../yourProfile/store/yourProfile.actions"

const mapState = state => ({
  isLoading: mentorMatcherSelectors.getIsLoading(state),
  isPremium: getIsPremium(state),
  matches: mentorMatcherSelectors.getMentors(state),
  services: getServices(state)
})

const mapDispatch = dispatch => ({
  updateServices: services => dispatch(toggleServices(services))
})

const MentorMatcherWidget = connect(
  mapState,
  mapDispatch
)(MentorMatcherWidgetComponent)

export default MentorMatcherWidget
