import React from "react"

import PageLayout from "../../layouts/PageLayout"
import DiscoverView from "../../features/businessLibrary/views/Discover"

const Discover = () => (
  <PageLayout>
    <DiscoverView />
  </PageLayout>
)

export default Discover
