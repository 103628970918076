/* eslint-disable react/jsx-curly-newline */
import React from "react"
import { Flex, Text, Spinner } from "@engaging-tech/components"
import { titleCaps } from "@engaging-tech/frontend-utils"
import {  usePaths, Link } from "@engaging-tech/routing"
import styled from "styled-components"
import WidgetCard from "../../components/WidgetCard"
import DynamicState from "../../containers/DynamicState"
import ClickableIcon from "../../../ui/components/ClickableIcon"

const noCVsYet = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/career-developer/widget-images/cv-library-premium-no-bg.svg`)
const notPremium = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/career-developer/widget-images/cv-library-free-no-bg.svg`)

const columnHeaders = ["Role", "Company", "Edit"]

const BottomBorder = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[4]};
  margin-bottom: 15px;
`

const EllipisText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
`

const CVsMade = ({ cvList, isLoadingCVs, id }) => {
  const paths = usePaths()
  return (
    <DynamicState
      id={id}
      removable
      title="Your CV Library"
      btnText="GO TO MY CV LIBRARY"
      linkTo="/develop-career/cv-library"
      disableOverflowY
    >
      {isLoadingCVs ? (
        <Spinner color="primary.0" width={1 / 1} />
      ) : (
        <Flex flexDirection="column" alignItems="stretch">
          <Flex mb={3} mt={3} width={1 / 1}>
            {columnHeaders.map(title => (
              <Text
                color="dark.2"
                key={title}
                width={title === "Edit" ? "auto" : "40%"}
              >
                {title}
              </Text>
            ))}
          </Flex>

          {cvList?.slice(0, 3).map(cv => (
            <BottomBorder py={2} width="100%" key={cv.id}>
              <EllipisText color="primary.0" fontWeight={600} width="40%">
                <Link to={`/develop-career/cv-library/cv/${cv.id}`}>
                  {titleCaps(cv.targetPosition.jobTitle)}
                </Link>
              </EllipisText>
              <EllipisText color="primary.0" fontWeight={600} width="40%">
                {cv.targetPosition.company}
              </EllipisText>
              <Link
                to={`${paths.careerDeveloper.cvLibrary.index}/${cv.id}/edit`}
              >
                <ClickableIcon name="edit" color="primary.0" />
              </Link>
            </BottomBorder>
          ))}
        </Flex>
      )}
    </DynamicState>
  )
}

const NonPremiumView = ({ id }) => {
  // not locked behind premium anymore, but kept incase they change their mind
  return (
    <DynamicState
      id={id}
      removable
      hasBanner
      title="Tailor your job applications"
      subTitle="Create perfect CVs in a jiffy tailored towards specific job roles. Sign up to our Premium Service to create unlimited CVs!"
      image={notPremium}
      btnText="GET PREMIUM"
      linkTo="/premium"
      bgColor="yellow.4"
    />
  )
}

const NoCVsMadeYet = ({ id }) => (
  <DynamicState
    id={id}
    removable
    title="Tailor your job applications"
    subTitle="Create perfect CVs in a jiffy tailored towards specific job roles. Create an unlimited amount of CVs!"
    image={noCVsYet}
    btnText="GO TO CV LIBRARY"
    linkTo="/develop-career/cv-library"
    bgColor="secondary.5"
  />
)

const CVLibraryWidget = ({
  isPremium,
  cvList,
  isLoadingCVs,
  isLoading,
  id
}) => {
  const getWidgetState = () => {
    switch (true) {
      // case !isPremium: {
      //   return <NonPremiumView id={id} />
      // }
      case cvList && cvList.length > 0: {
        return <CVsMade cvList={cvList} isLoadingCVs={isLoadingCVs} id={id} />
      }
      default: {
        return <NoCVsMadeYet id={id} />
      }
    }
  }
  return (
    <WidgetCard width={1 / 1} bg="light.0" mb={3} height="100%">
      {isLoading ? (
        <Spinner py={3} color="primary.0" width={1 / 1} />
      ) : (
        getWidgetState()
      )}
    </WidgetCard>
  )
}

export default CVLibraryWidget
