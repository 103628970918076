import React, { useEffect } from "react"
import { Flex } from "@engaging-tech/components"

import FilterDropdown from "../../../ui/components/Search/FilterDropdown"

const Filter = ({
  postType,
  searchTerm,
  onFilter,
  onReset,
  categories,
  width = 160,
  ...props
}) => {
  useEffect(() => {
    onReset(postType, searchTerm)
  }, [])

  return (
    <Flex alignItems="center" width="auto" {...props}>
      <FilterDropdown
        id="filter-category"
        onChange={selectedOption => {
          onFilter(selectedOption, postType, searchTerm)
        }}
        zIndex={20}
        maxExtendedHeight={300}
        placeholder="Category"
        defaultValueId="0"
        width={width}
        options={[
          { id: "0", value: "" },
          ...categories.map(category => ({
            id: category.id,
            value: category.name
          }))
        ]}
      />
    </Flex>
  )
}

export default Filter
