import { createSelector } from "reselect"

import { surveyDataMetaState } from "../../survey/store/survey.selectors"

export const getSurveyResultsState = state => state.surveyResults

export const getResultsResponse = createSelector(getSurveyResultsState, state => state && state.response)

export const getHasError = createSelector(getSurveyResultsState, state => state.hasError)

export const getSurveyTemplateId = createSelector(
  getSurveyResultsState,
  state => state?.response?.surveyTemplateId
)

export const getIsLoading = createSelector(getSurveyResultsState, state => state.isLoading)

export const getIsIsolatedResultsLoading = createSelector(
  [surveyDataMetaState, getResultsResponse, getIsLoading],
  (meta, response, isLoading) => !meta || !response || isLoading
)

export const getHasLoaded = createSelector(getSurveyResultsState, state => state.hasLoaded)

export const getResponseResults = createSelector(getResultsResponse, response => response && response.results)

export const getSurveyType = createSelector(getResultsResponse, results => {
  return results?.type
})

export const getSurveyTitle = createSelector(getResultsResponse, results => {
  return results?.title
})

export const getSurveyId = createSelector(getResultsResponse, results => {
  return results?.surveyId
})

export const getSurveyLanguages = createSelector(getResultsResponse, results => {
  return results?.languages
})

export const getSurveyDate = createSelector(getResponseResults, results => results?.completedAt)

export const getResultsData = createSelector(getResponseResults, report => report && report.data)

export const getHasResults = createSelector(getResultsResponse, response => {
  const data = response && response.results && response.results.data
  const isProcessed = !!response && !!response.status && response.status === "processed"

  const hasHappinessDataHighlightScore =
    isProcessed && data && data.happinessDataHighlight && data.happinessDataHighlight.score
  return !!hasHappinessDataHighlightScore
})

export const getResultsLayout = createSelector(getResponseResults, results => results && results.layout)

export const getSummaryLayout = createSelector(
  getResultsLayout,
  layout => layout && layout.map(element => element.name)
)

export const getMatricesLayout = createSelector(
  getResultsLayout,
  layout => layout && layout.find(layer => layer.name === "matrices")
)

export const getMatricesChildrenLayout = createSelector(
  getMatricesLayout,
  layout => layout && layout.children && layout.children.map(element => element.name)
)
