import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { AnimatePresence } from "framer-motion"
import { Text, Flex, Icon, ImageCropModal } from "@engaging-tech/components"

import FilePicker from "../../../ui/components/FilePicker"

const holdingImage = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const RoundImage = styled.img`
  border-radius: 50%;
`

const ProfileImage = ({
  picture,
  onImageSelect,
  uploadingImageError,
  size = 80
}) => {
  const [image, setImage] = useState(null)
  const [imageToCrop, setImageToCrop] = useState(null)
  const [imageName, setImageName] = useState("")

  useEffect(() => {
    if (image) {
      onImageSelect(image)
    }
  }, [image, onImageSelect])
  useEffect(() => {
    if (uploadingImageError) {
      setImage(null)
    }
  }, [uploadingImageError])

  return (
    <>
      <Flex flexDirection="column" alignItems="center" mt={3}>
        <Flex width={size} height={size} position="relative">
          {picture && (
            <Icon
              name="delete"
              position="absolute"
              style={{ cursor: "pointer" }}
              right={-15}
              top={-10}
              onClick={() => {
                onImageSelect(null)
                setImage(null)
                setImageName("")
              }}
            />
          )}
          {!picture ? (
            <img
              width={`${size}px`}
              height={`${size}px`}
              src={holdingImage}
              alt="Logo Placeholder"
            />
          ) : (
            <RoundImage
              width={`${size}px`}
              height={`${size}px`}
              style={{ objectFit: "cover" }}
              src={picture}
              alt="Logo"
            />
          )}
        </Flex>

        {uploadingImageError && (
          <Flex
            borderRadius="16px"
            bg="red"
            height={28}
            width="auto"
            px={3}
            justifyContent="center"
            alignItems="center"
            mt={3}
          >
            <Text fontSize={2}>Error uploading profile picture</Text>
          </Flex>
        )}

        <Flex width="100%" justifyContent="center" mt={3}>
          <FilePicker
            imageName={imageName}
            image={image}
            setImage={setImage}
            onCancel={() => onImageSelect(null)}
            setImageToCrop={(img, name) => {
              setImageToCrop(img)
              setImageName(name || "untitled.png")
            }}
          />
        </Flex>
      </Flex>
      <AnimatePresence>
        {imageToCrop && (
          <ImageCropModal
            cancelCrop={() => setImageToCrop(null)}
            imageToCrop={imageToCrop}
            saveCroppedImage={imageToSave => {
              setImage(imageToSave)
              setImageToCrop(null)
            }}
            imageName={imageName}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default ProfileImage
