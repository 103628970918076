import React from "react"
import { usePaths } from "@engaging-tech/routing"
import PromotionCard from "../../../ui/components/PromotionCard"

const Lecture = () => {
  const paths = usePaths()

  return (
    <PromotionCard
      title="Publish lecture"
      content={[
        "Upload your own video on WorkL to educate and give to the world. Your video will be published here and on the Global Hub."
      ]}
      action={{
        description: "Publish lecture",
        linkTo: paths.resourceCentre.publish.lecture
      }}
    />
  )
}

export default Lecture
