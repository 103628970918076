import { Box, Select } from "@engaging-tech/components"
import { titleCaps } from "@engaging-tech/frontend-utils"
import React, { useEffect, useState } from "react"
import { isChoiceVisible } from "../../../../utils/logic"

const Dropdown = ({
  onAnswer,
  values,
  label,
  initialValue,
  allAnswers,
  choiceLogics
}) => {
  const [selected, setSelected] = useState("-1")
  const [choices, setChoices] = useState([])

  const handleSelect = e => {
    setSelected(e.target.value)
    onAnswer(e.target.value)
  }

  useEffect(() => {
    if (
      initialValue &&
      initialValue?.value &&
      initialValue?.value.length > 0 &&
      initialValue.value[0]
    )
      setSelected(initialValue?.value[0])
  }, [initialValue])

  useEffect(() => {
    const dropdownChoices = []
    // eslint-disable-next-line array-callback-return, consistent-return
    values.forEach(val => {
      if (isChoiceVisible(val, allAnswers, choiceLogics))
        dropdownChoices.push(val)
    })

    setChoices(dropdownChoices)
  }, [allAnswers, choiceLogics, values])

  return (
    <Box mt={[3, 5]}>
      <Select
        id="dropdown"
        label={titleCaps(label)}
        value={selected}
        onChange={handleSelect}
      >
        <option disabled value="-1">
          {label}
        </option>
        {choices.map(val => (
          <option key={val.id} value={val.id}>
            {val.value}
          </option>
        ))}
      </Select>
    </Box>
  )
}

export default Dropdown
