import React from "react"
import { Flex, Box, Text } from "@engaging-tech/components"
import Styled from "styled-components"

const StyledImg = Styled.img`
height: auto;
width: auto;
max-width: 100px;
max-height: 100px;
padding-right: 14px;
padding-left: 14px;
padding-bottom: 12px;
`
const StyledBox = Styled(Box)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  `

const SponsorLogo = ({ arr }) => {
  const ImgMapper = ({ imgArr }) => (
    <>
      {imgArr && (
        <StyledBox width={1 / 1}>
          {imgArr.map(e => (
            <StyledImg
              src={e.image}
              alt={e.imageAlt}
              style={{ flex: "1 0 25%" }}
            />
          ))}
        </StyledBox>
      )}
    </>
  )
  return (
    <Flex
      flexDirection="column"
      width={1 / 1}
      p={2}
      alignItems="space-evenly"
      ml={0}
    >
      <Flex mb={4}>
        <Text
          color="primary.0"
          fontSize={4}
          fontWeight="300"
          textAlign="center"
        >
          WorkL Happiest Workplaces UK Sponsors and Partners
        </Text>
      </Flex>
      <ImgMapper imgArr={arr} />
    </Flex>
  )
}

export default SponsorLogo
