import { Button, Flex, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React, { useEffect } from "react"

const FormFooter = ({
  cancelPath,
  handleBack,
  handleNext,
  formInvalid,
  stepNumber,
  finalStep,
  formStep,
  formik,
  handleTouchAllInputs
}) => {
  useEffect(() => {
    formik.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepNumber])

  if (formStep.FooterOverride) {
    return (
      <formStep.FooterOverride
        cancelPath={cancelPath}
        handleBack={handleBack}
        handleNext={handleNext}
        formInvalid={formInvalid}
        stepNumber={stepNumber}
        finalStep={finalStep}
        formik={formik}
        validationSchema={formStep.validationSchema}
        handleTouchAllInputs={handleTouchAllInputs}
      />
    )
  }

  const stepNotFirstUI = (
    <Button elevation={0} width={100} bg="transparent" onClick={() => handleBack({ values: formik.values })}>
      <Text fontWeight={700} color="primary.0" fontSize={3}>
        Back
      </Text>
    </Button>
  )

  const stepNotLastUI = (
    <Button
      elevation={0}
      bg={formInvalid ? "dark.5" : "secondary.0"}
      width={finalStep ? 150 : 100}
      onClick={
        formInvalid ? () => handleTouchAllInputs({ formik }) : () => handleNext({ values: formik.values })
      }
    >
      <Text fontWeight={700} color={formInvalid ? "dark.2" : "primary.0"} fontSize={3}>
        {finalStep ? "Done" : "Next"}
      </Text>
    </Button>
  )

  return (
    <Flex width={1 / 1} justifyContent="space-between" alignItems="center" alignContent="center" mt={4}>
      <Link to={cancelPath}>
        <Button elevation={0} bg="transparent">
          <Text fontWeight={700} color="primary.0" fontSize={3}>
            Cancel
          </Text>
        </Button>
      </Link>
      <Flex justifyContent="flex-end" alignItems="center" alignContent="center">
        {stepNumber !== 0 && stepNotFirstUI}
        {!formStep.info.lastStep && stepNotLastUI}
      </Flex>
    </Flex>
  )
}

export default FormFooter
