import { Flex, Text, TextField } from "@engaging-tech/components"
import { useField } from "formik"
import React from "react"

import InputLoadError from "./InputLoadError"

const FieldInput = ({
  field,
  meta,
  label,
  type = "text",
  helperText,
  height,
  inputLabel,
  onChange,
  errorTextOverride,
  width = "100%",
  ...other
}) => {
  if (!("value" in field)) {
    return <InputLoadError />
  }

  return (
    <Flex width={1 / 1} flexDirection="column" {...other}>
      {label && <Text mb={3}>{label}</Text>}
      <TextField
        width={width}
        id={field.name}
        name={field.name}
        type={type}
        label={inputLabel}
        onChange={onChange || field.onChange}
        onBlur={field.onBlur}
        value={field.value ? field.value : ""}
        error={meta.error && meta.touched ? errorTextOverride || meta.error : ""}
        helperText={helperText}
        height={height}
      />
    </Flex>
  )
}

const FieldInputHandler = ({
  label,
  inputLabel,
  name,
  height,
  type,
  helperText,
  onChange,
  errorTextOverride,
  ...other
}) => {
  const [field, meta] = useField({ name })

  return (
    <FieldInput
      label={label}
      inputLabel={inputLabel}
      type={type}
      onChange={onChange}
      field={field}
      height={height}
      errorTextOverride={errorTextOverride}
      helperText={helperText}
      meta={meta}
      {...other}
    />
  )
}

export default FieldInputHandler
