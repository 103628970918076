import { types } from "./businessProfilePage.actions"

export const initialState = {
  isLoading: false,
  bipInformation: null,
  dunnData: null,
  awardsData: []
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD:
      return {
        ...state,
        isLoading: true
      }

    case types.LOAD_DUNNS_DATA:
      return {
        ...state,
        isLoading: true,
        dunnData: null
      }

    case types.LOAD_SUCCESS:
      return {
        ...state,
        bipInformation: action.payload,
        isLoading: false
      }

    case types.LOAD_FAIL:
      return {
        ...state,
        isLoading: false,
        bipInformation: null
      }
    case types.LOAD_DUNNS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dunnData: action.payload
      }
    case types.LOAD_DUNNS_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        dunnData: null
      }
    default:
      return state
  }
}
