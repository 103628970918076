import { useEffect } from "react"
import { connect } from "react-redux"
import {
  getAllLanguages,
  getHasLoaded,
  getIsLoading
} from "../store/misc.selectors"
import { loadLanguages } from "../store/misc.actions"

const LanguagesLoaderComponent = ({
  allLanguages,
  load,
  loading,
  hasLoaded
}) => {
  useEffect(() => {
    if (loading) return
    if (hasLoaded && !allLanguages.length) {
      setTimeout(loadLanguages, 1000)
    } else if (!allLanguages.length) {
      load()
    }
  }, [allLanguages, hasLoaded, load, loading])

  return null
}

const mapState = state => ({
  allLanguages: getAllLanguages(state),
  loading: getIsLoading(state, "Languages"),
  hasLoaded: getHasLoaded(state, "Languages")
})

const mapDispatch = dispatch => ({
  load: () => dispatch(loadLanguages())
})

const LanguagesLoader = connect(mapState, mapDispatch)(LanguagesLoaderComponent)

export default LanguagesLoader
