import React from "react"
import styled from "styled-components"
import { Flex, Text, Icon, IconButton } from "@engaging-tech/components"
import { getFileType, getFilename } from "@engaging-tech/frontend-utils"

const AttachmentText = styled(Text)`
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  /* Width needs to be coerced into a px value to get text
  overflowing to work. */
  width: calc(100% - 0px);
`

const FileLink = styled.a`
  width: 100%;
`

const File = ({ name, url, onDismiss }) => (
  <FileLink href={url} target="_blank noopener noreferrer">
    <Flex
      pr={2}
      mt={[3, 3, 2]}
      width={1 / 1}
      bg="secondary.4"
      height={[52, 52, 40]}
      alignItems="center"
      borderRadius={4}
    >
      <Icon name="attach_file" width="auto" m={2} />
      <Text color="dark.2" fontSize={3} fontWeight="500" mr={3} width="auto">
        {getFileType(name)}
      </Text>
      <AttachmentText color="dark.2" fontSize={3}>
        {getFilename(name)}
      </AttachmentText>
      {onDismiss && <IconButton icon="close" size={32} onClick={onDismiss} />}
    </Flex>
  </FileLink>
)

export default File
