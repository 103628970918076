import { CustomHooks, Flex } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import BaseLayout from "./BaseLayout"

const StickyFlex = styled(Flex)`
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[0]}px) {
    position: sticky;
    top: ${({ theme }) => theme.space[6] + theme.space[2]}px;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[1]}px) {
    position: sticky;
    top: ${({ theme }) => theme.space[3]}px;
  }
`

const GlobalHubSideColumnLayout = ({
  sideColumn: SideColumn,
  direction = ["column", "row", "row"],
  children,
  isSticky,
  banner: Banner,
  maxWidth = 1280,
  topRow: TopRow,
  ...props
}) => {
  const deviceType = CustomHooks.useClientDevice()
  const mainSectionWidth = ["100%", "60%", "60%"]
  const sideColumnWidth = ["100%", "260px", "260px"]
  const sideColumnMarginLeft = ["0px", "20px", "20px"]

  const getWidthCalculation = () => {
    switch (deviceType) {
      case "MOBILE":
        return `calc(${mainSectionWidth[0]}`
      case "TABLET":
        return `calc(${mainSectionWidth[1]} + ${sideColumnWidth[1]} + ${sideColumnMarginLeft[1]})`
      default:
        return `calc(${mainSectionWidth[2]} + ${sideColumnWidth[2]} + ${sideColumnMarginLeft[2]})`
    }
  }
  return (
    <BaseLayout {...props}>
      {Banner && <Banner />}
      <Flex
        flexDirection="column"
        m="0 auto"
        maxWidth={maxWidth}
        py={5}
        px={[3, 3, 4]}
        justifyContent="center"
        alignItems="center"
      >
        <Flex flexDirection={direction} justifyContent="center" flexWrap="wrap" width="100%">
          {TopRow && (
            <Flex style={{ width: getWidthCalculation() }} mb={3}>
              <TopRow />
            </Flex>
          )}
          <Flex width={mainSectionWidth} flexDirection="column">
            {children}
          </Flex>
          {isSticky ? (
            <StickyFlex width={sideColumnWidth} ml={sideColumnMarginLeft} mb={[3, 0, 0]}>
              <SideColumn />
            </StickyFlex>
          ) : (
            <Flex width={sideColumnWidth} ml={sideColumnMarginLeft} mb={[3, 0, 0]}>
              <SideColumn />
            </Flex>
          )}
        </Flex>
      </Flex>
    </BaseLayout>
  )
}

export default GlobalHubSideColumnLayout
