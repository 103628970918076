import { connect } from "react-redux"
import { yourProfileActions, yourProfileSelectors } from "../../yourProfile"
import ConfirmBanner from "../components/ConfirmBanner"
import { getIsAuthenticated } from "../store/account.selectors"

const mapState = state => ({
  isConfirmed: yourProfileSelectors.getIsConfirmed(state),
  isAuthenticated: getIsAuthenticated(state)
})

const mapDispatch = dispatch => ({
  sendVerification: () => dispatch(yourProfileActions.requestConfirmation())
})

/**
 * A banner prompting a user to confirm their email address.
 */
const ConfirmEmailBanner = connect(mapState, mapDispatch)(ConfirmBanner)

export default ConfirmEmailBanner
