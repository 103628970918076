import React, { useContext, createContext, useState } from "react"

const SliderContext = createContext({
  direction: "",
  setDirection: () => null
})

export const SliderProvider = ({ children }) => {
  const [direction, setDirection] = useState("forward")

  return (
    <SliderContext.Provider
      value={{
        direction,
        setDirection
      }}
    >
      {children}
    </SliderContext.Provider>
  )
}

export const useSliderContext = () => {
  const { direction, setDirection } = useContext(SliderContext)
  return { direction, setDirection }
}
