import { Button, Flex, H2, Modal, Text, Utils } from "@engaging-tech/components"
import { useLocation, useRouter } from "@engaging-tech/routing"
import React from "react"
import { createPortal } from "react-dom"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import PercentageGraphic from "../../../../ui/components/PercentageGraphic"
import actionPlans from "../../lib/actionPlans"
import retailTrustActionPlanItems from "../../lib/retailTrustActionPlans"
import FurtherReading from "./FurtherReading"
import Point from "./Point"
import Resources from "./Resources"

const appRoot = () => typeof document !== "undefined" && document.getElementById("root")

const BackButton = styled(Button)`
  text-transform: none;
`

const SeparatorBox = styled(Flex)`
  & > div {
    border-bottom: 1px solid ${({ theme }) => theme.colors.dark[4]};

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
`

const StyledModal = styled(Modal)`
  width: 100%;
  -webkit-overflow-scrolling: touch;
`

const ActionPlansModal = ({
  stepResult,
  stageIndex,
  setVisibility,
  isPremium,
  surveyTemplateId,
  furtherReading = true
}) => {
  const router = useRouter()
  const location = useLocation()

  const { t } = useTranslation(["results"])

  const RTSurveyTemplateId = "63eb809fdcab2b001abf7f84"
  const OldRTSurveyTemplateId = "632313c3f4b1540013a0b2bf"
  const RTSurveyTemplateId2024 = "65a14b8b66b42400145f8ecf"
  const RTSurveyTemplateId2024V2 = "65f84953e948fd001465a082"

  const SurveyTemplateChecker = () => {
    if (surveyTemplateId === RTSurveyTemplateId || surveyTemplateId === OldRTSurveyTemplateId) {
      return retailTrustActionPlanItems[stageIndex]
    }
    if (surveyTemplateId === RTSurveyTemplateId2024 || surveyTemplateId === RTSurveyTemplateId2024V2) {
      return retailTrustActionPlanItems[stageIndex]
    }
    return actionPlans[stageIndex]
  }

  const isIndividualSurvey = location.pathname.split("/").includes("happiness-survey")

  if (!stepResult) return null
  if (!isPremium && isIndividualSurvey) router.navigate(`/premium?lastRoute=${location.pathname}`)

  return createPortal(
    <StyledModal
      onToggle={() => setVisibility(false)}
      bg="light.0"
      minWidth="80%"
      p={[2, 6]}
      py={[3, 6]}
      my={[0, 5]}
    >
      <Utils.DisableScroll />
      <Flex flexDirection="column" width={1 / 1}>
        <BackButton
          onClick={() => setVisibility(false)}
          leadingIcon="arrow_back"
          variant="light.0"
          borderRadius={24}
          elevation={1}
        >
          <Text marginRight={4} fontWeight={400} color="dark.2" fontSize={3}>
            {t("action_plan.backButton", "")}
          </Text>
        </BackButton>

        {}
        <H2
          fontSize={[6, 7]}
          fontWeight={500}
          fontFamily="serif"
          p={[2, 3]}
          textAlign={["center", "justify"]}
        >
          {t("action_plan.button", "")} - {t(`action_plan.stage.${stageIndex}.heading`, "")}
        </H2>
        <Flex flexDirection={["column", "row-reverse"]} flexWrap={["nowrap", "wrap"]} width={1 / 1}>
          <Flex flexDirection="column" p={[2, 3]} width={[1 / 1, 2 / 5]}>
            <Text textAlign="center" fontSize={3} width={1 / 1} fontWeight={500} color="dark.2">
              {t("action_plan.overallScore", "")}:
            </Text>
            <PercentageGraphic score={stepResult.score} />
            <Resources resources={SurveyTemplateChecker()} stageIndex={stageIndex} />

            {SurveyTemplateChecker().furtherReading.length && furtherReading ? (
              <FurtherReading furtherReading={SurveyTemplateChecker()} stageIndex={stageIndex} />
            ) : null}
          </Flex>
          <SeparatorBox flexDirection="column" p={[2, 3]} textAlign="justify" width={[1 / 1, 3 / 5]}>
            {stepResult.questions.map(question => (
              <Point
                questionInfo={question}
                stageIndex={stageIndex}
                key={question.id}
                modalInfoIndex={t(`action_plan.stage.${stageIndex}.modalInfo`, {
                  returnObjects: true
                }).findIndex(info => info.questionId === question.id)}
              />
            ))}
          </SeparatorBox>
        </Flex>
      </Flex>
    </StyledModal>,
    appRoot()
  )
}

export default ActionPlansModal
