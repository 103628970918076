/* eslint-disable no-nested-ternary */
import { Button, Flex, Text } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"

import { businessProfilePageSelectors } from "../.."
import MissionStatement from "./mission-statement"
import SocialLinks from "./social-links"
import EmblaCarousel from "./video-carounsel"

const FlexContainer = styled.div`
  margin-top: 30px;

  max-width: 810px;
  width: 100%;

  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

const AboutUsTabWrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
`

const AboutUsContainer = styled.div`
  width: auto;

  margin: 20px 10px;

  @media (min-width: 768px) {
    margin: 42px 64px;
  }

  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 1px #00000040;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const AboutUsItem = styled.div`
  padding: 10px;

  flex: 1 1 calc(50% - 10px);

  text-align: left;
`

const AboutUsItemText = styled(Text)`
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;

  color: #003e4c;
`

const AboutUsItemTextValue = styled(Text)`
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;

  color: #003e4c;
`

const AboutUsTabClaimPageContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 392px;
`

const AboutUsTabClaimPageTitle = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.4px;
  text-align: center;

  color: #003e4c;
`

const AboutUsTabClaimPageSubtitle = styled.p`
  margin: 4px 0 0 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: center;

  color: #003e4c;
`

function AboutUsTabClaimPage() {
  return (
    <Flex justifyContent="center" mt="22px" mb="18px">
      <AboutUsTabClaimPageContainer>
        <AboutUsTabClaimPageTitle>Business owner or leader?</AboutUsTabClaimPageTitle>
        <AboutUsTabClaimPageSubtitle>Claim and manage this page</AboutUsTabClaimPageSubtitle>
        <Button
          variant="primary.0"
          mt="14px"
          onClick={e => {
            e.preventDefault()

            window.open(
              `${getConfig().worklForBusiness.public.index}${
                getConfig().worklForBusiness.public.paths.worklBusinessServices
              }`,
              "__blank"
            )
          }}
        >
          CLAIM PAGE
        </Button>
      </AboutUsTabClaimPageContainer>
    </Flex>
  )
}

export default function AboutUsTab() {
  const bipInfo = useSelector(businessProfilePageSelectors.getBipInfo)
  const dunnsData = useSelector(businessProfilePageSelectors.getDunnsData)

  const industriesValue =
    bipInfo?.profile?.status === "PUBLISHED"
      ? bipInfo?.profile?.summary?.industry?.name
      : dunnsData?.industries
      ? dunnsData.industries.toString()
      : ""
  const sizeValue =
    bipInfo?.profile?.status === "PUBLISHED" && bipInfo?.profile?.summary?.size
      ? bipInfo.profile.summary.size
      : typeof dunnsData?.employeesInfo?.totalEmployees === "string"
      ? parseInt(dunnsData.employeesInfo.totalEmployees, 0)
      : dunnsData?.employeesInfo?.totalEmployees
  const foundedAtValue =
    bipInfo?.profile?.status === "PUBLISHED" && bipInfo?.profile?.summary?.foundedAt
      ? new Date(bipInfo.profile.summary.foundedAt).getFullYear()
      : dunnsData?.businessInfo?.establishmentYear
  const addressValue =
    bipInfo?.profile?.status === "PUBLISHED" && bipInfo?.profile?.summary?.locations?.length
      ? `${bipInfo.profile.summary.locations[0].city}, ${bipInfo.profile.summary.locations[0].country.name}`
      : dunnsData?.businessInfo?.address
      ? `${
          dunnsData?.businessInfo?.address && dunnsData?.businessInfo?.address?.city
            ? `${dunnsData.businessInfo.address.city}, `
            : ""
        } ${dunnsData?.businessInfo?.address?.street1 ? `${dunnsData.businessInfo.address.street1}, ` : ""}${
          dunnsData.businessInfo.address.country
        }`
      : ""

  return (
    <AboutUsTabWrapper>
      <AboutUsContainer>
        <Text mt="11px" fontWeight={700} fontSize={24} color="#003e4c" width="fit-content">
          About Us
        </Text>

        <FlexContainer>
          <AboutUsItem>
            <AboutUsItemText>Industry:</AboutUsItemText>
            <AboutUsItemTextValue ml="4px">{industriesValue}</AboutUsItemTextValue>
          </AboutUsItem>
          <AboutUsItem>
            <AboutUsItemText>Size:</AboutUsItemText>
            <AboutUsItemTextValue ml="4px">{sizeValue}</AboutUsItemTextValue>
          </AboutUsItem>
          <AboutUsItem>
            <AboutUsItemText>Founded:</AboutUsItemText>
            <AboutUsItemTextValue ml="4px">{foundedAtValue}</AboutUsItemTextValue>
          </AboutUsItem>
          <AboutUsItem>
            <AboutUsItemText>Location:</AboutUsItemText>
            <AboutUsItemTextValue ml="4px">{addressValue}</AboutUsItemTextValue>
          </AboutUsItem>
        </FlexContainer>

        {bipInfo?.profile?.status !== "PUBLISHED" ? (
          <AboutUsTabClaimPage />
        ) : (
          <>
            <SocialLinks />

            <MissionStatement />
          </>
        )}
      </AboutUsContainer>
      {bipInfo?.profile?.status === "PUBLISHED" && bipInfo?.profile?.summary?.videos?.length ? (
        <AboutUsContainer>
          <Flex mt="16px" mb="16px" style={{ width: "100%", padding: "0 4px" }}>
            <EmblaCarousel slides={bipInfo.profile.summary.videos} options={{ slidesToScroll: "auto" }} />
          </Flex>
        </AboutUsContainer>
      ) : null}
    </AboutUsTabWrapper>
  )
}
