import React from "react"
import styled from "styled-components"
import { Card, Flex, Text } from "@engaging-tech/components"

import CircleIcon from "../../../ui/components/CircleIcon"

const Wrapper = styled(Card)`
  width: calc(100% - 32px);

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}px) {
    height: 160px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}px) {
    height: 126px;
  }
`

const LandingPageCard = ({ icon, title, children, isGreyscale }) => (
  <Wrapper
    p={3}
    flexDirection={["column", "column", "row"]}
    elevation={2}
    bg="light.0"
    mx={3}
    mb={3}
  >
    <CircleIcon name={icon} mr={2} bg={isGreyscale ? "dark.5" : null} />
    <Flex mt={2} flexDirection="column" width="auto">
      <Text fontWeight="500" mb={12}>
        {title}
      </Text>
      {children}
    </Flex>
  </Wrapper>
)

export default LandingPageCard
