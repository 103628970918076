import React, { useEffect } from "react"
import styled from "styled-components"
import { Flex, Box, Spinner, Text } from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"

import Preview from "./Preview"

const TitleWrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[4]};
`

const TitleText = styled(Text)`
  text-transform: capitalize;
`

const Title = ({ text, withLink }) => {
  const paths = usePaths()

  return (
    <TitleWrapper pb={3}>
      <TitleText fontSize={3}>
        {text}
        {withLink && (
          <Text color="primary.0" fontSize={3} fontWeight={700}>
            <Link to={paths.resourceCentre.browse.books.index}>Books</Link>
          </Text>
        )}
      </TitleText>
    </TitleWrapper>
  )
}

const FeaturedGrid = styled(Flex)`
  align-items: stretch;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  padding: 1px;
`

const FeaturedBooks = ({
  onLoad,
  isLoading,
  top5Books,
  recommendedBooks,
  newBooks
}) => {
  useEffect(() => {
    onLoad()
  }, [])

  if (isLoading) {
    return <Spinner color="primary.0" />
  }
  return (
    <Flex flexDirection="column">
      <Title text="Top Five Business " withLink />
      <FeaturedGrid flexWrap={["nowrap", "nowrap", "wrap"]}>
        {top5Books &&
          top5Books.map(book => <Preview book={book} key={book.id} />)}
      </FeaturedGrid>
      <Title text="Popular Releases " />
      <FeaturedGrid flexWrap={["nowrap", "nowrap", "wrap"]}>
        {newBooks &&
          newBooks.map(book => <Preview book={book} key={book.id} />)}
      </FeaturedGrid>
      <Title text="Recommended " withLink />
      <FeaturedGrid flexWrap={["nowrap", "nowrap", "wrap"]}>
        {recommendedBooks &&
          recommendedBooks.map(book => <Preview book={book} key={book.id} />)}
      </FeaturedGrid>
    </Flex>
  )
}

export default FeaturedBooks
