import React from "react"
import SurveyGuard from "../../features/survey/guards/surveyGuard"
import SurveySubmitView from "../../features/survey/views/surveySubmit"
import OrganisationSurveyLayout from "../../layouts/organisationSurveyLayout"

const SurveySubmit = () => (
  <OrganisationSurveyLayout>
    <SurveyGuard>
      <SurveySubmitView />
    </SurveyGuard>
  </OrganisationSurveyLayout>
)

export default SurveySubmit
