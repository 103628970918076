import { Box, Spinner } from "@engaging-tech/components"
import { useLocation } from "@engaging-tech/routing"
import React, { useEffect } from "react"

import CallToAction from "../../containers/CallToAction"
import LoadMore from "../../containers/LoadMore"
import Post from "./Post"

const splitPosts = posts => ({
  firstPost: posts[0],
  otherPosts: posts.filter((_, index) => index !== 0)
})

const Posts = ({ posts, isPostCreationVisible, isLoading, onLoad }) => {
  const { pathname } = useLocation()

  const query = new URLSearchParams(useLocation().search)
  const tab = query.get("tab")
  const inAppView = pathname.includes("in-app-view")

  useEffect(() => {
    const type = tab || ""

    if (inAppView) {
      onLoad({ place: "in-app-view", type })
    } else {
      onLoad({ type })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, inAppView])

  if (isLoading) {
    return <Spinner color="primary.0" mt={4} mb={4} py={3} width={1 / 1} />
  }

  if (posts.length) {
    const { firstPost, otherPosts } = splitPosts(posts)

    return isPostCreationVisible ? (
      <>
        <Post post={firstPost} />
        <Box mt={4} width={1 / 1}>
          {!inAppView && <CallToAction />}
        </Box>
        {otherPosts.map(post => (
          <Post key={post.id} post={post} />
        ))}
        <LoadMore />
      </>
    ) : (
      <>
        <Box mt={4} width={1 / 1}>
          {!inAppView && <CallToAction />}
        </Box>
        {posts.map(post => (
          <Post key={post.id} post={post} />
        ))}
        <LoadMore />
      </>
    )
  }

  return null
}

export default Posts
