import styled from "styled-components"

export const MultiSelectContainer = styled.div`
  position: relative;
  width: 100%;

  &:before {
    font-family: "EngagingTechIcons";
    font-size: 16px;
    line-height: 1;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 20px;
    right: 16px;
    transition: 180ms;
    opacity: ${({ isDisabled }) => (isDisabled ? 0.6 : 1)};
    z-index: 1;
    pointer-events: none;
  }

  &:focus-within {
    &:before {
      transform: ${({ noRotate }) => (noRotate ? "none" : "rotate(180deg)")};
    }
  }
`

export const MultiSelectButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 54px;
  padding: 0 0 0 14px;
  background-color: rgba(0, 0, 0, 0.04);
  border: 0;

  border-radius: 4px 4px 0 0;

  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);

  cursor: pointer;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
    border-bottom: 1px solid rgba(0, 0, 0, 0.87);
  }

  &:focus {
    background-color: rgba(0, 0, 0, 0.12);
    border-bottom: 2px solid rgba(0, 0, 0, 1);
    transition: 0.15s;

    & + label {
      color: rgba(1, 62, 76, 0.6);
    }
  }

  & + label {
    color: rgba(0, 0, 0, 0.6);
    left: 16px;
    right: auto;
    top: 18px;
    pointer-events: none;
    position: absolute;
    line-height: 1.15rem;
    transition: 0.15s;
    transform: translateY(-50%) translateX(-15%) scale(0.75);
  }
`

export const DropdownList = styled.ul`
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 1000;
  background-color: #f5f5f5;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 20px 20px;

  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  padding-left: 0;
  padding: 11px 0 20px;
`

export const DropdownItem = styled.li`
  width: 100%;
  height: 32px;
  list-style: none;

  text-align: center;

  display: flex;
  justify-content: start;
  align-content: start;
  align-items: center;

  cursor: pointer;

  background-color: ${({ selected }) => (selected ? "#d7d7d7" : "")};

  padding: 0 3px 0 9px;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;

  color: #003e4c;

  &:hover {
    background-color: #d7d7d7;
  }
`
