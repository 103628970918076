/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useState } from "react"

import {
  DropdownItem,
  DropdownList,
  MultiSelectButton,
  MultiSelectContainer
} from "./multiSelectInput.styled"

const MultiSelectInput = ({ options, filterValue = [], onFilter }) => {
  const dropdownRef = useRef()

  const [isOpen, setIsOpen] = useState(false)

  let filtersValuesText = ""
  filterValue?.forEach((item, index) => {
    if (index === 0) filtersValuesText = item.label
    else filtersValuesText += `, ${item.label}`
  })

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const handleOptionClick = item => {
    const oldValues = filterValue ?? []
    let newValues = []

    if (item.value !== "0") {
      if (oldValues?.length) {
        if (oldValues?.find(i => i.value === item.value)) {
          newValues = oldValues.filter(i => i.value !== item.value)
        } else {
          newValues = [...oldValues, item]
        }
      } else {
        newValues = [item]
      }
    }

    onFilter(newValues)
  }

  const toggleDropdown = () => {
    setIsOpen(prevIsOpen => !prevIsOpen)
  }

  return (
    <MultiSelectContainer ref={dropdownRef}>
      <MultiSelectButton isOpen={isOpen} onClick={toggleDropdown}>
        {filterValue?.length ? filtersValuesText : " -- "}
      </MultiSelectButton>
      {isOpen && (
        <DropdownList>
          <DropdownItem value="0" onClick={() => handleOptionClick({ value: "0", label: " -- " })}>
            --
          </DropdownItem>
          {options?.map((item, index) => (
            <DropdownItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              selected={filterValue?.length && filterValue?.find(i => i.value === item.value)}
              onClick={() => handleOptionClick(item)}
            >
              {item.label}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </MultiSelectContainer>
  )
}

export default MultiSelectInput
