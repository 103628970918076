import { connect } from "react-redux"
import ManageAlertsModalComponent from "../components/ManageAlertsModal"
import {
  notificationsActions,
  notificationsSelectors
} from "../../../Notifications"

const mapState = state => ({
  modalType: notificationsSelectors.getModalType(state)
})

const mapDispatch = dispatch => ({
  setModalType: type => dispatch(notificationsActions.setModalType(type))
})

const ManageAlertsModal = connect(
  mapState,
  mapDispatch
)(ManageAlertsModalComponent)

export default ManageAlertsModal
