import React from "react"
import { Card, Flex, Text, Paragraph } from "@engaging-tech/components"
import styled from "styled-components"
import IconCircle from "../../../ui/components/CircleIcon"

const StyledPillar = styled(Card)`
  background-color: ${({ theme }) => theme.colors.tertiary[0]};
  border-radius: 10px;
  box-shadow: 0px 0px 8px ${({ theme }) => theme.colors.dark[4]};

  -webkit-transition: -webkit-transform 0.2s ease-out;
  -moz-transition: -moz-transform 0.2s ease-out;
  -o-transition: -o-transform 0.2s ease-out;
  -ms-transition: -ms-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  &:hover {
    box-shadow: 0px 0px 26px ${({ theme }) => theme.colors.dark[3]};
    -webkit-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -o-transform: scale(1.02);
    transform: scale(1.02);
  }
`

const Pillar = ({ index, icon, title, paragraphs, length, pb }) => (
  <StyledPillar
    mb={4}
    width={["auto", length === index + 1 && length % 2 === 1 ? "100%" : "49%"]}
    pb={pb}
  >
    <Flex m={4} width="auto" alignItems="center">
      <IconCircle
        name={icon}
        size={42}
        ICON_SIZE_RATIO={1.5}
        bg="primary.0"
        color="yellow.0"
        padding={3}
        mr={4}
      />
      <Text fontSize={6} fontFamily="Tiempos" color="primary.0" width={1 / 1}>
        {title}
      </Text>
    </Flex>
    {paragraphs.map(paragraph => (
      <Paragraph key={paragraph} mx={4} width={10 / 11} color="primary.0">
        {paragraph}
      </Paragraph>
    ))}
  </StyledPillar>
)

export default Pillar
