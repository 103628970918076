import { Button, Flex, Text } from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"
import React from "react"

import PremiumFeaturePromoCard from "../../developCareer/components/PremiumFeaturePromoCard"
import WidgetCard from "../../developCareer/components/WidgetCard"
import CircleIcon from "../../ui/components/CircleIcon"
import useJobMatcherRedirect from "../hooks/useJobMatcherRedirect"
import Banner from "./banner"
import MatchesList from "./matchesList"

const premiumFeatures = [
  {
    title: "CV Library",
    text: "Create different versions of your CV to tailor your applications towards specific job roles"
  },
  {
    title: "Survey Results Action Plans",
    text: "Get comprehensive guidance in how to improve your low-scoring areas of your workplace happiness"
  },
  {
    title: "Mentor Matcher Service - For Mentees",
    text: "Get connected with a mentor who can help guide and advise you at any point in your career"
  },
  {
    title: "Monthly Business e-magazine",
    text: "With articles, podcasts, lectures, book reviws and much more"
  },
  {
    title: "Tailored Job Notifications",
    text: "Receive job notifications tailored to your search preferences to save time un your job search"
  },
  {
    title: "Event Notifications",
    text: "Receive tailored event notifications based on your interests, preferences and location"
  },
  {
    title: "Receive 20% off all books, ebooks and audiobooks in the Business Library"
  },
  {
    title: "Receive a free ebook: 'Fairness For All' by Lord Mark Price"
  }
]

const PremiumView = () => {
  const paths = usePaths()

  const { onGoToJobFinder } = useJobMatcherRedirect()

  return (
    <Flex flexDirection="column" alignItems="stretch">
      <Banner />
      <WidgetCard width="99.5%" p={[2, 0]} m={1} mb={3}>
        <Flex flexDirection="column" px={[1, 3]} py={[1, 3]} mb={1}>
          <Flex alignItems="center" mb={3}>
            <CircleIcon name="queue" size={30} color="primary.0" bg="secondary.2" />
            <Text fontWeight={800} color="dark.0" ml={3}>
              Job Matcher
            </Text>
          </Flex>
          <Text display="block" mb={4}>
            Set up your job matching criteria, then sit back and wait for the job matches to come your way! If
            you are interested in a job role, simply select that you would like to notify the employer.
          </Text>
          <Flex>
            <Link to={paths.careerDeveloper.jobMatcher.setCriteriaForm}>
              <Button bg="primary.0" elevation={0} borderRadius={4} m={["auto", 0]}>
                <Text color="secondary.0" fontWeight={700} fontSize={3} m={1}>
                  Set Match Criteria
                </Text>
              </Button>
            </Link>
            <Button
              bg="text.noBg.primary.0"
              elevation={0}
              borderRadius={4}
              ml={10}
              onClick={() => onGoToJobFinder()}
            >
              <Text fontWeight={700} fontSize={3} m={1}>
                View Matched Jobs
              </Text>
            </Button>
          </Flex>
        </Flex>
        <MatchesList />
      </WidgetCard>
    </Flex>
  )
}

const PremiumFeaturePromoCardSubtitle = () => {
  const paths = usePaths()
  return (
    <>
      <Text fontSize={3} color="light.0" textAlign="center" px={[2, 5]} maxWidth={500}>
        Sign up to our{" "}
        <Link to={paths.getPremium.index}>
          <Text fontSize={3} color="yellow.0" fontWeight={700}>
            Premium Service
          </Text>
        </Link>{" "}
        to get automatically matched with a job vacancy based on your skill set and carerr goals.
      </Text>
    </>
  )
}

const JobMatches = ({ isPremium }) => {
  if (isPremium) {
    return <PremiumView />
  }

  return (
    <PremiumFeaturePromoCard
      iconName="star_border"
      title="Your Ideal Job"
      SubTitle={PremiumFeaturePromoCardSubtitle}
      premiumFeatures={premiumFeatures}
    />
  )
}

export default JobMatches
