import { Card, Box, Text, Flex, Button } from "@engaging-tech/components"
import React from "react"
import { useTranslation } from "react-i18next"
import failureDialogContainer from "../containers/failureDialog.container"

const FailureDialog = ({ onRetry }) => {
  const { t } = useTranslation(["organisation_survey"])

  return (
    <Card
      mt={[5, 5, 6]}
      mb={[6, 5]}
      elevation={8}
      bg="light.0"
      alignItems="center"
    >
      <Box bg="dark.7" py={[2, 3]} px={3} width={1 / 1} mb={5}>
        <Text
          fontSize={[3, 6]}
          textAlign="center"
          fontWeight="500"
          display="block"
        >
          {t("failure.title", "")}
        </Text>
      </Box>
      <Flex flexDirection="column" alignItems="center" px={3} pb={3}>
        <Text
          color="dark.2"
          fontWeight="500"
          mb={3}
          width="auto"
          textAlign="center"
        >
          {t("failure.text1", "")}
        </Text>
        <Text
          fontWeight="500"
          fontSize={3}
          mb={5}
          width="auto"
          textAlign="center"
        >
          {t("failure.text2", "")}
        </Text>
        <Button variant="primary.0" onClick={onRetry} height={36} px={4}>
          {t("failure.retry_btn", "")}
        </Button>
      </Flex>
    </Card>
  )
}

export default failureDialogContainer(FailureDialog)
