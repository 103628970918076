import { call, put, takeLatest } from "redux-saga/effects"

import { getDunnsDataByKey, loadProfile } from "../services/businessProfilePage.service"
import * as Actions from "./businessProfilePage.actions"

export function* loadSaga(action) {
  try {
    const res = yield call(loadProfile, action.payload)

    yield put(Actions.loadSuccess(res.data.getBusinessInformationProfile))
  } catch {
    yield put(Actions.loadFail())
  }
}
export function* getDunnsDataSaga(action) {
  try {
    const result = yield call(getDunnsDataByKey, action.payload)
    if (result?.data?.getCompany?.companyNameKey) {
      yield put(Actions.loadDunnsDataSuccess(result?.data?.getCompany))
    }
  } catch (error) {
    yield put(Actions.loadDunnsDataFail(error))
  }
}

export function* saga() {
  yield takeLatest(Actions.types.LOAD, loadSaga)
  yield takeLatest(Actions.types.LOAD_DUNNS_DATA, getDunnsDataSaga)
}
