import { Box, Text } from "@engaging-tech/components"
import styled, { css } from "styled-components"

export const JobDetailContainer = styled(Box)`
  ${({ fullScreen }) =>
    fullScreen
      ? css`
          position: relative;
          top: none;

          width: 1080px;

          margin: 25px 0 20px 20px;
        `
      : css`
          position: -webkit-sticky; /* Safari */
          position: sticky;
          top: 25px;

          width: 662px;

          margin: 0 0 20px 20px;
        `}

  height: 100%;

  display: flex;
  flex-direction: column;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  @media screen and (max-width: 1080px) {
    display: ${({ fullScreen }) => (fullScreen ? "flex" : "none !important")};

    width: fit-content !important;

    box-shadow: unset;
    border-radius: 0;
  }
`

export const JobSubtitle = styled(Text)`
  width: auto;

  margin-bottom: 6px;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;

  color: #003e4c;
`

export const ButtonWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
`
