import { formatCurrencyGbp } from "../../../../utils/fomatCurrency"
import parseMultipleInfoInline from "../../../../utils/parseMultipleInfoInline"

export const parseJobLocationAndSalary = ({ jobLocation, jobMinSalary, jobMaxSalary }) => {
  const jobSalary = parseMultipleInfoInline(
    jobMinSalary && jobMinSalary > 0 ? formatCurrencyGbp(jobMinSalary) : undefined,
    jobMaxSalary && jobMaxSalary > 0 ? formatCurrencyGbp(jobMaxSalary) : undefined
  )

  return parseMultipleInfoInline(jobLocation, jobSalary)
}

export const parseJobTypeAndPostedDate = ({ jobType, jobAge }) => {
  return parseMultipleInfoInline(jobType, jobAge ? `Posted ${jobAge}` : undefined)
}
