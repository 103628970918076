import React, { useState } from "react"
import { Chip, Flex, Select, Button, Text } from "@engaging-tech/components"
import styled from "styled-components"
import ProfileSection from "./ProfileSection"

const StyledChip = styled(Chip)`
  &:hover {
    cursor: auto;
  }
`

const AddLink = styled(Text)`
  cursor: ${({ disabled }) => !disabled && "pointer"};
  color: ${({ theme }) => theme.colors.primary[0]};
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
`

const FormShowing = ({ toggle, languages, onUpdate, allLanguages }) => {
  const [language, setLanguage] = useState("")
  const [myLanguages, setMyLanguages] = useState(languages)
  const handleSelect = e => {
    setLanguage({
      code: e.target.value,
      name: allLanguages.find(lan => lan.code === e.target.value).name
    })
  }
  return (
    <Flex flexDirection="column" mt={3}>
      <Select
        label="Add Language"
        defaultValue=""
        value={language.code}
        onChange={handleSelect}
      >
        <option disabled value="">
          {" "}
        </option>
        {allLanguages.map(lan => (
          <option key={lan.code} value={lan.code}>
            {lan.name}
          </option>
        ))}
      </Select>
      <AddLink
        disabled={language === ""}
        mt={3}
        onClick={() => {
          if (!myLanguages.find(h => h.code === language.code) && !!language) {
            setMyLanguages([...myLanguages, language])
          }
        }}
      >
        + ADD LANGUAGE
      </AddLink>
      {myLanguages ? (
        <Flex flexWrap="wrap">
          {myLanguages.length > 0 &&
            myLanguages.map(point => (
              <Chip
                key={point.code}
                mr={2}
                mt={2}
                trailingIcon="close"
                color="primary.0"
                onClick={() => {
                  setMyLanguages([
                    ...myLanguages.filter(el => el.code !== point.code)
                  ])
                }}
              >
                {point.name}
              </Chip>
            ))}
        </Flex>
      ) : null}
      <Button
        mt={3}
        elevation={0}
        variant="outline.primary.0"
        width="120px"
        onClick={() => {
          onUpdate({ languages: myLanguages.map(lan => lan.code) })
          toggle()
        }}
      >
        SAVE
      </Button>
    </Flex>
  )
}

const DataShowing = ({ languages }) =>
  languages ? (
    <>
      <Text mt={2}>Your selected languages:</Text>
      <Flex flexWrap="wrap">
        {languages.length > 0 &&
          languages.map(point => (
            <StyledChip key={point.code} mr={2} mt={2} cursor="none">
              {point.name}
            </StyledChip>
          ))}
      </Flex>
    </>
  ) : (
    <Text mt={2}>You have not selected any languages yet</Text>
  )

const Languages = ({
  languages,
  onUpdate,
  allLanguages,
  toggle,
  hideCancelButton = false
}) => {
  const [dataShowing, setDataShowing] = useState(true)
  return (
    <ProfileSection
      title="Languages"
      icon="translate"
      onClick={() => setDataShowing(!dataShowing)}
      toggle={toggle}
      dataShowing={dataShowing}
      hideCancelButton={hideCancelButton}
    >
      {dataShowing ? (
        <DataShowing languages={languages} />
      ) : (
        <FormShowing
          toggle={toggle || (() => setDataShowing(true))}
          languages={languages}
          onUpdate={onUpdate}
          allLanguages={allLanguages}
        />
      )}
    </ProfileSection>
  )
}

export default Languages
