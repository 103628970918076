import React from "react"
import styled from "styled-components"
import { Flex } from "@engaging-tech/components"

import * as Icons from "./icons/index"

const IconWrapper = styled(Flex)`
  flex-shrink: 0;

  svg {
    max-width: 38px;
    max-height: 38px;
  }

  path {
    stroke: ${({ theme }) => theme.colors.secondary[0]};
  }

  circle {
    stroke: ${({ theme }) => theme.colors.secondary[0]};
  }
`

const SurveyIcon = ({ name, ...props }) => {
  const SelectedIcon = Icons[name]

  return (
    <IconWrapper
      alignItems="center"
      justifyContent="center"
      bg="secondary.4"
      borderRadius={99}
      width={64}
      height={64}
      {...props}
    >
      {SelectedIcon}
    </IconWrapper>
  )
}

export default SurveyIcon
