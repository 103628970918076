import React from "react"

import WelcomeCard from "../../features/happinessSurvey/containers/Welcome"
import SurveyLayout from "../../layouts/SurveyLayout"

const Welcome = () => {
  return (
    <SurveyLayout>
      <WelcomeCard />
    </SurveyLayout>
  )
}

export default Welcome
