import { connect } from "react-redux"
import { reportPost } from "../store/globalHub.actions"
import ReportPost from "../components/Posts/ReportPost"

const mapDispatch = dispatch => ({
  onReportPost: ({ id, reason }) => dispatch(reportPost(id, reason))
})

const mapState = (state, OwnProps) => ({ ...OwnProps })
const ReportPostContainer = connect(mapState, mapDispatch)(ReportPost)

export default ReportPostContainer
