import React from "react"

import { libraryPostTypes } from "../../features/userGeneratedContent/lib/postTypes"

import AuthGuard from "../../features/ui/containers/AuthGuard"
import BookmarkedMaterials from "../../features/myLibrary/containers/BookmarkedMaterials"
import BackBar from "../../features/ui/components/Backbar"
import PageLayout from "../../layouts/PageLayout"

const BookmarkedLectures = BookmarkedMaterials(libraryPostTypes.lectures)

const Lectures = () => (
  <AuthGuard>
    <PageLayout
      bg="dark.7"
      banner={() => (
          <BackBar />
      )}
    >
      <BookmarkedLectures />
    </PageLayout>
  </AuthGuard>
)

export default Lectures
