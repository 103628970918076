import { createSelector } from "reselect"

const connectState = state => state.connect

export const getIsLoading = createSelector(
  [connectState, (_, props) => props],
  (state, id) => state?.isLoading[id]
)

export const getHasLoaded = createSelector(
  [connectState, (_, props) => props],
  (state, id) => state?.hasLoaded[id]
)

export const getConnectResponse = createSelector(
  [connectState, (_, props) => props],
  (state, id) => state?.connectResponses[id]
)

export const getHasError = createSelector(
  [connectState, (_, props) => props],
  (state, id) => state?.hasError[id]
)

export const getAllInvites = createSelector(
  connectState,
  state => state?.allInvites
)

export const getInvite = createSelector(
  [getAllInvites, (_, props) => props],
  (invites, id) => invites.find(invite => invite.user.id === id)
)
