import { Button, Flex, Spinner } from "@engaging-tech/components"
import { useLocation } from "@engaging-tech/routing"
import React from "react"
import styled from "styled-components"

const logo = require(`${process.env.RAZZLE_PUBLIC_DIR}/favicon.ico`)

const Image = styled.img`
  width: 24px;
`

const LoadedAllIndicator = () => (
  <Flex width={1 / 1} justifyContent="center">
    <Image src={logo} />
  </Flex>
)

const LoadMore = ({ postType, from, isLoadingMore, hasLoadedAll, onLoad }) => {
  const { pathname } = useLocation()

  const inAppView = pathname.includes("in-app-view")

  if (hasLoadedAll) {
    return <LoadedAllIndicator />
  }

  if (isLoadingMore) {
    return <Spinner py={3} color="primary.0" justifyContent="center" width={1 / 1} />
  }

  return (
    <Button
      variant="primary.0"
      onClick={() => onLoad(postType, from, inAppView ? "in-app-view" : "")}
      width={1 / 1}
    >
      Load more
    </Button>
  )
}

export default LoadMore
