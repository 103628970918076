import React from "react"
import { useSelector } from "react-redux"
import { Flex, Text } from "@engaging-tech/components"
import PremiumFeatures from "../../ui/components/PremiumFeatures"
import Mentees from "../containers/Mentee"
import Mentors from "../containers/Mentor"
import { getIsPremium } from "../../yourProfile/store/yourProfile.selectors"
import WidgetCard from "../../developCareer/components/WidgetCard"
import CircleIcon from "../../ui/components/CircleIcon"
import ServicesToggle from "../../developCareer/widgets/containers/ServicesToggle"

const MentorMatcher = () => {
  const isPremium = useSelector(getIsPremium)
  const premiumTexts = {
    title: "Looking for a mentor?",
    signUpText: "become a mentee, or a mentor and a mentee!",
    subTexts: [
      "Get connected with a mentor who can help guide and advice you at any point in your career"
    ],
    icon: "person_rounded"
  }
  return (
    <Flex
      flexDirection={["column", "column", "row"]}
      width={1 / 1}
      alignItems="stretch"
    >
      {isPremium ? (
        <WidgetCard width={1 / 1} m={[0, 0, 2]}>
          <Flex p={3} width={1 / 1}>
            <CircleIcon
              name="supervised_user_circle"
              bg="primary.0"
              color="secondary.0"
              mr={3}
            />
            <Flex flexDirection="column">
              <Text fontWeight={600} mt={2} mb={1}>
                Find a Mentor
              </Text>
              <ServicesToggle mentor />
            </Flex>
          </Flex>
          <Mentors mentor />
        </WidgetCard>
      ) : (
        <PremiumFeatures {...premiumTexts} />
      )}
      <WidgetCard width={1 / 1} m={[0, 0, 2]}>
        <Flex p={3} width={1 / 1} alignItems="center">
          <CircleIcon
            name="record_voice_over"
            bg="primary.0"
            color="secondary.0"
            mr={3}
          />
          <Flex flexDirection="column">
            <Text fontWeight={600} mt={2} mb={1}>
              Find a Mentee
            </Text>
            <ServicesToggle mentee />
          </Flex>
        </Flex>
        <Mentees mentee />
      </WidgetCard>
    </Flex>
  )
}

export default MentorMatcher
