import widgetDictonary from "../lib/widgetDictonary"

const findWidgetWithinDictonaryById = id => {
  const matchingWidgetKey = Object.keys(widgetDictonary).find(
    key => widgetDictonary[key].id === id
  )

  return matchingWidgetKey ? widgetDictonary[matchingWidgetKey] : undefined


}


export { findWidgetWithinDictonaryById }
