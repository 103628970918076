import { Button, Flex, H2, Icon, Text } from "@engaging-tech/components"
import { useLocation, useParams } from "@engaging-tech/routing"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import SelectShaped from "../../ui/components/SelectShaped"
import UseBackBar from "../../ui/components/UseBackBar"

const wfhIcon = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/work-from-home-icon.png`)

const IconImage = styled.img`
  width: 30px;
`

const YourSurveysHeader = ({ type, surveyTitle }) => (
  <Flex flexDirection="column" width={1 / 1}>
    <UseBackBar to="/your-surveys" target="results-layout" />
    <H2 fontWeight={500} fontFamily="serif" width={1 / 1} fontSize={[5, 7]}>
      {(type === "workFromHome" || type === "individualWorkFromHome") && <IconImage alt="" src={wfhIcon} />}{" "}
      {surveyTitle}
    </H2>
  </Flex>
)

const ResultsPageHeader = ({ type, surveyTitle, savantaId, languages, load }) => {
  const { pathname } = useLocation()
  const isYourSurvey = pathname.split("/")[1] === "your-surveys"
  const { id } = useParams()
  const { t, i18n } = useTranslation(["results"])

  if (isYourSurvey) return <YourSurveysHeader type={type} surveyTitle={surveyTitle} />

  return (
    <Flex flexDirection="column" width={1 / 1}>
      <Flex flexDirection={["column", "row"]}>
        <H2 fontWeight={500} fontFamily="serif" width={[1 / 1, 3 / 4]} fontSize={[5, 7]}>
          {t("header.title", "Replacement text.")}
        </H2>
        <Flex width={[1 / 1, 1 / 4]} alignItems="center">
          <SelectShaped
            mt={[2, 0]}
            width={1 / 1}
            borderRadius={0}
            bg="light.0"
            id="language"
            placeholder="option/opswin"
            defaultValue=""
            value={i18n.languages[0]}
            onChange={e => {
              i18n.changeLanguage(e.target.value)
              load(id)
            }}
          >
            {languages?.map(lang => (
              <option key={lang.code} value={lang.code}>
                {lang.nativeName}
              </option>
            ))}
          </SelectShaped>
          <Icon ml={1} size={40} name="public" color="#00008b" />
        </Flex>
      </Flex>

      <Text my={3} color="dark.2" width={1 / 2} fontSize={[3, 4]}>
        {t("header.subtitle", "Replacement text.")}
      </Text>

      {savantaId && (
        <Button variant="primary.0" mb={3}>
          <a rel="noreferrer" target="_blank" href={`https://sample.savanta.com/V2/c/${savantaId}`}>
            Collect reward
          </a>
        </Button>
      )}
    </Flex>
  )
}

export default ResultsPageHeader
