import { Button, Flex, Text } from "@engaging-tech/components"
import styled from "styled-components"

export const ChipContainer = styled(Flex)`
  margin: 10px 0;

  width: 100%;

  & > *:not(:last-child) {
    margin-right: 0.5rem; /* Equivalent to 8px if base font-size is 16px */
  }
`

export const Chip = styled(Button)`
  background-color: #003e4c;

  display: flex;

  & > *:not(:last-child) {
    margin-right: 0.5rem; /* Equivalent to 8px if base font-size is 16px */
  }
`

export const ChipText = styled(Text)`
  color: #ffffff;

  font-weight: 400;
  font-size: 14px;
  line-height: 18.2px;

  text-transform: none;
`
