import React from "react"
import styled from "styled-components"
import { Button, Text } from "@engaging-tech/components"

const ButtonText = styled(Text)`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary[0]};
  box-shadow: 0;
`

const OutlinedPrimaryButton = ({ text, ...props }) => (
  <Button
    alignItems="center"
    width="auto"
    variant="outline.primary.0"
    elevation={0}
    {...props}
  >
    <ButtonText>{text}</ButtonText>
  </Button>
)

export default OutlinedPrimaryButton
