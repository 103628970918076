import { useParams, usePaths } from "@engaging-tech/routing"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import SEO from "../../../../helpers/SEO"
import BaseLayout from "../../../../layouts/BaseLayout"
import { loadJob, loadJobRankings } from "../../store/jobFinder.actions"
import { getJobDetails } from "../../store/jobFinder.selectors"
import JobDetailComponent from "../jobsFeed/jobDetail"

const JobDetail = () => {
  const { id } = useParams()
  const paths = usePaths()
  const dispatch = useDispatch()

  const jobDetails = useSelector(getJobDetails)

  useEffect(() => {
    if (jobDetails?.company_name_key) {
      dispatch(loadJobRankings([{ companyNameKey: jobDetails.company_name_key }]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobDetails?.company_name_key])

  useEffect(() => {
    dispatch(loadJob(id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <>
      {jobDetails ? (
        <SEO
          title={jobDetails?.title}
          keywords={jobDetails?.keywords}
          url={`${paths.findAJob.jobFinder.index}/${jobDetails.job_id}`}
          image={jobDetails?.logo}
        />
      ) : null}

      <BaseLayout>
        <JobDetailComponent fullScreen job={jobDetails} />
      </BaseLayout>
    </>
  )
}

export default JobDetail
