import React from "react"
import styled from "styled-components"
import { Link } from "@engaging-tech/routing"
import { Text, Box, Flex } from "@engaging-tech/components"

import pluralise from "../../../userGeneratedContent/lib/pluralise"

import FeaturedCards from "./FeaturedCards"

const TitleWrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[4]};
`

const TitleText = styled(Text)`
  text-transform: capitalize;
`

const FeaturedGrid = styled(Flex)`
  align-items: stretch;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  padding: 1px;
`

const Title = ({ type }) => {
  const postType = pluralise(type)

  return (
    <TitleWrapper pb={3}>
      <TitleText fontSize={3} fontWeight="500">
        Featured{" "}
        <Text color="primary.0" fontSize={3} fontWeight={700}>
          <Link to={`/business-library/browse/${postType}`}>{postType}</Link>
        </Text>
      </TitleText>
    </TitleWrapper>
  )
}

const FeaturedPosts = ({ posts, type }) =>
  posts && (
    <Box mb={4}>
      <Title type={type} />
      <FeaturedGrid flexWrap={["nowrap", "nowrap", "wrap"]}>
        <FeaturedCards posts={posts} type={type} />
      </FeaturedGrid>
    </Box>
  )

export default FeaturedPosts
