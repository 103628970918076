import { getConfig } from "@engaging-tech/ssr-config"
import { API, Amplify } from "aws-amplify"

import { JOBS_FEED, JOBS_FEED_FILTERS, JOB_BY_ID, JOB_COMPANY_RANKING } from "./jobFinder.queries"

export const getJobsFeed = filters => {
  const config = getConfig()
  Amplify.configure({
    aws_appsync_graphqlEndpoint: config.appSync.url,
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_appsync_region: config.appSync.publicGatewayRegion
  })

  return API.graphql({
    query: JOBS_FEED,
    variables: { ...filters }
  })
}
export const getJobsFeedFilters = () => {
  const config = getConfig()
  Amplify.configure({
    aws_appsync_graphqlEndpoint: config.appSync.url,
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_appsync_region: config.appSync.publicGatewayRegion
  })

  return API.graphql({
    query: JOBS_FEED_FILTERS
  })
}
export const getJobById = ({ id }) => {
  const config = getConfig()
  Amplify.configure({
    aws_appsync_graphqlEndpoint: config.appSync.url,
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_appsync_region: config.appSync.publicGatewayRegion
  })

  return API.graphql({
    query: JOB_BY_ID,
    variables: { job_id: id }
  })
}
export const getJobCompanyRanking = ({ companyNameKey }) => {
  const config = getConfig()
  Amplify.configure({
    aws_appsync_graphqlEndpoint: config.appSync.url,
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_appsync_region: config.appSync.publicGatewayRegion
  })

  return API.graphql({
    query: JOB_COMPANY_RANKING,
    variables: { companyNameKey }
  })
}
