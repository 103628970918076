import { connect } from "react-redux"

import {
  getIsLoading,
  getSelectedProduct,
  getSelectedFormat
} from "../store/libraryBooks.selectors"

import { addToBasket } from "../store/libraryBooks.actions"

import AddToBasketComponent from "../components/SelectedBook/AddToBasket"

const mapState = state => ({
  isLoading: getIsLoading(state),
  product: getSelectedProduct(state),
  format: getSelectedFormat(state)
})

const mapDispatch = dispatch => ({
  addToBasket: ({ format, quantity }) =>
    dispatch(addToBasket({ format, quantity }))
})

const AddToBasket = connect(mapState, mapDispatch)(AddToBasketComponent)

export default AddToBasket
