import React from "react"
import { usePaths } from "@engaging-tech/routing"

import { libraryPostTypes } from "../../../features/userGeneratedContent/lib/postTypes"

import DetailsLayout from "../../../layouts/DetailsLayout"
import AuthGuard from "../../../features/ui/containers/AuthGuard"
import Publish from "../../../features/businessLibrary/containers/Publish"

const PublishArticleForm = Publish(libraryPostTypes.articles)

const Article = () => {
  const paths = usePaths()

  return (
    <AuthGuard>
      <DetailsLayout
        backTo={paths.resourceCentre.browse.articles.selectedArticle}
      >
        <PublishArticleForm />
      </DetailsLayout>
    </AuthGuard>
  )
}

export default Article
