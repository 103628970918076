import { useLocation, useRouter } from "@engaging-tech/routing"
import React, { useEffect, useRef, useState } from "react"

import { createUrl } from "../../../../../lib/utils"
import {
  DropdownButton,
  DropdownContainer,
  DropdownItem,
  DropdownList,
  StyledIcon
} from "./selectInputs.styled"

const SelectInput = ({ options }) => {
  const location = useLocation()
  const dropdownRef = useRef()
  const router = useRouter()

  const optionSearchParams = new URLSearchParams(location.search)
  const filterValue = optionSearchParams.get(options.id) ?? ""

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const toggleDropdown = () => {
    setIsOpen(prevIsOpen => !prevIsOpen)
  }

  const handleOptionClick = option => {
    const newValue = option === filterValue ? undefined : option

    if (newValue) {
      optionSearchParams.set(options.id, newValue)
    } else {
      optionSearchParams.delete(options.id)
    }
    const optionUrl = createUrl(location.pathname, optionSearchParams)
    toggleDropdown()
    router.navigate(optionUrl)
  }

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownButton isOpen={isOpen} onClick={toggleDropdown} selected={filterValue}>
        {options.label}
        <StyledIcon />
      </DropdownButton>
      {isOpen && (
        <DropdownList>
          {options.items.map((item, index) => (
            <DropdownItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              selected={filterValue === item}
              onClick={() => handleOptionClick(item)}
            >
              {item}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  )
}

export default SelectInput
