const PLURALS = ["research", "all categories", "expert", "advice"]

/**
 * This attempts to pluralise strings by adding an 's' character to the end
 * @param {string} text Text to pluralise
 * @returns A new string that is 'pluralised'
 */
const pluralise = text =>
  PLURALS.find(term => term === text.toLowerCase()) ? text : `${text}s`

export default pluralise
