import { Formik } from "formik"
import React from "react"
import { object, string } from "yup"

import Form from "./form"

const initialValues = {
  content: ""
}

const validation = object().shape({
  content: string().required("Comment text is required")
})

const CreateCommentForm = ({ isCommenting, onSubmit, user, isAuthenticated, postId }) =>
  isAuthenticated ? (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={comment => onSubmit({ ...comment, postId })}
    >
      {props => <Form {...props} isLoading={isCommenting} user={user} />}
    </Formik>
  ) : null
export default CreateCommentForm
