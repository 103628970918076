import { Box, Flex, Text } from "@engaging-tech/components"
import { getCountries } from "@engaging-tech/frontend-utils/dist/countryCodes"
import React from "react"
import * as Yup from "yup"

import DropdownInput from "../../../formFlowWizard/components/formElements/DropdownInput"
import FieldInput from "../../../formFlowWizard/components/formElements/FieldInput"
import LocationInput from "../../../formFlowWizard/components/formElements/locationInput"
import Separator from "../../../ui/components/Separator"
import MarkPriceQuote from "./MarkPriceQuote"

const icon = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/create.png`)

const info = {
  icon,
  title: "Create a CV",
  submitData: false
}

const validationSchema = Yup.object().shape({
  targetPosition: Yup.object().shape({
    jobTitle: Yup.string().required("This field cannot be left blank"),
    company: Yup.string().required("This field cannot be left blank")
  }),
  phone: Yup.string().required("This field cannot be left blank"),
  address: Yup.object()
    .shape({
      name: Yup.string()
    })
    .nullable(),
  references: Yup.object().shape({
    id: Yup.string().required("This field cannot be left blank")
  })
})

const ContactDetails = ({ formik }) => {
  const handleAddress = loc => {
    const countryFound = getCountries().filter(
      country =>
        country.code ===
        loc.address_components.filter(component => component.types.includes("country"))[0].short_name
    )[0]

    const countryInfo = loc.address_components.filter(country =>
      country.types.includes("country", "political")
    )[0]

    formik.setValues({
      ...formik.values,
      address: {
        name: loc.formatted_address,
        countryId: countryFound.id,
        point: {
          coordinates: [loc.geometry.location.lat(), loc.geometry.location.lng()]
        },
        country: {
          shortName: countryInfo.short_name,
          name: countryInfo.long_name
        },
        vicinity: loc.vicinity
      }
    })
  }

  return (
    <Flex width={1 / 1} flexDirection="column">
      <MarkPriceQuote quote="What needs to shine through is that you have a genuine interest in, and admiration for the organisation you have written to." />
      <FieldInput
        name="targetPosition.company"
        inputLabel="Target Company"
        label="Which company are you targeting?"
      />
      <FieldInput
        name="targetPosition.jobTitle"
        inputLabel="Target Job"
        label="Which job position are you targeting?"
      />
      <Separator width={1 / 1} lineColor="primary.0" />
      <Text my={3} fontWeight={700}>
        Your Contact Details
      </Text>
      <FieldInput name="phone" inputLabel="Phone Number" label="Phone Number" />
      <Box width={1 / 1}>
        <LocationInput
          name="address"
          label="Flat/House Number, Street name, City, Postcode"
          selectedLocationCallback={loc => handleAddress(loc)}
        />
      </Box>
      <DropdownInput
        name="references"
        placeholderLabel="Please Select"
        label="References"
        options={[
          { id: "1", label: "References available" },
          { id: "2", label: "References not available" }
        ]}
      />
    </Flex>
  )
}

export default { component: ContactDetails, validationSchema, info }
