import { getConfig } from "@engaging-tech/ssr-config"

import { hubPostTypes } from "../../userGeneratedContent/lib/postTypes"

const callsToAction = {
  [hubPostTypes.article]: {
    title: "Are you a business looking to publish your own article?",
    description:
      "Upload your own articles to the Business Library and a preview will appear here on the Global Hub.",
    action: "Publish an article",
    url: () => "/global-hub/publish?type=article"
  },
  [hubPostTypes.podcast]: {
    title: "Are you a business looking to publish your own podcast?",
    description:
      "Upload your own podcasts to the Business Library and a preview will appear here on the Global Hub.",
    action: "Publish a podcast",
    url: () => "/global-hub/publish?type=podcast"
  },
  [hubPostTypes.lecture]: {
    title: "Are you a business looking to publish your own lecture?",
    description:
      "Upload your own lectures to the Business Library and a preview will appear here on the Global Hub.",
    action: "Publish a lecture",
    url: () => "/global-hub/publish?type=lecture"
  },
  [hubPostTypes.research]: {
    title: "Are you a business looking to publish your own research?",
    description:
      "Upload your own research to the Business Library and a preview will appear here on the Global Hub.",
    action: "Publish research",
    url: () => "/global-hub/publish?type=research"
  },
  [hubPostTypes.event]: {
    title: "Want to post an event?",
    description: "Sign up to WorkL For Business to post events and for all your business needs.",
    action: "View Business Services",
    external: true,
    newTab: true,
    url: () =>
      `${
        getConfig().worklForBusiness.public.index +
        getConfig().worklForBusiness.public.paths.worklBusinessServices
      }`
  },
  [hubPostTypes.advice]: {
    title: "Need expert advice on a specific topic?",
    description: "Ask our WorkL experts questions and receive professional advice.",
    action: "Ask an expert",
    url: () => "/global-hub/publish?type=advice"
  },
  default: {
    title: "Are you a business owner or leader looking to post vacancies?",
    description: "Sign up to WorkL for Business to post jobs instantly.",
    action: "SIGN UP",
    external: true,
    newTab: true,
    url: () => `${getConfig().worklForBusiness.app.index + getConfig().worklForBusiness.app.paths.signUp}`
  }
}

const getCallToAction = category => {
  return callsToAction[category] || callsToAction.default
}

export default getCallToAction
