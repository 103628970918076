import { createSelector } from "reselect"

const userContentState = state => state.userContent

export const getIsLoading = createSelector(
  userContentState,
  state => state.isLoading
)

export const getIsSeriesLoading = createSelector(
  userContentState,
  state => state.isSeriesLoading
)

export const getHasCreated = createSelector(
  userContentState,
  state => state.hasCreated
)

export const getSeries = createSelector(
  userContentState,
  state => state.series
)

export const getHasSeries = createSelector(
  getSeries,
  series => series.length > 0
)

export const getHasCreatedSeries = createSelector(
  userContentState,
  state => state.hasCreatedSeries
)

export const getIntentSecret = createSelector(
  userContentState,
  state => state.intentSecret
)
