import { Flex } from "@engaging-tech/components"
import React from "react"
import { useTranslation } from "react-i18next"

import PercentageGraphic from "../../../ui/components/PercentageGraphic"
import ReportItem from "../../components/ReportItem"
import ResultCard from "../../components/ResultCard"
import HappinessComparisonChart from "./HappinessComparisonChart"
import StatisticBox from "./StatisticBox"

const HappinessDataHighlight = ({
  happinessScore,
  industryScore,
  globalScore,
  completedAt,
  happinessChange,
  sharable,
  type
}) => {
  const { t } = useTranslation(["results"])
  const formatDate = date => `${date.getDate()} ${t(`months.${[date.getMonth().toString()]}`, "")} 
    ${date.getFullYear()}`

  return (
    <ResultCard flexDirection={["column", "row"]}>
      <Flex flexDirection="column" justifyContent="flex-start">
        <ReportItem
          heading={t("happiness_data_highlight.heading", "")}
          subHeading={`${t("happiness_data_highlight.sub_heading", "")} ${formatDate(new Date(completedAt))}`}
        >
          <PercentageGraphic score={happinessScore} />
          <StatisticBox
            comparedToIndustry={happinessScore - industryScore}
            happinessChange={happinessChange}
          />
        </ReportItem>
      </Flex>
      <HappinessComparisonChart
        score={happinessScore}
        industryScore={industryScore}
        globalScore={globalScore}
        type={type}
        sharable={sharable}
      />
    </ResultCard>
  )
}

export default HappinessDataHighlight
