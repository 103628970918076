import { connect } from "react-redux"
import { yourProfileSelectors } from "../../yourProfile"
import {
  getAllWidgets,
  getWidgetLayout
} from "../store/developCareer.selectors"
import { addWidgets } from "../store/developCareer.actions"
import AddAWidgetComponent from "../components/AddAWidget"

const mapState = state => ({
  allWidgets: getAllWidgets(state),
  widgetLayout: getWidgetLayout(state),
  userId: yourProfileSelectors.getUserId(state)
})

const mapDispatch = dispatch => ({
  addWidgets: payload => dispatch(addWidgets(payload, true))
})

const AddAWidget = connect(mapState, mapDispatch)(AddAWidgetComponent)

export default AddAWidget
