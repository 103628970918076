import useEmblaCarousel from "embla-carousel-react"
import React, { useEffect, useState } from "react"
import ReactPlayer from "react-player"
import styled from "styled-components"

import { ChevronLeftIcon, ChevronRightIcon } from "../../../../../assets/icons"

const CarouselContainer = styled.section`
  width: 100%;
  margin: auto;
  overflow: hidden;
`

const Viewport = styled.div`
  overflow: hidden;
  position: relative;
  /* pointer-events: none; */
`

const SlideContainer = styled.div`
  display: flex;
  gap: 16px;
  transition: transform 0.2s ease;

  @media (min-width: 768px) {
    margin-left: calc(50% - 240px);
    margin-right: calc(50% - 240px);
  }
`

const Slide = styled.div`
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  position: relative;

  @media (min-width: 768px) {
    width: 480px;
  }
`

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%;
`

const ReactPlayerStyled = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
`

const CarouselButton = styled.button`
  display: inline-flex;
  align-items: center;

  background-color: transparent;
  border: none;

  font-weight: 700;
  font-size: 14px;
  line-height: 18.2px;

  color: ${({ disabled }) => (disabled ? "#D9D9D9" : "#003e4c")};

  &:disabled {
    svg {
      path {
        fill: #d9d9d9;
      }
    }
  }

  margin: 8px 8px 0;

  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`

export default function VideoCarousel({ slides, options }) {
  const carouselOptions = {
    ...options,
    align: "center",
    containScroll: "trimSnaps",
    draggable: false,
    watchDrag: false
  }

  const [emblaRef, emblaApi] = useEmblaCarousel(carouselOptions)
  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on("select", () => {
        setSelectedIndex(emblaApi.selectedScrollSnap())
      })
      emblaApi.on("init", () => {
        emblaApi.reInit()
      })
    }
  }, [emblaApi])

  return (
    <CarouselContainer>
      <Viewport ref={emblaRef}>
        <SlideContainer>
          {slides.map((slide, index) => (
            <Slide key={slide}>
              <VideoWrapper>
                <ReactPlayerStyled
                  url={slide}
                  light
                  playing={index === selectedIndex}
                  controls
                  width="100%"
                  height="100%"
                />
              </VideoWrapper>
            </Slide>
          ))}
        </SlideContainer>
      </Viewport>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%"
        }}
      >
        <CarouselButton type="button" disabled={selectedIndex === 0} onClick={() => emblaApi.scrollPrev()}>
          <ChevronLeftIcon />
          <p>Prev</p>
        </CarouselButton>
        <CarouselButton
          type="button"
          disabled={selectedIndex === slides.length - 1}
          onClick={() => emblaApi.scrollNext()}
        >
          <p>Next</p>
          <ChevronRightIcon />
        </CarouselButton>
      </div>
    </CarouselContainer>
  )
}
