import React from "react"
import styled from "styled-components"
import { Avatar, Icon, Flex, Text } from "@engaging-tech/components"

const Wrapper = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.dark[5]};
  cursor: pointer;
`

const AddComment = ({ avatar, onClick }) => (
  <Wrapper alignItems="center" p={3} onClick={onClick}>
    <Flex mr={2} width={40} height={40}>
      <Avatar picture={avatar} />
    </Flex>
    <Icon name="edit" width="auto" m={0} mr={2} />
    <Text color="dark.2" fontWeight="500">
      Comment
    </Text>
  </Wrapper>
)

export default AddComment
