import { H2 } from "@engaging-tech/components"
import React from "react"

import { RoundedBorderFlex } from "./formHeader.styled"
import RoundedImageContainer from "./roundedImageContainer"

const FormHeader = ({ title, icon }) => (
  <RoundedBorderFlex
    width={1 / 1}
    bg="tertiary.0"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    alignContent="center"
  >
    <RoundedImageContainer icon={icon} />
    <H2 fontSize={5} color="primary.0" textAlign="center" mt={2} mb={4} fontFamily="Tiempos">
      {title}
    </H2>
  </RoundedBorderFlex>
)

export default FormHeader
