import { createSelector } from "reselect"

const yourProfileState = state => state && state.yourProfile

export const getProfile = createSelector(yourProfileState, state => state?.profile)
export const getMyGoals = createSelector(getProfile, state => state?.goals)

export const getUserId = createSelector(yourProfileState, state => state?.profile?.id)

export const getGoalsViewed = createSelector(getProfile, profile => profile?.goalsViewed)

export const getAllowMarketing = createSelector(getProfile, profile => profile?.allowMarketing)

export const getJobTitle = createSelector(getProfile, profile => profile?.jobTitle)
export const getHasError = createSelector(yourProfileState, state => state?.hasError)
export const getHasLoaded = createSelector(yourProfileState, state => state?.hasLoaded)

export const getUploadingImageError = createSelector(yourProfileState, state => state?.uploadingImageError)

export const getCompany = createSelector(getProfile, profile => profile?.companyName)

export const getFirstName = createSelector(getProfile, profile => profile?.firstName)
export const getLastName = createSelector(getProfile, profile => profile?.lastName)

export const getProfilePictureUrl = createSelector(getProfile, profile => profile?.picture?.large)

export const getShowModal = createSelector(yourProfileState, profile => profile?.showModal)

export const getIsLoading = createSelector(yourProfileState, profile => profile?.isLoading)

export const getAge = createSelector(getProfile, profile => profile?.age)

export const getGender = createSelector(getProfile, profile => profile?.gender)

export const getPersonalStatement = createSelector(getProfile, profile => profile?.personalStatement)

export const getQualifications = createSelector(getProfile, profile => profile?.qualifications || [])

export const getPositionsHeld = createSelector(getProfile, profile => profile?.positionsHeld || [])

export const getIndustries = createSelector(getProfile, profile => profile?.industries || [])

export const getSkills = createSelector(getProfile, profile => profile?.skills)

export const getHobbies = createSelector(getProfile, profile => profile?.hobbies)

export const getLanguages = createSelector(getProfile, profile => profile?.languages)

export const getServices = createSelector(getProfile, profile => profile?.services)

export const getJobMatcherProfile = createSelector(getProfile, profile => profile?.jobMatcher)

export const getJobMatcherDesiredLocation = createSelector(getProfile, profile => profile?.desiredJobLocation)

export const getJobMatcherProfileComplete = createSelector(getProfile, profile => {
  if (!profile?.jobMatcher) {
    return false
  }
  if (profile?.jobMatcher?.desiredJobRoles && profile.jobMatcher.desiredJobRoles.length > 0) {
    return true
  }
  return false
})

export const getMenteeMatcherStatus = createSelector(getServices, services => services?.menteeMatcher)

export const getMentorMatcherStatus = createSelector(getServices, services => services?.mentorMatcher)

export const getJobMatcherStatus = createSelector(
  getServices,
  services => !!(services?.jobMatcher && services?.jobMatcher === "ON")
)

export const getIsConfirmed = createSelector(getProfile, profile => (profile ? profile.confirmed : false))

export const getIsVerificationEmailSending = createSelector(
  yourProfileState,
  profile => profile?.isVerificationEmailSending
)

export const getIsVerificationEmailSent = createSelector(
  yourProfileState,
  profile => profile?.isVerificationEmailSent
)

// PREMIUM //
export const getPremiumState = createSelector(getProfile, profile => profile?.premium)
export const getIsPremium = createSelector(getPremiumState, premium => premium?.active)

export const getIsPremiumLoading = createSelector(getProfile, profile => profile?.isLoadingPremium)

export const getHasErrorPremium = createSelector(getProfile, profile => profile?.premium.hasErrorPremium)

export const getPaymentIntent = createSelector(getProfile, profile => profile?.paymentIntent)

export const getShowSuccessPage = createSelector(getProfile, profile => profile?.showSuccessPage)

export const getPremiumExpiration = createSelector(getProfile, profile => profile?.premiumExpiration)

export const getIsPremiumCancelled = createSelector(getProfile, profile => profile?.premiumCancelled)

export const getDiscountCode = createSelector(yourProfileState, state => state?.discountCode)

export const getPromoId = createSelector(yourProfileState, state => state?.promoId)

export const getDiscountCodeError = createSelector(yourProfileState, state => state?.discountCodeError)

export const getIsDiscountCodeLoading = createSelector(
  yourProfileState,
  state => state?.isDiscountCodeLoading
)

// export const getIsPremium = createSelector(
//   yourProfileState,
//   state => state?.isPremium
// )
