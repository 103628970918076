import { Flex } from "@engaging-tech/components"
import React from "react"

import SiteLogo from "../../ui/components/SiteLogo"
import surveyLogoContainer from "../containers/surveyLogo.container"

const SurveyLogo = ({ brand }) => (
  <Flex width={1 / 1} justifyContent="center" mb={[3, 4]}>
    {brand.logo ? <img src={brand.logo.url} alt="" height="100" /> : <SiteLogo />}
  </Flex>
)

export default surveyLogoContainer(SurveyLogo)
