import { usePaths, useRouter } from "@engaging-tech/routing"
import React from "react"

import WidgetCard from "../../components/WidgetCard"
import DynamicState from "../../containers/DynamicState"

const image = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/career-developer/widget-images/job-finder-free-no-bg.svg`)
const imagePremium = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/career-developer/widget-images/job-finder-premium-no-bg.svg`)

const JobFinderWidget = ({ id, isPremium }) => {
  const paths = usePaths()
  const router = useRouter()
  return (
    <WidgetCard width="100%" bg="light.0" height="100%">
      <DynamicState
        removable
        id={id}
        title="Job Finder"
        subTitle="Use our Job Finder service to look for jobs that are available now and start applying. Click the button below to get started!"
        image={isPremium ? imagePremium : image}
        bgColor="secondary.5"
        btnText="BEGIN JOB SEARCH"
        onClick={() => router.navigate(paths.findAJob.index)}
      />
    </WidgetCard>
  )
}

export default JobFinderWidget
