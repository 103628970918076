import { createSelector } from "reselect"

const bookmarks = state => state.bookmarks

export const getBookmarkedMaterials = createSelector(bookmarks, state => {
  return state.bookmarkedMaterials
})

export const getIsBookmarked = createSelector(
  [getBookmarkedMaterials, (state, props) => props.id],
  (bookmarkedMaterials, idToSearch) => {
    return !!bookmarkedMaterials.find(
      bookmarkedMaterial => bookmarkedMaterial.refId === idToSearch
    )
  }
)
