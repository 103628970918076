/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import { Flex, Box } from "@engaging-tech/components"

const Hero = ({ children, ...props }) => (
  <Flex as="section" justifyContent="center" alignItems="center" {...props}>
    <Box m={0} p={0} maxWidth={1024}>
      {children}
    </Box>
  </Flex>
)

export default Hero
