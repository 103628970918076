/* eslint-disable no-nested-ternary */
import { Avatar, Flex, Icon, Text } from "@engaging-tech/components"
import { decode } from "@engaging-tech/jwt"
import { useLocation } from "@engaging-tech/routing"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { signInSuccess } from "../../../../account/store/account.actions"
import { load } from "../../../../yourProfile/store/yourProfile.actions"
import CreatePostForm from "../../../forms/createPostForm"
import { CreateCard } from "./createPost.styled"
import NotAuthenticated from "./notAuthenticated"

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const CreatePost = ({ profile, isAuthenticated, onSubmit, postCount, isPosting, isVisible }) => {
  const location = useLocation()

  const dispatch = useDispatch()

  const isInAppView = location.pathname.includes("/in-app-view")
  const inAppViewToken = localStorage.getItem("custom-token")
  const tokenDecoded = useMemo(() => decode(inAppViewToken), [inAppViewToken])

  useEffect(() => {
    if (isInAppView && inAppViewToken && tokenDecoded) {
      dispatch(signInSuccess(tokenDecoded.sub))
      dispatch(load({ userId: tokenDecoded.sub, place: "in-app-view" }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inAppViewToken, tokenDecoded])

  const [showForm, setShowForm] = useState(false)

  useEffect(() => {
    setShowForm(false)
  }, [postCount])

  const toggleForm = () => setShowForm(!showForm)

  if (!isAuthenticated) {
    return <NotAuthenticated />
  }

  if (!isVisible) {
    return null
  }

  return (
    <CreateCard
      width={1 / 1}
      p={3}
      mb={[3, 4, 4]}
      onClick={!showForm ? toggleForm : undefined}
      isOpen={showForm}
    >
      {showForm ? (
        <CreatePostForm user={profile} onClose={toggleForm} onSubmit={onSubmit} isPosting={isPosting} />
      ) : profile ? (
        <Flex alignItems="center" width={1 / 1}>
          <Avatar picture={profile.picture.small || profilePlaceholder} />
          <Icon name="edit" width="auto" m={0} mr={2} ml={2} />
          <Text color="dark.2" fontWeight="500">
            Create a post
          </Text>
        </Flex>
      ) : null}
    </CreateCard>
  )
}

export default CreatePost
