import { connect } from "react-redux"

import { loadAll, resetFilters } from "../store/businessLibrary.actions"
import {
  getFilter,
  getHasFiltered,
  getPostType,
  getSearchTerm
} from "../store/businessLibrary.selectors"

import Filter from "../components/Search/Filter"

const mapDispatch = dispatch => ({
  onFilter: (category, postType, searchTerm) =>
    dispatch(loadAll({ category, postType, searchTerm })),
  onReset: (postType, searchTerm) => dispatch(loadAll({ postType, searchTerm }))
})

const mapState = state => ({
  postType: getPostType(state),
  searchTerm: getSearchTerm(state)
})

const BrowseFilters = connect(mapState, mapDispatch)(Filter)

export default BrowseFilters
