import React from "react"
import BrowseNav from "../../../features/businessLibrary/components/BrowseNav"
import SideBar from "../../../features/businessLibrary/containers/SideBar"
import { AllBooks, Search as SearchBooks } from "../../../features/gardners"
import { libraryPostTypes } from "../../../features/userGeneratedContent/lib/postTypes"
import BrowseLibraryLayout from "../../../layouts/BrowseLibraryLayout"

const Banner = () => (
  <>
    <SearchBooks />
    <BrowseNav />
  </>
)

const Books = () => (
  <BrowseLibraryLayout
    sideColumn={SideBar(libraryPostTypes.books)}
    // topRow={TopBar(libraryPostTypes.books)}
    banner={Banner}
  >
    <AllBooks />
  </BrowseLibraryLayout>
)

export default Books
