import { createSelector } from "reselect"

import { surveyResultsSelectors } from "../.."
import { developCareerSelectors } from "../../../developCareer/index"

export const getCompletedAt = createSelector(
  surveyResultsSelectors.getResponseResults,
  response => response?.completedAt
)

export const getHappinessDataHighlight = createSelector(
  surveyResultsSelectors.getResultsData,
  data => data && data.happinessDataHighlight
)

export const getHappinessScore = createSelector(getHappinessDataHighlight, data => data.score)

export const getIndustryScore = createSelector(getHappinessDataHighlight, data => data.industry)

export const getGlobalScore = createSelector(getHappinessDataHighlight, data => data.global)

export const getHappinessScoreChange = createSelector(
  [developCareerSelectors.getIndividualSurveySubmissions, (state, submissionId) => submissionId],
  (surveys, submissionId) => {
    const foundSurveyIndex = surveys.findIndex(survey => survey.id === submissionId)

    if (surveys.length <= 1 || foundSurveyIndex === -1) {
      return "-"
    }

    const percentageDifference =
      surveys[foundSurveyIndex].score * 100 - surveys[foundSurveyIndex + 1].score * 100

    const trendDirection = percentageDifference > 0 ? "+" : ""
    return `${trendDirection}${Math.round(percentageDifference)}%`
  }
)
