import React from "react"
import Trophy from "./trophy.svg"
import AlarmClock from "./alarmClock.svg"
import Medal from "./medal.svg"
import Information from "./information.svg"
import PaperAirplane from "./paperAirplane.svg"
import Resources from "./resources.svg"
import Lightbulb from "./lightbulb.svg"
import ChainLink from "./chainLink.svg"
import Megaphone from "./megaphone.svg"
import Umbrella from "./umbrella.svg"
import Brainstorm from "./brainstorm.svg"
import Environment from "./environment.svg"
import Sun from "./sun.svg"
import Heart from "./heart.svg"
import Star from "./star.svg"
import Family from "./family.svg"
import ThumbsUp from "./thumbsUp.svg"
import Cake from "./cake.svg"
import Relationship from "./relationship.svg"
import Podium from "./podium.svg"
import Question from "./question.svg"
import Person from "./person.svg"
import Briefcase from "./briefcase.svg"

export const trophy = <Trophy width="38px"/>
export const alarmClock = <AlarmClock width="38px"/>
export const medal = <Medal width="38px"/>
export const information = <Information width="38px"/>
export const paperAirplane = <PaperAirplane width="38px"/>

export const resources = <Resources width="38px"/>
export const lightbulb = <Lightbulb width="38px"/>
export const chainLink = <ChainLink width="38px"/>
export const megaphone = <Megaphone width="38px"/>
export const umbrella = <Umbrella width="38px"/>
export const brainstorm = <Brainstorm width="38px"/>
export const environment = <Environment width="38px"/>
export const sun = <Sun width="38px"/>
export const heart = <Heart width="38px"/>
export const star = <Star width="38px"/>
export const family = <Family width="38px"/>
export const thumbsUp = <ThumbsUp width="38px"/>
export const cake = <Cake width="38px"/>
export const relationship = <Relationship width="38px"/>
export const podium = <Podium width="38px"/>
export const question = <Question width="38px"/>
export const person = <Person width="38px"/>
export const briefcase = <Briefcase width="38px"/>
