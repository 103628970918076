import React from "react"
import { SelectedBook, AddToBasket } from "../../../features/gardners"

import SideColumnLayout from "../../../layouts/sideColumnLayout"
import BackBar from "../../../features/ui/components/Backbar"

const Book = () => (
  <SideColumnLayout sideColumn={AddToBasket} bg="dark.7" banner={BackBar}>
    <SelectedBook />
  </SideColumnLayout>
)

export default Book
