import { Flex, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import ClickableIcon from "../../ui/components/ClickableIcon"

const AlertText = styled(Text)`
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes[3]}px;
  color: ${({ theme }) => theme.colors.dark[1]};
`

const pluralise = matType => (matType === "research" ? matType : `${matType}s`)

const BLText = ({ category, materialType }) => {
  if (category?.name && materialType) return `Business Library ${category?.name} ${pluralise(materialType)}`
  if (category?.name) return `Business Library ${category?.name}`
  if (materialType) return `Business Library ${pluralise(materialType)}`
  return null
}

const JOB_TYPES = {
  internships: "internship",
  fullTime: "Full-time",
  partTime: "Part-time",
  volunteer: "Volunteer",
  graduateSchemes: "Graduate Scheme",
  placements: "Placement"
}

const jobText = ({ jobRole, jobType, industry, salary, location, radius }) => {
  let result = jobRole ? `${jobRole} ` : "All "
  if (jobType) {
    result += ` ${JOB_TYPES[jobType] || ""} `
  }
  result += "jobs"
  if (industry) {
    result += `, ${industry.name}`
  }
  if (salary) {
    result += `, £${salary}+`
  }
  if (location) {
    result += ` in ${location.country.name}`
    if (radius) {
      result += ` within a ${radius.value} kilometer radius`
    }
  }
  return result
}

const eventText = ({ category, radius, location }) => {
  let result = `${category?.name} events`
  if (location) {
    result += ` in ${location.country.name}`
    if (radius) {
      result += ` within a ${radius.value} kilometer radius`
    }
  }
  return result
}

const criteria = {
  job: jobText,
  "Business Library": BLText,
  event: eventText
}

const SuccessBox = ({ alert, successToggle, type }) => (
  <Flex bg="primary.5" flexDirection="row" py={2} px={3} borderRadius={4}>
    <Text color="dark.2" fontSize={3}>
      Thank you, your <AlertText>{alert.frequency} alert</AlertText> for{" "}
      <AlertText>{criteria[type](alert)}</AlertText> has been created.
    </Text>
    <ClickableIcon name="close" onClick={successToggle} />
  </Flex>
)

export default SuccessBox
