import { connect } from "react-redux"

import Nav from "../components/HubNav"
import { getSelectedCategory } from "../store/globalHub.selectors"

const mapState = state => ({
  selected: getSelectedCategory(state)
})

const HubNav = connect(mapState)(Nav)

export default HubNav
