import { Flex, MobileOnly, TabletUp, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"
import styled from "styled-components"

import CircleIcon from "./CircleIcon"
import SecondaryButton from "./SecondaryButton"

const reactStringReplace = require("react-string-replace")

const Paragraph = styled(Text)`
  margin-top: 20px;

  font-size: 18px;
  color: ${({ theme }) => theme.colors.primary[0]};
  font-weight: 500;
  margin-left: 16px;
`
const Title = styled(Text)`
  font-weight: 700;
  font-size: 32px;
  color: ${({ theme }) => theme.colors.primary[0]};
  margin-left: 10px;
  margin-top: 10px;
`
const ButtonText = styled(Text)`
  font-size: 16px;
  font-weight: 700;

  text-transform: none;
  color: ${({ theme, yellowText }) => (yellowText ? theme.colors.yellow[0] : theme.colors.quaternary[0])};
`

const LinkText = styled(Text)`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.primary[0]};
  font-weight: 700;
`

const filterTextLinks = (text, linkText, linkTo) => {
  if (text.includes(linkText)) {
    return reactStringReplace(text, linkText, (match, i) => (
      <Link to={`${getConfig().workL.app}/${linkTo}`} external newTab>
        <LinkText key={i}>{match}</LinkText>
      </Link>
    ))
  }
  if (text.includes("Premium Service")) {
    return reactStringReplace(text, "Premium Service", (match, i) => (
      <Link to={`${getConfig().workL.app}/premium`} external newTab>
        <LinkText
          key={i}
          onClick={() => {
            if (typeof window !== "undefined" && window.hj) {
              window.hj("tagRecording", ["[WorkL][Public][General] Get premium link clicked"])
            }
          }}
        >
          {match}
        </LinkText>
      </Link>
    ))
  }
  if (text.includes("Premium")) {
    return reactStringReplace(text, "Premium", (match, i) => (
      <Link to={`${getConfig().workL.app}/premium`} external newTab>
        <LinkText
          key={i}
          onClick={() => {
            if (typeof window !== "undefined" && window.hj) {
              window.hj("tagRecording", ["[WorkL][Public][General] Get premium link clicked"])
            }
          }}
        >
          {match}
        </LinkText>
      </Link>
    ))
  }
  if (text.includes("happiest workplaces")) {
    return reactStringReplace(text, "happiest workplaces", (match, i) => (
      <Link to={`${getConfig().workL.app}${getConfig().workL.paths.happiestWorkplaces}`} external newTab>
        <LinkText key={i}>{match}</LinkText>
      </Link>
    ))
  }
  return text
}

const SectionPoint = ({
  title,
  text1,
  text2,
  text3,
  icon,
  iconColor,
  buttons,
  linkText,
  linkTo,
  path,
  children,
  DisplayComponent,
  displayComponentLeft = false,
  ...props
}) => (
  <Flex flexDirection="column" {...props}>
    <Flex flexDirection="row">
      {displayComponentLeft && <TabletUp>{DisplayComponent}</TabletUp>}
      <Flex flexDirection="column">
        {icon && <CircleIcon name={icon} size={60} iconColor={iconColor} />}
        {title && <Title>{title}</Title>}{" "}
        <MobileOnly width="100%">
          <Flex justifyContent="center" alignContent="center" alignItems="center" width="100%">
            {DisplayComponent}
          </Flex>
        </MobileOnly>
        {text1 && <Paragraph>{filterTextLinks(text1, linkText, linkTo)}</Paragraph>}
        {text2 && <Paragraph>{filterTextLinks(text2, linkText, linkTo)}</Paragraph>}
        {text3 && <Paragraph>{filterTextLinks(text3, linkText, linkTo)}</Paragraph>}
      </Flex>
      {children && <Paragraph>{children}</Paragraph>}{" "}
      {!displayComponentLeft && <TabletUp>{DisplayComponent}</TabletUp>}
    </Flex>
    <Flex flexDirection={["column", "column", "row"]}>
      {buttons &&
        buttons.map(button => (
          <Link to={button.path} {...button.linkProps}>
            <SecondaryButton
              mt={[4, 5]}
              px={4}
              width="auto"
              bg={button.bg || "secondary.0"}
              leadingIcon={button.icon}
              color={button.iconColor}
              onClick={() => {
                if (button.onClick) {
                  button.onClick()
                }
              }}
              {...button.style}
            >
              <ButtonText yellowText={button.yellowText}>{button.text}</ButtonText>
            </SecondaryButton>
          </Link>
        ))}
    </Flex>
  </Flex>
)
export default SectionPoint
