import { Box } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"
import { ConfirmBanner } from "../features/account"
import SiteBanner from "../features/ui/components/SiteBanner"
import TestingSiteBanner from "../features/ui/components/TestingSiteBanner"
import AuthGuard from "../features/ui/containers/AuthGuard"
import MinifiedNav from "../features/ui/containers/MinifiedNav"

const MinifiedNavLayout = ({
  bg = "transparent",
  children,
  pb = 0,
  ...props
}) => {
  const { websiteInfoBanner } = getConfig()
  return (
    <AuthGuard>
      <Box as="main" minHeight="100vh" bg={bg} pb={pb} {...props}>
        <TestingSiteBanner />
        {websiteInfoBanner && (
          <SiteBanner text={websiteInfoBanner} disableClose icon="warning" />
        )}
        <MinifiedNav />
        <ConfirmBanner />
        {children}
      </Box>
    </AuthGuard>
  )
}

export default MinifiedNavLayout
