import { Flex, Icon, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import DynamicState from "../../components/DynamicState"
import WidgetCard from "../../components/WidgetCard"
import ContentPost from "./ContentPost"

const ScrollFlex = styled(Flex)`
  overflow-x: auto;
  overflow-y: hidden;
`

const layout = [
  {
    title: "Events",
    contentType: "events",
    key: "eventRef"
  },
  {
    title: "Articles",
    contentType: "articles",
    key: "materialRef"
  },
  {
    title: "Podcasts",
    contentType: "podcasts",
    key: "materialRef"
  },
  {
    title: "Research",
    contentType: "research",
    key: "materialRef"
  },
  {
    title: "Lectures",
    contentType: "lectures",
    key: "materialRef"
  }
]

const processBookmarks = bookmarks => {
  const uniqueBookmarkIds = []
  return layout.reduce((commulator, lay) => {
    const scropedCommulator = commulator

    scropedCommulator[lay.contentType] = bookmarks
      .map(bookmark => {
        const hasS = lay.contentType.slice(lay.contentType.length - 1) === "s"
        const type = hasS ? lay.contentType.slice(0, -1) : lay.contentType

        if (bookmark.refSubType === type && !uniqueBookmarkIds.includes(bookmark.refId)) {
          uniqueBookmarkIds.push(bookmark.refId)
          return bookmark
        }
        return null
      })
      .filter(bookmark => bookmark)
    return scropedCommulator
  }, {})
}

const SavedContentWidget = ({ id, bookmarks }) => {
  const content = processBookmarks(bookmarks)
  const contentSaved = () => {
    let isContent = false
    layout.forEach(el => {
      if (!isContent && content[el.contentType].length > 0) {
        isContent = true
      }
    })
    return isContent
  }

  return (
    <WidgetCard bg="light.0" width="100%" height="100%">
      <DynamicState
        id={id}
        title="Your Saved Content"
        name="savedContent"
        btnText="Resource Centre"
        linkTo="/business-library"
      >
        {contentSaved() ? (
          <Flex width="99%" flexDirection="column">
            {layout.map(el => {
              return (
                content[el.contentType].length > 0 && (
                  <Flex key={el.title} width="100%" flexDirection="column" mb={3}>
                    <Flex mb={2}>
                      <Text fontSize={4} color="dark.2">
                        {el.title}
                      </Text>
                    </Flex>
                    <Flex alignItems="center">
                      <ScrollFlex width="100%" py={1} flexDirection="row">
                        {content[el.contentType].map(
                          bookmark =>
                            bookmark.refTarget && (
                              <ContentPost
                                key={bookmark.refId}
                                type={el.contentType}
                                post={{
                                  [el.key]: bookmark.refTarget,
                                  user: bookmark.refTarget.user,
                                  publishedAt: bookmark.refTarget.publishedAt
                                }}
                              />
                            )
                        )}
                      </ScrollFlex>
                    </Flex>
                  </Flex>
                )
              )
            })}
          </Flex>
        ) : (
          <Flex my={2}>
            <Icon name="bookmarks" color="primary.0" mr={2} />
            <Text fontSize={4}>
              Start bookmarking content in from our Resource Centre. There are thousands of free resources to
              help you develop.
            </Text>
          </Flex>
        )}
      </DynamicState>
    </WidgetCard>
  )
}

export default SavedContentWidget
