/* eslint-disable import/no-cycle */
import React from "react"
import { useSelector } from "react-redux"

import { UiSelectors } from "../../ui"
import SignInForm from "../forms/signIn"

/**
 * The "Reset Password" dialog box.
 * @param {React.Component} siteLogo The brand logo to show within the dialog.
 * @param {string} title The title text to show in the Sign In dialog.
 * @param {string} subtitle Optional subtitle text to show in the Sign In dialog.
 * @param {React.Component} footer Optional footer component to show underneath the sign in form.
 * @param {string} redirectTo The location to redirect a user to after they submit the reset password form.
 * @param {string} cancelRoute The route to redirect a user to if they click "Cancel".
 * @param {string} domain The domain name to be assigned to the cookie when signing in. This is used for accounts
 * that might require authentication across multiple front-ends.
 */
export default function SignIn({ siteLogo, title, subtitle, footer, cancelRoute }) {
  const previousRoute = useSelector(UiSelectors.getPreviousRoute)

  return (
    <SignInForm
      siteLogo={siteLogo}
      footer={footer}
      title={title}
      subtitle={subtitle}
      redirectTo={previousRoute}
      cancelRoute={cancelRoute}
    />
  )
}
