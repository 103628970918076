import { connect } from "react-redux"

import { cvLibraryActions, cvLibrarySelectors } from ".."
import CVListComponent from "../components/CVList"

const mapState = state => ({
  isLoadingCVs: cvLibrarySelectors.getIsLoadingCVs(state)
})

const mapDispatch = dispatch => ({
  deleteCV: ({ id, isDefault, place }) => dispatch(cvLibraryActions.deleteCV({ id, isDefault, place })),
  setDefaultCV: ({ id, place }) => dispatch(cvLibraryActions.setDefaultCV({ id, place }))
})

const CVList = connect(mapState, mapDispatch)(CVListComponent)

export default CVList
