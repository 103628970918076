import { Button, Card, Paragraph, Text } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React, { useState } from "react"

import PrimaryButton from "../../../ui/components/PrimaryButton"

const CATEGORIES = [
  {
    name: "podcast",
    reference: "a podcast"
  },
  {
    name: "article",
    reference: "an article"
  },
  {
    name: "research",
    reference: "research"
  },
  {
    name: "lecture",
    reference: "a lecture"
  }
]

const PublishLinks = () => {
  const paths = usePaths()
  const router = useRouter()

  return CATEGORIES.map(category => (
    <Button
      key={category.name}
      width={1 / 1}
      variant="outline.primary.0"
      mb={3}
      elevation={0}
      onClick={() => {
        router.navigate(`${paths.globalHub.newPost}?type=${category.name}`)
      }}
    >
      <Text color="primary.0" fontWeight={700}>
        Publish {category.reference}
      </Text>
    </Button>
  ))
}

const PublishContent = ({ ...props }) => {
  const [showLinks, setShowLinks] = useState(false)

  return (
    <Card width={1 / 1} p={3} pb={showLinks ? 0 : 3} {...props}>
      <Text fontWeight={700} mb={3}>
        Publish content
      </Text>
      <Paragraph color="dark.2" fontSize={3} mb={3}>
        Upload content to the Resource Centre and a preview will appear here on the Global Hub. You can
        publish podcasts and articles, as well as research and lectures.
      </Paragraph>
      {showLinks ? (
        <PublishLinks />
      ) : (
        <PrimaryButton
          onClick={() => setShowLinks(true)}
          width={1 / 1}
          color="grey.0"
          text="Publish content"
        />
      )}
    </Card>
  )
}

export default PublishContent
