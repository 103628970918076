import { types } from "./ui.actions"

export const initialState = {
  previousRoute: "/",
  companyName: ""
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ROUTE:
      return { ...state, previousRoute: action.payload }


    default:
      return state
  }
}
