import React from "react"
import { useSelector } from "react-redux"

import { getIsIsolatedResultsLoading } from "../../surveyResults/store/surveyResults.selectors"
import SurveyWrapper from "../components/surveyWrapper"
import { SectionContextProvider } from "../contexts/sectionContext"
import SurveyGuard from "../guards/surveyGuard"
import BrandingWrapper from "../layouts/brandingWrapper"
import IsolatedWrapper from "../layouts/isolatedWrapper"
import { getData, getIsIsolated, getIsIsolatedLoading } from "../store/survey.selectors"

const SurveyContent = () => {
  const data = useSelector(getData)

  const isIsolated = useSelector(getIsIsolated)
  const isIsolatedLoading = useSelector(getIsIsolatedLoading)

  const shouldShowIsolatedContent = isIsolated || isIsolatedLoading

  const Wrapper = shouldShowIsolatedContent ? IsolatedWrapper : BrandingWrapper

  return (
    <SurveyGuard>
      <Wrapper>
        <SectionContextProvider data={data}>
          <SurveyWrapper />
        </SectionContextProvider>
      </Wrapper>
    </SurveyGuard>
  )
}

export default SurveyContent
