import { connect } from "react-redux"

import { yourProfileSelectors } from "../../yourProfile"
import Form from "../forms/createReplyForm"
import { reply } from "../store/globalHub.actions"
import { getIsCommenting } from "../store/globalHub.selectors"

const mapState = state => ({
  user: yourProfileSelectors.getProfile(state),
  isCommenting: getIsCommenting(state)
})

const mapDispatch = dispatch => ({
  onSubmit: newReply => dispatch(reply(newReply))
})

const CreateReply = connect(mapState, mapDispatch)(Form)

export default CreateReply
