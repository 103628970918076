import React, { useState } from "react"
import styled from "styled-components"
import { Button, Box } from "@engaging-tech/components"

const RadioButton = styled(Button)`
  text-transform: capitalize;
  font-weight: 400;
  justify-content: flex-start;
  padding: 0 ${({ theme }) => theme.space[3]}px;
  border-radius: 99px;
  background-color: ${({ theme, isSelected }) =>
    !isSelected && theme.colors.dark[6]};

  &:focus,
  &:active,
  &:hover {
    background-color: ${({ theme, isSelected }) =>
      isSelected && theme.colors.secondary[0]};
  }
`

const Radio = ({ values: selectInputs, onAnswer, initialValue }) => {
  const [selected, setSelected] = useState(
    (initialValue && initialValue.value[0]) || null
  )

  const isSelected = value => value === selected

  const handleSelect = value => {
    setSelected(value)
    onAnswer(value)
  }

  return (
    <Box mt={[3, 5]}>
      {selectInputs.map(val => (
        <RadioButton
          isSelected={isSelected(val.id)}
          variant={isSelected(val.id) ? "secondary.0" : "light.0"}
          onClick={() => handleSelect(val.id)}
          key={val.id}
          elevation={0}
          width={1 / 1}
          mb={2}
        >
          {val.value}
        </RadioButton>
      ))}
    </Box>
  )
}

export default Radio
