import { createSelector } from "reselect"

const productsState = state => state.products

export const getIsLoading = createSelector(
  productsState,
  state => state.isLoading
)

export const getProducts = createSelector(
  productsState,
  state => state.products
)

export const getStripeCheckoutIsLoading = createSelector(
  productsState,
  state => state.stripeCheckoutIsLoading
)

export const getStripeCheckoutHasLoaded = createSelector(
  productsState,
  state => state.stripeCheckoutHasLoaded
)

export const getStripeCheckoutUrl = createSelector(
  productsState,
  state => state.stripeCheckoutUrl
)
