import { Flex, Icon, Text } from "@engaging-tech/components"
import { useRouter } from "@engaging-tech/routing"
import React from "react"
import styled from "styled-components"

import PrimaryButton from "../../ui/components/PrimaryButton"
import SecondaryButton from "../../ui/components/SecondaryButton"
import OptionsBox from "../containers/OptionsBox"

const PremiumBanner = styled(Flex)`
  border-radius: 7px 7px 0 0;
`
const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`

const WidgetButton = ({ outline, onClick, text, link, ...props }) => {
  const router = useRouter()

  if (outline) {
    return (
      <SecondaryButton
        width="auto"
        height="30px"
        minWidth="max-content"
        variant="outline.primary.0"
        elevation={0}
        onClick={() => {
          if (onClick) {
            onClick()
          }
          router.navigate(link)
        }}
        text={text}
        mb={text ? [2, 0] : 0}
        {...props}
      />
    )
  }

  return (
    <PrimaryButton
      color="light.0"
      px={3}
      text={text}
      width="auto"
      height="30px"
      minWidth="max-content"
      onClick={() => {
        if (onClick) {
          onClick()
        }
        router.navigate(link)
      }}
      mb={[2, 0]}
      {...props}
    />
  )
}

const DynamicState = ({
  isPremium,
  title,
  subTitle,
  image,
  hasBanner,
  btnText,
  linkTo,
  onClick,
  outlineBtn,
  outlineBtn2,
  linkTo2,
  onClick2,
  btnText2,
  id,
  removeWidget,
  disableOverflowY = false,
  removable = false,
  premiumBannerHeightPx = 35,
  titleHeightPx = 25,
  subTitleHeightPx = 32,
  bgColor,
  btnHeightPx = 30,
  padding = 10,
  width = "100%",
  children,
  ...props
}) => {
  const calculateTopSectionHeightPx = () => {
    return (
      (hasBanner ? premiumBannerHeightPx : 0) +
      (subTitle ? subTitleHeightPx : 0) +
      titleHeightPx +
      padding * 2
    )
  }

  const calculateBottomSectionHeightPx = () => {
    return btnText || btnText2 ? btnHeightPx : 0
  }

  const calculateMiddleSectionHeight = () => {
    return `calc(100% - ${calculateTopSectionHeightPx()}px - ${calculateBottomSectionHeightPx()}px - ${
      padding * 2
    }px)`
  }

  return (
    <Flex
      justifyContent="space-between"
      flexDirection="column"
      height="100%"
      width={width}
      bg={image && bgColor}
      {...props}
    >
      <Flex
        className="top-section"
        justifyContent="space-between"
        flexDirection="column"
        width="100%"
        height={calculateTopSectionHeightPx()}
      >
        {hasBanner && (
          <PremiumBanner
            width="100%"
            py={2}
            bg="primary.0"
            height={premiumBannerHeightPx}
            justifyContent="center"
            alignItems="center"
          >
            <Flex justifyContent="center" alignItems="center">
              <Icon name="stars" color="#F7E754" mr={2} />
              <Text color="#F7E754" width="auto" fontWeight={600} textAlign="center" fontSize={4}>
                PREMIUM SERVICE
              </Text>
            </Flex>
          </PremiumBanner>
        )}
        <Flex
          flexDirection="column"
          m={padding}
          height={`calc(${subTitle ? subTitleHeightPx : 0}px + ${titleHeightPx}px)`}
        >
          <Text fontSize={4} fontWeight={600} mr={3} width="fit-content" height={titleHeightPx}>
            {title}
          </Text>
          {subTitle && (
            <Text fontSize={3} width="calc(100% - 40px)">
              {subTitle}
            </Text>
          )}
          <OptionsBox removable={removable} removeWidget={removeWidget} id={id} />
        </Flex>
      </Flex>

      <Flex
        className="middle-section"
        width="100%"
        height={calculateMiddleSectionHeight()}
        flexDirection="column"
        px={3}
        justifyContent="space-between"
        style={{ position: "relative" }}
      >
        {image && (
          <Flex width="100%" height="100%">
            <Image src={image} alt={`${title} image`} />
          </Flex>
        )}
        {children && (
          <Flex
            flexDirection="column"
            width="99%"
            height="100%"
            justifyContent="flex-start"
            style={{ overflowY: disableOverflowY ? "none" : "auto" }}
            mb={2}
            mt={2}
          >
            {children}
          </Flex>
        )}
      </Flex>
      <Flex
        className="bottom-section"
        height={calculateBottomSectionHeightPx()}
        width="100%"
        my={padding}
        px={padding}
      >
        {btnText && <WidgetButton link={linkTo} text={btnText} onClick={onClick} outline={outlineBtn} />}
        {btnText2 && (
          <WidgetButton link={linkTo2} text={btnText2} onClick={onClick2} outline={outlineBtn2} ml={2} />
        )}
      </Flex>
    </Flex>
  )
}

export default DynamicState
