import React from "react"
import { string, object, array } from "yup"

import { Field, Area } from "../../PublishForm/PublishInput"

import CategorySelect from "../../PublishForm/CategorySelect"
import PublishForm from "../../PublishForm/PublishForm"
import UploadContent from "../../PublishForm/UploadContent"

const initialValues = {
  title: "",
  summary: "",
  seriesId: "",
  categories: [],
  attachments: []
}

const validation = object().shape({
  title: string().required("A title for your lecture is required"),
  summary: string().required("A short summary of your lecture is required"),
  seriesId: string(),
  categories: array().of(string()),
  attachments: array().min(2, "A video and cover image are required")
})

const CreateForm = ({
  onSubmit,
  isLoading,
  hasCreated,
  renderSeriesCreator: CreateSeries
}) => (
  <PublishForm
    postType="lecture"
    initialValues={initialValues}
    validation={validation}
    onSubmit={onSubmit}
    isLoading={isLoading}
    hasCreated={hasCreated}
    render={({
      handleChange,
      handleBlur,
      handleSelect,
      handleCloseDropdown,
      setValues,
      setTouched,
      validateForm,
      values,
      touched,
      errors
    }) => {
      const handleAttachment = async attachments => {
        setValues({ ...values, attachments })
        await validateForm()
        if (attachments.length > 0) {
          setTouched({ ...touched, attachments: true })
        }
      }

      return (
        <>
          <Field
            onSelect={handleSelect}
            id="lecture-title"
            name="title"
            type="text"
            label="Title"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
            error={touched.title && errors.title}
          />
          <CreateSeries name="seriesId" onChange={handleChange} />
          <CategorySelect
            label="Categories"
            name="categories"
            helperText="Select categories for your lecture (max 3)"
            onChange={handleChange}
            closeDropdownTrigger={handleCloseDropdown}
          />
          <Area
            onSelect={handleSelect}
            height={[248, 124]}
            id="lecture-summary"
            name="summary"
            type="text"
            label="Summary"
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={250}
            helperText="Summary and key points covered in your lecture"
            value={values.summary}
            error={touched.summary && errors.summary}
          />
          <UploadContent
            type="video"
            helperTexts={[
              "Attach a video of your lecture and a cover image (image must be square).",
              "Please compress your files to below 80MB each before uploading."
            ]}
            onSelect={handleAttachment}
            error={touched.attachments && errors.attachments}
          />
        </>
      )
    }}
  />
)

export default CreateForm
