import { Flex, Spinner, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import WidgetCard from "../../components/WidgetCard"
import DynamicState from "../../containers/DynamicState"

const Image = styled.img`
  width: 40px;
  border-radius: 30px;
  margin-right: 15px;
`

const placeholderImage = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/career-developer/profile-image-placeholder.svg`)
const searching = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/career-developer/widget-images/mentor-matcher-in-progress-no-bg.svg`)
const searchingPremium = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/career-developer/widget-images/mentor-matcher-in-progress-premium-no-bg.svg`)
const notTurnedOnPremium = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/career-developer/widget-images/mentor-matcher-premium-no-bg.svg`)
const notTurnedOn = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/career-developer/widget-images/mentor-matcher-free-no-bg.svg`)

const HasMatches = ({ matches, updateServices, id }) => (
  <DynamicState
    id={id}
    removable
    outlineBtn2
    title="Mentor Matcher"
    btnText="VIEW ALL MATCHES"
    linkTo="/develop-career/mentor-matcher"
    btnText2="TURN OFF Matcher"
    onClick2={() => updateServices({ mentorMatcher: "OFF" })}
  >
    <Flex mt={2} style={{ overflowY: "auto" }} height="100%" flexDirection="column" mb="70px">
      {matches.slice(0, 5).map((match, index) => (
        <Flex
          bg="dark.7"
          p={2}
          my={1}
          height="auto"
          // eslint-disable-next-line react/no-array-index-key
          key={`${index}-match-${match.user.firstName}`}
        >
          <Image
            alt="mentor image"
            src={match.user.picture.small ? match.user.picture.small : placeholderImage}
          />
          <Flex flexDirection="column">
            <Text fontSize={4}>{`${match.user.firstName} ${match.user.lastName}`}</Text>
            <Text fontSize={3}>{match.user.jobTitle}</Text>
          </Flex>
          <Text fontSize={5} ml={3} mt={2}>
            <strong>{Math.round(match.score * 100)}%</strong> Match
          </Text>
        </Flex>
      ))}
    </Flex>
  </DynamicState>
)

const NoMatchesYet = ({ updateServices, isPremium, id }) => (
  <DynamicState
    id={id}
    removable
    outlineBtn
    title="Mentor Matcher"
    bgColor="secondary.5"
    image={isPremium ? searchingPremium : searching}
    subTitle="Our Mentor Matching service is taking your preferences and requirements to match you with a Mentor or Mentee, depending on your preferences. Please wait."
    btnText="Cancel mentor Matcher"
    onClick={() => updateServices({ mentorMatcher: "OFF" })}
  />
)

const TurnOnView = ({ updateServices, id, isPremium }) => (
  <DynamicState
    id={id}
    removable
    title="Mentor Service"
    subTitle="Turn on Mentor Service to help you navigate finding a job and get advice about how to be happier at work.  
    "
    image={isPremium ? notTurnedOnPremium : notTurnedOn}
    bgColor="secondary.5"
    btnText="Turn on mentor service"
    onClick={() => updateServices({ mentorMatcher: "ON" })}
  />
)

const DetermineView = ({ matches, services, isPremium, updateServices, id }) => {
  if (services?.mentorMatcher === "ON" && matches.length > 0)
    return <HasMatches matches={matches} updateServices={updateServices} id={id} />

  if (services?.mentorMatcher === "ON" && matches.length === 0)
    return <NoMatchesYet updateServices={updateServices} id={id} isPremium={isPremium} />

  return <TurnOnView updateServices={updateServices} id={id} isPremium={isPremium} />
}

const MentorMatcherWidget = ({ matches, updateServices, services, isLoading, isPremium, id }) => (
  <WidgetCard width="100%" bg="light.0" height="100%">
    {isLoading ? (
      <Spinner py={3} color="primary.0" width="100%" height="100%" />
    ) : (
      <DetermineView
        id={id}
        matches={matches}
        updateServices={updateServices}
        services={services}
        isPremium={isPremium}
      />
    )}
  </WidgetCard>
)

export default MentorMatcherWidget
