import { Button, Card, Text } from "@engaging-tech/components"
import React from "react"

const FurtherReadingCard = ({ furtherReading, stageIndex }) => {
  return (
    <>
      {furtherReading.furtherReading.map(widget => (
        <Card
          width={1 / 1}
          alignItems="center"
          py={5}
          px={5}
          marginTop={4}
          marginBottom={2}
          stageIndex={stageIndex}
        >
          <Text
            textAlign="center"
            fontSize={3}
            width={1 / 1}
            fontWeight={500}
            color="dark.2"
            lineHeight="1.4"
          >
            {widget.text}
          </Text>

          <Button
            variant="primary.0"
            borderRadius={20}
            marginTop={4}
            p={2}
            onClick={() => {
              window.open(widget.url, "_blank", "noopener")
            }}
          >
            {widget.title}
          </Button>
        </Card>
      ))}
    </>
  )
}

export default FurtherReadingCard
