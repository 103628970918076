import { Button, Flex, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React from "react"
import styled from "styled-components"

const Wrapper = styled(Flex)`
  flex-wrap: wrap;
  -webkit-overflow-scrolling: touch;
  padding-top: ${({ pt }) => pt || "35px"};
`

const ChipWrapper = styled(Button)`
  text-transform: none;
  padding: 0 12px;
  flex-shrink: 0;
  text-transform: capitalize;
  margin-top: 3px;
  margin-bottom: 3px;

  &:focus {
    outline: 0;
  }
`

const Chip = ({
  text,
  isActive,
  onClick,
  chipBgActive,
  textWeight,
  textColor,
  chipBgNotActive,
  chipProps
}) => (
  <ChipWrapper
    onClick={onClick}
    borderRadius={99}
    height={32}
    elevation={0}
    mr={1}
    ml={1}
    bg={isActive ? chipBgActive : chipBgNotActive}
    {...chipProps}
  >
    <Text
      fontSize={3}
      fontWeight={isActive ? textWeight || 700 : textWeight || 400}
      color={isActive ? textColor || "secondary.0" : textColor || "dark.2"}
    >
      {text}
    </Text>
  </ChipWrapper>
)

const ChipBar = ({
  chips,
  bg = "tertiary.0",
  chipBg,
  textColor,
  textWeight,
  chipBgActive = "primary.0",
  chipBgNotActive = "light.0",
  overflow = "true",
  chipProps,
  ...props
}) => (
  <Wrapper alignItems="center" pl={[3, 3, 4]} pt={3} bg={bg} overflow={overflow ? "auto" : "none"} {...props}>
    {chips.map((chip, index) => (
      <Link
        // eslint-disable-next-line react/no-array-index-key
        key={`${index}-${chip.name}`}
        to={chip.linkTo}
        onClick={chip.onClick}
        queryParameters={chip.queryParameters}
      >
        <Chip
          chipBgActive={chipBgActive}
          chipBgNotActive={chipBgNotActive}
          textColor={textColor}
          textWeight={textWeight}
          isActive={chip.isActive}
          text={chip.name}
          chipProps={chipProps}
        />
      </Link>
    ))}
  </Wrapper>
)

export default ChipBar
