import { Button, Box } from "@engaging-tech/components"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { isChoiceVisible } from "../../../../utils/logic"

const RadioButton = styled(Button)`
  text-transform: none;
  font-weight: 400;
  justify-content: flex-start;
  padding: 0 ${({ theme }) => theme.space[3]}px;
  border-radius: 99px;
  background-color: ${({ theme, isSelected }) => !isSelected && theme.colors.dark[6]};
  white-space: pre-line;
  min-height: 32px;
  height: auto;

  &:focus,
  &:active,
  &:hover {
    background-color: ${({ theme, isSelected }) => isSelected && theme.colors.secondary[0]};
  }
`

const Radio = ({ values: selectInputs, onAnswer, initialValue, allAnswers, choiceLogics }) => {
  const [selected, setSelected] = useState(null)

  const isSelected = value => value === selected

  const handleSelect = value => {
    setSelected(value)
    onAnswer(value)
  }

  useEffect(() => {
    if (initialValue && initialValue?.value && initialValue?.value.length > 0 && initialValue.value[0])
      setSelected(initialValue?.value[0])
  }, [initialValue])

  return (
    <Box mt={[3, 5]}>
      {selectInputs.map(val => {
        if (!isChoiceVisible(val, allAnswers, choiceLogics)) {
          return null
        }
        return (
          <RadioButton
            isSelected={isSelected(val.id)}
            variant={isSelected(val.id) ? "secondary.0" : "light.0"}
            onClick={() => handleSelect(val.id)}
            key={val.id}
            elevation={0}
            width={1 / 1}
            mb={2}
          >
            {val.value}
          </RadioButton>
        )
      })}
    </Box>
  )
}

export default Radio
