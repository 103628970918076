import { Flex } from "@engaging-tech/components"
import React from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"

import { businessProfilePageSelectors } from "../.."
import { ReactComponent as FacebookLogoBaseIcon } from "../../../../assets/icons/logos/facebook-logo-icon.svg"
import { ReactComponent as InstagramLogoBaseIcon } from "../../../../assets/icons/logos/instagram-logo.icon.svg"
import { ReactComponent as LinkedInLogoBaseIcon } from "../../../../assets/icons/logos/linkedin-logo-icon.svg"
import { ReactComponent as XBaseIcon } from "../../../../assets/icons/logos/x-logo-icon.svg"

const FacebookLogoIcon = styled(FacebookLogoBaseIcon)`
  width: 32px;
  height: 32px;
`

const InstagramLogoIcon = styled(InstagramLogoBaseIcon)`
  width: 32px;
  height: 32px;
`

const LinkedInIcon = styled(LinkedInLogoBaseIcon)`
  width: 32px;
  height: 32px;
`

const XIcon = styled(XBaseIcon)`
  width: 32px;
  height: 32px;
`

const socialLinksList = [
  {
    id: "twitter",
    Icon: XIcon
  },
  {
    id: "facebook",
    Icon: FacebookLogoIcon
  },
  {
    id: "linkedIn",
    Icon: LinkedInIcon
  },
  {
    id: "instagram",
    Icon: InstagramLogoIcon
  }
]

const SocialLinkContainer = styled(Flex)`
  margin-top: 36px;
  width: 100%;
  justify-content: center;
`

const SocialLink = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;

  margin-right: 12px;
`

export default function SocialLinks() {
  const bipInfo = useSelector(businessProfilePageSelectors.getBipInfo)

  return (
    <SocialLinkContainer>
      {socialLinksList.map(item => {
        const link = bipInfo?.profile?.summary?.social[item.id]

        if (link) {
          return (
            <SocialLink
              key={item.id}
              type="button"
              onClick={() => {
                window.open(link, "_blank")
              }}
            >
              <item.Icon />
            </SocialLink>
          )
        }

        return null
      })}
    </SocialLinkContainer>
  )
}
