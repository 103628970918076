import React from "react"
import { Button, Text, Flex, Icon } from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"

import PremiumDialog from "./PremiumDialog"
import SolidLayout from "../../../layouts/SolidLayout"
import SecondaryButton from "../../ui/components/SecondaryButton"
import premiumFeaturesList from "../lib/PremiumFeaturesList"

const FeatureList = () =>
  premiumFeaturesList?.map(feature => (
    <Flex key={feature.text} alignItems="center" mb={3} flexDirection="column">
      <Flex flexDirection="row" alignItems="center" mb={2}>
        <Icon name="done_outline" mr={2} color="primary.0" size={20} />

        {feature.title && (
          <Text color="dark.1" fontWeight="500" fontSize={3}>
            {feature.title}
          </Text>
        )}
      </Flex>
      {feature.text && (
        <Text color="dark.2" fontSize={2} fontWeight={300}>
          {feature.text}
        </Text>
      )}
    </Flex>
  ))

const PremiumFeatures = () => {
  const paths = usePaths()
  return (
    <SolidLayout>
      <PremiumDialog
        title="Get access to these exclusive features with Premium"
        my={3}
        headerBg="tertiary.0"
      >
        <Text
          color="dark.2"
          fontSize={3}
          display="block"
          mb={3}
          fontWeight={700}
        >
          Enjoy a free 14 day trial then use our full range of Premium services
          for £4.99 a month
        </Text>
        <Text
          fontWeight="500"
          fontSize={3}
          display="block"
          mb={4}
          color="dark.2"
        >
          This price includes VAT
        </Text>
        <FeatureList />
        <Flex width={1 / 1} justifyContent="space-between">
          <Link to={paths.home}>
            <Button variant="text.noBg.primary.0" elevation={0}>
              Cancel
            </Button>
          </Link>
          <Link to={paths.getPremium.subscribeToPremium}>
            <SecondaryButton text="Get premium" ml={4} px={4} elevation={0} />
          </Link>
        </Flex>
      </PremiumDialog>
    </SolidLayout>
  )
}

export default PremiumFeatures
