import { useLocation } from "@engaging-tech/routing"
import { useEffect } from "react"
import { connect } from "react-redux"
import { setRoute } from "../store/ui.actions"

const excludedRoutes = ["/account", "/premium", "/onboarding"]

const Tracker = ({ onUpdateRoute }) => {
  const { pathname, search } = useLocation()

  useEffect(() => {
    const isExcluded = () =>
      !!excludedRoutes.find(route => pathname.includes(route))

    if (!isExcluded(pathname)) {
      onUpdateRoute(search ? `${pathname}${search}` : pathname)
    }
  }, [pathname, onUpdateRoute, search])

  return null
}

const mapDispatch = dispatch => ({
  onUpdateRoute: route => dispatch(setRoute(route))
})

const UrlTracker = connect(null, mapDispatch)(Tracker)

export default UrlTracker
