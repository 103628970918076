import { DatePicker, Flex, Text } from "@engaging-tech/components"
import { useField } from "formik"
import React from "react"

import InputLoadError from "./InputLoadError"

const DateInput = ({
  field,
  meta,
  label,
  helperText,
  height,
  inputLabel,
  onChange,
  errorTextOverride,
  width = "100%",
  setFieldTouched,
  setFieldValue,
  ...other
}) => {
  if (!("value" in field)) {
    return <InputLoadError />
  }

  return (
    <Flex width={1 / 1} flexDirection="column" {...other} id={field.name} type="input">
      {label && <Text mb={3}>{label}</Text>}
      <DatePicker
        width={width}
        id={field.name}
        name={field.name}
        type="date"
        label={inputLabel}
        onChange={onChange || field.onChange}
        onBlur={field.onBlur}
        value={field.value ? new Date(field.value) : ""}
        error={meta.error && meta.touched ? errorTextOverride || meta.error : ""}
        helperText={helperText}
        height={height}
        format="dd/MM/yyyy"
        onClick={() => setFieldTouched(field.name, true, true)}
        handleChange={val => setFieldValue(field.name, val)}
      />
    </Flex>
  )
}

const DateInputHandler = ({
  label,
  inputLabel,
  name,
  height,
  type,
  helperText,
  onChange,
  errorTextOverride,
  ...other
}) => {
  const [field, meta] = useField({ name })

  return (
    <DateInput
      label={label}
      inputLabel={inputLabel}
      type={type}
      onChange={onChange}
      field={field}
      height={height}
      errorTextOverride={errorTextOverride}
      helperText={helperText}
      meta={meta}
      {...other}
    />
  )
}

export default DateInputHandler
