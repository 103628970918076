import { useLocation } from "@engaging-tech/routing"
import useRouter from "@engaging-tech/routing/dist/hooks/useRouter"
import React from "react"
import Providers from "../saml/lib/providers"

const SamlEntryPoint = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const router = useRouter()

  const provider = params.get("provider")

  const Component = Providers[provider]
  if (!Component) {
    router.redirect("/")
    return null
  }
  return <Component />
}

export default SamlEntryPoint
