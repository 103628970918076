import React from "react"
import styled from "styled-components"
import { Link as BaseLink } from "@engaging-tech/routing"

import { libraryPostTypes } from "../../../userGeneratedContent/lib/postTypes"

import pluralise from "../../../userGeneratedContent/lib/pluralise"

import FeaturedText from "./FeaturedText"
import FeaturedMedia from "./FeaturedMedia"

const Link = styled(BaseLink)`
  display: flex;
  flex-shrink: 0;
  width: 184px;
  margin-right: ${({ theme }) => theme.space[3]}px;
  margin-top: ${({ theme }) => theme.space[3]}px;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[1]}px) {
    width: inherit;
    min-width: 184px;
    max-width: 18.5%;
  }
`

const isMedia = type =>
  type === libraryPostTypes.podcasts || type === libraryPostTypes.lectures

const generateCards = ({ posts, type, render: Component }) =>
  posts.map(post => (
    <Link
      key={post.id}
      to={`/business-library/browse/${pluralise(type)}/${post.id}`}
    >
      <Component content={post} />
    </Link>
  ))

const FeaturedCards = ({ posts, type }) =>
  generateCards({
    posts,
    type,
    render: isMedia(type) ? FeaturedMedia : FeaturedText
  })

export default FeaturedCards
