import React from "react"
import { Card } from "@engaging-tech/components"

const ResultCard = ({
  children,
  width = 1 / 1,
  height = "auto",
  minHeight = "auto",
  mt = [3, 5],
  p = [3, 3, 4],
  justifyContent = "flex-start",
  alignItems = "flex-start",
  ...props
}) => {
  return (
    <Card
      width={width}
      justifyContent={justifyContent}
      flexDirection={["column", "row"]}
      elevation={2}
      mt={mt}
      alignItems={alignItems}
      p={p}
      height={height}
      minHeight={minHeight}
      {...props}
    >
      {children}
    </Card>
  )
}

export default ResultCard
