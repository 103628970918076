import React from "react"
import { Box, Text } from "@engaging-tech/components"

const Disclaimer = ({ postType }) => (
  <Box mb={3}>
    <Text fontSize={3} color="dark.2">
      Your {postType} will appear in the Business Library and a preview will be
      shared to our professional community via the Global Hub
    </Text>
  </Box>
)

Disclaimer.defaultProps = {
  postType: "content"
}

export default Disclaimer
