import COMPANIES_TO_LOAD from "../lib/companiesToLoad"
import { types } from "./rankings.actions"

export const initialState = {
  isCompaniesLoading: false,
  isMoreCompaniesLoading: false,
  companies: [],
  nextToken: "",

  isLoading: false,
  rankings: {
    company: []
  },
  loadFrom: 0,
  isLoadingMore: false,
  hasLoadedAll: false
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_COMPANIES:
      return { ...state, isCompaniesLoading: true }
    case types.LOAD_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.payload.body,
        nextToken: action.payload.nextToken,
        isCompaniesLoading: false
      }
    case types.LOAD_COMPANIES_FAIL:
      return { ...state, isCompaniesLoading: false }
    case types.LOAD_COMPANIES_LOAD_MORE:
      return { ...state, isMoreCompaniesLoading: true }
    case types.LOAD_COMPANIES_LOAD_MORE_SUCCESS:
      return {
        ...state,
        companies: state.companies.concat(action.payload?.body || []),
        nextToken: action.payload.nextToken,
        isMoreCompaniesLoading: false
      }
    case types.LOAD_COMPANIES_LOAD_MORE_FAIL:
      return { ...state, isMoreCompaniesLoading: false }

    case types.LOAD_COMPANY_RANKINGS:
      return { ...state, isLoading: true }
    case types.LOAD_COMPANY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasLoadedAll: false,
        rankings: {
          ...state.rankings,
          company: action.payload
        },
        loadFrom: action.payload.length
      }
    case types.LOAD_FAIL:
      return { ...state, isLoading: false, isLoadingMore: false }
    case types.LOAD_MORE:
      return { ...state, isLoadingMore: true }
    case types.LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadFrom: state.loadFrom + action.payload.length,
        isLoadingMore: false,
        rankings: {
          ...state.rankings,
          company: [...state.rankings.company, ...action.payload]
        },
        hasLoadedAll: action.payload.length < COMPANIES_TO_LOAD
      }

    case types.GET_SURVEY_COMPANIES:
      return { ...state, isLoadingSurveyCompanies: true }
    case types.GET_SURVEY_COMPANIES_SUCCESS:
      return {
        ...state,
        isLoadingSurveyCompanies: false,
        surveyCompanies: action.payload
      }
    case types.GET_SURVEY_COMPANIES_FAIL:
      return { ...state, isLoadingSurveyCompanies: false, surveyCompanies: [] }

    default:
      return state
  }
}
