import React from "react"
import styled from "styled-components"
import { Flex, Text, Box } from "@engaging-tech/components"

import CategoryCard from "./CategoryCard"

const Grid = styled(Flex)`
  flex-flow: row wrap;
  align-items: stretch;

  & > div {
    width: ${({ theme }) =>
      `calc(100% - ${theme.space[1]}px - ${theme.space[1]}px)`};
    margin-left: ${({ theme }) => `${theme.space[1]}px`};
    margin-right: ${({ theme }) => `${theme.space[1]}px`};

    @media (min-width: ${({ theme }) => theme.breakpoints[0]}px) {
      width: ${({ theme }) =>
        `calc(50% - ${theme.space[2]}px - ${theme.space[2]}px)`};
      margin-left: ${({ theme }) => `${theme.space[2]}px`};
      margin-right: ${({ theme }) => `${theme.space[2]}px`};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints[1]}px) {
      width: ${({ theme }) =>
        `calc(33% - ${theme.space[2]}px - ${theme.space[2]}px)`};
    }
  }
`

const CategoryGrid = ({ title, routes, icon }) => (
  <Box width={1 / 1}>
    <Flex>
      <Text color="dark.1" fontWeight="500">
        {title}
      </Text>
    </Flex>
    <Grid mt={3} mb={2}>
      {routes.map(route => (
        <CategoryCard key={route.path} icon={icon} {...route} />
      ))}
    </Grid>
  </Box>
)

export default CategoryGrid
