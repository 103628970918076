import { Text, ToggleLogic, Flex, Icon } from "@engaging-tech/components"
import { AnimatePresence } from "framer-motion"
import React from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { getIsPremium } from "../../../yourProfile/store/yourProfile.selectors"
import ManageAlertsModal from "../containers/ManageAlertsModal"
import PremiumModal from "../../../Notifications/components/PremiumModal"
import PrimaryButton from "../../../ui/components/PrimaryButton"
import WidgetCard from "../../components/WidgetCard"

const PremiumBanner = styled(Flex)`
  border-radius: 7px 7px 0px 0px;
  width: 100%;
  height: 38px;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
`

const ManageAlertsWidget = () => {
  const isPremium = useSelector(getIsPremium)

  return (
    <WidgetCard
      elevation={2}
      width="100%"
      height="100%"
      alignItems="center"
      bg="light.0"
      justifyContent="space-between"
    >
      {!isPremium && (
        <PremiumBanner bg="primary.0">
          <Icon name="stars" color="yellow.0" />
          <Text
            fontSize={14}
            color="yellow.0"
            ml={2}
            fontWeight={700}
            width="auto"
          >
            PREMIUM SERVICE
          </Text>
        </PremiumBanner>
      )}

      <Flex
        p={3}
        width={1 / 1}
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        {isPremium && (
          <Text
            fontSize={4}
            fontWeight={600}
            mb={["2px", "2px", "2px"]}
            width={1 / 1}
          >
            Manage Alerts
          </Text>
        )}

        <Text
          color="dark.2"
          fontSize={3}
          mb={[2, 2, 2]}
          width="auto"
          maxHeight="54px"
        >
          Set up alerts to keep track of things important to you like Job
          Matcher or Event Notifications
        </Text>
        <ToggleLogic>
          {({ on, toggle }) => (
            <>
              <PrimaryButton
                color="light.0"
                px={3}
                mt={[10, 20, 20]}
                onClick={toggle}
                text="SET UP ALERTS"
                width={150}
              />
              <AnimatePresence>
                {on &&
                  (!isPremium ? (
                    <PremiumModal toggle={toggle} />
                  ) : (
                    <ManageAlertsModal toggle={toggle} />
                  ))}
              </AnimatePresence>
            </>
          )}
        </ToggleLogic>
      </Flex>
    </WidgetCard>
  )
}

export default ManageAlertsWidget
