import { Flex } from "@engaging-tech/components"
import React from "react"
import * as Yup from "yup"

import FieldInput from "../../../formFlowWizard/components/formElements/FieldInput"

const icon = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/heart.png`)

const info = {
  icon,
  title: "What is your desired role?",
  submitData: false
}

const validationSchema = Yup.object({
  desiredJobRoles: Yup.array().of(Yup.string()).min(1)
})

const DesiredRoleTemplate = ({ formik }) => {
  const combiner = event => {
    const previousArray = formik.values.desiredJobRoles
    let newArray = []
    switch (event.target.id) {
      case "desiredJobRoles[0]":
        newArray = [event.target.value, previousArray[1], previousArray[2]].filter(Boolean)
        break
      case "desiredJobRoles[1]":
        newArray = [previousArray[0], event.target.value, previousArray[2]].filter(Boolean)
        break
      case "desiredJobRoles[2]":
        newArray = [previousArray[0], previousArray[1], event.target.value].filter(Boolean)
        break
      default:
        break
    }
    formik.setValues(
      {
        ...formik.values,
        desiredJobRoles: newArray
      },
      true
    )
  }

  return (
    <Flex width={1 / 1} flexDirection="column">
      {formik.values.desiredJobRoles.length >= 0 && (
        <FieldInput
          name="desiredJobRoles[0]"
          label="Please enter up to 3 roles you might be interested in:"
          inputLabel="Enter a role"
          type="text"
          onChange={combiner}
          errorTextOverride="Minimum of one job role must be entered"
        />
      )}
      {formik.values.desiredJobRoles.length >= 1 && (
        <FieldInput
          name="desiredJobRoles[1]"
          inputLabel="Enter a role"
          type="text"
          onChange={combiner}
          errorTextOverride="Minimum of two job roles must be entered"
        />
      )}
      {formik.values.desiredJobRoles.length >= 2 && (
        <FieldInput name="desiredJobRoles[2]" inputLabel="Enter a role" type="text" onChange={combiner} />
      )}
    </Flex>
  )
}

export default { component: DesiredRoleTemplate, validationSchema, info }
