import React from "react"

import MagicLinkForm from "../forms/magicLink"

/**
 * The "SendMagicLink" dialog box.
 * @param {React.Component} siteLogo The brand logo to show within the dialog.
 * @param {string} cancelRoute The route to redirect a user to if they click "Cancel".
 */
export default function SendMagicLink({ siteLogo, cancelRoute, redirectTo }) {
  return <MagicLinkForm siteLogo={siteLogo} cancelRoute={cancelRoute} redirectTo={redirectTo} />
}
