import { Flex } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"
import styled from "styled-components"

import SiteBanner from "../features/ui/components/SiteBanner"
import TestingSiteBanner from "../features/ui/components/TestingSiteBanner"
import gradientCss from "../helpers/gradientCss"

const GradientBox = styled(Flex)`
  ${({ theme }) => gradientCss(theme)}
`

const GradientLayout = ({ children, banner: Banner, ...props }) => {
  const { websiteInfoBanner } = getConfig()

  return (
    <>
      <TestingSiteBanner />
      {websiteInfoBanner && <SiteBanner text={websiteInfoBanner} disableClose icon="warning" />}
      {Banner && <Banner />}
      <GradientBox as="main" flexDirection="column" minHeight="100vh">
        <Flex width={1 / 1} flex="1" alignItems="center" justifyContent="center" {...props}>
          {children}
        </Flex>
      </GradientBox>
    </>
  )
}

export default GradientLayout
