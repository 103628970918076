import styled from "styled-components"
import { MotionBox } from "@engaging-tech/components"

const SingleKnobSliderTrack = styled(MotionBox)`
  width: ${({ trackWidth }) => trackWidth}px;
  height: ${({ trackHeight }) => trackHeight}px;
  /* This gradient contains a calculation for the slider track. */
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.secondary[0]} 0%,
    ${({ theme }) => theme.colors.secondary[0]}
      ${({ knob1Position, trackWidth }) =>
        Number(100 * (knob1Position / trackWidth)).toFixed()}%,
    ${({ theme }) => theme.colors.secondary[2]}
      ${({ knob1Position, trackWidth }) =>
        Number(100 * (knob1Position / trackWidth)).toFixed()}%
  );
  border-radius: ${({ trackWidth }) => trackWidth / 2}px;
  position: relative;
`
export default SingleKnobSliderTrack
