import React from "react"
import { H1, Text, Flex } from "@engaging-tech/components"

const ComparisonHeader = () => (
  <Flex mb={3} textAlign="Center" flexDirection="column" alignItems="center">
    <H1
      fontWeight="600"
      fontSize={6}
      fontFamily="Tiempos"
      mb={1}
      width="auto"
      color="primary.0"
    >
      Build Your Career with WorkL Premium
    </H1>
    <Text p={3} fontsize={3} textAlign="center" color="primary.0">
      Become a WorkL Premium member to get ahead at work and have a happier
      working life.
    </Text>
  </Flex>
)

export default ComparisonHeader
