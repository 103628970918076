import React from "react"
import { Flex, Text } from "@engaging-tech/components"

import categories from "../../../lib/categories"

const CategoryChip = ({ id }) => (
  <Flex
    mr={2}
    borderRadius={99}
    bg="dark.7"
    width="auto"
    py={2}
    px={3}
    mb={[2, 0, 0]}
  >
    <Text fontSize={3}>
      {categories.find(category => category.id === id).name}
    </Text>
  </Flex>
)

const CategoryChips = ({ ids }) => (
  <Flex mt={3} flexWrap="wrap">
    {ids.map(id => (
      <CategoryChip key={id} id={id} />
    ))}
  </Flex>
)

export default CategoryChips
