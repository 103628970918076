import { Box, Flex, MobileOnly, TabletUp, Text } from "@engaging-tech/components"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import SurveyIcon from "../../ui/components/SurveyIcon/SurveyIcon"
import ProgressBar from "../containers/ProgressBar"
import { useQuestionContext } from "../lib/QuestionContext"
import Answer from "./Answer/Answer"
import SurveyCard from "./SurveyCard"

const Question = ({
  currentQuestion,
  sectionTitle,
  question,
  questionCount,
  onNext,
  onPrevious,
  previousAnswer,
  onSubmit,
  questionId
}) => {
  const { isLastSection } = useQuestionContext()

  const [answer, setAnswer] = useState(previousAnswer)
  const { t } = useTranslation(["happiness_survey"])

  const { title, description, type, fields, values, min, max, label, icon, required } = question

  const handleAnswer = value =>
    setAnswer({
      id: questionId,
      value: type === "short-text" ? value : [value]
    })

  const handleSubmit = () => {
    if (answer) {
      onSubmit(answer)
    }

    onNext()
  }

  return (
    <SurveyCard
      title={sectionTitle}
      isLast={currentQuestion === questionCount && isLastSection}
      onNext={handleSubmit}
      onPrevious={onPrevious}
      type={type}
      isValid={!required || !!answer}
    >
      <Flex>
        <TabletUp>
          <SurveyIcon name={icon} mr={3} />
        </TabletUp>
        <Box>
          <MobileOnly>
            <Flex mb={3} alignItems="flex-end">
              <SurveyIcon name={icon} mr={3} />
              <ProgressBar id={questionId} />
            </Flex>
          </MobileOnly>
          <TabletUp>
            <ProgressBar id={questionId} />
          </TabletUp>
          <Text fontWeight="500" color="dark.2" fontSize={[3, 4]}>
            {title}
          </Text>
          {!required && (
            <Text fontSize={3} color="dark.2">
              {" "}
              ({t("ui.optional", "")})
            </Text>
          )}
          <Answer
            type={type}
            fields={fields}
            values={values}
            description={description}
            min={min}
            max={max}
            label={label}
            onAnswer={value => handleAnswer(value)}
            initialValue={answer}
          />
        </Box>
      </Flex>
    </SurveyCard>
  )
}

export default Question
