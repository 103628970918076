import React from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"

import ReportItem from "../../components/ReportItem"
import ResultCard from "../../components/ResultCard"
import PlanBox from "../containers/PlanBox"
import actionPlansList from "../lib/actionPlans"
import Wrapper from "./Wrapper"

const ActionPlans = ({ furtherReading = true, showShadow = true }) => {
  const { t } = useTranslation(["results"])

  return (
    <ResultCard style={showShadow ? undefined : { boxShadow: "none" }}>
      <ReportItem heading={t("action_plan.heading", "")} subHeading={t("action_plan.sub_heading", "")}>
        <Wrapper
          flexDirection={["column-reverse", "row-reverse"]}
          flexWrap={["nowrap", "wrap-reverse"]}
          justifyContent={["flex-start", "space-around"]}
        >
          {actionPlansList.map((plan, index) => {
            return (
              <PlanBox
                id={plan.section}
                key={plan.heading}
                stageIndex={index}
                section={plan.section}
                icon={plan.icon}
                furtherReading={furtherReading}
              />
            )
          })}
        </Wrapper>
      </ReportItem>
    </ResultCard>
  )
}

function mapStateToProps(state, { isolated = false }) {
  return {
    furtherReading: !isolated,
    showShadow: !isolated
  }
}

export default connect(mapStateToProps)(ActionPlans)
