export default [
  {
    title: "The workplace in review: Ethnicity",
    thumbnailSrc:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/business/material-images/The+Workplace+in+review.png",
    url: "https://youtu.be/1NiN8aS2wkc"
  },
  {
    title: "Approaches to Change",
    thumbnailSrc:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/thumbnails/approaches-to-change.png",
    url:
      "https://www.managers.org.uk/campaigns/managing-change-approaches-to-change/"
  },
  {
    title: "Why Change Happens",
    thumbnailSrc:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/thumbnails/why-change-matters.png",
    url:
      "https://www.managers.org.uk/campaigns/managing-change-why-change-happens/"
  },
  {
    title: "Initiating and Planning Change",
    thumbnailSrc:
      "https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/thumbnails/initiating-and-planning-change.png",
    url:
      "https://www.managers.org.uk/campaigns/managing-change-initiating-and-planning-change/"
  }
]
