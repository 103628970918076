import React from "react"

import AuthGuard from "../../features/ui/containers/AuthGuard"
import LibraryLanding from "../../features/myLibrary/components/LibraryLanding"
import PageLayout from "../../layouts/PageLayout"

const Index = () => (
  <AuthGuard>
    <PageLayout>
      <LibraryLanding />
    </PageLayout>
  </AuthGuard>
)

export default Index
