/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import { Flex, Box } from "@engaging-tech/components"

const HomepageBlock = ({ children, bg = "#fff", ...props }) => (
  <Flex
    bg={bg}
    as="section"
    justifyContent="center"
    alignItems="center"
    {...props}
  >
    <Box p={[4, 6]} maxWidth={1200} width={1 / 1}>
      {children}
    </Box>
  </Flex>
)

export default HomepageBlock
