import { Flex, MotionFlex, Spinner, TabletUp, Text, ToggleLogic } from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"
import { AnimatePresence } from "framer-motion"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import PremiumModal from "../../../Notifications/components/PremiumModal"
import CircleIcon from "../../../ui/components/CircleIcon"
import PrimaryButton from "../../../ui/components/PrimaryButton"
import WidgetCard from "../../components/WidgetCard"
import DynamicState from "../../containers/DynamicState"
import ManageAlertsModal from "../containers/ManageAlertsModal"
import ContentPost from "./ContentPost"

const ScrollFlex = styled(Flex)`
  overflow-x: auto;
  overflow-y: hidden;
`

const layout = [
  {
    title: "Upcoming Events",
    contentType: "events",
    contentPath: "event"
  },
  {
    title: "Articles To Read",
    contentType: "articles",
    contentPath: "article"
  },
  {
    title: "Podcasts To Discover",
    contentType: "podcasts",
    contentPath: "podcast"
  }
]

const SeeAllButton = ({ contentType, direction }) => {
  const [buttonPressed, setButtonPressed] = useState(false)
  const [hovered, setHovered] = useState(false)
  const scrollContainer = useRef()
  const interval = useRef()

  const handlePress = () => {
    if (direction === "RIGHT" && scrollContainer.current) {
      scrollContainer.current.scrollLeft += 30
    } else if (direction === "LEFT" && scrollContainer.current) {
      scrollContainer.current.scrollLeft -= 30
    }
  }

  useEffect(() => {
    const element = document.querySelector(`#content-scroll-box-${contentType}`)
    if (element) {
      scrollContainer.current = element
    }
    return clearInterval(interval.current)
  }, [contentType])

  useEffect(() => {
    if (buttonPressed) {
      interval.current = setInterval(() => {
        handlePress()
      }, 30)
    } else {
      clearInterval(interval.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonPressed])

  return (
    <MotionFlex
      mb={30}
      ml={direction === "RIGHT" ? 3 : 1}
      mr={direction === "RIGHT" ? 0 : 3}
      width={40}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      onTap={() => setButtonPressed(false)}
      onTapStart={() => setButtonPressed(true)}
      onHoverStart={() => setHovered(true)}
      onHoverEnd={() => setHovered(false)}
      style={{ cursor: "pointer" }}
    >
      <CircleIcon
        name={direction === "RIGHT" ? "navigate_next" : "navigate_before"}
        elevation={3}
        mb={2}
        animate={{ scale: hovered ? 1.05 : 1 }}
        bg="primary.0"
        color="secondary.0"
      />
    </MotionFlex>
  )
}
const ContentForYouWidget = ({ id, loadContentForYou, contentForYou, isPremium }) => {
  const paths = usePaths()
  useEffect(() => {
    loadContentForYou()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <WidgetCard bg="light.0" width="100%" height="100%">
      <DynamicState id={id} removable title="Content For You">
        {contentForYou.loading ? (
          <Spinner py={3} color="primary.0" justifyContent="center" width={1 / 1} />
        ) : (
          <Flex width="99%" flexDirection="column">
            {layout.map(el => (
              <Flex key={el.title} width="100%" flexDirection="column" my={2}>
                <Flex alignItems="center">
                  <TabletUp>
                    <SeeAllButton contentType={el.contentType} direction="LEFT" />
                  </TabletUp>

                  <Flex flexDirection="column" width={["100%", "calc(100% - 48px - 48px - 16px - 16px)"]}>
                    <Text fontSize={4} color="dark.2">
                      {el.title}
                    </Text>
                    <ScrollFlex
                      width="100%"
                      py={1}
                      flexDirection="row"
                      id={`content-scroll-box-${el.contentType}`}
                    >
                      {contentForYou.data[el.contentType].length > 0 ? (
                        contentForYou.data[el.contentType].map(post => (
                          <ContentPost type={el.contentType} post={post} key={post.id} />
                        ))
                      ) : (
                        <Spinner py={3} color="primary.0" justifyContent="center" width={1 / 1} />
                      )}
                    </ScrollFlex>
                    <Flex
                      width="100%"
                      mt={[2, 14]}
                      justifyContent="space-between"
                      flexDirection={["column", "row"]}
                    >
                      <ToggleLogic>
                        {({ on, toggle }) => (
                          <>
                            <PrimaryButton
                              color="light.0"
                              px={3}
                              onClick={toggle}
                              text="MANAGE NOTIFICATIONS"
                            />
                            <AnimatePresence>
                              {on &&
                                (!isPremium ? (
                                  <PremiumModal toggle={toggle} />
                                ) : (
                                  <ManageAlertsModal
                                    initialNavItem={
                                      el.contentType === "events" ? "Events" : "Business Library"
                                    }
                                    toggle={toggle}
                                  />
                                ))}
                            </AnimatePresence>
                          </>
                        )}
                      </ToggleLogic>
                      <Link
                        newTab
                        width="auto"
                        to={`${paths.globalHub.index}?tab=${[el.contentPath]}`}
                        mt={[2, 0]}
                      >
                        <Text
                          width="auto"
                          minWidth="max-content"
                          style={{ textTransform: "capitalize" }}
                          color="primary.0"
                          fontWeight="700"
                          fontSize={3}
                        >
                          See All {el.contentType}
                        </Text>
                      </Link>
                    </Flex>
                  </Flex>
                  <TabletUp>
                    <SeeAllButton contentType={el.contentType} direction="RIGHT" />
                  </TabletUp>
                </Flex>
              </Flex>
            ))}
          </Flex>
        )}
      </DynamicState>
    </WidgetCard>
  )
}

export default ContentForYouWidget
