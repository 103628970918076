import React, { useState } from "react"
import {
  Modal,
  TabletUp,
  MobileOnly,
  CustomHooks
} from "@engaging-tech/components"
import { createPortal } from "react-dom"
import styled from "styled-components"
import MobileView from "./MobileGoals"
import SecondScreen from "./OnboardingSecondScreen"
import DesktopView from "./DesktopGoals"
import WelcomeScreen from "./WelcomeScreen"

const StyledModal = styled(Modal)`
  overflow: hidden;
  overflow-y: auto;
`

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const GoalModal = ({
  toggle,
  allGoals,
  myGoals,
  seenBefore,
  updateSeenBefore,
  updateMyGoals,
  userProfile
}) => {
  CustomHooks.useFreshChatDeactivate()
  const [chosenGoals, setChosenGoals] = useState(
    myGoals?.length > 0 ? [...myGoals] : []
  )
  const [welcomeShowing, setWelcomeShowing] = useState(true)
  const [showSecondOnboarding, setShowSecondOnboarding] = useState(false)
  const [goalsLeftToChoose, setGoalsLeftToChoose] = useState(
    allGoals.data.length > 0 && myGoals
      ? allGoals.data.filter(elem => !myGoals.find(({ id }) => elem.id === id))
      : []
  )
  const chosenGoalsIdsArray =
    chosenGoals && chosenGoals.length > 0
      ? chosenGoals.map(goal => goal.id)
      : []

  const renderModalState = () => {
    if (welcomeShowing && !seenBefore) {
      return (
        <WelcomeScreen
          userProfile={userProfile}
          seenBefore={seenBefore}
          setWelcomeShowing={setWelcomeShowing}
          toggle={toggle}
          updateSeenBefore={updateSeenBefore}
        />
      )
    }
    if (showSecondOnboarding) {
      return (
        <SecondScreen
          setShowSecondOnboarding={setShowSecondOnboarding}
          toggle={toggle}
          updateSeenBefore={updateSeenBefore}
          seenBefore={seenBefore}
          chosenGoals={chosenGoals}
          updateMyGoals={updateMyGoals}
          chosenGoalsIdsArray={chosenGoalsIdsArray}
        />
      )
    }
    return (
      <>
        <TabletUp width={1 / 1}>
          <DesktopView
            setChosenGoals={setChosenGoals}
            chosenGoals={chosenGoals}
            goalsLeftToChoose={goalsLeftToChoose}
            allGoals={allGoals}
            myGoals={myGoals}
            setGoalsLeftToChoose={setGoalsLeftToChoose}
            seenBefore={seenBefore}
            toggle={toggle}
            updateSeenBefore={updateSeenBefore}
            updateMyGoals={updateMyGoals}
            chosenGoalsIdsArray={chosenGoalsIdsArray}
            setShowSecondOnboarding={setShowSecondOnboarding}
          />
        </TabletUp>
        <MobileOnly width={1 / 1} id="mobile-view">
          <MobileView
            setChosenGoals={setChosenGoals}
            chosenGoals={chosenGoals}
            goalsLeftToChoose={goalsLeftToChoose}
            allGoals={allGoals}
            myGoals={myGoals}
            setGoalsLeftToChoose={setGoalsLeftToChoose}
            seenBefore={seenBefore}
            toggle={toggle}
            updateSeenBefore={updateSeenBefore}
            updateMyGoals={updateMyGoals}
            chosenGoalsIdsArray={chosenGoalsIdsArray}
            setShowSecondOnboarding={setShowSecondOnboarding}
            welcomeShowing={welcomeShowing}
            setWelcomeShowing={setWelcomeShowing}
          />
        </MobileOnly>
      </>
    )
  }

  return createPortal(
    <StyledModal
      animated
      disableScrollbar
      id="modal-em"
      bg="light.0"
      onToggle={toggle}
      flexDirection="column"
      maxHeight={["100vh", "80vh"]}
      mb={[0, 100]}
      minWidth={
        !seenBefore && welcomeShowing ? ["90vw", "750px"] : ["100vw", "750px"]
      }
    >
      {renderModalState()}
    </StyledModal>,
    appRoot()
  )
}

export default GoalModal
