import { getConfig } from "@engaging-tech/ssr-config"
import axios from "axios"

import { apollo } from "../../../lib/Apollo"
import { apolloInAppView } from "../../../lib/ApolloInAppView"
import { getIdToken } from "../../account/services/account.service"
import {
  CANCEL_SUBSCRIPTION,
  CONFIRM_EMAIL,
  CREATE_PROFILE,
  LOAD,
  SEND_CONFIRMATION_EMAIL,
  TOGGLE_SERVICES,
  UPDATE_MY_GOALS,
  UPDATE_YOUR_PROFILE
} from "./yourProfile.queries"

const GRAPHQL_API_URL = () => `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

const SUBSCRIPTION_URL = () => `${getConfig().api.url}${getConfig().api.endpoints.payment}`

export const load = (userId, place) => {
  if (place === "in-app-view") {
    return apolloInAppView.query({
      uri: GRAPHQL_API_URL(),
      query: LOAD,
      variables: { userId }
    })
  }

  return apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD,
    variables: { userId }
  })
}

export const toggleServices = services =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: TOGGLE_SERVICES,
    variables: { services }
  })

const UPLOAD_PROFILE_IMAGE_URL = () => `${getConfig().api.url}${getConfig().api.endpoints.profileUpload}`

export const upload = async (file, url = UPLOAD_PROFILE_IMAGE_URL(), api = axios) => {
  const tokenString = await getIdToken()
  const formData = new FormData()
  formData.append("file", file)

  return api({
    method: "post",
    url,
    data: formData,
    headers: { Authorization: `JWT ${tokenString}` }
  })
}

export const create = ({ firstName, lastName, allowMarketing, location }) => {
  return apollo.mutate({
    uri: GRAPHQL_API_URL,
    mutation: CREATE_PROFILE,
    variables: {
      firstName,
      lastName,
      allowMarketing,
      location
    }
  })
}

export const update = ({
  firstName,
  lastName,
  location,
  jobTitle,
  companyName,
  websiteUrl,
  picture,
  currentGoal,
  employmentStatus,
  skills,
  personalStatement,
  allowMarketing,
  age,
  gender,
  industries,
  hobbies,
  languages,
  positionsHeld,
  qualifications,
  goalsViewed
}) => {
  return apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: UPDATE_YOUR_PROFILE,
    variables: {
      firstName,
      lastName,
      location,
      jobTitle,
      companyName,
      websiteUrl,
      picture,
      currentGoal,
      employmentStatus,
      skills,
      personalStatement,
      allowMarketing,
      age,
      gender,
      industries,
      hobbies,
      languages,
      positionsHeld,
      qualifications,
      goalsViewed
    }
  })
}

export const requestConfirmEmail = () =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: SEND_CONFIRMATION_EMAIL
  })

export const confirmEmail = confirmToken => {
  return apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: CONFIRM_EMAIL,
    variables: { confirmToken }
  })
}
export const updateMyGoals = goalIds =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: UPDATE_MY_GOALS,
    variables: { goalIds }
  })

// PREMIUM //
/**
 * Subscribes to premium service, using a token created by stripe.js.
 * @param {string} source The source used to create a subscription against. Generated by stripe.js
 * @param {string} promoId The promoId to apply a discount
 * @param {string} url The URL of the API endpoint to send the request to.
 * @param {*} api The function used to make the request. Defaults to Axios.
 */
export const subscribe = async ({ source, promoId }, url = SUBSCRIPTION_URL(), api = axios) => {
  const tokenString = await getIdToken()
  return api({
    method: "post",
    url: `${url}/premium/subscribe`,
    data: { source, promoId },
    headers: { Authorization: `JWT ${tokenString}` }
  })
}

export const cancel = ({ reason, message }) =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: CANCEL_SUBSCRIPTION,
    variables: { reason, message }
  })

/**
 *
 * @param {string} discountCode the discount code to be applied
 * @param {string} url The URL of the API endpoint to send the request to.
 * @param {*} api The function used to make the request. Defaults to Axios.
 */
export const applyDiscount = async ({ discountCode, url = SUBSCRIPTION_URL(), api = axios }) => {
  const tokenString = await getIdToken()

  return api({
    method: "post",
    url: `${url}/premium/promotion`,
    data: {
      discountCode
    },
    headers: { Authorization: `JWT ${tokenString}` }
  })
}
