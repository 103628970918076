import React, { useState } from "react"
import {
  Flex,
  Modal,
  Text,
  Card,
  Icon,
  Button,
  CustomHooks
} from "@engaging-tech/components"
import { createPortal } from "react-dom"
import styled from "styled-components"
import { AnimatePresence } from "framer-motion"
import WidgetCard from "./WidgetCard"
import PrimaryButton from "../../ui/components/PrimaryButton"
import TextButton from "../../ui/components/TextButton"

import { findWidgetWithinDictonaryById } from "../widgets/helpers/widgetHelpers"

const BrokenBorder = styled(WidgetCard)`
  border: 2px solid ${({ theme }) => theme.colors.dark[3]};
  border-radius: 8px;
  border-style: dashed;
`

const ClickFlex = styled(Flex)`
  cursor: pointer;
`

const Image = styled.img`
  max-height: 200px;
  max-width: 300px;
  width: 98%;
  object-fit: contain;
  @media (max-width: 370px) {
    max-width: 280px;
  }
`
const StyledIcon = styled(Icon)`
  position: absolute;
  right: ${({ mobile }) => (mobile ? "-10px" : "-20px")};
  top: -28px;
`

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const WidgetChoiceModal = ({
  setWidgetModalShowing,
  widgetModalShowing,
  allWidgets,
  widgetLayout,
  addWidgets
}) => {
  CustomHooks.useFreshChatDeactivate()
  const device = CustomHooks.useClientDevice()
  // Gets all unselected widgets by comparing layout widgets against all available widgets
  const unselectedWidgets = allWidgets?.data
    .filter(
      widget =>
        !widgetLayout.data.desktop
          .map(selectedWidgetLayoutInfo => selectedWidgetLayoutInfo.i)
          .includes(widget.id)
    )
    // Map widget backend ID's to widget dictonary
    .map(wid => findWidgetWithinDictonaryById(wid.id))
    // Do not include disabled widgets within selection options
    .filter(wid => wid && !wid.disabled)

  const [selectedOptionsIds, setSelectedOptionsIds] = useState([])

  return createPortal(
    <Modal
      display="block"
      bg="light.0"
      animated
      disableScrollbar
      onToggle={() => setWidgetModalShowing(!widgetModalShowing)}
      flexDirection="column"
      justifyContent="space-between"
      maxHeight="80vh"
      maxWidth="90vw"
      mb={100}
      minWidth={[0, 760, 760]}
    >
      <Flex width={1 / 1} py={3} bg="#E1F4F4" display="block">
        <Text
          fontFamily="Tiempos"
          fontWeight={700}
          color="primary.0"
          textAlign="center"
          fontSize={5}
        >
          What Widget Would You Like To Add?
        </Text>
      </Flex>
      <Text textAlign="center" my={4} fontSize="18px" width={1 / 1}>
        Select from the widget options below
      </Text>
      <Flex
        width="98%"
        height="100%"
        px="10px"
        style={{ overflowY: "auto" }}
        flexDirection={["column", "row"]}
        flexWrap={["nowrap", "wrap"]}
        alignItems={["center", "flex-end"]}
        justifyContent={["flex-start", "space-around"]}
        pt={3}
      >
        {unselectedWidgets.map(widget => (
          <ClickFlex
            flexDirection="column"
            height="auto"
            minHeight="max-content"
            width={["90%", "45%"]}
            alignItems="center"
            key={widget.id}
            my={3}
            onClick={() => {
              if (selectedOptionsIds.includes(widget.id)) {
                setSelectedOptionsIds(
                  selectedOptionsIds.filter(e => e !== widget.id)
                )
              } else {
                setSelectedOptionsIds([...selectedOptionsIds, widget.id])
              }
            }}
          >
            <Card
              mb={2}
              position="relative"
              width="100%"
              p={2}
              justifyContent="center"
              alignItems="center"
            >
              {selectedOptionsIds.includes(widget.id) && (
                <StyledIcon mobile={device === "MOBILE"} name="check_circle" />
              )}
              <Image src={widget.image} alt={widget.name} />
            </Card>
            <Text fontSize={4} fontWeight={700} textAlign="center">
              {widget.name}
            </Text>
          </ClickFlex>
        ))}
      </Flex>
      <Flex mt={2} p={3} width={1 / 1} justifyContent="space-between">
        <Button
          variant="text.dark.3"
          elevation={0}
          onClick={() => setWidgetModalShowing(false)}
        >
          CANCEL
        </Button>
        <Flex justifyContent="flex-end" alignItems="center">
          <TextButton
            mr={[2, 4]}
            onClick={() => {
              setSelectedOptionsIds([])
            }}
          >
            RESET SELECTION
          </TextButton>

          <PrimaryButton
            width="120px"
            onClick={() => {
              addWidgets(selectedOptionsIds)
              setWidgetModalShowing(false)
            }}
          >
            CONFIRM
          </PrimaryButton>
        </Flex>
      </Flex>
    </Modal>,
    appRoot()
  )
}

const AddAWidget = ({ allWidgets, userId, widgetLayout, addWidgets }) => {
  const [widgetModalShowing, setWidgetModalShowing] = useState(false)

  return (
    <BrokenBorder width="100%" height="100%" elevation={0} mt="10px">
      <Flex
        width={1 / 1}
        height="200px"
        justifyContent="center"
        alignItems="center"
      >
        <PrimaryButton
          color="light.0"
          text="+ Add A Widget"
          px={3}
          onClick={() => setWidgetModalShowing(!widgetModalShowing)}
        />
        <AnimatePresence>
          {widgetModalShowing && (
            <WidgetChoiceModal
              addWidgets={addWidgets}
              widgetModalShowing={widgetModalShowing}
              setWidgetModalShowing={setWidgetModalShowing}
              allWidgets={allWidgets}
              widgetLayout={widgetLayout}
              userId={userId}
            />
          )}
        </AnimatePresence>
      </Flex>
    </BrokenBorder>
  )
}

export default AddAWidget
