import { connect } from "react-redux"

import { answer } from "../store/happinessSurvey.actions"
import {
  getAnswerById,
  getQuestionById
} from "../store/happinessSurvey.selectors"

import QuestionComponent from "../components/Question"

const mapState = (state, { questionId }) => ({
  previousAnswer: getAnswerById(state, questionId),
  question: getQuestionById(state, questionId)
})

const mapDispatch = dispatch => ({
  onSubmit: ({ id, value }) => dispatch(answer({ questionId: id, value }))
})

const Question = connect(mapState, mapDispatch)(QuestionComponent)

export default Question
