export const types = {
  LOAD_ALL: "[Business Library] Load All",
  LOAD_ALL_SUCCESS: "[Business Library] Load All Success",
  LOAD_ALL_FAIL: "[Business Library] Load All Fail",
  LOAD_MORE: "[Business Library] Load More",
  LOAD_MORE_SUCCESS: "[Business Library] Load More Sucess",
  LOAD_MORE_FAIL: "[Business Library] Load More Fail",
  LOAD_SELECTED: "[Business Library] Load Selected",
  LOAD_SELECTED_SUCCESS: "[Business Library] Load Selected Success",
  LOAD_SELECTED_FAIL: "[Business Library] Load Selected Fail",
  LOAD_FEATURED: "[Business Library] Load Featured",
  LOAD_FEATURED_SUCCESS: "[Business Library] Load Featured Success",
  LOAD_FEATURED_FAIL: "[Business Library] Load Featured Fail",
  FILTER: "[Business Library] Filter",
  RESET_FILTER: "[Business Library] Reset Filters",
  SEARCH: "[Business Library] Search",
  RESET_SEARCH: "[Business Library] Reset Search"
}

export const loadAll = ({ postType, searchTerm, category }) => ({
  type: types.LOAD_ALL,
  payload: { postType, searchTerm, category }
})

export const loadAllSuccess = posts => ({
  type: types.LOAD_ALL_SUCCESS,
  payload: posts
})

export const loadAllFail = () => ({
  type: types.LOAD_ALL_FAIL
})

export const loadMore = ({ postType, from, searchTerm, categoryId }) => ({
  type: types.LOAD_MORE,
  payload: {
    postType,
    from,
    searchTerm,
    categoryId
  }
})

export const loadMoreSuccess = posts => ({
  type: types.LOAD_MORE_SUCCESS,
  payload: posts
})

export const loadMoreFail = () => ({
  type: types.LOAD_MORE_FAIL
})

export const loadSelected = postId => ({
  type: types.LOAD_SELECTED,
  payload: postId
})

export const loadSelectedSuccess = post => ({
  type: types.LOAD_SELECTED_SUCCESS,
  payload: post
})

export const loadSelectedFail = () => ({
  type: types.LOAD_SELECTED_FAIL
})

export const loadFeatured = () => ({
  type: types.LOAD_FEATURED
})

export const loadFeaturedSuccess = featured => ({
  type: types.LOAD_FEATURED_SUCCESS,
  payload: featured
})

export const loadFeaturedFail = () => ({
  type: types.LOAD_FEATURED_FAIL
})

export const filter = category => ({
  type: types.FILTER,
  payload: category
})

export const resetFilters = () => ({
  type: types.RESET_FILTER
})

export const search = searchTerm => ({
  type: types.SEARCH,
  payload: searchTerm
})

export const resetSearch = () => ({
  type: types.RESET_SEARCH
})
