import { Box, Card } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"

import { AccountSelectors } from "../../account"
import ComparisonCard from "../components/ComparisonCard"
import ComparisonBanner from "../components/ComparisonCard/ComparisonBanner"
import FourPillars from "../components/FourPillars"

const SignUp = ({ onLoad, stripeCheckoutHasLoaded, stripeCheckoutUrl, ...props }) => {
  const paths = usePaths()
  const router = useRouter()

  const isAuthenticated = useSelector(AccountSelectors.getIsAuthenticated)

  const freeAccountAction = () => {
    if (isAuthenticated) {
      return router.navigate(paths.home)
    }
    return router.navigate(paths.account.signUp)
  }

  const premiumAccountAction = () => {
    if (isAuthenticated) {
      return router.navigate(paths.getPremium.subscribeToPremium)
    }
    return router.navigate(`${paths.account.signUp}?from=premium`)
  }

  useEffect(() => {
    onLoad()
  }, [onLoad])

  useEffect(() => {
    if (stripeCheckoutHasLoaded && stripeCheckoutUrl) {
      window.location.replace(stripeCheckoutUrl)
    }
  }, [stripeCheckoutHasLoaded, stripeCheckoutUrl])

  return (
    <Card
      width={1 / 1}
      maxWidth={1080}
      bg="light.0"
      elevation={8}
      alignItems="center"
      mx={[0, 3]}
      mb={[6, 5]}
      mt={[0, 5]}
      pb={4}
      height="100%"
    >
      {!isAuthenticated && <ComparisonBanner />}
      <Box px={[3, 3, 5]} paddingTop={isAuthenticated && 4} height="100%">
        {isAuthenticated ? (
          <FourPillars {...props} />
        ) : (
          <ComparisonCard
            freeAccountAction={freeAccountAction}
            premiumAccountAction={premiumAccountAction}
            {...props}
          />
        )}
      </Box>
    </Card>
  )
}

export default SignUp
