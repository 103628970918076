import { CookiePolicy } from "@engaging-tech/legal"
import {
  consentCategories,
  ConsentToggles,
  ThirdPartyActions,
  ThirdPartySelectors
} from "@engaging-tech/third-party-cookies"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import PageLayout from "../../layouts/PageLayout"
import { firstPartyCookies, thirdPartyCookies } from "../../lib/legal"

const CookiesComponent = ({ hasConsented, init }) => {
  useEffect(() => {
    if (!hasConsented) init()
  }, [hasConsented, init])

  return (
    <PageLayout isPublic flexDirection="column">
      {ConsentToggles().map((Toggle, index) => (
        <Toggle
          key={consentCategories[index]}
          id={`toggle-${index}`}
          category={consentCategories[index]}
        />
      ))}
      <CookiePolicy
        date={new Date("2024-01-01")}
        siteName="WorkL"
        firstPartyCookies={firstPartyCookies}
        thirdPartyCookies={thirdPartyCookies}
      />
    </PageLayout>
  )
}

const mapState = state => ({
  hasConsented: ThirdPartySelectors.getHasConsented(state)
})

const mapDispatch = dispatch => ({
  init: () => dispatch(ThirdPartyActions.getConsent())
})

const Cookies = connect(mapState, mapDispatch)(CookiesComponent)

export default Cookies
