import { Flex } from "@engaging-tech/components"
import React from "react"

import PercentageGraphic from "../../../../ui/components/PercentageGraphic"
import WidgetContainer from "./widget-container"

export default function WorkplaceHappinessScore({ score, ...props }) {
  return (
    <WidgetContainer
      mb={4}
      width={["100%", "38%"]}
      height={["auto", "500px"]}
      title="Workplace Happiness Score"
      {...props}
    >
      <Flex width={["60%", "auto"]} height="auto">
        <PercentageGraphic graphicWidth="auto" height="auto" score={score} noData={!score} animate />
      </Flex>
    </WidgetContainer>
  )
}
