import { connect } from "react-redux"
import {
  notificationsSelectors,
  notificationsActions
} from "../../../Notifications"

import EditAlertComponent from "../components/EditAlert"

const mapState = state => ({
  activeAlert: notificationsSelectors.getActiveAlert(state),
  isLoading: notificationsSelectors.getIsLoading(state)
})

const mapDispatch = dispatch => ({
  onSubmit: values => {
    if (values.type === "JobAlert")
      return dispatch(notificationsActions.updateJobAlert(values))
    if (values.type === "EventAlert")
      return dispatch(notificationsActions.updateEventAlert(values))
    if (values.type === "MaterialAlert")
      return dispatch(notificationsActions.updateMaterialAlert(values))
  }
})

const EditAlert = connect(mapState, mapDispatch)(EditAlertComponent)

export default EditAlert
