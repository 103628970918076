import { connect } from "react-redux"

import SearchBar from "../../ui/components/Search/SearchBar"

import { getSearchTerm } from "../store/libraryBooks.selectors"
import { search, resetSearch } from "../store/libraryBooks.actions"

const mapState = state => ({
  hasSearched: !!getSearchTerm(state)
})

const mapDispatch = dispatch => ({
  onSearch: e => dispatch(search(e)),
  onClear: () => dispatch(resetSearch())
})

const SearchBooks = connect(mapState, mapDispatch)(SearchBar)

export default SearchBooks
