const createTouchFieldObject = (container = null) => {
  const containerElement = container || document.querySelector("#form-flow-wizard")
  const formInputs = Array.from(containerElement.getElementsByTagName("input"))

  const formSelects = Array.from(containerElement.getElementsByTagName("select"))

  const formTextAreas = Array.from(containerElement.getElementsByTagName("textarea"))

  const formCustomInputs = Array.from(
    document.querySelector("#form-flow-wizard").querySelectorAll("[type='input']")
  )

  const locationInputs = Array.from(containerElement.querySelectorAll(".location-input"))

  return [...formInputs, ...formSelects, ...formCustomInputs, ...formTextAreas, ...locationInputs].reduce(
    (outputFieldObject, input) => ({ ...outputFieldObject, [input.id]: true }),
    {}
  )
}

export default createTouchFieldObject
