export const types = {
  LOAD_FILTERS: "[Demographic Filters] Load",
  LOAD_FILTERS_SUCCESS: "[Demographic Filters] Load Success",
  LOAD_FILTERS_FAIL: "[Demographic Filters] Load Fail"
}

export const loadFilters = payload => ({
  type: types.LOAD_FILTERS,
  payload
})

export const loadFiltersSuccess = payload => ({
  type: types.LOAD_FILTERS_SUCCESS,
  payload
})

export const loadFiltersFail = () => ({
  type: types.LOAD_FILTERS_FAIL
})
