import React, { useEffect } from "react"
import { Link } from "@engaging-tech/routing"
import { Spinner, Box, Text } from "@engaging-tech/components"

import DigitalBookPreview from "./DigitalBookPreview"
import ExternalBadges from "./AdobeBadges"

const AudiobookList = ({
  audioBooks,
  isLoading,
  onLoad,
  onDownload,
  userDetails
}) => {
  useEffect(() => {
    if (userDetails.userId) onLoad(userDetails.userId)
  }, [])

  if (isLoading) {
    return <Spinner color="primary.0" justifyContent="center" />
  }

  return (
    <Box width={1 / 1}>
      <Text mb={3} fontSize={5} fontWeight={500} display="block">
        My Audiobooks
      </Text>
      <Box p={3} bg="dark.6" mb="4" borderRadius={4}>
        <Text fontWeight={500} fontSize={4}>
          Download iTunes (or other MP3 players) to play your audiobook
        </Text>
        <ExternalBadges
          appleUrl="https://www.apple.com/uk/itunes/"
          googleUrl="https://play.google.com/store/apps/details?id=com.apple.android.music&referrer=utm_source=http%3A%2F%2Fitunes.apple.com%2Fsubscribe%3Fapp%3Dmusic%26at%3D1000lpya%26ct%3D"
          desktopUrl="https://www.apple.com/uk/itunes/download/"
          mt={[2]}
          alignItems="flex-start"
          justifyContent="flex-start"
          alignment="flex-start"
        />
      </Box>
      {audioBooks.length > 0 ? (
        audioBooks.map(audioBook => (
          <DigitalBookPreview
            book={audioBook}
            key={audioBook.id}
            onDownload={onDownload}
          />
        ))
      ) : (
        <>
          <Text mb={3} display="block">
            Your purchased audiobooks will appear here
          </Text>
          <Text fontSize={3} mb={3}>
            Visit the{" "}
            <Link to="/business-library/browse/books">
              <Text color="primary.1">Business Library</Text>
            </Link>{" "}
            to purchase
          </Text>
        </>
      )}
    </Box>
  )
}

export default AudiobookList
