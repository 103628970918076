import { Box, Button, Flex, Text, TextField } from "@engaging-tech/components"
import { Link, useRouter } from "@engaging-tech/routing"
import React from "react"
import styled from "styled-components"

import AccountDialog from "../../../ui/components/AccountDialog"

const ActionContainer = styled(Flex)`
  width: 100%;

  flex-direction: row-reverse;
  justify-content: end;
  align-items: center;

  @media only screen and (max-width: 580px) {
    flex-direction: column;

    > button {
      margin-bottom: 10px;
      width: 100%;
    }
  }
`

export default function SignInFormContent({
  handleSubmit,
  errors,
  touched,
  values,
  isValid,
  handleChange,
  handleBlur,
  isLoading,
  siteLogo,
  footer: Footer,
  title,
  cancelRoute
}) {
  const router = useRouter()

  return (
    <AccountDialog title={title} renderLogo={siteLogo}>
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <TextField
            width={1 / 1}
            id="sign-in-email"
            name="email"
            type="email"
            label="Email"
            bg="dark.6"
            borderRadius="4px 4px 0px 0px"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={touched.email && errors.email}
          />
        </Box>
        <Box mb={2}>
          <TextField
            width={1 / 1}
            id="sign-in-password"
            name="password"
            type="password"
            bg="dark.6"
            label="Password"
            labelHeight={30}
            borderRadius="4px 4px 0px 0px"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            error={touched.password && errors.password}
          />
        </Box>
        <Box mb={5}>
          <Link to="/account/forgot-password">
            <Text fontSize={[4, 3, 2]} color="primary.0">
              Forgot password?
            </Text>
          </Link>
        </Box>

        {Footer && <Footer />}

        <ActionContainer>
          <Button variant="primary.0" type="submit" disabled={!isValid || isLoading} width={100} ml={3}>
            Sign In
          </Button>
          <Button
            variant="primary.0"
            width={100}
            ml={3}
            type="button"
            disabled={isLoading}
            onClick={() => router.navigate("/account/comparison")}
          >
            Sign up
          </Button>
          <Button
            variant="outline.primary.0"
            type="button"
            ml={3}
            onClick={() => window.open("/account/magic-link", "_self")}
          >
            Send Magic Link
          </Button>
          <Button
            variant="text.noBg.primary.0"
            type="button"
            onClick={() => window.open(cancelRoute, "_self")}
          >
            Cancel
          </Button>
        </ActionContainer>
      </form>
    </AccountDialog>
  )
}
