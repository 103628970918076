import { CircularProgress } from "@engaging-tech/charts"
import { Flex, Paragraph } from "@engaging-tech/components"
import { getCountries } from "@engaging-tech/frontend-utils"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import DropdownCircular from "./DropdownCircularProgress"

const bg = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/happiness_widget_background.png`)

const HappinessFlex = styled(Flex)`
  background: url(${bg}) no-repeat;
  background-size: cover;
`

const BorderedFlex = styled(Flex)`
  border-radius: 4px;
  box-shadow: 0px 2px 4px #00000033;
  height: 131px;
`

const getOverallByName = (countriesWithScores, name) => {
  if (!countriesWithScores) return 65
  const foundCountry = countriesWithScores.find(country => country.name === name)
  if (!foundCountry) return 65
  return foundCountry.overall
}

const getDisplayCountries = (allCountries, countriesWithScores) =>
  allCountries.map(({ id, name }) => ({
    id,
    name,
    overall: getOverallByName(countriesWithScores, name)
  }))

const HappinessWidget = ({
  worldwide,
  industries,
  countries,
  defaultCountry = "United Kingdom",
  title = "Live Workplace Happiness Scores:",
  text = "Average Happiness Score"
}) => {
  const [selectedIndustry, setSelectedIndustry] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [allCountries, setAllCountries] = useState([])

  useEffect(() => {
    setAllCountries(getCountries())
  }, [])

  const handleIndustrySelect = id => setSelectedIndustry(industries.find(industry => industry.id === id))

  const displayCountries = getDisplayCountries(allCountries, countries)

  const handleCountrySelect = id => setSelectedCountry(displayCountries.find(country => country.id === id))

  return (
    <HappinessFlex
      flexDirection="column"
      pb={3}
      px={6}
      width={[1 / 1]}
      alignItems="center"
      alignContent="center"
    >
      <Paragraph textAlign="center" width={270} color="light.0" fontWeight={700} mt={2} fontSize={4}>
        {title}
      </Paragraph>
      <Flex
        flexDirection={["column", "column", "row"]}
        alignItems="center"
        justifyContent="space-between"
        height={[410, 410, 150]}
        mt={2}
      >
        <BorderedFlex bg="light.0" mx={4} alignItems="center">
          <Paragraph textAlign="center" fontSize={5} pt={3} ml={4}>
            {text}
          </Paragraph>
          <CircularProgress
            height="65px"
            color="primary.1"
            percentage={worldwide || 0}
            strokeColor="#00BB88"
            thickness={0.5}
          />
        </BorderedFlex>
        <BorderedFlex mx={4}>
          {industries && industries.length > 0 && (
            <DropdownCircular
              type="industry"
              onChange={handleIndustrySelect}
              items={industries || []}
              selectedItem={selectedIndustry}
              defaultValue={industries[0].name}
            />
          )}
        </BorderedFlex>
        <BorderedFlex mx={4}>
          {countries && displayCountries && (
            <DropdownCircular
              type="industry"
              onChange={handleCountrySelect}
              items={displayCountries || []}
              selectedItem={selectedCountry}
              defaultValue={defaultCountry}
            />
          )}
        </BorderedFlex>
      </Flex>
    </HappinessFlex>
  )
}

export default HappinessWidget
