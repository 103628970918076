import { connect } from "react-redux"

import { load, reset } from "../store/myLibrary.actions"

import {
  getIsLoading,
  getContentByBookmarkedDate
} from "../store/myLibrary.selectors"

import PostList from "../components/PostList"

const BookmarkedMaterials = postType => {
  const mapState = state => ({
    isLoading: getIsLoading(state),
    content: getContentByBookmarkedDate(state),
    postType
  })

  const mapDispatch = dispatch => ({
    onLoad: () => dispatch(load(postType)),
    onDismount: () => dispatch(reset())
  })

  return connect(mapState, mapDispatch)(PostList)
}

export default BookmarkedMaterials
