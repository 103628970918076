const organisationSurvey = {
  __typename: "",
  type: "",
  id: "",
  surveyId: "",
  title: "",
  status: "",
  features: [],
  introduction: [],
  languages: [],
  language: null,
  meta: {},
  template: {}
}

const initialState = {
  individualCode: null,
  isValidCode: false,
  isValidatingCode: false,
  isLoading: false,
  isSubmitting: false,
  hasSubmitted: false,
  hasAttachedUser: false,
  hasLoaded: false,
  hasError: false,

  submissionId: null,

  content: null,
  answers: [],

  organisationSurvey,

  sectionsList: [],
  currentSection: 0,
  questionsHistory: [],
  currentQuestion: 0,
  branchingLogics: [],

  savantaId: 0
}

export default initialState
