/* eslint-disable react/no-unescaped-entities */

/* eslint-disable no-param-reassign */
import { Flex, Icon, Spinner, Text } from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"
import React, { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"

import PrimaryButton from "../../../ui/components/PrimaryButton"
import DisplayTable from "../../components/DisplayTable"
import Filters from "../../components/filters"
import { config, headings, mobileConfig, mobileHeadings } from "../../lib/companyTableConfig"
import { loadMoreCompanies } from "../../store/rankings.actions"
import {
  getCompanies,
  getCompaniesIsLoading,
  getCompaniesNextToken,
  getMoreCompaniesIsLoading
} from "../../store/rankings.selectors"
import { LoadMoreWrapper, Subtitle, SubtitleUnderline } from "./happiestCompanies.styled"

const types = {
  country: "country",
  industry: "industry",
  category: "category"
}

const updateField = (appliedFields, filter, type) => {
  const fieldIndex = appliedFields.findIndex(field => field.type === type)
  const typeObj = { type, ...filter }
  if (fieldIndex === -1) {
    appliedFields.push(typeObj)
  } else {
    appliedFields[fieldIndex] = typeObj
  }
}

const generateSelectedFields = (selectedFields, countryFilter, industryFilter, categoryFilter) => {
  let appliedFields = [...selectedFields.current]

  if (countryFilter) updateField(appliedFields, countryFilter, types.country)
  if (industryFilter) updateField(appliedFields, industryFilter, types.industry)
  if (categoryFilter) updateField(appliedFields, categoryFilter, types.category)

  if (countryFilter === undefined) appliedFields = appliedFields.filter(field => field.type !== types.country)
  if (industryFilter === undefined)
    appliedFields = appliedFields.filter(field => field.type !== types.industry)
  if (categoryFilter === undefined)
    appliedFields = appliedFields.filter(field => field.type !== types.category)

  selectedFields.current = [...appliedFields]
}

const StyledIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`

const StyledFlex = styled(Flex)`
  width: fit-content;
  border: 1px solid gray;
  padding: 4px 8px;
  border-radius: 8px;
`

export default function HappiestCompaniesContent({
  setDemographicsFilterIsOpen,
  isDemographicFiltersSelected,
  searchTerm,
  setSearchTerm,
  countryFilter,
  setCountryFilter,
  industryFilter,
  setIndustryFilter,
  categoryList,
  categoryFilter,
  setCategoryFilter,
  demographicsFilter,
  onRemoveFilterItem
}) {
  const selectedFields = useRef([])
  const paths = usePaths()

  const companiesList = useSelector(getCompanies)
  const isLoading = useSelector(getCompaniesIsLoading)
  const isLoadingMore = useSelector(getMoreCompaniesIsLoading)
  const hasNextToken = useSelector(getCompaniesNextToken)

  const dispatch = useDispatch()

  const onLoadMore = () => {
    dispatch(
      loadMoreCompanies({
        searchTerm,
        industryFilter,
        countryFilter,
        categoryFilter,
        filters: demographicsFilter
      })
    )
  }

  generateSelectedFields(selectedFields, countryFilter, industryFilter, categoryFilter)

  const handleClick = type => {
    if (type === types.country) setCountryFilter(undefined)
    if (type === types.industry) setIndustryFilter(undefined)
    if (type === types.category) setCategoryFilter(undefined)
  }

  return (
    <>
      <Flex px={[3, 0]} justifyContent="flex-start" flexDirection="column" alignItems="flex-start">
        <Filters
          setDemographicsFilterIsOpen={setDemographicsFilterIsOpen}
          isDemographicFiltersSelected={isDemographicFiltersSelected}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          countryFilter={countryFilter}
          setCountryFilter={setCountryFilter}
          industryFilter={industryFilter}
          setIndustryFilter={setIndustryFilter}
          categoryList={categoryList}
          categoryFilter={categoryFilter}
          setCategoryFilter={setCategoryFilter}
          demographicsFilter={demographicsFilter}
          onRemoveFilterItem={onRemoveFilterItem}
        />

        <Flex width={1 / 1} mt={2}>
          {selectedFields.current?.map(({ name, type, id }) => (
            <StyledFlex width="fit-content" alignItems="center" key={id}>
              <Text mr={2}>{name}</Text>
              {id !== "happinessScores" && <StyledIcon name="clear" onClick={() => handleClick(type)} />}
            </StyledFlex>
          ))}
        </Flex>

        <Subtitle mt={20}>
          Click on a organisation's name to view their current vacancies on the
          <Link to={paths.findAJob.index}>
            <SubtitleUnderline ml={1} mr={1}>
              Jobs Board.
            </SubtitleUnderline>
          </Link>
        </Subtitle>
      </Flex>

      <DisplayTable
        config={config}
        rows={companiesList}
        headings={headings}
        mobileConfig={mobileConfig}
        mobileHeadings={mobileHeadings}
        isLoading={false}
        isDemographicFiltersSelected={isDemographicFiltersSelected}
        type="companies"
      />

      <LoadMoreWrapper>
        {isLoadingMore ? (
          <Spinner mt={4} mb={4} py={3} color="primary.0" width={1 / 1} />
        ) : (
          <PrimaryButton
            onClick={() => onLoadMore()}
            width={300}
            disabled={!hasNextToken || isLoading || isLoadingMore}
            color="light.0"
            text={!hasNextToken ? "Loaded all companies" : "Load more"}
          />
        )}
      </LoadMoreWrapper>
    </>
  )
}
