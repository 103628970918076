import { types } from "./connect.actions"

export const initialState = {
  isLoading: {},
  hasLoaded: {},
  connectResponses: {},
  hasError: {},
  allInvites: []
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CONNECT_USER:
      return {
        ...state,
        isLoading: { ...state.isLoading, [action.payload]: true },
        hasLoaded: { ...state.haLoaded, [action.payload]: false }
      }

    case types.CONNECT_USER_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, [action.payload.id]: false },
        hasLoaded: { ...state.haLoaded, [action.payload.id]: true },
        connectResponses: {
          ...state.connectResponses,
          [action.payload.id]: action.payload.response
        }
      }

    case types.CONNECT_USER_FAIL:
      return {
        ...state,
        isLoading: { ...state.isLoading, [action.payload]: false },
        hasLoaded: { ...state.haLoaded, [action.payload]: true },
        hasError: { ...state.hasError, [action.payload]: true }
      }

    case types.LOAD_INVITES_SUCCESS:
      return { ...state, allInvites: action.payload }

    case types.LOAD_INVITES_FAIL:
      return state

    default:
      return state
  }
}
