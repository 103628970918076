import {
  Flex,
  Icon,
  MobileOnly,
  Paragraph,
  Spinner,
  TabletUp,
  Text,
  ToggleLogic
} from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import { AnimatePresence } from "framer-motion"
import React from "react"
import styled from "styled-components"

import {
  BriefcaseIcon,
  EducationIcon,
  IndustryIcon,
  LikeIcon,
  PersonIcon,
  SkillsIcon,
  SubjectIcon,
  TranslateIcon
} from "../../../../assets/icons"
import CircleIcon from "../../../ui/components/CircleIcon"
import PrimaryButton from "../../../ui/components/PrimaryButton"
import AboutSectionModal from "../../components/AboutSectionModal"
import WidgetCard from "../../components/WidgetCard"

const displayText = "The more information you add, the more personalised your WorkL experience will be."

const sectionsList = ({
  age,
  gender,
  personalStatement,
  positionsHeld,
  qualifications,
  industries,
  skills,
  hobbies,
  languages
}) => {
  return [
    {
      IconComponent: PersonIcon,
      name: "About You",
      ref: !age && !gender ? null : [age, gender]
    },
    { IconComponent: SubjectIcon, name: "Personal Statement", ref: personalStatement },
    { IconComponent: BriefcaseIcon, name: "Experience", ref: positionsHeld },
    { IconComponent: EducationIcon, name: "Education", ref: qualifications },
    {
      IconComponent: IndustryIcon,
      name: "Industries",
      ref: industries
    },
    { IconComponent: SkillsIcon, name: "Skills", ref: skills },
    { IconComponent: LikeIcon, name: "Hobbies and Interests", ref: hobbies },
    { IconComponent: TranslateIcon, name: "Languages", ref: languages }
  ]
}

const BorderBottomFlex = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[5]};
  &:nth-last-child(-n + 2) {
    border-bottom: none;
  }
`

const getColor = ref => {
  if (!ref || (ref && ref === null) || (ref && ref.length === 0)) {
    return "dark.3"
  }
  return "primary.0"
}

const getIconColor = ref => {
  if (!ref || (ref && ref === null) || (ref && ref.length === 0)) {
    return "#6A6A6A"
  }
  return "#043E4C"
}

const SectionsList = ({ list, profileCompletionPercentage }) => {
  return list.map(section => {
    const IconComponent = section?.IconComponent

    return (
      <BorderBottomFlex
        key={section.name}
        width="47%"
        maxWidth={360}
        py={profileCompletionPercentage < 100 ? "14px" : 3}
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex alignItems="center" width="90%">
          <IconComponent style={{ marginRight: "8px" }} color={getIconColor(section.ref)} />
          <Text color={getColor(section.ref)} fontSize={4}>
            {section.name}
          </Text>
        </Flex>

        <ToggleLogic>
          {({ on, toggle }) => (
            <div style={{ cursor: "pointer" }}>
              <Icon name="edit" color="primary.0" onClick={() => toggle()} style={{ cursor: "pointer" }} />
              <AnimatePresence>
                {on && <AboutSectionModal sectionName={section.name} toggle={toggle} />}
              </AnimatePresence>
            </div>
          )}
        </ToggleLogic>
      </BorderBottomFlex>
    )
  })
}

const MobileView = ({ profileCompletionPercentage }) => (
  <ToggleLogic>
    {({ on, toggle }) => (
      <MobileOnly
        width="100%"
        height={profileCompletionPercentage < 100 ? "calc(100% - 40px)" : "100%"}
        px={3}
        py={profileCompletionPercentage < 100 ? 2 : 3}
      >
        <Flex alignItems="center" mb={3}>
          <CircleIcon mr={2} name="stickman" bg="secondary.3" color="primary.0" />
          <Paragraph fontSize={4} my={1} fontWeight={600}>
            About You
          </Paragraph>
          <Icon name="edit" color="primary.0" onClick={toggle} style={{ cursor: "pointer" }} />
        </Flex>
        <Paragraph fontSize={3} my={2} mt={3} maxWidth={500}>
          {displayText}
        </Paragraph>
        <Paragraph fontSize={3} mt={3} maxWidth={500}>
          Your data is confidential and private. Please see our{" "}
          <Link to="/legal/privacy-policy">
            <Text fontSize={3} fontWeight={600} color="primary.0">
              Privacy Policy
            </Text>
          </Link>{" "}
          to find out more.
        </Paragraph>
        {on && <AnimatePresence>{on && <AboutSectionModal toggle={toggle} />}</AnimatePresence>}
      </MobileOnly>
    )}
  </ToggleLogic>
)

const DesktopView = ({ allSections, profileCompletionPercentage }) => (
  <TabletUp width="100%" height={profileCompletionPercentage < 100 ? "calc(100% - 32px)" : "100%"}>
    <Flex
      flexDirection="column"
      width="100%"
      height="100%"
      py={profileCompletionPercentage < 100 ? 2 : 3}
      px={5}
    >
      <Paragraph fontSize={4} fontWeight={600}>
        Tell Us About Yourself
      </Paragraph>
      <Flex
        width="100%"
        maxWidth={840}
        flexDirection={["column", "row", "row"]}
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <SectionsList list={allSections} profileCompletionPercentage={profileCompletionPercentage} />
      </Flex>
      <ToggleLogic>
        {({ on, toggle }) => (
          <>
            <Flex mt={profileCompletionPercentage < 100 ? 0 : 3} alignItems="center">
              <PrimaryButton height={36} width={220} onClick={() => toggle()}>
                {profileCompletionPercentage < 100 ? "Complete Profile" : "See All"}
              </PrimaryButton>
              <Text ml={4} fontSize={[3, 3, 4]} maxWidth={500}>
                {displayText}
              </Text>
            </Flex>
            <AnimatePresence>{on && <AboutSectionModal toggle={toggle} />}</AnimatePresence>
          </>
        )}
      </ToggleLogic>
    </Flex>
  </TabletUp>
)

const ReminderBanner = () => (
  <Flex
    width={1 / 1}
    p={1}
    height={[40, 32]}
    flexShrink={0}
    px={2}
    alignItems="center"
    bg="#FFBAAE"
    style={{ borderRadius: "7px 7px 0px 0px" }}
  >
    <Icon name="error" color="dark.0" mr={2} />
    <Text fontSize={[2, 3]} fontWeight={600}>
      Please Fill Out Your Profile Information To Provide A Better Experience
    </Text>
  </Flex>
)

const AboutYourself = ({
  isLoading,
  age,
  gender,
  qualifications,
  positionsHeld,
  hobbies,
  industries,
  languages,
  personalStatement,
  skills,
  profileCompletionPercentage
}) => {
  const allSections = sectionsList({
    age,
    gender,
    personalStatement,
    positionsHeld,
    qualifications,
    industries,
    skills,
    hobbies,
    languages
  })

  return (
    <WidgetCard width="100%" bg="light.0" height="100%">
      {isLoading ? (
        <Spinner py={3} color="primary.0" width={1 / 1} />
      ) : (
        <>
          {profileCompletionPercentage < 100 && <ReminderBanner />}
          <DesktopView allSections={allSections} profileCompletionPercentage={profileCompletionPercentage} />
          <MobileView profileCompletionPercentage={profileCompletionPercentage} />
        </>
      )}
    </WidgetCard>
  )
}

export default AboutYourself
