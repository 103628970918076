/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client"

export const LOAD_DEMOGRAPHIC_FILTERS = gql`
  query getdemoGraphicFilters {
    getdemoGraphicFilters {
      items
      label
      type
    }
  }
`
