import { Box } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"

import DownForMaintenance from "../features/ui/components/DownForMaintenance"

const AppViewBaseLayout = ({ bg = "transparent", banner: Banner, children, ...props }) => {
  const { downForMaintenance } = getConfig()

  return (
    <Box as="main" bg={bg} {...props}>
      {Banner && <Banner />}
      {downForMaintenance.all ? <DownForMaintenance /> : children}
    </Box>
  )
}

export default AppViewBaseLayout
