import React from "react"
import { Flex, Card, Text, Button } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"

const NoSavantaTerritory = () => {
  return (
    <Flex width={1 / 1} justifyContent="center">
      <Card
        width={2 / 3}
        bg="light.0"
        alignItems="center"
        height="100px"
        justifyContent="center"
      >
        <Text>Sorry, you are not able to take this survey currently</Text>
        <Link to="/">
          <Button variant="primary.0" mt={3}>
            Homepage
          </Button>
        </Link>
      </Card>
    </Flex>
  )
}

export default NoSavantaTerritory
