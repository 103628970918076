import { Box, Button, Card, Flex, Text } from "@engaging-tech/components"
import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

import { AccountSelectors } from "../../account"
import CircleIcon from "../../ui/components/CircleIcon"

const PrivateResults = ({ onSignUp, onSignIn, onFinish, t }) => (
  <>
    <Box bg="secondary.2" px={3} py={2} width={[1 / 1, 4 / 5]} borderRadius={4} mb={[3, 5]}>
      <Text fontSize={[3, 4]} fontWeight="500" textAlign="center" display="block" width={1 / 1}>
        {t("success_dialog.sign_up_text", "")}
      </Text>
    </Box>
    <Flex flexDirection="row-reverse" alignItems="space-between">
      <Flex flexDirection="row-reverse">
        <Button variant="primary.0" onClick={onSignUp}>
          {t("ui.signUp", "")}
        </Button>
        <Button variant="text.dark.2" mr={2} elevation={0} onClick={onSignIn}>
          {t("ui.signIn", "")}
        </Button>
      </Flex>
      <Button variant="text.dark.2" elevation={0} onClick={onFinish}>
        {t("ui.finish", "")}
      </Button>
    </Flex>
  </>
)

const PublicResults = ({ onFinish, t }) => (
  <Button variant="primary.0" onClick={onFinish} height={36} px={4}>
    {t("success_dialog.continue", "")}
  </Button>
)

const FeatureRow = ({ text, icon }) => (
  <Flex alignItems="center" mb={3}>
    <CircleIcon name={icon} size={40} bg="secondary.0" />
    <Text color="dark.2" fontWeight="500" fontSize={[3, 4]} width="auto" ml={3}>
      {text}
    </Text>
  </Flex>
)

const PointerText = styled(Text)`
  cursor: pointer;
`

const SignUpPrompt = ({ t, onFinish, onSignIn, onSignUp, savantaId }) => (
  <Card mt={[5, 5, 6]} mb={[6, 5]} elevation={8} bg="light.0" alignItems="center" maxWidth={560}>
    <Box bg="dark.7" py={[2, 3]} px={3} width={1 / 1} mb={4}>
      <Text fontSize={[3, 4]} textAlign="center" fontWeight="500" display="block">
        {t("success_dialog.text1", "")}
      </Text>
    </Box>
    <Flex flexDirection="column" alignItems="center" px={6} pb={3}>
      {savantaId && (
        <>
          <Text color="dark.1" fontWeight="500" mb={3} width="auto" fontSize={3} textAlign="center">
            You&apos;ve now completed your test. Click here to see your results and get your reward.
          </Text>
          <Button mb={3} variant="primary.0" onClick={onSignUp}>
            sign up, see results and collect rewards
          </Button>
        </>
      )}
      {!savantaId && (
        <>
          <Text color="dark.1" fontWeight="500" mb={3} width="auto" fontSize={3} textAlign="center">
            {t("success_dialog.text3", "")}
          </Text>

          <Text color="dark.1" fontWeight="500" mb={4} width="auto" fontSize={3} textAlign="center">
            {t("success_dialog.text4", "")}
          </Text>
          <Box width={1} px={4} mb={2}>
            <Text color="dark.1" mb={3} width={1} display="block" fontSize={3} textAlign="center">
              {t("success_dialog.features.title", "")}
            </Text>
            {[
              {
                icon: "message",
                text: t("success_dialog.features.feature1", "")
              },
              {
                icon: "shopping-bags",
                text: t("success_dialog.features.feature2", "")
              },
              {
                icon: "collections_bookmark",
                text: t("success_dialog.features.feature3", "")
              }
            ].map(feature => (
              <FeatureRow key={feature.text} {...feature} />
            ))}
          </Box>
        </>
      )}
      <Text color="dark.1" fontWeight="500" mb={3} width="auto" fontSize={3} textAlign="center">
        {t("success_dialog.text5", "")}
      </Text>
      <Flex width={1} justifyContent="center">
        <Text color="dark.2" fontWeight="500" mb={3} width="auto" mr={1}>
          {t("success_dialog.text6", "")}
        </Text>
        <PointerText color="primary.0" fontWeight="500" mb={3} width="auto" onClick={onSignIn}>
          {t("ui.signIn", "")}
        </PointerText>
      </Flex>
      {savantaId && (
        <Button variant="primary.0" mb={3}>
          <a rel="noreferrer" target="_blank" href={`https://sample.savanta.com/V2/c/${savantaId}`}>
            Collect reward
          </a>
        </Button>
      )}
    </Flex>
    <Flex width={1} justifyContent="space-between" p={3}>
      <Button variant="text.dark.2" elevation={0} width="auto" onClick={onFinish}>
        {t("ui.finish", "")}
      </Button>
      <Button variant="primary.0" width={152} onClick={onSignUp}>
        {t("success_dialog.signUpBtn", "")}
      </Button>
    </Flex>
  </Card>
)

const SuccessDialog = ({ isPrivate, onFinish, onSignIn, onSignUp, onSeeResults, savantaId }) => {
  const isAuthenticated = useSelector(AccountSelectors.getIsAuthenticated)
  const { t } = useTranslation(["happiness_survey"])

  const history = useHistory()
  const inAppView = history.location.pathname.includes("/in-app-view")

  if (!inAppView && !isAuthenticated)
    return (
      <SignUpPrompt t={t} onFinish={onFinish} onSignIn={onSignIn} onSignUp={onSignUp} savantaId={savantaId} />
    )

  return (
    <Card mt={[5, 5, 6]} mb={[6, 5]} elevation={8} bg="light.0" alignItems="center" maxWidth={560}>
      <Box bg="dark.7" py={[2, 3]} px={3} width={1 / 1} mb={5}>
        <Text fontSize={[3, 6]} textAlign="center" fontWeight="500" display="block">
          {t("success_dialog.title", "")}
        </Text>
      </Box>
      <Flex flexDirection="column" alignItems="center" px={3} pb={3}>
        <Text color="dark.2" fontWeight="500" mb={3} width="auto" textAlign="center">
          {t("success_dialog.text1", "")}
        </Text>
        <Text fontWeight="500" fontSize={3} mb={5} width="auto" textAlign="center">
          {t("success_dialog.text2", "")}
        </Text>
        {isPrivate ? (
          <PrivateResults onSignUp={onSignUp} onSignIn={onSignIn} onFinish={onFinish} t={t} />
        ) : (
          <PublicResults onFinish={onSeeResults} t={t} />
        )}
      </Flex>
    </Card>
  )
}

export default SuccessDialog
