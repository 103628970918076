import { createSelector } from "reselect"

const mentorMatcher = state => state.mentorMatcher

export const getIsLoading = createSelector(
  mentorMatcher,
  state => state.isLoading
)

export const getHasLoaded = createSelector(
  mentorMatcher,
  state => state.hasLoaded
)

export const getMentors = createSelector(
  mentorMatcher,
  state => state?.matches?.mentors || []
)

export const getMentees = createSelector(
  mentorMatcher,
  state => state?.matches?.mentees || []
)
