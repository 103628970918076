import React from "react"
import styled from "styled-components"
import { Flex, Text, Utils } from "@engaging-tech/components"

import CircleIcon from "../../../ui/components/CircleIcon"
import LandingPageCard from "./LandingPageCard"

const gradient = theme =>
  Utils.createGradient(
    {
      from: "secondary.0",
      to: "light.7",
      transition: "95%",
      direction: "180deg"
    },
    theme
  )

const CardWrapper = styled(Flex)`
  ${({ theme }) => gradient(theme)};

  width: 100%;
  margin-left: 0;
  margin-right: 0;

  @media (min-width: 768px) {
    width: ${({ theme }) =>
      `calc(100% - ${theme.space[1]}px - ${theme.space[1]}px)`};

    margin-left: ${({ theme }) => `${theme.space[1]}px`};
    margin-right: ${({ theme }) => `${theme.space[1]}px`};
  }
`

const ColumnWrapper = styled(Flex)`
  margin-bottom: 32px;

  &:first-child {
    margin-top: 32px;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  @media only screen and (min-width: 768px) {
    margin-top: 32px;
    margin-bottom: 0px;
  }
`

const CategoryColumn = ({ title, icon, points }) => (
  <ColumnWrapper
    flexDirection="column"
    alignItems="center"
    width={[1 / 1, 1 / 2, 1 / 3]}
  >
    <CircleIcon name={icon} size={80} mb={2} bg="secondary.3" />
    <Text width="auto" fontSize={5} mb={5} fontWeight="500">
      {title}
    </Text>
    <CardWrapper flexDirection="column" pt={3} borderRadius={4}>
      {points.map(point => (
        <LandingPageCard
          icon={point.icon}
          title={point.heading}
          key={point.heading}
          isGreyscale
        >
          <Text color="dark.2" fontSize={3}>
            {point.description}
          </Text>
        </LandingPageCard>
      ))}
    </CardWrapper>
  </ColumnWrapper>
)

export default CategoryColumn
