/* eslint-disable react/jsx-props-no-spreading */
import { Icon, MotionFlex } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const NoShrink = styled(MotionFlex)`
  flex-shrink: 0;
`

const CircleIcon = ({
  name,
  size = 40,
  bg = "secondary.1",
  color = "dark.2",
  ICON_SIZE_RATIO = 1.666667,
  ...props
}) => {
  const getIconSize = circleSize => Math.round(circleSize / ICON_SIZE_RATIO)
  return (
    <NoShrink
      borderRadius={99}
      justifyContent="center"
      alignItems="center"
      width={`${size}px`}
      height={`${size}px`}
      bg={bg}
      {...props}
    >
      <Icon name={name} size={getIconSize(size)} color={color} />
    </NoShrink>
  )
}

export default CircleIcon
