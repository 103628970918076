import React, { useState } from "react"
import styled from "styled-components"
import { BookCategoryChips } from "../../../gardners"
import { Card, Text, Flex, Box, H4 } from "@engaging-tech/components"

import DigitalBookDialog from "./DigitalBookDialog"

const Thumbnail = styled.img`
  height: 124px;
  width: auto;
`

const DowloadFlex = styled(Flex)`
  cursor: pointer;
  box-shadow: 0 2px 2px ${({ theme }) => theme.colors.dark[4]};
`

const canBeDownloaded = book =>
  book.MaxDownloads == null || book.MaxDownloads > 0

const DigitalBookPreview = ({ book, onDownload }) => {
  const [showDownloadPopup, setShowDownloadPopup] = useState(false)

  const handleDownloadPopup = () => setShowDownloadPopup(!showDownloadPopup)

  const DownloadText =
    book.type === "ebook" ? "DOWNLOAD EBOOK" : "DOWNLOAD AUDIOBOOK"

  return (
    <Card mb={3} p={3} bg="light.0" flexDirection={["column", "column", "row"]}>
      {book.image && (
        <Box mr={[0, 3]} mb={[3, 0]} width="auto">
          <Thumbnail src={book.image} />
        </Box>
      )}
      <Flex flexDirection="column" height={["auto", "auto", "124px"]}>
        <Flex
          flexWrap="wrap"
          flexDirection="column"
          height={["auto", "auto", "124px"]}
        >
          <H4 mb={1} fontSize={4} fontWeight="500">
            {book.title}
          </H4>
          <Flex>
            {book.authors &&
              book.authors.map(author => (
                <Text
                  mr={3}
                  color="dark.2"
                  lineHeight="1.4"
                  fontSize={3}
                  width="auto"
                >
                  {author}
                </Text>
              ))}
          </Flex>
          {book.maxDownloads != null && (
            <Box mt={2}>
              <Text fontSize={3} color="dark.2">
                {"You have "}
              </Text>
              <Text fontSize={3} fontWeight={500} color="dark.2">
                {book.maxDownloads}
              </Text>
              <Text fontSize={3} color="dark.2">
                {" downloads of this book left"}
              </Text>
            </Box>
          )}
        </Flex>
        <Flex
          justifyContent="flex-end"
          flexDirection={["column", "column", "row"]}
        >
          {book.categories.length > 0 && (
            <BookCategoryChips classifications={book.categories} />
          )}
          <Flex
            mt={[3]}
            justifyContent={["flex-start", "flex-start", "flex-end"]}
          >
            <DowloadFlex
              width="auto"
              bg="secondary.0"
              py={2}
              px={4}
              borderRadius={4}
              onClick={canBeDownloaded(book) && handleDownloadPopup}
            >
              <Text fontSize={3} fontWeight={500} color="dark.2">
                {DownloadText}
              </Text>
            </DowloadFlex>
            {showDownloadPopup && (
              <DigitalBookDialog
                book={book}
                onDownload={onDownload}
                handleDownloadPopup={handleDownloadPopup}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  )
}

export default DigitalBookPreview
