import { Modal, Box, MotionFlex, CustomHooks } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React, { useState } from "react"
import { createPortal } from "react-dom"
import styled from "styled-components"
import { AnimatePresence } from "framer-motion"
import TabBar from "../../../../ui/components/TabBar"
import ManageProfile from "../../containers/ProfileSettingsModal/ManageProfile"
import MarketingSettings from "../../containers/ProfileSettingsModal/MarketingSettings"
import PremiumSettings from "../../containers/ProfileSettingsModal/PremiumSettings"
import PremiumHeading from "../PremiumHeading"

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const StyledModal = styled(Modal)`
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
`

const routes = {
  profile: {
    route: "profile",
    text: "Edit Profile",
    component: <ManageProfile />
  },
  marketingPreferences: {
    route: "marketingPreferences",
    text: "Marketing Preferences",
    component: <MarketingSettings />
  },
  premium: {
    route: "premium",
    text: "Manage Premium",
    component: <PremiumSettings />
  }
}
const tabRoutes = Object.values(routes)
const maxHeight = () => {
  if (typeof window !== "undefined") {
    return window.innerHeight * 0.8
  }
  return 7000
}
const modalVariants = {
  profile: {
    height: maxHeight(),
    transition: {
      duration: 0.5,
      ease: "easeInOut"
    }
  },
  premium: isPremium => ({
    height: isPremium ? "400px" : "365px",
    transition: {
      duration: 0.5,
      ease: "easeInOut"
    }
  })
}

const ProfileModalManager = ({ setVisibility, isPremium }) => {
  const paths = usePaths()
  const router = useRouter()

  const [route, setRoute] = useState()

  CustomHooks.useFreshChatDeactivate()

  return createPortal(
    <StyledModal
      animated
      disableScrollbar
      onToggle={() => {
        setVisibility(false)
        router.navigate(paths.careerDeveloper.index)
      }}
      maxHeight="80vh"
      mb={100}
    >
      <MotionFlex
        bg="light.0"
        width={1}
        pb={[3, 0]}
        flexDirection="Column"
        animate={route}
        variants={modalVariants}
        custom={isPremium}
        maxHeight="80vh"
        style={{ overflowY: "auto" }}
      >
        {isPremium && (
          <PremiumHeading
            bg="primary.0"
            color="yellow.0"
            fontSize={3}
            radius={4}
          />
        )}
        <TabBar routes={tabRoutes} onChange={setRoute} />

        <Box p={4}>
          <AnimatePresence>
            {setVisibility && routes[route]?.component}
          </AnimatePresence>
        </Box>
      </MotionFlex>
    </StyledModal>,
    appRoot()
  )
}

export default ProfileModalManager
