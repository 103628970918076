import React from "react"
import styled from "styled-components"
import { H3, Card, Flex } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"

import BillingForm from "./CheckoutForm"
import StripeWrapper from "./StripeWrapper"

const API_KEY = () => getConfig().keys.stripe

const CardTitle = styled(H3)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[4]};
`

const BillingInformation = ({
  isLoading,
  updateOrder,
  orderId,
  intentSecret,
  isPremium,
  userDetails,
  setDeliveryType,
  onPaymentActionSuccess,
  onPaymentActionFail,
  hasDeliveryCosts
}) => (
  <Card bg="light.0" flexDirection="column" width={[1 / 1, 1 / 1, 3 / 4]} MaxWidth={700} ml="auto">
    <CardTitle width={1 / 1} fontSize={18} fontWeight={700} p={3}>
      Checkout
    </CardTitle>
    <Flex p={3} width={1/1}>
      <StripeWrapper apiKey={API_KEY()}>
        <BillingForm
          intentSecret={intentSecret}
          isPremium={isPremium}
          userDetails={userDetails}
          isLoading={isLoading}
          onSubmit={{
            action: orderInfo => updateOrder(orderId, orderInfo),
            text: "PLACE ORDER"
          }}
          onPaymentActionSuccess={{
            action: onPaymentActionSuccess,
            text: "Success Payment Action"
          }}
          onPaymentActionFail={{
            action: onPaymentActionFail,
            text: "Failed Payment Action"
          }}
          setDeliveryType={setDeliveryType}
          hasDeliveryCosts={hasDeliveryCosts}
        />
      </StripeWrapper>
    </Flex>
  </Card>
)

export default BillingInformation
