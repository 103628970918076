/* eslint-disable import/prefer-default-export */
import { gql } from "../../../lib/Apollo"

export const REPLY_INVITATION = gql`
  mutation($invitationId: ID!, $accept: Boolean!) {
    replyConnectInvitation(invitationId: $invitationId, accept: $accept)
  }
`

export const CONNECT_TO_USER = gql`
  mutation($userId: ID!) {
    connectToUser(userId: $userId)
  }
`

export const GET_CONNECT_INVITES = gql`
  query($limit: Float, $skip: Float) {
    getConnectInvitations(limit: $limit, skip: $skip) {
      id
      inviteDate
      user {
        id
        firstName
        lastName
        jobTitle
        companyName
        picture {
          small
        }
      }
      inviteDate
      isRecipient
    }
  }
`
export const REPORT_USER = gql`
  mutation reportUser($reason: String, $reportedUserId: String) {
    reportUser(reason: $reason, reportedUserId: $reportedUserId) {
      reason {
        key
      }
    }
  }
`
