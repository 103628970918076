import { Box, Card, Flex, Icon, Text } from "@engaging-tech/components"
import { titleCaps } from "@engaging-tech/frontend-utils"
import React from "react"
import styled from "styled-components"

import ClickableIcon from "../../../ui/components/ClickableIcon"

const BorderBottomBox = styled(Box)`
  border-bottom: ${({ isLast, theme }) => (isLast ? "none" : `1px solid ${theme.colors.dark[5]}`)};
  width: 100%;
  padding-top: ${({ theme }) => theme.space[3]}px;
  padding-left: ${({ theme }) => theme.space[4]}px;
  padding-right: ${({ theme }) => theme.space[4]}px;
  padding-bottom: ${({ theme }) => theme.space[3]}px;
`

const Alert = ({ isLast, setModalType, setActiveAlert, alert, type }) => {
  const { location, country, industry, category, jobRole, jobType, frequency, materialType } = alert
  const handleClick = tab => {
    setActiveAlert(alert)
    setModalType(tab)
  }
  const countryName = country?.name
  const industryName = industry?.name
  const categoryName = category?.name

  const joinCriteria = array => array.filter(crit => crit).join(" | ")
  const criteria = {
    Job: joinCriteria([countryName, industryName, jobType]),
    Event: location?.name,
    "Business Library": categoryName
  }

  const BLText = () => {
    if (category?.name && materialType) return `${category?.name} ${titleCaps(materialType)}s`
    if (category?.name) return `${category?.name}`
    if (materialType) return `${titleCaps(materialType)}${materialType === "research" ? "" : "s"}`
    return null
  }

  const determineText = {
    Job: jobRole,
    Event: category?.name && `${category?.name} Events`,
    "Business Library": BLText()
  }
  return (
    <BorderBottomBox isLast={isLast}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text color="dark.1" width="auto">
          {determineText[type]}
        </Text>
        <Flex width="auto" alignItems="center">
          <ClickableIcon name="edit" mr={2} onClick={() => handleClick("edit")} />
          <ClickableIcon name="delete" onClick={() => handleClick("delete")} />
        </Flex>
      </Flex>
      <Text width={1} color="dark.2">
        {criteria[type]}
      </Text>
      <Flex width={1} alignItems="center" py={2}>
        <Icon name="schedule" />
        <Text width="auto" color="dark.2" fontSize={3} ml={2}>
          {`${frequency} via Email`}
        </Text>
      </Flex>
    </BorderBottomBox>
  )
}

const AlertsList = ({ type, alerts, setModalType, setActiveAlert }) => {
  return alerts.length ? (
    <Card elevation={1} width={1} mb={[20]}>
      <Text fontWeight={500} display="block" pt={4} px={4}>
        Saved {type} Alerts
      </Text>
      {alerts?.map((alert, i) => (
        <Alert
          key={alert.id}
          alert={alert}
          isLast={i === alerts.length - 1}
          setModalType={setModalType}
          setActiveAlert={setActiveAlert}
          type={type}
        />
      ))}
    </Card>
  ) : (
    <Text p={2} width="auto" color="dark.2">
      You currently have no {type} alerts. You can manage all your {type} alerts here once you create some.
    </Text>
  )
}

export default AlertsList
