/* eslint-disable import/prefer-default-export */
import { Button, Flex } from "@engaging-tech/components"
import styled from "styled-components"

export const PremiumBanner = styled(Flex)`
  border-radius: 4px 4px 0px 0px;
  height: 38px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const CreateJobAlertButton = styled(Button)`
  width: 180px;
  background: #003e4c;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`
