/* eslint-disable import/prefer-default-export */
import { TextArea } from "@engaging-tech/components"
import styled from "styled-components"

export const TextAreaCustom = styled(TextArea)`
  > label {
    margin-left: 10px;
    padding-bottom: 10px;
  }
`
