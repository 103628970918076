import { Navbar } from "@engaging-tech/navigation"
import { usePaths, useRoutes } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"
import SiteLogo from "./SiteLogo"

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const MinifiedNav = ({
  isAuthenticated,
  userProfile,
  disableAccountRoutes,
  headerLogo
}) => {
  const routes = useRoutes()
  const paths = usePaths()

  const allAccountRoutes = routes.filter(route =>
    route.path.includes(paths.account.index)
  )[0].children

  const userAccountRoutes = allAccountRoutes

  const auxillaryRoutes = [
    {
      linkProps: {
        external: true,
        newTab: true
      },
      description: "For Business",
      path: getConfig().worklForBusiness.public.index
    }
  ]

  const signedOutRoutes = userAccountRoutes.filter(
    route =>
      route.path === paths.account.signIn || route.path === paths.account.signUp
  )

  const signedInRoutes = userAccountRoutes.filter(
    route => route.path === paths.account.signOut
  )

  return (
    <Navbar
      headerLogo={headerLogo}
      siteLogo={SiteLogo}
      accountRoutes={(() => {
        if (disableAccountRoutes) {
          return []
        }
        return isAuthenticated ? signedInRoutes : signedOutRoutes
      })()}
      auxillaryRoutes={auxillaryRoutes}
      height={100}
      elevation={3}
      navbarItemColor={{ selected: "primary.0", unSelected: "primary.1" }}
      tabBarColor="tertiary.0"
      userDetails={{
        isAuthenticated,
        details: userProfile,
        premiumColor: "dark.6",
        nonPremiumColor: "dark.6"
      }}
      accountRoutesProps={{
        bg: "tertiary.0"
      }}
      holdingImage={profilePlaceholder}
      profilePath={paths.careerDeveloper.yourProfile}
    />
  )
}

export default MinifiedNav
