import { connect } from "react-redux"

import { UserContentActions, UserContentSelectors } from "../../userGeneratedContent"
import CreateMaterialForm from "../../userGeneratedContent/components/BusinessLibrary/Create/CreateMaterialForm"
import CreateSeries from "./CreateSeries"

const Publish = materialType => {
  const mapState = state => ({
    isLoading: UserContentSelectors.getIsLoading(state),
    hasCreated: UserContentSelectors.getHasCreated(state),
    type: materialType,
    renderSeriesCreator: CreateSeries
  })

  const mapDispatch = dispatch => ({
    onSubmit: newMaterial => dispatch(UserContentActions.addMaterial(materialType, newMaterial))
  })

  return connect(mapState, mapDispatch)(CreateMaterialForm)
}

export default Publish
