export const types = {
  CREATE: "[Expert Advice] Create",
  CREATE_SUCCESS: "[Expert Advice] Create Success",
  CREATE_FAIL: "[Expert Advice] Create Fail",
  RESET_CREATE: "[Expert Advice] Reset Create",
  LOAD_REGIONS: "[Expert Advice] Load Regions",
  LOAD_REGIONS_SUCCESS: "[Expert Advice] Load Regions Success",
  LOAD_REGIONS_FAIL: "[Expert Advice] Load Regions Fail",
  LOAD_JOB_ROLES: "[Expert Advice] Load Job Roles",
  LOAD_JOB_ROLES_SUCCESS: "[Expert Advice] Load Job Roles Success",
  LOAD_JOB_ROLES_FAIL: "[Expert Advice] Load Job Roles Fail",
  SET_JOB_ROLE_TEXT_SEARCH: "[Expert Advice] Set Job Role Text Search"
}

export const create = ({ expertTypeId, industriesId, question, location }) => ({
  type: types.CREATE,
  payload: { expertTypeId, industriesId, question, location }
})

export const createSuccess = newAdvice => ({
  type: types.CREATE_SUCCESS,
  payload: newAdvice
})

export const createFail = () => ({
  type: types.CREATE_FAIL
})

export const loadRegions = countryId => ({
  type: types.LOAD_REGIONS,
  payload: countryId
})

export const loadRegionsSuccess = regions => ({
  type: types.LOAD_REGIONS_SUCCESS,
  payload: regions
})

export const loadRegionsFail = () => ({
  type: types.LOAD_REGIONS_FAIL
})

export const loadJobRoles = text => ({
  type: types.LOAD_JOB_ROLES,
  payload: text
})
export const loadJobRolesSuccess = jobRoles => ({
  type: types.LOAD_JOB_ROLES_SUCCESS,
  payload: jobRoles
})

export const loadJobRolesFail = () => ({
  type: types.LOAD_JOB_ROLES_FAIL
})

export const resetCreate = () => ({
  type: types.RESET_CREATE
})

export const setJobRoleTextSearch = textSearch => {
  return {
    type: types.SET_JOB_ROLE_TEXT_SEARCH,
    payload: textSearch
  }
}
